import checkmark from '../../components/images/check-circle.svg'

import css from '../sass/registerqr.module.scss'

export default function QrConfirmRow( {message} ) {  

  return (
    <div className={css['qr-confirm-row']}>
      <img className={css['qr-confirm-check']} src={checkmark} alt="check mark" />
      <p className={css['qr-confirm-text']}>{message}</p>
    </div>
  )
}