import styled from 'styled-components'
import { device } from 'common/Device'

const defaultFontSize = 16

const SearchStyles = styled.div`
  display:inline-flex;
  flex-direction:row;
  align-items:center;
  width:${ 425/645 * 100}%;
  margin-right:${ 12/defaultFontSize }rem;
  box-shadow: 0 ${2/defaultFontSize}rem ${4/defaultFontSize}rem 0 rgba(0,0,0,0.09);
  border-radius:${3/defaultFontSize}rem;
  box-sizing:border-box;
  height:${ 50/defaultFontSize }rem;
  background:#FFFFFF;
  padding:0 ${ 7/defaultFontSize }rem;

  input {
    flex:1 0 100%;
    max-width:100%;
    line-height:${ 18/defaultFontSize };
    border:none;
    box-sizing:border-box;
    padding:0 ${ 7/defaultFontSize }rem;
  }

  @media screen and (max-width:1180px) {
    width:${ 514/720 * 100}%;
  }

  @media ${ device.tablet } {
    height:${ 40/defaultFontSize }rem;
  }

  @media ${ device.mobile } {
    width:100%;
    padding:0 ${ 7/320 * 100 }%;

    input {
      padding:0 ${ 7/320 * 100 }%;
    }
  }
`

// keyword search for filtering results
const HistorySearch = props => (
  <SearchStyles>
    <input
      type="text"
      placeholder="Enter Unit/Fund/Keyword or Amount"
      value={ props.value }
      onChange={ props.onChange }
      onKeyDown={ props.onKeyDown }
    />
  </SearchStyles>
)
export default HistorySearch;
