// the printable mebership card
// this should only render the card for printing
import { combineClasses } from 'services/util'
import css from '../sass/card.module.scss'


function Card({
  firstName,
  lastName,
  membershipLevel,
  joinDate,
  expirationDate,
  constituentLookupId
}) {
  let memberSince = ''
  let bgClass
  let nameCoords = {}
  let crmCoords = {}

  // figure out what background image and copy to use
  // set coordinates for everything
  switch( membershipLevel ) {
    case 'Blue':
      bgClass = 'blue'
      nameCoords.x = '4%'
      nameCoords.y = '87%'
      crmCoords.x = '8%'
      crmCoords.y = '93%'
      memberSince = (
        <text
          className={css.since}
          x="78%"
          y="93%"
        >
          { joinDate.split('-')[0].slice(-4) }/{ expirationDate.split('-')[0].slice(-4) }
        </text>
      )
    break;

    case 'Gold':
      bgClass = 'gold'
      nameCoords.x = '7%'
      nameCoords.y = '93%'
      crmCoords.x = '70.5%'
      crmCoords.y = '93%'
      memberSince = (
        <>
          <text
            className={css.since}
            x="78%"
            y="11%"
          >
            Member
          </text>
          <text
            className={css.since}
            x="79.5%"
            y="17%"
          >
            since { joinDate.split('-')[0].slice(-4) }
          </text>
        </>
      )
    break;

    case 'Life':
      bgClass = 'life'
      nameCoords.x = '4%'
      nameCoords.y = '87%'
      crmCoords.x = '8%'
      crmCoords.y = '93%'
      memberSince = (
        <>
          <text
            className={css.since}
            x="76%"
            y="87%"
          >
            Member
          </text>
          <text
            className={css.since}
            x="77.5%"
            y="93%"
          >
            since { joinDate.split('-')[0].slice(-4) }
          </text>
        </>
      )
    break;

    // note: regular members don't have a date!
    default:
      memberSince = ''
      bgClass = 'member'
      nameCoords.x = '4%'
      nameCoords.y = '87%'
      crmCoords.x = '8%'
      crmCoords.y = '93%'
    break;
  }

  return (
    <aside className='print-only'>
      <div className={combineClasses(css, '', 'print-card', bgClass)}>
        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
          <image width="100%" height="100%" href={`${process.env.PUBLIC_URL}/alumni/images/${bgClass}.png`}/>
          <text className={css.name} {...nameCoords}>{ firstName } { lastName }</text>
          <text className={css.crm} {...crmCoords}>ID #{ constituentLookupId }</text>
          { memberSince }
        </svg>
      </div>
    </aside>
  )
}

export default Card
