import { useState} from 'react'

import { history } from 'services/history.js'

import { submitCode } from './verifyUtils'

import css from '../sass/flow.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

export default function EmailVerificationCode(props) {

  const [verificationCode, setVerificationCode] = useState()
  const email = useState(props.location.email)

  const submitVerification = () => {

    try {
      const codeResponse = submitCode(email, verificationCode)
      console.log(codeResponse)

      history.push({ pathname: '/flow/inviteeonlyrouter' })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className={css['flow-container-w-margin']}>
      <h2 className={css['centered']}>Enter Verification Code</h2>
      <div className={css['flow-inputs']}>
        <label>Verification Code</label>
        <input type='text' value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />
      </div>

      <div className={css['flow-button-container']}>
        <button 
          onClick ={() => submitVerification()}
          className={`${themeCss['FilledBlue']} ${themeCss['button']} ${css['centered-button']}`}>Complete Email Verification</button>
        {/* <button
          onClick ={(e) => {history.push({ pathname: '/flow/emailverificationcode' }) }}
          className={`${themeCss['FilledBlue']} ${themeCss['button']}`}>Fake Action - Verification not successful</button> */}
      </div>
    </div>
  )
}