import { useState } from 'react';

import GiftsAssociatedGrid from './GiftsAssociatedGrid'

import buttonStyle from '../sass/buttons.module.scss'
import css from '../payment/sass/associatedgift.module.scss'

function GiftsAssociatedToggle(props) {
  const [showAssociatedGifts, setShowAssociatedGifts] = useState(false);

  const toggleAssociatedGifts = (e) => {
    setShowAssociatedGifts(prevShowAssociatedGifts => !prevShowAssociatedGifts);
  }

  return (
    <div>
      <h2 className={css['gift-section-header']}>Gifts associated with this credit card</h2>
      {showAssociatedGifts ? <GiftsAssociatedGrid gifts={props.gifts} /> : '' }
      <a onClick={toggleAssociatedGifts}
        className={`${buttonStyle.button} ${buttonStyle.StrokeBlue} ${buttonStyle.WideMobileButton} ${css['gifts-associated']}`}>
        <p>{showAssociatedGifts ? 'Hide associated gifts' : 'Show associated gifts'}</p>
        <div className={showAssociatedGifts ? css['gifts-associated__arrow-up'] : css['gifts-associated__arrow-down']}> </div>
      </a>
    </div>
  )
}

export default GiftsAssociatedToggle;