import styled from 'styled-components'

import * as buttons from 'css/Buttons'
import * as colors from 'css/Colors'
import Zindex from 'css/z-indexLayers.js';
import { device } from 'common/Device'

const defaultFontSize = 16

export const ContactFormContainer = styled.div`
  position: absolute;
  left: 0;
  top:100%;
  width:100%;
  box-shadow:0 30px 38px 0 rgba(0,0,0,0.3);
  @media ${device.tablet}{
    top:${58 / defaultFontSize}rem;
    z-index: 2000;
  }
  @media ${device.mobile}{
    top:100%;
    position:relative;
  }
`
export const ContactForm = styled.form`
  position:relative;
  z-index: ${Zindex.zindexOverlaystackOrder1};
  flex:1 0 ${ 953 / 1440  * 100}%;
  margin-right:${15/1440 * 100}%;
  padding:${40/defaultFontSize}rem 0 ${ 12/defaultFontSize }rem;
  .content-link {
     color:#0000000;
   }
  h4{
   color:#000;
   margin:0 0 ${30/defaultFontSize}rem;
  }
  .contact-form{
    display:flex;
    justify-content: space-between;
    & > div{
      flex:0 48%;
    }
    li{
      padding: 0 ${8/defaultFontSize}rem !important;
      align-items: flex-start;
    }
  }
  .required{
    color:${colors.messagingRed};
  }
  @media ${ device.tablet } {
    flex:1 0 100%;
    padding:0;
  }
  @media ${device.mobile}{
    padding:0;
    order:2;
    .contact-form{
      flex-direction:column;
      & >div{
        flex:0 100%;
      }

    }
    & > div{
     display:flex:
     flex-direction:column;
    }

    h4{
     margin:${24/defaultFontSize}rem 0 ${28/defaultFontSize}rem;
    }

  }
`

export const Label = styled.label`
  color: #666666;
  margin-bottom: 4px;
  font-weight:normal;
`
export const SubmitBtn = styled(buttons.FilledBlue)`
  width:100%;
`

export const ContactUsFormContent = styled.div`
  margin-top:0;
  background:${ colors.lightGray };=
  .save-changes-alert{
    text-align:left;
    padding:${30 / defaultFontSize}rem ${30 / defaultFontSize}rem 0;
    margin:0;
    width:60%;
    & >div{
      margin:0;
    }
  }
  .wrapper{
    max-width:100%;
  }
  .contact-wrapper{
    display: flex;
    flex-direction: row;
  }
  @media ${device.tablet}{
    .contact-wrapper{
      flex-direction:column-reverse;
    }
    .save-changes-alert{
      width:90%;
      }
  }
  @media ${ device.mobile } {
    .contact-wrapper{
      flex-direction:column;
    }
    .save-changes-alert{
      padding:${30 / defaultFontSize}rem ${20 / defaultFontSize}rem 0;
    }
  }

`

export const ContactCopyImageSlide = styled.div`
  content:'';
  flex:1 0 ${ 472/1440 * 100 }%;
  box-sizing:border-box;
  display: flex;
  flex-direction: column;
  padding: 26px 0;
  background: #FFFFFF;
  position:relative;
  margin-left:${15/1440 * 100}%;
  padding-left:${ 27/1440 * 100 }%;
  padding-right:${ 15/1440 * 100 }%;
  @media ${device.tablet} {
    background:transparent;
    padding-left:0;
    padding-right:0;
    &:after {
      display:none;
    }
  }
  @media ${device.mobile}{
    display:block;
    position:static;
    order:1;
    border-bottom: 1px solid ${colors.guideGray};
    padding: ${28/defaultFontSize}rem 0 ${14/defaultFontSize}rem;
  }
`

export const ContactInnerImage = styled.div`
  z-index: ${Zindex.zindexHeader};
  flex: 0 55%;
  background:${ props => `url("${props.icon}")` || ''};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 70%;
  border-radius:5px;
  @media ${device.tablet}{
    display:none;
  }
`

export const ContactUsHeader=styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  &:after {
    content:'';
    display:block;
    transform: translate(${ props=>props.showContactForm? '25%, 25%' : '25%, -25%' }) rotate(135deg);
    background:#FFFFFF;
    height:${(5/defaultFontSize)}rem;
    width:${(5/defaultFontSize)}rem;
    border-top:${props => !props.showContactForm?`2px solid ${colors.primaryBlue}`:`1px solid #FFFFFF`} ;
    border-left:${props => !props.showContactForm?`1px solid #FFFFFF`:`2px solid ${colors.primaryBlue}`};
    border-right:${props => !props.showContactForm?`2px solid ${colors.primaryBlue}`:`1px solid #FFFFFF`} ;
    border-bottom:${props => !props.showContactForm?`1px solid #FFFFFF`:`2px solid ${colors.primaryBlue}`};
    margin:${props => props.showContactForm?`0 ${6/defaultFontSize}rem`:`${3/defaultFontSize}rem ${6/defaultFontSize}rem`};
  }
  @media ${device.mobile}{
    :after{
      margin:${props => props.showContactForm?`${6/defaultFontSize}rem`:`${3/defaultFontSize}rem ${6/defaultFontSize}rem`};
    }
  }
`