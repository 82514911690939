import { useEffect, useState } from 'react'
import { useQuery } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import { sortSpan, performSort, handleSort } from '../auctionsadminutil'

import { adminGetAuctionByCode, sendAllPaymentReminderEmails } from '../auctionsadminutil'

import css from './sass/closedauction.module.scss'
import themeCss from '../../components/sass/theme.module.scss'
import ClosedBiddingRow from './ClosedBiddingRow'
import ClosedManageCheckout from './ClosedManageCheckout'
import ConfirmSendPayReminders from '../ConfirmModal/ConfirmSendPayReminders'

export default function ClosedBidding(props) {

  const [itemsPaidCount, setItemsPaidCount] = useState(0)
  const [itemsUnpaidCount, setItemsUnpaidCount] = useState(0)
  const [totalDue, setTotalDue] = useState(0)
  const [totalPaid, setTotalPaid] = useState(0)
  const [attendeesPaidCount, setAttendeesPaidCount] = useState(0)
  const [attendeesUnpaidCount, setAttendeesUnpaidCount] = useState(0)
  const [showPaidFilter, setShowPaidFilter] = useState(true)
  const [showOweFilter, setShowOweFilter] = useState(true)
  // const [showHasAltFilter, setShowHasAltFilter] = useState(true)
  // const [showNoAltFilter, setShowNoAltFilter] = useState(true)
  const [nameFilter, setNameFilter] = useState('')
  const [selectedAttendees, setSelectedAttendees] = useState([])
  const [unclaimedItems, setUnclaimedItems] = useState([])

  //to keep the attendee page inside the live bidding tab
  const [attendeeView, setAttendeeView] = useState(false)
  const [managedAttendee, setManagedAttendee] = useState()

  //array of truth for all attendees in auction
  const [attendeeArray, setAttendeeArray] = useState([])

  //filtered list based off selection
  const [displayedAttendees, setDisplayedAttendees] = useState([])
  
  //confirm modal variables
  const [modalUse, setModalUse] = useState()
  const [modalData, setModalData] = useState()
  const [showModal, setShowModal] = useState(false)
  
  //for table sorting
  const [sortOptions, setSortOptions] = useState({ key: '', dir: 'asc' })

  const tableFormatter = [
    ['FullName', 'Attendee Name'],
    ['TotalDue', 'Total Amount Due'],
    ['TotalPaid', 'Total Amount Paid'],
    ['ItemCount', 'Items Won']
  ]
        
  const auctionCode = props.auctionCode

  const formatAttendeeName = (attendee) => {
    //needs an input of attendee.CurrentHighBidderInfo
    if (!attendee) return 'Error loading'
    return `${attendee.FirstName} ${attendee.LastName}`
  }

  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, error, isLoading } = useQuery({
    queryKey: ["adminAuctionDetail", auctionCode],
    queryFn: async () => {
      return await adminGetAuctionByCode(auctionCode)
    },
    staleTime: 0
  })

  //use effect for query results
  useEffect(() => {
    showLoading(isLoading)

    if (error || (auctionDetail && auctionDetail.error)) {
      console.log('Error!', error || auctionDetail.error)
    }

  }, [auctionDetail, error, isLoading])

  // use effect to take auctionDetail and reformat the data for attendee display, assigning it to attendeeArray for mapping in JSX
  // maybe could reformat into auctionDetail if we don't need all of the details the query returns
  useEffect(() => {

    if (!auctionDetail) return

    const adminItems = auctionDetail?.Items
    const formattedAttendeeItems = {}
    
    const attendeeItemFormat = (item) => {
      return {
        CurrentBid: item.CurrentBid,
        ItemId: item.ItemId,
        Paid: item.Paid,
        Title: item.Title
      }
    }
  
    for (let i = 0; i < adminItems?.length; i++) {
      let item = adminItems[i]
      let user = item.CurrentHighBidder
  
      // check to see if the item has been paid for
      let itemPayDetails = {}
  
      //if it has been paid for, set up the item pay details, setting total paid to current high bid, setting due to zero
      if (item.Paid) {
        itemPayDetails = {
          TotalPaid: item.CurrentBid,
          TotalDue: 0
        }
      } else {
        // if it has not been paid for, flip the above conditions
        itemPayDetails = {
          TotalPaid: 0,
          TotalDue: item.CurrentBid
        }
      }
  
      // for the if block, just add the current item payment details to the totals in the current item
      if (formattedAttendeeItems[user]) {
        formattedAttendeeItems[user].Items.push(attendeeItemFormat(item))
  
        formattedAttendeeItems[user].TotalPaid += itemPayDetails.TotalPaid
        formattedAttendeeItems[user].TotalDue += itemPayDetails.TotalDue
        
      } else {
        //for the else block, just add the item payment details
        formattedAttendeeItems[user] = {
          CurrentHighBidderInfo: item.CurrentHighBidderInfo,
          CurrentHighBidder: user,
          Items: [attendeeItemFormat(item)],
          ...itemPayDetails
        }
      }
  
    }

    //take the item object and format the name and item count for table sorting
    const formattedAttendeeArray = Object.values(formattedAttendeeItems).map((attendee) => {
      return {
        ...attendee,
        FullName: formatAttendeeName(attendee.CurrentHighBidderInfo),
        ItemCount: attendee.Items.length
      }
    })

    setAttendeeArray(formattedAttendeeArray)
  }, [auctionDetail])

  // const countAttendees = () => {
  //   return selectedAttendees.reduce((count, value) => count + (value ? 1 : 0), 0)
  // }

  //useEffect to calculate display totals of all attendees and items
  useEffect(() => {
    let paidItems = 0
    let unpaidItems = 0

    let paidAttendees = 0
    let unpaidAttendees = 0

    let calcDue = 0
    let calcPaid = 0

    let unclaimed = []

    attendeeArray.forEach((attendee) => {
      if (attendee.CurrentHighBidder){
        if (attendee.TotalDue > 0) unpaidAttendees++
        else paidAttendees++
  
        calcDue += attendee.TotalDue
        calcPaid += attendee.TotalPaid
  
        const items = attendee.Items
      
        if (items) {
          items.forEach((item) => {
            if (item.Paid) paidItems++
            else unpaidItems++
          })}

      } else unclaimed.push(attendee.Items)
    })

    setUnclaimedItems(...unclaimed)

    setAttendeesPaidCount(paidAttendees)
    setAttendeesUnpaidCount(unpaidAttendees)

    setItemsPaidCount(paidItems)
    setItemsUnpaidCount(unpaidItems)

    setTotalPaid(calcPaid)
    setTotalDue(calcDue)

  }, [attendeeArray])

  //filter for displayed attendees
  useEffect(() => {

    let filtered = [...attendeeArray]

    if (nameFilter) {
      filtered = attendeeArray?.filter((attendee) => {
        console.log(attendee)
        return (attendee.CurrentHighBidderInfo.FirstName.includes(nameFilter)) ||
          (attendee.CurrentHighBidderInfo.LastName.includes(nameFilter))
      })
    }
    
    const output = filtered.filter((attendee) => {
      return (showOweFilter && attendee.TotalDue) || (showPaidFilter && !attendee.TotalDue)
    })

    const sortedAttendees = performSort(output, sortOptions)

    setDisplayedAttendees(sortedAttendees)

  }, [showOweFilter, showPaidFilter, attendeeArray, nameFilter, sortOptions])

  const toggleCheckbox = (userId) => {
    const userIndex = selectedAttendees.indexOf(userId)
    const returnArr = [...selectedAttendees]

    if (userIndex > -1) returnArr.splice(userIndex, 1)
    else returnArr.push(userId)

    setSelectedAttendees(returnArr)


  }

  const sendReminderEmails = async () => {
    console.log('send all reminder emails - needs ACTION')

    const data = {
      auctionId: auctionDetail.AuctionId
    }

    setModalData(data)
    setShowModal(true)
    setModalUse('payReminders')

    // showLoading(true)
    // const response = await sendAllPaymentReminderEmails(auctionDetail.AuctionId)
    // showLoading(false)
    // console.log({response})
  }

  // const selectAllAttendees = (checked) => {
  //   let returnArr = [];

  //   if (checked) {
  //     returnArr = attendeeArray.map((attendee) => attendee.CurrentHighBidder)
  //   } else returnArr = []

  //   console.log('return Arr', returnArr)

  //   setSelectedAttendees(returnArr)

  // }

  const resetFilters = () => {
    setShowPaidFilter(true)
    setShowOweFilter(true)
    // setShowHasAltFilter(true)
    // setShowNoAltFilter(true)
    setNameFilter('')
  }

  const toAttendeePage = (attendeeFromRow) => {
    setManagedAttendee(attendeeFromRow)
    setAttendeeView(true)

  }

  const toAllAttendeePage = () => {
    setAttendeeView(false)
  }

  if (!auctionDetail) return (<></>)

  if (attendeeView) {
    return <ClosedManageCheckout auctionCode={auctionCode} managedAttendee={managedAttendee} toAllAttendeePage={toAllAttendeePage} />
  }

  return (
    <div className={css['closed-auction-container']}>
        
      {(showModal && modalUse === 'payReminders') && <ConfirmSendPayReminders setShowConfirmModal={setShowModal} data={modalData} /> }

      <div className={css['closed-auction-summary']}>
        <div>
          <p>{attendeesUnpaidCount} ATTENDEES WITH BALANCE DUE</p>
          <p>{attendeesPaidCount} ATTENDEES WITH FULLY PAID BALANCE</p>
        </div>
        <div>
          <p>{itemsUnpaidCount} ITEMS WITH BALANCE DUE</p>
          <p>{itemsPaidCount} ITEMS WITH FULLY PAID BALANCE</p>
        </div>
      </div>

      <p>
        <button onClick={() => sendReminderEmails()} className={`${themeCss['button']} ${themeCss['FilledWhite']}`}>
          Send All Payment Reminder Emails
        </button>
      </p>

      {/* <div className={css['closed-auction-charge-selected']}>
        <p>
          {countAttendees()} ATTENDEES SELECTED
        </p>
        <button className={`${themeCss['button']} ${themeCss['FilledBlue']}`}>
          Charge Amount Due
        </button>

      </div> */}

        <p className={css['closed-auction-info']}>
          To charge a constituent for their item(s), click on "Manage Checkout" in the attendee table below.
        </p>

      <div className={css['closed-auction-attendee-filters']}>
        <div className={css['closed-auction-filter']}>
          <label>
            Attendee Name
          </label>
          <input type='text' value={nameFilter} onChange={(e) => setNameFilter(e.target.value)}/>
        </div>

        <div className={css['closed-auction-filter']}>
          <div className={css['closed-auction-filter-checkbox']}>
            <input type='checkbox' checked={showPaidFilter} onChange={(e) => setShowPaidFilter(!showPaidFilter)} />
            <label> Show Fully Paid Attendees</label>
          </div>
          <div className={css['closed-auction-filter-checkbox']}>
            <input type='checkbox' checked={showOweFilter} onChange={(e) => setShowOweFilter(!showOweFilter)} />
            <label> Show Attendees That Owe</label>
          </div>
        </div>

        {/* <div className={css['closed-auction-filter']}>
        <div className={css['closed-auction-filter-checkbox']}>
            <input type='checkbox' checked={showHasAltFilter} onChange={(e) => setShowHasAltFilter(!showHasAltFilter)} />
            <label> Show Alt. Payment</label>
          </div>
          <div className={css['closed-auction-filter-checkbox']}>
            <input type='checkbox' checked={showNoAltFilter} onChange={(e) => setShowNoAltFilter(!showNoAltFilter)} />
            <label> Show No Alt. Payment</label>
          </div>
        </div> */}

        <button className={`${themeCss['button']} ${themeCss['FilledYellow']}`} onClick={resetFilters}>
          Reset Filters
        </button>
      </div>

      <div className={css['closed-auction-table']}>
        <table>
          <thead>
            <tr className={css['closed-auction-table-header']}>
              {/* <th>
                <input type='checkbox' onChange={(e) => selectAllAttendees(e.target.checked)}
                  checked={selectedAttendees.length === attendeeArray.length}
                />
              </th> */}
              {tableFormatter.map((ele) => {
              return (
                <th onClick={() => handleSort(ele[0], sortOptions, setSortOptions)} key={ele[1]}>
                  {ele[1]} {sortSpan(ele[0], sortOptions)}</th>
                )})}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {displayedAttendees && displayedAttendees?.map((attendee) => {
              if (attendee.CurrentHighBidder) {
                return <ClosedBiddingRow key={attendee.CurrentHighBidder} 
                  toAttendeePage={toAttendeePage}
                  selectedAttendees={selectedAttendees} toggleCheckbox={toggleCheckbox}
                  attendee={attendee} auctionCode={auctionCode} />
              } else return <></>
            })}
          </tbody>
          <tfoot>
            <tr className={css['closed-auction-table-footer']}>
              <td></td>
              <td>Totals</td>
              <td>{totalDue}</td>
              <td>{totalPaid}</td>
              <td>{itemsPaidCount + itemsUnpaidCount}</td>
              {/* <td>{'alt total'}</td> */}
              <td></td>
              
            </tr>
          </tfoot>
        </table>
      </div>

      {unclaimedItems?.length > 0 && <div className={css['closed-auction-unclaimed']}>
        <h4>Unclaimed Items</h4>
        <ul>
          {unclaimedItems.map((item) => <li key={item.ItemId} >{item.Title}</li>)}
        </ul>
      </div>}
      
    </div>
  )
}