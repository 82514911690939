import styled from 'styled-components'

import { device } from 'common/Device'
import * as colors from 'css/Colors'
import { FilledYellow } from 'css/Buttons'

const defaultFontSize = 16

export const ReportsContainer = styled.div`
  position:relative;
  z-index:5;
  background:#FFFFFF;
  padding:${25/16}rem ${64/16}rem ${41/16}rem;
  margin:${30/16}rem 0 2rem;
  border-radius:4px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);

  .h3 {
    white-space:nowrap;
    margin-bottom:0;
    font-weight: bold;
  }

  @media ${ device.tablet } {
    margin-top:0;
    padding:${12/16}rem ${32/16}rem ${40/16}rem;
  }

  @media ${ device.mobile } {
    padding:${27/16}rem ${24/16}rem ${32/16}rem;
  }

  @media print {
    display:none;
  }
`

export const ReportsBlock = styled.div`
  padding-bottom: 1.5rem;
`

export const ReportsListing = styled.div`
  display:inline-block;
  min-width:85%;
  border:1px solid ${ colors.secondaryMediumBlue };
  border-radius:5px;
  padding:0 0 ${ 12 / 16 }rem;

  @media ${ device.tablet } {
    display:block;
    min-width:auto;
  }
  @media ${ device.mobile } {
    padding:${ 10 / 16 }rem 0;
  }
  .reports-row-wrapper:nth-child(odd) {
    background-color: rgb(246 246 246);
  }
}
`
export const RowWrapper = styled.div`
  padding:${ 5 / 16 }rem ${ 24.5 / 16 }rem ${ 5 / 16 }rem ;
}
`
export const YearDropdown = styled.div`
  margin: ${ 12/defaultFontSize }rem 0 ${ 18/defaultFontSize }rem  ${ 24.5 / 16 }rem;
  background-color:#F6F6F6;
  height:${ 40/defaultFontSize }rem;
  vertical-align:middle;
  width:50%;
  /* header */
  div {
    background-color:#F6F6F6;
    height:${ 40/defaultFontSize }rem;
    line-height:${ 38/defaultFontSize }rem;
    label {
      padding-right:${ 10/defaultFontSize }rem;
    }
    .report-year-dropdown {
      background-color:#F6F6F6;
    }
  }

  @media ${ device.mobile } {
    width:60%;
    align-items:center;
    margin-left:auto;
    margin-right:auto;
    margin-top:${ 15/defaultFontSize }rem
  }
`
export const LinkWrapper = styled.div`
  position:relative;
  z-index:2;
  @media print {
    display:none;
  }
`

export const ReportLink = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  background:transparent;
  font-weight:bold;
  font-size:${ 24/16 }rem;
  color:${ colors.secondaryMediumBlue };
  padding:${ 5 / 16 }rem ${ 8 / 16 }rem ${ 5 / 16 }rem ${ 16 / 16 }rem;

  ${ FilledYellow } {
    margin-left:${ 56/16 }rem;
    width: ${ 150/16 }rem
  }

  @media ${ device.tablet } {
    font-size:${ 20/16 }rem;
    white-space:normal;
  }

  @media ${ device.mobile } {
    flex-direction:column;
    align-items:flex-start;
    padding:${ 24/16 }rem 0;
    width:${ 224 / 320 * 100}%;
    margin: 0 auto;

    ${ FilledYellow } {
      margin:${ 24/16}rem auto 0;
    }
  }
`
export const EndowmentSurvey = styled.div`
  @media ${ device.mobile } {
    button {
      font-size:${ 13/16 }rem;
    }
  }
}
`
export const RecognitionSelect = styled.div`
  display: flex;
  button {
    height:${ 42/defaultFontSize }rem;
    margin: ${ 11/defaultFontSize }rem 0 0 ${ 20/defaultFontSize }rem;
    @media ${ device.mobile } {
      width:61%;
      margin-top:auto;
      margin-bottom:10px;
      margin-left:1px; // fudging for the dropdown border (I think)
    }
  }
  @media ${ device.mobile } {
    display:block;
    width:100%;
    text-align:center;
  }
`
export const YearHeader = styled.h3`
  margin-bottom:0;
  font-size:${ 28/16 }rem;
  font-weight:600;
  color:#000000;
  padding:0 ${ 24.5 / 16 }rem 0;

  @media ${ device.tablet } {
    font-size:${ 22/16 }rem;
  }

  @media ${ device.mobile } {
    font-size:${ 18/16 }rem;
    margin-left:auto;
    margin-right:auto;
    width:70%;
  }
`
export const Divider = styled.div`
  padding-left:0;
  padding-right:0;
  margin-top:${ 16 / 16 }rem ;
  padding-bottom:${ 18 / 16 }rem ;
  margin-left: -1px;
  margin-right: -1px;
  border-top: 1px;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  border-style: solid;
  border-color: ${ colors.secondaryMediumBlue };
}
`
export const PastYearsContainer = styled.div`
  text-align:right;
  padding-bottom:10px ;
  span {
    padding-right:${ 30 / 16 }rem;
  }
}
`
export const PastYearsLink = styled.button`
  background: none;
  font-weight:bold;
  font-size:${ 24/16 }rem;
  color:${ colors.secondaryMediumBlue };
  border: none;
  cursor:pointer;
  color:${ colors.primaryBlue };
  &:hover {
    color:${ colors.secondaryMediumBlue };
  }
  @media ${ device.tablet } {
    font-size:${ 20/16 }rem;
  }
  @media ${ device.mobile } {
    font-size:${ 20/16 }rem;
  }
`
