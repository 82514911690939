import styled from 'styled-components'
import { device } from 'common/Device'

const defaultFontSize = 16

const OuterDiv = styled.div`
  min-height: 100vh;
  box-sizing:border-box;
  padding-bottom:${ 75/defaultFontSize }rem;

  @media ${ device.laptop } {
     padding-bottom:${ 120/defaultFontSize }rem;
  }

  @media ${ device.mobile } {
    min-height:auto;
    /* padding-bottom:${ 40/defaultFontSize }rem; */
    padding-bottom:0;
  }
`

export default OuterDiv
