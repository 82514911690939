import css from '../sass/explore.module.scss'
import { FilledBlue } from 'css/Buttons'

function Explore() {
  return (
    <section className={css.wrapper}>
      <h1 className="h2 black bold with-line line-yellow">Explore UCLA Alumni</h1>
      <ul className={css['explore-links']}>
        <li className={css['career-programs']}>
          <div className={css.copy}>
            <FilledBlue onClick={() => window.open('https://alumni.ucla.edu/alumni-career-engagement/')} isSmall={true}>Career Engagement</FilledBlue>
            <p>UCLA Alumni Affairs provides resources, opportunities and events for Bruins at all stages of your career.</p>
          </div>
        </li>
        <li className={css['alumni-networks']}>
          <div className={css.copy}>
            <FilledBlue onClick={() => window.open('https://alumni.ucla.edu/alumni-networks/')} isSmall={true}>Alumni Networks</FilledBlue>
            <p>The Bruin bond spans region, culture and UCLA affiliations. Join an alumni group that share your interests and strengthen your Bruin network.</p>
          </div>
        </li>
        <li className={css['alumni-events']}>
          <div className={css.copy}>
            <FilledBlue onClick={() => window.open('https://alumni.ucla.edu/upcoming-events/')} isSmall={true}>Alumni Events</FilledBlue>
            <p>From virtual career workshops to group hikes to game watch parties, these events for alumni, by alumni, appeal to your personal and professional interests.</p>
          </div>
        </li>
      </ul>
    </section>
  )
}

export default Explore
