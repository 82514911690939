import styled from 'styled-components'

import Zindex from 'css/z-indexLayers.js';

const defaultFontSize = 16

const LinkUserWrapper = styled.div`
.user-search-form{
    margin-top:${10/defaultFontSize}rem;
    display:flex;
    input{
      height:${50 / defaultFontSize}rem;
    border-radius:3px;
    }
}

.linked-image-wrapper{
  position:relative;

  img{
  position: absolute;
  background-color: #fff;
  z-index:${Zindex.zindexOverlaystackOrder1};
  transform:translateX(-50%);
  left:50%;
  top:40%;
  }

  ::after{
    content: "";
    position: absolute;
    top:0;
    left:50%;
    border-left: 1px solid #ccc;
    height: 100%;
  }
}`
export default LinkUserWrapper;