import { useState, useEffect, useContext } from 'react'
import { useSelector } from "react-redux"

import { CONTACT_STATES } from 'common/forms/data/States'
import { CONTACT_COUNTRIES } from 'common/forms/data/Countries'

import { getAuctionUser } from '../redux/selectors'
import css from './sass/address.module.scss'

// the old SignUp component with all the fields is in auctions/archive/SignUp.kjs
export default function CheckoutAddress(props) {
  console.log(props)

  const initialState = props.address || {}

  const [streetAddress1, setStreetAddress1] = useState(initialState.streetAddress1 || '')
  const [streetAddress2, setStreetAddress2] = useState(initialState.streetAddress2 || '')
  const [city, setCity] = useState(initialState.city || '')
  const [state, setState] = useState(initialState.state || 'CA')
  const [zipCode, setZipCode] = useState(initialState.zipCode || '')
  const [intlCity, setIntlCity] = useState(initialState.intlCity || '')
  const [country, setCountry] = useState(initialState.country || 'United States')
  const [address, setAddress] = useState(initialState || {})

  // const loggedInUser = useSelector(getLoggedInUser)
  // const theme = useContext(ThemeContext)

  const auctionUser = useSelector(getAuctionUser)

  const updateAddress = props.updateAddress

  useEffect(() => {
    console.log('CHANGE!')

    setAddress({
      streetAddress1,
      streetAddress2,
      city,
      state,
      zipCode,
      intlCity,
      country,
    })
  }, [streetAddress1, streetAddress2, city, state, zipCode, intlCity, country])

  useEffect(() => {
    if (country !== 'United States') {
      setState('')
      setZipCode('')
    }
  }, [country])

  useEffect(() => {
    console.log({address})

    updateAddress(address)
  }, [updateAddress, address])

  return (
    <>
      <p>Please provide some extra information so that we can process your payment.</p>

      <form onSubmit={() => {}}>

        <div className={css['input-item']}>
          <label>Street Address</label>
          <input type='text' id="auctionsProfileStreetAddress1Input"
            value={streetAddress1}
            onChange={e => setStreetAddress1(e.target.value)}/>
        </div>

        <div className={css['input-item']}>
          <label>Address Line 2 (optional)</label>
          <input type='text' id="auctionsProfileStreetAddress2Input"
            value={streetAddress2}
            onChange={e => setStreetAddress2(e.target.value)}/>
        </div>

        {country === 'United States' &&
          <>
            <div className={css['input-item']}>
              <label>City</label>
              <input type='text' id="auctionsProfileCityInput"
                value={city}
                onChange={e => setCity(e.target.value)}/>
            </div>

            <div className={css['input-item']}>
              <label>State/Province</label>
              <select id="auctionsProfileStateInput" onChange={e => setState(e.target.value)} value={state} >
                {CONTACT_STATES.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>

            <div className={css['input-item']}>
              <label>Zip/Postal Code</label>
              <input type='text' id="auctionsProfileZipCodeInput"
                value={zipCode}
                onChange={e => setZipCode(e.target.value)}/>
            </div>
          </>}

        {country !== 'United States' &&
          <div className={css['input-item']}>
            <label>City, Province, Postal Code*</label>
            <input type='text' id="auctionsProfileIntlCityInput"
              value={intlCity}
              onChange={e => setIntlCity(e.target.value)}/>
          </div>}

        <div className={css['input-item']}>
          <label>Country</label>
          <select id="auctionsProfileCountryInput" onChange={e => setCountry(e.target.value)} value={country} >
            {CONTACT_COUNTRIES.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>

      </form>
    </>
  )
}