import { Component } from 'react';
import { withRouter } from 'react-router';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';

import { doPostLogin } from "../postlogin";
import { validateCreatePassword } from "../validators"
import { showLoading } from 'services/util'

import { Label } from '../components/Label'
import { Input } from 'css/forms'
import * as cs from 'css/CommonStyle';
import { device } from 'common/Device';

const ResetPwdFinishFormContainer = styled.div`
width:430px;
margin:0 auto;

@media ${device.mobile}{
  width:100%;
}

`

class ResetPasswordFinish extends Component {
	state = {
		code: '',
		password: '',
		confirmPassword: '',
	}

	componentDidMount() {
		if (!this.props.location.state) this.props.history.push({ pathname: '/signin' });
	}

	validateForm = () => {
		let isValid = true;

		if (!this.state.code) {
			this.setState({ codeError: "Please enter the code we sent via email or text" });
			isValid = false;
		}

		const passwordProgress = validateCreatePassword(this.state.password);
		if (passwordProgress.passwordFailing) {
			this.setState(passwordProgress);
			isValid = false;
		}

		if (this.state.password !== this.state.confirmPassword) {
			this.setState({ confirmPasswordError: "Passwords don't match." });
			isValid = false;
		}

		return isValid;
	}

	handleSubmit = async (event) => {
		event.preventDefault();

		this.setState({ genericError: null, passwordError: null, codeError: null, confirmPasswordError: null });

		if (!this.validateForm()) {
			return;
		}

		showLoading(true);

		try {
			await Auth.forgotPasswordSubmit(this.props.location.state.username, this.state.code, this.state.password)
			await Auth.signIn(this.props.location.state.username, this.state.password);
			doPostLogin({username:this.props.location.state.username, password:this.state.password});
		}
		catch (err) {
			showLoading(false);
			this.setState({ genericError: err.message });
		}
	}

	setCode = (e) => {
		this.setState({ code: e.target.value })
	}
	setNewPwd = (e) => {
		this.setState({ password: e.target.value })
		this.showPasswordRules(e);
	}
	setConfrmPwd = (e) => {
		this.setState({ confirmPassword: e.target.value })
	}

	resendCode = async () => {
		showLoading(true);

		try {
			await Auth.forgotPassword(this.props.location.state.username)
			this.setState({ resendCodeMsg: "Your code has been re-sent." });
		}
		catch (err) {
			this.setState({ resendCodeMsg: err.message });
		}
  	showLoading(false);
	}

	showPasswordRules = (e) => {
		this.setState(validateCreatePassword(e.target.value));
	};

	hidePasswordRules = (e) => {
		this.setState({ passwordProgress: null });
	};

	render() {
		const username = this.props.location.state ? this.props.location.state.username : '';

		const checkmark = (<span>&#10004;</span>);
		const xmark = (<span>&#10008;</span>);

		return (
			<cs.CommonOuterDiv>


					<h3 id="pageTitle" className=" bold">Reset your password</h3>

				<cs.CommonInnerDiv>
					<cs.InnerContent>

						<div>
							<p>A verification code has been sent to <cs.TextSpan>{username}. </cs.TextSpan>
								Please insert the verification code below to verify. For security purposes, the code will expire within 30 minutes.</p>
						</div>

						<ResetPwdFinishFormContainer>

							<form onSubmit={this.handleSubmit}>

								<cs.TextInputdiv>
									{/* TOOD - I'm sure there's a better way to get the input labels to align left, but I can't figure it out */}
									<div className="alignLeft">
										<Label>Verification Code</Label>
										<div>
											<Input width="430px" className={this.state.codeError ? "error" : ""} onChange={this.setCode} name="verificationcode" placeholder="Enter Verification Code" />
										</div>
										{this.state.codeError &&
											<cs.ErrorSpan textAlign="right">
												<label >{this.state.codeError}</label>
											</cs.ErrorSpan>}
									</div>
								</cs.TextInputdiv>

								<cs.TextInputdiv>
									<div className="alignLeft">
										<Label className="alignLeft">New Password</Label>
										<div>
											<Input width="430px" className={this.state.passwordFailing ? "error" : ""} onChange={this.setNewPwd} onFocus={this.showPasswordRules} onBlur={this.hidePasswordRules} type='password' name="password" placeholder="Enter New Password" />
											{this.state.passwordProgress &&
												<cs.PasswordHintSpan>
													<label className={this.state.passwordProgress.lowercase ? "green" : ""}>
														{this.state.passwordProgress.lowercase ? checkmark : xmark} Password must contain a lower case letter<br />
													</label>
													<label className={this.state.passwordProgress.uppercase ? "green" : ""}>
														{this.state.passwordProgress.uppercase ? checkmark : xmark} Password must contain an upper case letter<br />
													</label>
													<label className={this.state.passwordProgress.hasnumber ? "green" : ""}>
														{this.state.passwordProgress.hasnumber ? checkmark : xmark} Password must contain a number<br />
													</label>
													<label className={this.state.passwordProgress.minlength ? "green" : ""}>
														{this.state.passwordProgress.minlength ? checkmark : xmark} Password must be at least 8 characters<br />
													</label>
												</cs.PasswordHintSpan>}
										</div>
									</div>
								</cs.TextInputdiv>

								<cs.TextInputdiv>
									<div className="alignLeft">
										<Label className="alignLeft">Confirm New Password</Label>

										<div>
											<Input width="430px" type='password' name="confirmPassword" placeholder="Confirm New Password"
											       className={this.state.confirmPasswordError ? "error" : ""}
											       onChange={this.setConfrmPwd}  />
											{this.state.confirmPasswordError &&
												<cs.ErrorSpan>
													<label >{this.state.confirmPasswordError}</label>
												</cs.ErrorSpan>}
										</div>

									</div>
								</cs.TextInputdiv>

								{this.state.genericError &&
									<cs.ErrorSpan>
										{/* TODO - adding a <br> to get some padding below the error message,
	                        remove when we align all the global elements */}
										<label className="bold">{this.state.genericError}</label>
									</cs.ErrorSpan>}

								<cs.FooterBtnDiv width="430px">
									<cs.SubmitBtn onClick={this.handleSubmit}>RESET PASSWORD</cs.SubmitBtn>
								</cs.FooterBtnDiv>

							</form>

						</ResetPwdFinishFormContainer>

					</cs.InnerContent>



				</cs.CommonInnerDiv>

				<cs.CommonFooter>
					<p>Haven’t received the verification email/text?</p>
					<a onClick={this.resendCode}><cs.TextSpan fontStyle="italic"><b> Resend </b><i className="fa fa-chevron-right" aria-hidden="true"></i></cs.TextSpan></a>
					{this.state.resendCodeMsg &&
						<p><label className="resendmsg">{this.state.resendCodeMsg}</label></p>}
				</cs.CommonFooter>

			</cs.CommonOuterDiv>
		)
	}
}

export default withRouter(ResetPasswordFinish)