import styled from 'styled-components';
import * as colors from 'css/Colors'

const desktopColumnWidth = 319
const desktopTotalWidth = 900


// TODO - we should move all these exported components to a shared component
export const SignInRegisterDiv = styled.div`
  flex:1 1 ${ desktopColumnWidth / desktopTotalWidth * 100 }%;

  .block-title{
    color:${colors.darkGray};
  }
}
`