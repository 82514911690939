import { NavLink } from "react-router-dom"

import AdminLinkIcon from 'images/admin-link-icon.svg'
import AdminAuditIcon from 'images/admin-audit-icon.svg'
import AdminUsersIcon from 'images/admin-users-icon.svg'
import AdminPendingListIcon from '../images/admin-pending-list-icon.svg'
import AdminBrowseIcon from 'images/admin-browse-icon.svg'
import { AdminList, AdminListItem } from 'admin/AdminViewListStyles'
import AdminDeleteIcon from 'images/x-red.svg'

const AdminViewList = (props) => {
  return (
    <AdminList >
      <ul>
        <hr />
        <div className={"divider"}>
          Shared Admin Tools
        </div>
        <hr />

        <AdminListItem>
          <NavLink className="bold" id="AdminLinkUserToCrmLink" activeClassName="selectedTab" to="/admin/linkusertocrm">
            <img src={AdminLinkIcon} alt='Link Donor icon'/>
            <div><span>LINK USER TO CRM</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold" id="AdminAuditLink"  activeClassName="selectedTab" to="/admin/history">
            <img className="admin-users-svg" src={AdminAuditIcon} alt='Admin Change History icon'/>
            <div><span>CHANGE HISTORY</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold" id="AdminUsersLink"  activeClassName="selectedTab" to="/admin/users">
            <img src={AdminUsersIcon} alt='Admin Users icon'/>
            <div><span>USERS</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold"  id="AdminUnconfirmedUserList"  activeClassName="selectedTab" to="/admin/unconfirmedusers">
            <img  src={AdminDeleteIcon} alt='Unconfirmed User List icon'/>
            <div><span>UNCONFIRMED USERS</span></div>
          </NavLink>
        </AdminListItem>

        <div className={"divider"}>
          UCLA1 Admin
        </div>
        <hr />

        <AdminListItem>
          <NavLink className="bold"  id="AdminPendingListLink"  activeClassName="selectedTab" to="/admin/pendinglist">
            <img  src={AdminPendingListIcon} alt='Pending List icon'/>
            <div><span>PENDING LIST</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold"  id="AdminUcla1IdLink"  activeClassName="selectedTab" to="/admin/ucla1id">
            <img  src={AdminBrowseIcon} alt='UCLA1 User ID icon'/>
            <div><span>SET UCLA1 USER ID</span></div>
          </NavLink>
        </AdminListItem>
      </ul>
    </AdminList>
  )
}

export default AdminViewList;
