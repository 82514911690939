import styled from 'styled-components'
import { device } from 'common/Device'
import Zindex from 'css/z-indexLayers.js';

const defaultFontSize = 16
const defaultPrintFontSize = 10
const printPreviewBannerHeight = 70

export const PrintPreviewBanner = styled.header`
  position:absolute;
  z-index:${parseInt(Zindex.zindexPopUpStackOrder2,10) + 1};
  width:100%;
  box-sizing:border-box;
  height:${ printPreviewBannerHeight/defaultFontSize }rem;
  top:0;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  padding:${ 10/defaultFontSize }rem ${ 30/defaultFontSize }rem;
  background:#FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);

  .header-image {
    width:${ 232 / defaultFontSize }rem;
  }

  button {
    margin-left: ${ 20/defaultFontSize }rem;
  }

  @media screen and ${ device.tablet } {
    padding:${ 10/defaultFontSize }rem ${ 20/defaultFontSize }rem;
  }

  @media screen and ${ device.mobile } {
    justify-content:flex-end;
    .header-image {
      display:none;
    }
  }
`

export const PrintPreviewFonts = styled.div`
  line-height:${ 14/defaultPrintFontSize };
  font-size:${ 18/defaultFontSize * 100}%;

  @media ${ device.tablet } {
    font-size:${ 14/defaultFontSize * 100}%;
  }

  @media print {
    font-size:${ defaultPrintFontSize/defaultFontSize * 100 }%;
  }
`

export const PrintContainer = styled.div`
  position:relative;
  top:${ printPreviewBannerHeight/defaultFontSize }rem;
  height:calc(100vh - ${ printPreviewBannerHeight/defaultFontSize }rem);
  overflow:scroll;
  z-index:${ Zindex.zindexPopUpStackOrder2 };

  @media print {
    position:static;
    overflow:auto;
    height:auto;
  }
`

const RecognitionSummaryFontFactor = 20;
export const RecognitionSummaryPrintPreviewFonts = styled.div`
  line-height:${ 14/defaultPrintFontSize };
  font-size:${ 18/RecognitionSummaryFontFactor * 100}%;

  @media ${ device.tablet } {
    font-size:${ 14/RecognitionSummaryFontFactor * 100}%;
  }

  @media print {
    font-size: ${70}%;
  }
`

export const PrintRecognitionSummaryPage = styled.div`
  box-sizing:border-box;
  margin:${ 20/defaultPrintFontSize }em 5mm ${ 55/defaultPrintFontSize }em 5mm;
  width:${ 95 }%;
  /* break-inside:avoid-page; */

  p {
    font-size: ${ 18/defaultFontSize * 100}%;
    margin-top:0;
  }
  footer {
    width:100%;
    text-align:right;
    margin:${ 5/defaultPrintFontSize }em;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    margin-top:${ 5/defaultPrintFontSize }em;
    font-size: ${ 32/defaultFontSize * 100}%;
    font-weight:600;
    span {
      font-size: ${ 9/defaultFontSize * 100}%;
      font-weight:normal;
      margin-top:.5em;
    }
    strong {
      color:#2774AE;
      margin-right: .5em;
      margin-left:${ 5/defaultPrintFontSize }em;
    }
  }

  @media print {
    margin-left:0;
    margin-right:0;
  }

  @media screen and ${ device.tablet } {
    /* had to eyeball this */
    width:${ 606 / 641 * 100}%;
  }

  @media screen and ${ device.mobile } {
    width:100%;
    padding:0 ${ 15/320 * 100 }%;

    margin:${ 16/defaultPrintFontSize }em auto ${ 35/defaultPrintFontSize }em;
  }
`
export const PrintBannerImage = styled.img`
  display:block;
  height:${ 52/defaultPrintFontSize }em;
  width:auto;

  @media screen and ${ device.mobile } {
    display:none;
  }
`

export const RecognitionSummaryReport = styled.table`
  border-collapse: collapse;
  tr {
    border: solid;
    border-width: 1px 0;
    border-color: lightgrey;
    page-break-inside:avoid;

    th {
      padding: 1em;
      margin-right: 1em !important;
      text-align:left;
    }
    th:nth-child(2) {
      white-space: pre;
    }
    th:nth-child(3) {
      white-space: pre;
    }
    th:nth-child(6) {
      padding-right: 0;
      white-space: pre;
    }
    th:nth-child(7) {
      padding-left: 0;
      padding-right: 0;
      text-align:center;
      white-space: pre;
    }
    th:nth-child(8) {
      padding-right: 0;
      text-align:right;
    }
    th:nth-child(9) {
      padding-right: 0;
      text-align:center;
    }
    th:last-child {
      white-space: pre;
      padding-right: 0;
      text-align:center;
    }
    td {
      padding: 1em;
      &.money {
        text-align: right;
      }
    }
    td:nth-child(6) {
      text-align: center;
    }
    td:nth-child(7) {
      padding-right: 2.5em;
    }
    td:nth-child(9) {
      padding-right: 1.5em;
    }
    td:last-child {
      padding-right: 2em;
    }
  }
`

export const PrintBanner = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  font-weight: 600;
  margin-bottom: ${ 20/defaultPrintFontSize }em;
  vertical-align: top;
  header {
    margin-top: ${ 5/defaultPrintFontSize }em;
    font-size: ${ 32/defaultFontSize * 100}%;
    strong {
      color:#2774AE;
    }
  }
`

export const ReportHeaderLeft = styled.div`
  display: inline-block;
  text-align: left;
  width: 50%;
  p {
    margin: ${ 5/defaultPrintFontSize }em 0 0 0;
    font-size: ${ 20/defaultFontSize * 100}%;
  }
`

export const ReportHeaderRight = styled.div`
  margin-top:${ 5/defaultPrintFontSize }em;
  display: inline-block;
  text-align: right;
  align-self:flex-end;
  header {
    margin-top: ${ 8/defaultPrintFontSize }em;
  }
  p {
    font-size: ${ 22/defaultFontSize * 100}%;
    margin: 0 0 ${ 2/defaultPrintFontSize }em 0;
  }
  strong {
    margin-left:${ 5/defaultPrintFontSize }em;
  }
`






