import styled from 'styled-components'

import { initializeDate } from 'services/util'
import { device } from 'common/Device'
import { darkGray } from 'css/Colors'
import DropDownMenu from 'common/forms/DropDown'

const defaultFontSize = 16

const SummaryBackground = styled.div`
  margin-top:${20/defaultFontSize}rem;
  max-width: 680px;
  border: 1px solid #DDDDDD;
  padding-top: calc(12rem/16);
  border-radius: 5px;
  @media screen and ${ device.tablet } {
    background:#FFFFFF;
    margin-top:${20/defaultFontSize}rem;
  }
  @media screen and ${ device.mobile } {
    width:100%;
  }
`

const ButtonSpacer = styled.div`
  display:block;
  margin:0 ${5/defaultFontSize}rem;
  text-transform:uppercase;
  vertical-align:middle;
  padding-right:${5/defaultFontSize}rem;
  &:last-child {
    margin-right:0;
  }
  @media screen and ${ device.mobile } {
    &:first-child {
      margin:0 0 ${5/defaultFontSize}rem;
    }
    margin:0;
  }
`

const SummaryStyles = styled.div`
  color:${ darkGray };
  font-weight:600;
  font-size:${ 14/defaultFontSize }rem;
  display:flex;
  justify-content:flex-end;
  align-items:center;
  padding-right:${10/defaultFontSize}rem;

  div:nth-child(2) {
    background-color:#F6F6F6;
    height:${ 40/defaultFontSize }rem;
    vertical-align:middle;
    /* header */
    div {
      background-color:#F6F6F6;
      height:${ 40/defaultFontSize }rem;
      line-height:42px;
      label {
        padding-right:${ 10/defaultFontSize }rem;
      }
    }
  }
  @media screen and ${ device.mobile } {
    padding-left:${15/defaultFontSize}rem;
    display:block;
  }
`

const Subtext = styled.div`
  display:block;
  color:#666;
  vertical-align:top;
  font-size:${defaultFontSize-4}px;
  padding:${2/defaultFontSize}rem ${8/defaultFontSize}rem ${10/defaultFontSize}rem ${8/defaultFontSize}rem;
  text-align:right;
`

const Spacer = styled.div`
  height:${16/defaultFontSize}rem;
`

function PrintReceipts(props) {

  const onPrintReceiptsForYear = (e) => {
    props.printReceiptsForYear(e.target.value)
  }

  const onprintRecognitionSummary = (e) => {
    const year = e.target.value !== 'Lifetime recognition' ? e.target.value : ""
    props.printRecognitionSummary(year)
  }

  const currentYear = initializeDate().getFullYear()
  const printReceiptYears = [currentYear, currentYear-1, currentYear-2]

  const recognitionSummaryYears = Array.from({length: currentYear-(props.donorSinceYear-1)}, (x, i) => currentYear-i)
  recognitionSummaryYears.unshift('Lifetime Recognition')

  return (
    <SummaryBackground>

      <SummaryStyles>
        <ButtonSpacer>Print recogniton summary for year</ButtonSpacer>

         <DropDownMenu name="printRecognitionSummary"
          title={'Choose a year to print'}
          list={recognitionSummaryYears}
          changeHandler={onprintRecognitionSummary}
          specialtype
         />

      </SummaryStyles>
      <Subtext>(includes both tax-deductible and non-tax-deductible giving)</Subtext>

      <Spacer />

      <SummaryStyles>
        <ButtonSpacer>Print all tax receipts for year</ButtonSpacer>

         <DropDownMenu
          id="printAllReceipts"
          name="printAllReceipts"
          title={'Choose a year to print'}
          list={printReceiptYears}
          changeHandler={onPrintReceiptsForYear}
          specialtype
         />
      </SummaryStyles>
      <Subtext>(includes only tax-deductible giving)</Subtext>

    </SummaryBackground>
  )
}

export default PrintReceipts
