import { useState, useEffect } from 'react'

import adminCss from './sass/adminpage.module.scss'

export default function AuctionStatusAlert({ liveStatus, auctionStatus}) {

  const [alertMessage, setAlertMessage] = useState()
  const [isGreen, setIsGreen] = useState(false)

  useEffect(() => {

    switch(auctionStatus) {
      case "CLOSED":
        setAlertMessage('AUCTION CLOSED')
        setIsGreen(false)
        break;
      case "PUBLIC":
        if (liveStatus === 'started') {
          setAlertMessage('AUCTION PUBLIC BIDDING LIVE')
          setIsGreen(true)
        } else if (liveStatus === 'ended') {
          setAlertMessage('AUCTION ENDED')
          setIsGreen(false)
        } else {
          setAlertMessage('AUCTION PUBLIC AND UPCOMING')
          setIsGreen(true)
        }
        break;
      case "PREVIEW":
        setAlertMessage('AUCTION IN PREVIEW MODE')
        setIsGreen(false)
        break;
      default:
        setAlertMessage('Error loading Auction State')
        setIsGreen(false)
    }

  }, [liveStatus, auctionStatus])

  return (
    <div className={adminCss['admin-auction-page-tab-container']}>
        <p className={`${adminCss['status-alert']} ${isGreen ? adminCss['status-alert-green'] : ''}`}>
          {alertMessage}
        </p>
    </div>
  )
}