import { formatMoneyCents } from 'services/util'

import greenCheck from '../components/images/check-circle.svg'

import css from './sass/mypages.module.scss'

export default function MyItemRow({item}) {

  return (
    <div className={css['myitems-row']}>
      <h3 className={css['mypages-headings']}>{item.Title}</h3>
      <div className={css['myitems-amount']}>
        <div className={css['myitems-amount-left']}>
          <p>Winning Bid</p>
          <h2 className={css['mypages-headings']}>{`$${formatMoneyCents(item.CurrentBid)}`}</h2>
        </div>
        <div className={css['myitems-amount-right']}>
          {item.Paid && <>
              <img className={css['myitems-paid-icon']} src={greenCheck} />
              <p className={css['myitems-paid-text']}>Paid</p>
            </>
          }
        </div>
      </div>
    </div>
  )
}