import { useEffect, useState, useContext } from 'react'
import { useMutation, useQuery } from "@tanstack/react-query"

import { history } from 'services/history.js'
import { showLoading } from 'services/util'
import { formatMoneyCents } from 'services/util'
import { ThemeContext } from 'ThemeContext'

import { getAuctionByCode, graphql } from '../api'
import { urlQueryParser } from '../util/urlQueryParser'
import { checkoutRouter } from './checkoutUtils'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/checkout.module.scss'

import { useSelector } from "react-redux"
import { getAuctionUser } from '../redux/selectors'
import CheckoutHeader from './CheckoutHeader'
import { makePaymentMutation } from '../graphql/Mutations'


export default function CheckoutConfirm(props) {
  //destructuring auction code from url
  //called id in url query for that convention, but using auction code in aws to declutter url from being massive from aws UUID for auction ID to link tables
  const { auctionId: auctionCode } = urlQueryParser(props.location.search)

  const theme = useContext(ThemeContext)

  // const [auction, setAuction] = useState()
  const [myItems, setMyItems] = useState()
  const [displayTotal, setDisplayTotal] = useState()
  const [intTotal, setIntTotal] = useState()
  const [errorDisplay, setErrorDisplay] = useState()

  const auctionUser = useSelector(getAuctionUser);
  const genericErrorMessage = 'Your payment was not processed. Please try again or contact support at ' + theme.supportEmail + '.'

  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, error, isLoading } = useQuery({
    queryKey: ["auctionDetail", auctionCode],
    queryFn: async () => {
      return await getAuctionByCode(auctionCode)
    },
    staleTime: 0
  })

  const {data: makePaymentResponse, error: paymentError, isPending: paymentIsLoading, mutate: makePayment } = useMutation({
    mutationFn: (input) => {
      console.log('input', input)
      return graphql({query: makePaymentMutation, variables: {...input} })
    },
  })

  // use effect for query results
  useEffect(() => {
    showLoading(isLoading)

    if (error || (auctionDetail && auctionDetail.error)) {
      console.log('Error!', error || auctionDetail.error)
    }

  }, [auctionDetail, error, isLoading])


  useEffect(() => {
    if (auctionDetail) {
      const pulledItems = auctionDetail.Items.filter((item) => item.CurrentHighBidder === auctionUser.UserId)
      const filteredItems = pulledItems.filter((item) => item.Paid !== true)
      setMyItems(filteredItems)

    }
  }, [auctionDetail, auctionUser.UserId])
  
  useEffect(() => {
    if (myItems) {
      const total = myItems?.reduce(((acc, item) => acc += item.CurrentBid), 0)
      setDisplayTotal(formatMoneyCents(total))
      setIntTotal(1*total)
    }

  }, [myItems])

  //payment error use effect
  useEffect(() => {
   showLoading(paymentIsLoading || false)

   if (paymentError) {
      console.log("Payment Error: ", paymentError)
      console.log(paymentError.errors[0]?.message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
      setErrorDisplay(paymentError.errors[0]?.message || genericErrorMessage)
    }
    else if (makePaymentResponse && !makePaymentResponse.data?.makePayment?.Success) {
      setErrorDisplay(makePaymentResponse?.data?.makePayment?.ErrorMsg || genericErrorMessage)
    }
    else if (makePaymentResponse) {
      console.log(makePaymentResponse)
      console.log('Payment Response: ', makePaymentResponse.data)

      history.push({
        pathname: '/checkoutsuccess',
        search: `?auctionId=${auctionCode}`,
        displayTotal
      })
    }
  }, [makePaymentResponse, paymentError, paymentIsLoading, auctionCode, genericErrorMessage])

  const processPayment = async () => {
    showLoading(true)

    const input = {
      auctionId: auctionDetail.AuctionId,
      paymentMethodDateAdded: auctionUser.PaymentMethods[0].DateAdded,
      totalAmount: intTotal
    }

    if (props.location?.state?.address) {

      const address = props.location.state.address

      input.address = {
        Street1: address.streetAddress1,
        Street2: address.streetAddress2,
        City: address.city || address.intlCity,
        State: address.state,
        PostCode: address.zipCode,
        Country: address.country,
      }
    }

    console.log({input, myItems})

    const paymentResponse = await makePayment(input)

    console.log({paymentResponse})
  }

  if (!auctionDetail || !myItems) return (<></>)
  
  return (
    <div className={css['checkout-container']}>

      <CheckoutHeader auctionCode={auctionDetail.AuctionCode} />

      <p className={css['confirm-header']}>Confirm Payment</p>
      <p className={css['confirm-text']}>Please confirm the payment of the following amount:</p>
      <p className={css['confirm-amount']}>${displayTotal}</p>

      <div className={css['checkout-linebreak']}></div>

      <div className={css[`checkout-payment${auctionUser.PaymentMethods[0].Last4 ? '' : '-hidden'}`]}>
        <h5>Payment Method</h5>
        <h6>VISA •••{auctionUser?.PaymentMethods[0].Last4}</h6>
      </div>

      {auctionUser.PaymentMethods[0].Last4 && <div className={css['checkout-linebreak']}></div>}

      <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${css['checkout-paybutton']}`}
          onClick ={() => processPayment()}>
        {`Confirm $${displayTotal} Payment`}
      </button>

      <button className={`${themeCss['button']} ${themeCss['StrokeBlue']} ${css['checkout-paybutton']}`}
          onClick ={() => checkoutRouter(auctionCode)}>
        Cancel
      </button>

      {errorDisplay &&
        <div className={themeCss['error-text']}>
          {errorDisplay}
        </div>}

    </div>
  )
}