import { useState, useEffect } from 'react'

import store from 'redux/store';
import { getCrmUser } from 'redux/selectors';
import { ceirCurrentYear } from '../reports/documentsapi'
import css from './sass/alert.module.scss'

// set persistent localStorage item to a combination of CEIR year (since new brochure is available to all), and string of user's current available reports
// if anything changes, we show banner again
function ReportsNotification(props) {
  const [hidden, setHidden] = useState(false);

  const crmUser = getCrmUser(store.getState())
  const hideState = "ceirCurrentYearAtBannerClose="+ceirCurrentYear + ", documentsAtBannerClose=" + JSON.stringify(crmUser.documents)

  useEffect(() => {
    if (hideState === localStorage.getItem('hideReportsNotification'))
      setHidden(true)
  }, [hideState])

  const hide = (e) =>{
    setHidden(true)
    localStorage.setItem('hideReportsNotification', hideState);
  }

  return (
    <>
    {!hidden && (
      <div className={`wrapper ${css.alertBannerGrid}`}>
        <div className={css.alertBannerContainer}>
          { props.children }
          <div className={css.alertBannerOuterDiv}>
            <button className={css.CloseNotificationButton} onClick={e => hide(e)}></button>
          </div>
        </div>
      </div>
    )}
    </>
  );
}

export default ReportsNotification;
