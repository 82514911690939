import { useContext } from 'react'

import { showLoading } from 'services/util'
import styled from 'styled-components'

import { ThemeContext } from 'ThemeContext';

import * as cs from 'css/CommonStyle'
import * as forms from 'css/forms'

const AuthenticationConatiner = styled.div`
  display:flex;
`

const StyledLink = styled.a`
  display: block;
  position: relative;
  padding: 15px 10px;
  font-size: 16px;
  text-align: left;
  font-family: Arial;
  font-weight: normal;
  text-decoration: none;
  color: ${ props => props.color || "#005587"};
  background-position: 10px 3px;
  width:100%;

`
const StyledLinkDiv = styled.div`
  flex:0 0 ${props => !props.socialmedia ? "72px" : "50px"};
  height:100%;
  background: ${ props => props.bgColor || ""} no-repeat url(${props => props.icon}); ;
  border-right: 1px solid #2774AE;
  & > img{
    padding:13px;
    display:block;
    margin:0 auto;
  }
`

const SocialMediaInput = styled(cs.TextInputdiv)`
  display:inline-block;
`

const AuthenticationLink = props => {
  const theme = useContext(ThemeContext)

  return (
    <AuthenticationConatiner>
      <StyledLinkDiv socialmedia={props.socialmedia} bgColor={props.bgColor}>
        <img src={props.icon} alt="Social icons" />
      </StyledLinkDiv>

      {!props.socialmedia ? <StyledLink href={props.oauthUrl} id={props.id}  onClick={() => {
        localStorage.setItem('socialLoginSource', props.loginMethod);

        theme.themeAnalytics.analyticsInitStorage({
          loginMethod: props.loginMethod,
          entryPath: props.isRegistration? 'registration' : 'signin'
        })
        if (props.isRegistration) {
          theme.themeAnalytics.analyticsRegistrationStep('verify')
        }

        showLoading(true)
      }} color={props.color} >
        {props.linkText}
      </StyledLink>
        : <SocialMediaInput>
          <forms.Input width="300px"></forms.Input>
        </SocialMediaInput>}
    </AuthenticationConatiner>
  )
}

export default AuthenticationLink
