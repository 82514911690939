import { history } from 'services/history.js'
import { showLoading } from 'services/util'

import { useSelector } from "react-redux"
import { getAuctionUser } from '../redux/selectors'

import css from './sass/checkout.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import CheckoutHeader from './CheckoutHeader'


export default function CheckoutBraintree( props ) {

  const {auctionCode, myTotal} = props.location  
  const auctionUser = useSelector(getAuctionUser);
  
  return (
    <div className={css['checkout-container']}>

      <CheckoutHeader auctionCode={auctionCode} />

      <a
          onClick ={(e) => {history.push({
            pathname: '/checkout',
            search: `?auctionId=${auctionCode}`,
          })}}
          >Back to Cart</a>

      <p>Braintree payment screen (iframe)</p>

      <button
          onClick ={(e) => {history.push({
            pathname: '/checkoutsuccess',
            search: `?auctionId=${auctionCode}`,
          })}}
          className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['button-to-checkout']}`}
        >Fake Successful Payment</button>




    </div>
  )
}




