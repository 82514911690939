export const LIFETIME_RECOGNITION =
"This amount is for recognition purposes only and represents your total contributions to UCLA. Your recognition total includes gifts, pledges, planned gifts, gifts in kind, and matching gifts, as well as any gifts for which you may have received associated credit."

export const CURRENT_YEAR_TAX_DEDUCTIBLE =
`This amount represents donations made during the ${localStorage.currentYear} calendar year for which you, as the legal donor, can receive a tax deduction. The tax-deductible amount reflects all of your legal giving to UCLA, with the exception of real estate transactions, contributions to a donor advised fund managed by UCLA, pledges, and in-kind gifts.`

export const PREVIOUS_YEAR_TAX_DEDUCTIBLE =
`This amount represents donations made during the ${1*localStorage.currentYear - 1} calendar year for which you, as the legal donor, can receive a tax deduction. The tax-deductible amount reflects all of your legal giving to UCLA, with the exception of real estate transactions, contributions to a donor advised fund managed by UCLA, pledges, and in-kind gifts.`

export const NON_RECEIPTABLE_MESSAGES = {
  // STOCK is the default if no code matches
  "STOCK": "This gift cannot be receipted through My Giving Gateway. Please contact MyGivingGateway@support.ucla.edu or (310) 794-2447 for assistance.",

  "JCCF": "Receipts for gifts to UCLA’s Jonsson Cancer Center Foundation (JCCF) are not available online. Please contact (310) 206-0675 for assistance.",

  "HAMMER": "Receipts for gifts to the Hammer Museum are not available online. Please contact info@hammer.ucla.edu or (310) 443-7000 for assistance.",

  "PLANNEDGIFT": "Receipts for payments on planned gifts are issued by the Office of Planned Giving. Please contact (310) 794-2334 for assistance.",

  "RECURRINGGIFT": "Recurring gifts are paid automatically using the information provided when the gifts were set up. To make a change to a recurring gift, please contact MyGivingGateway@support.ucla.edu or (310) 794-2447 at least five business days before the next payment is scheduled to occur.",

  "OUTSIDEDATERANGE": "Receipts can only be printed for gifts you personally have made within the past two calendar years. Please contact MyGivingGateway@support.ucla.edu or (310) 794-2447.",

  "PLEDGE": "Receipts cannot be generated for pledges because no money has been received. If you would like a receipt for a specific pledge payment, please click on the receipt icon next to that payment. If you have questions or need assistance, please contact MyGivingGateway@support.ucla.edu or (310) 794-2447.",

  "NOTLEGALDONOR": "Receipts can only be printed by the legal donor of a gift. This gift was made by another entity, and only that person/organization can print the receipt. Please contact MyGivingGateway@support.ucla.edu or (310) 794-2447 with any questions.",

  "GIFTINKIND": "Receipts cannot be generated for gifts in kind. For a copy of the deed of gift provided at the time the gift was made, please contact MyGivingGateway@support.ucla.edu or (310) 794-2447.",

  "INTERNALDAF": "Gifts made via donor advised funds are not receipted, as the tax benefit was incurred at the time the original gift was made. Please contact MyGivingGateway@support.ucla.edu or (310) 794-2447 with any questions.",

  "IRA": "Receipts for gifts from an IRA are issued by the Office of Planned Giving. Please contact (310) 794-2334 for assistance.",
}
