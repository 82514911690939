import { history } from 'services/history.js'

import css from '../sass/flow.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

export default function AuctionReg() {

  // if (!auctionDetail) return
  const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'))
  const userAttributes = loggedInUser.cognitoUser.attributes

  console.log('checking if email is verified!')
  //fake to test
  // userAttributes.email_verified = false

  //checking if the user has a verified email attached to account
  if (userAttributes.email_verified) {
    history.push({ pathname: '/flow/inviteeonlyrouter' })
  }

  if (userAttributes.email_verified === false) {
    history.push({ pathname: '/flow/emailverification' })
  }

  return (

    <div className={css['auction-email-buttons']}>
      <button 
        onClick ={(e) => {history.push({ pathname: '/flow/inviteeonlyrouter' }) }}
        className={`${themeCss['FilledBlue']} ${themeCss['button']}`}>Email Found? Click Here</button>
      
      <button 
        onClick ={(e) => {history.push({ pathname: '/flow/emailverification' }) }}
        className={`${themeCss['FilledBlue']} ${themeCss['button']}`}>Email Not Found? Click Here</button>
    </div>
  )
}