import { useEffect, useState} from 'react'

import { showLoading } from 'services/util'
import { history } from 'services/history.js'

import { urlQueryParser } from '../../util/urlQueryParser'
import { getPublicAuctionDetail } from '../../api'
import { getImageUrl } from 'sites/auctions/util/images'
import CustomMarkdown from 'sites/auctions/components/CustomMarkdown'

import css from '../sass/flow.module.scss'
import themeCss from '../../components/sass/theme.module.scss'
import { HeaderGradient } from 'common/header/HeaderStyles'


export default function AuctionFromEmail(props) {
  const [auctionDetail, setAuctionDetail] = useState()
  const [userExists, setUserExists] = useState(false)
  const [imageUrl, setImageUrl] = useState()

  const {
    auctionId: auctionCode,
    publicId,
    email,
  } = urlQueryParser(props.location.search)
  
  if (email) localStorage.setItem('savedUsername', email)
  localStorage.setItem('savedAuctionCode', auctionCode)

  useEffect(() => {
    if (!auctionCode)
      return

    showLoading(true)

    const getDetails = async () => {
      const {success, error} = await getPublicAuctionDetail(auctionCode, publicId, email)
      console.log({success, error})
      showLoading(false)

      if (error || !success || !success.auction || success.auction.error) {
        console.log('Error!', error ||  success?.auction?.error || "No auction found")
      }
      else if (success.auction) {
        setAuctionDetail(success.auction)
        setUserExists(success.userExists)
      }
    }

    getDetails()

  }, [auctionCode, publicId, email])

    // use effect for auction banner image
    useEffect(() => {
      const url = getImageUrl(auctionDetail?.AuctionCode, 'banner', auctionDetail?.BannerImageName)
      setImageUrl(url)
    }, [auctionDetail])

  if (!auctionDetail)
    return <p>Auction Not Found</p>

  // TODO: adding HeaderGradient here because they want the UCLA logo, there might be a cleaner way but right now
  // the site doesn't have a way to hide part of the header. So we hide the whole thing and add it back here.
  console.log({auctionDetail})

return (
    <>
      <HeaderGradient />
      <div className={css['auction-email-container']}>
        <h1 className={css['auction-email-container-header']}>{auctionDetail.Title}</h1>
        <div className={themeCss['blue-background']}>
          {imageUrl ?
           <div className={css['auction-email-banner-container']}><img className={css['auction-email-banner']} src={`${imageUrl}`} alt={'Auction Banner'} /></div>
           :
           <p className={css['auction-email-banner-undefined']}>No auction image saved, need to upload one inside Admin panel.</p>
          }
          <CustomMarkdown text={auctionDetail.Description} cssClass={css['auction-email-description']} />


            <div className={css['auction-email-buttons']}>
            <button
              onClick ={(e) => {history.push({
                pathname: userExists || localStorage.savedAuctionCode ? '/signin' : '/registration',
              })}}
              className={`${themeCss['FilledYellow']} ${themeCss['button']}`}>Sign In for Auction</button>
          </div>
        </div>
      </div>
    </>
  )
}