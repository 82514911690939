import styled from 'styled-components'

import Zindex from 'css/z-indexLayers.js';
import * as cs from 'css/CommonStyle'
import * as forms from 'css/forms'
import * as logon from 'authentication/css/AuthenticateStyles'
import MoreInfo from 'common/MoreInfo'
import { device } from 'common/Device'

const defaultFontSize = 16

const SocialMediaContent = styled.div`
  &&&{
  flex:0 48%;
  margin:5px;
  }
  & label{
  	margin-right:10px;
  }

  .social-media-icons{
  	height:54.11px;
  }
  .social-media-info-icon{
      margin-left:10px;
  }
  .social-url-text-input{
      padding-left:60px;
  }
`

const ImageOverlay = styled(logon.ImgLink)`
  display: flex;
  align-items: center;
  vertical-align: middle;
  width:50px;
  border-radius: 2px;
  z-index:${Zindex.zindexInlineLayer};
  background-image:${props => props.background ? `url("${props.background}")` : "none"};
  & >img{
  	margin: 0 auto;
  	padding:0;
  }
`

export const MoreInfoInnerStyles = styled.div`
  display:inline-block;
  vertical-align:middle;
  padding:${ 8/defaultFontSize }rem;

  img {
    display:block;
    width:auto;
    height:100%;
    margin:0 auto;
  }

  @media ${ device.mobile } {
    padding:${ 2/defaultFontSize }rem 0;

    img {
      max-width:100%;
    }
  }
`

const Social = (props) => (
  <SocialMediaContent>
    <div>
      <label>{props.title}</label>

      <MoreInfoInnerStyles>
        <MoreInfo
          expandedForm={ true }
          htmlName={props.htmlName}
          htmlId={props.htmlId}
          srText={props.srText}
          gutterRef={ props.gutterRef }
        >
          <img src={props.icon} alt='finding social media url/handle' />
        </MoreInfo>
      </MoreInfoInnerStyles>
    </div>

    <ImageOverlay className="social-media-icons" bgColor={props.bgColor} background={props.background}>
      <img src={props.socialMediaIcon} alt="Social Icon" />
    </ImageOverlay>

    <cs.TextInputdiv>
      <forms.Input
        className="social-url-text-input"
        placeholder={props.placeholder}
        id={props.keyName}
        name={props.keyName}
        value={props.url || ""}
        onChange={props.changeHandler}
      />
    </cs.TextInputdiv>


  </SocialMediaContent>

)

export default Social;
