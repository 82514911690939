import styled from 'styled-components';

const Styles=styled.div`
  span:nth-child(2) {
    margin:0 30px;
  }
`

const TaxIdNumber = () => (
  <Styles>
    <p> <strong>UCLA Foundation:</strong> 95-2250801</p>
    <p> <strong>UC Regents:</strong> 95-6006143W</p>
  </Styles>
)

export default TaxIdNumber