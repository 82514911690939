// dummy analytics file to feed to sites not using analytics and tests

export const analyticsPageLoad = () => {
}

export const analyticsInitStorage = () => {
}

export const analyticsLogin = () => {
}

export const analyticsFailedLogin = () => {
}

export const analyticsLoginConsolidate = () => {
}

export const analyticsLoginNoHistory = () => {
}

export const analyticsRegistrationStep = () => {
}

export const analyticsUpdateMyInfo = () => {
}

// DONOR PORTAL
export const analyticsGiveAgain = () => {
}

export const analyticsPayNow = () => {
}

export const analyticsYearReceipt = () => {
}

export const analyticsSingleReceipt = () => {
}

export const analyticsContactUs = () => {
}
