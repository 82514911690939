import { useEffect, useState } from 'react'
import { useQuery } from "@tanstack/react-query"

import { history } from 'services/history.js'
import { showLoading } from 'services/util'
import { formatMoneyCents } from 'services/util'
import { getLoggedInUser } from "redux/selectors"

import { getAuctionByCode } from '../api'
import { urlQueryParser } from '../util/urlQueryParser'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/checkout.module.scss'

import { useSelector } from "react-redux"
import { getAuctionUser } from '../redux/selectors'
import CheckoutItem from './CheckoutItem'
import CheckoutHeader from './CheckoutHeader'
import CheckoutAddress from './CheckoutAddress'


export default function CheckoutAuction(props) {
  //destructuring auction code from url
  //called id in url query for that convention, but using auction code in aws to declutter url from being massive from aws UUID for auction ID to link tables
  const { auctionId: auctionCode } = urlQueryParser(props.location.search)

  const [myItems, setMyItems] = useState()
  const [myTotal, setMyTotal] = useState()
  const [userAddress, setUserAddress] = useState(props.location?.state?.address || {})
  const [isValidAddress, setIsValidAddress] = useState(false)

  const auctionUser = useSelector(getAuctionUser)
  const loggedInUser = useSelector(getLoggedInUser)

  const [lastFour, setLastFour] = useState()

  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, error, isLoading } = useQuery({
    queryKey: ["auctionDetail", auctionCode],
    queryFn: async () => {
      return await getAuctionByCode(auctionCode)
    },
    staleTime: 0
  })
  // use effect for query results
  useEffect(() => {
    showLoading(isLoading)

    if (error || (auctionDetail && auctionDetail.error)) {
      console.log('Error!', error || auctionDetail.error)
    }

  }, [auctionDetail, error, isLoading])

  useEffect(() => {
    if (auctionDetail) {
      // setAuction(auctionDetail)
      const pulledItems = auctionDetail.Items.filter((item) => item.CurrentHighBidder === auctionUser.UserId)
      const filteredItems = pulledItems.filter((item) => item.Paid !== true)
      setMyItems(filteredItems)

    }
  }, [auctionDetail, auctionUser.UserId])
  
  useEffect(() => {
    if (myItems) {
      const total = myItems?.reduce(((acc, item) => acc += item.CurrentBid), 0)
      setMyTotal(formatMoneyCents(total))
    }

  }, [myItems])

  useEffect(() => {
    if (auctionUser?.PaymentMethods?.length > 0) {
      setLastFour(auctionUser.PaymentMethods[0].Last4)
    } else {
      setLastFour(undefined)
    }
  }, [auctionUser])

  useEffect(() => {

    // for international addresses, all state and postal code is in the city field
    if (userAddress.country !== 'United States') {
      setIsValidAddress(userAddress.streetAddress1 && userAddress.intlCity)
    }
    else {
      setIsValidAddress(userAddress.streetAddress1 && userAddress.city && userAddress.state && userAddress.zipCode)
    }
  }, [userAddress])

  const redirectPath = () => {
    const historyObj = {
      pathname: '/checkoutconfirm',
      search: `?auctionId=${auctionDetail.AuctionCode}`,
      state: {}
    }

    if (missingAddress())
      historyObj.state.address = userAddress

    return historyObj
  }
  
  const missingAddress = () => {
    return (!auctionUser.CrmId
            || (!loggedInUser.crmUser?.profile?.homeAddress && !loggedInUser.crmUser?.profile?.businessAddress))
  }

  const updateAddress = (address) => {
    console.log({address})
    setUserAddress(address)
  }

  if (!auctionDetail || !myItems) return (<></>)
  
  return (
    <div className={css['checkout-container']}>

      <CheckoutHeader auctionCode={auctionDetail.AuctionCode} />
        
      <div className={css['checkout-textline-medium']}>
        <h5>Items Won</h5>
        <p>{`${myItems.length} item${myItems.length === 1 ? '' : 's'}`}</p>
      </div>

      {myItems && myItems.map((item) => {
        return(
          <CheckoutItem item={item} auction={auctionDetail} />
        )
      })}

      <div className={css['checkout-linebreak']}></div>

      <div className={css['checkout-textline-medium']}>
        <h5>Total</h5>
        <p className={css['checkout-total']}>{`$${myTotal}`}</p>
      </div>

      <div className={css['checkout-linebreak']}></div>

      {lastFour && <div className={css[`checkout-payment`]}>
        <h5>Payment Method</h5>
        <h6>VISA •••{lastFour}</h6>
        <button className={css['checkout-payment-different']}
          onClick ={(e) => {history.push({
            pathname: '/paymentupdate/checkout',
            search: `?auctionId=${auctionDetail.AuctionCode}`,
            state: {address: userAddress}
          })}}
          >Enter Different Credit Card</button>
      </div>}

      {!lastFour && <div className={css[`checkout-payment`]}>
        <h5>No payment method is on file</h5>
        <h6>Follow below link to add a payment method.</h6>
        <button className={css['checkout-payment-different']}
          onClick ={(e) => {history.push({
            pathname: '/paymentupdate/checkout',
            search: `?auctionId=${auctionDetail.AuctionCode}`,
            state: {address: userAddress}
          })}}
          >Enter Credit Card</button>
      </div>}

      {lastFour && <div className={css['checkout-linebreak']}></div>}

      {missingAddress() &&
        <CheckoutAddress address={userAddress} updateAddress={updateAddress}/>}

      <p>
        To set up alternate payment methods besides using a credit card, you must contact the auction manager to process the alternate payment(s).
      </p>
      <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${css['checkout-paybutton']}`}
          disabled={!lastFour || (missingAddress() && !isValidAddress)}
          onClick ={(e) => {history.push(redirectPath())}}
          >
        {`Pay $${myTotal}`}
      </button>

    </div>
  )
}