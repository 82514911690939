// for indicating alumni membership levels
import css from '../sass/membership.module.scss'
function Membership({
  membershipLevel,
  joinDate,
}) {

  // fix date value to just the last two digits of the year
  const displayYear = (membershipLevel) ? joinDate.split('-')[0].slice(-2) : ''

  function printCard(e) {
    window.print()
  }

  return (
    <div className={css.level} id="membershipLevel">
      { membershipLevel &&
        <>
          <em className="label">Membership Level</em>
          { membershipLevel } since &rsquo;{ displayYear }
        </>}

      <div className={css.cards}>
        { membershipLevel &&
          <button id="printCardButton" className={'button is-small is-blue'} onClick={printCard}>Member ID</button>}
        { membershipLevel && (membershipLevel === "Gold" || membershipLevel === "Life") && (
          <button id="replacementCardButton"
            className={'button is-small is-blue'}
            onClick={ e=> {
              window.location="mailto:alumni@alumni.ucla.edu?subject=Request to order a replacement card"
            }}
          >
            Order a replacement card
          </button>
        )}
      </div>
    </div>
  )
}

export default Membership
