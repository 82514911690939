import React from 'react'
import styled from 'styled-components'
import { throttle } from 'throttle-debounce'
import Zindex from 'css/z-indexLayers.js';
import iconInfoBlue from 'images/icon-info-blue.svg'
import { primaryBlue } from 'css/Colors'

import { device } from 'common/Device'

const defaultFontSize = 16
const defaultImageWidth = 454

const TheCarat = styled.i`
  content:'';
  display:block;
  position:absolute;
  z-index:${parseInt(Zindex.zindexPopUpStackOrder2,10) + 1};
  background:#FFFFFF;
  height:${(10/defaultFontSize)}rem;
  width:${(10/defaultFontSize)}rem;
  border-top:1px solid ${ primaryBlue };
  border-left:1px solid ${ primaryBlue };
  border-right:1px solid #FFFFFF;
  border-bottom:1px solid #FFFFFF;
  box-shadow:-4px -2px 8px -2px rgba(0,0,0,0.2);
`

const TheContent = styled.label`
  position:absolute;
  z-index:${Zindex.zindexStackOnMainContentOrder2};
  box-sizing:border-box;
  max-width:100vw;
  padding:${ 12/defaultFontSize }rem ${ 20/defaultFontSize }rem;

  box-shadow: ${ 3/defaultFontSize }rem ${ 3/defaultFontSize }rem ${ 10/defaultFontSize }rem 0 rgba(0,0,0,0.2);
  border-radius: 4px;
  border:1px solid ${ primaryBlue };
  background:#FFFFFF;

  font-size:${ 14/defaultFontSize }rem;
  line-height:${ 20/14 };
  text-align:left;
`

const InfoIcon = styled.i`
  display:block;
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:url(${ iconInfoBlue }) no-repeat;
  background-size:contain;
  background-position:center;
  z-index:${ Zindex.zindexmMainContent }
`

const MoreInfoStyles = styled.div`
  position:relative;

  /* z-index:${ Zindex.zindexStackOnMainContentOrder2 }; */
  height:1rem;
  width:1rem;
  box-sizing:border-box;

  ${ TheCarat } {
    display:${ props => props.open? 'block' : 'none'};
    bottom:${ props => props.align === 'bottom'? `${-19/defaultFontSize}rem` : `calc(50% - ${ 6/defaultFontSize }rem)`};
    left:${ props => {
      if (props.align === 'left') {
        return `calc(-100% - ${ 10/defaultFontSize }rem)`
      }
      else if (props.align === 'right') {
        return `calc(100% + ${ 10/defaultFontSize }rem)`
      }

      return `calc(50% - ${ 5/defaultFontSize }rem)`
    }};
    transform:rotate(${ props => {
      if (props.align === 'left') {
        return '135deg'
      }
      else if (props.align === 'right') {
        return '315deg'
      }
      else if (props.align === 'top') {
        return '215deg'
      }

      return '45deg'
    }});
  }

  /* the text */
  ${ TheContent } {
    display:${ props => props.open? 'block' : 'none'};
    top:${ props => props.top !== null? props.top : `calc(100% + ${ 12/defaultFontSize }rem)` };
    left:${ props => props.left !== null? `${props.left}%` : `${10 / defaultFontSize}rem` };
    transform:${ props => props.align === 'top' || props.align === 'bottom'? 'translateX(-50%)' : 'none' };
    width:${ props => props.width !== null? `${props.width}px` : `${ defaultImageWidth / defaultFontSize }rem` };
    height:${ props => props.height !== null? `${props.height}px` : 'auto' };

  }
`

const InfoButton = styled.input`
  position:relative;
  display:block;
  height:100%;
  width:100%;
  margin:0;
  border:none;
  outline:none;
  cursor:pointer;
  opacity:0;
  box-sizing:border-box;
  z-index:${Zindex.zindexOverlaystackOrder1};
}
`

export default class MoreInfo extends React.Component {
  constructor( props ) {
    super(props)
    this.state = {
      open: false,
      left: null,
      width: null,
      height: null,
      top: null,
      align: 'bottom'
    }
    this.timeOutId = null
    this.inputRef = React.createRef()
    this.labelRef = React.createRef()

    this.handleResize = throttle( 200, this.movePopup )
  }

  showPopup() {
    if (!this.timeOutId) {
      this.timeOutId = window.setTimeout( () => {
        this.setState({ open: true }, () => {
          this.setInfoPopupStyles()
          this.timeOutId = null
        })
      })
    }
  }

  hidePopup() {
    if (!this.timeOutId) {
      this.timeOutId = window.setTimeout( () => {
        this.setState({ open: false }, () => {
          this.setState({ width:null, left:null, height:null, top:null, align:'bottom' })
          this.timeOutId = null
        })
      })
    }

  }

  togglePopup = () => {
    if ( this.state.open ) {
      this.hidePopup()
    }
    else {
      this.showPopup()
    }
  }

  movePopup = () => {
    if (this.state.open) {
      this.setInfoPopupStyles()
    }
  }

  setInfoPopupStyles() {
    const styles = this.infoPopupStyles()

    // console.log( styles )

    this.setState({ ...styles })
  }

  // my math could be better
  infoPopupStyles() {
    let { left, width, height, top, align } = this.state

    let calculatedWidth

    const bodyBounds = document.body.getBoundingClientRect()
    const bounds = this.labelRef.current.getBoundingClientRect()

    let measuredWidth = bounds.width
    let measuredHeight

    const labelStyle = window.getComputedStyle(this.labelRef.current)
    // assuming padding is same top / bottom, left / right
    const padding = {
      left: labelStyle.getPropertyValue('padding-left').replace('px',''),
      top: labelStyle.getPropertyValue('padding-top').replace('px',''),
    }
    const offsetParentBounds = this.inputRef.current.getBoundingClientRect()
    // this ref is used to calculate the current window gutter
    const gutterWidth = parseFloat(this.props.gutterRef? window.getComputedStyle( this.props.gutterRef.current ).getPropertyValue('padding-left').replace('px',''): '30')

    const newWidth = (window.innerWidth - 2 * gutterWidth)

    // force mobile layout if the width of the label text is within this distance from either gutter
    const pixelFudgeFactor = 10

    // device returns strings, so we need the numeric portion extracted
    const laptopSize = parseInt(device.laptop.match(/[0-9]+/)[0],10)

    // assume that the popop contains either text or an image to get the height of the popup
    // this addresses an issue in older iOS Safari
    // sometimes naturalHeight and naturalWidth are null for this.labelRef.current.children[0]
    if (this.labelRef.current.children.length && this.labelRef.current.children[0].naturalHeight) {
      measuredHeight = this.labelRef.current.children[0].naturalHeight + 2 * padding.top
      measuredWidth = this.labelRef.current.children[0].naturalWidth + 2 * padding.left
      width = defaultImageWidth
    }

    // start with the height calculations
    if ( bounds.top + measuredHeight + window.pageYOffset >= bodyBounds.height - pixelFudgeFactor ) {
      const newHeight = bodyBounds.height - (bounds.top + window.pageYOffset) - pixelFudgeFactor - 2 * padding.top - 2
      if (measuredHeight > newHeight ) {
        height = newHeight
      }
    }

    // expanded form means the box goes on the right so the height calculation is slightly different
    if (this.props.expandedForm && window.innerWidth >= laptopSize ) {
      // check against right gutter
      // 31 is width of carat plus spacing plus border
      if ( offsetParentBounds.right + bounds.width + gutterWidth + 31 + pixelFudgeFactor > window.innerWidth ) {
        left = (20 + bounds.width) / offsetParentBounds.width * -100
        align = 'left'
      }
      else {
        left = 31 / offsetParentBounds.width * 100
        align = 'right'
      }

      top = `calc(50% - ${(height? height : bounds.height) / 2}px)`

    }
    else {
      // do we have enough space to do the width calculation normally?
      if (window.innerWidth - (2 * gutterWidth) - bounds.width - 2 * pixelFudgeFactor > 0) {
        left = null

        // check against gutters and adjust position accordingly
        if ( offsetParentBounds.left + (offsetParentBounds.width - bounds.width) / 2 <= gutterWidth ) {
          // console.log('left gutter')
          left = (gutterWidth - offsetParentBounds.left + bounds.width/2 ) / offsetParentBounds.width * 100

        }
        else if ( offsetParentBounds.right - (offsetParentBounds.width - bounds.width) / 2 + gutterWidth > window.innerWidth ) {
          // console.log('right gutter')
          left = (window.innerWidth - offsetParentBounds.left - gutterWidth - bounds.width / 2) / offsetParentBounds.width * 100
        }
        // don't override styles (i.e. large desktop layouts)
      }
      else {
        // mobile mode - takes up all but the gutter and should be centered
        // console.log( 'mobile mode', height, this.props.gutterRef )
        left = (gutterWidth - offsetParentBounds.left + newWidth / 2) / offsetParentBounds.width * 100
        width = newWidth
      }
    }


    // basically recalculate the height if the proportionally sized image is not tall enough to fill the box
    if (height) {
      calculatedWidth = height? ( measuredWidth / measuredHeight ) * height : null

      if (calculatedWidth > defaultImageWidth ) {
        height = width * measuredHeight / measuredWidth
      }
      else {
        height = Math.min( (measuredHeight / measuredWidth ) * width, height )
      }
    }
    // adjust height if image child and this.labelRef.current.children[0] has naturalHeight and naturalWidth
    else if ( this.labelRef.current.children.length && measuredHeight && measuredWidth) {
      height = width * measuredHeight / measuredWidth
    }

    return { left, width, height, top, align }
  }

  // handle resize events
  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    if (this.timeOutId) {
      window.clearTimeout( this.timeOutId )
    }
  }

  // handles positioning and showing info when isOpen prop is passed in
  componentDidUpdate(prevProps) {
    if(this.props.isOpen !== prevProps.isOpen) {
      if(this.props.isOpen) {
        return this.showPopup();
      }
      return this.hidePopup();
    }
  }

  render() {
    return (
      <MoreInfoStyles
        className="more-info-container"
        open={ this.state.open }
        width={ this.state.width }
        height={ this.state.height }
        left={ this.state.left }
        top={ this.state.top }
        align={ this.state.align }
      >
        <InfoButton
          aria-haspopup="true"
          aria-expanded={ this.state.open }
          name={ this.props.htmlName }
          id={ this.props.htmlId }
          type={ "radio" }
          ref={ this.inputRef }
          onBlur={ e => this.hidePopup() }
          onFocus={ e => this.showPopup() }
          onClick={ this.togglePopup }
          onMouseEnter={ e => this.showPopup() }
          onMouseLeave={ e => this.hidePopup() }
        />
        <TheCarat />
        <TheContent
          data-testid="more-info-content"
          ref={ this.labelRef }
          htmlFor={ this.props.htmlId }
        >
          { this.props.children }
        </TheContent>
        <span className="sr-only">{ this.props.srText }</span>
        <InfoIcon className="more-info-icon"/>
      </MoreInfoStyles>
    )
  }
}
