import ReactDOMServer from 'react-dom/server'
import DepartmentLookupIdExample from './department-id-lookup-example.png'
import css from '../sass/upload.documents.module.scss'

export const fullName = "Additional Campus Reports"

const exampleWindowHtml = (
  <html>
    <img src={DepartmentLookupIdExample} alt="DepartmentLookupIdExample"/>
  </html>
)

const showDocumentLookupIdExample = () => {
  const newWin = window.open('url','windowName','height=930,width=1191');
  newWin.document.write(ReactDOMServer.renderToString(exampleWindowHtml));
}

export const helperText = (
  <div className={css.helperText}>
    <p><b>All files should be named in the following format:</b>
    <br/>[name of report].[User ID/Fund ID/Department ID/School ID].[year of report].[file extenstion]</p>

    <div>
      <b>For example:</b>
      <ol>
        <li><b>User Report:</b> <span>Joe Bruin Special Mid-Year Giving Report.0123456789.2021.pdf</span></li>
        <li><b>Fund Report:</b> <span>UCLA Health System Strategic Fund Special Report.63180C - HZ.2021.pdf</span></li>
        <li><b>Department Report (must contain school, division and department):</b> <span>Jonsson Cancer Center Foundation Press Release.45-45-0072.2021.mov</span></li>
        <li><b>School/Division Report (must contain school and division): </b> <span>Aerospace Studies Yearly Report.35-D.2021.pdf</span></li>
      </ol>
      <button onClick={showDocumentLookupIdExample}>Click here</button> to see a screenshot of where to find the School/Department Lookup ID in CRM.
      <br />&nbsp;
    </div>
  </div>
)

export const validateFile = (file, errors) => {
  const [reportName, reportId, reportYear, fileExt, badData] = file.name.split('.');

  if (badData) {
    errors.push({file, msg: "Filename has too many sections"})
  }
  else if (!fileExt) {
    errors.push({file, msg: "Filename has too few sections"})
  }
  else if (!/^\d{4}$/.test(reportYear)) {
    errors.push({file, msg: "Report Year: " + reportYear + " is malformed"})
  }
  else if (!/^\w{3,4}$/.test(fileExt)) {
    errors.push({file, msg: "File Extension: " + fileExt + " is malformed"})
  }
  else {
    let reportType;
    if (/^\d{10}$/.test(reportId)) {
      reportType = 'User';
    }
    else if (/^\d{5}[A-Z]? - [A-Z]{2}$/.test(reportId)) {
      reportType = 'Fund';
    }
    else if (/^[A-Za-z0-9]{2}-[A-Za-z0-9]{1,2}-[0-9]{4}$/.test(reportId)) {
      reportType = 'Dept';
    }
    else if (/^[A-Za-z0-9]{2}-[A-Za-z0-9]{1,2}$/.test(reportId)) {
      reportType = 'School';
    }
    if (!reportType) {
      return errors.push({file, msg: "ID: " + reportId + " doesn't match known User, Fund, Department or School ID format"})
    }
  }
}