import styled from 'styled-components'

import { device } from 'common/Device'

import { GiftLineItem, GiftItemHeader, defaultPrintFontSize } from './PrintStyles'
import { formatAmount } from '../mygivingutil'

const GiftAmountStyles = styled.div`
  text-align:right;
  margin-top:${ 30/defaultPrintFontSize }em;

  .receipt-amount-section {
    margin-bottom:${ 8/defaultPrintFontSize }em;

    > * {
      display:flex;
      margin:${ 5/defaultPrintFontSize }em 0;
    }

    ${ GiftItemHeader }, ${ GiftLineItem } {
      flex:1 0 50%;
    }
  }

  .tribute {
    font-weight:600;
  }

  @media screen and ${ device.tablet } {
    margin-top:${ 20/defaultPrintFontSize }em;
  }

  @media screen and ${ device.mobile } {
    margin-top:${ 15/defaultPrintFontSize }em;
  }
`

const ReceiptGiftSingle = props => (
  <GiftAmountStyles>
    <div className="receipt-amount-section">
      <div>
        <GiftItemHeader>Gift Amount:</GiftItemHeader>
        <GiftLineItem className="has-underline">{ formatAmount(props.data.displayAmount) }</GiftLineItem>
      </div>
      <div>
        <GiftItemHeader>Tax Deductible Amount:</GiftItemHeader>
        <GiftLineItem className="has-underline">{ formatAmount(props.data.taxDeductibleAmount) }</GiftLineItem>
      </div>
    </div>

    { props.data.tributeText1 && props.data.tributeText1.length && <div className="tribute">{ props.data.tributeText1.charAt(0).toUpperCase() + props.data.tributeText1.slice(1) }</div> }
    { props.data.tributeText2 && props.data.tributeText2.length && <div className="tribute">{ props.data.tributeText2.charAt(0).toUpperCase() + props.data.tributeText2.slice(1) }</div> }
    { props.data.benefitAmount === 0 && <div className="tribute">No goods or services were received for this donation.</div> }
  </GiftAmountStyles>
)

export default ReceiptGiftSingle
