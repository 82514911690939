import { useEffect } from 'react'

import { emailPasswordManualOauthUrl, facebookManualOauthUrl, googleManualOauthUrl, linkedInManualOauthUrl } from 'config'
import { showLoading } from "services/util"

// destination when user is redirected back to IDM site from Graduway
function ForceSignin(props) {

  useEffect(() => {
    showLoading(true, "ForceSignin")

    const referer = (document.referrer || "").toLowerCase();

    if (referer.indexOf('facebook') > -1) {
      showLoading(false, 'ForceSignin - Facebook login')
      document.location.href = facebookManualOauthUrl;
    }
    else if (referer.indexOf('youtube') > -1 || referer.indexOf('google') > -1) {
      showLoading(false, 'ForceSignin - Google login')
      document.location.href = googleManualOauthUrl;
    }
    else if (referer.indexOf('linkedin') > -1) {
      showLoading(false, 'ForceSignin - LinkedIn login')
      document.location.href = linkedInManualOauthUrl;
    }
    else {
      showLoading(false, 'ForceSignin - Email/Password login')
      document.location.href = emailPasswordManualOauthUrl;
		}
  }, [])

	return (
					<div>
					</div>
	)
}

export default ForceSignin;
