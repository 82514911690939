import { Component } from 'react';

import * as cs from 'css/CommonStyle';
import * as forms from 'css/forms';
import { FilledYellow } from 'css/Buttons'
import DropDownMenu from 'common/forms/DropDown'
import EmailPhoneToggle from 'authentication/components/EmailPhoneToggle'

import { InputWrap, ButtonWrap, OperatorWrapper } from 'admin/QueryStyles'

const metaSearchTypes = [
  {displayValue: "All Downloads", key: "AllDownloads"},
  {displayValue: "Endowment Reports", key: "EIAR"},
  {displayValue: "Fund Narratives", key: "CEIR"},
  {displayValue: "Recognition Summary", key: "YEGS"},
  {displayValue: "Additional Campus", key: "ACR"},
]

const searchTypes = [
  {displayValue: "Report Year", key: "ReportYear", isDropdown: true},
  {displayValue: "Show All", key: "ShowAll"},
  {displayValue: "Download Date", key: "DownloadDate"},
  {displayValue: "Fund Number", key: "ReportNumber"},
  {displayValue: "File Name", key: "FileName"},
  {displayValue: "CRM ID", key: "CrmId"},
  {displayValue: "Username", key: "CognitoUsername"},
]

let startYear = (new Date()).getFullYear()
const ReportYearValues = [];
while (startYear >= 2020) {
  ReportYearValues.push(startYear);
  startYear--;
}

class SharedQueryForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      metaSearchDisplay: 'All Downloads',
      metaSearchType: 'AllDownloads',
      searchType: "ReportYear",
      searchDisplay: "Report Year",
      reportYearValue: ReportYearValues[1], // start with previous year
      searchValue: ReportYearValues[1],
    }
  }

  componentDidMount() {
  }

  handleMetaDropdownChange = async e => {
    await this.setState({
      metaSearchType: e.target.value.key,
      metaSearchDisplay: e.target.value.displayValue,
    });
  }

  handleSearchTypeChange = e => {
    const searchType = e.target.value.key;

    this.setState({
      searchType,
      searchDisplay: e.target.value.displayValue,
      searchFieldName: searchType + 'InputField', // give fields different names so browsers will remember the right things
      searchValue: searchType === 'ReportYear' ? ReportYearValues[1] : '',
      searchDateTo: '',
      searchDateFrom: '',
    });
  }

  handleReportYearChange = e => {
    this.setState({
      reportYearValue: e.target.value,
      searchValue: e.target.value,
    });
  }

  handleSearchFieldChange = e => {
    this.setState({
      searchValue: '' + e.target.value,
    });
  }

  handleActionTypeChange = e => {
    this.setState({
      actionType: e.target.value,
      searchValue: e.target.value,
    });
  }

  handleToDateFieldChange = e => {
    this.setState({
      searchDateTo: '' + e.target.value,
    });
  }

  handleFromDateFieldChange = e => {
    this.setState({
      searchDateFrom: '' + e.target.value,
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const scrubbedValue = this.state.searchValue && this.state.searchType === 'CognitoUsername' && this.state.searchValue.charAt(0) === '+'
        ? '+' + this.state.searchValue.replace(/\D/g,'')
        : this.state.searchValue;

    await this.setState({searchValue:scrubbedValue})

    this.props.onSubmit(this.state);
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <cs.TextInputdiv className="user-search-form" >
            <InputWrap>
              <label className="link-donor-info-block-title">{"Search..."}</label>
              <DropDownMenu
                className="input-wrap-dropdown"
                name="metaSelect"
                title={this.state.metaSearchDisplay}
                list={metaSearchTypes}
                changeHandler={this.handleMetaDropdownChange}
              />
            </InputWrap>
            <InputWrap>
              <label className="link-donor-info-block-title">{"By..."}</label>
              <DropDownMenu
                className="input-wrap-dropdown"
                name="auditTrailSearch"
                title={this.state.searchDisplay}
                list={searchTypes}
                changeHandler={this.handleSearchTypeChange}
              />
            </InputWrap>

            {['ShowAll', 'DownloadDate'].indexOf(this.state.searchType) === -1 &&
              <OperatorWrapper>=</OperatorWrapper>}

            {['FileName', 'ReportNumber', 'CrmId'].indexOf(this.state.searchType) > -1 &&
              <InputWrap key={this.state.searchType}>
                <label className="link-donor-info-block-title">&nbsp;</label>
                  <forms.ClearableInput
                  width="100%"
                  type="text"
                  tryOutTwoWaySetValue={this.state.searchValue}
                  name={this.state.searchFieldName}
                  onChange={this.handleSearchFieldChange} />
              </InputWrap>}

            {this.state.searchType === 'ReportYear' &&
              <InputWrap key={this.state.searchType}>
                <label className="link-donor-info-block-title">&nbsp;</label>
                <DropDownMenu
                  className="input-wrap-dropdown"
                  name="ReportYearList"
                  title={this.state.reportYearValue}
                  list={ReportYearValues}
                  changeHandler={this.handleReportYearChange}
                />
              </InputWrap>}

            {this.state.searchType === 'CognitoUsername' &&
              <InputWrap key={this.state.searchType}>
                <label className="link-donor-info-block-title">&nbsp;</label>
                  <EmailPhoneToggle onChange={this.handleSearchFieldChange}/>
              </InputWrap>}

            {['DownloadDate'].indexOf(this.state.searchType) > -1 &&
              <>
                <InputWrap key={this.state.searchType+'From'}>
                  <label className="link-donor-info-block-title">From: (blank = beginning)</label>
                  <forms.ClearableInput
                    width="100%"
                    type="text"
                    name={this.state.searchFieldName+'From'}
                    placeholder={"m/d/yyyy"}
                    onChange={this.handleFromDateFieldChange} />
                </InputWrap>
                <InputWrap key={this.state.searchType+'To'}>
                  <label className="link-donor-info-block-title">To: (blank = now)</label>
                  <forms.ClearableInput
                    width="100%"
                    type="text"
                    placeholder={"m/d/yyyy"}
                    name={this.state.searchFieldName+'To'}
                    onChange={this.handleToDateFieldChange} />
                </InputWrap>
              </>}

            <ButtonWrap>
              <br/>
              <FilledYellow
                width="7.0625rem"
                bgColor={"#FFD100"}
                fontColor={"#005587"}
                borderColor={"#FFD100"}
                name={"filterDownloadSearchResults"}
              >
                <span> Search</span>
              </FilledYellow>
            </ButtonWrap>
          </cs.TextInputdiv>
        </form>
      </div>
    )
  }
}

export default SharedQueryForm;