import styled from 'styled-components'

import { device } from 'common/Device'
import { lightGray, borderGray } from 'css/Colors'

import ProfileDetails from '../dashboard/ProfileDetails'

import DonationTotals from './DonationTotals'
import { formatAmount } from '../mygivingutil'

const defaultFontSize = 16

const SummaryBackground = styled.div`
  background:${ lightGray };
  border-radius:${ 5/defaultFontSize }rem;
  padding:${15/defaultFontSize}rem ${15/defaultFontSize}rem ${23/defaultFontSize}rem ${30/defaultFontSize}rem;
  height:100%;
  box-sizing:border-box;
  h2 {
    color:#000000;
    font-size:${ 20/defaultFontSize }rem;
    font-weight:600;
    margin-top:0;
  }
  @media ${ device.tablet } {
    padding:${ 22/defaultFontSize }rem ${30/defaultFontSize}rem ${23/defaultFontSize}rem ${30/defaultFontSize}rem;
  }
  @media ${ device.mobile } {
    padding-left:${ 15/320 * 100 }%;
    padding-right:${ 15/320 * 100 }%;
  }
`

const SummaryDetails = styled.div`
  display:flex;
  flex-direction:row;
  align-items:flex-start;
  .details-container {
    position:relative;
    box-sizing:border-box;
    flex:1 1 ${342/754 * 100}%;
    &.donation-totals {
      flex:1 0 ${412/754 * 100}%;
      padding:0 ${10/412 * 100}% 0 0;
      &:before {
        position:absolute;
        content:'';
        display:block;
        height:100%;
        width:1px;
        background:${ borderGray };
        right:${ 100 + ( 15/3 ) }%;
      }
    }
  }

  @media ${ device.tablet } {
    .details-container {
      flex:1 1 ${206/598 * 100}%;
      &.donation-totals {
        flex:2 1 ${463/598 * 100}%;
        padding:0;
      }
    }
  }

  @media ${ device.mobile } {
    display:block;

    .details-container {
      padding:0 0 ${ 15/defaultFontSize }rem;
      &.donation-totals {
        padding:${ 15/defaultFontSize }rem 0 0;

        &:before {
          height:1px;
          width:100%;
          top:0;
          left:0;
        }
      }
    }
  }

`

const HistorySummary = props => {
  const { profile, summary } = props
  return (
    <SummaryBackground>
      <h2>{ profile? profile.primaryName.firstName + ' ' +profile.primaryName.keyName : '' }</h2>
      <SummaryDetails>
        <div className="details-container">
          { profile && summary && (
            <ProfileDetails
              uclaid={ profile.constituentLookupId }
              donorsince={ summary.donorSinceYear }
            />
          )}
        </div>

        <div className="details-container donation-totals">
          { summary && !isNaN(summary.lifetimeRecognition) && !isNaN(summary.currentYearTaxDeductible) && !isNaN(summary.previousYearTaxDeductible) && (
            <DonationTotals
              lifetimeRecognition={ formatAmount(summary.lifetimeRecognition) }
              currentYearTaxDeductible={ formatAmount(summary.currentYearTaxDeductible) }
              previousYearTaxDeductible={ formatAmount(summary.previousYearTaxDeductible) }
            />
          )}
        </div>
      </SummaryDetails>
    </SummaryBackground>
  )
}

export default HistorySummary;
