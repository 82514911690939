/**
Layering all elements based on appropriate  z-indexes 
**/

/* Layers
* Layer-0 => Background
* Layer=>1 Page Content ->header,main content,footer
* Layer=>2 Modal -> Dropdown,select,calendar, Info
* Layer=>3 Pop up/Dialog => loading graphic, leave confirmation
*/



const Zindex = {

  zindexdefault: '0',
  zindexUnderLay:'-1',
  zindexInlineLayer: '1',  //  Layer-0
  zindexHeader: '1',
  zindexmMainContent: '1',
  zindexmainContentStack: '2',
  zindexOverlaystackOrder1: '10',  //  Layer-1
  zindexOverlayStackOrder2: '20',
  zindexHeaderOverlay: '100',  //  Layer-2
  zindexStackOnMainContentOrder1: '100',
  zindexStackOnMainContentOrder2: '200',
  zindexStackOnMainContentLayer: '250',
  zindexStackOnMainContentOrder3: '300',
  zindexChildStackOnMainContent: '200',
  zindexPopUp: '1000',  //  Layer-3
  zindexPopUpStack: '1001', 
  zindexPopUpStackOrder1: '2000',
  zindexPopUpStackOrder2: '3000',
  zindexAllTheTop: '9999',
}

export default Zindex;
