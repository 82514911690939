import { Link } from 'react-router-dom'

import * as colors from 'css/Colors'

import styled from 'styled-components'
import AuthenticationLink from './AuthenticationLink'
import fBookIcon from 'images/facebook-icon.svg'
import googleIcon from 'images/google-icon.svg'
import { fbOauthUrl, googleOauthUrl } from 'config'
import { device } from 'common/Device'

import { SignInRegisterDiv } from '../components/SignInRegisterDiv'
import { Heading } from '../components/Heading'

const ExternalAccountsTitle = styled(props => <Heading {...props} />)`
margin-bottom:15px;
color:${colors.darkGray};
`

const ExternalAccountsDiv = styled.div`
  width:100%;
  height:50px;
  border: 1px solid #2774AE;
  border-radius: 3px;
  background-color: #F5F5F5;
  margin-bottom: 20px;

  & > div{
   height:100%;
   width:100%;
  }

  @media ${device.tablet}{
    /* width:280px; */
  }
`

const ExternalAccounts = props => (

  <SignInRegisterDiv className="external-accounts" >

    {!props.isPhone && <ExternalAccountsTitle className="bold"> {props.socialtitle}</ExternalAccountsTitle>}

    <ExternalAccountsDiv>
      <AuthenticationLink
        loginMethod={'facebook'}
        isRegistration={ props.isRegistration }
        oauthUrl={fbOauthUrl}
        icon={fBookIcon}
        id="facebookSignInLink"
        linkText="Continue with Facebook"
        bgColor="#3B5998"
      />
    </ExternalAccountsDiv>

    <ExternalAccountsDiv>
      <AuthenticationLink
        loginMethod={'google'}
        isRegistration={ props.isRegistration }
        oauthUrl={googleOauthUrl}
        icon={googleIcon}
        id="googleSignInLink"
        linkText="Continue with Google"
        bgColor="#FFFFFF"
      />
    </ExternalAccountsDiv>

    {/*
    <ExternalAccountsDiv >
      <AuthenticationLink
        oauthUrl = {"future shib url"}
        icon = { uclaIcon }
        linkText = "Continue with UCLA ID"
        bgColor = "#2774AE"
      />
    </ExternalAccountsDiv>
    */}

    {props.isPhone && props.isRegistration &&
      <p>Already have an account? <Link to='/signin'>Sign in</Link> now</p>}

    {props.isPhone && !props.isRegistration &&
      <p>Don't have an account?Already have an account? <Link to='/registration'>Register</Link> now</p>}

  </SignInRegisterDiv>
)

export default ExternalAccounts
