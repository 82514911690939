import { doApi } from 'services/api';

// deletes current logged in User from Cognito (used for testing)
// export const deleteUser = async (emailOrPhone) => {
//   return await doApi({route:'admin/deleteuser?username=' + emailOrPhone});
// };

export const getPendingList = async (status) => {
  return await doApi({route:'ucla1/admin/pendinglist?status=' + status});
};

export const updatePendingUser = async (data) => {
  return await doApi({route:'ucla1/admin/updatependinguser', method:'POST', data});
};

export const lookupUcla1Id = async (email) => {
  const cognitoUsers =  await doApi({route:'admin/getcognitousers?username=' + email});
  const ucla1IdCrmUsers = await doApi({route:'ucla1/admin/finduser?email=' + email});

  return {cognitoUsers, ucla1IdCrmUsers};
};

export const linkUcla1Id = async (action, primaryEmail, crmId, ucla1Id, previousUcla1Id) => {
  return await doApi({route:'ucla1/admin/linkucla1id', method:'POST', data:{action, primaryEmail, crmId, ucla1Id, previousUcla1Id}});
};
