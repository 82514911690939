import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { device } from 'common/Device'
import { HeaderTitle } from 'css/CommonStyle'
import { showLoading } from 'services/util';

import Links from '../components/Links'
import { getSummary, getRecentGifts } from '../history/historyapi'
import WithBackground from 'common/WithBackground'
// import { HeaderBackground } from '../components/CommonStyles'

import Highlight from './Highlight'
import ReportsAlert from './ReportsAlert';
import PaymentMethodsAlert from './PaymentMethodsAlert'
import Summary from './Summary'
import DashboardHeader from '../images/dashboard-top-background-banner.jpg'

const defaultFontSize = 16

const HeaderBackground = styled(WithBackground)`
  &:after {
    background: linear-gradient(rgba(0,59,92,0.8) 0%, rgba(0,59,92,0.8) 100%, transparent),${props => `url("${props.headerImage}")` || ''} no-repeat center;
  }

@media ${ device.tablet } {
  padding-top:0;
  ${ HeaderTitle } {
    padding:${ 15/defaultFontSize }rem 0;
  }
}
`

const Wrapper = styled.div`
  padding-top:${ 30/defaultFontSize }rem;

  @media ${ device.tablet } {
    padding-top:0;
  }
`

class DashBoardComponent extends Component {
  state = {
    alertContent:true,
    history:[],
    summary:{},
    profile:{},
  }

  closeBtn =()=>{
    this.setState({ alertContent:false });
  }

	componentDidMount = async () => {
		showLoading(true)

    // get profile, history data
    const history = await getRecentGifts()
    const summary = getSummary()
    const profile = this.props.authenticatedUser.crmUser.profile;
    const title = history.length ? "My Account" : "UCLA Donor Portal";

    showLoading(false)

    await this.setState({ history, summary, profile, title })
  }

	render() {
    return (
      <HeaderBackground className="background-grey" headerImage={DashboardHeader}>
        <ReportsAlert />
        <PaymentMethodsAlert/>
        <HeaderTitle className="h1 center has-no-margins" id="landingPageTitle">{this.state.title}</HeaderTitle>
          <React.Fragment>
            {this.state.history.length &&
            <Wrapper className="wrapper m-no-padding">
              <Summary
                summary={this.state.summary}
                history={this.state.history.length ? this.state.history.slice(0, 3) : []}
                profile={this.state.profile}
              />
            </Wrapper>}
            <div className="wrapper">
              <Highlight isDonor={!!this.state.history.length}/>
            </div>
            <div className="wrapper">
              <Links />
            </div>
          </React.Fragment>
      </HeaderBackground>
    )
	}
}

export default withRouter(DashBoardComponent);
