export const primaryBlue = "#2774AE"
export const primaryYellow = "#FFD100"
export const secondaryDarkBlue = "#003B5C"
export const secondaryMediumBlue = "#005587"
export const secondaryBabyBlue = "#8BB8E8"
export const secondaryLightBlue = "#C3D7EE"
export const buttonActiveBlue = "#DAEBFE"
export const guideGray = "#CCCCCC"
export const darkGray = "#666666"
export const lightGray = "#F6F6F6"
export const borderGray = "#DDDDDD"
export const guideRed = "#F54B5E"
export const messagingRed = "#D0021B"
export const messagingGreen = "#27A074"
export const guideGreen = "#48D2A0"
export const darkGold = "#FFB81C"
export const mediumGold = "#FFC72C"
