import buttonsCss from '../sass/buttons.module.scss'

function OlgContinueAsGuest (props) {
  return (
    <div className={buttonsCss['continue-as-guest']}>
      <button onClick={() => window.close()}>CLICK HERE TO CONTINUE AS GUEST</button>
    </div>
  )
}

export default OlgContinueAsGuest;

