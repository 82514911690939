
export const getLoggedInUser = store => store.loggedInUser

export const getCrmUser = store =>
  store.loggedInUser && store.loggedInUser.crmUser ? store.loggedInUser.crmUser : null

export const getCognitoUser = store =>
  store.loggedInUser ? store.loggedInUser.cognitoUser : null

export const getProfile = store =>
  store.loggedInUser && store.loggedInUser.crmUser ? store.loggedInUser.crmUser.profile : null

export const getRedirectAfterLogin = store => store.redirectAfterLogin.redirectTo

export const getTheme = store => store.theme

export const isLoading = store => store.isLoading

