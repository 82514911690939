import { NewGiftStyles } from './shared/SharedStyles'

const TaxDocumentation = () => {

  return (
    <NewGiftStyles>
      <ul>
        <li>
          <a href="https://www.finance.ucla.edu/tax-records/tax-services" target="_blank"
             rel="noreferrer">University’s Tax Status</a>
        </li>
        <li>
          <a href="https://www.finance.ucla.edu/tax-records/tax-services/requests-for-uclas-taxpayer-id-numbers" target="_blank"
             rel="noreferrer">UCLA and UCLA Foundation Taxpayer Identification Numbers</a>
        </li>
        <li>
          <a href="https://ucla.box.com/s/n6t7wt6bsm34cr8y6w6ozg4au8wlr1f0" target="_blank"
             rel="noreferrer">UCLA Foundation California Determination Letter</a>
        </li>
        <li>
          <a href="https://ucla.box.com/s/hd12hf4t5y96egmtb31f0q6qge7tmhem" target="_blank"
             rel="noreferrer">UCLA Foundation Federal Tax Exemption Status</a>
        </li>
        <li>
          <a href="https://ucla.box.com/s/gy769sc97rykojthugm17ewgq9uttry6" target="_blank"
             rel="noreferrer">UCLA Foundation W-9 form</a>
        </li>
        <li>
          <a href="https://ucla.box.com/s/rgn5xnd8closjecqf7w2xy1j5clmgven" target="_blank"
             rel="noreferrer">UC Regents Federal Tax Exemption Status Letter</a>
        </li>
        <li>
          <a href="https://ucla.app.box.com/tax-pdf-articles-of-incorp" target="_blank"
             rel="noreferrer">University of California Certificate of Incorporation</a>
        </li>
        <li>
          <a href="https://ucla.app.box.com/tax-pdf-form-w-9" target="_blank"
             rel="noreferrer">UCLA W-9 form</a>
        </li>
        <li>
          <a href="https://ucla.box.com/s/483jj2xtlhozlsiddiolmjk9q3eacf14" target="_blank"
             rel="noreferrer">Tax-Efficient Giving for Hong Kong Donors</a>
        </li>
        <li>
          <a href="https://ucla.box.com/s/y1kyxnc4aiqtasw684r2bkpjvv7bu0yw" target="_blank"
             rel="noreferrer">UCLA Foundation Form 990 For Public Distribution</a>
        </li>
      </ul>
    </NewGiftStyles>
  )
}

export default TaxDocumentation
