import { useState } from 'react';

import { showLoading } from 'services/util'

import LinkUserForm from 'admin/linkuser/LinkUserForm'

import { lookupUcla1Id, } from "../ucla1adminapi";

import CognitoResults from './CognitoResults'
import Ucla1IdCrmResults from './Ucla1IdCrmResults'

import css from '../sass/ucla1id.module.scss'

function LookupUcla1Id(props) {
  const [cognitoLookupResponse, setCognitoLookupResponse] = useState()
  const [ucla1Id, setUcla1Id] = useState(null)
  const [ucla1IdCrmUsers, setUcla1IdCrmUsers] = useState()

  const handleUcla1IdSubmit = async e => {
    e.preventDefault()

    showLoading(true)

    const data = new FormData(e.target);
    const email = data.get('email').trim();

    const apiResponse = await lookupUcla1Id(email);

    // users found in CRM from admin/findUser call
    const ucla1IdResponse = apiResponse.ucla1IdCrmUsers;
    if (ucla1IdResponse.success && ucla1IdResponse.success.blackbaudUser) {
      setUcla1IdCrmUsers([ucla1IdResponse.success.blackbaudUser])
    }
    else if (ucla1IdResponse.error && ucla1IdResponse.error.key === "NO_CRM_USERS_FOUND") {
      setUcla1IdCrmUsers([])
    }
    else if (ucla1IdResponse.error && ucla1IdResponse.error.key === "SPOUSE_CRM_USERS_FOUND") {
      setUcla1IdCrmUsers(ucla1IdResponse.error.duplicates)
    }
    else if (ucla1IdResponse.error) {
      setUcla1IdCrmUsers([{error: ucla1IdResponse.error ? ucla1IdResponse.error.key : "ERROR_IN_CRM_LOOKUP"}])
    }
    else {
      setUcla1IdCrmUsers([{error: "ERROR_IN_CRM_LOOKUP"}])
    }

    // users found in Cognito from admin/getCognitoUsers
    const cognitoUsers = (apiResponse.cognitoUsers && Array.isArray(apiResponse.cognitoUsers)) ?  apiResponse.cognitoUsers :[{error: "ERROR_IN_COGNITO_LOOKUP"}]
    setCognitoLookupResponse(cognitoUsers)

    setUcla1Id(email)
    props.handleUcla1IdChange(ucla1Id)

    showLoading(false)
  }

  const handleUcla1IdChange = e => {
    const ucla1Id = e.target.value.trim()
    setUcla1Id(ucla1Id)
    setCognitoLookupResponse('')
    setUcla1IdCrmUsers(null)
  }

  let CognitoUsers = cognitoLookupResponse && cognitoLookupResponse.map((user,index) => {
    return <CognitoResults
      key={index}
      value={index}
      user={user}
      selected={true}
      handleSelect={() => {}}
    />
  })
  const goodCidmCount = cognitoLookupResponse ? cognitoLookupResponse.filter(user => !user.error).length : ''

  let Ucla1IdUsers = ucla1IdCrmUsers && ucla1IdCrmUsers.map((user,index) => {
    return <Ucla1IdCrmResults
      key={index}
      value={index}
      user={user}
      selected={true}
      handleSelect={() => {}}
    />
  })
  const goodCrmCount = ucla1IdCrmUsers ? ucla1IdCrmUsers.filter(user => !user.error).length : ''

  return (
    <div className="span-5">
      <div className={css["userCRMInformation"]}>
        <LinkUserForm
          title={"Check UCLA One User ID"}
          inputName={"email"}
          btnName={"ucla1IdSearch"}
          inputTitle={"Enter UCLA One User ID (email) to link"}
          formType="crm"
          onSubmit={handleUcla1IdSubmit}
          onChange={handleUcla1IdChange}
        >
        </LinkUserForm>

      {ucla1IdCrmUsers &&
        <div className="account-display">
          <p>
            <strong>{goodCrmCount} CRM account{goodCrmCount !== 1 ? 's' : ''} found for {ucla1Id}{goodCrmCount > 0 ? ':' : ''}</strong>
          </p>
          {Ucla1IdUsers}
        </div>}

      {cognitoLookupResponse &&
        <div className="account-display">
          <p>
            <strong>{goodCidmCount} CIDM account{goodCidmCount !== 1 ? 's' : ''} found for {ucla1Id}{goodCidmCount > 0 ? ':' : ''}</strong>
          </p>
          {CognitoUsers}
        </div>}

      </div>
    </div>
  )
}

export default LookupUcla1Id;
