import { NavLink } from "react-router-dom"

import AdminLinkIcon from 'images/admin-link-icon.svg'
import AdminAuditIcon from 'images/admin-audit-icon.svg'
import AdminUsersIcon from 'images/admin-users-icon.svg'
import AdminBrowseIcon from 'images/admin-browse-icon.svg'
import AdminDeleteIcon from 'images/x-red.svg'
import { AdminList, AdminListItem } from 'admin/AdminViewListStyles'

import AdminDownloadIcon from '../images/admin-download-icon.svg'
import AdminDocumentsIcon from '../images/admin-documents-icon.svg'
import AdminPrintIcon from '../images/admin-print-icon.svg'

const AdminViewList = (props) => {
  return (
    <AdminList >
      <ul>
        <hr />
        <div className={"divider"}>
          Shared Admin Tools
        </div>
        <hr />

        <AdminListItem>
          <NavLink className="bold" id="AdminLinkUserToCrmLink" activeClassName="selectedTab" to="/admin/linkusertocrm">
            <img src={AdminLinkIcon} alt='Link Donor icon'/>
            <div><span>LINK USER TO CRM</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold" id="AdminAuditLink"  activeClassName="selectedTab" to="/admin/history">
            <img className="admin-users-svg" src={AdminAuditIcon} alt='Admin Change History icon'/>
            <div><span>CHANGE HISTORY</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold" id="AdminUsersLink"  activeClassName="selectedTab" to="/admin/users">
            <img src={AdminUsersIcon} alt='Admin Users icon'/>
            <div><span>USERS</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold"  id="AdminUnconfirmedUserList"  activeClassName="selectedTab" to="/admin/unconfirmedusers">
            <img  src={AdminDeleteIcon} alt='Unconfirmed User List icon'/>
            <div><span>UNCONFIRMED USERS</span></div>
          </NavLink>
        </AdminListItem>

        <div className={"divider"}>
          Donor Portal Admin
        </div>
        <hr />

        <AdminListItem>
          <NavLink className="bold"  id="AdminBrowseAsUserLink"  activeClassName="selectedTab" to="/admin/browseasuser">
            <img  src={AdminBrowseIcon} alt='Browse As User icon'/>
            <div><span>BROWSE AS USER</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold"  id="AdminPrintReceiptLink"  activeClassName="selectedTab" to="/admin/printreceipt">
            <img src={AdminPrintIcon} alt='Print Receipt icon'/>
            <div><span>PRINT RECEIPT</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold"  id="AdminDownloadsLink"  activeClassName="selectedTab" to="/admin/downloads">
            <img src={AdminDownloadIcon} alt='Admin Downloads icon'/>
            <div><span>Downloads</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold"  id="AdminDocumentsLink"  activeClassName="selectedTab" to="/admin/documents">
            <img src={AdminDocumentsIcon} alt='Admin Documents icon'/>
            <div><span>Documents</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold"  id="AdminUploadLink"  activeClassName="selectedTab" to="/admin/upload">
            <img style={{transform: "scaleY(-1)"}} src={AdminDownloadIcon} alt='Admin Upload icon'/>
            <div><span>Upload Documents</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold"  id="AdminDeleteUserLink"  activeClassName="selectedTab" to="/admin/deleteuser">
            <img src={AdminDeleteIcon} alt='Delete User icon' id="delete-icon"/>
            <div><span>DELETE USER</span></div>
          </NavLink>
        </AdminListItem>

      </ul>
    </AdminList>
  )
}

export default AdminViewList;
