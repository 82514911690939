import * as cs from 'css/CommonStyle';
import styled from 'styled-components'

import { device } from 'common/Device'

import { MyInformationContent, HeaderBackground, OuterContainer } from 'profile/MyInformationStyles'
import FAQlist from './FAQlist'
import faqBackground from '../images/faq.png'

const defaultFontSize = 16

const FAQBackground = styled( HeaderBackground )`
  padding-top:${ 203/defaultFontSize }rem;
  z-index:1;

  @media ${ device.tablet } {
    padding-top:${ 148/defaultFontSize }rem;
  }

  @media ${ device.mobile } {
    padding-top:${ 80/defaultFontSize }rem;
  }
`

const InnerContent = styled(MyInformationContent)`
  padding:0;

  @media ${ device.tablet } {
    padding-top:0;
  }

  @media ${ device.mobile } {
    padding:0;
  }
`
const FAQHeaderTitle = styled(cs.HeaderTitle)`
  display:flex;
  flex-direction:column;
  justify-content:center;
  height:${ 203/defaultFontSize }rem;
  position:absolute;
  top:0;
  left:0;
  margin:0;

  @media ${ device.tablet } {
    height:${ 148/defaultFontSize }rem;
  }

  @media ${ device.mobile } {
    height:${ 80/defaultFontSize }rem;
  }

`

const Styles = styled.div`
  margin-top:${ 10/defaultFontSize }rem;

  ${ OuterContainer } {
    margin-top:0;
  }

  @media ${ device.tablet } {
    margin-top:${ 5/defaultFontSize }rem;
  }

  @media ${ device.mobile } {
    margin-top:${ 3/defaultFontSize }rem;
  }
`

const faq = () => {
  return (
    <FAQBackground className="background-grey" headerImage={faqBackground}>
      <Styles className="wrapper t-no-padding">
        <FAQHeaderTitle className="h1 center">Frequently Asked Questions</FAQHeaderTitle>
        <div className="grid t-grid">
          <div className="span-12 t-span-12">
            <OuterContainer >
              <InnerContent >
                <FAQlist />
              </InnerContent>
            </OuterContainer>
          </div>
        </div>
      </Styles>
    </FAQBackground>
  )
}

export default faq;
