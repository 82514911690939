import React, { Component } from 'react';

import { ProfileDataContainer } from 'profile/personal/MyNames'
import DropDownMultiple from 'common/forms/DropDownMultiple'

import { CloseButton } from 'css/Buttons'
import * as colors from 'css/Colors'
import * as cs from 'css/CommonStyle'
import * as forms from 'css/forms'
import styled from 'styled-components'
import { device } from 'common/Device'

const ColumnList = styled.div`
  & >div{
    display:flex;
    justify-content:space-between;
  }
  ${ [1, 2].map(item => (
   `.flex-row-${item} {
    & >div{
      flex:0 48%;
    }
  };
  `))
  }
  & input:focus{
    border: 1px solid ${colors.primaryBlue};
  }
  .industry-tags{
    & >div{
    flex: 0 48%;
    }
  }
  @media ${device.mobile}{
    & >div{
      flex-direction:column;
    }
  }
`

const DropDownValueListed = styled.div`
  display:inline-block;
  background:#FFFFFF;
  &&& {
   margin:5px;
   padding:calc(5rem/16) calc(10rem/16) calc(5rem/16) 0;
  }
  & >img{
    width:10px;
  }
  & > span{
    font-size: 0.8rem;
    font-weight:600;
    color:${colors.primaryBlue};
    padding:0 8px;
  }`
  const CloseList = styled(CloseButton)`
  display:inline-block;
  cursor:pointer;
  && {
    padding: 0.625rem 0.325rem;
  }
`

class Employment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedId: '',
      selected: false,

      companyName1: false,
      companyName2: false,
      businessTitle: false,
    };
    this.resetThenSet = this.resetThenSet.bind(this);
    this.deleteDropDownListValue = this.deleteDropDownListValue.bind(this);

    this.companyRef = React.createRef();
    this.departnmentRef = React.createRef();
    this.titleRef = React.createRef();
  }

  resetThenSet = (value, index) => {
    let temp = JSON.parse(JSON.stringify(this.props.industryTypesList.types.fieldOfWork))

    const newList = [...this.props.employmentData.fieldsOfWork, temp[index]];
    this.setState({
      selectedId: temp[index],
      selected: true
    })
    //adding newList without any duplicates of industry values
    this.props.changeHandler({ target: { value: newList.filter((val, id, array) => array.indexOf(val) === id), name: "fieldsOfWork" } });
  }

  deleteDropDownListValue(value, id) {
    const newList = this.props.employmentData.fieldsOfWork.filter(list => list !== value);
    this.props.changeHandler({ target: { value: newList, name: "fieldsOfWork" } });
  }

  handleEmploymentValidation = (e) => {
    if (!this.companyRef.value && (this.titleRef.value || this.departnmentRef.value)) {
      this.setState({ companyName1: true }, this.updateValidation)
    }

    else {
       this.setState({
        companyName1: false,
        businessTitle: false,
        department: false
       }, this.updateValidation)
    }
  }

  updateValidation = (e) => {
    this.props.updateValidation(this.state.companyName1 || this.state.businessTitle)
  }

  render() {
    return (
      <ProfileDataContainer>
        <ColumnList>

         <div className="flex-row-1">
            <div>
              <label> Company Name</label>
              <cs.TextInputdiv>
                 <forms.Input placeholder="Enter most recent/main company"
                  name="companyName1"
                  className={this.state.companyName1 && "error"}
                  value={this.props.employmentData && this.props.employmentData.companyName1}
                  onChange={e => { this.props.changeHandler(e); this.handleEmploymentValidation(e); }}
                  onFocus={this.handleEmploymentValidation}
                  onBlur={this.handleEmploymentValidation}
                  ref={this.companyRef}
                 />
                {this.state.companyName1 && <cs.ErrorSpan textAlign="right"> {"Company Name cannot be empty"}</cs.ErrorSpan>}
              </cs.TextInputdiv>
            </div>
            <div>
              <label> Department</label>
              <cs.TextInputdiv>
                <forms.Input
                  placeholder="e.g. Finance"
                  name="companyName2"
                  value={this.props.employmentData && this.props.employmentData.companyName2}
                  onChange={e => { this.props.changeHandler(e); this.handleEmploymentValidation(e); }}
                  onFocus={this.handleEmploymentValidation}
                  onBlur={this.handleEmploymentValidation}
                  ref={this.departmentRef}
                />
              </cs.TextInputdiv>
            </div>
          </div>

          <div className="flex-row-2">
            <div>
              <label> Job/Business Title</label>
              <cs.TextInputdiv>
                <forms.Input
                  placeholder="e.g. VP of Engineering"
                  name="businessTitle"
                  className={this.state.businessTitle && "error"}
                  value={this.props.employmentData && this.props.employmentData.businessTitle}
                  onChange={e => { this.props.changeHandler(e); this.handleEmploymentValidation(e); }}
                  onFocus={this.handleEmploymentValidation}
                  onBlur={this.handleEmploymentValidation}
                  ref={this.titleRef}
                />
              </cs.TextInputdiv>
            </div>
            <div className="industry-dropdown-list">
              <label> Industry (you may choose multiple selections)</label>
              <cs.TextInputdiv>
                <DropDownMultiple
                  titleHelper="Industry"
                  title="Choose Industry"
                  list={!!(this.props.industryTypesList) && this.props.industryTypesList.types.fieldOfWork}
                  resetThenSet={this.resetThenSet}
                  selected={this.state.selected}
                  selectedId={this.props.employmentData.fieldsOfWork}
                />
              </cs.TextInputdiv>
            </div>
          </div>

          {/* Displaying all selected Industry tags here */}

          <div className="industry-tags">
            <div className="placeholder-element"></div>
            <div className="tags-display">
              {this.props.employmentData.fieldsOfWork && this.props.employmentData.fieldsOfWork.map((dropdownValue, index) =>
                (<DropDownValueListed key={index}>
                  <span>
                    {dropdownValue}
                  </span>
                  <CloseList onClick={() => this.deleteDropDownListValue(dropdownValue, index)} />
                </DropDownValueListed>)
              )}
            </div>
          </div>

        </ColumnList>
       </ProfileDataContainer>
    )
  }
}

export default Employment;
