import styled, { css } from 'styled-components'

import Zindex from 'css/z-indexLayers.js';
import * as colors from 'css/Colors'
import { device } from 'common/Device'

const defaultFontSize = 16

const BannerImage = styled.div`
  position:absolute;
  display:flex;
   width:100%;
  height:100%;
  z-index:${Zindex.zindexInlineLayer};
  flex:1 0 50%;


  /* this background should be swapped with the image */
  &:before {
    display:block;
    content:'';
    flex:1 0 50%;
    background:${ props => props.icon ? `url("${props.icon}")` : ""};
    background-size:cover;
    background-position: ${props => (props.referringPage === "Promo")? '0% 20%' : '50% 50%' };
    background-repeat: no-repeat;
  }

   &:after {
    display:block;
    content:'';
    flex:1 0 50%;
    background:linear-gradient(225.44deg, ${colors.primaryBlue} 0%, ${colors.secondaryDarkBlue} 100%);
  }


  /* tablet breakpoint */

  @media ${device.tablet} {
    ${props => (props.referringPage === "Promo") && css`{
    position:relative;
    flex-direction:column;
    height:${(800 / 1200)/2 * 100}rem;

    &:before {
       flex:1 0 100%;
     }
     &:after {
       display:none;
     }
    }
    `
  }
}

@media ${device.mobile} {
  position:relative;
  flex-direction:column;
  height:${(212 / 734)/2 * 100}rem;

  &:before {
    flex:1 0 100%;
  }
  &:after {
    background:none;
    flex:1 0 100%;
   }

 }
`

const BannerContent = styled.div`
  position:relative;
  z-index:${Zindex.zindexOverlaystackOrder1};
  color:#FFFFFF;

  @media ${device.tablet}{

  ${props => (props.referringPage === "Promo") && css`{
    position: absolute;
    top: 50%;
  }`
  }
  }
`

const BannerCopy = styled.div`
  padding: ${ 63 / defaultFontSize}rem ${39 / defaultFontSize}rem;
  .content-link {
    color:#FFFFFF;
  }

  /* tablet breakpoint */
  @media ${device.tablet}{
   padding: ${16 / defaultFontSize}rem 0 ${ 35/defaultFontSize }rem;
  }

  @media ${device.mobile} {
    padding:${ 16 / defaultFontSize}rem 0 ${ 25/defaultFontSize }rem;
  }
`

const BannerPadding = styled.div`
  ${props => (props.referringPage === 'Promo') && css`
    position:relative;
    padding-bottom:${ (800-63)/(1200-39) * 100}%;
    .banner-content {
      position:absolute;
    }
  `}

  .banner-content {
    padding:0 0 0 ${ 30 / defaultFontSize }rem;
  }

  @media ${ device.tablet } {
    padding:0;

    .banner-content {
      padding:0;
    }

    ${props => (props.referringPage === 'Promo') && css`
      position:relative;
      padding-bottom:0;
      .banner-content {
        position:static;
      }
    `}
  }
`

const BannerContainer = styled.div`
  position:relative;
  text-align:${props => props.referringPage === "Promo" ? "left" : "center"};

  @media ${device.tablet}{
   .content-wrapper{
    background:linear-gradient(225.44deg, ${colors.primaryBlue} 0%, ${colors.secondaryDarkBlue} 100%);
  }
}
  /* tablet breakpoint */
  @media ${device.mobile} {
    margin-top:0;
 }
`

const PromoBanner = props => (
  <BannerContainer {...props}>
    <BannerImage referringPage={props.referringPage} icon={props.icon} />
    <div className="wrapper content-wrapper">
      <BannerContent className="grid">
        <div className={`span-6 m-span-2 ${props.referringPage === "Promo" ? "t-span-12" : "t-span-6"}`} />
        <BannerCopy className={`span-6 m-span-2 ${props.referringPage === "Promo" ? "t-span-12" : "t-span-6"}`}>
          <BannerPadding referringPage={props.referringPage}>
            <div className="banner-content">
              {props.children}
            </div>
          </BannerPadding>
        </BannerCopy>

      </BannerContent>
    </div>
  </BannerContainer>
)

export default PromoBanner
