import { useState } from 'react'
import styled from 'styled-components'

import { showLoading } from "services/util"

const Wrapper = styled.div`
  padding: 0 30px 0 30px;
  font-size:18px;
  h3 {
    padding-bottom: 0;
    margin-bottom: 0;
  }
`
const Expander = styled.div`
  cursor:finger;
  color: blue;
  span {
    font-size:24px;
  }
`

function PocStart(props) {
  const [showExpanded, setShowExpanded] = useState(0)
  const [resetUsername, setResetUsername] = useState('')
  const [resetCrmId, setResetCrmId] = useState('')

  showLoading(false, 'systemerror page')

  const doExpand = (sectionNum) => {
    setShowExpanded(sectionNum)
  }

  const doReset = () => {
    const url = "https://pilot.api.cidm.support2.ucla.edu/admin/setupTestUsers?testtype=ucla1single&email="
                  + encodeURIComponent(resetUsername)
                  + "&crmid="
                  + resetCrmId

    window.open(url, '_blank');
  }

  return (
    <Wrapper>
      <h3>Instructions for testing the live user login and signup scenarios.</h3>
      <p>If you haven't done so already, go <a href="https://pilot.uclaone.support2.ucla.edu/" target="_blank" rel="noreferrer">to the Portal Site</a> and make an account with your @support.ucla.edu email.
         Do the same on <a href="https://uclatest.manual.graduway.com/" target="_blank" rel="noreferrer">Graduway test</a>.
         Then let me (Matt) know, and I will make you an admin user on both sites.</p>

      <p>For a test user, you need to use an email you have access to that is not in CRM. Be sure to use incognito windows when instructed,
        and close the incognito window (not just the tab) completely after each test.</p>

      <hr/>
      <p><b>To reset a test account for reuse:</b></p>
      <ol >
        <li>If you previously Approved the test account request, delete them in <a href="https://uclatest.manual.graduway.com/" target="_blank" rel="noreferrer">Graduway test</a> either
            by logging in as the user and deleting the account, or log in as an admin and navigate to the user's profile page through the Directory, then delete the user from the Admin panel.</li>
        <li>Enter the email or phone # of the account: <input name="email" length="30" onChange={(e) => setResetUsername(e.target.value)}/></li>
        <li>Add a CRM ID if you linked it to the account (optional): <input name="crmid" size="10" maxlength="10"  onChange={(e) => setResetCrmId(e.target.value)}/></li>
        <li>Click here: <button name="submit" onClick={doReset}>RESET</button> (don't reset 0000000009 - it's saved for existing user tests)</li>
        <li>Check to make sure the reset results don't show an error (it will say error and not have much else, a USER_NOT_FOUND response is ok). Close the tab.</li>
      </ol>
      <hr/>
      <h3>Test scenarios:</h3>
      <ol >
        <li>
          New UCLA One User, email is not found in CRM, Admin <b>APPROVES</b> request:
          <br />
          {showExpanded !== 1 &&
            <Expander onClick={() => doExpand(1)}><span>▸</span> Expand</Expander>}
          {showExpanded === 1 &&
            <Expander onClick={() => doExpand(0)}><span>◂</span> Collapse</Expander>}
          {showExpanded === 1 &&
            <ol type="a">
              <li>If you've previously used this test user, follow the steps above to delete the user in Graduway and reset them with UCLAOne Portal.</li>
              <li>Right-click <a href="https://uclatest.manual.graduway.com/">this link.</a> and select "Open Link in Incognito Window" from the context dropdown.</li>
              <li>Click the Sign In or Get Started link (they go to the same place) and then click Sign on with UCLA One Portal.</li>
              <li>Either Sign In if you've already created the account, or Sign Up by clicking the link on the bottom of the modal window. Use an email you have access to that is not in CRM.</li>
              <li>If Signing Up, you will be prompted to enter a security code to verify the email, which you will need to retrieve from your email account.</li>
              <li>Fill out the the Additional Information form with any data and submit. You should land on the /inprogress page.</li>
              <li>Close the incognito window.</li>
              <li>Right-click <a href="https://uclatest.manual.graduway.com/">this link.</a> and select "Open Link in Incognito Window" from the context dropdown.</li>
              <li>Sign in again with the same test account you just created. You should still land on the /inprogress page.</li>
              <li>Close the incognito window.</li>
              <li>Click <a href="https://pilot.uclaone.support2.ucla.edu/" target="_blank" rel="noreferrer">here to launch the portal site</a> in a new tab. You don't need incognito for this.</li>
              <li>Log in with your @support.ucla.edu email. You should be redirected to the pending list with All Open Items loaded.</li>
              <li>Find the Entry you just made. Make some notes and click the Save button. The usern's pending status should change to In Progress.</li>
              <li>Go back into the same entry. Click the EMAIL BIODATA button. An new email should open with the user's vital information populated.</li>
              <li>Enter a CRM ID that isn't already associated with UCLA1 and click the LOOKUP button. You can try 000000000x where x=0-8 (coordinate to make sure you aren't sharing).</li>
              <li>The user's CRM information should load in green. Click Approve to approve this request and link them to the CRM ID shown.</li>
              <li>The user's entry should move to the Approved Requests bucket (also Closed Requests).</li>
              <li>The user should receive an email letting them now they've been Approved.</li>
              <li>Make sure all incognito windows are closed, then right-click <a href="https://uclatest.manual.graduway.com/">this link.</a> and select "Open Link in Incognito Window" from the context dropdown.</li>
              <li>Sign in again with the same test account. You should land on the Graduway Basic Profile form with First and Last Name and Affiliation populated. Note: these fields are based on the data in CRM, not what the user entereed in the form.</li>
              <li>Complete filling out all the required steps of the Advanced Profile form. When finished, you should land on the user's UCLA One home page. Close the incognito window.</li>
            </ol>}
        </li>
        <br />
        <li>
          New UCLA One User, email is not found in CRM, Admin <b>DENIES</b> request:
          <br />
          {showExpanded !== 2 &&
            <Expander onClick={() => doExpand(2)}><span>▸</span> Expand</Expander>}
          {showExpanded === 2 &&
            <Expander onClick={() => doExpand(0)}><span>◂</span> Collapse</Expander>}
          {showExpanded === 2 &&
            <ol type="a">
              <li>Repeat the steps in #1 above, except don't link the user to a CRM ID, and this time click Deny instead of Approve. Don't forget to reset the test account and CRM ID if necessary, using the form above.</li>
              <li>The user's entry should move to the Denied Requests bucket (also Closed Requests).</li>
              <li>The user should receive an email letting them now they've been Denied.</li>
              <li>Make sure all incognito windows are closed, then right-click <a href="https://uclatest.manual.graduway.com/">this link.</a> and select "Open Link in Incognito Window" from the context dropdown.</li>
              <li>Sign in again with the account you just denied. You should now see the Access Not Approved page. Close the incognito window.</li>
            </ol>}
        </li>
        <br />
        <li>
          New UCLA One User, email is found in CRM (bypasses Pending List):
          <br />
          {showExpanded !== 3 &&
            <Expander onClick={() => doExpand(3)}><span>▸</span> Expand</Expander>}
          {showExpanded === 3 &&
            <Expander onClick={() => doExpand(0)}><span>◂</span> Collapse</Expander>}
          {showExpanded === 3 &&
            <ol type="a">
              <li>First log on to <a href="https://uclatest.manual.graduway.com/" target="_blank" rel="noreferrer">Graduway Test</a> with your admin account. If Joe Bruin exists in the Directory, delete the account.</li>
              <li>Use the RESET button above to reset d12.crm.user@gmail.com and CRM ID 0123456789 in UCLAOne Portal.</li>
              <li>Make sure all incognito windows are closed, then right-click <a href="https://uclatest.manual.graduway.com/">this link.</a> and select "Open Link in Incognito Window" from the context dropdown.</li>
              <li>Create a new account with d12.crm.user@gmail.com. Check gmail for the verification code. Since the user's email is in CRM, the user should go directly to the Graduway Basic Profile form (bypassing the Pending List).</li>
              <li>Finish completing the forms. You should land on the user's UCLA One home page..</li>
              <li>Right-click <a href="https://uclatest.manual.graduway.com/">this link.</a> and select "Open Link in Incognito Window" from the context dropdown.</li>
              <li>Slick Sign in, then Sign On to UCLA One Portal with d12.crm.user@gmail.com. This time Sign In with the portal modal. You should land on the user's UCLA One home page. Close the incognito window.</li>
            </ol>}
        </li>
        <br />
        <li>
          Existing UCLA One user, email is found in CRM (bypasses Pending List):
          <br />
          {showExpanded !== 4 &&
            <Expander onClick={() => doExpand(4)}><span>▸</span> Expand</Expander>}
          {showExpanded === 4 &&
            <Expander onClick={() => doExpand(0)}><span>◂</span> Collapse</Expander>}
          {showExpanded === 4 &&
            <ol type="a">
              <li>Run this test only after you've completed the previous test (Existing CRM User, new to UCLA One). Do not delete the test user in graduway when you're finished.</li>
              <li>Use the RESET button above to reset d12.crm.user@gmail.com and CRM ID 0123456789 in UCLAOne Portal.</li>
              <li>Make sure all incognito windows are closed, then right-click <a href="https://uclatest.manual.graduway.com/">this link.</a> and select "Open Link in Incognito Window" from the context dropdown.</li>
              <li>Create a new account with d12.crm.user@gmail.com. Since the user's email is in CRM, and the user has an existing UCLA One account you should land on the user's UCLA One home page. Close the incognito window.</li>
            </ol>}
        </li>
        <br />
        <li>
          Existing UCLA One user, uses a new email not in CRM, Admin <b>APPORVES</b> request:
          <br />
          {showExpanded !== 5 &&
            <Expander onClick={() => doExpand(5)}><span>▸</span> Expand</Expander>}
          {showExpanded === 5 &&
            <Expander onClick={() => doExpand(0)}><span>◂</span> Collapse</Expander>}
          {showExpanded === 5 &&
            <ol type="a">
              <li>Repeat the steps in #1 above, except this time link the user to CRM ID: 0000000009.</li>
              <li>You should see the CRM User's UCLA1 ID populated with nine.bruin@gmail.com.
                  This simulates a user that already has a UCLA1 account but doesn't have access to the email anymore, or wants to sign up with a different email for some reason.</li>
              <li>Approve the test account request.</li>
              <li>Make sure all incognito windows are closed, then right-click <a href="https://uclatest.manual.graduway.com/">this link.</a> and select "Open Link in Incognito Window" from the context dropdown.</li>
              <li>Sign in again with the account you just Approved. You should land on the user's UCLA One home page. Click Edit Profile on the upper right dropwdown. You should see nine.bruin@gmail.com (not the email you just used to sign in). Close the incognito window.</li>
              <li>Don't reset CRM ID 0000000009. It's saved for this test.</li>
            </ol>}
        </li>
        <br />
        <li>
          New UCLA One User signs in with LinkedIn, Google or Facebook:
          <br />
          {showExpanded !== 6 &&
            <Expander onClick={() => doExpand(6)}><span>▸</span> Expand</Expander>}
          {showExpanded === 6 &&
            <Expander onClick={() => doExpand(0)}><span>◂</span> Collapse</Expander>}
          {showExpanded === 6 &&
            <ol type="a">
              <li>Repeat the steps in #1 or #2 above, except this time use LinkedIn, Google or Facebook to log on. Close the incognito window between each test.</li>
              <li>Repeat for the other two social media types. You may need to make dummy accounts in LinkedIn, Gmail or Facebook.</li>
            </ol>}
        </li>
        <br />
        <li>
          New UCLA One User, email is not found in CRM, Admin <b>DENIES</b> request without sending email to user:
          <br />
          {showExpanded !== 7 &&
            <Expander onClick={() => doExpand(7)}><span>▸</span> Expand</Expander>}
          {showExpanded === 7 &&
            <Expander onClick={() => doExpand(0)}><span>◂</span> Collapse</Expander>}
          {showExpanded === 7 &&
            <ol type="a">
              <li>Repeat the steps in #2 above, except this time click DENY W/O EMAIL.</li>
              <li>The user should <b>not</b> receive any email.</li>
              <li>Make sure all incognito windows are closed, then right-click <a href="https://uclatest.manual.graduway.com/">this link.</a> and select "Open Link in Incognito Window" from the context dropdown.</li>
              <li>Sign in again with the account you just denied. You should now see the Access Not Approved page. Close the incognito window.</li>
            </ol>}
        </li>
        <br />
        <li>
          New UCLA One User, email is not found in CRM, Admin <b>DELETES</b> request:
          <br />
          {showExpanded !== 8 &&
            <Expander onClick={() => doExpand(8)}><span>▸</span> Expand</Expander>}
          {showExpanded === 8 &&
            <Expander onClick={() => doExpand(0)}><span>◂</span> Collapse</Expander>}
          {showExpanded === 8 &&
            <ol type="a">
              <li>Repeat the steps in #2 above, except this time click DELETE.</li>
              <li>The user's entry should move to the Deleted Requests bucket.</li>
              <li>The user should <b>not</b> receive any email.</li>
              <li>Make sure all incognito windows are closed, then right-click <a href="https://uclatest.manual.graduway.com/">this link.</a> and select "Open Link in Incognito Window" from the context dropdown.</li>
              <li>Sign in again with the account of the request you just deleted. The user doesn't have to sign up again because we didn't delete their account. Just the request.</li>
              <li>You should land on the Additional Information form, confirming that the user can sign up again. Complete the form. Close the incognito window.</li>
              <li>Go to the UCLA Portal. You should see the user's new request in the Pending List. This test is complete.</li>
            </ol>}
        </li>
        <br />
        <li>
          New UCLA One user, signs in with mobile number that is not found in CRM, Admin <b>APPROVES</b> request:
          <br />
          {showExpanded !== 9 &&
            <Expander onClick={() => doExpand(9)}><span>▸</span> Expand</Expander>}
          {showExpanded === 9 &&
            <Expander onClick={() => doExpand(0)}><span>◂</span> Collapse</Expander>}
          {showExpanded === 9 &&
            <ol type="a">
              <li>Repeat the steps in #1 or #2 above, except this time use a mobile phone number that you have access to, or use +14242699161. You may need to enter RESET this number before starting the test using the form above.</li>
              <li>Enter the number in the format +14242699161 to create your account. </li>
              <li>If you used your own number you should get a verification text. If you used the number provided, log on to dp.no.crm@gmail.com to access the forwarded text.</li>
              <li>Note: It's doubtful any user will ever figure out how to enter the number correctly.
                  But it's possible you may need to guide a user who already has a mobile CIDM account or insists on using a mobile # to log on.</li>
              <li>When you link the CRM ID, notice if the CRM user already has a UCLAOne User ID.
                  If not, the CRM User's primary email will be sent to Graduway and saved to CRM as the UCLAOne User ID.</li>
              <li>Approve the mobile test account request.</li>
              <li>Make sure all incognito windows are closed, then right-click <a href="https://uclatest.manual.graduway.com/">this link.</a> and select "Open Link in Incognito Window" from the context dropdown.</li>
              <li>Sign in again with the mobile account you just created. You should land on either the Basic Profile form or the UCLA One user's home page (depending on if the user already had an account in Graduway). Close the incognito window.</li>
            </ol>}
        </li>
        <br />

      </ol>
    </Wrapper>
  )
}

export default PocStart;