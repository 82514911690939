import { useEffect } from "react"
import css from './sass/additionalinfo.module.scss'

import { showLoading } from "services/util"

import * as cs from 'css/CommonStyle'

function PendingRequestInProgress(props) {
  useEffect(() => {
    showLoading(false, 'PendingRequestInProgress')
  }, [])

  return (
		<div className={css['additional-info-form']}>
			<br/>
			<h3 id="pageTitle" className=" bold">Submission In Progress</h3>

			<cs.CommonInnerDiv>
				<cs.InnerContent>

					<div style={{"text-align": "left"}}>
						<p>
						  Thank you for registering your UCLA ONE profile. We are currently reviewing your request. You will receive an email once your UCLA ONE profile has been approved.
					  </p>
					 	<p>
					 	  For any questions, please contact: <a href="mailto:ace@support.ucla.edu?subject=UCLA ONE registration inquiry">ace@support.ucla.edu</a>
						</p>
						<p>
						  The UCLA ONE team
						</p>
					</div>

				</cs.InnerContent>

			</cs.CommonInnerDiv>

		</div>
	)

}

export default PendingRequestInProgress