import { Route, Switch } from 'react-router-dom'

// shared with all sites
import PrivateRoute from 'common/PrivateRoute'
import * as dummyAnalytics from 'services/dummyanalytics'
import store from 'redux/store';
import { injectReducers } from 'redux/store'
import { setLoggedInUser } from 'redux/actions';
import MoreInfo from 'common/MoreInfo'
import authCss from 'authentication/sass/authenticate.module.scss'

// admin shared with all sites
import LinkUserSuccess from 'admin/linkuser/LinkUserSuccess'
import LinkCrm from 'admin/linkuser/LinkCrm'
import BrowseAsUser from 'admin/BrowseAsUser'
import UserSearch from 'admin/sharedqueries/UserSearch'
import AuditTrail from 'admin/sharedqueries/AuditTrail'

// auctions api
import { getAuctionUserApi, createAuctionUser, updateAuctionUser } from './api'
import { auctionReducers } from "./redux/reducers"

// auctions admin
import AdminViewList from './admin/AdminViewList'
import AdminPublicAuctionPage from './admin/PublicAuction/AdminPublicAuctionPage'
import AdminItemPage from './admin/AdminItemPage'
import CreateAuction from './admin/CreateAuction'
import AdminPreviewAuctionPage from './admin/PreviewAuction/AdminPreviewAuctionPage'
import AdminAuctionTabs from './admin/AdminAuctionTabs'
import AdminClosedAuctionsPage from './admin/ClosedAuction/AdminClosedAuctionsPage'
import CreateItem from './admin/CreateItem'
import DeleteAuctionUser from './admin/DeleteAuctionUser'

//sign up or in flow pages
// import EmailLink from './register/flow/EmailLink' // invitee only sign up page

import AuctionFromEmail from './register/flow/AuctionFromEmail' // initial entry page before loggin in
import EmailVerification from './register/flow/EmailVerification'
import EmailVerificationCode from './register/flow/EmailVerificationCode'
import PhoneVerification from './register/flow/PhoneVerification'
import PhoneVerificationCode from './register/flow/PhoneVerificationCode'

import AuctionsProfileUpdate from './register/AuctionsProfileUpdate'
import AuctionReg from './register/flow/AuctionReg'
import AuctionsUserPage from './register/AuctionsUserPage'
import UserUpdatePayment from './register/UserUpdatePayment'

// payment
import PaymentAdd from './payment/PaymentAdd'
import PaymentValid from './payment/PaymentValid'
import PaymentUpdate from './payment/PaymentUpdate'
import PaymentSuccess from './payment/PaymentSuccess'

// checkout
import CheckoutAuction from './checkout/CheckoutAuction'
import CheckoutPaymentUpdate from './checkout/CheckoutPaymentUpdate'
import CheckoutBraintree from './checkout/CheckoutBraintree'
import CheckoutSuccess from './checkout/CheckoutSuccess'
import CheckoutConfirm from './checkout/CheckoutConfirm'

// user
import MyPaymentMethods from './user/MyPaymentMethods'
import MyItems from './user/MyItems'
import MyAuctions from './user/MyAuctions'

// auction
import AuctionList from './auctions/AuctionList'
import Auction from './auctions/Auction'
import Item from './items/Item'

// auction components
import OutBidNotification from './components/OutBidNotification'
import Menu from './components/Menu'
import logoImage from './components/images/auction-header.svg'
import { HeaderGradient } from 'common/header/HeaderStyles'

/// demo or not in use for first auction ///
// import LiveBids from './demo/LiveBids'
// import MyAuctions from './demo/MyAuctions'
// import VerifyPhoneNumberOrEmail from './demo/VerifyPhoneNumberOrEmail'
// import MyAuctionItems from './user/MyAuctionItems'
// import InviteeOnlyPage from './register/flow/InviteeOnlyPage'
// import InviteeOnlyRouter from './register/flow/InviteeOnlyRouter'
// import GenericLanding from './components/GenericLanding'
// import TestPage from './components/TestPage'
// import AdminTestAuctionPage from './admin/TestAuction/AdminTestAuctionPage'

injectReducers(auctionReducers)

const routes = [

  // make home a private route so it will redirect to My Auctions if the user is logged in, and sign in if they aren't logged in
  <PrivateRoute exact path='/' component={MyAuctions} key='/myauctions' />,

  // invitee-only landing page
  // <Route exact path='/flow/emaillink' component={EmailLink} key='/emaillink' />,

  // landing page for personalized or non-personalized auction link
  <Route exact path='/flow/auction' component={AuctionFromEmail} key='/auctionfromemail' />,

  // header and footer is hidden on these pages (based on 'flow/auction')
  <PrivateRoute exact path='/flow/auction/profileupdate' component={AuctionsProfileUpdate} key='/flow/auction/profileupdate' />,
  <PrivateRoute exact path='/flow/auction/paymentadd' component={PaymentAdd} key='/paymentadd' />,

  // sign up or verify flow
  <PrivateRoute exact path='/flow/emailverification' component={EmailVerification} key='/emailverification' />,
  <PrivateRoute exact path='/flow/emailverificationcode' component={EmailVerificationCode} key='/emailverificationcode' />,
  <PrivateRoute exact path='/flow/auctionreg' component={AuctionReg} key='/auctionreg' />,
  <PrivateRoute exact path='/flow/paymentvalid' component={PaymentValid} key='/paymentvalid' />,
  <PrivateRoute exact path='/flow/paymentupdate' component={PaymentUpdate} key='/paymentupdate' />,
  <PrivateRoute exact path='/flow/paymentsuccess' component={PaymentSuccess} key='/paymentsuccess' />,
  <PrivateRoute exact path='/flow/phoneverification' component={PhoneVerification} key='/phoneverification' />,
  <PrivateRoute exact path='/flow/phoneverificationcode' component={PhoneVerificationCode} key='/phoneverificationcode' />,

  // auction
  <PrivateRoute exact path='/auction/item' component={Item} key='/item' />,
  <PrivateRoute exact path='/auctions' component={AuctionList} key='/auctions' />,
  <PrivateRoute exact path='/auctionhome' component={Auction} key='/auctionhome' />,

  // checkout
  <PrivateRoute exact path='/checkout' component={CheckoutAuction} key='/checkout' />,
  <PrivateRoute exact path='/paymentupdate/checkout' component={CheckoutPaymentUpdate} key='/checkout' />,
  <PrivateRoute exact path='/checkoutbraintree' component={CheckoutBraintree} key='/checkoutbraintree' />,
  <PrivateRoute exact path='/checkoutsuccess' component={CheckoutSuccess} key='/checkoutsuccess' />,
  <PrivateRoute exact path='/checkoutconfirm' component={CheckoutConfirm} key='/checkoutconfirm' />,

  // user
  <PrivateRoute exact path='/user/profile' component={AuctionsUserPage} key='/user/profile' />,
  <PrivateRoute exact path='/user/paymentupdate' component={UserUpdatePayment} key='/user/paymentupdate' />,
  <PrivateRoute exact path='/user/profileupdate' component={AuctionsProfileUpdate} key='/user/profileupdate' />,
  <PrivateRoute exact path='/mypaymentmethods' component={MyPaymentMethods} key='/mypaymentmethods' />,
  <PrivateRoute exact path='/myitems' component={MyItems} key='/myitems' />,
  <PrivateRoute exact path='/myauctions' component={MyAuctions} key='/myauctions' />,
  
  /// demo or not in use for first auction
  // <Route exact path='/genlanding' component={GenericLanding} key='/genlanding' />,
  // <Route exact path='/test' component={TestPage} key='/test' />,
  // <PrivateRoute exact path='/flow/inviteeonlypage' component={InviteeOnlyPage} key='/inviteeonlypage' />,
  // <PrivateRoute exact path='/flow/inviteeonlyrouter' component={InviteeOnlyRouter} key='/inviteeonlyrouter' />,
  // <PrivateRoute exact path='/auction/myitems' component={MyAuctionItems} key='/myauctionitems' />,

  // demo
  // <Route exact path='/authtest' component={AuthTest} key='/authtest' />,
  // <PrivateRoute exact path='/livebids' component={LiveBids} key='/livebids' />,
  // <PrivateRoute exact={true} path='/verify' component={VerifyPhoneNumberOrEmail} key='/verify' />,
]

// const themeHeader = <HeaderGradient />

const themeHeader = <>
    <OutBidNotification />
    <HeaderGradient />
  </>

const REGISTRATION_AFFILIATION = (
  <div>You may be affiliated with UCLA in one or more ways. Please use the email you associate with UCLA.
    <ul>
      <li>Alumni: If you have not changed your contact information with UCLA, please use the email or cell phone on your student record.</li>
      <li>Students: Please use the email or cell phone on your student record.</li>
      <li>Faculty / Staff: Please use the email or cell phone on your payroll account.</li>
    </ul>
  </div>
)

const REGISTRATION_TEXT = "To register, please enter the email address or mobile number associated with your UCLA Account"

const REGISTRATION_HELPER_TEXT = `Please use the email address or cell phone number you used when making a gift to UCLA or attending a university event. This is also the email or phone number we would have used to send you a gift receipt or registration confirmation.`

const cognitoClientIds = {
  "dev": "ch4nq6bo04mr2utijs0cvol68",
  "stage": "2etvirqh0s1gs0rgb30v74i8cj",
  "pilot": "2l64uhmcukpjqad6dbci6bncbd",
  "preprod": "1k2hsrpvrft8o3ojtgpkc8cmso",
  "prod": "sisodvtt85kt852ao5rtak4os",
}

const registrationText = (
  <>
    <span className="block-title bold">
      {REGISTRATION_TEXT}
    </span>
    <div className={authCss.moreInfoPosition}>
      <MoreInfo
        htmlName=""
        srText={"What is my UCLA account?"}
      >
      {REGISTRATION_HELPER_TEXT}
      </MoreInfo>
    </div>
    <span className="block-title bold">
      or UCLA affiliation
    </span>
    <div className={authCss.moreInfoPosition}>
      <MoreInfo
        htmlName=""
        srText={"What is my UCLA affiliation?"}
      >
      {REGISTRATION_AFFILIATION}
      </MoreInfo>
    </div>
    .
  </>)

// returns override route to send user to if needed
const doAuctionUser = async (loggedInUser) => {
  const user = await getAuctionUserApi()

  // if there's a real error, we don't want to create a new user
  if (user && user.error) {
    console.error("error in user logon", user.error)
    throw new Error(user.error.msg) // displayed in sign in box
  }

  // if non-CRM user ask for name
  // if non-CRM user and phone login, ask for and verify email
  // if crmUser, prefer login email/phone to crm email/phone if different
  // if crmUser && phone login && no primary email (1 in a million), ask for email and verify

  // user is new to auctions, create entry in cognito-login-mapping table and users table
  if (!user) {
    await createAuctionUser()
  }

  const savedAuctionCode = localStorage.getItem('savedAuctionCode')

  // user has not completed Auctions Profile form (either new user or returning)
  if (!user || !user.ContactEmail || !user.FirstName || !user.LastName) {

    const idToken = loggedInUser.cognitoUser.signInUserSession.idToken.payload
    const login = idToken.cognitoLogin

    // if we have a CRM user and enough information, user can skip secondary profile form
    if (loggedInUser?.crmUser?.profile
        && (loggedInUser?.crmUser?.profile?.primaryEmail || login.indexOf('@') > 0)
        && loggedInUser?.crmUser?.profile?.primaryName?.firstName
        && loggedInUser?.crmUser?.profile?.primaryName?.keyName
    ) {
      const existingAuctionUser = user || {}
      const crmProfile = loggedInUser.crmUser.profile

      const crmUpdatedUser = await updateAuctionUser({
        firstName: existingAuctionUser.FirstName || crmProfile.primaryName.firstName,
        lastName: existingAuctionUser.LastName || crmProfile.primaryName.keyName,
        // prefer login email as contact email
        emailAddress: existingAuctionUser.contactEmail || (login.indexOf('@') > 0 ? login : crmProfile.primaryEmail)
      })
      console.log({crmUpdatedUser})
      return "/flow/auction/paymentadd"
    }
    // social users might have enough information already, idToken.cognitoLogin comes from social provider and is assumed verified
    else if (login.indexOf('@') > 0 && idToken.given_name && idToken.family_name) {
      const socialUpdatedUser = await updateAuctionUser({
        firstName: idToken.given_name,
        lastName: idToken.family_name,
        emailAddress: login
      })
      console.log({socialUpdatedUser})
      return "/flow/auction/paymentadd"
    }
    else {
      return '/flow/auction/profileupdate'
    }
  }
  else {
    const updatedUser = await updateAuctionUser() // saves CRM ID from token, which we want to check every time

    // if user is not in CRM, update user initials in nav
    if (!loggedInUser?.crmUser?.profile) {
      const newCognitoUser = {...loggedInUser.cognitoUser, nonCrmFirstName: updatedUser.FirstName, nonCrmLastName: updatedUser.LastName}
      store.dispatch(setLoggedInUser({cognitoUser:newCognitoUser, crmUser: loggedInUser.crmUser}))
    }

    const auctionLandingPage = savedAuctionCode ? '/auctionhome?auctionId=' + savedAuctionCode : "" // empty string defers to theme.crmUserLandingPage and theme.nonCrmUserLandingPage
    console.log({updatedUser})

    if ( savedAuctionCode && (user.MyAuctions || []).filter(auction => auction.Auction.AuctionCode === savedAuctionCode).length > 0 ) {
      return auctionLandingPage
    }
    else if (savedAuctionCode) {
      // checking if there are no payments stored for the user
      if (!user.PaymentMethods || user.PaymentMethods.length === 0) {
        return "/flow/auction/paymentadd"
      }
      else {
        return "/flow/paymentvalid"
      }
    } else return auctionLandingPage
  }
}

const themeAdminLinks = (parentHistory) => (
  <div className="admin-content-wrapper">
    <Switch>
{/*      <PrivateRoute exact={true} path='/admin/linkusertocrm' key='/admin/linkusertocrm' component={LinkCrm} />
      <PrivateRoute exact={true} path='/admin/linkusertocrm/success' key='/admin/linkusertocrm/success' component={LinkUserSuccess} />
      <PrivateRoute exact={true} path='/admin/linkusertocrm' key='/admin/linkusertocrm' component={LinkCrm} />
      <PrivateRoute exact={true} path='/admin/browseasuser' key='/admin/browseasuser' component={BrowseAsUser} data={{parentHistory}} />
      <PrivateRoute exact={true} path="/admin/history" key="/admin/history" component={AuditTrail} />
      <PrivateRoute exact={true} path="/admin/users" key="/admin/users" component={UserSearch} />
*/}
      {/* upcoming auctions */}
      <PrivateRoute exact path='/admin/listpreviewauctions' component={AdminPreviewAuctionPage} key='/admin/listpreviewauctions' />,

      {/* live auctions */}
      <PrivateRoute exact path='/admin/listpublicauctions' component={AdminPublicAuctionPage} key='/admin/listpublicauctions' />,

      {/* closed auctions */}
      <PrivateRoute exact path='/admin/listclosedauctions' component={AdminClosedAuctionsPage} key='/admin/listclosedauctions' />,
      
      {/* admin tab views */}
      <PrivateRoute exact path='/admin/auctiondetail' component={AdminAuctionTabs} key='/admin/auctiondetail' />,
      {/*<PrivateRoute exact path='/admin/testauctionhome' component={AdminTestAuctionPage} key='/admin/testauctionhome' />,*/}
      
      {/* auction actions */}
      <PrivateRoute exact path='/admin/additem' component={CreateItem} key='/admin/additem' />,
      <PrivateRoute exact path='/admin/items' component={AdminItemPage} key='/admin/items' />,
      <PrivateRoute exact path='/admin/deleteauctionuser' component={DeleteAuctionUser} key='/admin/deleteauctionuser' />,
      <PrivateRoute exact path='/admin/createauction' component={CreateAuction} key='/admin/createauction' />,
    </Switch>
  </div>
)

const hideHeader = (locationPathname) => {
  const path = locationPathname || window.location.href
  return (path.indexOf('/flow/auction') > -1)
}
const hideFooter = (locationPathname) => {
  const path = locationPathname || window.location.href
  return (path.indexOf('/flow/auction') > -1)
}

const appLocalStoragePersist = [
  "savedAuctionCode"
]
const myInformationRouting = (user) => (user?.crmUser) ? "/profile" : "/user/profileupdate"

export const auctions = {
  name: 'auctions',
  apiGatewayHost: process.env.REACT_APP_IDM_API_GATEWAY_HOST,
  routes,
  cognitoClientId: cognitoClientIds[process.env.REACT_APP_AWS_ENVIRONMENT || "dev"],
  isProdEnv: (process.env.REACT_APP_AWS_ENVIRONMENT === "prod"),
  logoImage,
  themeHeader,
  themeMenu: Menu,
  windowTitle: 'UCLA Auctions',
  signinText: "Please enter the email or cell phone number associated with your UCLA Auctions account.",
  registrationTitle: "UCLA Auction Registration",
  registrationText,
  crmUserLandingPage: '/myauctions',
  nonCrmUserLandingPage: '/myauctions',
  customPostLogin: doAuctionUser,
  appName: "Auction",

  // TODO - this should be Donor Relations after the first auction
  // but the site will need to change to show auction specific support team in most places
  supportTeamName: "UCLA Auctions",
  supportEmail: "publichealth@support.ucla.edu",

  themeAdminViewList: AdminViewList,
  themeAdminLinks,
  adminUserTest: "isAuctionAdmin",
  themeAnalytics: dummyAnalytics,
  extraGetUserParam: '?app=auctions',
  hideFooter,
  hideHeader,
  appLocalStoragePersist,
  myInformationRouting,
  appPaymentMethodsLink: '/mypaymentmethods',
  uclaFoundationLink: 'https://www.uclafoundation.org/Resources/Disclosures',
}

