import { useState, useEffect, useContext } from 'react'
import { useSelector } from "react-redux"

import { history } from 'services/history.js'
import { showLoading } from 'services/util'
import { ThemeContext } from 'ThemeContext'
import { urlQueryParser } from 'sites/auctions/util/urlQueryParser'

import { getAuctionUser } from '../redux/selectors'
import { registerUserForAuction } from '../api'

import css from './sass/payment.module.scss'
import themeCss from '../components/sass/theme.module.scss'

import { updateAuctionUser } from 'sites/auctions/api'
import {getBraintreeClientInstance, savePaymentMethod} from './paymentapi'
import UpdatePaymentBraintree from './UpdatePaymentBraintree'

export default function EnterPayment( {header, message, buttonText, finishCheckout, user} ) {

  const [clientInstance, setClientInstance] = useState()
  const [loadError, setLoadError] = useState('')

  const auctionUser = useSelector(getAuctionUser)

  const theme = useContext(ThemeContext)

  useEffect(() => {
    const formLoadError = 'There was an error with your card. Please try again or contact ' + theme.supportTeamName + ' support at ' + theme.supportEmail + '.';
    showLoading(true)

    async function setup() {
      try {
        setClientInstance(await getBraintreeClientInstance())
        showLoading(false)
      }
      catch (e) {
        setLoadError(formLoadError)
        console.error({msg: 'Error loading payment form.', e})
        showLoading(false)
      }
    }

    if (sessionStorage.altCrmId)  {
      setLoadError("Entering another user's credit card information in Browse as User mode is not allowed due to PCI compliance.")
      showLoading(false)
    }
    else {
      setup()
    }

    return () => {
      setClientInstance();
      // setLoadError(formLoadError)
    }
  }, [theme])

  // need method to load Card for load card variable
  const loadCard = {}

  return (
    <div className={css['payment-container']}>
      <h2 className={css['payment-header']}>{header}</h2>
      <p className={css['payment-text']}>{message}</p>
      
      {clientInstance &&
      <>
        <UpdatePaymentBraintree clientInstance={clientInstance}
                card={loadCard}
                loadError={loadError} 
                finishCheckout={finishCheckout} />
      </>}

      {loadError && <div className={themeCss['error-text']}>
              {loadError}
            </div>}
    </div>
  )
}