import { useEffect, useState, useContext } from 'react'

import { getHistoryList } from '../history/historyapi'
import { getLoggedInUser } from 'redux/selectors'
import store from 'redux/store'
import { showLoading } from 'services/util'
import { ThemeContext } from 'ThemeContext'

import PaymentMethodsSingle from './PaymentMethodsSingle'
import PaymentMethodsCopy from './PaymentMethodsCopy'

import css from '../payment/sass/payments.module.scss'

function PaymentMethods(props) {

  const theme = useContext(ThemeContext)
  const [fullHistory, setFullHistory] = useState([])
  const [hasPledge, setHasPledge] = useState(false);
  const [hasRecurringGift, setHasRecurringGift] = useState(false);
  const loggedInUser = getLoggedInUser(store.getState())
  const cardsOnFile = loggedInUser.crmUser.creditCards;

  useEffect( () => {
    async function callHistory() {
      showLoading(true)
      const loadHistory = await getHistoryList()
      //TO DO: update once giving history is updated with pledge
      setFullHistory(loadHistory);
      showLoading(false)
    }    
    callHistory();
  }, [])

  //useEffect to determine which copy is shown
  useEffect(() => {
    const cardRevenues = [];
    
    cardsOnFile.forEach((card) => {
      cardRevenues.push(...card.revenueLookupIdArray)
    })

    const giftTypes = fullHistory.filter((gift) => cardRevenues.includes(gift.revenueLookupId))
                    .map((gift) => gift.revenueTypeFull)

    //revenueTypeFull   => ['Recurring Gift', 'Pledge']
    //revenueType       => ['RG', 'ST']

    if (giftTypes.indexOf('Recurring Gift') > -1) setHasRecurringGift(true)
    if (giftTypes.indexOf('Pledge') > -1) setHasPledge(true)
    
  }, [fullHistory])

  return (
    <div className={css['payment-method-body']}>
      <h1 className={css['payment-method-header']}>Payment Methods</h1>
      <p className={`${css['payment-method-body__copy']} ${css['p-zero-spacing']}`}>
        <PaymentMethodsCopy hasRecurringGift={hasRecurringGift} hasPledge={hasPledge} />
      </p>
        {(!cardsOnFile || !cardsOnFile.length) &&
          <h4>No payment methods are saved to your account</h4>
        }
        {cardsOnFile && cardsOnFile.map((card, index) => <PaymentMethodsSingle card={card} key={index} fullHistory={fullHistory}/>)}
    </div>
  )
}

export default PaymentMethods