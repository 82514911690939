import { history } from 'services/history.js'

import whiteCheck from '../images/icon-check-white.svg'

import buttonStyle from '../sass/buttons.module.scss'
import css from '../payment/sass/success.module.scss'

function SuccessfulUpdatePayment(props) {

    // if user deep links, send them back to the payment methods page
    if (!props.location.state?.updatedCard) {
      history.push('/paymentmethods')
      return <></>
    }

  // need to account for if user navigates here on their own
  if (props.location?.state?.updatedCard) {
    return (
      <div className={css['successful-update-body']}>
        <h1>Credit Card Successfully Updated</h1>
        <img src={whiteCheck} alt='White cheek icon' className={css['white-check-icon']}></img>
        <div className={css['successful-update-buttons']}>
          <button
            onClick ={(e) => {history.push({ pathname: '/dashboard' })}}
            className={`${buttonStyle.button} ${buttonStyle.FilledBlue}`}>
              My Giving Gateway Overview
          </button>
          <button
            onClick ={(e) => {history.push({ pathname: '/paymentmethods' })}}
            className={`${buttonStyle.button} ${buttonStyle.StrokeBlue}`}>
              Update Other Payment Methods
          </button>
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export default SuccessfulUpdatePayment