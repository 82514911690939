import { useEffect } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { redirectAfterLogin } from "redux/actions";
import { getLoggedInUser } from "redux/selectors";

const PrivateRoute = ({ component: Component, props, data, ...rest }) => {
  const authenticatedUser = useSelector(getLoggedInUser)
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!authenticatedUser) {
      const destination = location.pathname + location.search
      console.log('PriaveRoute - redirectTo: ' + destination)
      dispatch(redirectAfterLogin(destination))
      sessionStorage.setItem('signinDestination', destination) // in case of page refresh, as in social login
    }
  }, [authenticatedUser, location, dispatch])

  return (
    <>
      <Route {...rest} render={(props) => (
        authenticatedUser
          ? <Component {...props} {...data} authenticatedUser={authenticatedUser} />
          : <Redirect to={{
              pathname: '/signin',
            }} />
      )} />
    </>
  )
}

export default PrivateRoute;