import React, { Component } from 'react'

import store from 'redux/store';
import { getCrmUser } from 'redux/selectors';
import { getCognitoUser } from 'redux/selectors';
import { showLoading } from 'services/util';

import * as cs from 'css/CommonStyle'
import * as forms from 'css/forms'
import * as colors from 'css/Colors'
import DropDownMenu from 'common/forms/DropDown'
import Alert from 'common/notifications/Alert'
import { phoneMedia } from 'common/DeviceDetect'
import success from 'images/TickMark.svg'
import iconInfo from 'images/icon-info-red.svg'

import { submitFeedback } from './contactusapi'
import { analyticsContactUs } from '../analytics'
import ContactUsCopyContent from './ContactUsCopyContent'

import ContactusImg from '../images/ContactUs.jpg'

import { ContactForm, Label, ContactFormContainer, ContactUsFormContent, ContactCopyImageSlide, ContactInnerImage, SubmitBtn } from './ContactUsStyles'

const FEEDBACK_REASONS = {
  feedbackReasons:["Comments/Suggestions","Feedback","Projects","Benefits","Other"]
}

class ContactUs extends Component {
  state = {
    fullname: '',
    email: '',
    phonenumber: '',
    reason: '',
    comments: '',
    isAuthenticated: false,
    isSubmitted: false,
    displaymsg: '',
    formSuccess:true
  }

  async componentDidMount() {
    // use CRM user name, email and phone - else user cognito user if we have it
    try {
      const cognitoUser =  getCognitoUser(store.getState());
      const crmUser = getCrmUser(store.getState());

      if (crmUser && crmUser.profile) {
        const profile = crmUser.profile;
        this.setState({
          fullname: profile.primaryName.firstName + " " + profile.primaryName.keyName,
          email: [profile.homeEmail, profile.businessEmail].filter(item => item && item.isPrimary).map(item => item.emailAddress)[0],
          phone: [profile.homePhone, profile.homeCell, profile.businessPhone, profile.businessCell].filter(item => item && item.isPrimary).map(item => item.number)[0],
          isAuthenticated: true
        });
      }
      else if (cognitoUser && cognitoUser.attributes) {
        this.setState({
          fullname: cognitoUser.attributes.given_name && cognitoUser.attributes.family_name ? cognitoUser.attributes.given_name + " " + cognitoUser.attributes.family_name : '',
          email: cognitoUser.attributes.email,
          phone: cognitoUser.attributes.phone_number,
          isAuthenticated: true
        });
      }
    }
    catch (e) {
    // user not authenticated
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const isPhone = phoneMedia.matches;
    if (this.validate()) {
      if (isPhone) {
        let container = document.getElementById('contact-form-container');
        container.scrollIntoView({behavior: 'smooth'});
      }
      else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }

      console.log('feedback submitted!')

      // analytics
      analyticsContactUs()

      let msg = '';

      showLoading(true);
      const formResponse = await submitFeedback(e);
      if (formResponse.success) {
        msg = "Thank you for your feedback or query. We will respond to you shortly."
        if (this.state.isAuthenticated) {
          this.setState({
            isSubmitted: true,
            comments: '',
            reason: '',
            formSuccess:true
          })
        }
        else {
          this.setState({
            isSubmitted: true,
            fullname: '',
            email: '',
            phonenumber: '',
            comments: '',
            reason: '',
            formSuccess:true
          })
        }
      }
      else {
        msg = formResponse.error && "There was an error submitting your feedback. Please call Donor Relations at (310) 794-2447."
        this.setState({
          isSubmitted: false,
          formSuccess:false
        })
      }
      showLoading(false);

      this.setState({ displaymsg: msg });
    }
    else {
      if (isPhone) {
        let fullnameLabel = document.getElementById('fullname-label');
        let emailLabel = document.getElementById('email-label');
        let commentsLabel = document.getElementById('comments-label');

        if (!this.state.fullname) fullnameLabel.scrollIntoView({behavior: 'smooth'});
        else if (!this.state.email) emailLabel.scrollIntoView({behavior: 'smooth'});
        else if (!this.state.comments) commentsLabel.scrollIntoView({behavior: 'smooth'});
      }
      else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
  }

  resetThenSet = (value, index, name) => {
    this.setState({
      reason: value
    })
  }

  validate = () => {
    if (this.state.fullname === '') this.setState({fullname: undefined});
    if (this.state.email === '') this.setState({email: undefined});
    if (this.state.comments === '') this.setState({comments: undefined});

    return this.state.fullname && this.state.email && this.state.comments;
  }

  render() {
    return (
      <ContactFormContainer ref={this.props.innerRef} id="contact-form-container">
        <ContactUsFormContent>
          {(this.state.formSuccess && this.state.isSubmitted) &&
            <div className="save-changes-alert">
              <Alert
              nonPromoPage
              icon={success}
              color={colors.guideGreen}
              width="20" height="15" top="40%" >
              <p>{this.state.displaymsg} </p>
              </Alert>
            </div>
          }

          {!this.state.formSuccess &&
            <div className="save-changes-alert">
              <Alert
              nonPromoPage
              icon={iconInfo}
              color={colors.messagingRed}
              width="25" height="25" top="35%" >
              <p>{this.state.displaymsg}</p>
              </Alert>
            </div>
          }

          <div className="contact-wrapper wrapper">
            <ContactForm onSubmit={this.handleSubmit}>
              <forms.Input id="contact-form" name="isAuthenticated" type="hidden" defaultValue={this.state.isAuthenticated} />
              <h4> {'We\'d like to hear  from you!'}</h4>
              <div className="contact-form">
                <div className="user-name-details">
                  <div>
                    <Label id="fullname-label">Full Name<span className="required">*</span></Label>
                    <cs.TextInputdiv>
                      <forms.Input
                        name="fullname"
                        placeholder="Joe Bruin"
                        className={this.state.fullname === undefined ? "error" : ""}
                        onChange={(e) => this.setState({fullname: e.target.value})}
                        onBlur={(e) => this.state.fullname === '' ? this.setState({fullname: undefined}) : ''}
                        value={this.state.fullname || ''}
                      />
                      {this.state.fullname === undefined &&
                        <cs.ErrorSpan textAlign="right" >Full Name cannot be left empty</cs.ErrorSpan>
                      }
                    </cs.TextInputdiv>
                  </div>

                  <div>
                    <Label id="email-label">Email<span className="required">*</span></Label>
                    <cs.TextInputdiv>
                      <forms.Input
                        name="email"
                        placeholder="joe@ucla.edu"
                        className={this.state.email === undefined ? "error" : ""}
                        onChange={(e) => this.setState({email: e.target.value})}
                        onBlur={(e) => this.state.email === '' ? this.setState({email: undefined}) : ''}
                        value={this.state.email || ''}
                      />
                      {this.state.email === undefined &&
                      <cs.ErrorSpan textAlign="right">Email cannot be left empty</cs.ErrorSpan>}
                    </cs.TextInputdiv>
                  </div>

                  <div>
                    <Label>Phone Number</Label>
                    <cs.TextInputdiv>
                      <forms.Input
                        name='phonenumber'
                        placeholder="555-123-4567"
                        onChange={(e) => this.setState({phonenumber: e.target.value})}
                        onBlur={(e) => this.state.phonenumber === '' ? this.setState({phonenumber: undefined}) : ''}
                        value={this.state.phonenumber || ''}
                      />
                    </cs.TextInputdiv>
                  </div>

                  <div className="contact-us-reasons"><Label>How can we help?</Label>
                    <cs.TextInputdiv>
                      <DropDownMenu
                        contactus
                        name="reason"
                        title={this.state.reason || "Select A Reason"}
                        list={FEEDBACK_REASONS.feedbackReasons}
                        value={this.state.reason || ''}
                        resetThenSet={this.resetThenSet}
                      />
                    </cs.TextInputdiv>
                    <forms.Input name="feedbackreason" type="hidden" value={this.state.reason} />
                  </div>

                </div> {/* end of username details  and first rowspan*/}

                <div className="">
                  <div>
                    <Label id="comments-label">Comments<span className="required">*</span></Label>
                    <cs.TextInputdiv>
                      <forms.TextArea
                        name="comments"
                        className={this.state.comments === undefined ? "error" : ""}
                        height="272px"
                        borderColor={"#C2D1D9"}
                        onChange={(e) => this.setState({comments: e.target.value})}
                        onBlur={(e) => this.state.comments === '' ? this.setState({comments: undefined}) : ''}
                        value={this.state.comments || ''}
                      />
                      {this.state.comments === undefined &&
                        <cs.ErrorSpan textAlign="right">Comments cannot be left empty</cs.ErrorSpan>
                      }
                    </cs.TextInputdiv>

                  </div>
                  <div>
                    <cs.TextInputdiv>
                      <SubmitBtn id="contactUsSubmit" > SUBMIT</SubmitBtn>
                    </cs.TextInputdiv>
                  </div>
                </div>
              </div>
            </ContactForm>

            <ContactCopyImageSlide className="" >
              <ContactInnerImage icon={ContactusImg} className="contact-image" />
              <ContactUsCopyContent />
            </ContactCopyImageSlide>
          </div>

        </ContactUsFormContent>
      </ContactFormContainer>
    )
  }
}

export default React.forwardRef((props, ref) => <ContactUs innerRef={ref} {...props} />);
