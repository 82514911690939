import { useEffect, useState, useMemo } from "react"
import { getAuctionStatus, hourTimer } from "../util/dateString"

export default function AuctionStatus( {auction, updateStatus, updateCountdown, forced } ) {
  const [date, setDate] = useState()
  
  useEffect(() => {

    if (forced === 'end') {
      if (updateStatus) updateStatus('ended')
      return
    }

    if (forced === 'live') {
      if (updateStatus) updateStatus('started')
      return
    }

    if (auction) {
      const status = getAuctionStatus(auction.StartTime, auction.EndTime)
      if (updateStatus) updateStatus(status)
      if (updateCountdown) {
        if (status === 'before') updateCountdown(hourTimer(date, auction?.StartTime))
        if (status === 'started') updateCountdown(hourTimer(date, auction?.EndTime))
      }
    }

    const timer = setInterval(() => setDate(new Date()), 100)

    return function cleanup() {
      clearInterval(timer)
    }
  }, [auction, date, forced, updateStatus, updateCountdown])

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 100)

    return function cleanup() {
      clearInterval(timer)
    }
  })

  return (
    <>
    </>
  )
}
