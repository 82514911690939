import { history } from 'services/history.js'
import { formatMoneyCents } from 'services/util'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/auctions.module.scss'
import auctionCardCss from './sass/auctionscards.module.scss'
import AuctionEndedCardPaid from './AuctionEndedCardPaid'

export default function AuctionEndedCard({ auction, currentUserId, route }) {

  const amountDue = auction.Items.reduce((total, item) => {
    if (item.CurrentHighBidder === currentUserId && !item.Paid) {
      return total + item.CurrentBid
    } else {
      return total
    }
  }, 0)

  const proceedToCheckout = () => {
    if (route !== 'auction') localStorage.setItem('myItems', true)
    
    history.push({
      pathname: '/checkout',
      search: `?auctionId=${auction.AuctionCode}`,
    })
  }

  console.log(localStorage.myItems)

  if (amountDue === 0) return <AuctionEndedCardPaid auction={auction} />

  return(

         <div className={themeCss['box-top-yellow-accent-wide']} >
            <p className={auctionCardCss['auction-box-info-heading']}>Thank you for participating!</p>
            <p className={auctionCardCss['auction-box-info-text']}>Below are the items you have won.</p>
            <p className={css['auction-amount-due-label']}>Amount Due</p>
            <p className={css['auction-amount-due']}>{`$${formatMoneyCents(amountDue)}`}</p>
            
            <button
                  onClick ={() => proceedToCheckout()}
                  className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['button-to-checkout']}`}
                >Proceed to Checkout</button>
          </div>
  )
}