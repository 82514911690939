import { history } from 'services/history'
import { signOut } from 'authentication/signout'
import store from 'redux/store';
import { setIsLoading } from 'redux/actions';

export const showLoading = (loading, srcTracker) => store.dispatch(setIsLoading(loading, srcTracker))

export const doLink = (path, state) => {
  console.log('doLink!!!', path, state);
  history.push(path, state);
}

let loginTimeout;
const sessionTimeout = 58*60*1000;
export const resetLoginTimeout = async () => {
  console.log('resetLoginTimeout');
  if (localStorage.getItem('sessionEnds') && localStorage.getItem('sessionEnds') < (new Date()).getTime()) {
    await signOut();
    return;
  }

  if (loginTimeout) clearTimeout(loginTimeout)
  loginTimeout = setTimeout(signOut, sessionTimeout);
  await localStorage.setItem('sessionEnds', (new Date()).getTime() + sessionTimeout);
  return;
};

export const doTimeout = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const deleteCookie = (name, path, domain) => {
  if ( getCookie(name) ) {
    document.cookie = name + "=" +
    ((path) ? ";path="+path:"")+
    ((domain)?";domain="+domain:"") +
    ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

function getCookie(name) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export const combineClasses = (cssmodule, classname='', ...args) => {
  return args.filter(attr => attr).map(attr => cssmodule[attr]).join(' ') + ' ' + classname;
}

// create a "normalized" date object given a string
export const initializeDate = ( date ) => {
  let newDate = new Date()

  if ( date ) {
    if ( typeof date === 'string' ) {
      let matches = date.match(/^([0-9]{4})-([0-9]{1,2})-([0-9]{1,2})/)

      if (matches) {
        newDate.setYear( matches[1] )

        // need to set month and day at the same time or JS will change the date is month doesn't allow the date (IE - 6/31 becomes 7/1)
        const month = parseInt(matches[2],10) - 1;
        newDate.setMonth( month, matches[3] )
      }

    }
    else {
      newDate.setYear( date.getFullYear() )
      // need to set month and day at the same time or JS will change the date is month doesn't allow the date (IE - 6/31 becomes 7/1)
      newDate.setMonth( date.getMonth(), date.getDate() )
    }
  }

  newDate.setHours( 0 )
  newDate.setMinutes( 0 )
  newDate.setSeconds( 0 )
  newDate.setMilliseconds( 0 )

  if ( isNaN( newDate.valueOf() ) ) {
    return false
  }

  return newDate
}

export const formatMoney = amount => amount.toLocaleString('en-US', {maximumFractionDigits:2, minimumFractionDigits:2}).replace(/\.00$/, '')

export const formatMoneyCents = amount => amount.toLocaleString('en-US', {maximumFractionDigits:2, minimumFractionDigits:2})