import { Route } from 'react-router-dom';

import logoImage from './images/Boxed-Alumni-Portal-Logo-v3.svg';
import Menu from './components/Menu'
import Login from 'authentication/routes/Login';
import Help from './Help'
import ascHeader from './sass/navigation.module.scss'

const redirectTest = (ssoRedirectUrl) => {
  return ( ssoRedirectUrl.indexOf('https://dev.alumnischolarsclub.alumni.ucla.edu/') === 0
        || ssoRedirectUrl.indexOf('http://local.alumnischolarsclub.alumni.ucla.edu/') === 0
        || ssoRedirectUrl.indexOf('http://localhost') === 0
        || ssoRedirectUrl.indexOf('https://stage.alumnischolarsclub.alumni.ucla.edu/') === 0
        || ssoRedirectUrl.indexOf('https://preprod.alumnischolarsclub.alumni.ucla.edu/') === 0
        || ssoRedirectUrl.indexOf('https://alumnischolarsclub.alumni.ucla.edu/') === 0
  )
}

const cognitoClientIds = {
  "dev": "rlrhmvkc5emhq7e0ien7ue2o6",
  "stage": "62k0ckrg1iot5hcj1bpk4jc2k5",
  "preprod": "6irlpsaraci5n4ukl2ho38fet5",
  "prod": "42l4qo4lsmoe8l4ekap9egdb33",
}

// const themeHeader = (<div className={ascHeader.container}>
//                       <a className={ascHeader['return-link']} href="javascript:window.close();">Return to Alumni Scholars Club Site</a>
//                     </div>)

const hideFooter = () => {
  return true
}

const routes = [
  <Route exact={true} path='/' component={Login} key="/aschome" />,
  <Route exact={true} path='/help' component={Help} key="/help" />,
]

const REGISTRATION_TEXT = (<>
                            Please use the email on which you received your invitation.
                            If that email is not available, contact Alumni Scholars Club support
                            at <a href="mailto:asc@alumni.ucla.edu">asc@alumni.ucla.edu</a>.
                            <br/>(<a href="https://ucla-ea-as.atlassian.net/wiki/spaces/ASC/overview#How-To:-Log-In" target="_blank">click here for general help</a>)
                          </>)

const SIGN_IN_TEXT = (<>
                        Please enter the email associated with your Alumni Scholars Club account.
                        <br/>(<a href="https://ucla-ea-as.atlassian.net/wiki/spaces/ASC/overview#How-To:-Log-In" target="_blank">click here for general help</a>)
                      </>)

const registrationText = (
  <>
    <span className="block-title bold">
      {REGISTRATION_TEXT}
    </span>
  </>)

// IMPORTANT: ASC inherits from alumni
export const asc = {
  name: 'asc',
  apiGatewayHost: process.env.REACT_APP_IDM_API_GATEWAY_HOST,
  routes,
  cognitoClientId: cognitoClientIds[process.env.REACT_APP_AWS_ENVIRONMENT || "dev"],
  logoImage,
  themeHeader: null,
  hideFooter,
  themeMenu: Menu,
  windowTitle: 'UCLA Alumni Scholars Club',
  signinText: SIGN_IN_TEXT,
  registrationTitle: "Alumni Scholars Club Portal Registration",
  registrationText,
  redirectTest,
  setSsoCookieForCrmUser: true,
  setSsoCookieForNonCrmUser: false,
  captureNonCrmUser: true,
  nonCrmUserLandingPage: '/help',
  supportTeamName: "Alumni Scholars Club",
  supportEmail: "asc@alumni.ucla.edu",
  appName: "UCLA Alumni Scholars Club Sign In Portal",
  extraGetUserParam: '?app=asc',
  forceLogoutOnRedirect: true,
  addTokenToUrlInDev: (process.env.REACT_APP_AWS_ENVIRONMENT === "dev"),
};

