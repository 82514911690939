import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Auth } from 'aws-amplify';
import { redirectSignedInUser } from "authentication/helper";
import { doPostLogin } from "authentication/postlogin";
import { showLoading } from 'services/util'

import { ErrorSpan } from 'css/CommonStyle';
import { Input } from 'css/forms'
import { FilledBlueButton } from '../components/Buttons'

import { CommonOuterDiv, TextSpan, CommonFooter, CommonInnerDiv, InnerContent, TextInputdiv, FooterBtnDiv } from 'css/CommonStyle';

class RegistrationVerify extends Component {
	constructor(props) {
		super(props);
		this.state = {
			verificationCode: '',
		};
		this.verificationcodeinput=React.createRef();
	}

	verificationChange = (event) => {
		this.setState({ verificationCode: event.target.value });
	}

	hadleConfirmRegistration = async (event) => {
		event.preventDefault();

		const { username, password } = this.props.location.state.authParams;
		showLoading(true)

		try {
			await Auth.confirmSignUp(username, this.state.verificationCode);
			await Auth.signIn(username, password);
      doPostLogin({username, password});
		}
		catch (err) {
			console.log(err);

			showLoading(false)
			this.setState({ error: err.message || err });

			if (err.code === "NotAuthorizedException") {
  	    this.props.history.push({pathname:'/signin', state: {isBadPasswordOnReverify:true, username}});
  	  }
    }
    this.setState({resendCodeMsg:''})
	}

	resendCode = async () => {
		showLoading(true);

		try {
			await Auth.resendSignUp(this.props.location.state.authParams.username);
			this.setState({ resendCodeMsg: "Your code has been re-sent." ,error:''});
			this.verificationcodeinput.current.value="";
		}
		catch (err) {
			this.setState({ resendCodeMsg: err.message || err });
		};
  	showLoading(false)
	}

	async componentDidMount() {
    // don't let user accidentally click back and wind up on this page
    if (redirectSignedInUser())
    	return;

		if (this.props.location.state.isFromSignIn)
			await Auth.resendSignUp(this.props.location.state.authParams.username);
	}

	render() {
		return (
			<CommonOuterDiv >
				<CommonInnerDiv>
					<InnerContent>
						<div>
							<p>A Verification code has been sent to <TextSpan>{this.props.location.state.authParams.username}.</TextSpan></p>
							<p>Please enter the code below to verify your account. For security purposes, this code will expire in 30 minutes.</p>
						</div>

  					<form onSubmit={this.hadleConfirmRegistration}>
							<TextInputdiv>
								<Input
								width="260px"
								name="verificationcode"
								ref={this.verificationcodeinput}
								className={this.state.error ? "error" : ""}
								onChange={this.verificationChange}
								placeholder="Enter the code" />

								{this.state.error &&
									<ErrorSpan>
										<label>{this.state.error}</label>
									</ErrorSpan>
								}

								{/*  {this.state.error &&
	          <div className="resetCode-password-error">
	            <strong className='pwd-err-msg'>{this.state.error}</strong>
	          </div>} */}
							</TextInputdiv>
							<FooterBtnDiv>
								<FilledBlueButton onClick={this.hadleConfirmRegistration}>SUBMIT</FilledBlueButton>
							</FooterBtnDiv>
						</form>
					</InnerContent>
				</CommonInnerDiv>

				<CommonFooter>
					<p>Haven’t received the verification email/text?</p>
					<span onClick={this.resendCode} id="resendCodeLink"><TextSpan fontStyle="italic"><b> Resend </b><i className="fa fa-chevron-right" aria-hidden="true"></i></TextSpan></span>
					{this.state.resendCodeMsg &&
						<p><label className=" bold resendmsg">{this.state.resendCodeMsg}</label></p>}
				</CommonFooter>

			</CommonOuterDiv>
		)
	}
}

export default withRouter(RegistrationVerify);
