import { Component } from 'react';

import { emailRegex } from "authentication/validators"
import RadioButton from './CustomizedRadio'
import DropDownMenu from 'common/forms/DropDown'
import DeleteNonPrimary from './DeleteNonPrimary'
import MoreInfo from 'common/MoreInfo'
import { MoreInfoPositionStyles } from 'profile/contact/Address'
import * as cs from 'css/CommonStyle'
import * as forms from 'css/forms'
import { ProfileDataContainer, ProfileDataContent } from 'profile/personal/MyNames'

import styled from 'styled-components'
import { device } from 'common/Device'

const EmailDataContent=styled(ProfileDataContent)`
.email-fields-info{
  flex:0 75%;
  display:flex;
  justify-content:space-between;
  & >div{
    flex:0 45%;
  }
}

@media ${device.tablet}{
.email-fields-info{
  flex:0 95%;
}
@media ${device.mobile}{
  .email-fields-info{
    flex:0 90%;
    flex-direction:column;
  }
}
`

class Email extends Component {
 constructor(props) {
  super(props);
  this.state = {
   emailIdValid: false,
   isEmptyEmail: false
  }
 }
 async componentDidMount() {
  if (emailRegex.test(this.props.data.emailAddress)) this.setState({ emailIdValid: true, isEmptyEmail: false });
 }

 handleValidation = (e) => {
  switch (e.target.name) {
   case this.props.propName + '.emailAddress':
    this.setState({
     emailIdValid: emailRegex.test(e.target.value) ? true : false,
     isEmptyEmail: e.target.value ? false : true
    }, this.updateValidation); break;
   default:
    break;
  }
 }
 updateValidation = (e) => {
  this.props.updateValidation(!this.state.emailIdValid || this.state.isEmptyEmail)
 }

 render() {
  return (

   <ProfileDataContainer>
    <fieldset>
     <EmailDataContent>

      <div className="email-fields-info">
        <div>
        <label> Type</label>
        <cs.TextInputdiv>
          <DropDownMenu
          readOnly={this.props.readOnly}
          name={this.props.propName + '.emailType'}
          title={this.props.data.emailType || "Select an Email Type"}
          list={this.props.availableDisplayTypes}
          changeHandler={this.props.handleTypeChange}
          />
        </cs.TextInputdiv>
        </div>

        <div>
        <label> Email<span className="required">*</span></label>
        <cs.TextInputdiv>
          <forms.Input
          className={(!this.state.emailIdValid && this.state.isEmptyEmail) ? "error" : ""}
          name={this.props.propName + '.emailAddress'}
          value={this.props.data.emailAddress}
          onChange={(e) => { this.props.changeHandler(e); this.handleValidation(e); }}
          onFocus={this.handleValidation}
          onBlur={this.handleValidation}
          />
        </cs.TextInputdiv>
        {(!this.state.emailIdValid && this.state.isEmptyEmail) &&
          <cs.ErrorSpan textAlign="right">{"Email Address is required and must be valid"}</cs.ErrorSpan>}
        </div>
      </div>

      <div className="primary-contact">
       <RadioButton htmlFor={this.props.propName}>
        <input type="radio" id={this.props.propName}
         className="form-check-input"
         name="EmailPrimary"
         value={this.props.propName}
         checked={this.props.data.isPrimary}
         onChange={(e) => this.props.setPrimary('email', this.props.propName)}
        /> Primary
              <span className="checked-circle"></span>
       </RadioButton>
      </div>


      {this.props.remove ?
       <DeleteNonPrimary deletepropName={this.props.propName} remove={this.props.remove} /> :
       <MoreInfoPositionStyles>
        <MoreInfo
         htmlName={"more_info_primary_email_details"}
         htmlId={"more_info_primary_email_id_details"}
         srText={"Identifying the primary email?"}>
         This is the method we will use to contact you via email.
               </MoreInfo>
       </MoreInfoPositionStyles>}


     </EmailDataContent>
    </fieldset>

   </ProfileDataContainer>

  )
 }
}

export default Email;
