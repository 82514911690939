import { Component } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';

import { showLoading } from 'services/util'

import { CommonOuterDiv,  CommonInnerDiv, InnerContent, FooterBtnDiv } from 'css/CommonStyle';
import EmailPhoneToggle from '../components/EmailPhoneToggle'
import { FilledBlueButton } from '../components/Buttons'


const ResetPasswordToggleContent = styled.div`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
`

class ResetPasswordStart extends Component {
	state = {
		username: '',
		inputIsEmail: true
	};

	componentDidMount() {
		let { currentUsername } = this.props.location.state ? this.props.location.state : {currentUsername:""};
		if (!isNaN(currentUsername)) {
			this.setState({inputIsEmail: false});
		}
		else if (!currentUsername.includes('@')) {
			currentUsername = '';
		}
		this.setState({username: currentUsername});
	}

	sendCode = async (event) => {
		event.preventDefault();

		const data = new FormData(event.target);
		let username = data.get('username').trim();

		if (!username) {
			this.setState({ error: "Please enter an email address or US mobile phone number." });
			return;
		}

		const usernameType = data.get('usernameType');

		// remove parens and dashes in phone number
		if (usernameType === 'phone') username = username.replace(/[(\-)]/g, '');

		showLoading(true)

		try {
			await Auth.forgotPassword(username);
			this.props.history.push({ pathname: '/resetpasswordfinish', state: { username } })
		}
		catch (err) {
		  const error = err.code === 'UserNotFoundException' ? 'Username not found. You may need to re-register.' : err.message
		  this.setState({ error });
		}
  	showLoading(false)
	};

	render() {
		return (
		<div >
			<CommonOuterDiv>
					<h4 id="pageTitle" className="bold">Reset your password</h4>

				<CommonInnerDiv>
					<InnerContent>
						<div>
							<p>Please enter the email or mobile phone number you signed up with, and we'll send you a code to reset your password.</p>
						</div>

						<form onSubmit={this.sendCode}>
							<ResetPasswordToggleContent className="email-phone-toggle">

								<EmailPhoneToggle bgColor={"#FFFFFF"}
									savedUsername={this.state.username}
									usernameError={this.state.error}
									usernameIsEmail={this.state.inputIsEmail} />

								<FooterBtnDiv>
									<FilledBlueButton>SEND THE CODE</FilledBlueButton>
								</FooterBtnDiv>
							</ResetPasswordToggleContent>
						</form>
					</InnerContent>
				</CommonInnerDiv>
				</CommonOuterDiv>
			</div>
		)
	};
}

export default withRouter(ResetPasswordStart)
