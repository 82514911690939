import { Component } from 'react'

import { RemoveModalStyles } from './RemoveModalStyles'
import { FilledBlue, StrokeBlue } from 'css/Buttons';
import { SubmitButtons } from '../MyInformationStyles';

import deleteIcon from 'images/deleteIcon.svg'

import styled from 'styled-components'

const DeleteContainer=styled.div`
position: absolute;
right: 0;
top:0;

&&&{
margin: 0;
flex:none;
}
.delete-icon{
  position:relative;
  cursor:pointer;
}`

class DeleteNonPrimary extends Component {

  constructor(props) {
    super(props);
    this.state = {
       deleteModal:false
    }
  }
  deleteRecord = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    })
  }

render(){
  return(
  <DeleteContainer>
   <div className="delete-icon">
    <span onClick={this.deleteRecord}  >
      <img src={deleteIcon} alt='delete' /> </span>
   </div>

   {this.state.deleteModal&&
   <RemoveModalStyles>
    <div>
      <p className="split-line">Are you sure you want to
      remove?  </p>
      <SubmitButtons className="remove-action-buttons">
        <StrokeBlue onClick={this.deleteRecord}><span > CANCEL</span></StrokeBlue>
        <FilledBlue onClick={(e) => this.props.remove(this.props.deletepropName)}><span> YES</span></FilledBlue>
      </SubmitButtons>
     </div>
   </RemoveModalStyles>
   }
  </DeleteContainer>
    )
  }
}

export default DeleteNonPrimary;
