import { useState} from 'react'

import { history } from 'services/history.js'

import { startVerify } from './verifyUtils'

import css from '../sass/flow.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

export default function EmailVerification() {

  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()

  const submitInfo = () => {

    try {
      const codeResponse = startVerify(email)
      console.log(codeResponse)

      history.push({
        pathname: '/flow/emailverificationcode',
        email
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className={css['flow-container-w-margin']}>
      <h2 className={css['centered']}>Complete Account Set-Up</h2>
      <p className={css['centered']}>Please enter your name and email address to complete account set-up.</p>
      <div className={css['flow-inputs']}>
        <label>First Name</label>
        <input type='text' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
      </div>
      <div className={css['flow-inputs']}>
        <label>Last Name</label>
        <input type='text' value={lastName} onChange={(e) => setLastName(e.target.value)} />
      </div>
      <div className={css['flow-inputs']}>
        <label>Email Address</label>
        <input type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>

      <button 
        onClick ={() => submitInfo()}
        className={`${themeCss['FilledBlue']} ${themeCss['button']} ${css['centered-button']}`}>Complete Account Set-Up</button>
    </div>
  )
}