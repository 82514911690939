import styled,{css} from 'styled-components'

import { CloseButton } from 'css/Buttons'
import * as colors from 'css/Colors'
import {device} from 'common/Device'

export const AlertOuterDiv=styled.div`
  flex:1 1 ${ 117/1368 * 100}%;

  button {
    margin:0 auto;
  }
`

export const CloseAlert = styled( CloseButton )`
  /* margin:0 calc(40rem/16) 0 calc(67rem/16); */
  cursor:pointer;

  @media ${device.tablet}{
    width:${15/16}rem;
    height:${15/16}rem;
   }

  @media ${device.mobile}{
    margin:0;
  }
`

const AlertContainer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  position:relative;
  overflow:hidden;
  -ms-grid-column-span: 12;
  grid-column-end: span 12;

  background-color: ${ props => props.backgroundColor || '#FFFFC3' };
  border-radius:calc(5rem/16);
  cursor:default;

  padding:calc(23rem/16) 0 calc(19rem/16) calc(15rem/16);

  &:before {
    content:'';
    display:block;
    position:absolute;
    left:0;
    top:0;
    height:100%;
    width:calc(5rem/16);
    background-color:${props => props.color || colors.messagingRed };
  }

  /* tablet breakpoint */
  @media ${device.tablet} {
    border-radius:0;
    justify-content: flex-start;
  }

  @media ${device.mobile}  {
    ${ props => {
      return (!props.nonPromoPage) && css`
        &:before {
          width:100%;
          height:calc(5rem/16);
        }
      `
    }}
  }
`

const AlertIcon = styled.i`
display:block;
height:${props => (props.height&&`calc(${props.height}rem/16)`) || "calc(38rem/16)" };
width:${props => (props.width&&`calc(${props.width}rem/16)`) || "calc(38rem/16)"};
margin:0 calc(15rem/16);
background:url(${props => props.icon }) no-repeat;
background-size:contain;

@media ${device.mobile} {
margin:0;
width:calc(28rem/16);
height:calc(28rem/16);
}
`

const AlertMessage = styled.div`
font-style:italic;
padding:0 ${props => props.nonPromoPage ?`calc(60rem/16)`:`${ 30/1440 * 100}%`};
flex:1 1 ${ 1168/1368 * 100 }%;
p {
  margin:0;
 }

 @media ${device.mobile}  {
  padding:0 calc(30rem/16);
}

`


export const AlertIconContainer=styled.div`
/* position: absolute; */
`

const AlertGrid = styled.div`
/* tablet breakpoint
padding:0 ${props=>props.nonPromoPage?`15px 0 0`:`30px`};
*/
padding-right:${15/16}rem;
margin-top: ${props => props.padded ? "20px" : "0"};
margin-bottom: 40px;

${AlertIconContainer}{
  /* top: ${props=>props.top||`${30/16}rem`};
  left: ${props=>props.left||`${10/16}rem`}; */
}

@media ${device.tablet} {
&&&{
  grid-gap: 0 2.193%;
}
  &&&{
  width: 100% ;
  max-width:100% ;
  padding:0 ;
  margin:0 ;
  }
}

@media ${device.mobile} {
  /*
  ${AlertIconContainer}{
    top:${25/16}rem;

  }
     &&&{
 margin-left:0;
  } */
}
`

const Alert = props => {
  const { onClick, ...other } = props
  return(
  <AlertGrid {...other} >
    <AlertContainer backgroundColor={props.backgroundColor} color={props.color}>
       { other.icon && <AlertIcon icon={other.icon}/> }
      <AlertMessage {...other} />
      {typeof onClick === 'function' &&
      <AlertOuterDiv>
        <CloseAlert onClick={ e => onClick(e) }/>
      </AlertOuterDiv>
      }
    </AlertContainer>
  </AlertGrid>
  )
}


export default Alert
