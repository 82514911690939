import styled from 'styled-components'

import { device } from 'common/Device'
// import giveToUclaImage from 'images/ucla.jpg'

export const defaultPrintFontSize = 10

export const PrintPage = styled.div`
  box-sizing:border-box;
  margin:${ 20/defaultPrintFontSize }em auto ${ 55/defaultPrintFontSize }em;
  width:${ 460 / 612 * 100}%;
  page-break-after:always;
  /* break-inside:avoid-page; */

  &.no-page-break {
    page-break-after:auto;
  }

  @media screen and ${ device.tablet } {
    /* had to eyeball this */
    width:${ 606 / 641 * 100}%;
  }

  @media screen and ${ device.mobile } {
    width:100%;
    padding:0 ${ 15/320 * 100 }%;

    margin:${ 16/defaultPrintFontSize }em auto ${ 35/defaultPrintFontSize }em;
  }
`
export const ReceiptStyles = styled.div`
  padding:${ 10/defaultPrintFontSize }em 0;
  border-top:1px solid #000000;

  break-inside: avoid-page;

  @media screen and ${ device.mobile } {
    padding:${ 14/defaultPrintFontSize }em 0;
  }
`

export const LegalCopy = styled.p`
  font-size:${ 10/defaultPrintFontSize * 100 }%;
  margin:0 0 ${ 16/defaultPrintFontSize }em;
  line-height: ${ 14/defaultPrintFontSize };
`

export const LegalMainHeader = styled.h1`
  font-size:${ 17/defaultPrintFontSize * 100}%;
  margin:0 0 ${ 8/defaultPrintFontSize }em;
  color:#000000;
  font-weight:600;
`

export const LegalSectionHeader = styled.h2`
  font-size:${ 12/defaultPrintFontSize * 100}%;
  margin:0 0 ${ 1/defaultPrintFontSize}em;
  font-weight:600;
  text-transform:none;
`

export const ProfileHeader = styled.h2`
  font-size:${ 12/defaultPrintFontSize * 100}%;
  line-height:1.4;
  margin:0;
  font-weight:600;
  text-transform:uppercase;
`

export const ProfileSubHeader = styled.h3`
  color:#000000;
  font-size:${ 11/defaultPrintFontSize * 100}%;
  line-height:1.4;
  margin:0;
  font-weight:600;
`

export const ReceiptSummaryHeader = styled.h2`
  font-size:${ 12/defaultPrintFontSize * 100}%;
  line-height:1;
  margin:0 0 ${ 8/defaultPrintFontSize }em;
  font-weight:600;
  text-transform:uppercase;
`

export const GiftLineItem = styled.span`
  display:flex;
  align-items:flex-end;
  justify-content:flex-end;
  width:${ 111/defaultPrintFontSize }em;


  &.has-underline {
    border-bottom:1px dashed #000000;
  }

  @media screen and ${ device.mobile } {
    width:50%;
  }
`

export const GiftItemHeader = styled.span`
  display:block;
`
