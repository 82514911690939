import { withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'

import { device } from 'common/Device'
import { StrokeBlue }  from 'css/Buttons'
import MyInfoIcon from 'images/MyInformation.svg'

import MoreSummary from './MoreSummary'
import ContactUsCopyContent from '../contactus/ContactUsCopyContent'
import ContactUsIcon from '../images/icon-contact-us.svg'
import FAQIcon from '../images/icon-faqs.svg'


const DashboardLinks=styled.div`
  position: relative;
  h3{
    margin:0;
  }
  .contact-us-copy{
    p{
      font-size:1rem;
    }
  }

  @media ${device.tablet}{
    h3 {
      margin:1rem 0;
    }
  }
`

const Links = props => (

  <DashboardLinks className="grid" >
    <div className="span-4 ">
      <MoreSummary icon={MyInfoIcon} >
        <h3 className="h4 has-dots">My Information</h3>
        <p>Update your mailing address, phone number or email.</p>
        <StrokeBlue
        className="link-buttons-1"
        as={ Link }
        to="/profile">
          Update
        </StrokeBlue>
      </MoreSummary>
    </div>
    <div className="span-4 ">
      <MoreSummary icon={FAQIcon} >
        <h3 className="h4 has-dots">Frequently Asked Questions</h3>
        <p>
          <StrokeBlue
          className="link-buttons-2"
          as={ Link }
          to="/faq">
            FAQs
          </StrokeBlue>
        </p>
      </MoreSummary>
    </div>
    <div className="span-4 ">
      <MoreSummary icon={ContactUsIcon}>
        <h3 className="h4 has-dots">Contact Us</h3>
        <ContactUsCopyContent />
      </MoreSummary>
    </div>
  </DashboardLinks>
)

export default withRouter(Links)
