import { Component } from 'react';
import MyNames from './MyNames';
import BirthDate from './BirthDate';
import Spouse from './Spouse';
import { formatBirthDate, adjustBirthDate } from '../constants'
import EditIcon from 'images/EditIcon.svg';
export const editIcon = (<img src={EditIcon} alt='' />);

class MyInformationPersonal extends Component {
  birthdateChange = event => {
    const newBirthDate = adjustBirthDate(this.props.data.birthDate, event.target.value, event.target.index, event.target.name);
    this.props.changeHandler({target:{value:newBirthDate, name:"birthDate"}})
  }


  render () {
		let	userMaritalStatus = null;

		if (this.props.data) {
			userMaritalStatus = {
				maritalstatus: this.props.data.maritalStatus,
				spouseName: this.props.data.spouseName
			}
		}

	  return (
			<div>
				<h4 className="bold"> Name</h4>
				<p>The name you enter here will be used for legal/tax purposes, and will appear exactly as you enter it on any receipts or mailings you receive from UCLA.</p>
				{this.props.data &&
					<div>
						<MyNames
						isClean={this.props.isClean}
						updateValidation={this.props.updateValidation}
						nameData={this.props.data.primaryName}
						changeHandler={this.props.changeHandler}
						titleSuffixTypes={this.props.bioType} />

            <BirthDate
            changeHandler={this.birthdateChange}
            userBirthdateInfo={formatBirthDate(this.props.data.birthDate)}
            updateValidation={this.props.updateValidation}
            />
						<Spouse changeHandler={this.props.changeHandler} readOnly={this.props.data.spouseReadOnly} userMaritalStatus={userMaritalStatus} />
					</div>}
			</div>
		)
	}
}

export default MyInformationPersonal;
