import { useState, useEffect } from 'react';

import store from 'redux/store';
import { getLoggedInUser } from 'redux/selectors';
import { history } from 'services/history.js'
import { showLoading, initializeDate } from 'services/util'

import { getReceiptBio } from '../history/historyapi'
import Overlay from '../components/Overlay'
import PrintReceiptPreview from '../history/PrintReceiptPreview'

import PledgeDetail from './PledgeDetail'
import { getPledges } from './pledgesapi'

import { StrokeBlue } from 'css/Buttons'
import css from './sass/pledges.module.scss'


function PledgesPage(props) {
  const [allPledges, setAllPledges] = useState()
  const [showPayAllPledgesDueButton, setShowPayAllPledgesDueButton] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [printInstallment, setPrintInstallment] = useState(null)
  const [isSingle, setIsSingle] = useState(false)

  const loggedInUser = getLoggedInUser(store.getState())
  const receiptBio = getReceiptBio()

  useEffect(() => {
    setErrorMsg()
    loadPledges()
  }, [])

  const loadPledges = async () => {
    showLoading(true)

    const pledges = await getPledges()

    const revId = window.location.href.substring(window.location.href.indexOf('/pledges') + 9)

    if (revId) {
      // match revId to any installment in pledge
      setIsSingle(true)
      setAllPledges(pledges.filter(pledge => pledge.installments.filter(installment => installment.revenueLookupId === revId).length > 0))
    }
    else {
      // sort by descending date with unpaid pledges on top
      setAllPledges(pledges.sort((a,b) => initializeDate(a.date) < initializeDate(b.date) ? -1 : 1)
                    .sort((a,b) => a.pledgeBalance === 0 && b.pledgeBalance !== 0 ? 1 : -1))
      setShowPayAllPledgesDueButton(pledges.filter(pledge => pledge.pledgeBalance > 0).length > 0)
    }

    showLoading(false)
  }

  const printReceipt = (installment) => {
    setPrintInstallment(installment)
  }

  return (
    <>
      <div className={`${css["pledges-page"]} hide-on-print`}>

        <h1 id="landingPageTitle">{isSingle ? "Pledge Detail" : "Pledges"}</h1>
        {allPledges && allPledges.length !== 0 &&
          <p>This page will display all pledges you have made to UCLA, both those with a remaining balance and those that have been fully paid.
              Please click "SHOW PAYMENT HISTORY" to see individual payments on each pledge. </p>}

        <br/>
        {allPledges && allPledges.length === 0 &&
          <p>You have not made any pledges to UCLA.
             If you would like to make your first pledge, please
             visit <a alt="UCLA Giving Site" rel="noreferrer" target="_blank" href="https://giveto.ucla.edu/">giveto.ucla.edu</a>.</p>}

          <>
            {allPledges &&
              <StrokeBlue
                className={`${css["header-button"]} ${css["first-header-button"]}`}
                height={"2.6"}
                borderWidth={2}
                onClick={() => history.push('/history')}
                >VIEW ALL GIVING HISTORY</StrokeBlue>}

             {allPledges && allPledges.length !== 0 && showPayAllPledgesDueButton && <StrokeBlue
              className={css["header-button"]}
              height={"2.6"}
              borderWidth={2}
              onClick={() => history.push("/paypledge/all")}
              >PAY ALL PLEDGES DUE</StrokeBlue>}


            {allPledges && allPledges.length !== 0 && allPledges.map((pledge, index) =>
              <PledgeDetail pledge={pledge} key={index} printReceipt={printReceipt}/>
            )}
          </>
      </div>

      {printInstallment &&
        <Overlay onHideOverlay={() => setPrintInstallment(null)}>
          <PrintReceiptPreview
            gifts={[printInstallment]}
            bio={ receiptBio }
            crmId={ loggedInUser.constituentLookupId }
          />
        </Overlay>}
    </>
  )
}

export default PledgesPage;