import { useState, useEffect } from 'react'

import img from '../auctions/images/banner-demo.jpg'
import { history } from 'services/history.js'

import { formatDate } from './userUtils'

import css from './sass/mypages.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import { Link } from 'react-router-dom'
import CustomMarkdown from 'sites/auctions/components/CustomMarkdown'

export default function AuctionCardUpcoming({auction, isLive}) {

  return (
    <div className={css['mypages-card']}>
      {/* <img className={css['myauctions-image']} src={img}/> */}
      {isLive && <p className={css['myauctions-live']}>AUCTION IS LIVE</p>}
      <h2 className={`${css['mypages-headings']} ${css['myauctions-title']}`}>
        <Link className={css['card-image']} to={{ pathname: '/auctionhome', search: `?auctionId=${auction.AuctionCode}`}}>
          {auction.Title}
        </Link>
      </h2>
        <button onClick ={(e) => {history.push({ pathname: '/auctionhome', search: `?auctionId=${auction.AuctionCode}`}) }}
          className={`${themeCss['button']} ${themeCss['FilledWhite']} ${css['myauctions-button-link']}`}>
          Go To Auction
        </button>
      {isLive ? <div className={css['mypages-closedate']}>
        <h3>Auction Closes:</h3>
        <h4>{formatDate(auction.EndTime)}</h4>
      </div>
       :
      <h3 className={css['mypages-headings']}>{formatDate(auction.StartTime)}</h3>}

      <h3 className={css['mypages-headings']}>Location: {auction.AuctionLocation}</h3>
      <CustomMarkdown text={auction.Description} cssClass={themeCss['truncate-fourline']} />
      {/*<p className={themeCss['truncate-fourline']}>{auction.Description}</p>*/}
      
    </div>
  )
}