import styled from 'styled-components'

import { device } from 'common/Device'

const PersonalprojectsContainer = styled.div`
  text-align:center;
  text-decoration:none;
  flex: 1 0 10%;

  display:inline-block;

  @media ${device.laptop}{
     & img{
       width:80px;
     }
  }
  @media ${device.tablet}{

    & img{
      width:62%;
    }
  }
  @media ${device.mobile} {
   flex:  0 50%;
   margin-bottom:${25/16}rem;
    & img{
      width:50%;
    }
  }
`

const PromoPersonalProjects = props => (
  <PersonalprojectsContainer
  href={props.personalprojectforward}
  as={"a"}
  target="_blank">
    <img src={props.personalprojecticon} alt={props.personalprojecttitle}/>
    <h6 className="bold has-no-margins">{props.personalprojecttitle.toUpperCase()}</h6>
  </PersonalprojectsContainer>
)

export default PromoPersonalProjects;
