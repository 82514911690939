import styled from 'styled-components'

import * as colors from 'css/Colors'

const defaultFontSize = 16

export const AdminList = styled.div`
  font-size: ${14/defaultFontSize}rem;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .divider {
    padding: 10px 0 10px 10px;
    color: #2774AE;
    font-size: 18px;
    font-weight: bold;
    background-color: #FFFFC3;
    line-height: 7px;
  }
  hr {
    padding: 0;
    margin: 0;
  }
`

export const AdminListItem = styled.li`
  position: relative;
  text-transform: uppercase;
  box-sizing: border-box;
  border-bottom: 1px solid #FFFFFF;
  height: 60px;

  &:first-child {
    border-top: 1px solid #FFFFFF;
  }

  & > a {
    display: flex;
    justify-content: flex-start;
    height: 100%;
    text-decoration: none;
    color: #fff;
    padding:0 10%;
    &:hover {
      color: #fff;
      background-color: ${colors.secondaryMediumBlue};
    }
  }

  div {
    display: flex;
    align-items: center;
  }

  span {
    margin:0 ${17/defaultFontSize}rem;

  }
  .selectedTab {
    background-color: ${colors.secondaryMediumBlue};

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: calc(7rem/${defaultFontSize});
      background-color: ${colors.primaryYellow};
    }
  }

  img {
    width: 38px;
  }
`