import styled from 'styled-components'

import * as cs from 'css/CommonStyle';
import * as forms from 'css/forms';
import EmailPhoneToggle from 'authentication/components/EmailPhoneToggle'
import { FilledYellow } from 'css/Buttons'

const InputWrap = styled.div`
  flex: 1 0 100px;
  height: 3.125rem;
  margin-right: 0.625rem;

  .toggle-container {
    margin-top: 0;
  }
`;

const LinkUserForm = (props) => (
  <div>
    <h6 className="bold">{props.title}</h6>
    <form onSubmit={props.onSubmit}>
      <label className="link-user-info-block-title">{props.inputTitle}</label> {props.children}
      <cs.TextInputdiv className="user-search-form" >
        <InputWrap>
          {props.formType === 'user' && <EmailPhoneToggle onChange={props.onChange} />}
          {props.formType === 'crm' && <forms.ClearableInput type="text" name={props.inputName} onChange={props.onChange} onFocus={(e) => e.target.select()} />}
        </InputWrap>
        <FilledYellow
          className="search-user-name"
          width="7.0625rem"
          bgColor={"#FFD100"}
          fontColor={"#005587"}
          borderColor={"#FFD100"}
          name={props.btnName}
        >
          <span> Search</span>
        </FilledYellow>
      </cs.TextInputdiv>
    </form>
  </div>
)

export default LinkUserForm;