import themeCss from '../components/sass/theme.module.scss'
import css from './sass/auctions.module.scss'
import UnregisterButton from './UnregisterButton'
import CustomMarkdown from 'sites/auctions/components/CustomMarkdown'

export default function AuctionStatusClosed({ auctionDetail, auctionUser, userAttending}) {

  return (
    <div className={css['auction-container']}>

      <h2 id="landingPageTitle">
        {auctionDetail.Title}
      </h2>
      
      <div className={themeCss['blue-background']}>
        <p className={css['auction-description-unreg']}>This auction has been closed by the event staff. If you believe this is an error, please contact the auction administrator.</p>
        <p><CustomMarkdown text={auctionDetail.Description} cssClass={css['auction-description-unreg']} /></p>
      </div>

      {/* {userAttending && <UnregisterButton auctionDetail={auctionDetail} auctionUser={auctionUser} />} */}
    </div>
  )
}