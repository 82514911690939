
//////////////////////////////////////////////////////////////////////////////////////
// IMPORTANT: This component only deletes the Auction User.
//            It does not delete the CIDM user.
//            Only MGG admin can do that.
//////////////////////////////////////////////////////////////////////////////////////

import { Component } from 'react'
import { withRouter } from "react-router-dom"
import styled from 'styled-components'

import { showLoading } from 'services/util'
import { adminErrorHandler } from 'admin/adminutil'
import LinkUserWrapper from 'admin/linkuser/LinkUserWrapper'
import UserProfile from 'admin/linkuser/UserProfile'
import LinkUserForm from 'admin/linkuser/LinkUserForm'
import Alert from 'common/notifications/Alert'
import DeleteConfirmModal from 'common/ConfirmModal'
import UserProfileInfopopup from 'admin/linkuser/UserProfileInfopopup'
import * as colors from 'css/Colors'
import { FilledBlue } from 'css/Buttons'

import iconInfo from 'images/iconColorinfo.svg'
import AdminDeleteIcon from 'images/x-red.svg'

import { deleteAuctionUser, adminFindUser } from "./auctionsadminutil"

const defaultFontSize = 16

const ModalInner = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;
  img {
    height: 40px;
    width: 40px;
    padding: 0 10px;
  }
  .modal-text {
    padding: 0 10px;
    p {
      margin: 0;
      max-width: 450px;
    }
  }
`
const UserInformation = styled.div`
  color:#fff;
  padding:20px;
  border-radius:5px;
  background-color:${ props => props.crm ? `${colors.secondaryMediumBlue}` : `${colors.primaryBlue}`};
  /* min-height:${315/defaultFontSize}rem; */
  h6 {
    color:#fff;
    margin: 0 0 ${18/defaultFontSize}rem;
  }
  .user-search-form {
    margin-left: 0;
  }
  .user-search-form {
    margin: 0;
  }
  .crm-user-information{
    background-color: #FFFFC3;
  }
  .smaller-alert>div>div {
    padding: 0 calc(10rem/16);
  }
  .mismatch-error{
    color:#000000;

  }
  .link-donor-info-block-title{
    font-size:${16/defaultFontSize}rem;
  }
  .account-display{
    font-size:${14/defaultFontSize}rem;
  }

  .alert>div>div {
    padding-left: 0;
  }
`
const SubmitButtons = styled.div`
  text-align:center;
  margin:${30 / defaultFontSize}rem;
  & > div {
    display: inline-block;
  }
  a {
    text-decoration: none;
  }
`

class DeleteAuctionUser extends Component {

  state = {
    userLookup: '',
    adminFoundUser: '',
    userNotFound: false,
    deleteUserResponse: '',

    username: null,
    isClean: true
  }

  handleSubmit = async e => {
    e.preventDefault()

    showLoading(true)

    const data = new FormData(e.target)

    let lookup = data.get('username').trim()

    if (lookup && lookup.charAt(0) === '+') {
      lookup = '+' + lookup.replace(/\D/g,'')
    }

    const response = await adminFindUser(lookup)


    if (response.error) {
      this.setState({
        username: '',
        adminFoundUser: '',
        userNotFound: true,
      })
    }
    else if (response) {
      this.setState({
        username: lookup,
        adminFoundUser: response,
        userNotFound: false,
      })
    }

    showLoading(false)
  }

  handleChange = e => {
    this.setState({
      userLookup: e.target.value.trim(),
      adminFoundUser:'',
      username: null,
      userNotFound: false,
      isClean: true
    })
  }

  handleDeleteUser = async e => {
    e.preventDefault()
    showLoading(true)

    const username = this.state.username
    const response = await deleteAuctionUser(username)
    this.setState({
      deleteUserResponse: response,
      adminFoundUser:'',
      userNotFound: false,
      username: null,
      isClean: true
    })
    // console.log(response)

    showLoading(false)
  }


  render() {
    return (
      <>
        {!this.state.isClean &&
          <DeleteConfirmModal
            onConfirm={this.handleDeleteUser}
            onCancel={() => {this.setState({isClean: true})}}
            cancelText={"CANCEL"}
            confirmText={"OK"}
          >
            <ModalInner>
              <div><img src={AdminDeleteIcon} alt='Delete User icon'/></div>
              <div className="modal-text">
                <p className="bold">Confirm Auctions User Account Deletion?</p>
                <p>Are you sure you want to delete this user from the Auctions Application?<br></br></p>
              </div>
            </ModalInner>
          </DeleteConfirmModal>
        }

        <LinkUserWrapper>
          <h2>Delete Auctions User</h2>

          {this.state.deleteUserResponse.error &&
            <Alert
              className="smaller-alert"
              nonPromoPage
              icon={iconInfo}
              color={colors.messagingRed}
              width="25" height="25" top="35%" >
              <div className="mismatch-error">Could not delete user. Error: "{this.state.deleteUserResponse.error.msg}"</div>
            </Alert>
          }

          <div className=" grid">
            <div className="span-12">
              <UserInformation>
                <LinkUserForm
                  title={"Auction User's Information"}
                  inputName={"userId"}
                  btnName={"userSearch"}
                  inputTitle={"Enter the Email/Phone Number that the Auction User uses to login"}
                  formType="user"
                  onSubmit={this.handleSubmit}
                  onChange={this.handleChange}
                >
                  <UserProfileInfopopup/>
                </LinkUserForm>

                {this.state.userNotFound &&
                  <Alert
                    className="alert"
                    padded
                    nonPromoPage
                    icon={iconInfo}
                    color={colors.messagingRed}
                    width="25" height="25" top="35%" >
                    <div className="mismatch-error">{adminErrorHandler(this.state.adminFoundUser.error, `No Account found with username "${this.state.userLookup}". Please check the username and try again.`)}</div>
                  </Alert>}

                {this.state.adminFoundUser &&
                  <div className="account-display">
                    <p><strong><u>Account Found</u></strong> <br/></p>
                    <table>
                      <tr><td><b>First Name:</b></td><td>{this.state.adminFoundUser.FirstName || 'None'}</td></tr>
                      <tr><td><b>Last Name:</b></td><td>{this.state.adminFoundUser.LastName || 'None'}</td></tr>
                      <tr><td><b>Contact Email:</b></td><td>{this.state.adminFoundUser.ContactEmail || 'None'}</td></tr>
                      <tr><td><b>Contact Phone:</b></td><td>{this.state.adminFoundUser.ContactPhone || 'None'}</td></tr>
                      <tr><td><b>CRM ID:</b></td><td>{this.state.adminFoundUser.CrmId || 'None'}</td></tr>
                    </table>

                    <p><em><b>NOTE:</b> Deleting this user this will not affect the user’s other UCLA logins (My Giving Gateway, Alumni Portal, UCLA One, etc.)
                      or their CRM Record in Blackbaud.</em></p>

                  </div>}

              </UserInformation>
            </div>
          </div>

          <SubmitButtons>
            <div>
              <FilledBlue name="deleteUser"
                disabled={!this.state.adminFoundUser}
                onClick={() => {this.setState({isClean: false})}}>
                <span> DELETE USER</span>
              </FilledBlue>
            </div>
          </SubmitButtons>

          <br/>
          <br/>
          <br/>

        </LinkUserWrapper>
      </>
    )
  }
}

export default withRouter(DeleteAuctionUser)
