import { Component } from 'react';

import RadioButton from './CustomizedRadio'
import DropDownMenu from 'common/forms/DropDown'
import DeleteNonPrimary from './DeleteNonPrimary'
import MoreInfo from 'common/MoreInfo'
import { MoreInfoPositionStyles } from 'profile/contact/Address'

import * as cs from 'css/CommonStyle'
import * as forms from 'css/forms'
import { ProfileDataContainer, ProfileDataContent } from 'profile/personal/MyNames'
import { CONTACT_COUNTRIES } from 'common/forms/data/Countries'

import styled from 'styled-components'
import { device } from 'common/Device'

const AlignPhoneRecord = styled(ProfileDataContent)`

.phone-fields-info{
  flex:0 75%;
  display:flex;
  justify-content:space-between;

    & > div{
      flex:0 30%;
      max-width:30%;
    }
  }
  .primary-contact{
    margin-left:30px;
  }

@media ${device.tablet}{
  .phone-fields-info{
    flex:0 95%;
   }
    .primary-contact{
      margin:0;
    }
}

@media ${device.mobile}{
  .phone-fields-info
  {
    flex:0 90%;
    flex-direction:column;
    flex-wrap:nowrap;
    & >div{
      flex:0 100%;
      max-width:none;
      }
  }
}
`

class Phones extends Component {
 constructor(props) {
  super(props);
  this.state = {
   isEmptyPhone: false,
  }
 }

 handleValidation = (e) => {
  switch (e.target.name) {
   case this.props.propName + '.number':
    this.setState({ isEmptyPhone: e.target.value ? false : true }, this.updateValidation); break;
   default:
    break;
  }
 }

 updateValidation = (e) => {
  this.props.updateValidation(this.state.isEmptyPhone)
 }

 render() {
  return (

   <ProfileDataContainer>
    <fieldset>
     <AlignPhoneRecord>

      <div className="phone-fields-info">
        <div>
          <label> Type</label>
          <cs.TextInputdiv>
          <DropDownMenu
            readOnly={this.props.readOnly}
            name={this.props.propName + '.phoneType'}
            title={this.props.data.phoneType}
            list={this.props.availableDisplayTypes}
            changeHandler={this.props.handleTypeChange}
          />
          </cs.TextInputdiv>
        </div>

        <div>
          <label> Country</label>
          <cs.TextInputdiv>
          <DropDownMenu
            name={this.props.propName + '.country'}
            title={this.props.data.country || "Select a Country"}
            list={CONTACT_COUNTRIES}
            changeHandler={this.props.changeHandler}
          />
          </cs.TextInputdiv>
        </div>

        <div>
          <label> Phone Number<span className="required">*</span></label>
          <cs.TextInputdiv>
          <forms.Input
            className={this.state.isEmptyPhone ? "error" : ""}
            name={this.props.propName + '.number'}
            value={this.props.data.number}
            onChange={this.props.changeHandler}
            onFocus={this.handleValidation}
            onBlur={this.handleValidation}
          ></forms.Input>
          </cs.TextInputdiv>
          {(this.state.isEmptyPhone) &&
          <cs.ErrorSpan textAlign="right">Phone Number cannot be left empty</cs.ErrorSpan>
          }
        </div>

      </div>

      <div className="primary-contact">
       <RadioButton htmlFor={this.props.propName}>
        <input type="radio" id={this.props.propName}
         className="form-check-input"
         name="PhonePrimary"
         value={this.props.propName}
         checked={this.props.data.isPrimary}
         onChange={(e) => this.props.setPrimary('phone', this.props.propName)}
        /> Primary
              <span className="checked-circle"></span>
       </RadioButton>
      </div>

      {this.props.remove ?
       <DeleteNonPrimary deletepropName={this.props.propName} remove={this.props.remove} /> :
       <MoreInfoPositionStyles>
        <MoreInfo
         htmlName={"more_info_primary_phone_details"}
         htmlId={"more_info_primary_phone_id_details"}
         srText={"Identifying the primary phone?"}>
         This is the method we will use to contact you via phone.
               </MoreInfo>
       </MoreInfoPositionStyles>}

     </AlignPhoneRecord>
    </fieldset>
   </ProfileDataContainer>

  )
 }
}

export default Phones;
