import defaultImage from '../components/images/logo_UCLA_blue_boxed.png'

let origin = window.location.origin
if (origin === "http://localhost:3000") origin = "https://pilot.auctions.support2.ucla.edu"

export const getImageUrl = (auctionCode, itemCode, name) => {
  if (name === '') return defaultImage
  return `${origin}/auctions/images/${auctionCode}/${itemCode}%7C${name}`
}



// const [imageUrl, setImageUrl] = useState()

// // use effect for auction banner image
// useEffect(() => {
//   // const url = getImageUrl(auction?.AuctionCode, 'banner', auction?.BannerImageName)
//   // const url = getImageUrl(auction?.AuctionCode, item?.ItemCode, item?.ImageFilename)
//   setImageUrl(url)
// }, [auction])