import styled from 'styled-components'
import { device } from 'common/Device'
import { primaryBlue, lightGray, borderGray, messagingGreen } from 'css/Colors'
import arrowUpBlue from 'images/arrow-up-primary-blue.svg'
import Zindex from 'css/z-indexLayers.js';

import checkGreen from '../images/check-green.svg'
import iconPaymentDue from '../images/icon-clock-blue.svg'
import iconPaymentReveived from '../images/icon-check-green.svg'

// math things
const defaultFontSize = 16

export const FloatIt = styled.div`
  margin:0 0 0 1rem;
  float:right;

  @media ${ device.mobile } {
    float:none;
    order:2;
    margin:1rem 0 0;
  }
`
export const NoPledgeInstallmentsMessage = styled.h3`
  margin:0;
  padding:0 0 2rem 2rem;
`
export const HistoryTitle = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  @media ${ device.mobile } {
    flex-direction:column-reverse;
    align-items:flex-start;
  }
`
export const HistoryLayout = styled.div`
  background:#FFFFFF;
  padding-bottom:${ 20/defaultFontSize }rem;
`
export const HistoryLayoutColumn = styled.div`
  display:flex;
  width: 100%;
  height: 100%;
  padding-top: 0;
  flex-direction:column;
  justify-content:space-between;
  p {
    margin-block-start: 0;
    padding-bottom:0;
  }
`

// used to provide overrides to base wrapper styles
export const HistoryIntro = styled.div`
  background:#FFFFFF;
  padding-top:${ 35/defaultFontSize }rem;
  h1 {
    margin:0;
  }
  p {
    margin-top:0;
  }
  @media ${ device.tablet } {
    padding: ${ (30/defaultFontSize) }rem ${ (24/defaultFontSize) }rem ${ (15/defaultFontSize) }rem;
  }
  @media ${ device.mobile } {
    display:flex;
    flex-direction:column;
    h1 {
      order:1;
    }
  }
`

// toggle visibility
export const SortWrapper = styled.div`
  display:none;

  @media screen and ${ device.tablet } {
    display:flex;
    justify-content:flex-end;
  }
`

// used in HistoryNested to wrap both dropdowns when they appear on tablet/mobile
export const DropdownWrapper = styled.div`
  display:flex;
  justify-content:flex-end;

  @media screen and ${ device.mobile } {
    justify-content:space-between;
  }
`

/* TODO: lots of WCAG compliance checking */
export const HistoryDropdownStyles = styled.div`
  /* sort is only visible on tablet / mobile */
  display:none;
  height:${ 35/defaultFontSize }rem;

  position:relative;
  flex-direction:row;
  align-items:center;

  @media ${ device.tablet } {
    display:flex;
  }

  label {
    display:inline-block;
    vertical-align:middle;
    font-size:${ 14/defaultFontSize }rem;
    white-space:nowrap;
  }

  & > button {
    display:inline-block;
    vertical-align:middle;
    border:none;
    background:transparent;
    white-space:nowrap;

    color:${ primaryBlue }
    font-weight:600;
    font-size:${ 14/defaultFontSize }rem;

    padding:${ 2.5/defaultFontSize }rem;
    margin:0 0 0 ${6/defaultFontSize}rem;
    cursor:pointer;


    &:after {
      content:'';
      display:inline-block;
      vertical-align:middle;
      height:${ 5/defaultFontSize }rem;
      width:${ 8/defaultFontSize }rem;
      margin-left:${ 2/defaultFontSize }rem;

      background-size:contain;
      background-repeat:no-repeat;
      background-position:50% 50%;
      background-image:url('${ arrowUpBlue }');

      transform:rotate(0.5turn);

    }
  }

  ul {
    display:block;
    position:absolute;
    z-index:1000;
    right:${ -3/defaultFontSize }rem;
    top:${ 27/defaultFontSize }rem;
    padding:0;
    margin:0;

    background:#FFFFFF;
    border:1px solid ${ borderGray };
    box-shadow: 0 ${2/defaultFontSize}rem ${10/defaultFontSize}rem 0 rgba(0,0,0,0.3);

    li {
      display:flex;
      align-items:center;
      margin:0;
      border-bottom:1px solid ${ borderGray };
      padding:${ 6/defaultFontSize }rem ${ 12/defaultFontSize }rem ${ 6/defaultFontSize }rem ${ 6/defaultFontSize }rem;

      &:hover {
        background:${ lightGray }

        > button {
          background:${ lightGray }
        }
      }

      & > button {
        display:block;
        width:100%;
        height:100%;
        padding:${ 6/defaultFontSize }rem ${ 34/defaultFontSize }rem ${ 6/defaultFontSize }rem ${ 6/defaultFontSize }rem;
        box-sizing:border-box;
        border:none;
        cursor:pointer;
        line-height:${ 10/defaultFontSize }rem;
        font-size:${ 14/defaultFontSize }rem;
        text-align:left;
        white-space:nowrap;
        background:#FFFFFF;
      }

      &.selected {
        & > button {
          font-weight:600;
          color:${ primaryBlue };
        }

        &:after {
          content:'';
          background-size:contain;
          background-repeat:no-repeat;
          background-position:50% 50%;
          background-image:url('${ checkGreen }');
          display:block;
          width:1rem;
          height:1rem;
        }
      }



      &:last-child {
        border-bottom:none;
      }
    }
  }
`

// "payment due" messaging
export const PaymentDue = styled.div`
  display:inline-block;
  vertical-align:middle;
  color:${ primaryBlue };
  font-weight:600;
  text-transform:uppercase;

  &:before {
    content:'';
    display:inline-block;
    vertical-align:middle;
    background:url(${ iconPaymentDue }) no-repeat;
    background-size:contain;
    height:1rem;
    width:1rem;
    margin-right:${ 5/defaultFontSize }rem;
  }
`

// payment received messaging
export const PaymentReceived = styled.div`
  display:inline-block;
  vertical-align:middle;
  color:${ messagingGreen };
  font-weight:600;
  text-transform:uppercase;

  &:before {
    content:'';
    display:inline-block;
    vertical-align:middle;
    background:url(${ iconPaymentReveived }) no-repeat;
    background-size:contain;
    height:${ 13/defaultFontSize }rem;
    width:1rem;
    margin-right:${ 5/defaultFontSize }rem;
  }
`

export const ResetButton = styled.button`
  display:inline-block;
  border:none;
  background:transparent;
  color:#FFFFFF;
  font-weight:600;
  font-size:${ 14/defaultFontSize }rem;
  text-transform:uppercase;
  padding:${ 8/defaultFontSize }rem;
  margin:0 0 0 ${ 12/defaultFontSize }rem;
  cursor:pointer;
`

export const NoResults = styled.div`
  text-align:center;
  margin-top:${ 101/defaultFontSize }rem;
  padding-bottom:${ 200/defaultFontSize }rem;

  h2 {
    font-size:${ 32/defaultFontSize };
    color: #000000;
    margin:0;
  }

  p {
    margin:${ 8/defaultFontSize }rem 0 0;
  }
`

export const ReceiptActionStyles = styled.div`
  position:relative;
  display:flex;
  flex-direction:column;
  align-items:flex-end;

  /* override positioning - this is all imaginary numbers */
  .more-info-container {
    position: absolute;
    right:115%;
    // The below styles are causing the overlap with other info popups
   /*  top:0;
    transform: translateY( 50% );
    z-index:${ Zindex.zindexPopUpStackOrder1 }; */
  }

  .print-button-container {
    position:relative;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
  }

  .gift-actions-header {
    display:none;
    text-transform:uppercase;
    color:${ primaryBlue };
    font-weight:600;
    margin-right:${ 15/defaultFontSize }rem;
  }

  .installment-container {
    display:flex;
    align-items:center;

    span {
      margin-right:${ 4/defaultFontSize }rem;
    }
  }

  a {
    margin-top:${ 12/defaultFontSize }rem;
    margin-right:${ -15/defaultFontSize }rem;
  }

  @media ${ device.tablet } {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;

    &.expandable-row {
      justify-content:flex-end;
    }

    .print-button-container {
      display:flex;
      flex-direction:row;
      align-items:center;

      .more-info-container {
        position:relative;
        right:auto;
        transform:none;
        order:2;
        margin-right:0;
        margin-left:${ 15/defaultFontSize }rem;
      }
    }

    .arrow-right-link {
      position:absolute;
      margin-top:0;
      right:${ 26/defaultFontSize }rem;
      top: 0;
      transform: translateY(50%);
    }

    .gift-actions-header {
      display:block;
    }
  }

  @media ${ device.mobile } {
    flex-wrap:wrap;
    justify-content:space-between;

    .arrow-right-link {
      position:static;
      transform:none;
      margin-right:${ -2/defaultFontSize }rem;
    }

    .gift-actions-header {
      flex:1 0 100%;
      padding-bottom:${ 5/defaultFontSize }rem;
    }
  }
`

export const GiftTypeStyles = styled.div`
  span {
    display:inline-block;

    &.has-dash{
      white-space:nowrap;

      &:after {
        display:inline-block;
        content:'-';
        margin:0 ${ 4/defaultFontSize }rem;
      }
    }
  }
`
