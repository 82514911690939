
const SCREENS = {
  mobile: 319,
  tablet: 767,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560
};


export const device = {
  mobile: `(max-width: ${SCREENS.tablet}px)`,
  tablet:  `(max-width: ${SCREENS.laptop}px)`,
  laptop: `(max-width: ${SCREENS.laptopL}px)`,
  laptopL: `(min-width: ${SCREENS.laptopL})px`,
  desktop: `(max-width: ${SCREENS.desktop})px`,
};
