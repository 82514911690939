import React from 'react'
import styled from 'styled-components'
import { DayPicker } from "react-day-picker";
import { throttle } from 'throttle-debounce'

import { initializeDate } from 'services/util'
import { device } from 'common/Device'
import Zindex from 'css/z-indexLayers.js';
import calendarIcon from 'images/calendar.svg'
import { lightGray, darkGray, primaryYellow, primaryBlue } from 'css/Colors'

import DateTextEntry from './DateTextEntry'
import DatePickerNav from './DatePickerNav'

const defaultFontSize = 16
const datePickerWidth = 450

const TheOverlay = styled.div`
  position:fixed;
  top:0;
  left:0;
  height:100%;
  width:100vw;
  z-index:${Zindex.zindexPopUpStackOrder1};
  background:rgba(0,0,0,0.4);
`

const TheCarat = styled.i`
  content:'';
  display:block;
  position:absolute;
  z-index:${parseInt(Zindex.zindexPopUpStackOrder2,10) + 1};
  bottom:${ -26/defaultFontSize }rem; /* 20 margin, 5 is half height, 1 is border */
  left:calc(50% - ${ 5/defaultFontSize }rem);
  transform:rotate(45deg);
  background:#FFFFFF;
  height:${(10/defaultFontSize)}rem;
  width:${(10/defaultFontSize)}rem;
  border-top: none;
  border-left:1px solid #FFFFFF;
  border-right:1px solid #FFFFFF;
  border-bottom:1px solid #FFFFFF;
  box-shadow:-4px -2px 8px -2px rgba(0,0,0,0.2);

  @media ${ device.tablet } {
    bottom:${  -21/defaultFontSize }rem;
  }
`

const DateEntryStyles = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  position:relative;
  width:100%;
  box-sizing:border-box;

  ${ TheOverlay } {
    display:none;
  }

  label {
    min-width:${ 38/defaultFontSize }rem;
    text-align:center;
  }

  .date-picker-container {
    position:relative;
    z-index:${Zindex.zindexStackOnMainContentOrder2};
    box-sizing:border-box;
    height:${ 20/defaultFontSize }rem;
    width:${ 20/defaultFontSize }rem;

    &:focus {
      outline:none;
    }

    .styled-date-picker, ${ TheCarat } {
      display:${ props => props.open? 'block' : 'none' };
    }
  }

  .date-picker-toggle {
    position:relative;
    z-index:${Zindex.zindexOverlaystackOrder1};
    opacity:0;
    height:100%;
    width:100%;

    margin:0;
    cursor:pointer;

    &:focus {
      outline:none;
    }
  }

  @media screen and (max-width:1180px) {
    justify-content:space-around;
  }

  @media ${ device.mobile } {
    flex-wrap:wrap;
    justify-content:space-between;
    padding:0 ${ 12/defaultFontSize }rem ${ 7/defaultFontSize }rem 0;

    label {
      flex:1 0 100%;
      text-align:left;
      padding-left:${ 8/defaultFontSize }rem;
    }

    .date-picker-toggle {

      & ~ ${ TheOverlay } {
        display:${ props => props.open? 'block' : 'none' };
      }
    }
  }
`

const CalendarIcon = styled.img`
  position:absolute;
  z-index:1;
  top:0;
  left:0;
  display:block;
  height:100%;
`

// custom date picker styling
const StyledDatePicker = styled.div`
  position:absolute;
  top:100%;
  left:${ props => props.left !== null? `${props.left}%` : `${10 / defaultFontSize}rem` };
  margin-top:${ 20/defaultFontSize }rem;
  transform:translateX(-50%);
  z-index:${Zindex.zindexPopUpStackOrder2};
  box-sizing:border-box;
  width:${ props => props.width !== null? `${props.width}%` : `${datePickerWidth / defaultFontSize}rem` };
  max-width:100vw;
  background:#FFFFFF;
  box-shadow:0 0 10px 0 rgba(0,0,0,0.2);
  border-radius: 4px;
  padding:${ 20/defaultFontSize }rem;

  .DayPicker {
    margin-top:${ 18/defaultFontSize }rem;
    &-NavBar, &-Caption {
      display:none;
    }

    /* ie11 hacks */
    &-Week, &-WeekdaysRow {
      box-sizing:border-box;
      display:-ms-grid;
      -ms-grid-columns: (${ 1/7 * 100 }%)[7];
      -ms-grid-rows: auto;
    }

    ${
      props => {
        let ieHack = []
        for (let i = 1; i <= 7; i++) {
          ieHack.push(`
          &-Week .DayPicker-Day:nth-child(${ i }), &-WeekdaysRow .DayPicker-Weekday:nth-child(${ i }) {
            -ms-grid-column-span:1;
            -ms-grid-column:${ i };
          }
          `)
        }

        return ieHack
      }
    }
    /* end ie11 hacks */

    &-Week, &-WeekdaysRow {
      width:100%;
      display:grid;
      grid-template-columns:repeat(7, ${ 1/7 * 100 }%);
    }

    &-Weekday, &-Day {
      border-radius:0;
      margin:0 ${ 8/datePickerWidth * 700 }%;
      text-align:center;
      cursor:pointer;
    }

    &-Weekday {
      height:${ 24/defaultFontSize }rem;
      color:${ primaryBlue };
      font-weight:600;
      font-size:${ 12/defaultFontSize }rem;
      text-transform:uppercase;

      abbr {
        text-decoration:none;
      }
    }

    &-Day {
      display:flex;
      flex-direction:column;
      justify-content:center;
      box-sizing:border-box;
      height:${ 40/defaultFontSize }rem;


      &--outside {
        color: ${ darkGray };
        background:${ lightGray };
        cursor:default;
      }

      &--selected {
        background: ${ primaryYellow };
        color:${ primaryBlue };
        font-weight:600;
      }

      &:hover, &--highlighted {
        color:${ primaryBlue };
      }
    }
  }


  @media ${ device.tablet } {
    margin-top:${ 15/defaultFontSize }rem;
  }
`

class DateEntry extends React.PureComponent {
  constructor( props ) {
    super( props )

    this.state = {
      open: false,
      width: null,
      left: null,
    }

    this.calendarRef = React.createRef()

    this.handleResize = throttle( 200, this.movePicker )
  }

  datePickerStyles() {
    // const { width, left } = this.state


    // if (width !== null || left !== null) {
    //   // reuse the original math otherwise bounds will have already changed
    //   return { width, left }
    //
    // } else {
      const bounds = this.calendarRef.current.getBoundingClientRect()
      const offsetParentBounds = this.calendarRef.current.offsetParent.getBoundingClientRect()
      const formBounds = this.props.formRef.current.getBoundingClientRect()
      const gutterWidth = parseFloat(formBounds.left === 0? window.getComputedStyle( this.props.formRef.current ).getPropertyValue('padding-left').replace('px','') : '15')
      // force mobile layout if the width of the calendar is within this distance from either gutter
      const pixelFudgeFactor = 10
      const innerWidth = document.getElementsByTagName('html')[0].clientWidth - ( 2 * gutterWidth)

      // do we have enough space to do the calculation normally?
      if (innerWidth - bounds.width - 2 * pixelFudgeFactor > 0) {
        // check against gutters and adjust position accordingly
        if ( offsetParentBounds.left + (offsetParentBounds.width - bounds.width) / 2 <= gutterWidth ) {
          console.log('left gutter')
          return {
            left: (gutterWidth - offsetParentBounds.left + bounds.width/2 ) / offsetParentBounds.width * 100,
            width: null,
          }

        } else if ( (bounds.width + offsetParentBounds.width)/ 2 + offsetParentBounds.left > innerWidth ) {
          console.log('right gutter')
          return {
            left: (innerWidth - offsetParentBounds.left + gutterWidth - bounds.width / 2) / offsetParentBounds.width * 100,
            width: null,
          }
        }

        // don't override styles (i.e. large desktop layouts)
        console.log('default')
        return {
          left: null,
          width: null,
        }

      } else {
        // mobile mode - takes up all but the gutter and should be centered
        console.log('mobile')
        const newWidth = innerWidth
        return {
          left: (gutterWidth - offsetParentBounds.left + newWidth / 2) / offsetParentBounds.width * 100,
          width: newWidth / offsetParentBounds.width * 100,
        }
      }
    // }

  }


  setDatePickerStyles() {
    const styles = this.datePickerStyles()

    this.setState({ ...styles })
  }

  showPicker = e => {
    this.setState({ open: true }, () => {
      this.setDatePickerStyles()
    })
  }

  hidePicker = e => {
    this.setState({ open: false }, () => {
      this.setState({
        width:null, left:null
      })
    })
  }

  movePicker = e => {
    if (this.state.open) {
      this.setDatePickerStyles()
    }
  }

  handleDaySelection = date => {
    this.hidePicker()
    this.props.onChange( date || this.props.value )
  }

  // adding keyboard support
  handleKeyboardInput = (e) => {
    if (e.keyCode) {
      let date = initializeDate( this.props.value )
      let curDate = this.props.value

      switch( e.keyCode ) {
        case 32:
          // space -- still bugged
          e.preventDefault()
          this.handleDaySelection() // without parameter, defaults to current date in state
        break;

        case 38:
          // uparrow
          e.preventDefault()

          // decrement a week
          date.setDate( date.getDate() - 7 )
        break;

        case 40:
          // downarrow
          e.preventDefault()

          // increment a week
          date.setDate( date.getDate() + 7 )
        break;

        case 37:
          // left arrow
          e.preventDefault()

          // decrement a day
          date.setDate( date.getDate() - 1 )
        break;

        case 39:
          // right arrow
          e.preventDefault()

          // increment a day
          date.setDate( date.getDate() + 1 )
        break;

        default:
          // linter complains without a default
          // console.log('not trapped', e.keyCode, e.currentTarget, e.target, e.relatedTarget)
        break;
      }

      // prevent user from going outside the current month
      if (curDate.getMonth() !== date.getMonth()) {
        // process
      }
    }
  }

  changeMonth = dir => {
    let date = initializeDate( this.props.value )
    date.setMonth( date.getMonth() + dir )
    this.setState({ open: true })
    this.props.onChange(date)
  }

  changeYear = dir => {
    let date = initializeDate( this.props.value )
    date.setFullYear( date.getFullYear() + dir )
    this.setState({ open: true })
    this.props.onChange(date)
  }

  // handle resize events
  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }


  // could have used DayPicker props.navbarElement for DatePickerNav instead
  render() {
    const today = initializeDate()
    return (
      <DateEntryStyles open={ this.state.open }>
        <label htmlFor={ this.props.htmlName }>{ this.props.label }</label>
        <DateTextEntry
          htmlName={ this.props.htmlName }
          onChange={ this.props.onChange }
          value={ this.props.value }
        />
        <div
          className="date-picker-container"
          onKeyDown={ this.handleKeyboardInput }
          tabIndex="0"
          onFocus={ this.showPicker }
          onBlur={ this.hidePicker }
        >
          <input
            type="checkbox"
            name="date_picker_display"
            className="date-picker-toggle"
            onChange={ e => {
              if (e.currentTarget.value === 'on') {
                this.showPicker(e)
              } else {
                this.hidePicker(e)
              }

            }}
            checked={ this.state.open }
            tabIndex="-1"
          />
          <CalendarIcon src={ calendarIcon } alt="Pick a Date" tabIndex="-1" />
          <TheCarat />
          <TheOverlay onClick={ this.hidePicker }/>
          <StyledDatePicker
            className="styled-date-picker"
            ref={ this.calendarRef }
            left={ this.state.left }
            width={ this.state.width }
          >
            <DatePickerNav
              changeMonth={ this.changeMonth }
              changeYear={ this.changeYear }
              date={ this.props.value? this.props.value : today }
              tabIndex={ -1 }
            />
            <DayPicker
              showOutsideDays={ true }
              modifiers={ {highlighted: this.props.value? this.props.value : today} }
              selectedDays={ this.props.value? this.props.value : today }
              month={ this.props.value? this.props.value : today }
              onDayMouseDown={ this.handleDaySelection }
              tabIndex={ -1 }
            />
          </StyledDatePicker>
        </div>
      </DateEntryStyles>
    )
  }
}

export default DateEntry
