import { history } from 'services/history.js'

export const checkoutRouter = (auctionCode) => {
  console.log('local storage my items', localStorage.myItems)
  if (localStorage.myItems) {
    console.log('inside if')
    localStorage.removeItem('myItems')
    history.push({
      pathname: '/myItems'
    })
  } else {
    console.log('inside else')
    history.push({
      pathname: '/auctionhome',
      search: `?auctionId=${auctionCode}`
    })
  }

}