import Zindex from 'css/z-indexLayers.js';

import styled from 'styled-components'
import { device } from 'common/Device'

const defaultFontSize=16;

export const RemoveModalStyles=styled.div`
position:absolute;
right:0;
background:#FFFFFF;
border-radius: 3px;
z-index:${Zindex.zindexOverlaystackOrder1};
& >div{
  padding:20px;
}
box-shadow: 0 2px 10px 0 rgba(0,0,0,0.3);
.split-line{
  width: 200px;
  margin: 0 auto;
  text-align:center;
}
.remove-action-buttons{
  padding:calc(20rem/16) 0 0;
  margin:0;
}
& button{
  height: 2.725rem;
  padding: 0rem 0.875rem 0rem;
}

@media ${device.tablet}{
  &&  button{
    margin :0 ${10/defaultFontSize}rem ;
   }

  .remove-action-buttons{
    margin:0;
  }
}

@media ${device.mobile}{
  .remove-action-buttons{
    display:block;
    button{
     margin:${10/defaultFontSize}rem;
    }
  }

}
`