import { Component } from 'react';
import styled from 'styled-components';
import {device} from 'common/Device'
import * as colors from 'css/Colors';
import iconClear from 'images/x.svg';

const defaultFontSize=16;

export const Input = styled.input`
  border-radius: 3px;
  background-color: ${props => props.bgColor ? props.bgColor : (props.readOnly ? "#F5F5F5" : "#ffffff")};
  height: 54.11px;
  width: ${props => props.width ||"100%"};
  box-sizing: border-box;
  border: 1px solid #dedede;
  font-size: 16px;
  padding: 0 ${props => props.ExtraPaddingInput ? '50px' :'15px'};
  outline:none;

  ::placeholder{
    color:#9B9B9B;
  }

  cursor:${props=>props.readOnly?"default":"text"};

  @media ${device.mobile}{
    width:100%;
  }
`;


const ClearableContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  height: 3.125rem;
  width: ${props => props.width ||"100%"};

  input {
    padding-right: 40px;
    height: 100%;
    width: 100%;

    border: 1px solid ${props => props.errorOutline ? colors.guideRed : "#dedede"};

    &:focus + button {
      /* box-shadow: none; */
    }
  }

  button {
    visibility: ${props => props.clearVisible ? "visible" : "hidden"};

    position: absolute;
    top: 0;
    right: 0;

    height: 100%;
    width: 40px;

    padding: 0;
    /* box-shadow: -10px 0px 10px 1px #fff; */
    box-shadow: none;

    background: url(${iconClear}) no-repeat;
    background-position: 40% 50%;

    outline: none;
    border: none;

    cursor: pointer;
  }
`;

export class ClearableInput extends Component {

  state = {
    value: ''
  }

  handleChange = (e) => {
    this.setState({value: e.target.value});
    if (this.props.onChange) this.props.onChange(e);
  }

  handleClear = (e) => {
    e.preventDefault();
    this.setState({value: ''});
    if (this.props.onChange) this.props.onChange(e);
  }

  render() {
    return (
      <ClearableContainer
        className={this.props.className}
        clearVisible={this.state.value !== ''}
        width={this.props.width}
        errorOutline={this.props.errorOutline}
      >
        <Input {...this.props} value={this.state.value} onChange={this.handleChange}/>
        <button type="button" onClick={this.handleClear}></button>
      </ClearableContainer>
    )
  }
}


export const TextArea=styled.textarea`
width: ${props=>props.width? props.width:'100%'};
height:${props=>props.height||""};
border: 1px solid ${props=>props.borderColor||'#FFFFFF'};
border-radius: 3px;
box-sizing: border-box;
padding: ${15/defaultFontSize}rem;
resize: none;
outline:none;

 /* :focus{
    border: 1px solid ${colors.primaryBlue};
  } */
`
