import { useContext } from 'react'
import styled from 'styled-components'
import { device } from 'common/Device'

import { ThemeContext } from 'ThemeContext';
import { MyInformationContent, OuterContainer } from 'profile/MyInformationStyles'

const defaultFontSize = 16

const InnerContent = styled(MyInformationContent)`
  padding:0;
  height:100%;
  h4{
    margin-bottom:0;
  }
  @media ${ device.tablet } {
    padding-top:0;
  }
  @media ${ device.mobile } {
    padding:0;
  }
`

const HeaderLink = styled.div`
  color: #000;
  width:100%;
  text-align:center;
  padding-bottom:${ 5/defaultFontSize }rem;
`

const Styles = styled.div`
  padding-bottom:${ 20/defaultFontSize }rem;
  height:100%;
  ${ OuterContainer } {
    margin-top:0;
  }
  @media ${ device.tablet } {
    margin-top:${ 5/defaultFontSize }rem;
  }
  @media ${ device.mobile } {
    margin-top:${ 3/defaultFontSize }rem;
  }
`

function PrivacyPolicy(props) {
  const theme = useContext(ThemeContext)

  return (
    <Styles className="wrapper t-no-padding">
      <div className="grid t-grid">
        <div className="span-12 t-span-12">
          <OuterContainer >
            <InnerContent >
              <h3 className="bold center h4-mobile" id="pageTitle">Deletion of Information from {theme.appName}</h3>
              <p>You may delete your {theme.appName} account data and information at any time by contacting
                  UCLA {theme.supportTeamName} at <a href="mailto:{theme.supportEmail}">{theme.supportEmail}</a>
                  {theme.supportPhone && <span> or {theme.supportPhone}</span>}
                  &nbsp;and confirming you are
                  the owner of the account you wish to terminate. We may, in accordance with applicable law,
                  however, retain certain aspects of your account and other personal data for the purposes of
                  maintaining records of our dealings with you, analysis and/or statistics.
              </p>
              <h4 className="bold center h4-mobile">UCLA Privacy Policy and Terms of Use</h4>
              <HeaderLink><a href="https://www.ucla.edu/terms-of-use">https://www.ucla.edu/terms-of-use</a></HeaderLink>
              <iframe width="100%" height="400vh" align="center" title="UCLA Terms of Use" id="uclaTermsOfUseIframe"
                      src="https://api.cidm.support2.ucla.edu/uclatermsofuse"></iframe>
            </InnerContent>
          </OuterContainer>
        </div>
      </div>
    </Styles>
  )
}

export default PrivacyPolicy;
