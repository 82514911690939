import React from 'react'

import { FilledBlue } from 'css/Buttons'

import './PrintOverrides.css' // this makes it global FYI
import { PrintPage, ReceiptStyles } from './PrintStyles'
import { PrintPreviewBanner, PrintPreviewFonts, PrintContainer, PrintBannerImage } from './PrintReceiptPreviewStyles'
import ReceiptHeader from './ReceiptHeader'
import PledgeSummary from './PledgeSummary'
import ReceiptGiftReference from './ReceiptGiftReference'
import ReceiptGiftSingle from './ReceiptGiftSingle'
import PrintLegalCopy from './PrintLegalCopy'
import MygivingGatewayLogo from '../images/mygivinggatewaylogo.svg';

class PrintReceiptPreview extends React.Component  {

  render() {
    let { bio, gifts, crmId, primaryDonorName } = this.props

    console.log({ bio, gifts, crmId, primaryDonorName })

    if (!primaryDonorName) primaryDonorName = gifts[0].primaryDonorName

    const spouseName = gifts[0].spouseName

    // "flatten" gifts array to capture installments (pledge payments)
    let receipts = []
    for (let i=0; i<gifts.length; i++) {
      if (gifts[i].revenueTypeHasInstallments && gifts[i].installments && gifts[i].installments.length) {
        receipts.push( ...gifts[i].installments )

      } else {
        receipts.push( gifts[i] )
      }
    }

    console.log({ receipts })

    return (
      <PrintPreviewFonts>
        <PrintPreviewBanner className="hide-on-print">
          <img className="header-image" src={ MygivingGatewayLogo } alt="Give To UCLA" />
          <FilledBlue onClick={ e => window.print() }>Print</FilledBlue>
        </PrintPreviewBanner>

        <PrintContainer>

          <PrintPage>
            <PrintBannerImage src={ MygivingGatewayLogo } alt="Give To UCLA"/>
            { bio && <ReceiptHeader crmId={ crmId } primaryDonorName={ primaryDonorName } bio={ bio } spouseName={spouseName} /> }

            { receipts.map( item => (
                <ReceiptStyles key={`receipt-${ item.revenueLookupId }`}>
                  <ReceiptGiftReference data={ item } />
                  <ReceiptGiftSingle data={ item } />
                </ReceiptStyles>
              )
            )}

            { // pledge summary only appears when printing a single installment of a pledge
              receipts.length === 1 && receipts[0].revenueType === 'PP' &&
              <PledgeSummary amount={ receipts[0].pledgeAmount || receipts[0].pledge?.displayAmount || 0 } balance={ receipts[0].pledgeBalance || receipts[0].pledge?.pledgeBalance || 0 }/>
            }
          </PrintPage>

          <PrintPage className="no-page-break">
            <PrintLegalCopy />
          </PrintPage>
        </PrintContainer>
      </PrintPreviewFonts>
    )
  }
}

export default PrintReceiptPreview
