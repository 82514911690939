import styled from 'styled-components'

import Zindex from 'css/z-indexLayers.js'
import { device } from 'common/Device'

import MoreInfo from './MoreInfo'
import { LIFETIME_RECOGNITION, CURRENT_YEAR_TAX_DEDUCTIBLE, PREVIOUS_YEAR_TAX_DEDUCTIBLE } from './InfopopContent'

const defaultFontSize = 16

const MoreInfoPosition = styled.div`
  position:absolute;
  left:0;
  top:${ 4/defaultFontSize }rem;
  height:1rem;
  width:1rem;

  @media ${ device.tablet } {
    top:${ 3/defaultFontSize }rem;
  }
`

const Styles = styled.div`
  position:relative;
 z-index:${Zindex.zindexOverlayStackOrder2};
  /* padding: ${ 27/defaultFontSize }rem ${ 40/defaultFontSize }rem 0 0; */

  h3, .h3 {
    margin:0 0 ${ 5/defaultFontSize }rem;
    color:#000000;
    font-weight:600;
    font-size:${ 18/defaultFontSize }rem;
    line-height:${ 25/18 };
  }

  ul {
    display:block;
    list-style-type:none;
    /* margin:0 0 ${ 25/defaultFontSize }rem; */
    margin:0;
    padding:0;
  }

  li {
    text-align:left;
    position:relative;
    /* max-width:${ 492/defaultFontSize }rem; */
    list-style-type:none;
    margin:0;
    padding:0;

    .contribution-totals {
      display:flex;
      align-items:center;
      justify-content:space-between;
    }

    span {
      display:block;
    }

    .stat-type {
      margin-left:${ 26/defaultFontSize }rem;
    }

    .stat-total {
      font-weight:800;
    }
  }

  @media ${ device.tablet } {

    li {
      max-width:100%;
    }
  }

  @media ${ device.mobile } {

    li {
      display:block;
      margin-top:${ 14/defaultFontSize }rem;

      &:first-child {
        margin-top:0;
      }

      .contribution-totals {
        display:block;
      }

      .stat-total, .stat-type {
        display:block;
        margin-left:${ 30/defaultFontSize }rem;
      }
    }
  }
`

// this is the mini-summary seen on several pages
const DonationTotals = props => (
  <Styles>
    { props.header || ''}
    <ul>
      <li>
        <MoreInfoPosition>
          <MoreInfo
            htmlName="more_info_lifetime_recognition"
            htmlId="more_info_lifetime_recognition"
            srText={"What does Lifetime Recognition mean?"}
            gutterRef={ props.gutterRef }
          >
            { LIFETIME_RECOGNITION }
          </MoreInfo>
        </MoreInfoPosition>
        <div className="contribution-totals">
          <span className="stat-type">Lifetime Recognition:</span>
          <span className="stat-total">{ props.lifetimeRecognition }</span>
        </div>
      </li>
      <li>
        <MoreInfoPosition>
          <MoreInfo
            htmlName="more_info_ytd_tax_deductible"
            htmlId="more_info_ytd_tax_deductible"
            srText={"What does " + localStorage.currentYear + " Year to Date mean?"}
            gutterRef={ props.gutterRef }
          >
            { CURRENT_YEAR_TAX_DEDUCTIBLE }
          </MoreInfo>
        </MoreInfoPosition>
        <div className="contribution-totals">
          <span className="stat-type">{ localStorage.currentYear } Year to Date Tax-Deductible:</span>
          <span className="stat-total">{ props.currentYearTaxDeductible }</span>
        </div>
      </li>
      <li>
        <MoreInfoPosition>
          <MoreInfo
            htmlName="more_info_prev_ytd_tax_deductible"
            htmlId="more_info_prev_ytd_tax_deductible"
            srText={"What does " + (parseInt(localStorage.currentYear,10) - 1) + " tax deduction mean?"}
            gutterRef={ props.gutterRef }
          >
            { PREVIOUS_YEAR_TAX_DEDUCTIBLE }
          </MoreInfo>
        </MoreInfoPosition>
        <div className="contribution-totals">
          <span className="stat-type">{ parseInt(localStorage.currentYear,10) - 1 } Tax-Deductible:</span>
          <span className="stat-total">{ props.previousYearTaxDeductible }</span>
        </div>
      </li>
    </ul>
  </Styles>
)

export default DonationTotals
