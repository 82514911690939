import React from 'react'
import styled from 'styled-components'

import { device } from 'common/Device'
import { ProfileHeader, ProfileSubHeader, defaultPrintFontSize } from './PrintStyles'

const ReceiptHeaderStyles = styled.div`
  padding:${ 26/defaultPrintFontSize }em 0 ${ 13/defaultPrintFontSize }em;
  address {
    font-style:normal;
  }
  .user-profile {
    float:left;
  }
  .user-contact {
    float:right;
  }
  &:after {
    display:block;
    content:'';
    clear:both;
  }
  @media screen and ${ device.mobile } {
    padding:0 0 ${ 8/defaultPrintFontSize }em;
    .user-profile, .user-contact {
      float:none;
    }
    div {
      margin:0 0 ${ 10/defaultPrintFontSize }em;
    }
  }
`

const HeaderDetails = styled.ul`
  list-style-type:none;
  margin:0;
  padding:0;
  li {
    margin:0;
    padding:0;
    .tax-id {
      font-weight: 600;
    }
  }
  @media screen and ${ device.mobile } {
    li {
      margin:${ 2/defaultPrintFontSize }rem 0;
    }
  }
`

const ReceiptHeader = props => {
  const { bio, crmId, primaryDonorName, spouseName } = props
  return (
    <ReceiptHeaderStyles>
      <div className="user-profile">
        <ProfileHeader>{ crmId }</ProfileHeader>
        <ProfileSubHeader>{ primaryDonorName }</ProfileSubHeader>
        {spouseName && <ProfileSubHeader>{ spouseName }</ProfileSubHeader>}
        <address>
          <HeaderDetails>
            { bio && bio.address && (
            <React.Fragment>
              { bio.address.careOf && <li>{ bio.address.careOf }</li> }
              <li>{ bio.address.addressBlock }</li>
              <li>{ bio.address.city }, { bio.address.state } { bio.address.postCode }</li>
            </React.Fragment>
            )}
            <li>{ bio && bio.phone && bio.phone.number }</li>
            <li>{ bio && bio.email && bio.email.emailAddress }</li>
          </HeaderDetails>
        </address>
      </div>
      <div className="user-contact">
        <ProfileHeader>UCLA Development</ProfileHeader>
        <address>
          <HeaderDetails>
            <li>10889 Wilshire Boulevard, Suite 1000</li>
            <li>Los Angeles, CA 90024-6516</li>
            <li>Phone: (310) 794-2345</li>
            <li>gifts@support.ucla.edu</li>
            <li>UC Regents Tax ID: <span className="tax-id">95-6006143W</span></li>
            <li>UCLA Foundation Tax ID: <span className="tax-id">95-2250801</span></li>
          </HeaderDetails>
        </address>
      </div>
    </ReceiptHeaderStyles>
  )
}

export default ReceiptHeader
