import React, { Component } from 'react'

import { allowedPhoneRegex } from "authentication/validators"
import { Input } from 'css/forms'
import { Label } from './Label'
import * as logon from '../css/AuthenticateStyles'
import { ErrorSpan } from 'css/CommonStyle'

import EmailIcon from 'images/EmailShape.svg'
import PhoneIcon from 'images/PhoneIcon.svg'
import EmailArrowDownIcon from 'images/EmailAroowDown.svg'
import ArrowUp from 'images/ArrowUp.svg'

import Zindex from 'css/z-indexLayers.js'
import styled from 'styled-components'
import { device } from 'common/Device'

const PhoneLink = styled(logon.ImgLink)`
  height:100%;
  & img{
    padding:18px;
  }
`;

const FormContent = styled.div`
position:relative;
@media ${device.tablet}{
width:100%;
}
`
const ImgLinkDiv = styled.div`
position:absolute;
//top:${props => props.signIn ? "20%" : "0"};
top:0;
right:0;
cursor:pointer;

& img{
  padding:20px 15px;
  }

 /*  & +.toggle-login-list{
    display:none;
  }
 &:focus {
      outline:none;
      + .toggle-login-list{
      display:block;
    }
  } */
`;

const Phonediv = styled.div`
  position:absolute;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.2);
  z-index:${Zindex.zindexStackOnMainContentOrder1};
  height: 50px;
  width: ${props => props.width || "100%"};
  font-size: 16px;
  border: 1px solid #dedede;
  box-sizing:border-box;

  label {
    text-align:left;
    padding-left: 72px;
    display: block;
    color:#2774AE;
    line-height:50px;
  }`


class EmailPhoneToggle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleEmailPhone: true,
      hideToggle: this.props.hideToggle || false,
      usernameIsEmail: this.props.usernameIsEmail !== undefined ? this.props.usernameIsEmail : true,
      active: false,
      usernameError: props.usernameError,
      usernameEntry: this.props.savedUsername || ''
    };
    this.emailContainerRef = React.createRef()
    this.inputFieldRef = React.createRef()
  }

  async componentDidMount() {
    if (allowedPhoneRegex.test(this.props.savedUsername)) this.setState({ usernameIsEmail: false });
  }

  componentDidUpdate(prevProps, prevState) {
     if (this.props.savedUsername !== prevProps.savedUsername) {

        if (this.props.savedUsername && this.props.savedUsername.indexOf('@') === -1)  {
          this.setState({ usernameIsEmail: false });
          this.setState({ usernameEntry: this.props.savedUsername.substr(1) }); // strip off leading 1 - since it confuses the email phone toggle
        }
        else {
          this.setState({ usernameEntry: this.props.savedUsername });
        }
     }

    if (prevProps.usernameError !== this.props.usernameError)
      this.setState({ usernameError: this.props.usernameError });

      setTimeout(() => {
        if (this.state.active) {
          window.addEventListener('click', this.close)
        }
        else {
          window.removeEventListener('click', this.close)
        }
      }, 0)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.close)
  }

  close = (e) => {
    if (this.state.active) {

      this.setState({
        active: false
      })
    }
  }

  doPhoneDetect = (username) => {
    const cleanName = username.replace(/[(\-)]/g, '');
    if (allowedPhoneRegex.test(cleanName) || allowedPhoneRegex.test('1' + cleanName) || allowedPhoneRegex.test('+1' + cleanName))
      this.setState({ usernameIsEmail: false });
  }

  handleChange = (e, cursor) => {
    if (this.props.onChange) this.props.onChange(e);

    const { value, selectionStart } = e.target
    e.preventDefault()

    if ( this.state.usernameIsEmail ) {
      this.doPhoneDetect( value )
    }
    else if (value && !/\d|\(|\)|\+|-/.test(value) ) {
      if (value.length === 1)
        this.setState({ usernameIsEmail: true });
      else
        return;
    }

    if (this.state.usernameIsEmail) {
      this.setState({ usernameEntry: value.toLowerCase() })
      // don't set cursor for phone field because it fries everything out
      this.inputFieldRef.current.selectionStart = selectionStart
      this.inputFieldRef.current.selectionEnd = selectionStart

      if (this.props.handleChange) this.props.handleChange(value.toLowerCase());
    }
    else {
      this.setState({ usernameEntry: this.unMaskEntry( value ) })
      if (this.props.handleChange) this.props.handleChange(this.unMaskEntry( value ));
    }


  }

  unMaskEntry( value ) {
    // can't use lookback regex here bummer dude
    let unmasked = []

    for ( let i=0; i<value.length; i++) {
      if (/[0-9]/.test(value.charAt(i)) && ( i>0 ? value.charAt(i-1) !== '+' : true)) {
        unmasked.push( value.charAt(i) )
      }
    }

    // remove leading 1 if the user starts typing phone number while in email toggle
    let returnValue = unmasked.length ? unmasked.join('') : value
    if (returnValue.indexOf('1') === 0) returnValue = returnValue.substring(1)

    return returnValue
  }

  maskEntry(rawValue) {
    const value = this.unMaskEntry(rawValue); // if browser fills in value - handleChange is not called

    if (value === "+1(") {
      return "";
    }
    else if (1*value === 1) {
      return "+1";
    }
    else {
      const mask = [...'+1(999)-999-9999']
      let i = 0
      let displayValue = ''

      while (i < value.length && mask.length) {
        const code = mask.shift()

        if (/9/.test(code)) {
          displayValue += value.charAt(i++)
        }
        else {
          displayValue += code
        }
      }

      return displayValue
    }
  }

  showToggleSelect = () => {
    this.setState({
      active: true
    })
  };

  hideToggleSelect = () => {
      this.setState({
      active: false
    })
  }

  handleMousedown = (e) => {
    this.setState({
      usernameIsEmail: !this.state.usernameIsEmail,
      usernameEntry: '',
    })
    this.hideToggleSelect();
  }

  render() {
    const displayValue = this.state.usernameIsEmail? this.state.usernameEntry : this.maskEntry( this.state.usernameEntry )
    return (
      <div>
        <logon.SignInRegisterInnerDiv className="toggle-container">
          {this.props.signIn && <Label>Email / Cell Phone</Label>}
          <FormContent>

            <logon.ImgLink >
              <img src={this.state.usernameIsEmail ? EmailIcon : PhoneIcon} alt="phone/email toggle" />
            </logon.ImgLink>

            {/* Toggle Between Email and phone user name for Signin/register */}
            {/* */}

            <Input ExtraPaddingInput
              ref={this.inputFieldRef}
              bgColor={this.props.bgColor}
              name="username"
              autocomplete="off"
              id={this.state.usernameIsEmail ? "emailInput" : "phoneInput"}
              onChange={this.handleChange}
              className={this.state.usernameError ? "error" : ""}
              placeholder={this.state.usernameIsEmail ? "e.g. joe@ucla.edu" : "1(_ _ _)-_ _ _ - _ _ _ _"}
              value={ displayValue }
              type="text"
            />

            <Input name="usernameType" type="hidden" value={this.state.usernameIsEmail ? "email" : "phone"} />

            {this.state.usernameError &&
              <ErrorSpan textAlign="right">
                <label>{this.state.usernameError}</label>
              </ErrorSpan>}

            {!this.state.hideToggle &&
              <ImgLinkDiv
                ref={this.emailContainerRef}
                signIn={this.props.signIn}
                id="dropdown-toggle"
                onClick={e=>{
                  e.preventDefault()
                  if (this.state.active) {
                    this.hideToggleSelect(e)
                  } else {
                    this.showToggleSelect(e)
                  }
                }}
              >
              {!this.state.active
                ? <img src={EmailArrowDownIcon} alt="hide phone/email toggle" />
                : <img src={ArrowUp} alt='Arrow up toggle' />}
              </ImgLinkDiv>}

            {this.state.active &&
              <Phonediv
                className="toggle-login-list"
                id="phone-email-toggle"
               onClick={this.handleMousedown}
              >
                <PhoneLink>
                  <img src={this.state.usernameIsEmail ? PhoneIcon : EmailIcon} alt="email/phone select" />
                </PhoneLink>
                <label> {this.state.usernameIsEmail ? "Phone Number" : "Email"}</label>
              </Phonediv>}

          </FormContent>
        </logon.SignInRegisterInnerDiv>
      </div>

    )
  }
}

export default EmailPhoneToggle;
