import { formatStringDate } from './paymentUtilities'

import css from '../payment/sass/associatedgift.module.scss'

const giftLabels = {
  fundName: 'FUND NAME',
  giftType: 'GIFT TYPE',
  startDate: 'START DATE',
  recurringAmount: 'NEXT PAYMENT',
};

function GiftsAssociatedGrid(props) {

  return (
    <div className={css['gift-grid__container']}>
      {props.gifts.map((gift, index) => {
        return (    
          <div className={css['gift-grid']} key={index}>  
            <div className={`${css['gift-grid__col-header-1']} ${css['gift-grid__col-headers-' + index]}`}> {giftLabels.fundName}</div>
            <div className={`${css['gift-grid__col-header-2']} ${css['gift-grid__col-headers-' + index]}`}> {giftLabels.giftType}</div>
            <div className={`${css['gift-grid__col-header-3']} ${css['gift-grid__col-headers-' + index]}`}> {giftLabels.startDate}</div>
            <div className={`${css['gift-grid__col-header-4']} ${css['gift-grid__col-headers-' + index]}`}> {giftLabels.recurringAmount}</div>
            {/* TO DO: update once giving history is updated with pledge */}
            <div className={css['gift-grid__col-data-1']}> {`${gift.designation} ${gift.fundNumber}`} </div>
            <div className={css['gift-grid__col-data-2']}> {gift.revenueTypeFull} </div>
            <div className={css['gift-grid__col-data-3']}> {formatStringDate(gift.date)} </div>
            <div className={css['gift-grid__col-data-4']}> {`$${gift.nextInstallmentAmount}`} </div>
          </div>
        )
      })}
    </div>
  )
}

export default GiftsAssociatedGrid;