import { doApi } from 'services/api';
import store from 'redux/store';
import { getCrmUser } from 'redux/selectors';

// IMPORTANT: also need to set this in api-bl-donor_portal_user_lambda/reports.js as fundReportCurrentYear
export const ceirCurrentYear = 2023;

export const getDocumentList = () => {
  const documents = getCrmUser(store.getState()).documents;
  return (documents && documents.length) ? documents : null;
};

export const getFundReports = () => {
  const fundReports = getCrmUser(store.getState()).fundReports;
  return (fundReports && fundReports.length) ? fundReports : null;
};

export const getGrabBagReports = () => {
  const grabBagReports = getCrmUser(store.getState()).grabBagReports;
  return (grabBagReports && grabBagReports.length) ? grabBagReports : null;
};

export const getStaticDocument = async (doc) => {
  console.log('getStaticDocument!!!', doc);

  const filename = doc.filename || doc.url.substr(doc.url.lastIndexOf('/') + 1)

  const a = document.createElement('a')
  a.href = doc.url
  a.download = filename
  document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click()
  a.remove()

  doApi({route: 'logreportdownload/' + doc.reportType + '/' + doc.reportYear + '?filename=' + filename, method:"post"})
};

export const downloadDocument = async (doc) => {
  console.log('downloadDocument!!!', doc);

  const url = (doc.fundNumber)
            ? await getFundReportPresignedUrl(doc)
            : (doc.reportId)
              ? await getGrabBagPresignedUrl(doc)
              : await getUserDocumentPresignedUrl(doc);

  if (!url || !url.presignedUrl)
    return {error: 'Unable to access document'}

  console.log(url.presignedUrl)

  const a = document.createElement('a');
  a.href = url.presignedUrl;
  a.download = doc.prettyFileName;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
};

const getUserDocumentPresignedUrl = async (doc) => {
  console.log(doc);
  const [lastName, firstName, crmId, reportType, reportYear, fileExt] = doc.FileName.split('.');
  const fileNameStart = `${lastName}.${firstName}`
  const fileNameEnd = `${reportType}.${reportYear}.${fileExt}`
  return await doApi({route: `userdocumenturl/${doc.ReportType}/${doc.ReportYear}?fns=${fileNameStart}&fne=${fileNameEnd}`});
};

const getFundReportPresignedUrl = async (fund) => {
  return await doApi({route: `fundreporturl/${fund.reportType}/${fund.reportYear}?fn=${fund.fileName}`});
};

const getGrabBagPresignedUrl = async (report) => {
  return await doApi({route: `grabbagurl?fn=${report.fileName}`});
};

export const getPastCeirReports = async () => {
  // await doTimeout(5000);
  const pastCeirReports = localStorage.getItem('pastCeirReports');
  if (pastCeirReports) {
    return JSON.parse(pastCeirReports);
  }
  else {
    const response = await doApi({route: `getpastceir`});
    const pastCeirReports = (response && response.length) ? response : [];
    localStorage.setItem('pastCeirReports', JSON.stringify(pastCeirReports))
    return pastCeirReports;
  }
};

export const getPastGrabBagReports = async () => {
  // await doTimeout(5000);
  const pastGrabBagReports = localStorage.getItem('pastGrabBagReports');
  if (pastGrabBagReports) {
    return JSON.parse(pastGrabBagReports);
  }
  else {
    const response = await doApi({route: `getpastacr`});
    const pastGrabBagReports = (response && response.length) ? response : [];
    localStorage.setItem('pastGrabBagReports', JSON.stringify(pastGrabBagReports))
    return pastGrabBagReports;
  }
};

//   const pastGrabBagReports = await doApi({route: `getpastacr`});
//   return (pastGrabBagReports && pastGrabBagReports.length) ? pastGrabBagReports : [];
  //  return dummyGrabBagReports;


const doTimeout = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const dummyCeirReports = [
  {
    "fullPath": "CEIR/2019/60239C - GZ.CEIR.2019.pdf",
    "fundDesignation": "Fund for Excellence",
    "fundNumber": "60239C - GZ",
    "reportType": "CEIR",
    "reportYear": "2019",
    "fileName": "60239C - GZ.CEIR.2019.pdf",
    "prettyFileName": "60239C.2019.GivingInAction.pdf",
    "fileExtension": "pdf"
  },
  {
    "fullPath": "CEIR/2021/63180C - HZ.CEIR.2021.pdf",
    "fundDesignation": "Diversity & Inclusion Fund",
    "fundNumber": "63180C - HZ",
    "reportType": "CEIR",
    "reportYear": "2021",
    "fileName": "63180C - HZ.CEIR.2021.pdf",
    "prettyFileName": "63180C.2021.GivingInAction.pdf",
    "fileExtension": "pdf"
  },
  {
    "fullPath": "CEIR/2021/60239C - GZ.CEIR.2021.pdf",
    "fundDesignation": "Fund for Excellence",
    "fundNumber": "60239C - GZ",
    "reportType": "CEIR",
    "reportYear": "2021",
    "fileName": "60239C - GZ.CEIR.2021.pdf",
    "prettyFileName": "60239C.2021.GivingInAction.pdf",
    "fileExtension": "pdf"
  }
];

const dummyGrabBagReports =
[
  {
    "fullPath": "ACR/2021/Super Special User Report22.0093034551.2021.pdf",
    "reportName": "Super Special User Report22",
    "reportId": "0093034551",
    "reportYear": "2021",
    "fundDesignation": "Some super fund yay",
    "fileName": "Super Special User Report22.0093034551.2021.pdf",
    "fileExt": "pdf",
    "prettyFilename": "Super Special User Report22.2021.pdf"
  },
  {
    "fullPath": "ACR/2019/Super Duper User Report.0093034551.2019.pdf",
    "reportName": "Super Duper User Report",
    "reportId": "0093034551",
    "reportYear": "2019",
    "fundDesignation": "Some super fund yay 2534234",
    "fileName": "Super Duper User Report.0093034551.2019.pdf",
    "fileExt": "pdf",
    "prettyFilename": "Super Duper User Report.2019.pdf"
  },
  {
    "fullPath": "ACR/2021/Super Special User Report.0093034551.2021.pdf",
    "reportName": "Super Special User Report",
    "reportId": "0093034551",
    "reportYear": "2021",
    "fundDesignation": "Some super fund yay sdfdf",
    "fileName": "Super Special User Report.0093034551.2021.pdf",
    "fileExt": "pdf",
    "prettyFilename": "Super Special User Report.2021.pdf"
  },
  {
    "fullPath": "ACR/2019/Cool 2019 Fund Report.60239C - GZ.2019.pdf",
    "reportName": "Cool 2019 Fund Report",
    "reportId": "60239C - GZ",
    "reportYear": "2019",
    "fundDesignation": "Some super fund",
    "fileName": "Cool 2019 Fund Report.60239C - GZ.2019.pdf",
    "fileExt": "pdf",
    "prettyFilename": "Cool 2019 Fund Report.2019.pdf"
  },
  {
    "fullPath": "ACR/2019/Awesome 2019 Department Report.61-61-0004.2019.pdf",
    "reportName": "Awesome 2019 Department Report",
    "reportId": "61-61-0004",
    "reportYear": "2019",
    "fundDesignation": "super fund yay",
    "fileName": "Awesome 2019 Department Report.61-61-0004.2019.pdf",
    "fileExt": "pdf",
    "prettyFilename": "Awesome 2019 Department Report.2019.pdf"
  },
  {
    "fullPath": "ACR/2021/Totally Rad Fund Report.60239C - GZ.2021.pdf",
    "reportName": "Totally Rad Fund Report",
    "reportId": "60239C - GZ",
    "reportYear": "2021",
    "fundDesignation": "Some super  yay",
    "fileName": "Totally Rad Fund Report.60239C - GZ.2021.pdf",
    "fileExt": "pdf",
    "prettyFilename": "Totally Rad Fund Report.2021.pdf"
  },
  {
    "fullPath": "ACR/2021/Cool Awesome Department Report.61-61-0004.2021.pdf",
    "reportName": "Cool Awesome Department Report",
    "reportId": "61-61-0004",
    "reportYear": "2021",
    "fundDesignation": "Some other fund yay",
    "fileName": "Cool Awesome Department Report.61-61-0004.2021.pdf",
    "fileExt": "pdf",
    "prettyFilename": "Cool Awesome Department Report.2021.pdf"
  }
]