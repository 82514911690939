import { Link } from 'react-router-dom';

import EmailPhoneContact from './shared/EmailPhoneContact'
import { NewGiftStyles } from './shared/SharedStyles'

const DisclosureStatements = () => (
  <NewGiftStyles>
    <h4><strong>UCLA AND THE UCLA FOUNDATION'S DISCLOSURE STATEMENTS FOR DONORS</strong></h4>

    <p>To reduce UCLA’s impact on the environment and printing costs, and provide prospective donors with the most up-to-date disclosure statements,
       UCLA has posted this information online. These statements will inform you of how your contact and donor information may be used, the governance
       of The UCLA Foundation, the application of administrative fees and the management of endowed funds</p>

    <div>
      <div>
        <p><strong>Privacy Notice</strong></p>
        <p className="first-paragraph">The 1977 California Information Practices Act requires UCLA to inform individuals asked to supply information about
           themselves of the following: UCLA is requesting this information to update the general resource files of its External Affairs Department. Furnishing
           the information is strictly voluntary and will be maintained confidentially. The information may be used by other university departments in the regular
           course of business, but will not be disseminated to others except if required by law.</p>
        <p>You have the right to review your own data file. Inquiries should be forwarded to the Associate Vice Chancellor - Advancement Services, External Affairs,
           10889 Wilshire Blvd., Suite 1100, Los Angeles, CA 90024.</p>
      </div>
      <div>
        <p><strong>Donor's Consent for Public Acknowledgment</strong></p>
        <p className="first-paragraph">The university is grateful for the support it receives from alumni, parents and friends. One of the ways we express our thanks
           is by listing the names of donors in Web-based and/or print honor rolls. Should you wish that your name not appear as a donor, please notify donor relations
           at (310) 794-2447 if you have not already done so.</p>
      </div>
      <div>
        <p><strong>Fiduciary Responsibility of The Foundation</strong></p>
        <p className="first-paragraph">The Foundation is a California non-profit, public benefit corporation organized for the purpose of encouraging voluntary private gifts,
           trusts and bequests for the benefit of the UCLA campus. Responsibility for governance of The Foundation, including investments, is vested in its Board of Directors.</p>
      </div>
      <div>
        <p><strong>Administrative Fee on Gifts</strong></p>
        <p className="first-paragraph">The Foundation and UCLA apply a one-time administrative fee to all gifts and pledge payments to provide essential support necessary
           to UCLA's overall operation. The fee is currently 6.5 percent.</p>
      </div>
      <div>
        <p><strong>Donor Advised Funds</strong></p>
        <p className="first-paragraph">Gifts for which a donor is entitled to receive any return benefits (e.g., meal, parking, etc.) must be paid by individuals or entities
           other than a Donor Advised Fund.</p>
        <p>Donor advised funds (e.g., Fidelity gift fund) and corporate matching platforms (e.g., Benevity, OneCause, etc.) do not permit donors to receive benefits in exchange
           for gifts they've made. If you wish to receive the benefits from this event, please pay with a source of funds other than a donor advised fund or corporate matching platform.</p>
      </div>
      <div>
        <p><strong>Endowments</strong></p>
        <p className="first-paragraph">Gifts are managed and invested in accordance with UCLA’s endowment investment policies. The total return earned in excess of the amount
           approved annually for payout will be retained in the endowment principal to protect from the effects of inflation and to allow for growth. At the discretion of the
           appropriate campus office, when the payout is not needed for purposes of the fund, the return of payout may be added to the endowment fund principal. In the event the
           fund does not reach endowment minimum; or the program ceases to exist at UCLA, proceeds from the fund will be utilized in an area and manner as closely related as possible
           to the original intent and purposes for which the fund was established</p>
        <p>Although the Endowment is intended to exist in perpetuity, unforeseen circumstances may alter or remove the subject area from the campus academic plan or it may become
           impossible, impracticable or wasteful to administer. In such an event, the Chancellor is authorized to redesignate the purpose of the Endowment, after taking into
           consideration the designated endowment purpose.</p>
        <p>Gifts directed to Regental endowments supporting UCLA are invested in a similar way to gifts made to The Foundation. Additional information is
           available <a target="_blank" rel="noreferrer" href="https://www.ucop.edu/investment-office/index.html">online</a>.</p>
      </div>
      <div>
        <p><strong>Fundraising Permit</strong></p>
        <p className="first-paragraph">In compliance with the Charitable Solicitation Ordinance in the City of Los Angeles (L.A. Municipal Code Article 4, Section 44), The UCLA
           Foundation’s permit is on file with the City of Los Angeles.</p>
        <p>In compliance with the Charitable Solicitation Ordinance in the City of Beverly Hills(Under Article 8, Section 4-3-801 of the Beverly Hills Municipal Code (BHMC),
           The UCLA Foundation's permit is on file with the City of Beverly Hills.</p>
      </div>
      <div>
        <p><strong>For Medical Sciences Solicitations</strong></p>
        <p className="first-paragraph">If you do not wish to receive further fundraising information from UCLA Health Sciences, please either call us at (855) 364-6945 or email us
           at <a href="mailto:OptOutUCLAHSD@support.ucla.edu">OptOutUCLAHSD@support.ucla.edu</a>, providing your name, address, phone number and from which department you’re
           requesting to be removed.</p>
      </div>
      <div>
        <p><strong>Donor Influence or Control</strong></p>
        <p className="first-paragraph">All gifts to support UCLA accord with the University’s foundational value of academic freedom, which is essential to its research and teaching
           mission. At no time, today or in the future, shall any donor unduly direct the inquiry, research, teaching, or learning of faculty, students, or staff. All gifts will be
           governed by UCLA’s policies and procedures.</p>
      </div>
      <div>
        <p><strong>Chair Disclosures</strong></p>
        <p className="first-paragraph">The amount approved for distribution annually by UCLA will support the work of a Chair holder, who will be selected in accordance with University
           Policy and Procedure. Establishment of a Chair must comply with current Policy and Administrative Guidelines of the University of California with approval by the UCLA Chancellor
           and endorsement from the Academic Senate. If a term-appointment Chair is established, the incumbent will serve a term of up to five years and may be reappointed in accordance
           with University policy. Upon relinquishment of a Chair, the holder is entitled to be referred to as the holder of that Chair, listing dates of incumbency. A Chair will not be
           established, nor a chair holder appointed, until at least 25% of that Chair's required campus minimum is paid. During periods when a Chair is vacant, the appropriate campus
           official is authorized to utilize the payout amount for purposes closely related to the academic field represented by that Chair.</p>
      </div>
    </div>

  </NewGiftStyles>

)
export default DisclosureStatements
