import { useEffect } from 'react'
import { doPostLogin } from "authentication/postlogin"

import { showLoading } from "services/util"

// browser is redirected back to this page after Oauth call
// needs to call postLogin() due to auto-login in ForceSignin
function PostLogin(props) {

  useEffect(() => {
    async function doInit() {
      showLoading(true, "PostLogin")
      await doPostLogin()
      showLoading(false, 'PostLogin')
		}

	  doInit()

  }, [])


	return (
		<div>
		</div>
	)
}

export default PostLogin;
