import { useState, useLayoutEffect } from 'react';

import { history } from 'services/history.js'
import { initializeDate } from 'services/util'
import MoreInfo from 'common/MoreInfo'

import GiftActions from '../history/GiftActions'
import { formatAmount, formatDate, formatFund, formatActivity } from '../mygivingutil'
import { PaymentDue, PaymentReceived, ReceiptActionStyles } from '../history/HistoryStyles'

import { FilledBlue } from 'css/Buttons'
import css from './sass/pledges.module.scss'

function PledgeDetail(props) {
  const [isSectionOpen, setIsSectionOpen] = useState(false)
  const [width, setWidth] = useState(0);

  const toggleSection = (e) => {
    if (isSectionOpen)
      setIsSectionOpen(false)
    else
      setIsSectionOpen(true)
  }

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const pledge = props.pledge;
  const installments = props.pledge.installments.sort((a,b) => initializeDate(a.date) > initializeDate(b.date) ? -1 : 1)
  const installmentsLength = installments.length;

  const pledgePaymentStatus = ({ balance, date }) => {
    if (balance) {
      return <PaymentDue>{ initializeDate() > date ? "Past Due" : "Due" }</PaymentDue>
    }
    else {
      return <PaymentReceived>Paid</PaymentReceived>
    }
  }

  const pledgePaymentMethod = (installment, index) => {
    const revenueLookupId = installment.revenueLookupId || index
    return installment.hasAutomaticPayments && ( initializeDate(installment.date) >= initializeDate() )?
    (
      <ReceiptActionStyles>
        <div className="installment-container">
          <span>Autopay</span>
          <MoreInfo
            htmlName={`more_info_${ revenueLookupId }`}
            htmlId={`more_info_${ revenueLookupId }_${ index }`}
            srText={"About Automatic Payments"}
          >
            You have elected to make automatic payments on this pledge. We will charge the appropriate amount on the date the payment is due.
          </MoreInfo>
        </div>
      </ReceiptActionStyles>
    ) : installment.paymentMethod || null
  }

  const pledgeActions = (installment, index) => {
    return (
      <div className="pledge-print-button">
        <GiftActions
          isReceiptable={ installment.isReceiptable }
          printReceipt={ props.printReceipt }
          original={ installment }
          index={ index }
        />
      </div>
    )
  }

  return (
    <div className={css["pledge-container"]}>
      <div className={css["pledge-details-container"]}>
        <h2>Pledge Details</h2>
        <div className={css["pledge-details"]}>
          <div>
            <h3>FUND NAME</h3>
            <p>{formatFund(pledge.designation, pledge.designationLookupId.split('-')[0])}</p>
          </div>
          <div>
            <h3>AUTOMATIC PAYMENT</h3>
            {pledge.hasAutomaticPayments
              ? <p><strong className={css["chartreuse-dot"]}>&#11044;</strong><strong className={css["auto-payments"]}> ON</strong></p>
              : <p><strong className={css["red-dot"]}>&#11044;</strong><strong className={css["auto-payments"]}> OFF</strong></p>}
          </div>
          <div>
            <h3>UNIT</h3>
            <p>{pledge.department}</p>
          </div>
          <div>
            <h3>PLEDGE TOTAL</h3>
            <p>{formatAmount(pledge.displayAmount)}</p>
          </div>
          <div>
            <h3>START DATE</h3>
            <p>{formatDate(initializeDate(pledge.date))}</p>
          </div>
          <div>
            <h3>PLEDGE BALANCE</h3>
            <p>{formatAmount(pledge.pledgeBalance)}</p>
          </div>
          <div>
            <h3>LEGAL/DONOR NAME</h3>
            <p>{pledge.primaryDonorName}</p>
          </div>
          <div>
            <h3>PAID TO DATE</h3>
            <p>{formatAmount(pledge.totalPaidAmount)}</p>
          </div>
        </div>

        {pledge.pledgeBalance !== 0 && pledge.agency !== 'Regents' &&
          <div className={css["payment-button"]}>
            <FilledBlue onClick={() => history.push("/paypledge/" + pledge.revenueLookupId)}>MAKE A PAYMENT</FilledBlue>
          </div>}

          {pledge.pledgeBalance !== 0 && pledge.agency === 'Regents' &&
          <div className={css["pledge-regent-text"]}>
            <p>Credit Card payment is not available. Please make your check payable to UC Regents and mail to:</p>
            <p>
              The UCLA Foundation <br/>
              PO Box 7145<br/>
              Pasadena, CA 91109-9903
            </p>
          </div>}

        {installments &&
          <div className={css["pledge-details-footer"]}>
            <span onClick={toggleSection}
                  className={isSectionOpen ? css['up-arrow'] : ""}
                  >{isSectionOpen ? "HIDE" : "SHOW"} PAYMENT HISTORY</span>
          </div>}

      </div>
      {isSectionOpen && <div className={css["payment-history-outer-container"]}>
        <div className={css["payment-history-inner-container"]}>

          {width > 1024 &&
            <table className={css["payment-history-table"]}>
            <thead>
              <tr>
                <th></th>
                <th className={css["payment-history-label"]}>ACTIVITY</th>
                <th className={css["payment-history-label"]}>STATUS</th>
                <th className={css["payment-history-label"]}>DATE</th>
                <th className={css["payment-history-label"]}>LEGAL DONOR NAME</th>
                <th className={css["payment-history-label"]}>AMOUNT</th>
                <th className={css["payment-history-label"]}>PAYMENT BALANCE</th>
                <th className={css["payment-history-label"]}>PAYMENT METHOD</th>
                <th className={css["payment-history-label"]}>RECEIPT</th>
                <th className={css["payment-history-label"]}></th>
              </tr>
            </thead>
            <tbody>
              {installments.map((installment, index) =>
                <tr key={index}>
                  <td></td>
                  <td>{formatActivity({ index: installmentsLength - (index+1), balance: installment.balance })}</td>
                  <td>{pledgePaymentStatus({ balance: installment.balance, date: initializeDate(installment.date), installment })}</td>
                  <td>{formatDate(installment.date )}</td>
                  <td>{installment.primaryDonorName}</td>
                  <td>{formatAmount(installment.displayAmount) }</td>
                  <td>{formatAmount(installment.balance || 0 )}</td>
                  <td>{pledgePaymentMethod(installment, index)}</td>
                  <td>{pledgeActions(installment, index)}</td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>}

          {width <= 1024 &&
            <div className={css["payment-history-mobile-and-tablet-container"]}>
              {installments.map((installment, index) =>
                <div className={css["payment-history-mobile-and-tablet-grid"]} key={index}>
                  <div>
                    <h3 className={css["payment-history-label"]}>ACTIVITY</h3>
                    <span>{formatActivity({ index: installmentsLength - (index+1), balance: installment.balance })}</span>
                  </div>
                  <div>
                    <h3 className={css["payment-history-label"]}>STATUS</h3>
                    <span>{pledgePaymentStatus({ balance: installment.balance, date: initializeDate(installment.date), installment })}</span>
                  </div>
                  <div>
                    <h3 className={css["payment-history-label"]}>DATE</h3>
                    <span>{formatDate(installment.date )}</span>
                  </div>
                  <div>
                    <h3 className={css["payment-history-label"]}>LEGAL DONOR NAME</h3>
                    <span>{installment.primaryDonorName}</span>
                  </div>
                  <div>
                    <h3 className={css["payment-history-label"]}>AMOUNT</h3>
                    <span>{formatAmount(installment.displayAmount) }</span>
                  </div>
                  <div>
                    <h3 className={css["payment-history-label"]}>PAYMENT BALANCE</h3>
                    <span>{formatAmount(installment.balance || 0 )}</span>
                  </div>
                  <div>
                    <h3 className={css["payment-history-label"]}>PAYMENT METHOD</h3>
                    <span>{pledgePaymentMethod(installment, index)}</span>
                  </div>
                  <div className={css["tablet-only"]}>
                  </div>
                  <div>
                    <span>{pledgeActions(installment, index)}</span>
                  </div>
                </div>
              )}
            </div>}

        </div>

        <div className={css["pledge-details-footer"]}>
          <span onClick={toggleSection} className={isSectionOpen ? css['up-arrow'] : ""}>HIDE PAYMENT HISTORY</span>
        </div>
      </div>}
    </div>
  )
}

export default PledgeDetail;
