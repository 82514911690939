import { NavLink } from "react-router-dom"

import AdminLinkIcon from 'images/admin-link-icon.svg'
import AdminAuditIcon from 'images/admin-audit-icon.svg'
import AdminUsersIcon from 'images/admin-users-icon.svg'
import AdminBrowseIcon from 'images/admin-browse-icon.svg'
import AdminDeleteIcon from 'images/x-red.svg'
import AdminCompletedIcon from 'images/check-white.svg'
import { AdminList, AdminListItem } from 'admin/AdminViewListStyles'

import AdminAuctionsIcon from './images/admin-live-auction-icon.svg'
import AdminPreviewAuctionsIcon from './images/admin-auctions-icon.svg'

const AdminViewList = (props) => {
  return (
    <AdminList >
      <ul>
{/*         <hr />
       <div className={"divider"}>
          Shared Admin Tools
        </div>
        <hr />

        <AdminListItem>
          <NavLink className="bold" id="AdminLinkUserToCrmLink" activeClassName="selectedTab" to="/admin/linkusertocrm">
            <img src={AdminLinkIcon} alt='Link Donor icon'/>
            <div><span>LINK USER TO CRM</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold" id="AdminAuditLink"  activeClassName="selectedTab" to="/admin/history">
            <img className="admin-users-svg" src={AdminAuditIcon} alt='Admin Change History icon'/>
            <div><span>CHANGE HISTORY</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold" id="AdminUsersLink"  activeClassName="selectedTab" to="/admin/users">
            <img src={AdminUsersIcon} alt='Admin Users icon'/>
            <div><span>USERS</span></div>
          </NavLink>
        </AdminListItem>

        <div className={"divider"}>
          Auctions Admin
        </div>
   */}     <hr />

        <AdminListItem>
          <NavLink className="bold" id="AdminPreviewAuctionLink" activeClassName="selectedTab" to="/admin/listpreviewauctions">
          <img  src={AdminPreviewAuctionsIcon} alt='Preview Auctions Menu'/>
          <div><span>PREVIEW AUCTIONS</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold"  id="AdminPublicAuctionsLink"  activeClassName="selectedTab" to="/admin/listpublicauctions">
            <img  src={AdminAuctionsIcon} alt='Public Auctions Menu'/>
            <div><span>PUBLIC AUCTIONS</span></div>
          </NavLink>
        </AdminListItem>
        <AdminListItem>
          <NavLink className="bold" id="AdminClosedAuctionsLink" activeClassName="selectedTab" to="/admin/listclosedauctions">
            <img  src={AdminCompletedIcon} alt='Closed Auctions Menu'/>
            <div><span>CLOSED AUCTIONS</span></div>
          </NavLink>
        </AdminListItem>
{/*        <AdminListItem>
          <NavLink className="bold"  id="AdminBrowseAsUserLink"  activeClassName="selectedTab" to="/admin/browseasuser">
            <img  src={AdminBrowseIcon} alt='Browse As User icon'/>
            <div><span>BROWSE AS USER</span></div>
          </NavLink>
        </AdminListItem>
*/}        <AdminListItem>
          <NavLink className="bold"  id="AdminDeleteUserLink"  activeClassName="selectedTab" to="/admin/deleteauctionuser">
            <img src={AdminDeleteIcon} alt='Delete User icon' id="delete-icon"/>
            <div><span>DELETE AUCTION USER</span></div>
          </NavLink>
        </AdminListItem>

      </ul>
    </AdminList>
  )
}

export default AdminViewList
