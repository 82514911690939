import { Component } from 'react';
import styled from 'styled-components'

import Alert from 'common/notifications/Alert'
import * as colors from 'css/Colors';
import iconInfo from 'images/iconColorinfo.svg'
import { showLoading } from 'services/util'

import { getAuditTrail } from "../adminapi"
import LinkUserWrapper from '../linkuser/LinkUserWrapper'
import AuditTrailForm from './AuditTrailForm'
import AuditTrailResults from './AuditTrailResults'

const AuditWrapper = styled.div`
  color:#fff;
  padding:20px;
  border-radius:5px;
  background-color:${ props => props.crm ? `${colors.secondaryMediumBlue}` : `${colors.primaryBlue}`};

  .user-search-form {
    margin: 0;
  }

`
class AuditTrail extends Component {

  state = {
    userAction: 'Last60Days',
  };

  async componentDidMount() {
    showLoading(false)
  }

  handleSearch = async data => {

    if (["CognitoUsername","CrmId","AdminUsername"].indexOf(data.searchType) > -1 && !data.searchValue) {
      this.setState({errorMsg: 'Search field cannot be blank'})
      return;
    }

    this.setState({errorMsg: ''})
    showLoading(true)

    const response = await getAuditTrail(data.searchType, data.searchValue);

    if (!response || !response.entries || response.error || response.errorType) {
      console.log(response)
      await this.setState({errorMsg: response.error ? response.error.msg || 'An Error Occurred' : 'An Error Occurred'})
      await this.setState({tableData: null});
      showLoading(false)
      return "error"
    }

    await this.setState({tableData: response.entries})

    showLoading(false)
  }

  render() {
    return (
      <>
        <LinkUserWrapper>
          <h2>IDM User Change History</h2>

          {this.state.errorMsg &&
            <Alert icon={iconInfo} >
              <div>{this.state.errorMsg}</div>
            </Alert>
          }

          <div className=" grid">
            <div className="span-12">
              <AuditWrapper>
                <AuditTrailForm
                  onSubmit={this.handleSearch}
                >
                </AuditTrailForm>

              </AuditWrapper>
            </div>
          </div>

          <br/>

          {this.state.tableData &&
            <AuditTrailResults data={this.state.tableData} />}

        </LinkUserWrapper>
      </>
    )
  }

  longTableTestData =  [
    {
      Timestamp: '2021-04-14T22:12:24.805Z',
      Action: 'RELINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-15T22:12:24.805Z',
      Action: 'LINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: ''
    },
    {
      Timestamp: '2021-04-16T22:12:24.805Z',
      Action: 'UNLINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-17T22:12:24.805Z',
      Action: 'DELETE_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-14T22:12:24.805Z',
      Action: 'RELINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-15T22:12:24.805Z',
      Action: 'LINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: ''
    },
    {
      Timestamp: '2021-04-16T22:12:24.805Z',
      Action: 'UNLINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-17T22:12:24.805Z',
      Action: 'DELETE_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-14T22:12:24.805Z',
      Action: 'RELINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-15T22:12:24.805Z',
      Action: 'LINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: ''
    },
    {
      Timestamp: '2021-04-16T22:12:24.805Z',
      Action: 'UNLINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-17T22:12:24.805Z',
      Action: 'DELETE_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-14T22:12:24.805Z',
      Action: 'RELINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-15T22:12:24.805Z',
      Action: 'LINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: ''
    },
    {
      Timestamp: '2021-04-16T22:12:24.805Z',
      Action: 'UNLINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-17T22:12:24.805Z',
      Action: 'DELETE_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-14T22:12:24.805Z',
      Action: 'RELINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-15T22:12:24.805Z',
      Action: 'LINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: ''
    },
    {
      Timestamp: '2021-04-16T22:12:24.805Z',
      Action: 'UNLINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-17T22:12:24.805Z',
      Action: 'DELETE_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-14T22:12:24.805Z',
      Action: 'RELINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-15T22:12:24.805Z',
      Action: 'LINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: ''
    },
    {
      Timestamp: '2021-04-16T22:12:24.805Z',
      Action: 'UNLINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-17T22:12:24.805Z',
      Action: 'DELETE_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-14T22:12:24.805Z',
      Action: 'RELINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-15T22:12:24.805Z',
      Action: 'LINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: ''
    },
    {
      Timestamp: '2021-04-16T22:12:24.805Z',
      Action: 'UNLINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-17T22:12:24.805Z',
      Action: 'DELETE_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-14T22:12:24.805Z',
      Action: 'RELINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-15T22:12:24.805Z',
      Action: 'LINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: ''
    },
    {
      Timestamp: '2021-04-16T22:12:24.805Z',
      Action: 'UNLINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-17T22:12:24.805Z',
      Action: 'DELETE_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-14T22:12:24.805Z',
      Action: 'RELINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-15T22:12:24.805Z',
      Action: 'LINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: ''
    },
    {
      Timestamp: '2021-04-16T22:12:24.805Z',
      Action: 'UNLINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-17T22:12:24.805Z',
      Action: 'DELETE_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-14T22:12:24.805Z',
      Action: 'RELINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-15T22:12:24.805Z',
      Action: 'LINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: ''
    },
    {
      Timestamp: '2021-04-16T22:12:24.805Z',
      Action: 'UNLINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-17T22:12:24.805Z',
      Action: 'DELETE_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-14T22:12:24.805Z',
      Action: 'RELINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-15T22:12:24.805Z',
      Action: 'LINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: ''
    },
    {
      Timestamp: '2021-04-16T22:12:24.805Z',
      Action: 'UNLINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-17T22:12:24.805Z',
      Action: 'DELETE_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-14T22:12:24.805Z',
      Action: 'RELINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-15T22:12:24.805Z',
      Action: 'LINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: ''
    },
    {
      Timestamp: '2021-04-16T22:12:24.805Z',
      Action: 'UNLINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-17T22:12:24.805Z',
      Action: 'DELETE_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-14T22:12:24.805Z',
      Action: 'RELINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-15T22:12:24.805Z',
      Action: 'LINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: ''
    },
    {
      Timestamp: '2021-04-16T22:12:24.805Z',
      Action: 'UNLINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-17T22:12:24.805Z',
      Action: 'DELETE_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-14T22:12:24.805Z',
      Action: 'RELINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-15T22:12:24.805Z',
      Action: 'LINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: ''
    },
    {
      Timestamp: '2021-04-16T22:12:24.805Z',
      Action: 'UNLINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-17T22:12:24.805Z',
      Action: 'DELETE_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-17T22:12:24.805Z',
      Action: 'DELETE_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-14T22:12:24.805Z',
      Action: 'RELINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-15T22:12:24.805Z',
      Action: 'LINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '0123456789',
      PreviousCrmId: ''
    },
    {
      Timestamp: '2021-04-16T22:12:24.805Z',
      Action: 'UNLINK_USER',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
    {
      Timestamp: '2021-04-17T22:12:24.805Z',
      Action: 'WTF',
      AdminUser: 'msavino@support.ucla.edu',
      CognitoUser: 'suzzer99gmail.com',
      CrmId: '',
      PreviousCrmId: '000000008'
    },
  ]
}

export default AuditTrail;
