import { Component } from 'react';

import { ThemeContext } from 'ThemeContext';
import { capitalize } from 'services/util';
import Address from './Address';
import Phones from './Phones';
import Email from './Email';
import SocialMedia from './SocialMedia';

import * as colors from 'css/Colors'
import styled from 'styled-components'

const ContactInformation = styled.div`
.custom-h6{
 font-size:calc(16rem/16);
  padding:15px 0;
}
`

const AddressWrapper = styled.div`
display:flex;
flex-wrap:nowrap;
flex-direction:column;
justify-content:flex-start;
`
const AddOnWrapper = styled.div`
outline-style: dashed;
outline-width:thin;
outline-color: ${colors.primaryBlue};
margin-bottom: 30px;`


const AddOnContainer = styled.div`
text-align:center;
margin:0;
& >h6{
  margin:2px;
  cursor:pointer;
}`

const BioDataWrapper = styled(AddressWrapper)`
flex-direction:column;
& > div{
 flex:1 0 0;
}`

const types = {
 address: ["homeAddress", "businessAddress", "noAddressTypeSelectedYet"],
 phone: ["homePhone", "businessPhone", "homeCell", "businessCell", "noPhoneTypeSelectedYet"],
 email: ["homeEmail", "businessEmail", "noEmailTypeSelectedYet"],
};

const displayTypes = {
 homeAddress: "Home",
 businessAddress: "Business",
 noAddressTypeSelectedYet: "Select a Type",

 homePhone: "Home Phone",
 businessPhone: "Business Phone",
 homeCell: "Home Cell",
 businessCell: "Business Cell",
 noPhoneTypeSelectedYet: "Select a Type",

 homeEmail: "Home",
 businessEmail: "Business",
 noEmailTypeSelectedYet: "Select a Type",
};

class MyInformationContact extends Component {
 constructor(props) {
  super(props);
  this.state = {
   addressList: [],
   phoneList: [],
   emailList: [],
  }

 }


 // create phone, address and email arrays which contain the items shown
 static getDerivedStateFromProps(props, state) {
  if (props.data) {
   const phones = MyInformationContact.processContact(props, state, 'phone');
   const addresses = MyInformationContact.processContact(props, state, 'address');
   const emails = MyInformationContact.processContact(props, state, 'email');
   return {
    phoneList: phones,
    addressList: addresses,
    emailList: emails,
   }
  }
  return null;
 }

 // check userData object against all types, if an item is new add it to the array, if it is missing remove it from the array
 // push primary item to top - but only at initial page load (otherwise it jumps around when the user changes primary - bad experience)
 static processContact(props, state, fieldType) {
  let items = state[fieldType + 'List'];

  types[fieldType].forEach(type => {
   if (props.data[type]) {
    const item = { contact: props.data[type] };
    // the property name used on userData object - needed for the setType dropdown
    item.propName = type;

    // re-calculate availableDisplayTypes for each item every time something changes
    item.availableDisplayTypes = MyInformationContact.getAvailableTypes(fieldType, props);

    const matchingItem = items.filter(member => member.contact[fieldType + 'Type'] === displayTypes[type])[0];
    if (matchingItem) {
     items[items.indexOf(matchingItem)] = item;
    }
    else {
     if (items.length === 0 || item.contact.isPrimary) items.unshift(item); // only put primary on top at initial page load
     else items.push(item);
    }
   }
   else {
    // item may have been removed, if so remove it from items array
    items = items.filter(member => member.contact[fieldType + 'Type'] !== displayTypes[type]);
   }
  });

  // only phone matters - if user changes type we need to preserve the position or it jumps around
  if (fieldType === 'phone' && items.length > 0 && items[items.length-1].contact.oldIndex > -1) {
    const oldIndex = items[items.length-1].contact.oldIndex;
    const lastIndex = items.length-1;
    items.splice(oldIndex, 0, items.splice(lastIndex, 1)[0]);
  }

  return items;
 }

 // gets all the available types left for the type select dropdown when the user adds a new item
 // IE - if homePhone and businessPhone are showing, will return homeCell and businessCell
 static getAvailableTypes = (fieldType, props) => {
  return types[fieldType]
   .filter(type => !props.data[type] && type !== 'no' + capitalize(fieldType) + 'TypeSelectedYet')
   .map(type => { return { key: type, displayValue: displayTypes[type] }; });
 }

 // sets isPrimary to true for target item and false for all the other items
 setPrimary = (fieldType, fieldName) => {
  // don't allow user to set primary until they pick a type
  if (fieldName.indexOf('TypeSelectedYet') === -1) {
   types[fieldType].forEach(type => {
    // set target item to true, all others to false
    if (this.props.data[type]) this.props.changeHandler({ target: { value: (type === fieldName), name: type + '.isPrimary' } });
   });
  }
 }

 // for new type - use a copy of existing object, then remove the old one - since each is stored as a unique property on the userData object
 handleTypeChange = (event) => {
  const [oldItemName, fieldType] = event.target.name.split('.');
  const newValue = event.target.value.displayValue;
  const newKey = event.target.value.key;

  const itemCopy = JSON.parse(JSON.stringify(this.props.data[oldItemName]));

  // set old index so phoen doesn't jump around when changing type
  if (fieldType === 'phoneType') {
    const phoneNames = this.state.phoneList.map(item => item.propName);
    itemCopy.oldIndex = phoneNames.indexOf(oldItemName);
  }

  itemCopy[fieldType] = newValue;
  this.removeItem(oldItemName);

  this.props.changeHandler({ target: { value: itemCopy, name: newKey } });
 }

 // removes a contact item (phone, email, address) from the userData object - which triggers the item to be removed locally
 removeItem = (itemName) => {
  this.props.changeHandler({ target: { value: null, name: itemName } });
 }

 // adds a new contact item (phone, email, address) from the userData object - which triggers the item to be removed locally
 addNewItem = (fieldType) => {
  const availableTypes = MyInformationContact.getAvailableTypes(fieldType, this.props);
  const isPrimary = (availableTypes.length === types[fieldType].length); // set primary to true if it's the first one added

  let newItem = null, type = null;
  if (fieldType === 'phone') {
   type = (availableTypes.length > 1) ? 'noPhoneTypeSelectedYet' : availableTypes[0].key;
   newItem = {
    "phoneType": displayTypes[type],
    "country": "United States", // TODO - need to handle international numbers
    "number": "",
    "isPrimary": isPrimary,
    "endDate": null,
    "phoneId": "",
   }
  }
  else if (fieldType === 'address') {
   type = (availableTypes.length > 1) ? 'noAddressTypeSelectedYet' : availableTypes[0].key;
   newItem = {
    "addressType": displayTypes[type],
    "country": "",
    "addressBlock": "",
    "city": "",
    "state": "CA",
    "postCode": "",
    "isPrimary": isPrimary,
    "endDate": null,
    "addressId": "",
   }
  }
  else if (fieldType === 'email') {
   type = (availableTypes.length > 1) ? 'noEmailTypeSelectedYet' : availableTypes[0].key;
   newItem = {
    "emailType": displayTypes[type],
    "emailAddress": "",
    "isPrimary": isPrimary,
    "endDate": null,
    "emailId": "",
   }
  }

  this.props.changeHandler({ target: { value: newItem, name: type } });
 }

 render() {

  return (
   <ContactInformation>

    <h4 className="bold"> Address</h4>
    <p>Deleting your mailing address, email or phone number will not remove you from UCLA mailing or call lists.
    To opt out of receiving communications from UCLA, please contact <a href={"mailto:"+ this.context.supportEmail}>{this.context.supportEmail}</a>
    {this.context.supportPhone && <span> or {this.context.supportPhone}</span>}
    &nbsp;If you delete your existing mailing address or email and do not provide a new one, UCLA will not be able to provide you with a tax receipt for any new gifts.</p>

    <AddressWrapper>
     {this.state.addressList.map(address =>
      <Address key={address.contact.addressType}
       updateValidation={this.props.updateValidation}
       data={address.contact}
       propName={address.propName}
       readOnly={address.availableDisplayTypes.length < 1}
       setPrimary={this.setPrimary}
       availableDisplayTypes={address.availableDisplayTypes}
       handleTypeChange={this.handleTypeChange}
       changeHandler={this.props.changeHandler}
       remove={!address.contact.isPrimary ? this.removeItem : null} />
     )}

     {this.state.addressList.length < 2 &&
      <AddOnWrapper onClick={() => this.addNewItem('address')}>
       <AddOnContainer>
        <h6 className="custom-h6 bold"> ADD  ADDRESS </h6>
       </AddOnContainer>
      </AddOnWrapper>}
    </AddressWrapper>

    <BioDataWrapper>
     <h4 className="bold"> Phones </h4>

     {this.state.phoneList.map(phone =>
      <Phones key={phone.contact.phoneType}
      updateValidation={this.props.updateValidation}
       data={phone.contact}
       propName={phone.propName}
       readOnly={phone.availableDisplayTypes.length < 1}
       setPrimary={this.setPrimary}
       availableDisplayTypes={phone.availableDisplayTypes}
       handleTypeChange={this.handleTypeChange}
       changeHandler={this.props.changeHandler}
       remove={!phone.contact.isPrimary ? this.removeItem : null} />
     )}

     {this.state.phoneList.length < 4 &&
      <AddOnWrapper onClick={() => this.addNewItem('phone')}>
       <AddOnContainer>
        <h6 className="custom-h6 bold"> ADD PHONE NUMBER </h6>
       </AddOnContainer>
      </AddOnWrapper>}
    </BioDataWrapper>

    <BioDataWrapper>
     <h4 className="bold"> Emails </h4>

     {this.state.emailList.map(email =>
      <Email key={email.contact.emailType}
      updateValidation={this.props.updateValidation}
       data={email.contact}
       propName={email.propName}
       readOnly={email.availableDisplayTypes.length < 1}
       setPrimary={this.setPrimary}
       availableDisplayTypes={email.availableDisplayTypes}
       handleTypeChange={this.handleTypeChange}
       changeHandler={this.props.changeHandler}
       remove={!email.contact.isPrimary ? this.removeItem : null} />
     )}

     {this.state.emailList.length < 2 &&
      <AddOnWrapper onClick={() => this.addNewItem('email')}>
       <AddOnContainer>
        <h6 className="custom-h6 bold"> ADD EMAIL </h6>
       </AddOnContainer>
      </AddOnWrapper>}
    </BioDataWrapper>

    <BioDataWrapper>
     <h4 className="bold"> Social Media</h4>
     <SocialMedia
      data={this.props.data}
      changeHandler={this.props.changeHandler}
      gutterRef={ this.props.gutterRef }
     />
    </BioDataWrapper>
   </ContactInformation>
  )
 }
}

export default MyInformationContact;
MyInformationContact.contextType = ThemeContext;

