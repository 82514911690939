import { useContext } from 'react';

import styled, { css } from 'styled-components'

import { ThemeContext } from 'ThemeContext';
import { device } from 'common/Device'
import Zindex from 'css/z-indexLayers.js'
import * as colors from 'css/Colors'
import uclalogo from 'images/UCLA-logo.svg'

const defaultFontSize = 16

const FooterContainer = styled.footer`
  position:absolute;
  bottom:0;
  width: 100%;
  height:${75 / defaultFontSize}rem;
  box-sizing:border-box;
  z-index:${ Zindex.zindexmMainContent };
  background-color: ${colors.secondaryMediumBlue};
  .wrapper {
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ucla-footer-logo{
    background:url(${uclalogo}) no-repeat;
    background-size:contain;
    width: ${79 / defaultFontSize}rem;
    height: ${26 / defaultFontSize}rem;
    margin:0 ${18 / defaultFontSize}rem 0 0;
  }
  @media ${device.laptop}{
    height:${120 / defaultFontSize}rem;
    padding-top:${ 16/defaultFontSize }rem;
    padding-bottom:${ 16/defaultFontSize }rem;
    .wrapper {
      flex-direction:column;
      justify-content:space-between;
    }
    .ucla-footer-logo{
      margin:0 auto;
    }
  }
  @media ${device.mobile}{
    position:relative;
    height:auto;
    padding-top:0;
    padding-bottom:0;
    .wrapper {
      align-items:flex-start;
      padding:0;
    }
   .ucla-footer-logo{
      margin:${17/defaultFontSize}rem 0 0 ${ 15 / 320 * 100 }%;
    }
  }
`

const LinkStyles = css`
  text-decoration: none;
  color: #fff;
  border-right: 1px solid #fff;
  padding-right: 10px;
  &:hover {
  color: #fff;
  text-decoration: underline;
  }
  & span{
    color:#fff;
  }
  @media ${device.mobile}{
    border:none;
  }
`
const StyledLink = styled.a`
  ${LinkStyles}
`

const FooterList = styled.ul`
  display:flex;
  justify-content: center;
  align-content: center;
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 0;
  @media ${device.mobile}{
    width:100%;
    flex-direction: column;
    align-content: flex-start;
    justify-content: space-between;
    padding:0;
    margin: ${10 / defaultFontSize}rem 0;
    &:last-child {
      height:100%;
    }
  }
`

const FooterListLastChildOverlay= styled.div`
  @media ${device.mobile} {
    width:100%;
    :before{
      content:'';
      display:block;
      width:100%;
      height:${1/defaultFontSize}rem;
      background-color:#fff;
    }
  }
`
const FooterListItem = styled.li`
  color: #fff;
  font-size:${14 / defaultFontSize}rem;
  line-height:1rem;
  margin: 0 ${5 / defaultFontSize}rem;

@media ${device.laptop}{
  &:last-child{
    ${StyledLink} {
      border-right:none;
    }
  }
}
@media ${device.mobile}{
  text-align:left;
  margin:0;
  line-height:${32/14};
  padding-left:${ 15 / 320 * 100 }%;
  padding-right:${ 15 / 320 * 100 }%;
}`

const Footer = (props) => {
  const theme = useContext(ThemeContext)

  return (
    <FooterContainer className="hide-on-print">
      <div className="wrapper">
        <div className="ucla-footer-logo" />
        <FooterList>
          <FooterListItem><StyledLink target="_blank" href="https://www.ucla.edu">UCLA.edu</StyledLink></FooterListItem>
          <FooterListItem><StyledLink target="_blank" href="https://externalaffairs.ucla.edu/development">UCLA Development</StyledLink></FooterListItem>
          <FooterListItem><StyledLink target="_blank" href={theme.uclaFoundationLink || 'https://www.uclafoundation.org'}>UCLA Foundation</StyledLink></FooterListItem>
          <FooterListItem><StyledLink href="/privacypolicy">Privacy Policy</StyledLink></FooterListItem>
          <FooterListItem><StyledLink target="_blank" href="https://www.ucla.edu/accessibility">Accessibility</StyledLink></FooterListItem>
        </FooterList>
        <FooterListLastChildOverlay>
          <FooterList>
            <FooterListItem><span>© {localStorage.currentYear} Regents of the</span> University of California</FooterListItem>
          </FooterList>
        </FooterListLastChildOverlay>
      </div>
    </FooterContainer>
  )
}

export default Footer

