import { useEffect, useState } from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import css from './sass/tabnav.module.scss'

export default function TabNav( {titles, panels, goToIndex} ) {

  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    setSelectedIndex(goToIndex)
  }, [goToIndex])
  

  return (
    <>
      <Tabs className={css['react-tabs']}
          selectedIndex={selectedIndex}
          onSelect={index => setSelectedIndex(index)}
      >
        <TabList className={css['react-tabs__tab-list']}>
          {titles && titles.map((title, idx) => <Tab 
              key={idx}
              className={css['react-tabs__tab']}
              selectedClassName={css['react-tabs__tab--selected']}
              disabledClassName={css['react-tabs__tab--disabled']}
            >{title.toUpperCase()}</Tab>)}
        </TabList>
        {panels && panels.map((panel, idx) => <TabPanel
            key={idx}
            className={css['react-tabs__tab-panel']}
            selectedClassName={css['react-tabs__tab-panel--selected']}
          >{panel}</TabPanel>)}
      </Tabs>
    </>
  )
}