import { useEffect } from 'react';

const MatchingGifts = () => {

  const scriptId = 'edl-script'

  useEffect(() => {

    // strip hash if it exists
    window.history.pushState("", document.title, window.location.pathname + window.location.search);

    // handles hard refresh
    // window.addEventListener("beforeunload", function(e) {
    //   sessionStorage.removeItem('removeScript');
    // });

    if (document.getElementById(scriptId) === null) {
      loadScript(scriptId)
    }

    // sessionStorage.setItem('removeScript', "true")

    // breaks on local because of the react strict-mode double render
    // the best answer I could come up with was the sessionStorage toggle
    // but that breaks it when not double-rendering, so it's commented out
    return () => {

      console.log('unmounting')
      // if (document.getElementById(scriptId) && sessionStorage.getItem('removeScript')) {
      if (document.getElementById(scriptId)) {
        console.log('removing script')
        // sessionStorage.removeItem('removeScript')
        document.getElementById(scriptId).remove()
      }
      else {
        console.log('script already removed')
        // sessionStorage.setItem('removeScript', "true")
      }
    }
  }, [])

  const loadScript = (scriptId) => {
    const script = document.createElement("script");
    script.src = "https://matchbox.hepdata.com/edl/ucla/rb8kfiw5/script.js";
    script.async = false;
    script.id = scriptId;
    document.head.appendChild(script);
  };

  return  (
    <>
      <div id="edl-template"></div>
    </>
  )
}

export default MatchingGifts
