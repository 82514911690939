// this is the big overlay that takes up the page
import styled from 'styled-components'

import { device } from 'common/Device'
import { primaryBlue } from 'css/Colors'
import Zindex from 'css/z-indexLayers.js';
import iconBackArrow from 'images/arrow-back.svg'
import iconBlueArrow from 'images/arrow-right.svg'

const defaultFontSize = 16
const overlayWidth = 1413
const tabletOverlayWidth = 768

const Modal = styled.div`
  display:flex;
  align-items:stretch;
  position:fixed;
  top:0;
  left:0;
  z-index:${Zindex.zindexPopUpStackOrder1};
  width:100vw;
  height:100vh;
  background:rgba(0,0,0,0.7);

  @media ${ device.mobile } {
    display:block;
  }

  @media print {
    position:static;
    height:auto;
    width:100%;
    background:transparent;
  }
`

const BackButton = styled.span`
  display:block;
  cursor:pointer;
  margin-top:${ 153/defaultFontSize }rem;
  margin-right:${ 15/defaultFontSize }rem;
  white-space:nowrap;
  color:#FFFFFF;

  span {
    display:inline-block;
    vertical-align:middle;
  }

  &:before {
    transform: scaleX(-1);
    transform-origin: center;
    content:'';
    display:inline-block;
    vertical-align:middle;
    height:0.9em;
    width:0.9em;
    background:url(${iconBackArrow}) no-repeat;
    background-size:contain;
    margin-right:${ 6/defaultFontSize }rem;
  }

  @media screen and ${ device.mobile } {
    color:${ primaryBlue };
    margin:${ 8/defaultFontSize }rem 0;

    &:before {
      background-image:url(${iconBlueArrow});
    }
  }
`

const ModalContent = styled.div`
  position:relative;
  overflow:scroll;
  z-index:${Zindex.zindexPopUpStackOrder2};
  height:100vh;
  top:0;
  right:0;
  background:#FFFFFF;

  @media print {
    position:static;
    overflow:auto;
    height:auto;
    width:100%;
    background:transparent;
  }
`

const CloseModal = styled.span`
  display:block;
  text-align:right;

  text-transform:uppercase;
  font-weight:600;

  flex:1 0 ${ 152/overlayWidth * 100 }%;
  height:100vh;

  @media screen and ${ device.tablet } {
    flex:1 0 ${ 127/tabletOverlayWidth * 100 }%;
  }

  @media screen and ${ device.mobile } {
    display:flex;
    align-items:center;
    position:fixed;
    top:0;
    left:0;
    width:20%;
    padding:0 ${ 15/320 * 100 }%;
    height:${ 70/defaultFontSize }rem;
    z-index:${parseInt(Zindex.zindexPopUpStackOrder2, 10) + 1};
    text-align:left;
  }

  @media print {
    display:none;
  }
`

const Overlay = props => (
  <Modal>
    {!props.useFullModal && <CloseModal>
      <BackButton
        onClick={ e => {
          if (props.onHideOverlay && typeof props.onHideOverlay === 'function') {
            props.onHideOverlay( e )
          }
        }}
      ><span>Back</span></BackButton>
    </CloseModal>}
    <ModalContent className="modal-content">
      { props.children }
    </ModalContent>
  </Modal>
)

export default Overlay
