import { useEffect } from "react"
import css from './sass/additionalinfo.module.scss'

import { showLoading } from "services/util"

import * as cs from 'css/CommonStyle'

function PendingRequestDenied(props) {
  useEffect(() => {
    showLoading(false, 'PendingRequestDenied')
  }, [])

  return (
		<div className={css['additional-info-form']}>
			<br/>
			<h3 id="pageTitle" className=" bold">Access Not Approved</h3>

			<cs.CommonInnerDiv>
				<cs.InnerContent>

					<div style={{"text-align": "left"}}>
						{props && props.location && props.location.state && props.location.state.firstName &&
							<p>Dear {props.location.state.firstName},</p>}

						<p>
						  Thank you for registering your profile on UCLA ONE. Unfortunately, we are unable to approve your request at this time. The most common reason for this is because the information you provided does not match your UCLA record.
						</p>
						<p>
						  If you believe that you qualify for access to UCLA ONE, please email us at: <a href="mailto:ace@support.ucla.edu?subject=UCLA ONE request not approved inquiry">ace@support.ucla.edu</a>
						</p>
						<p>
						  Kind Regards,
					    <br/>
					    The UCLA ONE team
						</p>
					</div>

				</cs.InnerContent>

			</cs.CommonInnerDiv>

		</div>
	)

}

export default PendingRequestDenied