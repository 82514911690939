import adminCss from './sass/adminpage.module.scss'

import ItemDisplay from './ItemDisplay'

export default function AdminAuctionItems(props) {

  return (
    <div className={adminCss['admin-auction-page-tab-container']}>
      <h3 className={adminCss['admin-auction-page-tab-header']}>
        Auction Items
      </h3>

      <ItemDisplay selectedAuction={props.auction} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} />
    </div>
  )
}