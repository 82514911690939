import { Component } from 'react';
import { withRouter } from "react-router-dom";

import { ThemeContext } from 'ThemeContext';
import { getCognitoUsers, linkCrmUser } from "admin/adminapi";
import { adminErrorHandler } from 'admin/adminutil'
import { showLoading } from 'services/util'

import styled from 'styled-components'
import LookupCrmPanel from 'admin/linkuser/LookupCrmPanel'
import LinkUserWrapper from 'admin/linkuser/LinkUserWrapper'
import UserProfile from 'admin/linkuser/UserProfile'
import LinkUserForm from 'admin/linkuser/LinkUserForm'
import ConfirmModal from 'common/ConfirmModal'
import Alert from 'common/notifications/Alert'
import UserProfileInfopopup from 'admin/linkuser/UserProfileInfopopup'
import * as colors from 'css/Colors';
import { FilledBlue } from 'css/Buttons';
import LinkImage from 'images/link-image.svg'
import iconInfo from 'images/iconColorinfo.svg'

const defaultFontSize = 16

const ModalInner = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;

  .modal-text {
    padding: 0 10px;
    p {
      margin: 0;
      max-width: 450px;
    }
    .bold {
      margin-bottom: 10px;
    }
  }
`
const UserCRMInformation = styled.div`
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  background-color: ${ props => props.crm ? `${colors.secondaryMediumBlue}` : `${colors.primaryBlue}`};
  min-height: ${315/defaultFontSize}rem;
  h6 {
    color :#fff;
    margin: 0 0 ${18/defaultFontSize}rem;
  }
  .crm-user-information {
    background-color: #FFFFC3;
  }
  .smaller-alert > div > div {
    padding: 0 calc(10rem/16);
  }
  .user-search-form {
    margin: 0;
  }
  .mismatch-error {
    color:#000000;

  }
  .link-user-info-block-title {
    font-size: ${16/defaultFontSize}rem;
  }
  .account-display {
    font-size: ${14/defaultFontSize}rem;
  }
`

const SubmitButtons = styled.div`
  text-align: center;
  margin: ${30 / defaultFontSize}rem;
  & > div {
    display: inline-block;
  }
  a {
    text-decoration: none;
  }
`

class LinkCrmComponent extends Component {

  state = {
    searchFoundUsers: '',
    alreadyLinked: false,
    linkedCrmId: '',
    cognitoLookup: '',
    cognitoLookupResponse: '',
    crmLookup: '',
    crmFoundConstituent: false,
    username: null,
    confirmUnlink: false,
    confirmRelink: false,
    linkUserResponse: ''
  };

  handleCognitoSubmit = async e => {
    e.preventDefault()

    showLoading(true)

    const data = new FormData(e.target);

    let lookup = data.get('username').trim();

    // Strip phone username of nonnumeric chars
    if (lookup && lookup.charAt(0) === '+')
      lookup = lookup.replace(/\D/g,'')

    const response = await getCognitoUsers(lookup);

    this.setState({
      cognitoLookupResponse: response,
      searchFoundUsers: response.length
    });

    if (response.length) {
      if ('primaryCrmId' in response[0].Attributes) // ok to use first since all or none users (or none) should be linked
        this.setState({alreadyLinked: true, linkedCrmId: response[0].Attributes.primaryCrmId})
      else
        this.setState({alreadyLinked: false, linkedCrmId: null})

      this.setState({
        username: response[0].Attributes.email || response[0].Attributes.phone_number,
      })
    }
    else {
      this.setState({ username: null, alreadyLinked: false, linkedCrmId: null })
    }

    showLoading(false)
  }

  handleCognitoChange = e => {
    this.setState({
      cognitoLookup: e.target.value.trim(),
      cognitoLookupResponse:'',
      searchFoundUsers: '',
      username: null,
      alreadyLinked: false,
    });
  }

  handleCrmChange = (crmLookup) => {
    this.setState(crmLookup);
  }

  handleLinkUser = async e => {
    e.preventDefault()
    showLoading(true);

    const response = this.state.confirmUnlink
      ? await linkCrmUser("UNLINK_USER", this.state.crmLookup, this.state.username, this.state.linkedCrmId)
      : await linkCrmUser(this.state.linkedCrmId ? "RELINK_USER" : "LINK_USER", this.state.crmLookup, this.state.username, this.state.linkedCrmId);

    this.setState({
      linkUserResponse: response,
      confirmRelink: false,
      confirmUnlink: false
    });

    if (response && response.success) {
      this.props.history.push("/admin/linkusertocrm/success");
    }

    showLoading(false);
  }


  render() {
    // for some reason react on node > 14 complains about the ThemeContext not being initiated for this file and Menu.js
    LinkCrmComponent.contextType = ThemeContext;
    if (!this.context.name) return

    let UserProfiles = this.state.searchFoundUsers && this.state.cognitoLookupResponse.map((user,index) => {
      return <UserProfile
        key={index}
        value={index}
        user={user}
        numUsers={this.state.searchFoundUsers}
        selected={true}
        handleSelect={() => {}}
      />
    })

    return (
      <>
        {(this.state.confirmUnlink || this.state.confirmRelink) &&
          <ConfirmModal
            onConfirm={this.handleLinkUser}
            onCancel={() => {this.setState({confirmUnlink: false, confirmRelink: false})}}
            cancelText={"CANCEL"}
            confirmText={"OK"}
          >
            {this.state.confirmUnlink &&
              <ModalInner>
                {/* <div><img src={AlertIcon} alt='Unlink User confirmation icon'/></div> */}
                <div className="modal-text">
                  <p className="bold">Confirm Unlink User?</p>
                  <p>Are you sure you want to unlink <b>{this.state.username}</b> from CRM account <b>{this.state.linkedCrmId}</b>?</p>
                </div>
              </ModalInner>
            }
            {this.state.confirmRelink && this.state.crmLookup &&
              <ModalInner>
                {/* <div><img src={AlertIcon} alt='Delete User confirmation icon'/></div> */}
                <div className="modal-text">
                  <p className="bold">Confirm Re-Link User?</p>
                  <p>Are you sure you want to change <b>{this.state.username}</b> from CRM account <b>{this.state.linkedCrmId}</b> to CRM account <b>{this.state.crmLookup}</b>?</p>
                </div>
              </ModalInner>
            }
          </ConfirmModal>
        }

        <LinkUserWrapper>
          <h2> Link User to CRM</h2>

          {this.state.linkUserResponse && !this.state.linkUserResponse.success &&
            <Alert
              className="smaller-alert"
              nonPromoPage
              icon={iconInfo}
              color={colors.messagingRed}
              width="25" height="25" top="35%" >
              <div className="mismatch-error">Could not update user link. Error: "{this.state.linkUserResponse.error.msg}"</div>
            </Alert>
          }

          <div className=" grid">

          {/* User Information */}
            <div className="span-5">
              <UserCRMInformation>
                <LinkUserForm
                  title={"User's Information"}
                  inputName={"userId"}
                  btnName={"cognitoSearch"}
                  inputTitle={"Enter User's Email/Phone Number"}
                  formType="user"
                  onSubmit={this.handleCognitoSubmit}
                  onChange={this.handleCognitoChange}
                >
                  <UserProfileInfopopup/>
                </LinkUserForm>

              {this.state.cognitoLookupResponse && (this.state.searchFoundUsers
                ?
                <div className="account-display">
                  <p> <strong>{this.state.searchFoundUsers} account{this.state.searchFoundUsers !== 1 ? 's' : ''} found {this.state.alreadyLinked ? 'linked to ' + this.state.linkedCrmId : ''}</strong> <br/>
                  {this.state.searchFoundUsers > 1&& <em>choose to link, re-link, or unlink these accounts from CRM</em>} </p>
                  {UserProfiles}
                </div>
                :
                <Alert
                  className="smaller-alert"
                  padded
                  nonPromoPage
                  icon={iconInfo}
                  color={colors.messagingRed}
                  width="25" height="25" top="35%" >
                  <div className="mismatch-error">{adminErrorHandler(this.state.cognitoLookupResponse.error, `No Account found with username "${this.state.cognitoLookup}". Please check the username and try again.`)}</div>
                </Alert>
              )}
              </UserCRMInformation>
            </div>

            {/* Linked Image */}
            <div className=" span-2 linked-image-wrapper" >
              <img alt='' src={LinkImage} />
            </div>

            {/* CRM Information display */}
            <LookupCrmPanel handleCrmChange={this.handleCrmChange} specialHandling={this.context.linkCrmSpecialHandling ? this.context.linkCrmSpecialHandling : {}}/>

          </div>

          {/* TODO - for now only allowing link user */}
          {(!this.state.alreadyLinked || localStorage.isDpAdmin === "true") && <SubmitButtons>
            <div>
              <FilledBlue name="linkUser"
                disabled={!this.state.username || !this.state.crmFoundConstituent || (this.state.linkedCrmId && this.state.linkedCrmId === this.state.crmLookup)}
                onClick={this.state.alreadyLinked ? () => this.setState({confirmRelink: true}) : this.handleLinkUser}>
                <span> {this.state.alreadyLinked ? 'RE-LINK' : 'LINK'} USER TO CRM</span>
              </FilledBlue>
            </div>
          </SubmitButtons>}
          {localStorage.isDpAdmin === "true" && <SubmitButtons>
            <div>
              <FilledBlue name="unlinkUser"
                disabled={!this.state.alreadyLinked}
                onClick={() => this.setState({confirmUnlink: true})}>
                <span> UNLINK USER</span>
              </FilledBlue>
            </div>
          </SubmitButtons>}

          <br/>
          <br/>
          <br/>

        </LinkUserWrapper>
      </>
    )
  }
}


export default withRouter(LinkCrmComponent);
