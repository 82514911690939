import styled from 'styled-components'

import WithBackground from 'common/WithBackground'
import { HeaderTitle } from 'css/CommonStyle'
import { device } from 'common/Device'
const defaultFontSize = 16

export const HeaderBackground = styled(WithBackground)`
  &:after {
    background: linear-gradient(rgba(0,59,92,0.8) 0%, rgba(0,59,92,0.8) 100%, transparent),${props => `url("${props.headerImage}")` || ''} no-repeat center;
  }
  @media ${ device.tablet } {
  padding-top:0;
  ${ HeaderTitle } {
    padding:${ 15/defaultFontSize }rem 0;
  }
}
`