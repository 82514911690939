import styled from 'styled-components'
import { FilledYellow } from 'css/Buttons'

const ButtonWrap = styled.div`
  display: inline-block;
  white-space: nowrap;
  // float: right;
  padding: .5rem 2rem .5rem 0;
`;

function PendingActionButton(props) {
  return (
    <ButtonWrap>
      <FilledYellow
        width="7.0625rem"
        bgColor={"#FFD100"}
        fontColor={"#005587"}
        borderColor={"#FFD100"}
        name={props.btnName}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        <span>{props.btnName}</span>
      </FilledYellow>
    </ButtonWrap>
  )
}

export default PendingActionButton
