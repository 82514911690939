import { doApi } from 'services/api';

// deletes current logged in User from Cognito (used for testing)
export const deleteUser = async (emailOrPhone) => {
  return await doApi({route:'admin/deleteuser?username=' + encodeURIComponent(emailOrPhone)});
};

export const printReceipt = async (revenueId) => {
  return await doApi({route:'admin/printreceipt/' + revenueId});
};

export const getDownloads = async (reportType, queryType, data) => {
  if (data.searchDateFrom || data.searchDateTo) {
    // return console.log({route:'admin/querydownloads?reporttype=' + reportType + '&querytype=' + queryType
    //                      + '&queryvalue1=' + encodeURIComponent(data.searchDateFrom || '')
    //                      + '&queryvalue2=' + encodeURIComponent(data.searchDateTo || '') });
    return await doApi({route:'admin/querydownloads?reporttype=' + reportType + '&querytype=' + queryType
                         + '&queryvalue1=' + encodeURIComponent(data.searchDateFrom || '')
                         + '&queryvalue2=' + encodeURIComponent(data.searchDateTo || '') });
  }
  else {
    // return console.log({route:'admin/querydownloads?reporttype=' + reportType + '&querytype=' + queryType + '&queryvalue1=' + encodeURIComponent(data.searchValue || '')});
    return await doApi({route:'admin/querydownloads?reporttype=' + reportType + '&querytype=' + queryType + '&queryvalue1=' + encodeURIComponent(data.searchValue || '')});
  }
};

export const getDocuments = async (documentType, queryType, data) => {
  return await doApi({route:'admin/querydocuments?documenttype=' + documentType + '&querytype=' + queryType + '&queryvalue=' + encodeURIComponent(data.searchValue || '')});
};

const adminGetDocumentPreSignedUrl = async (doc) => {
  const reportType = (['User', 'Dept', 'Fund'].indexOf(doc.ReportType) > -1) ? "ACR" : doc.ReportType;
  return await doApi({route: `admin/documenturl/${reportType}/${doc.ReportYear}?fn=${doc.FileName}`});
};

export const adminGetPreSignedUrlForUpload = async (doc) => {
  return await doApi({route: `admin/uploaddocumenturl?filename=${doc.fileName}&reporttype=${doc.reportType}&contenttype=${doc.contentType}`});
};

export const adminProcessReportFiles = async (docType) => {
  return await doApi({route: `admin/processreportfiles?documenttype=` + docType});
};

export const adminDownloadDocument = async (doc) => {

  const url = await adminGetDocumentPreSignedUrl(doc);

  if (!url || !url.presignedUrl)
    return {error: 'Unable to access document'}

  console.log(url.presignedUrl)

  const a = document.createElement('a');
  a.href = url.presignedUrl;
  a.download = doc.PrettyFileName;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
};

export const deleteDocument = async (doc) => {
  return await doApi({ route: `admin/deletedocument/${doc.ReportType}/${doc.ReportYear}?filename=${encodeURIComponent(doc.FileName)}` });
};

export const saveDocument = async (doc) => {
  return await doApi({ route: `admin/savedocument`, method:"POST", data: doc });
};

