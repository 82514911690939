import { useState } from 'react';

import { history } from 'services/history.js'
import { showLoading } from 'services/util'

import LinkUserWrapper from 'admin/linkuser/LinkUserWrapper'
import LookupCrmPanel from 'admin/linkuser/LookupCrmPanel'
import ConfirmModal from 'common/ConfirmModal'
import Alert from 'common/notifications/Alert'

import { linkUcla1Id } from "../ucla1adminapi";

import LookupUcla1Id from './LookupUcla1Id'

import * as colors from 'css/Colors';
import { FilledBlue } from 'css/Buttons';
import LinkImage from 'images/link-image.svg'
import iconInfo from 'images/iconColorinfo.svg'

import css from '../sass/ucla1id.module.scss'

function SetUcla1Id(props) {
  const [action, setAction] = useState('')
  const [linkUserResponse, setLinkUserResponse] = useState('')

  // from resutls of CRM search
  const [crmId, setCrmId] = useState('')
  const [primaryEmailFromCrm, setPrimaryEmailFromCrm] = useState('')
  const [previousUcla1Id, setPreviousUcla1Id] = useState(false)

  // from resutls of UCLA One ID search
  const [ucla1Id, setUcla1Id] = useState(null)

  const handleUcla1IdChange = (ucla1Id) => {
    setUcla1Id(ucla1Id)
    setLinkUserResponse(null)
  }

  const handleCrmChange = (crmState) => {
    if (crmState && crmState.crmFoundConstituent) {
      const crmUser = crmState.crmLookupResponse.crmUser
      setCrmId(crmUser.constituentLookupId)
      setPreviousUcla1Id(crmUser.uclaOneUserId)
      setPrimaryEmailFromCrm((crmUser.emails.filter(item => item.isPrimary)[0] || {}).emailAddress)
    }
    else {
      setCrmId(null)
      setPreviousUcla1Id(null)
      setPrimaryEmailFromCrm(null)
      setLinkUserResponse(null)
    }
  }

  const handleLinkUser = async e => {
    e.preventDefault()
    showLoading(true);

    const newUcla1Id = (action === "REMOVE_UCLA1ID") ? '' : ucla1Id

    const response = await linkUcla1Id(action, primaryEmailFromCrm, crmId, newUcla1Id, previousUcla1Id)

    setLinkUserResponse(response)
    setAction(null)

    if (response && response.success) {
      props.history.push("/admin/ucla1id/success");
    }

    showLoading(false);
  }

  const confirmText = {
    SET_UCLA1ID: {
      title: "Confirm Set UCLA One ID?",
      body:  (<p>Are you sure you want to <b>set</b> the UCLA One Id for CRM Account:<b>{crmId}</b> to <b>{ucla1Id}</b>?</p>)
    },
    CHANGE_UCLA1ID: {
      title: "Confirm Change UCLA One ID?",
      body:  (<p>Are you sure you want to <b>change</b> the UCLA One Id for CRM Account:<b>{crmId}</b> from <b>{previousUcla1Id}</b> to <b>{ucla1Id}</b>?</p>)
    },
    REMOVE_UCLA1ID: {
      title: "Confirm Remove UCLA One ID?",
      body:  (<p>Are you sure you want to <b>remove</b> UCLA One ID:<b>{ucla1Id}</b> from CRM Account:<b>{crmId}</b>?</p>)
    }
  }

  return (
    <>
      {action &&
        <ConfirmModal
          onConfirm={handleLinkUser}
          onCancel={() => setAction(null)}
          cancelText={"CANCEL"}
          confirmText={"OK"}>

          <div className={css["modalInner"]}>
            <div className="modal-text">
              <p className="bold">{confirmText[action].title}</p>
              {confirmText[action].body}
            </div>
          </div>
        </ConfirmModal>}

      <LinkUserWrapper>
        <h2>Set UCLA One User ID in CRM</h2>

        {linkUserResponse && !linkUserResponse.success &&
          <Alert
            className="smaller-alert"
            nonPromoPage
            icon={iconInfo}
            color={colors.messagingRed}
            width="25" height="25" top="35%" >
            <div className="mismatch-error">Could not update UCLA1 ID. Error: "{linkUserResponse.error.msg || linkUserResponse.error.key || "Unknown Error"}"</div>
          </Alert>
        }

        <div className=" grid">
          <LookupCrmPanel handleCrmChange={handleCrmChange} specialHandling={{showUcla1Id: true}}/>

          <div className=" span-2 linked-image-wrapper" >
            <img alt='' src={LinkImage} />
          </div>

          <LookupUcla1Id handleUcla1IdChange={handleUcla1IdChange}></LookupUcla1Id>
        </div>

        {(!previousUcla1Id || localStorage.isDpAdmin === "true") &&
          <div className={css["submitButtons"]}>
            <div>
              <FilledBlue name="linkUser"
                disabled={!ucla1Id || !crmId || ucla1Id === previousUcla1Id}
                onClick={() => setAction((previousUcla1Id ? 'CHANGE' : 'SET') + "_UCLA1ID")}>
                <span> {previousUcla1Id ? 'CHANGE' : 'SET'} UCLA ONE ID FOR {crmId}</span>
              </FilledBlue>
            </div>
          </div>}

        {localStorage.isDpAdmin === "true" &&
          <div className={css["submitButtons"]}>
            <div>
              <FilledBlue name="unlinkUser"
                disabled={!previousUcla1Id}
                onClick={() => setAction("REMOVE_UCLA1ID")}>
                <span>REMOVE UCLA ONE ID FOR {crmId}</span>
              </FilledBlue>
            </div>
          </div>}

        <br/>
        <br/>
        <br/>

      </LinkUserWrapper>
    </>
  )
}

export default SetUcla1Id;
