import styled from 'styled-components'

import { device } from 'common/Device'

import { formatDate, formatFund } from '../mygivingutil'
import { defaultPrintFontSize } from './PrintStyles'

const GiftReferenceStyles = styled.div`
  /* ie11 hacks */
  display:-ms-grid;
  -ms-grid-columns: ${ 100/402*100 }% 1fr;
  -ms-grid-rows: auto;
  /* end ie11 hacks */

  display:grid;
  grid-template-columns:${ 100/402*100 }% 1fr;
  grid-template-rows: auto;

  box-sizing:border-box;

  .gift-reference {
    -ms-grid-column-span:1;
    -ms-grid-column:1;

    grid-column-end: span 1;

    .gift-date {
      font-weight:600;
    }
  }

  .gift-details {
    -ms-grid-column-span:1;
    -ms-grid-column:2;

    grid-column-end: span 1;

    .gift-department {
      font-weight:600;
    }
  }

  @media screen and ${ device.mobile } {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns:1fr 1fr;

    .gift-reference {
      padding-right:${ 15/defaultPrintFontSize }em;
    }
  }
`

const ReceiptGiftReference = props => (
  <GiftReferenceStyles>
    <div className="gift-reference">
      <div className="gift-date">
        { formatDate(props.data.date) }
      </div>
      <div>{ props.data.revenueTypeFull }</div>
      <div>{ props.data.revenueLookupId }</div>
      <div>{ props.data.agency }</div>
    </div>

    <div className="gift-details">
      <div className="gift-department">{ props.data.department }</div>
      <div>{ formatFund(props.data.designation, props.data.designationLookupId.split('-')[0]) }</div>
    </div>
  </GiftReferenceStyles>
)

export default ReceiptGiftReference
