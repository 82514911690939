import styled from 'styled-components'

import Zindex from 'css/z-indexLayers.js';
import { primaryBlue, darkGray, lightGray, borderGray } from 'css/Colors'
import { device } from 'common/Device'

// preparing for eventual swap with a global (imported) constant
const defaultFontSize = 16

const Styles = styled.div`
  -ms-grid-column:5;
  -ms-grid-row:2;
  position:relative;
  z-index:${Zindex.zindexOverlaystackOrder1};

  h3 {
    margin:0 0 ${ 5/defaultFontSize }rem;
    color:#000000;
    font-weight:600;
    font-size:${ 18/defaultFontSize }rem;
    line-height:${ 25/18 };
  }
  padding: 0 ${ 40/defaultFontSize }rem ${ 19/defaultFontSize }rem 0;

  table {
    border-collapse: collapse;
    width:100%;
    text-align:left;
    color: ${ darkGray };

    font-size: ${ 14/defaultFontSize }rem;

    td, th {
      padding:${ 14/defaultFontSize }rem ${ 15/defaultFontSize }rem ${ 11/defaultFontSize }rem;
      &:last-child {
        text-align:right;
      }
    }

    thead {
      background:${ lightGray };
    }

    th {
      font-size:${ 12/defaultFontSize }rem;
      line-height:${ 33/12 };
      text-transform:uppercase;
      color:${ primaryBlue };
      font-weight: 800;
    }

    th, .nowrap {
      white-space:nowrap;
    }

    td {
      vertical-align:top;
      &:last-child {
        font-weight: 800;
      }
    }

    tr {
      border-bottom:1px solid ${ borderGray };
      &:last-child {
        border-bottom:none;
      }
    }
  }

  &:before {
    display:none;
    position:absolute;
    content:'';
    background:${ borderGray };
    height:1px;
    width:100%;
    top:${ -20/defaultFontSize }rem;
    left:0;
  }

  @media ${ device.tablet } {
    padding: ${ 15/defaultFontSize }rem ${ 15/defaultFontSize }rem ${ 22/defaultFontSize }rem;

    &:before {
      display:block;
      top:0;
    }
  }

  @media ${ device.mobile } {
    padding: 0 ${ 15 / 320 * 100 }%;

    &:before {
      top:${ -10/defaultFontSize }rem;
    }

    /* styles with respect to Dashboard for mobile */
   table {
     border-collapse:separate;
     border-spacing:0 ${20/defaultFontSize}rem;

      thead{
        display:none;
      }
      tr
      {
        display:block;
        border-bottom:none;
        border-radius: 5px;
        border: 1px solid ${borderGray};
      //  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
        //-webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
        margin:${10/defaultFontSize}rem 0;
      }
      td{
        display:block;
        border-bottom: 1px solid ${borderGray};

        &:last-child {
        text-align:left !important;
        border-bottom:none;
        }

        /* headers and their styles*/
        &:before {
          display:block;
          color:${ primaryBlue };
          font-weight:600;
        }

        &:nth-child(1):before {
          content:'DATE';
        }
        &:nth-child(2):before {
          content:'UNIT';
        }
        &:nth-child(3):before {
          content:'FUND NAME';
        }
        &:nth-child(4):before {
          content:'GIFT TYPE';
        }
        &:nth-child(5):before {
          content:'GIFT AMOUNT';
        }

      }

   }

  }
`

const RecentGifts = props => {
  return <Styles {...props}>{ props.children }</Styles>
}

export default RecentGifts
