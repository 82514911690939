export const saveCalInvite = (auction) => {
  // const newEvent = { ...event, address: event?.event_url ? event?.event_url : `${event?.address?.line_1}, ${event?.address?.line_2}, ${event?.address?.city} ${event?.address?.state}, ${event?.address?.country} ${event?.address?.postal_code} ` }

  console.log(auction.EndTime)
  // Create the .ics URL

  //hardcoded for pacific time zone
  const timeZoneOffset = 8

  let url = [
    "BEGIN:VCALENDAR",
    // "BEGIN:VTIMEZONE",
    // "BEGIN:STANDARD",
    // "END:STANDARD",
    // "END:VTIMEZONE",

    // https://stackoverflow.com/questions/35645402/how-to-specify-timezone-in-ics-file-which-will-work-efficiently-with-google-outl
    "BEGIN:VTIMEZONE",
    "TZID:America/Los_Angeles",
    "LAST-MODIFIED:20050809T050000Z",
    "BEGIN:STANDARD",
    "DTSTART:20071104T020000",
    "TZOFFSETFROM:-0800",
    "TZOFFSETTO:-0900",
    "TZNAME:PST",
    "END:STANDARD",
    "BEGIN:DAYLIGHT",
    "DTSTART:20070311T020000",
    "TZOFFSETFROM:-0900",
    "TZOFFSETTO:-0800",
    "TZNAME:PDT",
    "END:DAYLIGHT",
    "END:VTIMEZONE",

    "BEGIN:VEVENT",
    // "DTEND;TZID=America/Los_Angeles:20240116T100000",
    // "DTSTART;TZID=America/Los_Angeles:20240116T090000",
    `DTEND:${formatDate(auction.EndTime)}`,
    `DTSTART:${formatDate(auction.StartTime)}`,
    // `DTEND:${formatDate(auction.EndTime, timeZoneOffset)}`,
    // `DTSTART:${formatDate(auction.StartTime, timeZoneOffset)}`,
    // `DTEND;TZID=America/Los_Angeles:${formatDate(auction.EndTime, timeZoneOffset)}`,
    // `DTSTART;TZID=America/Los_Angeles:${formatDate(auction.StartTime, timeZoneOffset)}`,
    `LOCATION:${auction.AuctionLocation}`,
    `SUMMARY:${auction.Title}`,
    `UID:${uuidv4()}`,
    "END:VEVENT",
    "END:VCALENDAR"
  ].join("\n")

  let blob = new Blob([url], { type: 'text/calendar;charset=utf-8' })

  if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
    // Open/Save link in IE and Edge
    window.navigator.msSaveBlob(blob, `.ics`)
  } else {
    // Open/Save link in Modern Browsers
    // console.log(encodeURI("data:text/calendar;charset=utf8," + url))
    window.open(encodeURI("data:text/calendar;charset=utf8," + url))
  }

}

const formatDate = (isoAuctionDate) => {
  let eventDate = ''
  let counter = 0

  while (eventDate.length < 15) {
    if (isoAuctionDate[counter] !== ':' && isoAuctionDate[counter] !== '-' && isoAuctionDate[counter] !== '.') {
      eventDate += isoAuctionDate[counter]
    }
    counter++
  }
  return eventDate
}

// const formatDate = (auctionDate, timeZoneOffset) => {

//   const auctionDateTime = new Date(auctionDate)
//   const zonedDate = new Date(auctionDateTime.getTime() - (timeZoneOffset*60*60*1000))
//   const zonedString = new Date(zonedDate).toISOString()

//   let eventDate = ''
//   let counter = 0

//   while (eventDate.length < 15) {
//     if (zonedString[counter] !== ':' && zonedString[counter] !== '-' && zonedString[counter] !== '.') {
//       eventDate += zonedString[counter]
//     }
//     counter++
//   }
//   return eventDate
// }

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  .replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, 
          v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}
