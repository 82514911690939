import { useState, useEffect } from 'react'

import { formatMoney } from 'services/util'

import checkmark from './images/check-circle.svg'

import css from './sass/notification.module.scss'

// import { fakeItem } from '../demo/utilsDemo'

export default function BidSuccessNotification( { successItem } ) {
    
  const [showItem, setShowItem] = useState(successItem)

  //use effect to check for auction status [and force end for demo]
  useEffect(() => {

    if (successItem) setShowItem(successItem)

  }, [successItem])

  // key prop in first div triggers SASS/CSS to fully re-render every time showItem changes
  return (
    <>      
      {showItem &&
        <div key={JSON.stringify(showItem)} className={css['notification-container']}>
          <div className={css['success-container']}>
            <div className={css['successful-bid']}>
              <div className={css['successful-bid-box-line']}></div>
              <div className={css['successful-bid-top-row']}>
                <img src={checkmark} className={css['successful-bid-icon']} alt={'Bid Successful'} />
                <h4 className={css['successful-bid-header']}>Bid successful!</h4>
                <button className={css['successful-bid-close']}
                  onClick={() => setShowItem()}>&times;</button>
              </div>
              {/* <SuccessTop removeFunction={() => removeSuccess(item.ItemId, item.CurrentBid)} /> */}
              <p className={css['successful-bid-text']}>
                You've bid ${formatMoney(successItem.CurrentBid)} on {successItem.Title}
              </p>
              <div className={css['bid-timer']}></div>
            </div>
          </div>
      </div>}
      {/* <div className={css['faking-buttons']}>
            <button onClick={() => successItems.push(fakeItem)} >
              Fake success bid
            </button>
            <button onClick={() => outBidItems.push(fakeItem)} >
              Fake outbid
            </button>
      </div> */}
    </>
  )
}