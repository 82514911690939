import styled from 'styled-components';
import { device } from 'common/Device'
import { primaryBlue } from 'css/Colors'
import WhiteHexagon from 'images/hexagon-white.svg'
import BlueWhiteHexagon from 'images/hexagon-blue-white.svg'
import WithBackground from 'common/WithBackground'
import Zindex from 'css/z-indexLayers.js';
import * as colors from 'css/Colors'
import * as cs from 'css/CommonStyle';

const defaultFontSize = 16

export const Background = styled.div`
  padding-bottom:${ 20/defaultFontSize }rem;
  .is-non-crm-user-placeholder{
    background:#FFFFFF;
  }
  @media ${ device.tablet } {
    padding-bottom:0;
  }
`

export const UserInitials = styled.div`
  background:url('${ WhiteHexagon}') no-repeat;
  background-size:cover;
  height:${ 163 / defaultFontSize}rem;
  width:${ 159 / defaultFontSize}rem;
  color:${ primaryBlue};
  font-size:${ 60 / defaultFontSize}rem;
  line-height:${ 72 / 60};
  font-weight:400;
  display:flex;
  justify-content:center;
  align-items:center;
  @media ${ device.tablet} {
    height:${ 50 / defaultFontSize}rem;
    width:${ 50 / defaultFontSize}rem;
    font-weight:600;
    font-size:${ 18 / defaultFontSize}rem;
    line-height:${ 18 / 22};
  }
  @media ${ device.mobile} {
    height:${ 50 / defaultFontSize}rem;
    width:${ 50 / defaultFontSize}rem;
    font-weight:600;
    font-size:${ 18 / defaultFontSize}rem;
    line-height:${ 18 / 22};
  }
`

export const HeaderBackground = styled(WithBackground)`
  z-index:${Zindex.zindexStackOnMainContentOrder1};
  &:after {
    background: linear-gradient(rgba(0,59,92,0.8) 0%, rgba(0,59,92,0.8) 100%, transparent),${props => `url("${props.headerImage}")` || ''} no-repeat center;
    height:${203 / defaultFontSize}rem;
  }
  &:before{
    content:'';
    display:block;
    position:absolute;
    left:0;
    top:${203 / 16}rem;
    width:100%;
    height:calc(10rem/16);
    background-color: #FFD100;
  }
  .placeholder-text{
   text-align: center;
   margin:150px auto;
   h2{
     color:#000000;
    }
  }
  @media ${device.tablet}{
    padding-bottom:0;
    &:after {
      height:${148 / defaultFontSize}rem;
    }
    &:before{
      top:${148 / defaultFontSize}rem;
      height:${ 5 / defaultFontSize }rem;
    }
    .grid{
      width:100%;
      margin:0;
      >div[class*='span-'],>div[class*='t-span-']{
        padding:0;
      }
    }
  }
  @media ${device.mobile}{
    padding:${12/defaultFontSize}rem 0 0;

    ${ cs.HeaderTitle } {
      margin-bottom:${ 15/defaultFontSize }rem;
    }

    &:after {
      height:${80 / defaultFontSize}rem;
    }

    &:before{
      top:${80 / defaultFontSize}rem;
      height:${ 3 / defaultFontSize }rem;
    }
  }`

export const OuterContainer = styled.div`
  max-width:${962 / defaultFontSize}rem;
  margin:0 auto;
  position:relative;
  z-index:${Zindex.zindexOverlaystackOrder1};
  margin-top:-4.75rem;
  background: #FFFFFF;
  @media ${device.tablet}{
  max-width:100%;
  margin-top:-3.2rem;
  }
  @media ${device.mobile}{
    margin-top:${ -43/defaultFontSize }rem;
    .user-initials-img-container{
      padding:0;
    }
  }
`

export const MyInformationContent = styled.div`
  padding:5rem calc(30rem/16) 0;
  background:#FFFFFF;
  & h4{
    margin-bottom: 1rem;
  }
  & label{
    color:${colors.darkGray}
  }
  .required-note{
  float:right;
  color:${colors.messagingRed};
  font-size:${14 / defaultFontSize}rem;
  margin-top: ${10/defaultFontSize}rem;
  }
  .save-changes-alert{
    margin:10px 0;
    & >div{
      margin:0;
    }
  }
  @media ${ device.tablet } {
    padding:${ 72/defaultFontSize }rem calc(24rem/16) 0;
  }
  @media ${ device.mobile } {
    padding:${ 39/defaultFontSize }rem ${ 15 / 320 * 100 }% 0;
  }
`

export const SubmitButtons = styled.div`
  padding:calc(36rem/16) 0;
  margin: auto calc(30rem/16);
  & >button{
    margin-right:  ${15 / defaultFontSize}rem;
  }
  @media (max-width:425px){
    display:flex;
    flex-direction:column;
    & >button{
      margin: 0 0 ${15 / defaultFontSize}rem;
    }
  }
`

export const UserInitialsImg = styled(UserInitials)`
  margin:0 auto;
  background:url('${ BlueWhiteHexagon}') no-repeat center;
  z-index:${Zindex.zindexOverlaystackOrder1};
  @media ${ device.tablet} {
    height:${ 119 / defaultFontSize}rem;
    width:${ 117 / defaultFontSize}rem;
    font-weight:400;
    font-size:${ 40 / defaultFontSize}rem;
    line-height:${ 48 / 22};

  }
  @media ${ device.mobile} {
    height:${ 50 / defaultFontSize}rem;
    width:${ 50 / defaultFontSize}rem;
    font-size:${ 18 / defaultFontSize}rem;
    line-height:${ 18 / 22};
    padding:${10 / defaultFontSize}rem;
  }
`

export const UserImage = styled.div`
  position:relative;
  padding-top:${ 32 / defaultFontSize}rem ;
  z-index:20;
  @media ${device.tablet}{
    padding-top:${ 5 / defaultFontSize}rem ;
  }
  @media ${device.mobile}{
    top:${ -10/defaultFontSize }rem;
    padding-top:0;
  }
`

export const InformationListsHeader = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${colors.guideGray};
  h6 {
    width:33.33%;
    text-align:center;
    color:${colors.darkGray};
    text-decoration:none;
    cursor:pointer;
  }
  .show{
    color:${colors.primaryBlue};
     &:after{
      content:"";
      background-color:${colors.primaryYellow};
      width:100%;
      height:5px;
      right:0;
      display:block;
      top:100%;
      right:0;
      margin-top:${13 / defaultFontSize}rem;
    }
  }
  @media ${device.mobile}{
    justify-content:space-between;
    margin:0 ${ -15 / 320 * 100 }%;
    padding-left:${ 15 / 320 * 100 }%;
    padding-right:${ 15 / 320 * 100 }%;
    h6 {
      width:auto;
    }
  }
`

export const MyProfileData = styled.div`
  &&& {
    padding:0;
  }
`

