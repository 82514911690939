import { createRef, useState } from 'react'

import { Ellipsis, PaginationStyles, EntryListing, MobilePaginator, ArrowButton, ButtonWrapper  } from './HistoryPaginationStyles'

import { primaryBlue, buttonActiveBlue, borderGray } from 'css/Colors'
import { StyledButton } from 'css/Buttons'
import iconPaginationPrev from 'images/arrow-pagination-prev.svg'
import iconPaginationNext from 'images/arrow-pagination-next.svg'

function HistoryPagination (props) {
  const [currentPage, setCurrentPage] = useState(0)

  const mobileInputRef = createRef()

  if (props.setHistoryNestedGotoPage) {
    props.setHistoryNestedGotoPage((page) => {
      setCurrentPage(1*page)
      props.gotoPage(1*page)
    })
  }

  // handle user inputs
  const changePage = (page) => {
    if (page === undefined || isNaN(page))
      return

    // ignore clicks on button for current page
    if (page !== currentPage) {
      setCurrentPage(1*page)
      props.gotoPage(1*page)
    }

    mobileInputRef.current.value = 1*page + 1
  }

  const handleMobileInput = e => {
    if (e.target.value >= 1 && e.target.value <= props.pageCount) {
      changePage( 1*e.target.value - 1 )
    } else {
      e.target.value = currentPage + 1
    }
  }

  const buildPageButtons = () => {
    let startPage = 0
    let startIndex = 0
    let length = Math.min( props.pageCount, 7 )
    let pageButtons = []

    // left ellipsis
    if (currentPage > 3 && props.pageCount > 7) {
      length -= 2
      startIndex = 2
      startPage = currentPage / 2 - Math.floor( currentPage / 2 ) > 0 ? currentPage - 1 : currentPage
      // first page
      pageButtons.push(
        <StyledButton
          value={ 0 }
          bgColor={  "#FFFFFF" }
          borderColor={ borderGray }
          fontColorHover={ primaryBlue }
          key={ 0 }
          isSquare={ true }
        >1</StyledButton>,
      )

      // the ellipsis
      pageButtons.push(<Ellipsis key={ 1 }>...</Ellipsis>)
    }

    // right ellipsis
    if (props.pageCount > 7) {
      if (currentPage < props.pageCount - 5) {
        length -= 2
        pageButtons.push(<Ellipsis key={ 5 }>...</Ellipsis>)
        // last page
        pageButtons.push(
          <StyledButton
            value={ props.pageCount-1 }
            bgColor={ "#FFFFFF" }
            borderColor={ borderGray }
            fontColorHover={ primaryBlue }
            key={ props.pageCount-1 }
            isSquare={ true }
          >{ props.pageCount }</StyledButton>
        )

      } else {
        startPage = props.pageCount - 5
      }
    }

    for (let idx=0; idx < length; idx++) {
      pageButtons.splice( startIndex + idx , 0,
        <StyledButton
          value={ startPage + idx }
          bgColor={ currentPage === startPage + idx ? buttonActiveBlue :  "#FFFFFF" }
          borderColor={ currentPage === startPage + idx ? buttonActiveBlue : borderGray }
          fontColorHover={ primaryBlue }
          key={ startIndex + idx }
          isSquare={ true }
        >{startPage + idx + 1}</StyledButton>
      )
    }
    return pageButtons
  }

  const start = currentPage * props.pageSize + 1
  const end = Math.min(start + props.pageSize - 1, props.totalRows)

  return (
    <PaginationStyles>
      <EntryListing>
        Showing <em>{ start }</em> to <em>{ end }</em> of <em>{ props.totalRows || '0' }</em> entries
      </EntryListing>
      <ButtonWrapper onClick={ e => changePage( e.target.value ) }>
        <div className="pagination-buttons">
          <ArrowButton
            disabled={ !props.canPrevious }
            value={ currentPage - 1 }
            isSquare={ true }
            arrowIcon={ iconPaginationPrev }
            className="arrow-button"
          />

          { buildPageButtons() }

          <MobilePaginator>
            <input
              type="text"
              defaultValue={ currentPage + 1 }
              onClick={ e => e.stopPropagation() }
              onBlur={ handleMobileInput }
              ref={ mobileInputRef }
            />
            <span>/</span>
            <span>{ props.pageCount }</span>
          </MobilePaginator>

          <ArrowButton
            disabled={ !props.canNext }
            value={ currentPage + 1 }
            isSquare={ true }
            arrowIcon={ iconPaginationNext }
            className="arrow-button"
          />
        </div>
      </ButtonWrapper>
    </PaginationStyles>
  )
}


export default HistoryPagination
