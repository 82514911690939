import { Link } from 'react-router-dom';

import EmailPhoneContact from './shared/EmailPhoneContact'
import { NewGiftStyles } from './shared/SharedStyles'

const MakeANewGift = () => (
  <NewGiftStyles>
    <p>If you would like to donate to an area you have supported in the past, please click the “Give Again” button next
       to your previous gift and you will have the option to donate again.</p>
    <p>To make a gift to a new area, please see below or call (310) 794-2447 for assistance.</p>
    <ul>
      <li> Online
        <p><a href="https://www.giveto.ucla.edu" rel="noreferrer" target="_blank">www.giveto.ucla.edu</a></p>
      </li>

      <li> Check
        <p>All checks should be made payable to <strong>The UCLA Foundation</strong> and mailed to:</p>
        <div className="ucla-address">
          <p>The UCLA Foundation</p>
          <p>10889 Wilshire Boulevard, Suite 1000</p>
          <p>Los Angeles, CA 90024</p>
        </div>

        <p>If you are sending our check via FedEx, please use:</p>
        <div className="ucla-address">
          <p>The UCLA Foundation [ATTN: Patrick Bruno]</p>
          <p>Lockbox 7145, 14005 Live Oak Avenue</p>
          <p>Irwindale, CA 91706-1300</p>
          <p>(626) 939-2164*</p>
          <p>*Please note that the phone number listed above is for FedEx delivery purposes only. To reach UCLA, please contact
              the Office of Donor Relations at (310) 794-2447.</p>
        </div>

        <p>Please include the name of the fund you would like to support and the associated fund number on the memo line of your check.</p>
      </li>

      <li> Stocks and Securities
        <p>Gifts of appreciated securities are tax deductible at their full market value. In most cases, appreciation in the value of the security
           benefits the University and is not taxable to the donor. Please contact the securities coordinator at (310) 794-3333 for more information.
           Wiring instructions for cash gifts can be
           found <a target="_blank" rel="noreferrer" href="https://ucla.box.com/s/8rjb9vso94erhj70afwp6gmnazgr73qj">here</a>. For gifts of stocks or bonds,
           wiring instructions can be found <a target="_blank" rel="noreferrer" href="https://ucla.app.box.com/s/ggmc8sna8kdk1paeuamz479ixbvw9oad">here</a>.</p>
      </li>

      <li> Matching Gifts
        <p>The easiest way to double—or even triple—your contribution to UCLA is to request a match from your employer.
           Thousands of companies match gifts to the organizations and institutions their employees support.
           To see if your employer is one of them,
           please <a href="/faq#matching">click here</a>.</p>
      </li>

      <li> Bequests
        <p>If you would like all or a portion of your estate to benefit UCLA, please specify in your will or living trust that the
           funds be directed to The UCLA Foundation. For information on including UCLA in your estate plans,
           please call (800) 737-8252 or <a target="_blank" rel="noreferrer" href="https://legacy.ucla.edu/">click here</a>.</p>
      </li>

      <li> Real Estate
        <p>Real property, either in its entirety or in part, can be deeded to The UCLA Foundation to benefit the University.
           For residential properties, it is possible to arrange a sizable tax deduction by deeding a home to UCLA, while continuing to
           occupy the property for life.</p>
      </li>

      <li> Charitable Gift Annuity
        <p>Donors may transfer money, securities, or real estate in trust to the University and receive income for themselves or another,
           for life. Donors may receive immediate tax benefits, and ultimately UCLA receives the trust property. For further information
           about making such a planned gift or bequest, visit
           the <a target="_blank" rel="noreferrer" href="https://legacy.ucla.edu/?pageID=1014">gift planning website</a> or call (800) 737-8252</p>
      </li>

      <li> Gifts of Materials
        <p>UCLA gratefully accepts donations of materials that support the academic, teaching and research mission of the University.
           The University receives a wide variety of such gifts, including rare books and manuscripts, works of art,
           equipment and other items of value.</p>
      </li>

      <li> Qualified Retirement Plans
        <p>The UCLA Foundation as a beneficiary of your qualified retirement plan (IRA, KEOGH, 401(k) or 403(b)) may be particularly advantageous;
           doing so may result in more assets being passed on to your heirs than if you make a bequest from other funds in your estate.
           For information on how to incorporate UCLA into your estate and retirement planning, please contact gift planning at (800) 737-8252</p>
      </li>

    </ul>

    <div>
      <div>
        <p><strong>Questions About Your Gift</strong></p>
        If you see any discrepancies or have concerns about a gift listed in your giving history, <EmailPhoneContact/>.
      </div>

      <div>
        <p><strong>No Goods or Services</strong></p>
          Each individual tax receipt states whether or not goods and services were received in exchange for a gift. You can print receipts <Link to="/history">here</Link>.
          If you require a separate letter stating that no goods or services were provided in exchange for your gift, <EmailPhoneContact/>
      </div>

      <div>
        <p><strong>Payment Information and Recurring Gifts</strong></p>
          To provide updated debit or credit card information, or to make changes to a pledge or recurring gift, please <a target="_blank" rel="noreferrer" href="https://mygiving.ucla.edu/signin">log in to My Giving Gateway</a>.
          If you would rather speak with someone directly, please call (310) 794-2447.
      </div>

      <div>
        <p><strong>Updating Your Personal Information</strong></p>
          To update your biographical information, please <Link to="/profile">click here</Link>.
      </div>

      <div>
        <p><strong>Receipts</strong></p>
          To generate a gift receipt, please visit the <Link to="/history">My Giving</Link> page. You can print individual receipts or consolidated receipts by year.
          If you need assistance, <EmailPhoneContact/>
      </div>

      <div>
        <p><strong>Refunds</strong></p>
          To request a refund, <EmailPhoneContact/>
      </div>



    </div>

  </NewGiftStyles>

)
export default MakeANewGift
