import { Auth } from 'aws-amplify'
import { showLoading } from "services/util"
import { doUnconfirmed } from "./authapi"
import { validateSignup } from "./validators"
import { history } from 'services/history'
import store from 'redux/store';

export const handleSignup = async (event, setMessages) => {
  const theme = store.getState().theme
  const analytics = theme.themeAnalytics

  let signupData = event // case of unconfirmed existing user resubmitting sign up after deletion
  let errorMessage = ""
  showLoading(true, "handleSignup - start")

  if (!event.username) {
    event.preventDefault();
    const data = new FormData(event.target)
    signupData = {
      username: data.get('username').trim(),
      password: data.get('password'),
      confirmPassword: data.get('confirmPassword'),
      usernameType: data.get('usernameType'),
    }
  }
    // remove parens and dashes in phone number
  if (signupData.usernameType === 'phone') signupData.username = signupData.username.replace(/[(\-)]/g, '')

  analytics.analyticsInitStorage({
    loginMethod: signupData.usernameType,
    entryPath: 'registration'
  })

  errorMessage = validateSignup(signupData, setMessages)
  if (errorMessage.length !== 0) {
    showLoading(false, "handleSignup - validateSignup")
    return
  }

  try {
    await Auth.signUp(signupData)
    analytics.analyticsRegistrationStep('verify')
    showLoading(false, "handleSignup - success - registerVerify")
    history.push({pathname:'/registerVerify', state: {authParams: {username: signupData.username, password: signupData.password}}})
  }
  catch (err) {
    if (err.code === "UsernameExistsException") {
      const unconfirmedResult = await doUnconfirmed(signupData.username);
      if (unconfirmedResult.success && unconfirmedResult.success.usersDeleted) {
        await handleSignup(signupData, setMessages)
      }
      else {
        showLoading(false, "handleSignup - usernameexists")
        history.push({pathname:'/usernameexists', state: {authParams: {username: signupData.username}}})
      }
    }
    else {
      showLoading(false, "handleSignup - generic error");
      analytics.analyticsFailedLogin( err.message )
      setMessages.setGenericError(err.message)
    }
  }
};

