import WarningIcon from '../../../images/icon-warning.svg'

import css from '../payment/sass/payments.module.scss'

function CardWarning(props) {

  return (
    <>
      <img src={WarningIcon} alt='WarningIcon' className={css['warning-icon']} />
      <p className={css['p-zero-spacing']}>This card is expired or is expiring soon!</p>
    </>
  )
}

export default CardWarning;