const initialState = JSON.parse(localStorage.getItem('bidSubscriptionResponse') || "{}")

const bidSubscriptionResponse = (state = initialState, action) => {
  // console.log({action, state})
  if (action.type === "SET_BID_SUBSCRIPTION_RESPONSE") {
    const { bidSubscriptionResponse } = action.payload

    localStorage.setItem('bidSubscriptionResponse', JSON.stringify(bidSubscriptionResponse))
    return bidSubscriptionResponse
  }
  else {
    return state
  }

}

export default bidSubscriptionResponse;
