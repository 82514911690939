import styled from 'styled-components'
import { device } from 'common/Device'
import { primaryBlue } from 'css/Colors'
import { StyledButton, FilledBlue } from 'css/Buttons'

// custom pagination component for react-table
const defaultFontSize = 16

export const PaginationStyles = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  margin-top:${ 30/defaultFontSize }rem;
  @media ${ device.mobile } {
    flex-direction:column;
  }
`

export const EntryListing = styled.div`
  flex:0 1 50%;
  font-style:italic;
  em {
    font-weight:600;
    margin:0 ${ 2/defaultFontSize }rem;
  }
  @media ${ device.mobile } {
    text-align:center;
    margin-bottom:${ 20/defaultFontSize }rem;
  }
`

export const ButtonWrapper = styled.div`
  flex:1 0 auto;
  text-align:right;
  button, span {
    margin:0 ${2.5/defaultFontSize}rem;
  }
  .pagination-buttons {
    display:inline;
  }
  @media ${ device.mobile } {
    text-align:center;
    width:100%;
    .pagination-buttons {
      display:flex;
      justify-content:space-between;

      ${ StyledButton } {
        display:none;
      }
      .arrow-button {
        display:block;
      }
    }
  }
`
export const MobilePaginator = styled.fieldset`
  display:none;
  border:0;
  outline:0;
  flex:1 1 100%;
  input {
    display:inline-block;
    height:${ 40/defaultFontSize }rem;
    border: 1px solid ${ primaryBlue };
    border-radius: 3px;
    padding:0 ${ 12/defaultFontSize }rem;
    width:${ 60/320 * 100 }%;
    min-width:${ 14/defaultFontSize }rem;
    margin-right:${ 10/defaultFontSize }rem;
    text-align:center;
  }
  span {
    padding:0 ${ 10/defaultFontSize }rem;
  }
  @media ${ device.mobile } {
    display:block;
  }
`

// don't feel like adding this icon behavior into the original FilledBlue
export const ArrowButton = styled ( FilledBlue )`
  font-size:${ 25/defaultFontSize }rem;
  text-align:center;
  background-image:url('${ props => props.arrowIcon }');
  background-repeat:no-repeat;
  background-size:21%;
  background-position:center;
  @media ${ device.mobile } {
    margin-left:${ 7.5/defaultFontSize }rem;
    margin-right:${ 7.5/defaultFontSize }rem;
  }
`

export const Ellipsis = styled.span`
  display:inline-flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  box-sizing:border-box;
  width:${ 50/defaultFontSize }rem;
  height:${ 50/defaultFontSize }rem;
  padding:0.2rem;
`
