import { Component } from 'react';

import * as cs from 'css/CommonStyle';
import { FilledYellow } from 'css/Buttons'
import DropDownMenu from 'common/forms/DropDown'

import { InputWrap, ButtonWrap, OperatorWrapper } from 'admin/QueryStyles'

const searchTypes = [
  {displayValue: "All Open Requests", key: "All Open"},
  {displayValue: "New Requests", key: "New"},
  {displayValue: "In Progress Requests", key: "In Progress"},
  {displayValue: "Approved Requests", key: "Approved"},
  {displayValue: "Denied Requests", key: "Denied"},
  {displayValue: "All Closed Requests", key: "All Closed"},
  {displayValue: "Deleted Requests", key: "Deleted"},
]

let startYear = (new Date()).getFullYear()
const ReportYearValues = [];
while (startYear >= 2020) {
  ReportYearValues.push(startYear);
  startYear--;
}

class SharedQueryForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchDisplay: 'All Open Requests',
      searchType: 'All Open',
    }
  }

  handleDropdownChange = async e => {
    await this.setState({
      searchType: e.target.value.key,
      searchDisplay: e.target.value.displayValue,
    });

    console.log(e.target.value)
    this.props.onChange(e.target.value.key);
  }

  render() {
    return (
      <div>
        <form>
          <cs.TextInputdiv className="user-search-form" >
            <InputWrap>
              <label className="link-donor-info-block-title">{"Filter..."}</label>
              <DropDownMenu
                className="input-wrap-dropdown"
                name="pendingList"
                title={this.state.searchDisplay}
                list={searchTypes}
                changeHandler={this.handleDropdownChange}
                maxHeight="300"
              />
            </InputWrap>

         </cs.TextInputdiv>
        </form>
      </div>
    )
  }
}

export default SharedQueryForm;