import { graphql } from './api'
import { updateCurrentBidMutation } from './graphql/Mutations'

export const updateLiveBid = async (bidFields) => {
  console.log('updateLiveBid!')
  try {
    const response = await graphql({query: updateCurrentBidMutation, variables: bidFields })
    if (!response?.data?.updateCurrentBid?.BidCount) {
      console.log("Bid failed: ", response)
      return {error: {response}}
    }
    else {
      return response.data.updateCurrentBid
    }
  }
  catch (e) {
    console.log("error in updateLiveBid:", e)
    if (e.toString().startsWith("Error: No current user")) {
       // In practice we will redirect the user to the login page here
       return {error: {message: "You need to be logged in to use this service."}}
    }
    else if (e.errors[0]?.message?.startsWith("The conditional request failed")) {
      // reloading all data to see what actual current price is
      // in practice we will know if the user was outbid or lost to an equal bid getting in ahead of them, but for now this is ok
      return {error: {message: "Oops you've been outbid or another bid snuck in just ahead of you. Would you like to bid again?"}}
    }
    else {
      return {error: {e}}
    }
  }
  // finally {
  //   // always refresh list on any attempted bid
  //   setAuctionDetail(await getLiveAuctionData(auctionId))
  // }
}