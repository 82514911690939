import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { primaryBlue, primaryYellow } from 'css/Colors'
import * as colors from 'css/Colors';
import { device } from 'common/Device'

const defaultFontSize = 16

export const StyledMenu = styled.ul`
  display:flex;
  flex-direction:row;
  align-items:center;

  padding:0;
  list-style-type: none;
  margin:0;

  /* the divider */
  &:after {
    content:'';
    display:block;
    height:42px;
    width:1px;
    margin:0;
    background: #BFBFBF;
  }
  li {
    position:relative;
    margin:0px ${ 20/defaultFontSize }rem 0 ${ 15/defaultFontSize }rem;
    padding:0;
    height:${ 60/defaultFontSize }rem;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    & :hover{
      color:${primaryBlue};
    }
  }
  li i{
    font-size: 10px;
    color: ${colors.darkGray};
    margin-left:5px;
  }
  li a,li span {
    color: ${colors.darkGray};
    font-size: ${14/defaultFontSize}rem;
    text-decoration:none;
    cursor:pointer;
  }
  .activeLinks{
    font-weight: bold;
    color: :${primaryBlue};
    border-bottom: 2px solid ${primaryYellow};
  }
  @media ${device.tablet}{
    display:block;
    width:100%;
    &:after {
      position:absolute;
      bottom:0;
      left:0;
      width:100%;
      content:'';
      display:block;
      height:1px;
      background: #BFBFBF;
    }
    li{
      // width:100%;
      align-items:flex-start;
      margin:0;
      height:${ 50/defaultFontSize }rem;
      &.m-no-border {
        &:after {
          display:none;
        }
      }
      &.m-border{
        &:after {
          position:absolute;
          bottom:0;
          left:0;
          width:100%;
          content:'';
          display:block;
          height:1px;
          background: #BFBFBF;
        }
      }
    }
    .activeLinks{
      border-bottom: 0px;
    }
  }
`

export const StyledNavLink = styled(NavLink)`
 font-size:${14/defaultFontSize}rem;
  &.active {
    font-weight: 600;
    color: ${ primaryBlue};
    &:after {
      display:block;
      position:absolute;
      bottom:0;
      content:'';
      width:100%;
      height:5px;
      background:${ primaryYellow};
    }
  }
  @media ${device.tablet}{
    &.active:after {
      display:none;
    }

    &.active:before {
      content:'';
      display:block;
      position:absolute;
      top: 0;
      left:0;
      height:100%;
      width:calc(5rem/16);
      background:${ colors.primaryYellow};
    }
  }
`

