import { Component } from 'react';
import { withRouter, Link } from 'react-router-dom'
import styled from 'styled-components';

import * as buttons from 'css/Buttons'
import * as colors from 'css/Colors'
import { device } from 'common/Device';

const defaultFontSize=16;

const RegistrationPwd = styled.div`
text-align: center;
font-family: Arial;
font-size: 16px;
margin: 5% auto 50px;

.swap-registrationSignIn-account{
  font-size: 14px;

  .swap-SignIn-link{
  text-decoration: underline;
  color:#005587;
  }
}

h3{
  color: #2774AE;
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1px;
}
.back-To-Registration{

	position:relative;
	display:inline-block;
  font-size: 16px;
  color:#2774ae;
  font-style: italic;
	font-weight:bold;
	margin-top:14px;

	&:before{
		content:'';
		position:absolute;
		left: ${-15/defaultFontSize}rem;
		top: 0;
		margin-top:${ 4/defaultFontSize }rem;
		border: solid ${colors.primaryBlue};
		border-width: 0 ${4/defaultFontSize}rem ${4/defaultFontSize}rem 0;
    padding: ${4/defaultFontSize}rem;
		transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
	}
}`

const RegistrationPwdInner = styled.div`

display:block;
margin:0 auto;
min-width: 460px;
width:669px;
background-color: #f6f6f6;

.backToregistrationIcon{
  padding-left:5px;
}

@media ${device.mobile}{
min-width:100%;
width:200px;
}`;

const UserExistsInformation = styled.div`
/* display:inline-block; */
padding: 10% 0;

.user-Exists-Information-Content{
  color: #005587;
 }
 .footer-buttons{
   & >div{
     margin:calc(20rem/16) auto;
   }

   div:nth-child(2){
      width: ${263/defaultFontSize}rem;
   }
 }

.back-to-signin{
  display:block;
  width:100%;
 }
@media ${device.tablet}{
 .footer-buttons{
  div:nth-child(2){
    width: ${220/defaultFontSize}rem;
 }
}
}

@media ${ device.mobile } {
  padding-left:${ 15/320 * 100 }%;
  padding-right:${ 15/320 * 100 }%;
}

`


class UsernameExists extends Component {

	render() {
		return (
			<RegistrationPwd >
				<h3 id="pageTitle">Account already exists </h3>
				<RegistrationPwdInner>
					<UserExistsInformation>

						<p>It looks like you have already created an account with this email/phone number.</p>
						<p>Did you forget your password?</p>
            <div className="footer-buttons">
              <div>
                <buttons.FilledBlue onClick={() => this.props.history.push('/resetpasswordstart')}>RESET YOUR PASSWORD</buttons.FilledBlue>
              </div>

              <div>
                <buttons.StrokeBlue
                  className="back-to-signin"
                  onClick={() => this.props.history.push('/signin')}>
                  SIGN IN
                </buttons.StrokeBlue>
              </div>
            </div>

					</UserExistsInformation>
				</RegistrationPwdInner>

				<div>
					<Link className="back-To-Registration" to='/registration'>Back To Registration</Link>
				</div>

			</RegistrationPwd>
		)
	}
}

export default withRouter(UsernameExists)
