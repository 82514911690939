import {createGlobalStyle} from 'styled-components'
import {device} from 'common/Device'

const wrapperWidth = 1368;
const tabletWrapperWidth = 720;
const mobileWrapperWidth = 320;

export const Grid = createGlobalStyle`
  /* default .grid has grid gap */
  .grid {
    /* begin IE 11 hacks */
    display:-ms-grid;
    -ms-grid-columns: (1fr)[12];
    -ms-grid-rows: auto;
    /* end IE 11 hacks */

    display:grid;
    box-sizing:border-box;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;

    width:${ (wrapperWidth + 30) / wrapperWidth * 100 }%;
    margin-left:${ -15 / wrapperWidth * 100 }%;

    /* spans */
    ${ props => {
      let spans = ''
      for (let i=1; i<=12; i++) {
        /* ie first */
        for ( let j=0; j<(Math.floor(12/i)); j++ ) {
          spans += `
          .span-${ i }:nth-child(${ j+1 }) {
            -ms-grid-column:${ i*j+1 };
          }
          `
        }

        /* ie specific classes to help make layout easier */
        spans += `
        .-ms-force-row-${ i } {
          -ms-grid-row:${ i };
        }
        .-ms-force-column-${ i } {
          -ms-grid-column:${ i };
        }
        `

        spans += `
        .span-${ i } {
          -ms-grid-column-span:${ i };
          grid-column-end: span ${ i };
          box-sizing:border-box;
          padding-left:${ 15/(wrapperWidth+30) * 12/i * 100 }%;
          padding-right:${ 15/(wrapperWidth+30) * 12/i * 100 }%;
        }


        `

      }
      return spans
    }}

    /* rowspans */
    ${ props => {
      let rowspans = ''
      for (let i=1; i<5; i++) {
        rowspans += `
        .rowspan-${i} {
          -ms-grid-row-span: ${i};
          grid-row-end: span ${i};
        }
        `
      }
      return rowspans
    }}
  }

  /* no gap variants */
  .grid, .t-grid, .m-grid {
    &.no-gap {
      width:100%;
      margin-left:0;

      ${ props => {
        let nogaps = ''
        for (let i=1; i<=12; i++) {
          nogaps += `
          .span-${ i }, .t-span-${ i }, .m-span-${ i } {
            padding-left:0;
            padding-right:0;
          }
          `
        }
        return nogaps
      }}
    }
  }

  /* remove padding from nested spans and grids */
  [class*=span] [class*=span] {
    padding-left:0;
    padding-right:0;
  }

  [class*=grid] [class*=grid] {
    padding-left:0;
    padding-right:0;
    width:100%;
    margin-left:0;
  }

  .wrapper {
    box-sizing:border-box;
    max-width:${ wrapperWidth + 72 }px;
    width:100%;

    padding-left:calc(36rem/16);
    padding-right:calc(36rem/16);

    &.no-padding {
      padding-left:0;
      padding-right:0;
    }

    margin-left:auto;
    margin-right:auto;
  }

  @media ${ device.tablet } {
    .wrapper {
      padding-left: calc(24rem/16);
      padding-right: calc(24rem/16);

      &.t-no-padding {
        padding-left:0;
        padding-right:0;
      }
    }

    .t-grid {
      display:grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: auto;

      .t-span-1, .t-grid .t-span-1 {
        grid-column-end: span 1 !important;
      }
    }

    /* redefine spans */
    .grid, .t-grid {
      width:${ (tabletWrapperWidth + 20) / tabletWrapperWidth * 100 }%;
      margin-left:${ -10 / tabletWrapperWidth * 100 }%;

      ${ props => {
        let spans = ''
        for (let i=1; i<=12; i++) {
          /* ie first */
          for ( let j=0; j<(Math.ceil(12/i)); j++ ) {
            spans += `
            .t-span-${ i }:nth-child(${ j+1 }) {
              -ms-grid-column:${ i*j+1 } !important;
            }
            `
          }

          /* ie specific classes to help make layout easier */
          spans += `
          .-t-ms-force-row-${ i } {
            -ms-grid-row:${ i } !important;
          }
          .-t-ms-force-column-${ i } {
            -ms-grid-column:${ i } !important;
          }
          `

          spans += `
          .t-span-${ i } {
            -ms-grid-column-span:${ i } !important;
            grid-column-end: span ${ i } !important;
          }
          `

          spans += `
          .span-${ i }, .t-span-${ i } {
            padding-left:${ 10/(tabletWrapperWidth+20) * 12/i * 100 }%;
            padding-right:${ 10/(tabletWrapperWidth+20) * 12/i * 100 }%;
          }
          `

        }
        return spans
      }}

      /* rowspans */
      ${ props => {
        let rowspans = ''
        for (let i=1; i<5; i++) {
          rowspans += `
          .t-rowspan-${i} {
            grid-row-end: span ${i} !important;
          }
          `
        }
        return rowspans
      }}
    }
  }

  /* mobile breakpoint */
  @media ${ device.mobile } {
    .grid, .t-grid, .m-grid {
      grid-template-columns: 1fr 1fr;

      width:100%;
      margin-left:0;
    }

    .wrapper {
      padding-left:${ 15 / mobileWrapperWidth * 100 }%;
      padding-right:${ 15 / mobileWrapperWidth * 100 }%;

      &.m-no-padding {
        padding-left:0;
        padding-right:0;
      }
    }

    /* reset desktop / tablet span classes */
    .grid {
      ${ props => {
        let resets = ''
        for (let i=1; i<=12; i++) {
          resets += `
          .span-${ i }, .t-span-${ i } {
            grid-column-end: span 2 !important;
          }
          `
        }
        return resets
      }}
    }

    /* redefine spans */
    .grid, .t-grid, .m-grid {
      .m-span-1 {
        grid-column-end: span 1 !important;
      }

      .m-span-2 {
        grid-column-end: span 2 !important;
      }

      /* spans */
      ${ props => {
        let spans = ''
        for (let i=1; i<=12; i++) {
          spans += `
          .span-${ i }, .t-span-${ i }, .m-span-${ i } {
            padding-left:0;
            padding-right:0;
          }
          `
        }
        return spans
      }}

      /* rowspans */
      ${ props => {
        let rowspans = ''
        for (let i=1; i<5; i++) {
          rowspans += `
          .m-rowspan-${i} {
            grid-row-end: span ${i} !important;
          }
          `
        }
        return rowspans
      }}
    }
  }


`
