import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { getLoggedInUser } from 'redux/selectors';
import { ThemeContext } from 'ThemeContext';

import Alert from 'common/notifications/Alert'
import css from './sass/help.module.scss'

function Help() {
  const theme = useContext(ThemeContext)
  const loggedInUser = useSelector(getLoggedInUser);

  return (
    <section>

      {loggedInUser &&
        <div className={css['alert-wrapper']}>
          <Alert
            nonPromoPage={true}
            backgroundColor={'#F2F2F2'}
          >
            <header className={css['user-not-found']} id="crmUserNotFound">
              <em>We were unable to connect your email address to an account currently in our system.</em>
              <p>Please use the email you received your invitation to ASC. If that is not available please contact ASC support at <a href="mailto:alumni@alumni.ucla.edu">alumni@alumni.ucla.edu</a>.</p>
              <i className={css['icon-info']} />
            </header>
          </Alert>

        {theme.nonCrmUserExtra}
        </div>
      }
    </section>
  )
}

export default Help
