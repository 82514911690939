import { REDIRECT_AFTER_LOGIN } from "../actionTypes";

const initialState = {
  redirectTo: sessionStorage.getItem('signinDestination')
};

const redirectAfterLogin = (state = initialState, action) => {
  if(action.type === REDIRECT_AFTER_LOGIN) {
    console.log(action);
    const { redirectTo } = action.payload;
    console.log(redirectTo);
    return {
      redirectTo
    };
  }
  return state;
}


export default redirectAfterLogin;
