import adminCss from '../sass/adminpage.module.scss'

export default function PreBidding(props) {
  
  return (
    <div>
      <h1 className={adminCss['admin-auction-page-tab-header']}>
        Bidding View
      </h1>
      <p className={adminCss['admin-auction-page-tab-bolded']}>
        Once the auction is live, a list of live bids will appear here. This view will update in real time. After the auction has concluded, bidding results for the auction will be shown here.
      </p>
    </div>    
  )
}