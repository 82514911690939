import { createRef, useState, useEffect } from 'react'

import IdmReactTable from "common/IdmReactTable"

import { getGivingSiteLink } from './historyapi'
import { formatAmount, formatDate, formatFund } from '../mygivingutil'
import HistorySubtable from './HistorySubtable'
import GiftActions from './GiftActions'
import GiftType from './GiftType'
import TableStyles from './TableNestedStyles'
import { DropdownWrapper, SortWrapper } from './HistoryStyles'
import SimpleDropdown from './SimpleDropdown'
import { SCREENS } from 'common/DeviceDetect'

let gotoPage = () => {}

// this displays the gift history table / listing
function HistoryNested (props) {
  const [sorted, setSorted] = useState([{id: 'date', desc: true}])
  const [expandRowAfterDataLoad, setExpandRowAfterDataLoad] = useState()

  //  reset to first page on any filter change
  useEffect(() => {
    gotoPage(0)
  }, [props.data.length])

  const tableRef = createRef()

  const tableProps = {
    doPagination: true,
    initialState: {
      minRows: 0,
      pageIndex: 0,
      pageSize: window.innerWidth <= SCREENS.tablet ? 10 : 25,
      sortBy: [{id: 'date', desc: true}]
    },
  }

  const mainTableColumns = [
    { Header: 'Date', desc: true, accessor: 'date', Cell: cell => formatDate( cell.value )},
    { Header: 'Legal/Donor Name', desc: false, accessor: 'primaryDonorName' },
    { Header: 'Unit', desc: false, accessor: 'department' },
    { Header: 'Gift Fund', desc: false, accessor: 'designation', Cell: cell => formatFund(cell.value, cell.row.original.designationLookupId.split('-')[0]) },
    { Header: 'Gift Type', desc: false, accessor: 'revenueTypeFull', Cell: cell => <GiftType original={ cell.row.original } /> },
    { Header: 'Gift Amount', desc: true, accessor: 'displayAmount', Cell: cell => formatAmount(cell.value) },
    { Header: 'Receipt', desc: true, accessor: 'isReceiptable', Cell: cell => (
      <GiftActions
        isReceiptable={ cell.value }
        original={ cell.row.original }
        isExpanded={ cell.row.isExpanded }
        index={ cell.row.index }
        printReceipt={ props.printReceipt }
        doExpand={ () => showSubtable(cell.row) }
        linkText={ cell.row.original.revenueType === "PP" ? "View Pledge" : "Give Again" }
        linkUrl={ cell.row.original.revenueType === "PP"
                  ? "/pledges/" + cell.row.original.revenueLookupId
                  : getGivingSiteLink(cell.row.original.displayAmount, cell.row.original.fundNumber) }
      />
    )},
  ];

  const updateSort = ( id ) => {
    // toggle when selecting the same sort item, or use default set in column settings above
    const column = mainTableColumns.find( item => item.accessor === id )
    const sortOrder = sorted[0].id === id? !sorted[0].desc : column.desc

    setSorted([{id: id, desc: sortOrder}])
  }

  // render subcomponent
  async function showSubtable(row) {
    if (!row.original.subsection && !row.original.loading) {
      await props.getRecurringData(row.original)
    }

    console.log('data row loaded', row)

    // row.getToggleRowExpandedProps().onClick()

    // hack to get row to open initially because row.getToggleRowExpandedProps().onClick() stopped working with some react upgrade
    setExpandRowAfterDataLoad(row)
  }

  // HistoryPagination sets method to call to change page
  const setHistoryNestedGotoPage = ( gotoPageFromHistoryPagination ) => {
    gotoPage = gotoPageFromHistoryPagination
  }

  return (
    <TableStyles ref={ tableRef } className="wrapper">
      <DropdownWrapper>
        <div></div>
        <SortWrapper>
          <SimpleDropdown
            label={"Sort By"}
            onUpdate={ updateSort }
            sorted={ sorted[0] }
            columns={ mainTableColumns.map( (column, idx) => ({
              id: column.accessor,
              header: column.Header
            }))}
          />
        </SortWrapper>
      </DropdownWrapper>

      <IdmReactTable
        {...tableProps}
        data={ props.data }
        columns={ mainTableColumns }
        sorted={ sorted }
        setHistoryNestedGotoPage={ setHistoryNestedGotoPage } // needs gotoPage function from HistoryPagination
        expandRowAfterDataLoad={expandRowAfterDataLoad}
        SubComponent={({ row }) => {
          if (row.isExpanded) {
             return (
               <HistorySubtable
                parentRow={row.original}
                data={row.original.subsection}
                printReceipt={props.printReceipt}
              />
            )
          }
        }}
      />
    </TableStyles>
  )
}

export default HistoryNested;
