import { useState, useEffect } from 'react';
import styled from 'styled-components'

import { showLoading } from 'services/util'
import LinkUserWrapper from 'admin/linkuser/LinkUserWrapper'
import Alert from 'common/notifications/Alert'
import * as colors from 'css/Colors';
import iconInfo from 'images/iconColorinfo.svg'

import PendingListForm from './PendingListForm'
import PendingListResults from './PendingListResults'

import { getPendingList } from "./ucla1adminapi"

const FormWrapper = styled.div`
  color:#fff;
  padding:20px;
  border-radius:5px;
  background-color:${ props => props.crm ? `${colors.secondaryMediumBlue}` : `${colors.primaryBlue}`};

  .user-search-form {
    margin: 0;
  }
`
function PendingList(props) {
  const [tableData, setTableData] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)

  useEffect(() => {
    setErrorMsg()
    handleFilter('All Open')
  }, [])

  const handleFilter = async status => {
    // this.setState({errorMsg: ''})
    showLoading(true)
    const response = await getPendingList(status);

    if (!response || !response.entries) {
      await setErrorMsg(response && response.error ? response.error.msg || 'An Error Occurred' : 'An Error Occurred')
      await setTableData(null)
      showLoading(false)
      return;
    }

    // setTableData(tempData)
    setTableData(response.entries)

    // console.log(response.entries)

    // const tableData = response.entries.map(entry => {
    //   const newEntry = {...entry};
    //   for (const prop in newEntry) {
    //     if (prop.indexOf("LastLogin") > 0) {
    //       if (!newEntry.AllApps) newEntry.AllApps = prop.replace('LastLogin', '')
    //       else newEntry.AllApps += ', ' + prop.replace('LastLogin', '')
    //     }
    //   }
    //   return newEntry;
    // })

    // await this.setState({tableData});

    showLoading(false)
  }

  return (
    <>
      <LinkUserWrapper>
        <span style={{"whiteSpace": 'nowrap'}}>
          {/*<h4>UCLA1 Pending List</h4>*/}

          {errorMsg &&
            <Alert icon={iconInfo} >
              <div>{errorMsg}</div>
            </Alert>
          }

        <br/>

          <div className=" grid">
            <div className="span-12">
              <FormWrapper>
                <PendingListForm
                  onChange={handleFilter}
                >
                </PendingListForm>

              </FormWrapper>
            </div>
          </div>
        </span>

        <br/>

        {tableData &&
          <PendingListResults data={tableData} handleFilter={handleFilter} />}

      </LinkUserWrapper>
    </>
  )
}

export default PendingList;
