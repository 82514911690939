import store from 'redux/store';
import { getCrmUser } from 'redux/selectors';

import { formatDate } from '../mygivingutil'
import { formatMoney } from 'services/util';
import { logPrintOfRecognitionSummary } from './historyapi'
import './PrintOverrides.css' // this makes it global FYI
import { FilledBlue, FilledYellow } from 'css/Buttons'
import { PrintPreviewBanner, RecognitionSummaryPrintPreviewFonts, PrintContainer,
         PrintBannerImage, PrintRecognitionSummaryPage, RecognitionSummaryReport,
         ReportHeaderLeft, ReportHeaderRight, PrintBanner } from './PrintRecognitionSummaryStyles'
import MygivingGatewayLogo from '../images/mygivinggatewaylogo.svg';

const headers = ['DONOR NAME','RECEIPT\nDATE','GIFT\nTYPE','DESIGNATION','AREA','ANON-  \nYMOS','TAX\n DEDUCTIBLE','BENEFIT','AMOUNT','RECOGNITION\n AMOUNT',]

function PrintRecognitionSummary(props) {

  const crmUser = getCrmUser(store.getState())

  const { gifts, year } = props
  const displayYear = year === 'Lifetime Recognition' ? 'Lifetime' : year;
  const title = displayYear + " Recognition Summary";

  const introText = "For your convenience, the following summary outlines your gifts and pledge payments for "
                    + (year ? `the ${year} calendar year` : "your lifetime giving history")
                    + ". This serves as a courtesy and should not be used to replace official tax receipts."

  const totalAmount = formatMoney(gifts.reduce((a, b) => a + b.trueRecognitionAmount, 0))

  const today = new Date().toLocaleString().split(",")[0]

  logPrintOfRecognitionSummary(displayYear)

  const printReport = (e) => {
    window.print()
  }

  const pn = crmUser.profile.primaryName;
  // ususally we'll get this from a receipt, but just in case the logged in user isn't the primary donor on any gifts, we approximate (mostly so it will match the spouse name format)
  const constructedPrimaryDonorName = pn.title + " " + pn.firstName + " " + (pn.middleName ? pn.middleName.substring(0,1) + ". " : "") + pn.keyName

  let primaryDonorName, spouseDonorName;
  const tableBody = gifts.map((row, idx) => {
    // Always show Donor Name, get donor name if from revenue primaryDonorName if possible.
    // If not use Title First Name Middle Initial Last Name
    // If spouse gave, show spouse name (need spouse crmId from getProfile call)
    // Never add foundations or anything else
    if (!primaryDonorName && row.constituentLookupId === crmUser.profile.constituentLookupId) {
      primaryDonorName  = row.primaryDonorName
    }
    else if (!spouseDonorName && row.constituentLookupId === crmUser.profile.spouseLookupId) {
      spouseDonorName  = row.primaryDonorName
    }

    return (<tr key={idx}>
              <td>{row.primaryDonorName}</td>
              <td>{formatDate(row.date)}</td>
              <td>{row.revenueType}</td>
              <td>{row.designation}</td>
              <td>{row.school}</td>
              <td>{row.isAnonymous === true ? 'Yes' : 'No'}</td>
              <td className="money">${formatMoney(row.taxDeductibleAmount)}</td>
              <td className="money">${formatMoney(row.benefitAmount)}</td>
              <td className="money">${formatMoney(row.displayAmount)}</td>
              <td className="money">${formatMoney(row.trueRecognitionAmount)}</td>
            </tr>)
  })

  return (
    <RecognitionSummaryPrintPreviewFonts>
      <PrintPreviewBanner className="hide-on-print">
        <div></div>
        {/*<img className="header-image" src={ MygivingGatewayLogo } alt="Give To UCLA" />*/}
        <div>
          <FilledYellow onClick={ e => props.onHideOverlay(e) }>Cancel</FilledYellow>
          <FilledBlue onClick={ printReport }>Print</FilledBlue>
        </div>
      </PrintPreviewBanner>

      <PrintContainer>

        <PrintRecognitionSummaryPage>
          <PrintBanner>
            <ReportHeaderLeft>
              <span>
                <PrintBannerImage src={ MygivingGatewayLogo } alt="Give To UCLA"/>
                <header><strong>{title}</strong></header>
              </span>
            </ReportHeaderLeft>
            <ReportHeaderRight>
              <span>
                <p>{primaryDonorName || constructedPrimaryDonorName}</p>
                {spouseDonorName && <p>{spouseDonorName}</p>}
              </span>
              <header>Total {displayYear} Recognition: <strong>${totalAmount}</strong></header>
            </ReportHeaderRight>
          </PrintBanner>

          <p>{introText}</p>

          <RecognitionSummaryReport>
            <thead>
              <tr>
              {headers.map((header, idx) => (
                <th key={header}><b>{header}</b></th>
               ))}
              </tr>
            </thead>
            <tbody>
              {tableBody}
            </tbody>
          </RecognitionSummaryReport>

          <footer>
            <span>Printed on: {today}</span>
            <div>Total {displayYear} Recognition: <strong>${totalAmount}</strong></div>
          </footer>

        </PrintRecognitionSummaryPage>
      </PrintContainer>
    </RecognitionSummaryPrintPreviewFonts>
  )
}

export default PrintRecognitionSummary


