import { history } from 'services/history.js'

// import checkmark from '../images/check-circle.svg'

import { useSelector } from "react-redux"
import { getAuctionUser } from '../redux/selectors'

import css from './sass/payment.module.scss'
import themeCss from '../components/sass/theme.module.scss'

import QrCard from '../register/flow/QrCard'

export default function PaymentSuccess() {

  const auctionCode = localStorage.getItem('savedAuctionCode')
console.log(auctionCode)

  // get the auctionUser from local storage
  //find the auction based off the auctionCode that was saved
  const auctionUser = useSelector(getAuctionUser);
  console.log(auctionUser)


  //maybe just query?
  const userAuctions = auctionUser.MyAuctions
  const findCurrentAuction = (auction) => auction.Auction.AuctionCode === auctionCode
  const currentIndex = userAuctions.findIndex(findCurrentAuction)
  const currentAuction = userAuctions[currentIndex].Auction

  return (
    <div className={themeCss['blue-background']}>
     <div className={css['payment-container']}>
      {/* <h2 className={css['payment-header-white']}>Successfully added payment!</h2> */}
      
      {/* <img src={checkmark} alt={'Payment Successful'} className={css['payment-confirm']} /> */}
      
      {currentAuction &&
      <QrCard auction={currentAuction} auctionState={'before'} checkedIn={'false'} />
    }

      <button 
        onClick ={() => {history.push({pathname: '/auctionhome',
        search: `?auctionId=${auctionCode}`, }) }}
        className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['auction-button']}`}>
          Go To Auction </button>
    </div>
    </div>
  )
}