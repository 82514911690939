export const formatStringDate = (dateString) => {
  const date = new Date(dateString);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

export const cardValidCheck = (dateString) => {
    //per Donor relations, warning should show up at the beginning of the month before the expiration date
    // ex: card expires 07/23, warning should show up starting 06/01/2023

    const today = new Date();
    
    const numExpMonth = parseInt(dateString.slice(4, 6), 10);
    const numExpYear = parseInt(dateString.slice(2, 4), 10);
    
    const currentYear = today.getFullYear() - 2000;
    const currentMonth = today.getMonth() + 1;
    
    //if the expiring year is less than current year, card is invalid
    if (numExpYear < currentYear) {
        return false;
    }
    
    //if expiration year is equal to current year, need to check current month
    if (numExpYear === currentYear) {   
        // if the exp month is one less than the current month, warning should show up
        if ((numExpMonth - 1) <= currentMonth) {
            return false;
        }
    }
    //special case for january for different year values
    //ex: current month 12/2022, exp date 01/23 - need to show as expiring soon
    if (numExpMonth === 1 &&
        currentMonth === 12 &&
        ((numExpYear - 1) === currentYear)
        ) {
        return false;
    }
    return true;
}

//return true if any are near expiring
export const anyPaymentMethodsExpiring = (userData) => {

    if (userData) {
      for (let i = 0; i < userData.length; i++) {
        if (!cardValidCheck(userData[i].ccExpiresOn)) {
          return true;
        } 
      }
    }
    //if all are checked and none fail the check, return false
    return false;
}