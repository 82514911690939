
export const fullName = "CEIR Fund Reports"

export const helperText = (
  <>
    <p><b>All files should be named in the following format:</b>
    <br/>[fund number].CEIR.[year of report].[file extenstion]</p>

    <div>
      <b>For example:</b>
      <ol>
        <li><b>PDF:</b> 31588C - GZ.CEIR.2021.pdf</li>
        <li><b>Movie:</b> 50029 - BE.CEIR.2021.mp4</li>
      </ol>
      <br/>
    </div>
  </>
)

export const validateFile = (file, errors) => {
  const [fundNumber, reportType, reportYear, fileExt, badData] = file.name.split('.');

  if (badData) {
    errors.push({file, msg: "Filename has too many sections"})
  }
  else if (!fileExt) {
    errors.push({file, msg: "Filename has too few sections"})
  }
  else if (reportType.toUpperCase() !== 'CEIR') {
    errors.push({file, msg: "Only CEIR reports are supported at this time"})
  }
  else if (!/^\d{4}$/.test(reportYear)) {
    errors.push({file, msg: "Report Year: " + reportYear + " is malformed"})
  }
  else if (!/^\w{3,4}$/.test(fileExt)) {
    errors.push({file, msg: "File Extension: " + fileExt + " is malformed"})
  }
  else if (!/^\d{5}[A-Z]? - [A-Z]{2}$/.test(fundNumber)) {
    errors.push({file, msg: "Invalid Fund Number: " + fundNumber + ', for file:'})
  }
}