import { useEffect, useState } from 'react'
import { useQuery } from "@tanstack/react-query"

import { history } from 'services/history.js'
import { showLoading } from 'services/util'
import { getAuctionByCode } from '../api'
import { urlQueryParser } from '../util/urlQueryParser'
import { useSelector } from "react-redux"
import { getAuctionUser } from '../redux/selectors'

import EnterPayment from '../payment/EnterPayment'

import css from './sass/checkout.module.scss'

export default function CheckoutPaymentUpdate(props) {

  //destructuring auction code from url
  //called id in url query for that convention, but using auction code in aws to declutter url from being massive from aws UUID for auction ID to link tables
  const { auctionId: auctionCode } = urlQueryParser(props.location.search)

  const [auction, setAuction] = useState()
  const [myItems, setMyItems] = useState()

  const myTotal = myItems?.reduce(((acc, item) => acc += item.CurrentBid), 0)
  const auctionUser = useSelector(getAuctionUser);

  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, error, isLoading } = useQuery({
    queryKey: ["auctionDetail", auctionCode],
    queryFn: async () => {
      console.log('hello')
      return await getAuctionByCode(auctionCode)
    },
    staleTime: 0
  })
  // use effect for query results
  useEffect(() => {
    showLoading(isLoading)

    if (error || (auctionDetail && auctionDetail.error)) {
      console.log('Error!', error || auctionDetail.error)
    }

  }, [auctionDetail, error, isLoading])


  useEffect(() => {
    if (auctionDetail) {
      setAuction(auctionDetail)
      const pulledItems = auctionDetail.Items.filter((item) => item.CurrentHighBidder === auctionUser.UserId)
      setMyItems(pulledItems)
    }
  }, [auctionDetail])

  const finishCheckout = () => {
    console.log({auctionDetail, props})
    history.push({
      pathname: '/checkout',
      search: `?auctionId=${auctionCode}`,
      state: {address: props.location?.state?.address}
    })
  }

  const header = 'Update Payment Method'
  const message = `Please enter new payment information to return to the checkout page and make your purchase of $${myTotal}`
  const buttonText = 'Update and return to Checkout'

  return (
      <EnterPayment header={header} message={message} buttonText={buttonText} finishCheckout={finishCheckout}/>
  )
}