import { Component } from 'react';

import styled from 'styled-components'
import * as cs from 'css/CommonStyle';
import * as colors from 'css/Colors';
import { formatDateMonth } from '../adminutil'

const defaultFontSize = 16;

export const UserInfoDisplay = styled.div`
  margin:${10/defaultFontSize}rem 0;
  border: 1px solid #FFFFFF;
  border-radius: 3px;
  padding:${15/defaultFontSize}rem;
  label{
    cursor:inherit;
  }
`

export const EachUserLayout=styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${23 / defaultFontSize}rem 0 0;
  & > div{
    flex:0 50%;
    margin:0 0 ${18 / defaultFontSize}rem;
    display:flex;
    flex-direction:column;
    label{
      font-size:${12 / defaultFontSize}rem;
    }
    & label:nth-child(2){
      font-size:${14 / defaultFontSize}rem;
    }
  }
`

class UserProfile extends Component {

  render() {
    const userDetails = this.props.user;

    const UserInfocontainer = styled.div`
      /* cursor:${userDetails.Attributes.primaryCrmId ?"default" :"pointer"}; */
      position:relative;
      .selected{
        background-color: #FFFFC3;
        color:#000000;
      }
      .user-info{
        background-color: ${colors.secondaryMediumBlue};
      }

      .each-user-layout{
        label:first-child{
        color:${this.props.selected &&`${colors.darkGray}`}
      }
    }
    `

    const UserInfoDisplayHeader=styled.div`
      display:flex;
      justify-content:space-between;

      label{
        font-size:${14/defaultFontSize}rem;
        color: ${this.props.selected?`${colors.primaryBlue}`:`#FFFFFF`};
      }
      .account-linked{
        color:${colors.guideGreen};
      }
      &:after{
        content: '';
        position: absolute;
        display: block;
        height: ${1/defaultFontSize}rem;
        width: 100%;
        top: ${50/defaultFontSize}rem;
        left: 0;
        background-color:${this.props.selected?`${colors.primaryBlue}`:`#FFFFFF`}
      }
      .linked-highlight{
        margin:0;
        img{
          margin-right:${5/defaultFontSize}rem;
        }
      }
    `

    return (
      <UserInfocontainer>
        <UserInfoDisplay
          className={this.props.selected ?'selected':'user-info'}
          onClick={()=>this.props.handleSelect(this.props.value)}>
          <UserInfoDisplayHeader>
            <label
              className={this.props.selected?"bold":"user-account-type"}>
              Sign in method: {userDetails.Provider === 'Cognito' ? 'Email/Phone Number' : userDetails.Provider}
            </label>
            {/* <label
              className={`${userDetails.Attributes.primaryCrmId?"account-linked":"user-account-option"} bold `}>
              {userDetails.Attributes.primaryCrmId
                ? <p className="linked-highlight"><img src={ThickCheck} alt='success icon'/>Linked </p>
                : this.props.numUsers > 1
                  ? this.props.selected ? 'UNSELECT' : 'SELECT'
                  : null
              }
            </label> */}
          </UserInfoDisplayHeader>

          <EachUserLayout className="each-user-layout">
            <cs.TextInputdiv>
              <label> Email</label>
              <label className="bold">{userDetails.Attributes.email || "None"}</label>
            </cs.TextInputdiv>

            <cs.TextInputdiv>
              <label> Phone</label>
              <label>{userDetails.Attributes.phone || userDetails.Attributes.phone_number || "None"}</label>
            </cs.TextInputdiv>

            <cs.TextInputdiv>
              <label> Date Account Created</label>
              <label>{formatDateMonth(userDetails.UserCreateDate)}</label>
            </cs.TextInputdiv>

            {userDetails.Attributes.name && <cs.TextInputdiv>
              <label>First and Last Name</label>
              <label>{userDetails.Attributes.name}</label>
            </cs.TextInputdiv>
            }

            <cs.TextInputdiv>
              <label>CRM ID</label>
              <label>{userDetails.Attributes.primaryCrmId || "None"}</label>
            </cs.TextInputdiv>

          </EachUserLayout>

        </UserInfoDisplay>
      </UserInfocontainer>
    )
  }
}

export default UserProfile;