import { Component } from 'react';
import styled from 'styled-components'

import { showLoading } from 'services/util'
import Alert from 'common/notifications/Alert'
import BlackbaudLink from 'admin/BlackbaudLink'
import * as forms from 'css/forms';
import { adminErrorHandler } from 'admin/adminutil'
import * as colors from 'css/Colors';
import { primaryBlue } from 'css/Colors'
import { FilledYellow } from 'css/Buttons'
import iconInfo from 'images/iconColorinfo.svg'

import Overlay from '../components/Overlay'

import PrintReceiptPreview from '../history/PrintReceiptPreview'
import { printReceipt } from "./mggadminutil"

const defaultFontSize = 16
const FormStyles = styled.div`
  box-sizing:content-box;

  form {
    padding: 20px;
    background-color: ${ primaryBlue };
    border-radius:${ 5/defaultFontSize }rem;
  }

  legend {
    display:block;
    color: #FFFFFF;
    padding-left: 0;
  }


  fieldset {
    display:block;
    border:none;
    margin:0;
    padding:0;

    & > div {
      display: flex;
      justify-content: stretch;
      align-items: center;

      .blackbaud-link {
        margin-bottom: 10px;
      }
    }
  }

  button[type="submit"] {
    margin-right:${ 25/defaultFontSize }rem;
  }

  .alert>div>div {
    padding-left: 0;
  }
`

const StyledClearableInput = styled(forms.ClearableInput)`
  min-width:${ 60/defaultFontSize }rem;
  margin-right: ${ 10/defaultFontSize }rem;
  vertical-align: middle;
`

class SearchReceipt extends Component {

  state = {
    searchVal: "",
    submitted: false,
    receipt: false,
    date: null,
    response: null

  }

  handleSubmit = async value => {

    showLoading(true)

    const response = await printReceipt( value )

    this.setState({
      response: response
    })

    // receipt === null means not found
    if (!response || response.error) {
      this.setState({
        receipt: null,
      })
    } else {
      let { receiptBio, receipt } = response

      if (receipt && receiptBio) {
        this.setState({
          receipt,
          bio: receiptBio,
          // we need profile data whooops
          crmId: receipt.constituentLookupId,
          primaryDonorName: receipt.primaryDonorName,
        })
      }

    }
    this.setState({submitted: true});

    showLoading(false)
  }

  handleChange = e => {
    this.setState({
      searchVal: e.target.value,
      submitted: false,
      response: null
    })
  }

  render() {
    return (
      <FormStyles className="dashboard-container">
        <div className="hide-on-print">
          <h2>Print Receipt By Revenue ID</h2>
          <form onSubmit={ e => {
              e.preventDefault()
              this.handleSubmit(this.state.searchVal)
            }}>
            <fieldset>
              <legend id="">Enter Revenue ID</legend>
              <div>
                <StyledClearableInput
                  type="text"
                  name="revId"
                  value={this.state.searchVal}
                  errorOutline={this.state.receipt === null && this.state.submitted}
                  onChange={this.handleChange}
                  placeholder="e.g. rev-14616190"
                />
                <FilledYellow type="submit"> Print </FilledYellow>
                <BlackbaudLink />
              </div>
            </fieldset>
          </form>

          {this.state.receipt === null && this.state.submitted &&
            <Alert
              className="alert"
              nonPromoPage
              padded
              icon={iconInfo}
              color={colors.messagingRed}
              width="25" height="25" top="35%" >
              <div className="mismatch-error">{adminErrorHandler(this.state.response.error, `No matching donation with Revenue ID "${this.state.submitted}". Please check the number and try again.`)}</div>
            </Alert>
          }

        </div>
        { this.state.receipt &&
          <Overlay onHideOverlay={ (e) => {
            // reset receipt state to hide modal
            this.setState({
              receipt: false,
              date: null
            })
          }}>
            <PrintReceiptPreview
              gifts={ [ this.state.receipt ] }
              date={ this.state.date }
              bio={ this.state.bio }
              crmId={ this.state.crmId }
              primaryDonorName={ this.state.primaryDonorName }
            />
          </Overlay>
        }

      </FormStyles>
    )
  }
}

export default SearchReceipt;
