import { useEffect, useRef } from "react";

import store from 'redux/store';
import { setLoggedInUser, setCrmUser } from 'redux/actions';
import { getLoggedInUser } from 'redux/selectors';

import { getUserFromDb } from 'services/api';
import { showLoading } from 'services/util';
import { setSsoCookie } from "./authapi";
import { history } from 'services/history'

export const doCrmUser = async (signinDestination, cognitoUser, ssoRedirectUrl) => {
  const theme = store.getState().theme
  console.log('getting crmUserResponse')
  const crmUserResponse = await getUserFromDb()

  if (crmUserResponse.success && crmUserResponse.success.profile) {
    const crmUser = crmUserResponse.success

    if (theme.setSsoCookieForCrmUser) {
      await doSsoCookie(cognitoUser, crmUser);
    }

    if (cognitoUser) {
      store.dispatch(setLoggedInUser({cognitoUser, crmUser}))
    }
    else {
      store.dispatch(setCrmUser(crmUser))
    }

    if (theme.setSsoCookieForCrmUser && ssoRedirectUrl && theme.redirectTest && theme.redirectTest(ssoRedirectUrl)) {
      console.log({ssoRedirectUrl})
      return doSsoRedirect(ssoRedirectUrl, cognitoUser);
    }
    else if (!signinDestination &&  theme.closeAfterSignin && !ssoRedirectUrl) {
      // gives warning but doesn't throw excexption if window wasn't opened by script
      // don't close if user is going to /profile or other page
      window.close()
      showLoading(false, "doCrmUser - finish");
      return
    }
    else {
      const themePathOverride = (theme.customPostLogin) ? await theme.customPostLogin({cognitoUser, crmUser}) : ''

      theme.themeAnalytics.analyticsLogin()
      showLoading(false, "doCrmUser - finish");

      let [pathname, search] = (themePathOverride || signinDestination || theme.crmUserLandingPage || "/").split('?')
      if (search) search = '?' + search
      history.push({pathname, search}); // have to split search into separate property, or page doesn't load
    }
  }
  else {
    console.error('Error: ', crmUserResponse);
    showLoading(false, "doCrmUser - system error");
    history.push({pathname: '/systemerror'}) // TODO - there will be different kinds of system errors, but for now just this
  }
}

export const doSsoRedirect = async (ssoUrl, cognitoUser) => {
  const theme = store.getState().theme
  const loggedInUser = getLoggedInUser(store.getState())
  console.debug(ssoUrl)

  if ((theme.addTokenToUrlInDev && process.env.REACT_APP_AWS_ENVIRONMENT === 'dev')
      || theme.addTokenToUrlInProd // cookie doesn't work on uclafoundation.org, needs a token
      || (theme.addTokenToUrlForLocalhost && ssoUrl.indexOf('http://localhost') === 0)) {
    window.location.href = ssoUrl + (ssoUrl.indexOf('?') > -1 ? '&' : '?') + "uclaeaidmtoken=" + loggedInUser.cognitoUser.signInUserSession.idToken.jwtToken;
   }
  else {
    window.location.href = ssoUrl;
  }
}

export const doSsoCookie = async (cognitoUser, crmUser) => {
  let firstName, lastName;
  if (crmUser && crmUser.profile && crmUser.profile.primaryName) {
    firstName = crmUser.profile.primaryName.firstName
    lastName = crmUser.profile.primaryName.keyName
  }
  else if (cognitoUser && cognitoUser.attributes) { // only social non-CRM users have given_name and primary_name
    firstName = cognitoUser.attributes.given_name
    lastName = cognitoUser.attributes.family_name
  }

  await setSsoCookie(firstName, lastName);
}

// redirects an already signed in user away from login or registration pages
export const redirectSignedInUser = () => {
  const loggedInUser = getLoggedInUser(store.getState())
  if (loggedInUser) {
    history.push({ pathname: loggedInUser.crmUser ? store.getState().theme.crmUserLandingPage : store.getState().theme.nonCrmUserLandingPage })
    return true;
  }
  return false;
}

export const useEffectOnce = (fn) => {
  const ref = useRef(true);
  useEffect(() => {
    if (ref.current) {
      fn();
    }
    return () => {
      ref.current = false;
    };
  }, [fn]);
}

