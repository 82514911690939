import QrCode from '../images/mock-qr.jpg'
import CalendarIcon from '../../auctions/images/calendar-plus-icon.svg'

import { saveCalInvite } from '../../components/utils'

import css from '../sass/registerqr.module.scss'
import auctionCss from '../../auctions/sass/auctionscards.module.scss'
import themeCss from '../../components/sass/theme.module.scss'
import QrConfirmRow from './QrConfirmRow'
import QrTicket from 'sites/auctions/components/QrTicket'
import { getAuctionStatus } from 'sites/auctions/util/dateString'

export default function QrCard( {auction, auctionUser} ) {

  const email = localStorage.getItem('savedUsername')

  const options = {
    weekday: "long",
    year: "numeric",
    timeZoneName: 'short',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }
  
  const readableDate = (dateStr) => {
    const utcDate = new Date(dateStr)
    return utcDate.toLocaleDateString(undefined, options)
  }

  console.log('auction', auction)
  const getHeaderText = () => {
    const timeStatus = getAuctionStatus(auction.StartTime, auction.EndTime)

    if (auction.NeedsCheckin) return 'We look forward to seeing you!'
    else if (timeStatus === 'before') return `The online auction is available to browse. Bidding opens ${readableDate(auction.StartTime)}.`
    else if (timeStatus === 'started') return 'You can now browse the auction and bid on items.'
  }

  return (
    <div className={auctionCss['qr-container']}>
      <QrConfirmRow message={'Payment method added successfully!'} />
      <QrConfirmRow message={'Registration successful!'} />
      <p className={auctionCss['auction-box-info-heading']}>{getHeaderText()}</p>
      {auction.NeedsCheckin && <p className={auctionCss['auction-box-info-text']}>{readableDate(auction.StartTime)}</p>}
      {auction.NeedsCheckin && <div className={auctionCss['auction-box-info-text']}>
        <p>{auction.OrganizerAddress}<br/>{auction.AuctionLocation}</p>
      </div>}
      <div className={auctionCss['auction-box-info-row']}>
        <button className={`${themeCss['button']} ${themeCss['FilledWhite']}`}
          onClick={()=> saveCalInvite(auction)}>
          <img className ={auctionCss['auction-box-info-icons']} src={CalendarIcon} alt='Add To Calendar Icon'/>
          <p className={auctionCss['auction-box-info-link']}>
              Add Event to Calendar</p>
        </button>
      </div>

      {!auction.NeedsCheckin && <div className={css['qr-container-spacer']}></div>}

      {auction.NeedsCheckin && <div>
        <p className={auctionCss['auction-box-info-heading']}>
          Please use this QR code to check in when you arrive at the event.
        </p>
        
        <QrTicket auctionId={auction.AuctionId} />

        <img className={css['qr-confirm-wallet-button']} src='https://support.apple.com/library/content/dam/edam/applecare/images/en_US/iOS/add-to-apple-wallet-logo.png' alt='Add to Apple Wallet' />
        <img className={css['qr-confirm-wallet-button']} src='https://developers.google.com/static/wallet/images/branding/temp-wallet-condensed.png' alt='Add to Google Wallet' />

        <p> A copy of this QR code has been sent to {email}.</p>
      </div>}
    </div>
  )
}