import EnterPayment from "../payment/EnterPayment"

export default function UserUpdatePayment() {

  const header = 'Update Payment Method'
  const message = ''
  const buttonText = 'Update Payment Card'

  return (
    <>
      <EnterPayment header={header} message={message} buttonText={buttonText} user={true}/>
    </>
  )
}