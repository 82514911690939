import css from './sass/closedauction.module.scss'

export default function ClosedBiddingRow( {attendee, auctionCode, selectedAttendees, toggleCheckbox, toAttendeePage} ) {

  return (
    <tr className={css['closed-auction-table-data-row']}>
      {/* <td>
        <input type='checkbox'
          checked={selectedAttendees.indexOf(attendee.CurrentHighBidder) > -1}
          onChange={() => toggleCheckbox(attendee.CurrentHighBidder)} 
          />
      </td> */}
      {/* <td>{formatName(attendee)}</td> */}
      <td>{attendee.FullName}</td>
      <td>{attendee.TotalDue}</td>
      <td>{attendee.TotalPaid}</td>
      <td>{attendee.ItemCount}</td>
      {/* <td>{'alt'}</td> */}
      <td>
        <a className={css['action-link']} onClick={() => toAttendeePage(attendee)}>
            Manage Checkout
        </a>
      </td>
    </tr>
  )
}