
import { NewGiftStyles } from './shared/SharedStyles'

const GivingFromAbroad = () => (
  <NewGiftStyles>
    <h3><strong>GIVING FROM ABROAD</strong></h3>

    <div>
      <div>
        <h5><strong>For donors in Canada, Hong Kong, the United Kingdom and Europe</strong></h5>
        <p className="first-paragraph">UCLA has long been one of the top U.S. destinations for international students, as well as a leader
           in U.S. students studying abroad. We welcome the support of our alumni and friends across the globe.</p>
      </div>
      <div>
        <p><strong>Canada</strong></p>
        <p className="first-paragraph">All gifts are tax-deductible as prescribed by law. The University of California, Los Angeles is recognized as a
           tax-exempt organization by Revenue Canada, as referenced in C.R.C., c. 945, Schedule VIII, Section 3503, and included in the current qualified donees list.</p>
        <p>Donations may be made to UCLA directly in Canadian or U.S. dollars.</p>
        <p>For remittance information or an official donation receipt, please contact Juliet Allup, Director of Treasury Management,
           at <a href="mailto:jallup@support.ucla.edu">jallup@support.ucla.edu</a>.</p>
      </div>
      <div>
        <p><strong>Hong Kong</strong></p>
        <p className="first-paragraph">Hong Kong taxpayers may make donations in Hong Kong dollars or U.S. dollars via the University of California Foundation Limited
           in Hong Kong. Contributions will be tax deductible as provided for under Section 88 of the Hong Kong Inland Revenue Ordinance. The University of California
           Foundation Limited will collect your contributions and forward them to UCLA.</p>
        <p>For more information on tax-effective giving in Hong Kong, please
           download <a target="_blank" rel="noreferrer" href="https://ucla.box.com/s/483jj2xtlhozlsiddiolmjk9q3eacf14">Tax-Efficient Giving for Hong Kong Donors (PDF)</a>.</p>
      </div>
      <div>
        <p><strong>United Kingdom</strong></p>
        <p className="first-paragraph">British and U.S. taxpayers may make donations in pounds sterling, U.S. dollars and euros
           via <a target="_blank" rel="noreferrer" href="http://london.universityofcalifornia.edu/giving/index.html">the University of California Trust UK</a> in
           London - registered British Charity No. 1075405. Additionally, the University of California Trust UK can collect Gift Aid on your behalf and forward it to
           UCLA. <a target="_blank" rel="noreferrer" href="https://www.gov.uk/government/publications/charities-gift-aid-declaration-form-for-a-single-donation">Gift Aid information and a PDF form</a> are
           provided on the UK.gov website. For additional information about the University of California Trust UK, use the
           online <a target="_blank" rel="noreferrer" href="http://london.universityofcalifornia.edu/giving/inquiry-form.html">donor inquiry form</a>.</p>
        <p>Donors who have tax liabilities both in the UK and the U.S. can give to UCLA via
           the <a target="_blank" rel="noreferrer" href="http://www.nptuk.org/single-gifts/">NPT Trust UK</a> for dual U.S./UK taxpayers. The NPT Trust allows U.S. citizens
           living in the United Kingdom to receive tax benefits in both countries for their international contributions.</p>
        <p>To make a donation from the UK via the University of California Trust UK or via NPT UK, please contact Matthew Daines, Executive Director of Development – Europe,
           at <a href="mailto:mdaines@support.ucla.edu">mdaines@support.ucla.edu</a> or +44 (0) 20 7269 5908.</p>
      </div>
      <div>
        <p><strong>Europe</strong></p>
        <p className="first-paragraph">If you are a European taxpayer, you may give to UCLA
           through <a target="_blank" rel="noreferrer" href="http://www.transnationalgiving.eu/en/about/">Transnational Giving Europe (TGE)</a>, which currently covers 18 countries. TGE enables
           individual or corporate donors resident in one of the participating countries to make donations to nonprofit organizations in other member states, while benefiting directly
           from the tax advantages provided for in the legislation of their country of residence.</p>
        <p>Donors may make a gift to their
           respective <a target="_blank" rel="noreferrer" href="http://www.transnationalgiving.eu/en/partner/">national TGE partner</a> which will transfer donations to the University of California Trust UK, which will then forward the funds to UCLA.</p>
        <p>For more information about gifts from Europe or to make a donation through a TGE partner, please contact Matthew Daines, Executive Director of Development – Europe,
           at <a href="mailto:mdaines@support.ucla.edu">mdaines@support.ucla.edu</a> or +44 (0) 20 7269 5908.</p>
      </div>
      <div>
        <p><strong>France</strong></p>
        <p className="first-paragraph">French taxpayers may elect to make donations in euros to the University of California Trust UK in London via Transnational Giving Europe.
           Contributions will be tax deductible under French law. Such donations, however, can only support student scholarships to support French students studying at UCLA or
           UCLA students studying in France. Donations for other charitable activities, research and programs at UCLA need to be made directly to UCLA.</p>
        <p>For remittance information, please contact Matthew Daines, Executive Director of Development – Europe,
           at <a href="mailto:mdaines@support.ucla.edu">mdaines@support.ucla.edu</a> or +44 (0) 20 7269 5908.</p>
      </div>
      <div>
        <p><strong>General Information</strong></p>
        <p className="first-paragraph">For more information on giving from abroad, please contact:
          <br/>
          Bill Kinsella, Director of Gift Policy, at <a href="mailto:williamk@support.ucla.edu">williamk@support.ucla.edu</a> or +1 (310) 794-3453
          <br/>
          or
          <br/>
          Juliet Allup, Director of Treasury Management, at <a href="mailto:jallup@support.ucla.edu">jallup@support.ucla.edu</a>
          </p>
      </div>

    </div>

  </NewGiftStyles>

)
export default GivingFromAbroad
