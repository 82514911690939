import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { setIsLoading } from "redux/actions";

import { combineClasses } from 'services/util'

import Profile from './Profile'
import Membership from './Membership'
import Contact from './Contact'
import Card from './Card'

import css from '../sass/alumni.module.scss'


function Overview({
  authenticatedUser,
  history
}) {

  const {
    crmUser,
    cognitoUser
  } = authenticatedUser
  let dispatch = useDispatch()

  useEffect(() => {
    console.log('overview')
    dispatch(setIsLoading(false, "Overview"))
  }, [dispatch])

  if (!crmUser) {
    history.push('/help')
    return null
  }

  const sustainingDonorLevel = crmUser?.sustainingDonorLevel

  const membershipHeader = sustainingDonorLevel ?
    "Thank you for being a UCLA Alumni Association Sustaining Donor" :
    "Consider becoming a UCLA Alumni Association Sustaining Donor"

  return (
    <>
      <section className={combineClasses(css, 'hide-on-print', 'alumni-wrapper')}>
        <div className={css.content}>

          <h1 className="h2 dark-gray bold with-line line-yellow" id="landingPageTitle">Account Information</h1>
          <Profile {...crmUser.profile} {...cognitoUser} />

          <div className={css.molecules}>
            <i className={css['molecule']} />
            <i className={css['molecule']} />
            <i className={css['molecule']} />
          </div>

          <div className={css['membership-header']}>
            <h2>{ membershipHeader }</h2>
            { !sustainingDonorLevel && (
              <a
                href='https://giving.ucla.edu/Campaign/Donate.aspx?SiteNum=1&fund=63227O&amount=100&code=M-6561&AutoFN=Y'
                target="_blank"
                rel="noopener noreferrer"
                className="button is-blue is-small"
              >
                Donate Now
              </a>
            )}
          </div>

          { crmUser.alumniProfile && (
            <Membership
              {...crmUser.alumniProfile}
            />
          )}

          <Contact />
        </div>
      </section>

      { crmUser.profile.constituentLookupId !== '' && (
        <Card
          {...crmUser.alumniProfile }
          constituentLookupId={crmUser.profile.constituentLookupId}
        />
      )}
    </>
  )
}

export default Overview
