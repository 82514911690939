import { useState, useEffect } from "react"
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import css from './sass/additionalinfo.module.scss'
import Alert from 'common/notifications/Alert'
import iconInfo from 'images/iconColorinfo.svg'

import { getLoggedInUser } from "redux/selectors";
import { history } from 'services/history'
import { showLoading } from "services/util"
import { saveUcla1NonCrmUser } from './ucla1api'

import { Input, TextArea } from 'css/forms'
import DropDownMenu from 'common/forms/DropDown'
import * as cs from 'css/CommonStyle'

const affiliationTypes = [
  "Select...",
  "Alumni",
  "Parent",
  "Student",
  "Donor",
  "Corporate Partner",
  "Faculty",
  "Staff",
]

const currentYear = (new Date()).getFullYear()
const graduationYears = ["Select..."]
for (let i=currentYear+4;i>=1920;i--){
  graduationYears.push(""+i);
}

// keeps form from accidentally submitting if user hits enter in a text field or drop down
const blockEnterSubmit = (e) => {
  const keyCode = e.keyCode ? e.keyCode : e.which;
  if (keyCode === 13)
    e.preventDefault()
}

// this component must be outside of AdditionalInfoForm or useForm gets weird with focus - https://stackoverflow.com/questions/56758864/input-is-loosing-focus-on-hooks-update
const TextField = (props) => {
  return (
    <cs.TextInputdiv>
      <div className={css['form-label']}>
        <label>{props.fieldLabel} {props.required && " *"}</label>
      </div>
      <div>
        <Input {...props.register(props.fieldName, {required: !!props.required})}
               className={css['form-input']}
               readOnly={props.readOnly}
               placeholder={props.placeholder}
               autoFocus
               onKeyDown={blockEnterSubmit}
               key={props.fieldName}/>
      </div>
    </cs.TextInputdiv>
)}

function AdditionalInfoForm(props) {
  const [affiliation, setAffiliation] = useState('')
  const [gradYear, setGradYear] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userPhone, setUserPhone] = useState('')
  const [errorMsg, setErrorMsg] = useState(null)
  const loggedInUser = useSelector(getLoggedInUser);

  const { register, handleSubmit, watch, setValue,
          formState: { errors, isSubmitting, isDirty, isValid } } = useForm({mode: "onChange"});

  useEffect(() => {
    setErrorMsg()
  }, [])

  useEffect(() => {
    const email = loggedInUser.cognitoUser.signInUserSession.idToken.payload.email
    const phone = loggedInUser.cognitoUser.signInUserSession.idToken.payload.phone_number

    if (email) {
      setValue("email", email)
      setUserEmail(email)
    }

    if (phone) {
      setUserPhone(phone)
      setValue("phoneNumber", phone)
    }

    showLoading(false, 'AdditionalInfoForm')
  }, [loggedInUser, setValue])

  const handleAffiliationChange = (e) => {
    const newValue = e.target.value === "Select..." ? "" : e.target.value
    setValue("affiliation", newValue)
    setAffiliation(newValue)
  }

  const handleGraduationYearChange = (e) => {
    const newValue = e.target.value === "Select..." ? "" : e.target.value
    setValue("gradYear", newValue)
    setGradYear(newValue)
  }

  const genericError = (<>We're sorry, an error has occurred in your form submission. Please try again, or contact Alumni Affairs at
                          <a href="mailto:ace@support.ucla.edu?subject=UCLA ONE registration inquiry">ace@support.ucla.edu</a></>)

  const onSubmit = async data => {
    showLoading(true, 'AdditionalInfoForm')
    const response = await saveUcla1NonCrmUser(data)
    console.log(response)
    if (!response || response.error) {
      await setErrorMsg(response && response.error ? response.error.msg || genericError : genericError)
      showLoading(false)
      return;
    }

    history.push('inprogress')
  }

  return (
    <div className={css['additional-info-form']}>
      <h3 id="pageTitle" className=" bold">Additional Information</h3>

      <cs.CommonInnerDiv>
        <cs.InnerContent>

          <div>
            <p>Please provide any information to help our UCLA ONE team match you to your UCLA record.</p>
          </div>

          {errorMsg &&
            <Alert icon={iconInfo} >
              <div>{errorMsg}</div>
            </Alert>
          }

            <form onSubmit={handleSubmit(onSubmit)}>

              <TextField fieldLabel={"Current Email"} fieldName={"email"} required={true} readOnly={!!userEmail} register={register}/>
              <TextField fieldLabel={"First Name"} fieldName={"firstName"} required={true} register={register}/>
              <TextField fieldLabel={"Middle Name"} fieldName={"middleName"} register={register}/>
              <TextField fieldLabel={"Last Name"} fieldName={"lastName"} required={true} register={register}/>
              <TextField fieldLabel={"Maiden Name"} fieldName={"maidenName"}  register={register}/>
              <TextField fieldLabel={"Birth Date"} fieldName={"birthDate"} placeholder="dd/mm/yyyy"  register={register} v/>
              <cs.TextInputdiv>
                <div className={css['form-label']}>
                  <label>Graduation Year</label>
                </div>
                  <div className={css['dropdown-wrapper']}>
                  <DropDownMenu
                    name={"GradutationYear"}
                    title={gradYear || "Select..."}
                    list={graduationYears}
                    changeHandler={handleGraduationYearChange}
                  />
                </div>
              </cs.TextInputdiv>

              <TextField fieldLabel={"Degree"} fieldName={"degree"} placeholder="BA, BS, MA, etc" register={register} b/>
              <TextField fieldLabel={"Major"} fieldName={"major"}  register={register}/>
              <TextField fieldLabel={"Past UCLA or student email(s)"} fieldName={"pastUclaEmail"}  register={register}/>
              <TextField fieldLabel={"Phone number"} fieldName={"phoneNumber"} defaultValue={userPhone} readOnly={!!userPhone} register={register}/>

              <cs.TextInputdiv>
                <div className={css['form-label']}>
                  <label>Affiliation</label>
                </div>
                <div>
                  <div className={css['dropdown-wrapper']}>
                    <DropDownMenu
                      name={"Affiliation"}
                      title={affiliation || "Select..."}
                      list={affiliationTypes}
                      changeHandler={handleAffiliationChange}
                    />
                  </div>
                </div>
              </cs.TextInputdiv>

              {affiliation === "Student" &&
                <TextField fieldLabel={"Student UID"} fieldName={"studentUid"} register={register}/>}

              <cs.TextInputdiv>
                <div className={css['form-label']}>
                  <label>Please add any other additional information you feel is relevant to reconciling your UCLA records</label>
                </div>
                <TextArea {...register("comments")} borderColor={"#dedede"}/>
              </cs.TextInputdiv>

              <cs.FooterBtnDiv width="430px">
                <cs.SubmitBtn className={css['form-submit-button']} disabled={!isDirty || !isValid}>SUBMIT</cs.SubmitBtn>
              </cs.FooterBtnDiv>

            </form>

        </cs.InnerContent>

      </cs.CommonInnerDiv>

    </div>
  )

}

export default AdditionalInfoForm