import { Route } from 'react-router-dom';

import Menu from './components/Menu'
import EventsHeader from './components/EventsHeader'
import Login from 'authentication/routes/Login';
import logoImage from 'images/logo-ucla.svg'
import * as dummyAnalytics from 'services/dummyanalytics';

import PromoPage from '../mygiving/promopage/PromoPage';

const cognitoClientIds = {
  "dev": "30986s9fhf521tstq5580vrb59",
  // "stage": "",
  // "pilot": "",
  // "preprod": "",
  // "prod": "",
}

const hideFooter = () => {
  return true
}

const themeHeader = (<EventsHeader />)

const hideHeader = () => {
  return (window.location.href.indexOf('/profile') > -1)
}

const routes = [
  <Route exact={true} path='/' component={Login} key="/eventshome" />,
  <Route exact path='/promopage' component={PromoPage} key='/promopage' />,
]

export const events = {
  name: 'events',
  appName: "UCLA Events Sign On Portal",
  routes,
  logoImage,
  themeMenu: Menu,
  themeHeader,
  hideHeader,
  hideFooter,

  windowTitle: 'UCLA Sign On Portal',
  signinText: "Please enter the email or cell phone number associated with your UCLA account.",
  registrationTitle: "UCLA Sign On Portal Registration",
  supportTeamName: "UCLA Events",
  supportEmail: "???@support.ucla.edu",
  supportPhone: "(310) ???-????",

  apiGatewayHost: process.env.REACT_APP_IDM_API_GATEWAY_HOST,
  extraGetUserParam: '?app=events',
  cognitoClientId: cognitoClientIds[process.env.REACT_APP_AWS_ENVIRONMENT || "dev"],

  setSsoCookieForCrmUser: true,
  setSsoCookieForNonCrmUser: true,
  closeWindowOnProfileSave: true,
  closeAfterSignin: true,
  forceLogoutOnSignOrRegister: true,
  captureNonCrmUser: false,
  themeAnalytics: dummyAnalytics,
  isProdEnv: (process.env.REACT_APP_AWS_ENVIRONMENT === "prod"),
};
