import { useContext } from 'react'

import { Auth } from 'aws-amplify'
import { deleteCookie } from "services/util"
import { signOutUrl } from 'config'
import { theme } from 'ThemeContext';

export const signOut = async (forceUrl, ssoRedirectUrl) => {
  console.log('signOut!!!', {forceUrl, signOutUrl, ssoRedirectUrl})

  try {
    await Auth.signOut

    clearLocalStorage()

    if (forceUrl === window.location.href)
      window.location.reload()
    else
      window.location.href = forceUrl || signOutUrl
  }
  catch (err) {
    console.error('signout error', err)
    clearLocalStorage()
  }

  deleteCookie('.UCLAEAIDMID', '/', 'ucla.edu');

  // get rid of old sessions and clear everything except persistent itms
  function clearLocalStorage() {
    const savedUsername = localStorage.getItem('savedUsername')
    const hideReportsNotification = localStorage.getItem('hideReportsNotification')

    let themeItems = {}
    if (theme.appLocalStoragePersist)
      theme.appLocalStoragePersist.forEach(item => themeItems[item] = localStorage.getItem(item))

    localStorage.clear()
    sessionStorage.clear()

    if (savedUsername)
      localStorage.setItem('savedUsername', savedUsername)
    if (hideReportsNotification)
      localStorage.setItem('hideReportsNotification', hideReportsNotification)
    if (ssoRedirectUrl)
      sessionStorage.setItem('ssoRedirectUrl', ssoRedirectUrl)

    Object.keys(themeItems).forEach(key => {
      console.log(key)
      console.log(themeItems[key])
      if (themeItems[key] !== null) localStorage.setItem(key, themeItems[key])
    })
  }
}
