const initialState = localStorage.getItem('liveAuctionCode')

const liveAuctionCode = (state = initialState, action) => {
  if (action.type === "SET_LIVE_AUCTION") {
    // console.log({action, state})
    const code = action.payload
    if (code) localStorage.setItem('liveAuctionCode', code)
    else localStorage.removeItem('liveAuctionCode')

    return code || ""
  }
  else {
    return state
  }
}

export default liveAuctionCode;
