import * as cs from 'css/CommonStyle'
import { ProfileDataContainer, ProfileDataContent } from '../personal/MyNames'

import styled from 'styled-components';
import { device } from 'common/Device'

export const PaddingProfileDateContent = styled(ProfileDataContent)`
 & >div:nth-child(2n){
	margin:0;

}
& input {
	color: #000000;
}

.education-flex-1,.education-flex-2{
  flex:0 100%;
  display:flex;
  & >div{
    flex:0 50%;
  }
  & >div:nth-child(2n){
    padding-left:${30/16}rem;
  }
}


@media ${device.mobile}{
  .education-flex-1,.education-flex-2{
    flex-direction:column;
    & >div{
      padding:0 !important;
    }
  }

}

`
const Education = props => (

  <ProfileDataContainer>
    <div>
      <h5>{props.title}</h5>

      <PaddingProfileDateContent>
        <div className="education-flex-1">
          <div>
            <label className="bold"> College</label>
            {/* <cs.TextInputdiv>
						<forms.Input width="415px"  defaultValue={(props.data && props.data.school)} disabled></forms.Input>
					</cs.TextInputdiv> */}
            <cs.TextInputdiv>
              <label>{props.data && props.data.school} </label>
            </cs.TextInputdiv>
          </div>

          <div>
            <label className="bold"> Major </label>
            <cs.TextInputdiv>
              {/* <forms.Input width="415px"  defaultValue={(props.data && props.data.major)} disabled ></forms.Input> */}
              <label>{props.data && props.data.major}</label>
            </cs.TextInputdiv>
          </div>
        </div>

        <div className="education-flex-2">
          <div>
            <label className="bold"> Degree</label>
            <cs.TextInputdiv>
              {/* <forms.Input width="415px"  defaultValue={(props.data && props.data.degree)} disabled ></forms.Input> */}
              <label>{props.data && props.data.degree}</label>
            </cs.TextInputdiv>
          </div>

          <div>
            <label className="bold">Class of</label>
            <cs.TextInputdiv>
              {/* <forms.Input width="415px"  defaultValue={(props.data && props.data.classOf)} disabled ></forms.Input> */}
              <label>{props.data && props.data.classOf}</label>
            </cs.TextInputdiv>
          </div>
        </div>
      </PaddingProfileDateContent>

    </div>
  </ProfileDataContainer>
)

export default Education;
