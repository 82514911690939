import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { getLoggedInUser } from 'redux/selectors';
import { ThemeContext } from 'ThemeContext';

import Alert from 'common/notifications/Alert'
import main from './sass/alumni.module.scss'
import css from './sass/help.module.scss'
import { combineClasses } from 'services/util'
import Explore from './overview/Explore'
import Contact from './overview/Contact'

function Help() {
  const theme = useContext(ThemeContext)
  const loggedInUser = useSelector(getLoggedInUser);

  return (
    <section className={combineClasses(main, 'hide-on-print', 'alumni-wrapper')}>

      {loggedInUser &&
        <div className={css['alert-wrapper']}>
          <Alert
            nonPromoPage={true}
            backgroundColor={'#F2F2F2'}
          >
            <header className={css['user-not-found']} id="crmUserNotFound">
              <em>We were unable to connect your name to an account currently in our system.</em>
              <p>If you previously attended an alumni event, or believe you have a currently active account,
                 please contact the James West Alumni Center at <a href="mailto:alumni@alumni.ucla.edu">alumni@alumni.ucla.edu</a>
                 for help linking that account to your online account.</p>
              <i className={css['icon-info']} />
            </header>
          </Alert>

        {theme.nonCrmUserExtra}
        </div>
      }

      <div className={main.content}>
        <Explore />
        <Contact />
      </div>
    </section>
  )
}

export default Help
