import styled from 'styled-components'
import { device } from 'common/Device'
import Zindex from 'css/z-indexLayers.js';
import { defaultPrintFontSize } from './PrintStyles'

const defaultFontSize = 16
const printPreviewBannerHeight = 70

export const PrintPreviewBanner = styled.header`
  position:absolute;
  z-index:${parseInt(Zindex.zindexPopUpStackOrder2,10) + 1};
  width:100%;
  box-sizing:border-box;
  height:${ printPreviewBannerHeight/defaultFontSize }rem;
  top:0;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  padding:${ 10/defaultFontSize }rem ${ 30/defaultFontSize }rem;
  background:#FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);

  .header-image {
    width:${ 232 / defaultFontSize }rem;
  }

  button {
    margin-left: ${ 20/defaultFontSize }rem;
  }

  @media screen and ${ device.tablet } {
    padding:${ 10/defaultFontSize }rem ${ 20/defaultFontSize }rem;
  }

  @media screen and ${ device.mobile } {
    justify-content:flex-end;
    .header-image {
      display:none;
    }
  }
`

export const PrintPreviewFonts = styled.div`
  line-height:${ 14/defaultPrintFontSize };
  font-size:${ 18/defaultFontSize * 100}%;

  @media ${ device.tablet } {
    font-size:${ 14/defaultFontSize * 100}%;
  }

  @media print {
    font-size:${ defaultPrintFontSize/defaultFontSize * 100 }%;
  }
`

export const PrintContainer = styled.div`
  position:relative;
  top:${ printPreviewBannerHeight/defaultFontSize }rem;
  height:calc(100vh - ${ printPreviewBannerHeight/defaultFontSize }rem);
  overflow:scroll;
  z-index:${ Zindex.zindexPopUpStackOrder2 };

  @media print {
    position:static;
    overflow:auto;
    height:auto;
  }
`

export const PrintBannerImage = styled.img`
  display:none;

  @media print {
    display:block;
    height:${ 52/defaultPrintFontSize }em;
    width:auto;
  }
`

