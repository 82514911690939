import { doApi } from 'services/api';
import { initializeDate } from 'services/util'

// gets cookies needed to interact with other sites (Give Again, Pay Now)
// user must be logged in and a CRM user for this to work
export const getCookies = async (target) => {
  return await doApi({route:'getcookies?target='+target, withCredentials: true});
};

// pings the VPC lambdas to wake them up
export const doPing = async () => {

  // TODO - remove comment to hide errors before going live
  const response = await doApi({route:'dppinger', noAuth:true, /* noError:true */});
  console.log('ping response: ' + response);
};

// @params Date || DateString rawDate
export const formatDate = rawDate => {
  const date = initializeDate( rawDate )


  let month = (1 + date.getMonth()).toString()
  // month = month.length > 1 ? month : '0' + month

  let day = date.getDate().toString()
  // day = day.length > 1 ? day : '0' + day

  return (month + '/' + day + '/' +  date.getFullYear())
}

export const formatAmount = amount => {
  return "$" + Number(amount).toLocaleString([], {minimumFractionDigits:2});
}

export const formatFund = (value, fundId) => {
  return (value.indexOf(fundId) === -1) ?
       value + ' - ' + fundId :
       value
 }

export const formatActivity = ({ index, balance }) => {
  const rowIndex = (1*index)+1;
  return (balance ? 'Scheduled ' : '') + 'Payment ' + rowIndex;
}

// helper for getting to and from date range
export const getLimitsForYear = ( year ) => {
  let from = new Date()
  let to = new Date()

  if (!isNaN(parseInt(year,10))) {
    from.setFullYear( year )
    to.setFullYear( year )

    from.setMonth(0)
    from.setDate(1)
    from.setHours(0)
    from.setMinutes(0)

    to.setMonth(11)
    to.setDate(31)
    to.setHours(23)
    to.setMinutes(59)
    to.setSeconds(59)

    return {
      from,
      to,
    }
  }

  return false;
}
