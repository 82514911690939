export const cardValidCheck = (dateString) => {
  //per Donor relations, warning should show up at the beginning of the month before the expiration date
  // ex: card expires 07/23, warning should show up starting 06/01/2023

  const today = new Date();
  
  const numExpMonth = parseInt(dateString.slice(0, 2), 10);
  const numExpYear = parseInt(dateString.slice(5, 7), 10);
  const currentYear = today.getFullYear() - 2000;
  const currentMonth = today.getMonth() + 1;
  
  //if the expiring year is less than current year, card is invalid
  if (numExpYear < currentYear) {
      return false;
  }
  
  //if expiration year is equal to current year, need to check current month
  if (numExpYear === currentYear) {   
      // if the exp month is one less than the current month, warning should show up
      if ((numExpMonth - 1) <= currentMonth) {
          return false;
      }
  }
  //special case for january for different year values
  //ex: current month 12/2022, exp date 01/23 - need to show as expiring soon
  if (numExpMonth === 1 &&
      currentMonth === 12 &&
      ((numExpYear - 1) === currentYear)
      ) {
      return false;
  }
  return true;
}