import { Route, Switch } from 'react-router-dom';

import logoImage from './images/Boxed-Alumni-Portal-Logo-v3.svg';
import PrivateRoute from 'common/PrivateRoute';
import Login from 'authentication/routes/Login';

import LinkUserSuccess from 'admin/linkuser/LinkUserSuccess'
import LinkCrm from 'admin/linkuser/LinkCrm'
import BrowseAsUser from 'admin/BrowseAsUser'
import UserSearch from 'admin/sharedqueries/UserSearch'
import AuditTrail from 'admin/sharedqueries/AuditTrail'
import UnconfirmedUsers from 'admin/UnconfirmedUsers'

import AlumniEmail from './alumniemail/AlumniEmail';
import Overview from './overview/Overview';
import Help from './Help'
import Menu from './components/Menu'
import alumniHeader from './sass/navigation.module.scss'
import * as dummyAnalytics from 'services/dummyanalytics';

import AdminViewList from './admin/AdminViewList'

// used to log in for network subscribe functionality
const redirectTest = (ssoRedirectUrl) => {
  return ( ssoRedirectUrl.indexOf('https://dev.alumni.ucla.edu/') === 0
        || ssoRedirectUrl.indexOf('https://stage.alumni.ucla.edu/') === 0
        || ssoRedirectUrl.indexOf('https://alumni.ucla.edu/') === 0

        || ssoRedirectUrl.indexOf('https://board.dev.alumni.ucla.edu/') === 0
        || ssoRedirectUrl.indexOf('https://board.alumni.ucla.edu/') === 0
  )
}

const returnToUclaAlumniLink = process.env.REACT_APP_ALUMNI_SITE_LINK || "https://alumni.ucla.edu/"

const routes = [
  <Route exact={true} path='/' component={Login} key="/alumnihome" />,
  <Route exact={true} path='/help' component={Help} key="/help" />,
  <PrivateRoute exact={true} path='/alumniemail' component={AlumniEmail} key="/alumniemail" />,
  <PrivateRoute exact={true} path='/overview' component={Overview} key='/overview' />,
]

const themeHeader = (<div className={alumniHeader.container}>
                      <a className={alumniHeader['return-link']} href={returnToUclaAlumniLink}>Return to UCLA Alumni</a>
                      <a className={alumniHeader['support-link']} href="https://giving.ucla.edu/Campaign/Donate.aspx?SiteNum=1&fund=63227O&code=M-6561&AutoFN=Y" target="_blank" rel="noopener noreferrer">Support UCLA</a>
                    </div>)

const cognitoClientIds = {
  "dev": "19cipoblib15lnupv8inu5sp61",
  "stage": "357p50p2pr8g5pd7qj42ul3qtv",
  "pilot": "7l7pkj2eoqnnrv8ac5hspvu9mu",
  "preprod": "7bictf5i30gn9ttiet8h0k8s9f",
  "prod": "46pjntp0lm1j2mpjfbe4b0dsba",
}

const REGISTRATION_TEXT = "If you have updated your contact information with UCLA, use that to register. Otherwise use the email or cell phone associated with your original student record."

const registrationText = (
  <>
    <span className="block-title bold">
      {REGISTRATION_TEXT}
    </span>
  </>)

const themeAdminLinks = (parentHistory) => (
  <div className="admin-content-wrapper">
    <Switch>
      <PrivateRoute exact={true} path='/admin/linkusertocrm' key='/admin/linkusertocrm' component={LinkCrm} />
      <PrivateRoute exact={true} path='/admin/linkusertocrm/success' key='/admin/linkusertocrm/success' component={LinkUserSuccess} />
      <PrivateRoute exact={true} path='/admin/linkusertocrm' key='/admin/linkusertocrm' component={LinkCrm} />
      <PrivateRoute exact={true} path="/admin/history" key="/admin/history" component={AuditTrail} />
      <PrivateRoute exact={true} path="/admin/users" key="/admin/users" component={UserSearch} />
      <PrivateRoute exact={true} path="/admin/unconfirmedusers" key="/admin/unconfirmedusers" component={UnconfirmedUsers} />

      <PrivateRoute exact={true} path='/admin/browseasuser' key='/admin/browseasuser' component={BrowseAsUser} data={{parentHistory}} />
    </Switch>
  </div>
)

// IMPORTANT: some of these are inherited or imported directly by other themes
export const alumni = {
  name: 'alumni',
  apiGatewayHost: process.env.REACT_APP_IDM_API_GATEWAY_HOST,
  routes,
  cognitoClientId: cognitoClientIds[process.env.REACT_APP_AWS_ENVIRONMENT || "dev"],
  logoImage,
  themeHeader,
  themeMenu: Menu,
  windowTitle: 'UCLA Alumni Portal',
  signinText: "Please enter the email or cell phone number associated with your UCLA account.",
  registrationTitle: "Alumni Portal Registration",
  registrationText,
  redirectTest,
  crmUserLandingPage: '/overview',
  nonCrmUserLandingPage: '/help',
  defaultAdminLink: '/admin/users',
  setSsoCookieForCrmUser: true,
  supportTeamName: "James West Alumni Center",
  supportEmail: "alumni@alumni.ucla.edu",
  supportPhone: "310-825-2586",
  appName: "UCLA Alumni Portal",
  captureNonCrmUser: true,
  extraGetUserParam: '?app=alumni',
  forceLogoutOnRedirect: true,
  themeAdminViewList: AdminViewList,
  themeAdminLinks,
  adminUserTest: "isApAdmin",
  themeAnalytics: dummyAnalytics,
};

