import { useEffect, useState} from 'react'

import { sortSpan, handleSort, performSort } from '../auctionsadminutil'

import css from './sass/liveauction.module.scss'

import LiveItems from './LiveItems'

export default function LiveBiddingItemsTable({ auctionDetail, userFullNamesMap }) {

  const [displayItems, setDisplayItems] = useState([])

  const [sortOptions, setSortOptions] = useState({ key: '', dir: 'asc' })
  const tableFormatter = [
    ['Title', 'Name'],
    ['BidCount', 'Bid Count'],
    ['CurrentBid', 'Current Bid'],
    ['CurrentHighBidderFullName', 'Current High Bidder'],
    ['Value', 'Fair Market Value'],
    ['StartingBid', 'Starting Bid'],
    ['MinRaise', 'Minimum Raise']
  ]

  const nameConvert = (item) => {
    let result = userFullNamesMap[item.CurrentHighBidder]
    if (result === undefined) result = ''
    return result.trim()
  }

  useEffect(() => {
    if (auctionDetail.Items && userFullNamesMap) {
      const output = auctionDetail.Items.map((item) => {
        return {
          ...item,
          CurrentHighBidderFullName: nameConvert(item)
        }
      })
      setDisplayItems(output)
    }
  }, [auctionDetail, userFullNamesMap])

  const sortedItems = performSort(displayItems, sortOptions)

  return (
      <table className={css['admin-items-table']}>
        <thead>
          <tr>
          {tableFormatter.map((ele) => {
              return (
                <th onClick={() => handleSort(ele[0], sortOptions, setSortOptions)} key={ele[1]}>
                  {ele[1]} {sortSpan(ele[0], sortOptions)}</th>
                )})}
          </tr>
        </thead>
        <tbody>
        {sortedItems && sortedItems.map((item) => {
          return (
            <tr key={item.ItemId}>
              <td>{item.Title} {item.BidCount > 0 && (<LiveItems auctionDetail={auctionDetail} item={item} />)}</td>
              <td>{item.BidCount}</td>
              <td className={item.CurrentBid > item.Value ? css['above-fmv'] : ''}>${item.CurrentBid}</td>
              <td>{item.CurrentHighBidderFullName}</td>
              <td>${item.Value}</td>
              <td>${item.StartingBid}</td>
              <td>${item.MinRaise}</td>
            </tr>)
        })}
        </tbody>
      </table>
  )
}