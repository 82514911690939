
//subscribes to all current bids
export const onUpdateCurrentBidSubscription = `
  subscription onUpdateCurrentBid($auctionId: String!) {
    onUpdateCurrentBid(AuctionId: $auctionId)  {
      ItemId
      CurrentBid
      BidCount
      CurrentHighBidder
    }
  }`


// subscribes to any auction update (like auction closed early)
export const onUpdateAuctionSubscription = `
  subscription onUpdateAuction($auctionId: String!) {
    onUpdateAuction(AuctionId: $auctionId)  {
      AuctionId
      ResponseData
    }
  }`


