import styled from 'styled-components'

import Zindex from 'css/z-indexLayers.js';
import { device } from 'common/Device'

import ContactEmail from 'images/ContactEmail.svg'
import PhoneIcon from 'images/PhoneIcon.svg'

const defaultFontSize=16

const ContactCopyContent = styled.div`
  z-index:${Zindex.zindexOverlaystackOrder1};
  padding-top:calc(10rem/${defaultFontSize});
  p:last-child{
    padding: 0 10px;
  }
  & >p{
    font-weight:normal;
    font-size:${14/defaultFontSize}rem;
    & a, span{
      margin-left:10px;
    }
  }

  & >p:last-child{
    color:#000000;
  }
  @media ${device.tablet} {
    position:relative;
    bottom:0;
    padding:0;
    p:last-child{
    padding:0
    }
    p:nth-child(1){
      display:inline;
      margin:0;
    }
    & p:nth-child(2){
      margin:0 ${10/defaultFontSize}rem;
      display:inline;
    }
  }
  @media ${device.mobile} {
  top:0;
  bottom:initial;
  }
`
const ContactUsCopyContent = (props) => {
  return (
    <ContactCopyContent>
      <p><img src={ContactEmail} alt=""/><a href="mailto:MyGivingGateway@support.ucla.edu">Email</a></p>
      <p><img src={PhoneIcon} alt="" /><span>(310) 794-2447</span> </p>
      <p>The UCLA Office of Donor Relations is open Monday through Friday from 10:00 a.m. to 3:00 p.m. PT.
        Please feel free to contact us at any time, and we will respond as soon as possible during our normal business hours.</p>
    </ContactCopyContent>
  )
}

export default ContactUsCopyContent
