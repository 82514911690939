/** landing page benefits summary (giving history, print receipts, My Information)
 * Dashboard page infomration( My information, FAQ's, ContactUS)
 */
import styled from 'styled-components'

import { primaryYellow } from 'css/Colors'
import { device } from 'common/Device'

const defaultFontSize = 16

const SummaryContent = styled.div`
  display:flex;
  width:100%;
  height:100%;
  box-sizing:border-box;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  position:relative;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  color:#000000;
  background-color: #FFFFFF;
  text-decoration:none;
  text-align:center;
  padding-top:${ 40/defaultFontSize }rem;
  padding-bottom:${ 40/defaultFontSize }rem;

  &:before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 5px;
    width: 100%;
    background-color:${ primaryYellow };
  }

  @media ${device.tablet}{
    /* flex-direction:row; */
    /* margin-bottom:${ 25/defaultFontSize }rem; */
    padding:${ 30/defaultFontSize }rem ${ 14/defaultFontSize }rem;
  }

  @media ${ device.mobile } {
    margin-bottom:${ 15/defaultFontSize }rem;
    p {
      line-height:${ 26/defaultFontSize };
    }
  }
`

const SummaryPadding = styled.div`
  width:100%;
  box-sizing:border-box;
  padding:${ 25/defaultFontSize }rem ${ 30/defaultFontSize }rem 0;

  @media ${device.tablet}{
    padding:0;
  }
`

const SummaryInner = styled.div`
  width:100%;
  box-sizing:border-box;
 // position:relative;
  @media ${device.tablet}{
    /* text-align:left; */
    h3.has-dots:after {
      margin:calc(12rem/16) auto 0;
    }
  }

  @media ${ device.mobile } {
    text-align:center;
  }
`

const SummaryImageContainer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;

  /* override grid padding */
  @media ${ device.tablet } {
    padding-right:0 !important;
  }
`


const MoreSummary = props => (
  <SummaryContent as={props.as} to={props.to} href={props.relativeLink}>
    <SummaryImageContainer className="m-span-2">
      <img src={props.icon} alt='' />
    </SummaryImageContainer>
    <SummaryInner className="m-span-2">
      <SummaryPadding>
        {props.children}
      </SummaryPadding>
    </SummaryInner>
  </SummaryContent>
)

export default MoreSummary
