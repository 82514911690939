import store from 'redux/store';
import { getCrmUser } from 'redux/selectors';

import { initializeDate } from 'services/util'
import { HeaderTitle } from 'css/CommonStyle'

import EiarReports from './components/EiarReports'
import CeirReports from './components/CeirReports'
import AcrReports from './components/AcrReports'
import RecognitionSummary from './components/RecognitionSummary'

import Links from '../components/Links'
import { HeaderBackground } from '../components/CommonStyles'
import DashboardHeader from '../images/dashboard-top-background-banner.jpg'
import { LinkWrapper } from './ReportStyles'


function Reports(props) {
  return (
    <HeaderBackground className="background-grey" headerImage={DashboardHeader}>
      <div className="wrapper m-no-padding">
        <HeaderTitle className="h1 center has-no-margins hide-on-print" id="landingPageTitle">Reports</HeaderTitle>

        <EiarReports />

        <CeirReports />

        <AcrReports />

        <RecognitionSummary />

        <LinkWrapper>
          <Links />
        </LinkWrapper>
      </div>
    </HeaderBackground>
  )
}

export default Reports