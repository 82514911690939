import { useState } from 'react';

import store from 'redux/store';
import { getCrmUser } from 'redux/selectors';

import { showLoading } from 'services/util'
import { initializeDate } from 'services/util'
import DropDownMenu from 'common/forms/DropDown'
import Alert from 'common/Alert'
import Overlay from '../../components/Overlay'
import { FilledYellow, FilledBlue } from 'css/Buttons'

import { ReportsContainer, ReportsBlock, ReportsListing, YearDropdown, RecognitionSelect, YearHeader, } from '../ReportStyles'

import { getRecognitionSummary } from '../../history/historyapi'
import PrintRecognitionSummary from '../../history/PrintRecognitionSummary'

function ReoognitionSummary(props) {
  const [displayRecognitionSummary, setDisplayRecognitionSummary] = useState(false)
  const [recognitionSummary, setRecognitionSummary] = useState({gifts: [], year: null})
  const [recognitionSummaryYear, setRecognitionSummaryYear] = useState(null)

  const printRecognitionSummary = async (dropdownYear) => {
    const year = dropdownYear !== 'Lifetime recognition' ? dropdownYear : ""
    showLoading(true)
    const gifts = await getRecognitionSummary( year )
    showLoading(false)
    setDisplayRecognitionSummary((gifts && gifts.length > 0) ? true : undefined)
    setRecognitionSummary({ gifts, year })
  }

  const handleRecognitionSummaryYearChange = e => {
    setRecognitionSummaryYear(e.target.value)
  }

  const crmUser = getCrmUser(store.getState())

  const currentYear = initializeDate().getFullYear()
  const recognitionSummaryYears = Array.from({length: currentYear-(crmUser.givingSummary.donorSinceYear-1)}, (x, i) => currentYear-i)
  recognitionSummaryYears.unshift('Lifetime recognition')

  return (
    <>
      <ReportsContainer>
        <ReportsBlock>
          <h3 className="h3">Recognition Summaries</h3>
          <p>Using the dropdown menu below, you can view and print a summary of your recognition by year.</p>
          <p>These summaries encompass all of your recognized giving for each year, including both gifts that are tax-deductible and those that are not.</p>
          <p>Thank you for your support of UCLA!</p>

          <ReportsListing>
            <YearHeader>View recogniton summary by year:</YearHeader>

            <RecognitionSelect>
              <YearDropdown>
                <DropDownMenu
                  className="report-year-dropdown"
                  name="printRecognitionSummary"
                  title={recognitionSummaryYear || currentYear}
                  list={recognitionSummaryYears}
                  changeHandler={handleRecognitionSummaryYearChange}
                />
              </YearDropdown>
              <FilledYellow onClick={e => printRecognitionSummary(recognitionSummaryYear || currentYear)}>View Summary</FilledYellow>
            </RecognitionSelect>

          </ReportsListing>

        </ReportsBlock>
      </ReportsContainer>

      { displayRecognitionSummary &&
        <Overlay useFullModal={true} >
          <PrintRecognitionSummary
            { ...recognitionSummary }
            profile={ crmUser.profile }
            onHideOverlay={ (e) => {
              setRecognitionSummary({gifts: [], year: null})
              setDisplayRecognitionSummary(false)
            }}
          />
        </Overlay>
      }
      { displayRecognitionSummary === undefined &&
        <Alert
          options={<FilledBlue isSmall={ true } onClick={ e => {
            // reset receipt state to hide modal
            setRecognitionSummary({gifts: [], year: null})
            setDisplayRecognitionSummary(false)
          }}>
            Return to History
          </FilledBlue>}
        >
          There are no gifts for { recognitionSummary.year }.
        </Alert>
      }
    </>
  )
}

export default ReoognitionSummary