import { NewGiftStyles } from './shared/SharedStyles'

const PledgePayment = () => (
  <NewGiftStyles>
    <ul>
      <li>If your payment is due within five business days and is noted as an Automatic Pledge Payment,
          then the amount due will automatically be charged to your card on the 15th of the month.</li>
      <li>If your payment is due in more than five business days, you can click on "View Pledge" from your Giving History,
          or the "PLEDGES" menu item to make a payment online.
          To make your payment by mail, please send a check made out to The UCLA Foundation to the address below.
        <div className="ucla-address">
          <p>The UCLA Foundation</p>
          <p>10889 Wilshire Boulevard, Suite 1000</p>
          <p>Los Angeles, CA 90024</p>
        </div>
      </li>
      <li>To provide updated debit or credit card information please click Payment Methods under your initials in the upper right.
          To set up new automatic pledge payments, please call (310) 794-2447.</li>
    </ul>
  </NewGiftStyles>
)

export default PledgePayment