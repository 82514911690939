import React from 'react'
import styled from 'styled-components'

import { device } from 'common/Device'
import { borderGray, primaryBlue } from 'css/Colors'
import { FilledYellow } from 'css/Buttons'
import { initializeDate } from 'services/util'

import { ResetButton } from './HistoryStyles'
import SelectYear from './SelectYear'
import HistorySearch from './HistorySearch'
import DateEntry from './DateEntry'
import { getLimitsForYear } from '../mygivingutil'

const defaultFontSize = 16

const WhiteBackground = styled.div`
  display:flex;
  position:relative;
  height:${ 50/defaultFontSize }rem;
  background:#FFFFFF;
  color:#000000;
  border-radius:${3/defaultFontSize}rem;

  > div {
    height:100%;
    box-sizing:border-box;
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:33.33333%;
    padding:${ 5/defaultFontSize }rem;

    &.has-border {
      position:relative;
      border-right:1px solid ${ borderGray };
    }
  }

  @media ${ device.tablet } {
    height:${ 40/defaultFontSize }rem;
  }

  @media ${ device.mobile } {
    height:auto;
    display:block;

    > div {
      display:inline-block;
      width:50%;
      padding:${ 3/defaultFontSize }rem ${ 3/defaultFontSize }rem ${ 4/defaultFontSize }rem;

      &.select-container {
        display:flex;
        width:100%;
      }

      &.has-border {
        border-right:none;
        &:after {
          display:block;
          position:absolute;
          right:0;
          top:50%;
          content:'';
          height:88%;
          width:1px;
          background:${ borderGray };
          transform:translateY(-50%);
        }
      }
    }
  }
`

const FilterStyles = styled.div`
  box-sizing:content-box;
  max-width:1368px;

  padding:0 calc(36rem/16);

  margin-left:auto;
  margin-right:auto;

  background:#FFFFFF;

  form {
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding: ${ 25/defaultFontSize }rem ${ 25/1368 * 100 }%;
    background-color: ${ primaryBlue };
    box-shadow: 0 ${ 2/defaultFontSize }rem ${ 10/defaultFontSize }rem 0 rgba(0,0,0,0.2);
    border-radius:${ 5/defaultFontSize }rem;
  }

  legend {
    color: #FFFFFF;
  }

  fieldset {
    box-sizing:border-box;
    width:50%;
    border:none;
    margin:0;
    &:first-child {
      padding:0 ${ 15/1368 * 100 }% 0 0;
    }
    &:last-child {
      padding:0 0 0 ${ 15/1368 * 100 }%;
    }
  }

  @media screen and (max-width:1180px) {
    padding-left: 0;
    padding-right: 0;

    form {
      border-radius:0;
      padding:${ 11/defaultFontSize }rem ${ 36/defaultFontSize}rem ${ 25/defaultFontSize }rem;
      flex-direction:column;

      fieldset {
        width:100%;
        &:first-child, &:last-child {
          padding:0;
        }
        &:last-child {
          margin-top:${ 10/defaultFontSize }rem;
        }
      }
    }
  }

  @media ${ device.tablet } {
    form {
      padding-left:${ 24/1024 * 100 }%;
      padding-right:${ 24/1024 * 100 }%;
    }
    legend {
      font-size:${ 15/defaultFontSize }rem;
    }
  }

  @media ${ device.mobile } {
    form {
      padding-left:${ 15/320 * 100 }%;
      padding-right:${ 15/320 * 100 }%;
    }
  }
`

const SearchFilterStyles = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  position:relative;
  width:100%;

  .search-buttons {
    white-space:nowrap;
    padding-right:${ 8/1180 * 100 }%;
    > * {
      margin-right:${ 12/defaultFontSize }rem;
      margin-left:${ 12/defaultFontSize }rem;
    }
  }

  @media ${ device.mobile } {
    display:block;

    .search-buttons {
      padding:0;
      > * {
        display:block;
      }
    }

    ${ FilledYellow } {
      margin:${ 15/defaultFontSize }rem 0 0 0;
      width:100%;
    }

    ${ ResetButton } {
      margin:${ 10/defaultFontSize }rem 0 0 0;
      float:right;
    }
  }
`

class FilterCriteria extends React.PureComponent {
  constructor(props) {
    super(props)

    this.formRef = React.createRef()
    this.state = {
      year: "all",
      searchInput: "", // current value of input
    }

  }

  // hitting enter while entering search filter text acts like a submission
  handleKeyboardInput = (e) => {
    if (e.keyCode && e.keyCode === 13) {
      e.preventDefault()
      this.onApply()
    }
  }

  // handle DatePicker change
  onDateChange = ({ from, to }) => {
    // use parameter, or if not set use bounds instead
    from = from? initializeDate( from ): this.props.from
    to = to? initializeDate( to ) : this.props.to

    this.props.onFilterChange({ from, to })
    this.setState({
      year: "custom"
    })
  }

  // handle year dropdown change
  onYearChange = ( year ) => {
    // if year is numerical
    if (!isNaN(parseInt(year,10))) {
      // get range for year
      let { from, to } = getLimitsForYear( year )

      this.props.onFilterChange({ from, to })
    }

    this.setState({ year })
  }

  // search text changed
  onSearchChange = ( e ) => {
    const searchInput = e.currentTarget.value.length? e.currentTarget.value : ""
    this.setState({ searchInput })
  }

  onReset = () => {
    this.setState({
      year:"all",
      searchInput: "",
    })

    this.props.onReset()
  }

  onApply = e => {
    const { searchInput } = this.state
    const { from, to } = this.props
    this.props.onFilterChange({ from, to, search:searchInput })
  }

  // all this does is reduce initial renders from 5 to 2
  // shouldComponentUpdate(np, ns) {
  //   const { year } = this.state
  //   const { from, to } = this.props
  //
  //   return year !== ns.year || from !== np.from || to !== np.to
  // }

  componentDidUpdate(pp, ps) {
    const { year, searchInput } = this.state
    // default state (clear from and to)
    let filters = { from: this.props.from, to: this.props.to, search: searchInput }
    // trigger updates on parent when filters are updated

    if (
      year !== ps.year
    ) {
      if (!isNaN(parseInt(year,10))) {
        // numerical year
        filters = getLimitsForYear( year )
      } else if (year === 'all') {
        // date range (picker)
        filters.from = null
        filters.to = null
      }

      this.props.onFilterChange( filters )
    }
  }

  render() {
    const { year, searchInput } = this.state
    const { from, to } = this.props

    return (
      <FilterStyles>
        <form onSubmit={ e => e.preventDefault() } ref={ this.formRef }>
          <fieldset>
            <legend id="date_range_search">Search by Year or Date Range</legend>
            <WhiteBackground>
              <div className="select-container">
                <SelectYear onChange={ this.onYearChange } value={ year } />
              </div>
              <div className="has-border">
                <DateEntry
                  label={"From"}
                  htmlName={"dateFrom"}
                  onChange={ date => this.onDateChange({ 'from': date }) }
                  value={ from }
                  formRef={ this.formRef }
                />
              </div>
              <div>
                <DateEntry
                  label={"To"}
                  htmlName={"dateTo"}
                  onChange={ date => this.onDateChange({ 'to': date }) }
                  value={ to }
                  formRef={ this.formRef }
                />
              </div>
            </WhiteBackground>

          </fieldset>
          <fieldset>
            <legend>Filter Keyword or Phrase (optional)</legend>
            <SearchFilterStyles>
              <HistorySearch
                onChange={ this.onSearchChange }
                onKeyDown={ this.handleKeyboardInput }
                value={ searchInput }
              />
              <div className="search-buttons">
                <FilledYellow onClick={ this.onApply }>Apply</FilledYellow>
                <ResetButton onClick={ this.onReset }>Reset</ResetButton>
              </div>
            </SearchFilterStyles>
          </fieldset>
        </form>
      </FilterStyles>
    )
  }
}

export default FilterCriteria
