
import styled from 'styled-components';
import * as buttons from 'css/Buttons'

export const Button = styled(buttons.FilledBlue)`
  display:block;
  width: ${props => props.width || "100%"};
  text-align:center;
`

export const FilledBlueButton = styled(buttons.FilledBlue)`
  width:100%;
`