import { doApi } from 'services/api';

export const submitFeedback = async (event) => {
  event.preventDefault();

  const data = new FormData(event.target);

  data.set('phonenumber', data.get('phonenumber').replace(/[(\-)]/g, ''));

  const contactusData = {
    fullName: data.get('fullname'),
    emailAddress: data.get('email'),
    phoneNumber: data.get('phonenumber'),
    reason: data.get('feedbackreason'),
    comments: data.get('comments'),
    isTest: (data.get('email').indexOf('@test.com') > 0) // used to test Contact Us in prod CRM environment
  };

  const isAuthenticated = data.get('isAuthenticated') === "true";

  const response = await doApi({
    route: isAuthenticated ? 'feedback' : 'feedbackanon',
    method:'POST',
    data:contactusData,
    noAuth:!isAuthenticated,
  });

  return response;
};