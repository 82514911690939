import { useState, useEffect } from 'react'

import { adminGetPreSignedUrlForUpload, adminProcessReportFiles } from "../mggadminutil"
import FileDropzone from 'admin/FileDropzone'
import SelectDocTypeForm from './SelectDocTypeForm'

import { showLoading } from 'services/util'
import Alert from 'common/notifications/Alert'
import { FilledYellow } from 'css/Buttons'
import iconInfo from 'images/iconColorinfo.svg'
import success from 'images/TickMark.svg'
import * as colors from 'css/Colors'
import css from '../sass/upload.documents.module.scss'

import * as acrUtil from './acr_util'
import * as ceirUtil from './ceir_util'
import * as eiarUtil from './eiar_util'

const successPrefix = "All files uploaded successfully:"

const reports = {
  EIAR: {...eiarUtil},
  CEIR: {...ceirUtil},
  ACR: {...acrUtil},
}

function UploadDocuments (props) {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [dropzoneKey, setDropzoneKey] = useState(0)
  const [submitError, setSubmitError] = useState('')
  const [submitSuccess, setSubmitSuccess] = useState('')
  const [reportType, setReportType] = useState('EIAR')

  useEffect(() => {
    if (selectedFiles.length === 0) {
      setSubmitError('')
    }
    else if (selectedFiles.length > 0) {
      setSubmitError(validateFilenames(selectedFiles, reportType))
      setSubmitSuccess('')
    }
  }, [selectedFiles, reportType])

  useEffect(() => {
    setSubmitError('')
    setSubmitSuccess('')
  }, [reportType])


  const validateFilenames = (files, docType) => {
    const errors = [];
    files.forEach(file => {
      reports[docType].validateFile(file, errors)
    })

    return (errors.length === 0) ? '' : (
      <>
        <p>The following filenames were not in the proper format. Please rename the files and try again:</p>
        <ul>
          {errors.map((error, index) => (
            <li key={index}><b>{error.msg}: {error.file.name}</b></li>
          ))}
        </ul>
      </>
    )
  }

  const doSubmitSuccess = (files) => (
    <>
      <b>{successPrefix}</b>
      <ul>
        {files.map((file, index) => (<li key={index}>{file.name}</li>))}
      </ul>
    </>
  )

  const uploadToS3 = async (file, contentType, presignedUrl) => {
    const fetchData = {
      method: 'PUT',
      headers: {
        'Content-Type': contentType,
      },
      body: file
    }

    try {
      const response = await fetch(presignedUrl, fetchData);
      return response;
    }
    catch (e) {
      console.log(e)
    }
  }

  const onClear = async e => {
    setSelectedFiles([])
    setDropzoneKey(dropzoneKey + 1) // hack because dropzone doesn't expose a way to reset itself
  }

  const onSubmit = async e => {

    e.preventDefault()

    if (validateFilenames(selectedFiles, reportType))
      return;

    showLoading(true)

    const uploadResponses = [];
    const uploadErrors = [];
    for (const file of selectedFiles) {
      const presignedUrlResponse = await adminGetPreSignedUrlForUpload({fileName:file.name, reportType:reportType, contentType:file.type})
      const response = await uploadToS3(file, file.type, presignedUrlResponse.presignedUrl)
      if (response && response.ok && response.status === 200)
        uploadResponses.push(response)
      else
        uploadErrors.push({ fileName: file.name, res: (response || {statusCode:'', statusText:'No response'}) })
    }

    if (uploadErrors.length > 0) {
      setSubmitError(doUploadError(uploadErrors))
      showLoading(false)
      return;
    }

    const processResponse = await adminProcessReportFiles(reportType)
    if (processResponse.saveFileSuccess && processResponse.saveFileSuccess.length === selectedFiles.length && processResponse.saveFileError.length === 0) {
      setSubmitSuccess(doSubmitSuccess(selectedFiles))
    }
    else {
      setSubmitError(doProcessError(processResponse))
      showLoading(false)
      return;
    }

    setDropzoneKey(dropzoneKey + 1) // hack because dropzone doesn't expose a way to reset itself

    showLoading(false)
  }

  const doUploadError = (uploadErrors) => (
    <>
      <p>There was an error uploading some of your files</p>
      <ul>
        {uploadErrors.map((error, index) => (
          <li key={index}>{error.fileName}: {error.res.statusText} {error.res.status} </li>
        ))}
      </ul>
    </>
  )

  const doProcessError = (processResponse) => (
    <>
      <p>There was an error processing some of your files</p>

      {!processResponse.saveFileSuccess &&
        <div>Debug info: {JSON.stringify(processResponse,0,2)}</div>}

      {processResponse.saveFileError && processResponse.saveFileError.length > 0 &&
        <ul>
          {processResponse.saveFileError.map((error, index) => (
            <>
              {error.saveFileResponse && error.saveFileResponse.Error &&
                <li key={index}><b>{error.filename}</b> - file Save to DB Error: {error.saveFileResponse.Error.message}</li>}

              {error.processFileResponse && error.processFileResponse.Error &&
                <li key={index*-1}><b>{error.filename}</b> - file Process to S3 Error: {error.processFileResponse.Error.message}</li>}
            </>
          ))}
        </ul>}

      {processResponse.saveFileSuccess && (!processResponse.saveFileError || processResponse.saveFileError.length === 0) &&
        <div>File Save Debug Info: {JSON.stringify(processResponse.saveFileSuccess,0,2)}</div>}
    </>
  )

  return (
    <div className={css.formStyles}>

      <div className={css.formWrapper}>
        <SelectDocTypeForm onChange={setReportType} />
      </div>

      <h3>Upload {reports[reportType].fullName}</h3>

      {reports[reportType].helperText}

      {submitSuccess &&
        <Alert
          nonPromoPage
          icon={success}
          color={colors.guideGreen}
          width="20" height="15" top="40%" >
          <div>{submitSuccess}</div>
        </Alert>
      }

      <form onSubmit={onSubmit}>

        <FileDropzone
          key={'dropzone-' + dropzoneKey}
          setSelectedFiles={setSelectedFiles}
        />

        {submitError &&
          <Alert icon={iconInfo} >
            <div>{submitError}</div>
          </Alert>
        }

        <FilledYellow type="submit" disabled={selectedFiles.length === 0 || submitError}> Upload </FilledYellow>
        <FilledYellow onClick={onClear} disabled={selectedFiles.length === 0}> Clear </FilledYellow>
      </form>

    </div>
  )
}

export default UploadDocuments;
