import { Component } from 'react'

import { ThemeContext } from 'ThemeContext';

import UserIcon from './UserIcon'
import styled from 'styled-components'

const AdminName = styled.div`
  div:nth-child(1) {
    margin: 0 auto;
    text-align: center;
  }
  p {
    color: #fff;
    font-size: 1rem;
  }
  .admin-name-icon-display {
    padding-top: 20px;
  }
`

class AdminSideBar extends Component {
  render() {
    const AdminViewList = this.context.themeAdminViewList

    return (
      <AdminName>
        <div className="admin-name-icon-display">
          <UserIcon> <h1 className="bold">{this.props.userinfo.initials}</h1></UserIcon>
          {this.props.userinfo.fullname &&
            <p className="bold">{this.props.userinfo.fullname}</p>
          }
        </div>
        <AdminViewList />
      </AdminName>
    )
  }
}

export default AdminSideBar;
AdminSideBar.contextType = ThemeContext;