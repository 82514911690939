import RedWarningIcon from '../../../images/iconColorinfo.svg'

import css from '../payment/sass/update.module.scss'

function InputWarning(props) {
  if (!props.show) {
    return (
        <img src={RedWarningIcon} alt='WarningIcon' className={css['input-warning-icon']} />
    )
  } else {
    return null;
  }
}

export default InputWarning;