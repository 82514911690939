
export const getAuctionUser = store => {
  return store.auctionUser
}

export const getBidSubscriptionResponse = store => {
  return store.bidSubscriptionResponse
}

export const getLiveAuctionCode = store => {
  return store.liveAuctionCode
}

