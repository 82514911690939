

import { formatMoneyCents } from 'services/util'

import css from './sass/checkout.module.scss'
import { useEffect, useState } from 'react'
import { getImageUrl } from '../util/images'

export default function CheckoutItem( {item, auction} ) {
  
  const [imageUrl, setImageUrl] = useState()

  // use effect for auction banner image
  useEffect(() => {
    const url = getImageUrl(auction?.AuctionCode, item?.ItemCode, item?.ImageFilename)
    setImageUrl(url)
  }, [auction])
  
  if (!item) return <></>


  console.log(item)
  return (
    <div className={css['checkout-item-container']}>
      <div className={css['checkout-item-container-left']}>
        <img className={css['checkout-item-img']} src={imageUrl} alt={item.Title} />
        <h5 className={css['checkout-item-title']}>{item.Title}</h5>
      </div>
      <p className={css['checkout-item-price']}>{`$${formatMoneyCents(item?.CurrentBid)}`}</p>
    </div>
  )
}




