import { useState } from 'react'
import { saveAlumniEmail } from '../alumniapi'
import { useDispatch } from "react-redux"
import { setIsLoading } from "redux/actions"
import { FilledBlue } from 'css/Buttons'
import css from '../sass/email.module.scss'


function Create({ firstName, keyName }) {
  const dispatch = useDispatch()
  const [ email, setEmail ] = useState()
  const [ password, setPassword ] = useState('')
  const [ confirm, setConfirm ] = useState('')
  const [ error, setError ] = useState('Required.')

  const [ showPassword, setPasswordVisibility ] = useState(false)
  const [ showConfirm, setConfirmVisibility ] = useState(false)

  function submitForm(e) {
    e.preventDefault()

    // make sure email ends in aluni.ucla.edu
    if (/^.+@alumni\.ucla\.edu$/.test(email)) {
      // make sure confirm and password match
      if (password === confirm && password !== '') {
        dispatch(setIsLoading(true, "AlumniEmail"))

        // use the API
        saveAlumniEmail({
          email,
          password,
          firstName,
          lastName: keyName

        }).then( response => {
          dispatch(setIsLoading(false, "AlumniEmail"))
          if (response.Success === email) {
            setError('Your email address has been created.')

          } else {
            switch (response.Success) {
              case 'EMAIL_ALREADY_EXISTS':
                setError('That email already exists.')
              break;

              case 'NO_GOOGLE_ACCOUNT':
                setError('Please check that all form fields are complete.')
              break;

              case 'FAILED_CREATE':
                setError('Could not create that email address.')
              break;

              default:
                setError('We were not able to create an email address.')
                console.log('email create error:', response.Success)
              break;
            }
          }

        }).catch( err => {
          dispatch(setIsLoading(false, "AlumniEmail"))
          setError('Something unexpected has happened.')
          console.log('email create broke:', err)
        })

      } else {
        setError('Please confirm your password.')
      }

    } else {
      setError('Email must end in @alumni.ucla.edu.')
    }
  }

  return (
    <form
      className={css['alumni-email']}
      onSubmit={submitForm}
    >
      <div className={css.spacer}>
        <label htmlFor='alumniemail'>Alumni Email</label>
        <input
          type='email'
          name='alumniemail'
          autoComplete='off'
          placeholder='joebruin@alumni.ucla.edu'
          className={css.input}
          onChange={ e => setEmail(e.currentTarget.value) }
        />
        <span className='error'>{ error }</span>
      </div>

      <div className={css.spacer}>
        <label htmlFor='alumnipassword'>Password</label>
        <input
          type={showPassword? 'text' : 'password'}
          name='alumnipassword'
          autoComplete='off'
          placeholder='Password for the new account'
          className={css.input}
          onChange={ e => setPassword( e.currentTarget.value ) }
        />
        <div className={css.password}>
          <input
            type='checkbox'
            name='show-password'
            className={css['show-password']}
            onClick={ e => setPasswordVisibility(!showPassword) }
          />
          <label htmlFor='show-password'>Show Password</label>
        </div>

      </div>

      <div className={css.spacer}>
        <label htmlFor='confirm'>Confirm</label>
        <input
          type={showConfirm? 'text' : 'password'}
          name='confirm'
          autoComplete='off'
          placeholder='Confirm the password entered above'
          className={css.input}
          onChange={ e => setConfirm( e.currentTarget.value ) }
        />

        <div className={css.password}>
          <input
            type='checkbox'
            name='show-confirm'
            className={css['show-password']}
            onClick={ e => setConfirmVisibility(!showConfirm) }
          />
          <label htmlFor='show-confirm'>Show Password</label>
        </div>
      </div>

      <p>
        <FilledBlue isSmall={true}> Submit </FilledBlue>
      </p>
      <p>If you have any questions, please call <a href="tel:3108252586">310-825-2586</a> Monday through Friday between 8 a.m. and 5 p.m. PT or email <a href="mailto:alumni@alumni.ucla.edu">alumni@alumni.ucla.edu</a>.</p>
    </form>
  )
}

export default Create
