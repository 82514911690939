import { checkoutRouter } from './checkoutUtils'

import blueX from '../auctions/images/x-primary-blue.svg'

import css from './sass/checkout.module.scss'

export default function CheckoutHeader( {auctionCode}) {
  
  if (!auctionCode) return (<></>)
  
  return (
    <div>

      <div className={css['checkout-heading']}>
        <h2>Checkout</h2>
        <img src={blueX} className={css['checkout-heading-img']}
            onClick ={() => checkoutRouter(auctionCode)}
          />
      </div>
      <div className={css['checkout-linebreak']}></div>

    </div>
  )
}