import { useSelector } from "react-redux";
import { getLoggedInUser } from 'redux/selectors';

import olgHeader from '../sass/navigation.module.scss'

function OlgHeader (props) {
  const loggedInUser = useSelector(getLoggedInUser);
  return (
    <>
    {loggedInUser && !loggedInUser.crmUser &&
      <div className={olgHeader.container}>
        <button className={olgHeader['return-link']} onClick={() => window.close()}>Continue as Guest</button>
      </div>}
    {(!loggedInUser || loggedInUser.crmUser) &&
      <div className={olgHeader.container}>
        <button className={olgHeader['return-link']} onClick={() => window.close()}>Return to UCLA Giving</button>
      </div>}
    </>
  )
}

export default OlgHeader;

