import { StyledMenu, StyledNavLink } from 'common/header/MenuStyles'
import { useSelector } from 'react-redux';
import { getAuctionUser } from '../redux/selectors';

export default function Menu(props) {

  const auctionUser = useSelector(getAuctionUser);

  const auctionsLink = <li><StyledNavLink
                            onClick={props.hideHeaderMenu}
                            className="bold header-links"
                            id="headerAuctionsLink"
                            to="/myauctions">MY AUCTIONS</StyledNavLink></li>

  const itemsLink = <li><StyledNavLink
                            onClick={props.hideHeaderMenu}
                            className="bold header-links"
                            id="headerAuctionsLink"
                            to="/myitems">MY ITEMS</StyledNavLink></li>

  const isAuctionAdmin = localStorage.isAuctionAdmin && !sessionStorage.altCrmId // don't show admin link when browsing as another user
  const adminLink = isAuctionAdmin && <li><StyledNavLink
                                            onClick={props.hideHeaderMenu}
                                            className="bold header-links"
                                            id="headerAdminLink"
                                            to="/admin/listpreviewauctions">ADMIN</StyledNavLink></li>

  return (
    <>
      {auctionUser?.UserId && <StyledMenu id="headerLinks">
        {adminLink}
        {auctionsLink}
        {itemsLink}
      </StyledMenu>}
    </>
  )
}