import React, { useState } from 'react'

import css from './sass/itemcard.module.scss'
import heartOutline from '../components/images/heart-outline.svg'
import heartFilled from '../components/images/heart-filled.svg'

export default function FavoriteItem( {item} ) {

  const [favorited, setFavorited] = useState(Math.random() < 0.5)

  let displayText = ''
  let displayImg

  if (favorited) {
    displayText = 'Favorited'
    displayImg = heartFilled
  } else {
    displayText = 'Add as Favorite'
    displayImg = heartOutline
  }

  const flipFavorite = () => {
    //TO DO - logic to flip
    setFavorited(!favorited)
  }
  

  return (
    <div className={css['favorite']} onClick={() => flipFavorite()}>
      <img className={css['auction-ended-paiditems-img']} src={displayImg} />
      <p>
        {displayText}
      </p>
    </div>
  )
}
