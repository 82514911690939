import { useState, useEffect } from 'react'
import { useMutation } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import { graphql } from '../api'
import { updateAuctionMutation } from '../graphql/Mutations'

import css from './sass/createedit.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import UploadFiles from './UploadFiles'
import { getAuctionStatus, forceTimezone } from '../util/dateString'
import { getImageUrl } from '../util/images'

export default function AuctionEdit({ auction, refreshAdminAuctionQuery}) {
  console.log('auction', auction)
  const [auctionCode, setAuctionCode] = useState(auction?.AuctionCode)
  const [auctionLocation, setAuctionLocation] = useState(auction?.AuctionLocation)
  const [bannerImage, setBannerImage] = useState(auction?.BannerImageName)
  const [description, setDescription] = useState(auction?.Description)
  const [endTime, setEndTime] = useState(auction?.EndTime.substring(0,16))
  // const [minimumRaise, setMinimumRaise] = useState(auction?.MinimumRaise)
  const [needsCheckin, setNeedsCheckin] = useState(auction?.NeedsCheckin || false)
  const [startTime, setStartTime] = useState(auction.StartTime.substring(0,16))
  const [title, setTitle] = useState(auction?.Title)
  const [organizerAddress, setOrganizerAddress] = useState(auction?.OrganizerAddress)
  const [organizerLogo, setOrganizerLogo] = useState(auction?.OrganizerLogoName)
  const [organizerName, setOrganizerName] = useState(auction?.OrganizerName)
  const [organizerWebsite, setOrganizerWebsite] = useState(auction?.OrganizerWebsite)
  const [supportEmail, setSupportEmail] = useState(auction?.SupportEmail)
  const [supportPhone, setSupportPhone] = useState(auction?.SupportPhone)
  const [fundName, setFundName] = useState(auction?.FundName)
  const [fundNumber, setFundNumber] = useState(auction?.FundNumber)
  const [marketingEffort, setMarketingEffort] = useState(auction?.MarketingEffort)

  const [needsRequired, setNeedsRequired] = useState(false)
  const [disableEdit, setDisableEdit] = useState(true)
  const [bannerUrl, setBannerUrl] = useState('')

  const liveStatus = getAuctionStatus(auction?.StartTime, auction?.EndTime)
  
  let isReadOnly = auction.Status === 'CLOSED' || liveStatus !== 'before'
  
  const italicLabel = '<i>text to italicize</i>'

  const {data: updateAuctionResponse, error, isPending, mutate: saveAuction } = useMutation({
    mutationFn: (input) => {
      return graphql({query: updateAuctionMutation, variables: {input: input} })
    },
  })

  useEffect(() => {
    if (auctionCode !== auction.AuctionCode ||
    auctionLocation !== auction.AuctionLocation ||
    description !== auction.Description ||
    endTime !== auction.EndTime ||
    // minimumRaise !== auction.MinimumRaise ||
    needsCheckin !== auction.NeedsCheckin ||
    startTime !== auction.StartTime ||
    title !== auction.Title ||
    organizerAddress !== auction.OrganizerAddress ||
    organizerLogo !== auction.OrganizerLogoName ||
    organizerName !== auction.OrganizerName ||
    organizerWebsite !== auction.OrganizerWebsite ||
    fundName !== auction.FundName ||
    fundNumber !== auction.FundNumber ||
    marketingEffort !== auction.MarketingEffort ||
    supportPhone !== auction.SupportPhone ||
    supportEmail !== auction.SupportEmail) {
      setDisableEdit(false)
    } else setDisableEdit(true)
  }, [auction, auctionCode, auctionLocation, bannerImage, description, endTime, needsCheckin, startTime, title,
      organizerAddress, organizerLogo, organizerName, organizerWebsite, supportEmail, supportPhone, fundName, fundNumber, marketingEffort])

  useEffect(() => {
    if (supportEmail === "" || auctionCode === "" || fundName === "" || fundNumber === "" || marketingEffort === "") setNeedsRequired(true)
    else setNeedsRequired(false)
  }, [supportEmail, auctionCode, fundName, fundNumber, marketingEffort])

  //needed to refresh the auction details from inside upload files component properly
  useEffect(() => {
    setBannerImage(auction.BannerImageName) // fine maybe?
  }, [auction])

  useEffect(() => {
    showLoading(isPending)

    if (error) {
      console.log("Auction not created: ", error)
      console.log(error.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (updateAuctionResponse) {
      console.log(updateAuctionResponse)
      console.log('Auction updated: ', updateAuctionResponse.data.updateAuction.AuctionId)
      refreshAdminAuctionQuery()
        //trim after submission to update input fields
        setTitle(title.trim())
        setStartTime(startTime.trim())
        setOrganizerWebsite(organizerWebsite.trim())
        setSupportEmail(supportEmail.trim())
        setSupportPhone(supportPhone.trim())
        setFundName(fundName.trim())
        setFundNumber(fundNumber.trim())
        setMarketingEffort(marketingEffort.trim())
        setOrganizerName(organizerName.trim())
        setOrganizerLogo(organizerLogo.trim())
        setOrganizerAddress(organizerAddress.trim())
        // setMinimumRaise(minimumRaise)
        setNeedsCheckin(needsCheckin)
        setEndTime(endTime.trim())
        setBannerImage(bannerImage.trim())
        setDescription(description.trim())
        setAuctionLocation(auctionLocation.trim())
    }
  }, [updateAuctionResponse, error, isPending, refreshAdminAuctionQuery, title, startTime, organizerWebsite,
    supportEmail, supportPhone, fundName, fundNumber, marketingEffort, organizerName, organizerLogo,
    organizerAddress, endTime, bannerImage, description, auctionLocation, needsCheckin])


  // use effect for auction banner image
  useEffect(() => {
    const url = getImageUrl(auction?.AuctionCode, 'banner', bannerImage)
    setBannerUrl(url)
  }, [auction, bannerImage])

  const toggleCheck = () => {
    let newValue = !needsCheckin
    setNeedsCheckin(newValue)
  }

  const onSubmit = async () => {

    const pacificStartTime = forceTimezone(startTime.trim(), "America/Los_Angeles")
    const pacificEndTime = forceTimezone(endTime.trim(), "America/Los_Angeles")

    const input = {
        Title: title.trim(),
        StartTime: pacificStartTime,
        OrganizerWebsite: organizerWebsite.trim(),
        SupportEmail: supportEmail.trim(),
        SupportPhone: supportPhone.trim(),
        FundName: fundName.trim(),
        FundNumber: fundNumber.trim(),
        MarketingEffort: marketingEffort.trim(),
        OrganizerName: organizerName.trim(),
        OrganizerLogoName: organizerLogo.trim(),
        OrganizerAddress: organizerAddress.trim(),
        // MinimumRaise: minimumRaise,
        NeedsCheckin: needsCheckin,
        EndTime: pacificEndTime,
        BannerImageName: bannerImage.trim(),
        Description: description.trim(),
        AuctionLocation: auctionLocation.trim(),
        // AuctionCode: auctionCode
        AuctionId: auction.AuctionId,
        Status: auction.Status
      }
    saveAuction(input)
  }


  return (
    <div className={css['edit-container']}>
      <p className={css['label-notice']}><span className={css['asterisk']}>*</span> denotes required fields</p>
      <div className={css['item-container']}>
        <div className={css['edit-item-input']}>
          <label>Title</label>
          <input type='text'
            placeholder='e.g. UCLA Auction'
            value={title}
            disabled={isReadOnly}
            onChange={(e) => setTitle(e.target.value)}/>  
        </div>
        <div className={css['edit-item-input']}>
          <label>Description</label>
          <label> - for weblinks use this format: <b>[link text](https://example.com)</b></label>
          <label> - for italics use this format: <b>{italicLabel}</b></label>
            <textarea 
              placeholder='Description of the auction goes here ...'
              value={description}
              disabled={isReadOnly}
              rows="6"
              onChange={(e) => setDescription(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Auction Location</label>
            <input type='text'
              placeholder='e.g. Los Angeles, CA'
              value={auctionLocation}
              disabled={isReadOnly}
              onChange={(e) => setAuctionLocation(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Auction Code<span className={css['asterisk']}>*</span> <span className={css['label-notice']}>(can not be edited after initialization)</span></label>
            <input type='text'
              value={auctionCode}
              disabled={true}
              onChange={(e) => setAuctionCode(e.target.value)}/>
        </div>
        {/* <div className={css['edit-item-input']}>
          <label>Minimum Raise (in dollars)</label>
            <input type='text'
              data-type="currency"
              value={minimumRaise}
              disabled={isReadOnly}
              onChange={(e) => setMinimumRaise(e.target.value)}/>
        </div> */}
        <div className={css['edit-item-input']}>
          <label>Start Time (Pacific Time)</label>
            <input type='datetime-local'
              disabled={isReadOnly}
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>End Time (Pacific Time)</label>
            <input type='datetime-local'
              disabled={isReadOnly}
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}/>
        </div>

        <div className={css['create-item-input-checkbox']}>
          <input type='checkbox'
            checked={needsCheckin}
            disabled={isReadOnly}
            onChange={(e) => {toggleCheck()}
            }/> Auction requires check in
          <p>Checking this box will generate a QR code for the attendee. This QR code will be required to be scanned at the event in order for the attendee to participate in the auction. Check this box for events requiring a ticket for physical in-person auction events.</p>
          <p>Leaving this box unchecked will automatically check the user in upon registration. Leave the box unchecked if the auction will be open online for bidding before the physical event occurs, or for free events.</p>
        </div>

        <h5>Organizer Information</h5>
        <div className={css['edit-item-input']}>
          <label>Organizer Address</label>
            <input type='text'
              placeholder='e.g. UCLA Luskin Conference Center'
              value={organizerAddress}
              disabled={isReadOnly}
              onChange={(e) => setOrganizerAddress(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Organizer Name</label>
            <input type='text'
              placeholder='e.g. UCLA Geffen School of Medicine'
              value={organizerName}
              disabled={isReadOnly}
              onChange={(e) => setOrganizerName(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Organizer Website</label>
            <input type='text'
              placeholder='e.g. www.ucla.edu'
              value={organizerWebsite}
              disabled={isReadOnly}
              onChange={(e) => setOrganizerWebsite(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Contact Email for Auction Support<span className={css['asterisk']}>*</span></label>
            <input type='text'
              required
              placeholder='e.g. ???@ucla.edu'
              value={supportEmail}
              disabled={isReadOnly}
              onChange={(e) => setSupportEmail(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Support Phone</label>
            <input type='text'
              placeholder='e.g. 310-???-????'
              value={supportPhone}
              disabled={isReadOnly}
              onChange={(e) => setSupportPhone(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Fund Name<span className={css['asterisk']}>*</span></label>
            <input type='text'
              placeholder='e.g. E. Richard Brown Health Policy Impact Fund'
              value={fundName}
              disabled={isReadOnly}
              onChange={(e) => setFundName(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Fund Number (without the '- HZ')<span className={css['asterisk']}>*</span></label>
            <input type='text'
              placeholder='e.g. 63090C'
              value={fundNumber}
              disabled={isReadOnly}
              onChange={(e) => setFundNumber(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Marketing Effort (should start with 'M-')<span className={css['asterisk']}>*</span></label>
            <input type='text'
              placeholder='e.g. M-45735'
              value={marketingEffort}
              disabled={isReadOnly}
              onChange={(e) => setMarketingEffort(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Banner Image Upload <span className={css['acceptable-text']}>(Accepted file types: .jpg, .jpeg, .png) </span></label>
          <div>
            <p>Current filename: {bannerImage}</p>
            <div className={css['thumbnail-container']}>
              <img src={bannerUrl} alt={`${auction.Title} banner thumbnail`}/>
            </div>
            <UploadFiles imageType={'auction'} auction={auction} 
              refreshQuery={refreshAdminAuctionQuery} pageType={'editAuction'} />
          </div>
        </div>
      </div>
      <button disabled={isReadOnly || disableEdit || needsRequired} className={`${themeCss['FilledBlue']} ${themeCss['button']} ${themeCss['centered-button']}`} onClick={() => onSubmit()} type='submit'>Save Auction</button>
      {needsRequired && <p className={css['label-notice']}>Make sure all required fields are entered</p>}
    </div>
  )
}