import { Component } from 'react';
import { withRouter } from 'react-router';
import { Auth } from 'aws-amplify';

import store from 'redux/store';
import { getLoggedInUser } from 'redux/selectors';
import { ThemeContext } from 'ThemeContext';
import { doCrmUser } from "authentication/helper";
import { showLoading } from 'services/util';

import UserNavigation from './UserNavigation';

import * as hs from './HeaderStyles';
import { tabletDownMedia } from 'common/DeviceDetect';
import * as colors from 'css/Colors';
import close from 'images/Close.svg';

import MobileHeaderMenuIcon from 'images/MobileHeaderMenuIcon.svg';
import warningIcon from 'images/icon-warning.svg';

import css from '../../sass/header.module.scss'
import { combineClasses } from 'services/util'

class Header extends Component {

  state = {
    showHeaderMenuOptions: true,
    showMenuIcon: true,
    showHeaderMenu: true,
  }

  async componentDidMount() {
    this.handleResize()
    this.setState({'logo': this.context.logoImage})
    tabletDownMedia.addListener(this.handleResize)
  }

  componentWillUnmount() {
    tabletDownMedia.removeListener(this.handleResize)
  }

  handleResize = () => {
    const showMenuIcon = tabletDownMedia.matches
    const showHeaderMenuOptions = !showMenuIcon

    this.setState({
      showMenuIcon,
      showHeaderMenuOptions
    })
  }

  showHeaderMenu = () => {
    this.setState({
      showHeaderMenuOptions: !this.state.showHeaderMenuOptions,
      showMenuIcon: !this.state.showMenuIcon
    })
  }

  hideHeaderMenu = async () => {
    if (tabletDownMedia.matches)
      await this.setState({ showHeaderMenu: false, showHeaderMenuOptions:false, showMenuIcon:true })
  }

  changePassword = async (event) => {
    event.preventDefault();

    await this.hideHeaderMenu();

    const username = this.props.authenticatedUser.cognitoUser.username;
    try {
      await Auth.forgotPassword(username);
      this.props.history.push({ pathname: '/resetpasswordfinish', state: { username } });
    }
    catch (err) {
      console.log(err);
    }
  };

  async endBrowseAsUserSession(e, self) {
    e.preventDefault();
    showLoading(true)
    const loggedInUser = getLoggedInUser(store.getState())
    await sessionStorage.removeItem('altCrmId');
    await doCrmUser('/admin/browseasuser', loggedInUser.cognitoUser);
  }

  render() {
    const Menu = this.context.themeMenu
    const isPhone = tabletDownMedia.matches
    const crmUser = this.props.authenticatedUser ? this.props.authenticatedUser.crmUser : null
    const proxyFirstName = (sessionStorage.altCrmId && crmUser) ? crmUser.profile.primaryName.firstName : null;
    const proxyLastName = (sessionStorage.altCrmId && crmUser) ? crmUser.profile.primaryName.keyName : null;
    const hideNavLinks = this.context.hideNavLinks || function() { return false }

    const userNavigation = this.props.authenticatedUser ?
      <UserNavigation
        changePassword={this.changePassword}
        authenticatedUser={this.props.authenticatedUser}
        isPhone={isPhone}
        closeMenu={this.showHeaderMenu}
        hideHeaderMenu={this.hideHeaderMenu}
        appName={this.context.appName}
      />
      : (this.props.isUnderConstruction === 'YES') ?  '' :
      <hs.NavigationLinkHeader
        onClick={this.hideHeaderMenu}
        className="bold header-links"
        to="/signin"
        activeStyle={{ color: colors.primaryBlue }}>
        SIGN IN
      </hs.NavigationLinkHeader>

    const hideHeaderMenu = isPhone ? this.hideHeaderMenu : function() {};

    return (
      <hs.HeaderOverlay className="hide-on-print" proxyBarVisible={sessionStorage.altCrmId}>

        {sessionStorage.altCrmId &&
          <hs.ProxyWarning>
            <hs.ErrorIcon src={warningIcon}/>
            <span>You are currently browsing as {proxyFirstName} {proxyLastName}.
            &nbsp;<a onClick={e => {this.endBrowseAsUserSession(e, this)}} href="">Back to admin</a></span>
          </hs.ProxyWarning>}

        {this.context.themeHeader}

        <div className={combineClasses(css, 'shadow', 'header-main')}>
          <hs.HeaderDiv>
            <div className="logo">
              <hs.DashboardImage
                src={this.state.logo}
                alt='Home'
                onClick={ e => { this.props.history.push("/") } }
              />
            </div>
            {isPhone && <div className="menu-icon" onClick={this.showHeaderMenu}>
              {this.state.showMenuIcon ? <img src={MobileHeaderMenuIcon} alt='' /> : <img src={close} alt='' />}
            </div>}
          </hs.HeaderDiv>
          {this.state.showHeaderMenuOptions &&
          <hs.NavBar>
            {!hideNavLinks() &&
              <Menu
                authenticatedUser={this.props.authenticatedUser}
                hideHeaderMenu={hideHeaderMenu}
                hasMultipleAccounts={this.props.hasMultipleAccounts}
              />}
              <hs.ProfileContainer>
              {userNavigation}
            </hs.ProfileContainer>
          </hs.NavBar>}
        </div>
      </hs.HeaderOverlay>
    )
  }
}

export default withRouter(Header);
Header.contextType = ThemeContext;
