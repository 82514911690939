import { useState, useEffect, useContext } from 'react'

import * as api from './paymentapi'
import store from 'redux/store'
import { history } from 'services/history.js'
import { getLoggedInUser } from 'redux/selectors'
import { setLoggedInUser, setCrmUser } from 'redux/actions'
import { showLoading } from 'services/util'
import { ThemeContext } from 'ThemeContext'

import UpdatePaymentForm from './UpdatePaymentForm'

import css from '../payment/sass/update.module.scss'
import PaymentMethodsCopy from './PaymentMethodsCopy'

function UpdatePayment(props) {
  const theme = useContext(ThemeContext)
  const [clientInstance, setClientInstance] = useState()
  const [loadError, setLoadError] = useState()
  const [hasRecurringGift, setHasRecurringGift] = useState(false);
  const [hasPledge, setHasPledge] = useState(false);

  useEffect(() => {
    const cardGiftTypes = props.location.state?.associatedGifts.map((gift) => gift.revenueTypeFull)
    if (cardGiftTypes && cardGiftTypes.indexOf('Recurring Gift') > -1) setHasRecurringGift(true)
    if (cardGiftTypes && cardGiftTypes.indexOf('Pledge') > -1) setHasPledge(true)
  }, [props])

  useEffect(() => {
    const formLoadError = 'Oops something went wrong. Please try again or contact Donor Relations support at ' + theme.supportPhone + '.';
    showLoading(true)

    async function setup() {
      try {
        setClientInstance(await api.getBraintreeClientInstance())
        showLoading(false)
      }
      catch (e) {
        setLoadError(formLoadError)
        console.error({msg: 'Error loading payment form.', e})
        showLoading(false)
      }
    }

    if (sessionStorage.altCrmId)  {
      setLoadError("Entering a donor's credit card information in Browse as User mode is not allowed due to PCI compliance.")
      showLoading(false)
    }
    else {
      setup()
    }

    return () => {
      setClientInstance();
      // setLoadError(formLoadError)
    }
  }, [theme])

  // if user deep links w/o selecting a card, send them back to the payment methods page
  if (!props.location.state?.cardId) {
    history.push('/paymentmethods')
    return <></>
  }

  const loggedInUser = getLoggedInUser(store.getState())
  const cardsOnFile = loggedInUser.crmUser.creditCards;

  let loadCard = {};

  if (props.location?.state?.cardId) {
    for (let i = 0; i < cardsOnFile.length; i++) {
      if (cardsOnFile[i].uniqueIdentifier === props.location.state.cardId) {
        loadCard = cardsOnFile[i];
        break;
      }
    }
  }

  const updateLoggedInUser = (newCard) => {
  
    //extract data to update loggedInUser
    const { cognitoUser, crmUser } = loggedInUser;

    //find the index of the card in the array to replace
    let cardIndexToReplace;
    crmUser.creditCards.forEach((card, index) => {
      if (card.uniqueIdentifier === loadCard.uniqueIdentifier) {
        cardIndexToReplace = index;
      }
    });

    //update creditcard in array
    crmUser.creditCards[cardIndexToReplace] = newCard;
    
    //update the props for /payment form
    props.location.state.cardId = newCard.uniqueIdentifier;

    // update the store for loggedInUser
    store.dispatch(setLoggedInUser({cognitoUser, crmUser}));

    // this function is just updating local storage and front end elements, errors handled before reaching updateloggedInUser function
    // route to successful page
    history.push({ pathname: '/paymentupdate', state: { updatedCard: true} });
  };

  return (
    <div>
      <h1 className={css.title}>Update Credit Card</h1>

      {loadError && <p className={css.copy}>{loadError}</p>}

      {clientInstance &&
        <>
          <p className={css.copy}>
            <PaymentMethodsCopy hasPledge={hasPledge} hasRecurringGift={hasRecurringGift} />
          </p>
            {props.location.state?.cardId &&
              <UpdatePaymentForm
                clientInstance={clientInstance}
                card={loadCard}
                associatedGifts={props.location.state?.associatedGifts}
                updateLoggedInUser={updateLoggedInUser}
                loadError={loadError} />}
        </>}
    </div>
  )
}

export default UpdatePayment