
import styled from 'styled-components';

export const NewGiftStyles = styled.div`
  ul {
    padding: 0 ${10/16}rem;
  }
  li {
    list-style-type: disc;
    margin:10px;
    p:nth-child(1){
      margin:0
    }
    p:nth-child(3){
      margin-bottom:0
    }
  }
  p:has(strong) {
    margin-bottom: ${5/16}rem;
  }
  .first-paragraph {
    margin-top:0;
  }
  h3 {
    margin-top: ${8/16}rem;
  }
  h5 {
    margin-bottom: ${8/16}rem;
  }
  .ucla-address {
    text-align:left;
    margin:0 50px;
    p {
      margin:0;
    }
  }
`
