import * as cs from 'css/CommonStyle';
import css from '../sass/ucla1id.module.scss'

function Ucla1IdCrmResults(props) {

    const user = props.user

    // if user.name is present it's a spouse user
    let spouseName
    if (user.name) {
      spouseName = {...user.name}
      delete spouseName.nameType
      delete spouseName.suffix1
      delete spouseName.suffix2
      delete spouseName.dateModified
    }

    return (
      <div className={css["crmUserLayout"]}>
        {!user.error &&
        <cs.TextInputdiv>
          <label>CRM ID: </label>
          <label className="bold">{user.constituentLookupId}</label>
        </cs.TextInputdiv>}

        {!user.name && !user.error &&
          <cs.TextInputdiv>
            <label>UCLA One User ID: </label>
            <label className="bold">{user.uclaOneUserId || "None"}</label>
          </cs.TextInputdiv>}

        {!user.name && !user.error &&
          <cs.TextInputdiv>
            <label>UID: </label>
            <label>{user.constituentUID || "None"}</label>
          </cs.TextInputdiv>}

        {user.name && !user.error &&
          <cs.TextInputdiv>
            <label className="bold">User Info: </label>
            <label>{JSON.stringify(spouseName,0,2).replaceAll('"','').replace('{','').replace('}','')}</label>
          </cs.TextInputdiv>}

        {user.error &&
          <cs.TextInputdiv>
            <label className="bold">Error: </label>
            <label>{user.error}</label>
          </cs.TextInputdiv>}
      </div>

    )
}

export default Ucla1IdCrmResults;