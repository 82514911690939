import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { getProfile } from 'redux/selectors'
import store from 'redux/store'

import { showLoading, initializeDate } from 'services/util'
import { analyticsYearReceipt, analyticsSingleReceipt } from '../analytics'
import Overlay from '../components/Overlay'
import Alert from 'common/Alert'

import { getLimitsForYear } from '../mygivingutil'
import { getHistoryList, getSummary, getReceiptBio,
         getInstallment, getSchedule, getReceipts, logPrintReceipt, getRecognitionSummary } from './historyapi'
import { NoResults, HistoryIntro, HistoryTitle, HistoryLayout, HistoryLayoutColumn } from './HistoryStyles'
import HistorySummary from './HistorySummary'
import FilterCriteria from './FilterCriteria'
import HistoryNested from './HistoryNested'
import PrintReceiptPreview from './PrintReceiptPreview'

import { getDocumentList, getFundReports } from '../reports/documentsapi'

import { StrokeBlue, FilledBlue } from 'css/Buttons'
import { lightGray } from 'css/Colors'
import { device } from 'common/Device'

const defaultFontSize = 16

const Wrapper = styled.div`
  background:#FFFFFF;
  padding-bottom:${ 66/defaultFontSize }rem;

  @media ${ device.tablet } {
    background:${ lightGray };
  }
`
const LayoutWrapper = styled.div`
  padding-top:${ 12/defaultFontSize }rem;

  @media ${ device.mobile } {
    order:2;
    .hide-on-mobile {
      display:none;
    }
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing:content-box;
  margin-left:auto;
  margin-right:auto;
  padding: 0 ${ 35/defaultFontSize }rem ${ 25/defaultFontSize }rem ${ 35/defaultFontSize }rem;
  max-width:1368px;
  background-color:#FFF;

  ${ StrokeBlue } {
    white-space:nowrap;
    font-size:${ 16/defaultFontSize }rem;
    height: ${ 42/defaultFontSize }rem;
    border-width: 2px;

    @media ${ device.mobile } {
      width: 90%;
      margin-left: 1rem;
    }

    @media ${ device.mobile } {
      margin-bottom:1rem;
    }
  }

  @media ${ device.tablet } {
  }
  @media ${ device.mobile } {
    display: block;
    padding:${ 10/defaultFontSize }rem;
  }
`


/*
 * this component's job is to...
 * - make service requests and process returned results
 * - apply filters to data, updating list for display
 */
class GivingHistory extends Component {
  constructor( props ) {
    super( props )
    this.state = {
      // receipts modal - true shows modal, false hides it. undefined means something went wrong.
      displayReceipts: false,
      displayRecognitionSummary: false,
      // filter criteria
      from: initializeDate(),
      to: initializeDate(),
      search: "",
      // service data
      installments: [],
      schedules: [],
      summary: null,
      profile: null,
      bio: null,
      history: [],
      hasPledges: false,
      // for printing
      receipts: {
        gifts: [],
        date: null
      },
    }
  }

  // load data from services
  // default to show all receipt ( no filter )
  componentDidMount = async () => {
    showLoading( true )

    const profile = getProfile(store.getState())

    let hasPledges = false;
    const history = (await getHistoryList()).filter(row => {
      if (row.isPledgeSummary) {
        hasPledges = true
        return false
      }
      else {
        return true
      }
    })

    const summary = getSummary()
    const bio = getReceiptBio()
    const documentList = getDocumentList()
    const fundReports = getFundReports()
    const bounds = this.calculateBounds({ history })

    showLoading( false )

    this.setState({
      isAuthenticated: true,
      history,
      hasPledges,
      summary,
      profile,
      documentList,
      fundReports,
      bio,
      ...bounds,
    })
  }

  // update date bounds when this.state.data changes
  calculateBounds({ to, from, history }) {
    if (history && history.length) {
      // traverse data and calculate new bounds
      history.forEach( item => {
        let date = initializeDate(item.date)

        if (from && to) {
          if (date < from) from = date
          if (date > to) to = date

        } else {
          from = date
          to = date
        }
      })
    }

    return { from, to }
  }

  // preprocess installments and schedules
  formatPledge(installments, schedules, original) {
    // link installment entries to parent pledge
    installments.forEach( item => (item.revenueType === 'PP' || item.revenueType === 'RP' || item.revenueType === 'BQ') ? item.pledge = original : false )

    // ignore sceduledpayments with balance zero, tack everything else on after installments
    const subsection = installments.concat(schedules.filter(row => row.balance !== 0).map(row => {
      row.displayAmount = row.amount
      row.pledge = original
      return row
    }))

    return subsection;
  }

  // applies textual filters to row items
  // this compares search terms to API data, not displayed information, which could be problematic
  compareRowToTerm( row, term ) {
    if ( row.primaryDonorName.toLowerCase().indexOf( term ) > -1
      || row.department.toLowerCase().indexOf( term ) > -1
      || row.designation.toLowerCase().indexOf( term ) > -1
      || row.fundNumber.toLowerCase().indexOf( term ) > -1
      || row.revenueTypeFull.toLowerCase().indexOf( term ) > -1
      || row.paymentMethod.toLowerCase().indexOf( term ) > -1
      || row.recognitionAmount === 1*( term.replace('$','') ))  {

      return true
    } else {
      return false
    }
  }

  // apply filters to history data
  filterHistory( history ) {
    const { from, to, search } = this.state

    const filtered = history.filter((row) => {
      const currentDate = initializeDate( row.date )

      // date filter
      const isInDateRange = (from && to)? ((currentDate >= from) && (currentDate <= to)) : true

      // if (!isInDateRange) console.log( 'history item not date range!', currentDate, from, to, (currentDate >= from), (currentDate <= to) )

      // search filter
      const isValidSearchCriteria = search && search.length? this.compareRowToTerm( row, search.toLowerCase() ) : true

      return isInDateRange && isValidSearchCriteria
    })

    return filtered
  }

  // manipulates state
  setRowStatus = ( id, loading ) => {
    // make shallow copy
    let row = {...this.state.history.find( (item, idx) => (item.revenueLookupId === id ) ), loading }

    // make copy of history and replace row
    let history = this.state.history.map( item => ( (item.revenueLookupId !== id)? item : row ))

    this.setState({ history })

    return row
  }

  // installments
  makeInstallmentCalls = async ( original ) => {
    let installments = [], schedules = []

    const hasInstallments = original.revenueTypeHasInstallments
    const hasSchedules = (original.revenueTypeHasInstallmentSchedule && original.recurringGiftStatus === 'Active')

    // start service calls
    if (hasInstallments && hasSchedules)
      [installments, schedules] = await Promise.all([getInstallment(original.revenueLookupId), getSchedule(original.revenueLookupId)]);
    else if (hasInstallments)
      installments = await getInstallment(original.revenueLookupId);
    else if (hasSchedules)
      schedules = await getSchedule(original.revenueLookupId);

    return { installments, schedules }
  }


  // REFACTOR
  getRecurringData = async ( original ) => {
    // set row to loading
    let row = this.setRowStatus( original.revenueLookupId, true )

    // make service calls
    let { installments, schedules } = await this.makeInstallmentCalls( original )

    let subsection = this.formatPledge(installments, schedules, original)

    // set service data onto new row object, resetting row reference
    row = this.setRowStatus( original.revenueLookupId, false )
    row.subsection = subsection

    // insert updated row into history
    let history = this.state.history.map( item => ( (item.revenueLookupId !== original.revenueLookupId)? item : row ))
    console.log(subsection)
    // set it back into state
    this.setState({ history })
  }

  // event handlers
  printRecognitionSummary = async (year) => {
    showLoading( true )

    // analyticsYearReceipt( year )

    const gifts = await getRecognitionSummary( year )

    showLoading( false )

    this.setState({
      displayRecognitionSummary: (gifts && gifts.length > 0) ? true : undefined,
      recognitionSummary: { gifts, year }
    })
  }

  printReceiptsForYear = async (year) => {
    const date = getLimitsForYear( year )
    showLoading( true )

    analyticsYearReceipt( year )

    const gifts = await getReceipts( year )

    showLoading( false )

    this.setState({
      displayReceipts: (gifts && gifts.length > 0)? true : undefined,
      receipts: { gifts, date }
    })
  }

  printReceipt = async ( original ) => {
    this.setState({ displayReceipts: true })

    analyticsSingleReceipt( original.revenueLookupId, original.date.substring(0,4) )

    let gifts = [ original ]

    this.setState({ receipts: {
      gifts,
      date: null
    }})

    logPrintReceipt(gifts[0].revenueLookupId);
  }

  // FilterCriteria will send parameters to this method
  onFilterChange = ({ from, to, search }) => {
    let bounds = this.calculateBounds({ history: this.state.history })
    // avoid unset date parameters
    this.setState({ from: from || bounds.from, to: to || bounds.to, search: search || "" })
  }

  // reset all filters
  resetFilters = () => {
    let bounds = this.calculateBounds({ history: this.state.history })
    this.setState({ ...bounds, search:"" })
  }

  render() {
    const { profile, summary, from, to, bio } = this.state
    let filteredHistory = this.filterHistory( this.state.history )

    let historyDisplay = <NoResults className="wrapper">
      <h2>No Gifts Found</h2>
      <p>Please adjust date range or refine your keywords.</p>
    </NoResults>

    if (filteredHistory.length) {
      historyDisplay = <HistoryNested
        data={ filteredHistory }
        className={ "wrapper" }
        printReceipt={ this.printReceipt }
        getRecurringData={ this.getRecurringData }
      />
    }

    return (
      <Wrapper>
        <div className="hide-on-print">
          <HistoryIntro className={"wrapper"}>
             <HistoryTitle>
              <h1 id="landingPageTitle">Giving History and Receipts</h1>

               {(this.state.fundReports || this.state.documentList) &&
                  <div><StrokeBlue as="a" href="/reports">View Reports</StrokeBlue></div>
                }
            </HistoryTitle>
          </HistoryIntro>

          <HistoryLayout className={"wrapper"}>
            <LayoutWrapper className={"grid"}>
              <div className={"span-5 t-span-12"}>
                <HistoryLayoutColumn>
                  <p>Your entire giving history is displayed below. However, the ability to view, download, or print receipts is limited to the last two calendar years for gifts personally made by you. If you do not see a receipt for a gift, please contact Donor Relations at 310-794-2447.</p>
                  <p>To view payments on an existing planned gift or recurring gift, click the “More” button on the right to view any payments and print corresponding receipts.</p>

                  {this.state.hasPledges &&
                    <p>To view your current and past pledges, click the "View All Pledges" button below, or click "View Pledge" on any row with a pledge payment.</p>}

                </HistoryLayoutColumn>
              </div>

              <div className="span-7 t-span-12">
                <HistoryLayoutColumn>
                  <HistorySummary
                    summary={ summary }
                    profile={ profile }
                  />
                </HistoryLayoutColumn>
              </div>

            </LayoutWrapper>
          </HistoryLayout>

          <ButtonContainer>
            {this.state.hasPledges &&
              <StrokeBlue
              onClick={() => this.props.history.push('/pledges')}
              >VIEW ALL PLEDGES</StrokeBlue>}

            {!this.state.hasPledges &&
              <div></div>}

            <StrokeBlue
              id="goToPrintSummariesAndReceiptsPage"
              onClick={() => this.props.history.push('/printsummaries')}
              >PRINT SUMMARIES AND RECEIPTS</StrokeBlue>
          </ButtonContainer>

          <FilterCriteria
            from={ from }
            to={ to }
            onFilterChange={ this.onFilterChange }
            onReset={ this.resetFilters }
          />

          { historyDisplay }
        </div>

        { this.state.displayReceipts &&
          <Overlay onHideOverlay={ (e) => {
            // reset receipt state to hide modal
            this.setState({
              receipts: {
                gifts: [],
                date: null
              },
              displayReceipts: false,
            })
          }}>
            <PrintReceiptPreview
              { ...this.state.receipts }
              bio={ bio }
              crmId={ profile.constituentLookupId }
            />
          </Overlay>
        }
        { this.state.displayReceipts === undefined &&
          <Alert
            options={<FilledBlue isSmall={ true } onClick={ e => {
              // reset receipt state to hide modal
              this.setState({
                receipts: {
                  gifts: [],
                  date: null
                },
                displayReceipts: false,
              })
            }}>
              Return to History
            </FilledBlue>}
          >
            There are no printable receipts for { this.state.receipts.date.from.getFullYear() }.
          </Alert>
        }
        </Wrapper>
    );
  }
}

export default withRouter(GivingHistory)
