import { doApi } from 'services/api';
import store from 'redux/store';

export const saveUcla1NonCrmUser = async (formBody) => {
  const loggedInUser = store.getState().loggedInUser

  if (!loggedInUser || !loggedInUser.cognitoUser || !loggedInUser.cognitoUser.username)
    console.error('User not logged in')
  else if (loggedInUser.cognitoUser.username.indexOf('@test.com') > 0)
    console.error('Cant save test user')
  else
     return await doApi({route:'ucla1/savenoncrmuser', method:'POST', data:formBody});
};

export const getPendingStatus = async () => {
  const loggedInUser = store.getState().loggedInUser

  if (!loggedInUser || !loggedInUser.cognitoUser || !loggedInUser.cognitoUser.username)
    console.error('User not logged in')
  else
     return await doApi({route:'ucla1/getpendingstatus'});
};
