import IdmReactTable from "common/IdmReactTable"

import { FilledYellow } from 'css/Buttons'

import { QueryResultsStyles, AdminButtonWrap } from 'admin/QueryStyles'
import { formatDate, formatPhone } from 'admin/adminutil'

function UserSearchResults(props) {

  const tableProps = {
    initialState: {
      sortBy: [
       {
        id: 'EmailOrPhone',
        desc: false
       }
      ]
    },
  }

  const mainTableColumns = [
    { Header: 'CRM ID', desc: false, accessor: 'PrimaryCrmId'},
    { Header: 'Username', desc: false, accessor: 'EmailOrPhone', Cell: cell => formatPhone(cell.value)},
    { Header: 'Last Login', desc: true, accessor: 'LastLogin', Cell: cell => formatDate(cell.value)},
    { Header: 'Date Created', desc: true, accessor: 'DateCreated', Cell: cell => formatDate(cell.value)},
    // { Header: 'Date Updated', desc: true, accessor: 'DateUpdated', Cell: cell => formatDate(cell.value)},
    { Header: 'Apps Used', desc: false, accessor: 'AllApps'},
  ]

  const copyTableToClipboard = () => {
    const textToCopy = [mainTableColumns.map(c => c.Header).join('\t')];
    props.data.forEach(row => {
      textToCopy.push(mainTableColumns.map(c => {
        if (["LastLogin","DateCreated","DateUpdated"].indexOf(c.accessor) > -1)
          return formatDate(row[c.accessor] || '');
        else
          return c.accessor === 'PrimaryCrmId' ? "'" + row[c.accessor] : '' + formatPhone(row[c.accessor] || '');
      })
      .join('\t'))
    })
    navigator.clipboard.writeText(textToCopy.join('\n'))
  }

  return (
      <QueryResultsStyles searchType={'users'}>
      {props.data.length > 0 &&
        <IdmReactTable
          { ...tableProps }
          data={ props.data }
          columns={ mainTableColumns }
        />}

      <section>
        <div>
          <span>Total Results: </span>{props.data.length}
          {props.data.length > 0 &&
            <AdminButtonWrap>
              <FilledYellow
                width="7.0625rem"
                bgColor={"#FFD100"}
                fontColor={"#005587"}
                borderColor={"#FFD100"}
                name={props.btnName}
                onClick={copyTableToClipboard}
              >
                <span>Copy to Clipboard</span>
              </FilledYellow>
            </AdminButtonWrap>}

        </div>
      </section>
    </QueryResultsStyles>
  )
}


export default UserSearchResults
