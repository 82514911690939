import { Switch, Route, Redirect } from 'react-router-dom';

import { history } from 'services/history'
import PrivateRoute from 'common/PrivateRoute';
import LinkUserSuccess from 'admin/linkuser/LinkUserSuccess'
import LinkCrm from 'admin/linkuser/LinkCrm'
import UserSearch from 'admin/sharedqueries/UserSearch'
import AuditTrail from 'admin/sharedqueries/AuditTrail'
import UnconfirmedUsers from 'admin/UnconfirmedUsers'
import Login from 'authentication/routes/Login';

import PendingList from './admin/PendingList'
import SetUcla1Id from './admin/ucla1id/SetUcla1Id'
import LinkUcla1IdSuccess from './admin/ucla1id/LinkUcla1IdSuccess'
import AdminViewList from './admin/AdminViewList'
import AdditionalInfoForm from './AdditionalInfoForm'
import ForceSignin from './ForceSignin'
import UserStatusPage from './UserStatusPage'
import PostLogin from './PostLogin'
import PendingRequestInProgress from './PendingRequestInProgress'
import PendingRequestDenied from './PendingRequestDenied'
import PocStart from './PocStart'
import logoImage from './images/ucla1_logo.png';
import { HeaderGradient } from 'common/header/HeaderStyles';

const cognitoClientIds = {
  "dev": "7cdfai707u5fi32ehhpnib9cju",
  "stage": "5t3p9q5m0a236tf6d782q9m3jl",
  "pilot": "6lhrral01oscmto0eg1vrn9mg7",
  "preprod": "4h5ffdosjd6il9ek5eku29eq3a",
  "prod": "6tu19rchhthkkeh6ci39b7l8ru",
}

const graduwaySites = {
  "dev": "https://uclatest.manual.graduway.com/",
  "stage": "https://uclatest.manual.graduway.com/",
  "pilot": "https://uclatest.manual.graduway.com/",
  "preprod": "https://uclatest.manual.graduway.com/",
  // "prod": "https://uclatest.manual.graduway.com/",
  "prod": "https://uclaone.com/",
}

const themeHeader = <HeaderGradient />

const routes = [
  <Route exact={true} path='/' component={Login} key="/ucla1home" />,
  <Route exact={true} path='/forcesignin' key='/forcesignin' component={ForceSignin} />,
  <PrivateRoute exact={true} path='/additionalinfo' key='/additionalinfo' component={AdditionalInfoForm} />,
  <Route exact={true} path='/status' key='/status' component={UserStatusPage} />,
  <Route exact={true} path='/postlogin' key='/postlogin' component={PostLogin} />,
  <Route exact={true} path='/inprogress' key='/inprogress' component={PendingRequestInProgress} />,
  <Route exact={true} path='/noaccess' key='/noaccess' component={PendingRequestDenied} />,
]

if (process.env.REACT_APP_AWS_ENVIRONMENT !== 'prod')
  routes.push(<Route exact={true} path='/pocstart' key='/pocstart' component={PocStart} />)

const themeAdminLinks = (parentHistory) => (
  <div className="admin-content-wrapper">
    <Switch>
      <PrivateRoute exact={true} path='/admin/linkusertocrm' key='/admin/linkusertocrm' component={LinkCrm} />
      <PrivateRoute exact={true} path='/admin/linkusertocrm/success' key='/admin/linkusertocrm/success' component={LinkUserSuccess} />
      <PrivateRoute exact={true} path="/admin/history" key="/admin/history" component={AuditTrail} />
      <PrivateRoute exact={true} path="/admin/unconfirmedusers" key="/admin/unconfirmedusers" component={UnconfirmedUsers} />
      <PrivateRoute exact={true} path="/admin/users" key="/admin/users" component={UserSearch} />
      <PrivateRoute exact={true} path="/admin/pendinglist" key="/admin/pendinglist" component={PendingList} />
      <PrivateRoute exact={true} path='/admin/ucla1id' key='/admin/ucla1id' component={SetUcla1Id} />
      <PrivateRoute exact={true} path='/admin/ucla1id/success' key='/admin/ucla1id/success' component={LinkUcla1IdSuccess} />
    </Switch>
  </div>
)

const hideFooter = () => {
  return (window.location.href.indexOf('/additionalinfo') > -1
       || window.location.href.indexOf('/inprogress') > -1
       || window.location.href.indexOf('/noaccess') > -1
       || window.location.href.indexOf('/forcesignin') > -1
       || window.location.href.indexOf('/status') > -1)
}

const hideHeader = () => {
  return (window.location.href.indexOf('/additionalinfo') > -1
       || window.location.href.indexOf('/inprogress') > -1
       || window.location.href.indexOf('/noaccess') > -1
       || window.location.href.indexOf('/forcesignin') > -1
       || window.location.href.indexOf('/status') > -1)
}

const specificLoginFailTest = (failMsg) => {
  if (failMsg.indexOf('UCLA1_ALT_ID_MISMATCH') === 0) {
    history.push({pathname: '/status', state: {isCrmIdMismatch: true}});
    return true;
  }
  else return false;
}

export const ucla1 = {
  name: 'ucla1',
  cognitoClientId: cognitoClientIds[process.env.REACT_APP_AWS_ENVIRONMENT || "dev"],
  routes,
  windowTitle: 'UCLA One Portal',
  appName: 'UCLA One',
  crmUserLandingPage: '/status',
  nonCrmUserLandingPage: '/status',
  setSsoCookieForCrmUser: false,
  setSsoCookieForNonCrmUser: false,
  logoImage,
  themeHeader,
  themeAdminViewList: AdminViewList,
  themeAdminLinks,
  hideHeader,
  hideFooter,
  openIdRedirect: graduwaySites[process.env.REACT_APP_AWS_ENVIRONMENT || "dev"],
  supportTeamName: "the UCLA ONE team",
  supportEmail: "ace@support.ucla.edu",
  supportPhone: null,
  primarySupport: "ace@support.ucla.edu",
  hideNavLinks: () => true,
  adminUserTest: "isUcla1Admin",
  specificLoginFailTest,
  linkCrmSpecialHandling: {showUcla1Id: true},
}

