
export const allowedPhoneRegex = /^\+1\d{10}$/;
export const emailRegex = /^.+@[\w.]+\w/;

// called by Register.js and ResetPasswordFinish.js
export const validateCreatePassword = (password) => {

  const errorObj = {
    passwordProgress: { },
  };

  // TODO - use this Regex if/when we add a special character requirement
  // const specialChar = /^(?=.*[!@#\$%\^&\*])/;

  const lowercase = /^(?=.*[a-z])/;
  const uppercase = /^(?=.*[A-Z])/;
  const hasnumber = /^(?=.*[0-9])/;
  const minlength = /^(?=.{8,})/;

  errorObj.passwordProgress.lowercase = lowercase.test(password);

  errorObj.passwordProgress.uppercase = uppercase.test(password);
  errorObj.passwordProgress.hasnumber = hasnumber.test(password);
  errorObj.passwordProgress.minlength = minlength.test(password);

  errorObj.passwordFailing = !(Object.keys(errorObj.passwordProgress).every((k) => !!(errorObj.passwordProgress[k])));

  return errorObj;
}

export const validateSignup = (data, setMessages) => {
  let errorMessage = ""

  setMessages.setUsernameError(null)
  setMessages.setPasswordError(null)
  setMessages.setConfirmPasswordError(null)
  setMessages.setGenericError(null)

  if (!data.username) {
    setMessages.setUsernameError("Please Enter an email address or mobile phone number.")
    errorMessage = "UsernameNotSet"
  }
  else if (data.usernameType === 'email' && !emailRegex.test(data.username)) {
    setMessages.setUsernameError("Please enter a valid email address.")
    errorMessage = "EmailAddressInvalid"
  }
  else if (data.usernameType === 'phone' && !allowedPhoneRegex.test(data.username)) {
    setMessages.setUsernameError("Please enter a valid US mobile phone number.")
    errorMessage = "PhoneNumberInvalid"
  }

  const passwordProgress = validateCreatePassword(data.password);
  if (passwordProgress.passwordFailing) {
    setMessages.setPasswordProgress(passwordProgress)
    errorMessage = "PasswordNonCompliant"
  }

  if (data.password !== data.confirmPassword) {
    setMessages.setConfirmPasswordError("Passwords don't match.")
    errorMessage = "PasswordNotMatching"
  }

  return errorMessage;
}

export const validateSignin = (data, setMessages) => {
  let errorMessage = ""

  setMessages.setUsernameError(null)
  setMessages.setPasswordError(null)
  setMessages.setGenericError(null)

  if (!data.username) {
    setMessages.setUsernameError("Please Enter an email address or mobile phone number.")
    errorMessage="UsernameNotSet"
  }
  else if (data.usernameType === 'email' && !emailRegex.test(data.username)) {
    setMessages.setUsernameError("Please enter a valid email address.")
    errorMessage="EmailAddressInvalid"
  }
  else if (data.usernameType === 'phone' && !allowedPhoneRegex.test(data.username)) {
    setMessages.setUsernameError("Please enter a valid US mobile phone number.")
    errorMessage="PhoneNumberInvalid"
  }

  if (!data.password) {
    setMessages.setPasswordError("Please enter a password.")
    errorMessage="PasswordNotSet"
  }

  return errorMessage;
}
