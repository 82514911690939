import { NavLink } from 'react-router-dom'

import { signOut } from 'authentication/signout'
import { theme } from 'ThemeContext';

import { MenuPopUpOverlay, MenuPopUp, UserName } from './UserNavigationStyles'

const doInitials = (loggedInUser) => {

  let userObj= { initials: "", fullname: "" }

  // console.log(loggedInUser)
  if (!loggedInUser)
    return userObj

  const cognitoUser = loggedInUser.cognitoUser ? loggedInUser.cognitoUser : null
  const cognitoUserAttributes = cognitoUser ? cognitoUser.attributes : null
  const crmUserProfile = loggedInUser.crmUser ? loggedInUser.crmUser.profile : null

  // can be set by some apps which allow non-CRM user participation (auctions)
  if (cognitoUser && cognitoUser.nonCrmFirstName && cognitoUser.nonCrmLastName) {
    userObj.initials = (cognitoUser.nonCrmFirstName.substr(0, 1) + cognitoUser.nonCrmLastName.substr(0, 1)).toUpperCase();
    userObj.fullname = cognitoUser.nonCrmFirstName + " " + cognitoUser.nonCrmLastName;
  }
  // else if we have a name from social logon - use that
  else if (cognitoUserAttributes && cognitoUserAttributes.given_name && cognitoUserAttributes.family_name) {
    userObj.initials = (cognitoUserAttributes.given_name.substr(0, 1) + cognitoUserAttributes.family_name.substr(0, 1)).toUpperCase();
    userObj.fullname = cognitoUserAttributes.given_name + " " + cognitoUserAttributes.family_name;
  }
  // else use name from CRM profile
  else if (crmUserProfile) {
    userObj.initials = crmUserProfile.primaryName.firstName.charAt(0) + crmUserProfile.primaryName.keyName.charAt(0)
    userObj.fullname= crmUserProfile.primaryName.firstName+" "+crmUserProfile.primaryName.keyName
  }
  // else use first letter of email
  else if (cognitoUserAttributes && cognitoUserAttributes.email) {
    userObj.initials = cognitoUserAttributes.email.substr(0, 1).toUpperCase();
  }
  // else use "ME" (non-CRM user who signed up with a phone)
  else {
    userObj.initials = "ME";
  }

  if (cognitoUser.signInUserSession && !sessionStorage.altCrmId)
    userObj.username = cognitoUser.signInUserSession.idToken.payload.cognitoLogin || '';

  return userObj;
}


function UserNavigation (props) {
  let myInformationLink = "/profile"
  if (theme.myInformationRouting) {
    myInformationLink = theme.myInformationRouting(props.authenticatedUser)
  }

  const headerInfo = doInitials(props.authenticatedUser);
  // console.log(props.authenticatedUser.crmUser?.creditCards?.length)
  return (
    <MenuPopUpOverlay>
      <MenuPopUp className="has-no-margins">
        <UserName>
          <span id="headerInitials" className="initials bold center">{headerInfo.initials}</span>

          {props.isPhone && (headerInfo.fullname &&
            <span className="user-info-mobile">
              <span className="fullname has-no-margins">{headerInfo.fullname}</span>
              <span className="username has-no-margins">{headerInfo.username}</span>
            </span>
          )}
        </UserName>

        <li className="popup">
          <ul className="has-no-margins">
           {!props.isPhone&& (headerInfo.fullname &&
              <div>
                <li className='fullname '>{headerInfo.fullname}</li>
                <li className='username'>{headerInfo.username}</li>

                {!headerInfo.fullname &&
                  <li className='username alone'>{headerInfo.username}</li>}
            </div>)
            }

            <li>
              <NavLink  id="headerMyInformationLink" className="header-links" to={myInformationLink} onClick={props.hideHeaderMenu}>
                My Information
              </NavLink>
            </li>
           
            {props.authenticatedUser.crmUser?.creditCards?.length > 0 && !theme.appPaymentMethodsLink &&
              <li className="popup-link">
                <NavLink className="header-links" id="headerPaymentMethodsLink" to="/paymentmethods" onClick={props.hideHeaderMenu}>
                  Payment Methods
                </NavLink>
              </li>
              }

            {/* some apps have their own payment methods page (auctions) */}
            {theme.appPaymentMethodsLink &&
              <li className="popup-link">
                <NavLink className="header-links" id="headerPaymentMethodsLink" to={theme.appPaymentMethodsLink} onClick={props.hideHeaderMenu}>
                  Payment Methods
                </NavLink>
              </li>
              }

            {props.authenticatedUser.cognitoUser.attributes && !props.authenticatedUser.cognitoUser.attributes.identities && /* for now only social users have identities, they can't change their passwords */
              <li className="change-password popup-link"><NavLink id="headerChangePasswordLink" className="header-links" onClick={props.changePassword} to="/resetpasswordfinish">Change Password</NavLink></li>}

            <li className="signout ">
              <button id="headerSignoutLink" className="header-links" onClick={(e) => {
                signOut()
              }}>
                Sign Out
              </button>
            </li>
          </ul>
        </li>
      </MenuPopUp>
    </MenuPopUpOverlay>
  )
}

export default UserNavigation
