
export const fullName = "EIAR Donor Reports"

export const helperText = (
  <>
    <p><b>All files should be named in the following format:</b>
    <br/>[donor's last name].[donor's first name].[donor's CRM ID].endowment.[year of report].[file extenstion]</p>

    <div>
      Spaces in the filename are ok. The important thing is not to add or remove a period.
      <br/><b>For example:</b>
      <ol>
        <li><b>PDF:</b> Bruin.Joe.0123456789.endowment.2021.pdf</li>
        <li><b>Movie:</b> Bruin.Joe.0123456789.endowment.2021.mov</li>
        <li><b>Compound Last Name:</b> Van Eyck.Joe.0123456789.endowment.2021.pdf</li>
        <li><b>Compound First Name:</b> Bruin.J Bob.0123456789.endowment.2021.pdf</li>
      </ol>
      <br/>
    </div>
  </>
)

export const validateFile = (file, errors) => {
  const [lastName, firstName, crmId, reportType, reportYear, fileExt, badData] = file.name.split('.');

  if (badData) {
    errors.push({file, msg: "Filename has too many sections"})
  }
  else if (!fileExt) {
    errors.push({file, msg: "Filename has too few sections"})
  }
  else if (reportType.toLowerCase() !== 'endowment') {
    errors.push({file, msg: "Only endowment reports are supported at this time"})
  }
  else if (!/^\d{4}$/.test(reportYear)) {
    errors.push({file, msg: "Report Year: " + reportYear + " is malformed"})
  }
  else if (!/^\w{3,4}$/.test(fileExt)) {
    errors.push({file, msg: "File Extension: " + fileExt + " is malformed"})
  }
  else if (!/^\d{10}$/.test(crmId)) {
    errors.push({file, msg: "Invalid crmId: " + crmId})
  }
}