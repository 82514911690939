import React, { Component } from 'react';

import * as colors from 'css/Colors'
import Zindex from 'css/z-indexLayers.js';
import styled from 'styled-components'
import { device } from 'common/Device'

import success from 'images/TickMark.svg';
import EmailArrowDownIcon from 'images/arrow-down-primary-blue.svg'
import ArrowUp from 'images/arrow-up-primary-blue.svg'

const defaultFontSize = 16

export const DropDownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 50px;
  width:100%;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  position: relative;
  height:${54.11/defaultFontSize}rem;
`

export const DropDownWrapper = styled.div`
  user-select: none;
  position: relative;
  color:#000000;
  cursor:${props=>props.readOnly?"default":"pointer"};

  .select-list {
  	display:none;
  }
  &:focus{
  	outline:none;
  	.select-list {
  		display:${props=>props.readOnly?"none":"block"};
  }

  }

  .error{
    border: 1px solid ${colors.messagingRed};
  }

  ${DropDownHeader}{
    background: ${props=>props.readOnly ?"#F5F5F5":"#fff"};
  }
`

export const DropDowntitle = styled.label`
  &&&{
  	color:#000000;
  }
  position:relative;
  overflow:hidden;
  padding: 2px 20px;
  white-space:nowrap;
  box-sizing:border-box;
  max-width:calc(100% - 40px);

  @media ${device.tablet}{
    padding: 2px 10px;
  }
`

export const DropDownList = styled.ul`
  z-index: ${Zindex.zindexStackOnMainContentOrder1};
  position: absolute;
  width: 100%;
  border: 1px solid #dfdfdf;
  border-top: none;
  border-radius: 0 0 3px 3px;
  background-color: #fff;
  box-shadow: 0 2px 5px -1px #e8e8e8;
  margin:0;
  padding: 0;
  max-height: ${props => props.maxHeight ? `calc(${props.maxHeight})rem/${defaultFontSize})` : `calc(250rem/${defaultFontSize})`};
  overflow:auto;

  .is-selected{
  	color:${colors.primaryBlue};
  }
  .is-focussed-keyboard{
    color:${colors.primaryBlue};
  	background-color: ${colors.lightGray};
  }

  & > li:nth-child(6){
    border-bottom: ${props=>props.specialtype?`2px dashed ${colors.borderGray}`:`1px solid ${colors.borderGray}`};
  }
`

export const DropDownListItem = styled.li`
  line-height: 1.6rem;
  cursor: default;
  display: block;
  list-style:none;
  white-space:nowrap;
  font-weight:normal;
  cursor:pointer;
  border-bottom: 1px solid ${colors.borderGray};
  &:hover{
  	color:${colors.primaryBlue};
  	background-color: ${colors.lightGray};
  }

  &&& {
  	padding: 8px 20px;
  	margin:0;
  	text-align:left;
  }
  & img {
    position: absolute;
    right: 0;
    padding: 8px;
  }
  `

  export const DropDownIcon = styled.div`
  width: 30px;
  height: 100%;
  & >img {
      vertical-align:middle;
  }
`

class DropdownMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      selected: false,
      timeOut: null,
      selectedIndex: 0,
      resetThenSet: this.props.resetThenSet || function () { },
    }
    this.selectItem = this.selectItem.bind(this);
    this.dropdownRef = React.createRef();
    this.listItemref = ''
  }

  componentDidMount() {
    if (this.props.title) {
      this.setState({ selected: true })
    }
    //creating a ref for each list item based on their index

   this.listItemref = this.props.list.reduce((obj, value, key) => {
      obj[key] = React.createRef();
      return obj;
    }, {});

  }

  //TODo need to de allocate the refs to avoid memory leak in componentWillUnmount()


  selectItem(selectedItem, index){
    const selectedValue = selectedItem.displayValue || selectedItem;
    this.setState({
      selected: true,
      selectedIndex: index,
      open: false,
    },this.state.resetThenSet(selectedValue, index,this.props.name));

    if (!this.props.contactus) {
      this.props.changeHandler({ target: { value: selectedItem, name: this.props.name, index: index } });
    }

  }



  showList = (e) => {
    if (this.props.readOnly) return;
    this.dropdownRef.current.focus();
    this.setState({ open: true })
  }

  hideList = (e) => {
    if (this.props.readOnly) return;
    this.dropdownRef.current.blur();
    this.setState({ open: false })
  }

  //keybaoard support to navigate to each element in list
  handleKeyboardInput = (e) => {

    if (e.keyCode) {
      switch (e.keyCode) {
        case 32:
            // space for selection
            e.preventDefault()
            if ( this.state.open ) {
              this.selectItem(this.props.list[this.state.selectedIndex], this.state.selectedIndex )
              this.hideList();

            } else {
              this.showList(e)
            }
          break;
        case 38:
          // uparrow
        if (this.state.selectedIndex > 0) {
            e.preventDefault();
            this.setState(prevState => ({
              selectedIndex: prevState.selectedIndex - 1
            }))
            this.scrollTo(this.state.selectedIndex)
          }

          break;

        case 40:
          // downarrow
          if (this.state.selectedIndex < this.props.list.length - 1) {
            e.preventDefault();
            this.setState(prevState => ({
              selectedIndex: ++prevState.selectedIndex
            }))
           this.scrollTo(this.state.selectedIndex)


          }
          break;
        default:
        break;
      }
    }
  }

  scrollTo = (id) => {
    if(!this.listItemref.current) return;
    this.listItemref[id].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
     });
  }

  render() {
    const { list } = this.props
    return (
      <DropDownWrapper
        tabIndex="0"
        ref={this.dropdownRef}
        onFocus={this.showList}
        onBlur={this.hideList}
        onKeyDown={this.handleKeyboardInput}
        readOnly={this.props.readOnly}
        name={this.props.name}>

        <DropDownHeader
          className={this.props.className}
          onMouseDown={e => {
            e.preventDefault()
            if (this.state.open) {
              this.hideList();
            } else {
              this.showList()
            }
          }}>

          <DropDowntitle >{this.props.title}</DropDowntitle>

          <DropDownIcon>{!this.props.readOnly && (this.state.open ?
            <img src={ArrowUp} width={"12"} height={"12"} alt='' /> :
            <img src={EmailArrowDownIcon} width={"12"} height={"12"} alt='' />
          )}</DropDownIcon>
        </DropDownHeader>

        <DropDownList
          className="select-list"
          //tabIndex="-1"
          specialtype={this.props.specialtype}
          onClick={e => e.stopPropagation()}
          maxHeight={this.props.maxHeight}
        >
          {this.props.list && list.map((item, index) => (
            <DropDownListItem
              key={item.key || index}
              className={`${this.props.title === item && "is-selected bold"} ${this.state.selectedIndex === index && "is-focussed-keyboard"}`}
              onMouseDown={()=>this.selectItem(item,index)}
              ref={this.listItemref[index]}
              onClick={this.hideList}
              //not sure why without this tabindex on DropDownList is detecting it's child list outside it for contact us
            >
              {item.displayValue || item} {this.props.title === item && <img src={success} alt='' />}
            </DropDownListItem>))}

        </DropDownList>
      </DropDownWrapper>
    )
  }
}

export default DropdownMenu
