import React from 'react'

import Zindex from 'css/z-indexLayers.js';
import styled from 'styled-components'

import { device } from 'common/Device'
import { primaryYellow, borderGray } from 'css/Colors'
import { FilledBlue } from 'css/Buttons'
import { initializeDate } from 'services/util'

const defaultFontSize = 16

const SelectStyles = styled.div`
  box-sizing:border-box;
  width:100%;
`

const YearList = styled.div`
  display:block;
  position:absolute;
  top:100%;
  width:100%;
  z-index:${parseInt(Zindex.zindexPopUpStackOrder2,10) + 1};
  background:#FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.3);

  @media ${ device.mobile } {
    border-left:${ 3/defaultFontSize }rem solid #FFFFFF;
    border-right:${ 3/defaultFontSize }rem solid #FFFFFF;
    border-bottom:${ 3/defaultFontSize }rem solid #FFFFFF;
    left:${ -3/defaultFontSize }rem;
  }
`

const YearOption = styled.div`
  display:block;
  box-sizing:border-box;
  width:100%;
  padding:${ 6/defaultFontSize }rem ${15/defaultFontSize}rem;
  border-bottom:1px solid ${ borderGray };
  cursor:pointer;

  &:last-child {
    border-bottom:none;
  }
`

const SelectWrapper = styled.div`
  position:relative;

  .select-list {
    display:none;
  }

  &:focus {
    outline:none;
    .select-list {
      display:block;
    }

    &:after {
      border-bottom:${ 8/defaultFontSize }rem solid ${ primaryYellow };
      border-top:none;
    }
  }

  &:after {
    display:block;
    position:absolute;
    top:50%;
    margin-top:${ -4/defaultFontSize }rem;
    right:${ 13/defaultFontSize }rem;
    content:"";
    border-top:${ 8/defaultFontSize }rem solid ${ primaryYellow };
    border-left:${ 6/defaultFontSize }rem solid transparent;
    border-right:${ 6/defaultFontSize }rem solid transparent;
  }
`

const SelectButton = styled( FilledBlue )`
  color:#FFFFFF;
  font-size:${ 20/defaultFontSize }rem;
  line-height:${ 19/20 };
  font-weight:600;
  width:100%;
  text-transform:none;

  @media ${ device.tablet } {
    height:${ 30/defaultFontSize }rem;
    font-size:${ 20/defaultFontSize }rem;
  }
`

// lots of this is shared with MoreInfo in GiftInterface.js
class SelectYear extends React.PureComponent {
  constructor( props ) {
    super( props )
    const cur = initializeDate()

    this.state = {
      open: false,
      selectedIndex: 0
    }

    this.containerRef = React.createRef()
    // use props to make this more reusable
    this.values = [
      { key: "all", value: "All Gifts" },
      { key: cur.getFullYear(), value: cur.getFullYear()},
      { key: cur.getFullYear() - 1, value: cur.getFullYear() - 1},
      { key: cur.getFullYear() - 2, value: cur.getFullYear() - 2},
      { key: "custom",  value: "Date Range" }
    ]
  }

  // adding keyboard support
  handleKeyboardInput = (e) => {
    if (e.keyCode) {
      switch( e.keyCode ) {
        case 32:
          // space
          e.preventDefault()
          if ( this.state.open ) {
            this.makeSelection( this.state.selectedIndex )

          } else {
            this.showSelect(e)
          }
        break;

        case 38:
          // uparrow
          e.preventDefault()
          this.changeSelection( -1 )
        break;

        case 40:
          // downarrow
          e.preventDefault()
          this.changeSelection( 1 )
        break;

        default:
          // linter complains without a default
        break;
      }
    }
  }

  hideSelect = () => {
    const currentKey = this.values[this.state.selectedIndex].key

    this.containerRef.current.blur()
    this.setState({
      open: false
    })
    // treat it as a selection when user tabs out after using arrows to select new value
    if ( currentKey !== this.props.value ) {
      this.props.onChange( currentKey )
    }
  }

  showSelect = (e) => {
    this.containerRef.current.focus()
    this.setState({ open: true })
  }

  // mousedown is the only way to capture this event
  handleMouseDown = (e) => {
    this.makeSelection( e.currentTarget.getAttribute('value') )
    e.preventDefault()
  }

  // trigger the update
  makeSelection( selectedIndex ) {
    let selected = this.values[ selectedIndex ].key
    this.props.onChange( selected )
    this.hideSelect()
  }

  // change what is selected without triggering update
  changeSelection( dir ) {
    let selectedIndex = this.state.selectedIndex + dir

    if (selectedIndex < 0) {
      selectedIndex = this.values.length - 1
    } else if (selectedIndex >= this.values.length ){
      selectedIndex = 0
    }

    this.setState({ selectedIndex })
  }

  // avoids the need to polyfill for IE11 I guess
  getSelectedIndex( value ) {
    let idx = 0
    return this.values.find( (item, i) => {
      idx = i
      return item.key === value
    })? idx : 0
  }

  // this is like derived state so probably a bad idea
  // i use it a lot on controlled form components though
  componentDidUpdate( pp, ps ){
    if (this.props.value !== pp.value) {
      this.setState({
        selectedIndex: this.getSelectedIndex( this.props.value )
      })
    }
  }

  render() {
    let yearOptions = []

    this.values.forEach((element, idx) => {
      yearOptions.push(
        <YearOption
          key={ `option-${ idx }` }
          role="option"
          onMouseDown={ this.handleMouseDown }
          value={ idx }
        >
          { element.value }
        </YearOption>
      )
    })

    return (
      <SelectStyles>
        <span id="select_year" className="sr-only">
          Select a Year
        </span>
        <SelectWrapper
          tabIndex="0"
          onFocus={ this.showSelect }
          onBlur={ this.hideSelect }
          onKeyDown={ this.handleKeyboardInput }
          ref={ this.containerRef }
        >
          <SelectButton
            aria-haspopup="listbox"
            aria-labelledby="exp_elem exp_button"
            id="exp_button"
            aria-expanded={ this.state.open }
            tabIndex="-1"
            isSmall={ true }
            onMouseDown={ e => {
              e.preventDefault()
              if ( this.state.open ) {
                this.hideSelect(e)
              } else {
                this.showSelect(e)
              }
            }}
          >
            { this.values[this.state.selectedIndex].value  }
          </SelectButton>
          <YearList
            className="select-list"
            id="exp_elem_list"
            tabIndex="-1"
            role="listbox"
            aria-labelledby="exp_elem"
          >
              { yearOptions }
          </YearList>
        </SelectWrapper>
      </SelectStyles>
    )
  }
}

export default SelectYear
