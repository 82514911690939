import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import store from "redux/store";
import { Amplify }  from 'aws-amplify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import 'index.css';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import * as config from 'config';
import buildMetadata from 'build-metadata.json';
import { doPing } from "services/api";

// you can set this in .env.local - which is ignore by git
if (process.env.REACT_APP_HIDE_CONSOLE_WARN)
  console.warn = () => {};

// use this to make sure builds match and verify updates are actually pushed, we will make it more formal eventually
// build # is only generated on npm build (eventually will only be incremented on a push to stage branch or something)
console.log("build environment: " + buildMetadata.build_environment);
console.log("build date: " + buildMetadata.date);
console.log("build ID: " + buildMetadata.build_id);
console.log("github commit ID: " + buildMetadata.commit_id);
console.log("idm api gateway host: " + process.env.REACT_APP_IDM_API_GATEWAY_HOST);
console.log("mgg api gateway host: " + process.env.REACT_APP_MGG_API_GATEWAY_HOST);
console.log("auctions appsync host: " + process.env.REACT_APP_AUCTIONS_APPSYNC_HOST);
console.log("idm host: " + process.env.REACT_APP_USER_POOL_DOMAIN);
console.log("cognito client id: " + config.awsconfig.Auth.userPoolWebClientId);
console.log("under construction via app: " + (process.env.REACT_APP_UNDER_CONSTRUCTION === 'YES'));
console.log("under construction via cloudfront: " + !!(document.getElementById('under-construction-root')));

console.log("config:");
console.log(config.awsconfig);
console.log(config.socialParams);

Amplify.configure(config.awsconfig);

doPing(); // wakes up the VPC lambdas, async of course

// set currentYear to PST so it matches back end
// not perfect but close enough
let currentYear = new Date()
currentYear.setHours( currentYear.getHours() + currentYear.getTimezoneOffset() / 60 - 7 )
localStorage.setItem('currentYear', currentYear.getFullYear())

if (document.getElementById('root')){
  const root = createRoot(document.getElementById('root'))
  const queryClient = new QueryClient()
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Provider>
    </React.StrictMode>,

  );
}
else {
  // using cloudfront by setting the root to underconstruction.html instead of index.html
  const root = createRoot(document.getElementById('under-construction-root'))
  root.render(
    <React.StrictMode>
      <Provider store={store}>
          <App isUnderConstruction={"YES"} />
      </Provider>
    </React.StrictMode>,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
