import styled from 'styled-components'

import { device } from 'common/Device'
import { primaryBlue, lightGray, borderGray } from 'css/Colors'

const defaultFontSize = 16

// doing the math for the columns here
// starting with main table widths
const tableColumnWidths = [
  134,
  217,
  239,
  261,
  156,
  182,
  178
]

// subtable widths
const subColumnWidths = [
  190,
  175,
  175,
  243,
  190,
  220,
  120,
]

const subTableCompWidth = subColumnWidths.reduce( (acc, width) => acc + width )

const tableCompWidth = tableColumnWidths.reduce( (acc, width) => acc + width )

// the table layout used on the giving history page
export default styled.div`
  font-size:${ 14/defaultFontSize }rem;
  line-height:${ 20/14 };
  margin-top:${ 10/defaultFontSize }rem;

  /* hide the loading message */
  .-loading {
    display:none;
  }

  .rt-table {
    border:1px solid ${ borderGray };
    border-radius:${ 5/defaultFontSize }rem;
  }

  .rt-tr {
    /* ie11 hacks */
    display:-ms-grid;
    -ms-grid-columns: ${ tableColumnWidths.map( width => {
      return `${width / tableCompWidth * 100}% `
    })}
    -ms-grid-rows: auto;
    /* individual columns must start on correct uh... column */

    ${
      props => {
        let ieHack = []
        for (let i = 1; i <= tableColumnWidths.length+1; i++) {
          ieHack.push(`
          > div:nth-child(${ i }) {
            -ms-grid-column-span:1;
            -ms-grid-column:${ i - 1 };
          }
          `)
        }

        return ieHack
      }
    }
    /* end ie11 hacks */

    display:grid;
    width:100%;
    box-sizing:border-box;
    /* build the grid dimensions */
    grid-template-columns:${ tableColumnWidths.map( (width,idx) => {
      return `${width / tableCompWidth * 100}% `
    })};
    padding:${ 14/defaultFontSize }rem ${ 30/tableCompWidth * 100 }%;

    /* grid padding is responsive (now) */
    ${ tableColumnWidths.map( (width,idx) => {
      const leftPadding = idx !== 0? `padding-left:${ 15/width * 100 }%;` : ""
      const rightPadding = (idx !== tableColumnWidths.length - 1)? `padding-right:${ 15/width * 100 }%;` : ""
      return `
        .rt-td:nth-child(${ idx + 1 }), .rt-th:nth-child(${ idx + 1}) {
          ${ leftPadding }
          ${ rightPadding }
        }
      `
    })}

    grid-template-rows:auto;

    &.-odd {
      background:${ lightGray };
      &.-expanded {
        background:transparent;
      }
    }
    &.-even {
      background:white;
      &.-expanded {
        background:transparent;
      }
    }
  }

  .rt-tr-group {
    background:#EEF6FF;
  }

  .rt-th {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    text-align:left;
    cursor:pointer;

    &:after {
      display:block;
      content:"";
      margin-left:${ 13/defaultFontSize }rem;
    }

    &.-sort-desc:after {
      border-top:${ 5/defaultFontSize }rem solid ${ primaryBlue };
      border-left:${ 5/defaultFontSize }rem solid transparent;
      border-right:${ 5/defaultFontSize }rem solid transparent;
    }

    &.-sort-asc:after {
      border-bottom:${ 5/defaultFontSize }rem solid ${ primaryBlue };
      border-left:${ 5/defaultFontSize }rem solid transparent;
      border-right:${ 5/defaultFontSize }rem solid transparent;
    }
  }

  .rt-td {
    position:relative;
    box-sizing:content-box;
  }

  /* header */
  .rt-thead {
    font-weight:600;
    color:${ primaryBlue };
    text-transform:uppercase;
    border-bottom:1px solid ${ borderGray };

    .rt-tr {
      padding-top:${ 26/defaultFontSize }rem;
      padding-bottom:${ 21/defaultFontSize }rem;
    }
  }

  /* individual grid cell styles */
  .rt-th {
    /* gift amount */
    &:nth-child(6), &:nth-child(7) {
      justify-content:flex-end;
    }
  }
  .rt-td {
    /* donor name */
    &:nth-child(2) {
      font-weight:600;
    }

    /* donation amount */
    &:nth-child(6) {
      font-weight:600;
      text-align:right;
    }
  }

  /* subtable styles */
  .subtable {
    margin:0 ${ 20/tableCompWidth * 100}%;
    padding-bottom:${ 20/defaultFontSize }rem;
    .rt-table {
      background:#FFFFFF;
    }

    .rt-th {
       cursor:default;
    }
    /* table borders */
    .rt-thead {
      padding:${ 29/defaultFontSize }rem ${30/subTableCompWidth * 100}% ${ 26/defaultFontSize }rem;
      border-bottom:2px solid ${ borderGray };
    }
    .rt-tbody {
      .rt-tr-group {
        .rt-td {
          border-bottom:1px solid ${ borderGray };
        }
        &:last-child {
          .rt-td {
            border-bottom:none;
          }
        }
      }
    }

    .rt-tr {
      background:white;

      padding:0;

      /* subtable grid */
      grid-template-columns:${ subColumnWidths.map( width => {
        return `${width / subTableCompWidth * 100}% `
      })};

      /* grid padding is responsive (now) */
      ${ subColumnWidths.map( (width,idx) => {
        return `
          .rt-td:nth-child(${ idx + 1 }), .rt-th:nth-child(${ idx + 1}) {
            padding-left:${ 15/width * 100 }%;
            padding-right:${ 15/width * 100 }%;
          }
        `
      }) }
      /* grid padding overrides */
      .rt-td:nth-child(1) {
        padding-right:0;
      }
      .rt-td:nth-child(7) {
        padding-right:0;
      }

      /* .rt-tr-group */
      &-group {
        padding:0 ${30/subTableCompWidth * 100}%;
        background:transparent;
      }
    }

    /* individual header cell styles */
    .rt-th {
      &:after {
        display:none;
      }

      /* unhide the "activity" header */
      /* activity */
      &:nth-child(1) {
        display:flex;
      }

      /* amount */
      &:nth-child(5)  {
        justify-content:flex-end;
      }

      /* payment method */
      &:nth-child(6) {
        justify-content:flex-start;
      }

      /* receipt */
      &:nth-child(7) {
        justify-content:flex-end;
      }
    }

    /* individual grid cell styles */
    .rt-td{
      display:flex;
      flex-direction:row;
      justify-content:center;
      align-items:flex-start;
      padding-top:${ 20/defaultFontSize }rem;
      padding-bottom:${ 20/defaultFontSize }rem;
      line-height:1.4;

      /* activity */
      &:nth-child(1) {
        justify-content:flex-start;
      }

      /* status */
      &:nth-child(2) {
        justify-content:flex-start;
        white-space:nowrap;
      }

      /* reset bold on date */
      &:nth-child(3) {
        font-weight:400;
        justify-content:flex-start;
      }

      /* legal donor name */
      &:nth-child(4) {
        font-weight:600;
        justify-content:flex-start;
      }

      /* amount */
      &:nth-child(5)  {
        justify-content:flex-end;
        font-weight:600;
      }

      /* payment method */
      &:nth-child(6) {
        justify-content:flex-start;
        font-weight:400;

        .more-info-container {
          position:relative;
          transform:none;
          right:auto;
        }
      }

      /* gift actions */
      &:nth-child(7) {
        justify-content:flex-end;
      }

    }
  }
  /* end subtable styles */

  @media ${ device.tablet } {
    /* hide border */
    .rt-table {
      border:none;
    }

    /* header */
    .rt-thead {
      display:none;
    }

    .rt-tr {
      padding:0;

      /* to preserve rounded corners */
      box-sizing: border-box;
      border-radius:${ 5/defaultFontSize }rem;

      &-group {

        /* add borders */
        box-sizing: border-box;
        border:1px solid ${ borderGray };
        border-radius:${ 5/defaultFontSize }rem;
        margin-bottom:${ 15/defaultFontSize }rem;

        /* add drop shadow */
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);

        .-odd {
          background:#FFFFFF
        }
      }
      /* go sorta on the grid */
      grid-template-columns: 1fr 1fr 1fr;

      /* fix padding inherited from desktop */
      ${ subColumnWidths.map( (width,idx) => {
        return `
          .rt-td {
            &:nth-child(${ idx + 1 }) {
              padding:${ 15/defaultFontSize }rem;
            }
          }
        `
      })}
    }

    /* funky layout hacks */
    /* TODO: check WCAG compliance on the headers. probably need some aria tagging on the markup now */
    .rt-td {
      min-height:${ 40/defaultFontSize }rem;
      /* add headers */
      &:before {
        display:block;
        text-transform:uppercase;
        color:${ primaryBlue };
        font-weight:600;
      }

      /* reset padding on date field */
      &:nth-child(2) {
        padding-left:${ 15/defaultFontSize }rem;
      }

      &:nth-child(1):before {
        content:'Date';
      }
      &:nth-child(2):before {
        content:'Legal/Donor Name';
      }
      &:nth-child(3):before {
        content:'Unit';
      }
      &:nth-child(4):before {
        content:'Fund Name';
      }
      &:nth-child(5):before {
        content:'Gift Type';
      }
      &:nth-child(6):before {
        content:'Gift Amount';
      }

      /* add borders */
      &:nth-child(1), &:nth-child(2), &:nth-child(4), &:nth-child(5) {
        border-bottom:1px solid ${ borderGray };
        border-right:1px solid ${ borderGray };
      }

      &:nth-child(3), &:nth-child(6) {
        border-bottom:1px solid ${ borderGray };
      }

      /* receipt cell takes up whole row */
      &:nth-child(7) {
        grid-column-end: span 3;
      }

      /* monetary amounts */
      &:nth-child(6)  {
        text-align:left;
      }

      /* donor name is no longer bolded */
      &:nth-child(2) {
        font-weight:400;
      }

    }

    /* subtable styles */
    .subtable {
      margin:0 ${ 15/defaultFontSize }rem;

      .rt-table {
        background:transparent;
      }

      /* go sorta on the grid */
      .rt-tr {
        grid-template-columns: 1fr 1fr 1fr;

        /* fix group backgrounds, padding, and hide bottom border */
        &-group {
          padding:0;
          border-bottom:none;
          /* hide drop shadow */
          box-shadow: none;

          &:nth-child(odd) {
            background:#FFFFFF
          }
        }

        /* fix padding inherited from desktop */
        ${ subColumnWidths.map( (width,idx) => {
          return `
            .rt-td {
              &:nth-child(${ idx + 1 }) {
                padding:${ 15/defaultFontSize }rem;
              }
            }
          `
        })}
      }

      .rt-tbody {
        .rt-tr-group {
          /* reinstate borders missing from inherited styles */
          &:last-child {
            .rt-td {
              border-bottom:1px solid ${ borderGray };
            }
          }
        }
      }


      .rt-td {
        display:block;
        /* add headers */
        &:before {
          display:block;
          text-transform:uppercase;
          color:${ primaryBlue };
          font-weight:600;
        }

        &:nth-child(1):before {
          content:'Activity';
        }
        &:nth-child(2):before {
          content:'Status';
        }
        &:nth-child(3):before {
          content:'Date';
        }
        &:nth-child(4):before {
          content:'Legal Donor Name';
        }
        &:nth-child(5):before {
          content:'Amount';
        }
        // &:nth-child(6):before {
        //   content:'Balance';
        // }
        &:nth-child(6):before {
          content:'Payment Method';
        }
        &:nth-child(7):before {
          content:'Receipt';
        }

        /* receipt cell takes up whole row */
        &:nth-child(7) {
          grid-column-end: span 3;
        }

        /* reset padding on activity field */
        &:nth-child(1) {
          padding-left:${ 15/defaultFontSize }rem;
        }

        /* add spacing between receipt header and button */
        /* also adjust alignment */
        &:nth-child(7) {
          display:flex;
          align-items:center;
          justify-content:flex-start;

          &:before {
            display:inline-block;
            margin-right:${ 15/defaultFontSize }rem;
          }

          /* slide "pay now" link to the right */
          .arrow-right-link {
            position:absolute;
            right:${ 15/defaultFontSize }rem;
            top:50%;
            transform:translateY(-50%);
          }
          /* adjust for unprintable receipt div */
          > div {
            position:relative;
            display:inline-block;
          }
          /* move the more info popup too */
          .more-info-container {
            right:${ -22/defaultFontSize }rem;
            left:auto;
            /* top:50%;
            transform:translate(50%, -50%) */
          }
        }
      }
    }

    .pledge-print-button .gift-actions-header {
      display: none;
    }
  }


  @media ${ device.mobile } {
    .rt-tr {
      display:block;

      /* fix padding inherited from tablet */
      ${ subColumnWidths.map( (width,idx) => {
        return `
          .rt-td {
            &:nth-child(${ idx + 1 }) {
              padding-left:${ 15/320 * 100 }%;
              padding-right:${ 15/320 * 100 }%;
            }
          }
        `
      }) }
    }

    .rt-td {
      /* add / hide borders */
      &:nth-child(1), &:nth-child(2), &:nth-child(4), &:nth-child(5) {
        border-right:none;
      }
    }

    /* subtable styles */
    .subtable {
      .rt-td {
        /* add / hide borders */
        &:nth-child(1), &:nth-child(2), &:nth-child(4), &:nth-child(5) {
          border-right:none;
        }

        &:nth-child(7) {
          flex-wrap:wrap;
          &:before {
            display:block;
            margin-right:0;
            flex:1 0 100%;
            padding-bottom:${ 4/defaultFontSize }rem;
          }
        }
      }
    }
  }
`
