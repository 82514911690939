import * as cs from 'css/CommonStyle';
import { formatDateMonth } from 'admin/adminutil'
import css from '../sass/ucla1id.module.scss'

function CognitoResults(props) {

  const user = props.user;

  return (
    <div className={css["userInfocontainer"]}>
      <div className={`${css["userInfoDisplay"]} selected`}>
        <div className={css["userInfoDisplayHeader"]}>
          <label>Login method: <span className={"bold"}>{user.Provider === 'Cognito' ? 'Email/Phone Number' : user.Provider}</span></label>
        </div>

        <div className={css["eachUserLayout"]}>

        {!user.error &&
          <>
            <cs.TextInputdiv>
              <label> Email</label>
              <label className="bold">{user.Attributes.email || "None"}</label>
            </cs.TextInputdiv>

            <cs.TextInputdiv>
              <label> Phone</label>
              <label>{user.Attributes.phone || user.Attributes.phone_number || "None"}</label>
            </cs.TextInputdiv>

            <cs.TextInputdiv>
              <label> Date Account Created</label>
              <label>{formatDateMonth(user.UserCreateDate)}</label>
            </cs.TextInputdiv>

            {user.Attributes.name && <cs.TextInputdiv>
              <label>Name</label>
              <label>{user.Attributes.name}</label>
            </cs.TextInputdiv>
            }

            <cs.TextInputdiv>
              <label>CRM ID</label>
              <label>{user.Attributes.primaryCrmId || "None"}</label>
            </cs.TextInputdiv>
          </>}

        {user.error &&
          <cs.TextInputdiv>
            <label className="bold">Error: </label>
            <label>{user.error}</label>
          </cs.TextInputdiv>}
        </div>

      </div>
    </div>
  )
}

export default CognitoResults;