import styled from 'styled-components'

import * as colors from 'css/Colors'
import { device } from 'common/Device'
import MoreInfo from 'common/MoreInfo'

import iconInfoWhite from 'images/iconInfoWhite.svg'
const defaultFontSize = 16


const MoreInfoPositionStyles=styled.div`
  display: inline-block;

  @media ${ device.tablet } {
    top:${ 3/defaultFontSize }rem;
  }
  .more-info-container{
    margin-bottom: -2px;
    margin-left: 2px;

    .more-info-icon {
      background:url(${ iconInfoWhite }) no-repeat;
    }
  }
  label{
    color: ${colors.darkGray};
    border-radius: 3px;
  }
`
const UserProfileInfopopup=(props)=>(

  <MoreInfoPositionStyles>
  <MoreInfo
    htmlName={"more_info_cognito_user_name"}
    htmlId={"more_info_cognito_user_name"}
    srText={"Identifying the Cognito username?"}>
    Enter email/phone number used in the My Giving Gateway's registration
  </MoreInfo>
</MoreInfoPositionStyles>

)
export default UserProfileInfopopup;
