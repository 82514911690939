import { cardValidCheck } from "./paymentUtilities"

import AmEx from '../images/payment-cards/american-express.svg'
import Discover from '../images/payment-cards/discover.svg'
import Mastercard from '../images/payment-cards/mastercard.svg'
import Visa from '../images/payment-cards/visa.svg'

import css from '../payment/sass/card.module.scss'

const ccTypes = {
  VI: { cardImage: Visa, cardAltText: 'Visa logo'},
  MC: { cardImage: Discover, cardAltText: 'Mastercard logo'},
  DI: { cardImage: Mastercard, cardAltText: 'Discover logo'},
  AX: { cardImage: AmEx, cardAltText: 'American Express logo'},
}

function PaymentMethodsSingleCard(props) {
  const cc = ccTypes[props.card.ccTypeCode]

  const expYear = props.card.ccExpiresOn.slice(2, 4)
  const expMonth = props.card.ccExpiresOn.slice(4, 6);
  const cardValid = cardValidCheck(props.card.ccExpiresOn);

  return (
    <div className={`${cardValid ? css['card-valid'] : css['card-invalid']} ${props.paymentUpdateForm ? css['payment-update-form__tablet-view'] : ''}`}>
      <div className={css['card-left']}>
        <p className={css['card-left__header']}>
          EXISTING CARD ON FILE
        </p>
        <p className={css['card-info']}>
          {props.card.ccName}
        </p>
        <p className={css['card-info']}>
          XXXX - XXXX - XXXX - {props.card.ccLastFour}
        </p>
        <p className={css['card-info']}>
          Exp. {expMonth}/{expYear}
        </p>
      </div>
      <div className={css['card-right']}>
        <div className={css['card-identifier']}>
          <img src={cc.cardImage} alt={cc.cardAlt} className={css['card-identifier__img']}/>
        </div>
      </div>
    </div>
  )
}

export default PaymentMethodsSingleCard