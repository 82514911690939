import {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from "react-redux"
import { useQuery } from "@tanstack/react-query"

import { history } from 'services/history.js'
import { showLoading } from 'services/util'
import store from 'redux/store'

import { graphql, getUpcomingAuctions, registerUserForAuction, unregisterUser } from '../api'
import { getAuctionUser } from '../redux/selectors'
import { setAuctionUser } from '../redux/actions'
import { timeUntil, getAuctionStatus, hourTimer } from '../util/dateString'
import CustomMarkdown from 'sites/auctions/components/CustomMarkdown'

import css from './sass/auctions.module.scss'
import themeCss from '../components/sass/theme.module.scss'

export default function AuctionList() {
  const [upcomingAuctionList, setUpcomingAuctionList] = useState()
  const [date, setDate] = useState(new Date())
  const auctionUser = useSelector(getAuctionUser)

  // load auction data by code, so it will work for deep-linking
  const { data: auctions, error, isLoading } = useQuery({
    queryKey: ["upcomingAuctionList"],
    queryFn: async () => await getUpcomingAuctions(),
    staleTime: 0
  })

  useEffect(() => {
    showLoading(isLoading)

    if (error || (auctions && auctions.error)) {
      console.log('Error!', error || auctions.error)
    }
  }, [auctions, error, isLoading])

  useEffect(() => {
    if (!auctionUser || !auctions)
      return

    const upcomingAuctions = auctions.sort((a, b) => (a.StartTime < b.StartTime) ? -1 : (a.StartTime > b.StartTime) ? 1 : 0)

    for (const upcomingAuction of upcomingAuctions) {
      const foundIndex = auctionUser.MyAuctions.findIndex(obj => obj.Auction.AuctionId === upcomingAuction.AuctionId)
      if (foundIndex > -1) {
        upcomingAuction.RegistrationId = auctionUser.MyAuctions[foundIndex].RegistrationId
      }
    }

    setUpcomingAuctionList(upcomingAuctions)
    showLoading(false)
  }, [auctions, auctionUser])

  // to display current time
  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000)
    return function cleanup() {
      clearInterval(timer)
    }
  })

  const registerForAuction = async (auction) => {
    showLoading(true)
    const signUpResponse = await registerUserForAuction(auction.AuctionCode, auctionUser)
    showLoading(false)
  }

  const unregisterFromAuction = async (auction) => {
    showLoading(true)
    const unregisterResponse = await unregisterUser(auction, auctionUser)
    auction.RegistrationId = null
    showLoading(false)
  }

  return (
    <>
      {auctionUser &&
      <div className={css['auction-list-container']}>
        <h1 id="landingPageTitle">Upcoming Auctions</h1>
        {upcomingAuctionList && upcomingAuctionList.map(auction => {
          return (
            <div key={auction.AuctionCode} className={css['auction-row']}>
              <div className={css['auction-row-top']}>
                <div className={`${css['auction-row-top-link']} ${css['auction-row-top-content']}`}>
                  <Link to={{
                      pathname: '/auctionhome',
                      search: `?auctionId=${auction.AuctionCode}`,
                      auction
                    }}
                  > {auction.Title} 
                    {timeUntil(date, auction.StartTime) === 'withinDay'
                      ? <p className={css['time-notice']}>Opening in a day!</p> : null}
                    {timeUntil(date, auction.StartTime) ==='withinHour' 
                      ? <p className={css['time-notice']}>Time til auction: {hourTimer(date, auction.StartTime)}</p> : null}
                    {getAuctionStatus(auction.StartTime, auction.EndTime) === 'started' && <p className={css['time-notice']}> Auction is live!</p>}
                  </Link>
                </div>
                <div className={css['auction-row-top-content']}>
                  {auction.RegistrationId
                    ? <button className={`${themeCss['FilledBlue']} ${themeCss['button']}`}
                        onClick={() => unregisterFromAuction(auction)}
                        disabled={getAuctionStatus(auction.StartTime, auction.EndTime) === 'ended'}
                      >Cancel Sign Up For Auction</button>
                    : <button className={`${themeCss['FilledBlue']} ${themeCss['button']}`}
                        onClick={() => registerForAuction(auction)}
                        disabled={getAuctionStatus(auction.StartTime, auction.EndTime) === 'ended'}
                      >Sign Up For Auction</button>
                  }
                </div>
                <div className={css['auction-row-top-content']}>
                  {getAuctionStatus(auction.StartTime, auction.EndTime) === 'before' && (<div>
                    <p> Auction Starts: {new Date(auction.StartTime).toLocaleString()}</p>
                    </div>)}
                  {getAuctionStatus(auction.StartTime, auction.EndTime) === 'started' && (<div>
                    <p> Auction Ends: {new Date(auction.EndTime).toLocaleString()}</p>
                    </div>)}
                  {getAuctionStatus(auction.StartTime, auction.EndTime) === 'ended' && (<div>
                    <p>Auction has ended!</p>
                    </div>)}
                </div>
              </div>
              <div>
                  <CustomMarkdown text={auction.Description} cssClass={themeCss['truncate-fourline']} />
                  {/*<p className={themeCss['truncate-fourline']}>{auction.Description}</p>*/}

              </div>
              <button
                  onClick ={(e) => {history.push({
                    pathname: '/auctionhome',
                    search: `?auctionId=${auction.AuctionCode}`,
                    auction
                  })}}
                  className={`${themeCss['FilledYellow']} ${themeCss['button']} ${css['auction-link-button']}`}
                >Go To Auction</button>
            </div>
          )
        })}
      </div>}
    </>
  )
}