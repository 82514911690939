import { useEffect, useState, useMemo, useContext } from "react"
import { useSelector } from "react-redux";
import css from './sass/additionalinfo.module.scss'

import { history } from 'services/history.js'
import { getLoggedInUser } from 'redux/selectors';
import { showLoading } from "services/util"
import { getPendingStatus } from './ucla1api';
import { ThemeContext } from 'ThemeContext';

import * as cs from 'css/CommonStyle'

// used as landing page for CRM and non-CRM users
// routest UCLA1 users based on pending status or admin user
function UserStatusPage(props) {
	const [title, setTitle] = useState('')
	const [content, setContent] = useState('')
	const [isCrmIdMismatch] = useState((props.location && props.location.state) ? props.location.state.isCrmIdMismatch : '')
  const loggedInUser = useSelector(getLoggedInUser);
  const theme = useContext(ThemeContext)

	const oopsMessage = useMemo(() => (
		<div>
			<p>
				Oops Something Went Wrong. Please try to <a href="https://uclaone.com/">login again through UCLA One</a>
		  </p>
			<p>
			  If you still have trouble logging in, please contact: <a href="mailto:ace@support.ucla.edu?subject=UCLA One - problem logging in">ace@support.ucla.edu</a>
		  </p>
			<p>
		   The UCLA ONE team
			</p>
		</div>
	), [])

	const mobileUserWithNoEmailInCrmMessage = useMemo(() => (
		<div>
			<p>
				An email address is required to access UCLA One, either on file, or as your logon.
				If you'd like to create an account with email instead of mobile number, please <a href="https://uclaone.com/">Sign Up again at UCLA One</a>
		  </p>
			<p>
			  If you still have trouble creating an account, please contact: <a href="mailto:ace@support.ucla.edu?subject=UCLA One - problem creating account">ace@support.ucla.edu</a>
		  </p>
			<p>
		   The UCLA ONE team
			</p>
		</div>
	), [])

	const nonAdminUserError = useMemo(() => (
		<div>
			<p>
				To access UCLA ONE, please go to <a href="https://uclaone.com">uclaone.com</a> and click "Sign in".
		  </p>
			<p>
				If you have trouble accessing the platform, olease contact: <a href="mailto:ace@support.ucla.edu?subject=UCLA One - unable to access site">ace@support.ucla.edu</a>.
			</p>
			<p>
		   The UCLA ONE team
			</p>
		</div>
	), [])

	const severeCrmIdMismatch = useMemo(() => (
		<div>
			<p>
				We're sorry, there is a problem that prevents us from creating your new UCLA ONE account automatically.
		  </p>
			<p>
				Please contact: <a href="mailto:ace@support.ucla.edu?subject=UCLA One - problem creating account, ID mismatch">ace@support.ucla.edu</a> and we will be happy to help.
			</p>
			<p>
		   The UCLA ONE team
			</p>
		</div>
	), [])

  useEffect(() => {
	  const doRouting = async status => {
	    showLoading(true, 'UserStatusPage')
	   	const pendingUser = await getPendingStatus()
	   	if (isCrmIdMismatch) {
				setTitle('Problem creating account - ID mismatch')
				setContent(severeCrmIdMismatch)
			}
	   	else if (!loggedInUser) {
				history.push('signin')
			}
	   	else if (loggedInUser.crmUser && localStorage.isUcla1Admin) {
				history.push('admin/pendinglist')
			}
	   	else if (loggedInUser.crmUser && !loggedInUser.crmUser.profile.primaryEmail) { // one in a million scneario
				setTitle('Verified Email Address Required')
				setContent(mobileUserWithNoEmailInCrmMessage)
			}
	   	// when users come from openid (graduway), they don't have attributes
	   	else if ((loggedInUser.crmUser && !localStorage[theme.adminUserTest]) || (!loggedInUser.crmUser && loggedInUser.cognitoUser.attributes)) {
				setTitle('You must be an admin user to use this portal')
				setContent(nonAdminUserError)
			}
	   	else if (!pendingUser && !loggedInUser.crmUser) {
				history.push('additionalinfo')
			}
	   	else if (pendingUser.PendingStatus === 'Denied') {
				history.push('noaccess', {firstName: pendingUser.FormData.firstName})
			}
	   	else if (["In Progress", "New"].indexOf(pendingUser.PendingStatus) > -1) {
				history.push('inprogress')
			}
			else {
				setContent(oopsMessage)
				setTitle('System Error')
			}
	    showLoading(false, 'UserStatusPage')
	  }

    doRouting()
  }, [loggedInUser, oopsMessage, mobileUserWithNoEmailInCrmMessage, isCrmIdMismatch, severeCrmIdMismatch, theme])

  return (
		<div className={css['additional-info-form']}>
			<br/>
			<h3 id="pageTitle" className=" bold">{title}</h3>

			{content &&
				<cs.CommonInnerDiv>
					<cs.InnerContent>
						<div>
							{content}
						</div>
					</cs.InnerContent>
				</cs.CommonInnerDiv>}

		</div>
	)

}

export default UserStatusPage