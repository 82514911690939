import { doApi } from 'services/api';

// tries twice then returns undefined if both calls fail
export const getTypes = async (isRetry) => {
  let types = sessionStorage.getItem('bioTypes');
  if (types) {
    return JSON.parse(types);
  }
  else {
    types = await doApi({route:'types', noAuth:true});
    if (types && types.success) {
      sessionStorage.setItem('bioTypes', JSON.stringify(types.success));
      return types.success;
    }
    else {
      if (!isRetry) {
        console.error('Get Types call failed, retrying...');
        return await getTypes(true);
      }
      else {
        console.error('Get Types call failed second try');
        return undefined;
      }
    }
  }
};

export const saveProfile = async (data) => {
  console.log('saveProfile!');

  // const response = await doApi({route:'saveprofile', method:'POST', data:data.useTestUser ? testSaveUser : data});
  const response = await doApi({route:'saveprofile', method:'POST', data});

  return response;
};