// logic for Approve, Deny, Delete

// Approve = email goes out, user linked to CRM
// Deny = email goes out, user sees message if they try to create an account again
// Delete = no email goes out, user can try to sign up again if they don't already have an account
// While user is waiting if they somehow find their way to the site they a message
// need a denial with no email - talk to Gloria about that
// Need to be able to undo a deny and change it to approve

import { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import styled from 'styled-components'

import { getLoggedInUser } from 'redux/selectors';
import { showLoading } from 'services/util'
import { getCrmUserAdmin, linkCrmUser, getCognitoUsers } from 'admin/adminapi';
import { formatPhone } from 'admin/adminutil'
import { updatePendingUser } from './ucla1adminapi';

import PendingActionButton from './PendingActionButton'
import Alert from 'common/notifications/Alert'
import iconInfo from 'images/iconColorinfo.svg'

const PendingListItemTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 1rem 1rem 2rem 1rem;
  tr {
    border-left: thin solid #E8E8E8;
  }
  th {
    border: thin solid #E8E8E8;
    text-align: center;
  }
  td {
    border-bottom: thin solid #E8E8E8;
    padding: 3px 3px 3px 8px;
    text-align: left;
    td {
      border-bottom: none;
    }
    &.crm-label {
      color: green;
    }
    &.user-label {
      color: blue;
    }
    &.user-header {
      padding: 20px 10px 5px 10px;
    }
    &.wrap-row {
      white-space: pre-line;
    }
  }
`;

function PendingListUserForm(props) {
  const [ showCrmData, setShowCrmData ] = useState(false)
  const [ lookupButtonText, setLookupButtonText ] = useState("LOOKUP")
  const [ linkedCrmId, setLinkedCrmId ] = useState(props.row.original.WorkingCrmId)
  const [ savedCrmId, setSavedCrmId ] = useState(props.row.original.WorkingCrmId)
  const [ crmUser, setCrmUser ] = useState({})
  const [ notesValue, setNotesValue ] = useState(props.row.original.Notes)
  const [ isDirty, setIsDirty ] = useState(false)
  const [ crmLookupErrorMsg, setCrmLookupErrorMsg ] = useState('')
  const [ userActionErrorMsg, setUserActionErrorMsg ] = useState('')
  const loggedInUser = useSelector(getLoggedInUser);

  let cleanNotesValue = props.row.original.Notes
  let cleanLinkedCrmId = props.row.original.WorkingCrmId

  const row = props.row
  const formData = row.original.FormData

  // load any saved CRM data when component is rendered
  useEffect(() => {
    async function doInit() {
      if (savedCrmId) {
        setLookupButtonText('CLEAR')
        const response = await getCrmUserAdmin(savedCrmId)

        if (!doError(response, setCrmLookupErrorMsg)) {
          const crmUser = processCrmUser(response.crmUser)
          setCrmUser(crmUser)
          setShowCrmData(true)
          setCrmLookupErrorMsg('')
        }
      }
    }

    doInit();
  }, [savedCrmId])

  const doNotesInputChange = (e) => {
    const notesValue = e.target.value
    setNotesValue(notesValue)
    setIsDirty(notesValue !== cleanNotesValue || cleanLinkedCrmId !== linkedCrmId)
  }

  const linkCrmId = async (e) => {
    if (!linkedCrmId) return;

    if (lookupButtonText === 'CLEAR') {
      setLookupButtonText('LOOKUP')
      setShowCrmData(false)
      setLinkedCrmId("")
      setCrmLookupErrorMsg('')
      setIsDirty(cleanLinkedCrmId !== "" || notesValue !== cleanNotesValue)
    }
    else {
      setLookupButtonText('CLEAR')
      const response = await getCrmUserAdmin(linkedCrmId)

      if (!doError(response, setCrmLookupErrorMsg)) {
        const crmUser = processCrmUser(response.crmUser)
        setCrmUser(crmUser)
        setShowCrmData(true)
        setCrmLookupErrorMsg('')
        if (linkedCrmId !== props.row.original.WorkingCrmId)
          setIsDirty(true)
      }
    }
    return;
  }

  const processCrmUser = (rawUser) => {
    const user = JSON.parse(JSON.stringify(rawUser))

    user.allNames  = rawUser.names.map(item => item.firstName + " " + item.middleName + " " + item.keyName).join(" | ")
    user.allEmails  = rawUser.emails.map(item => item.emailAddress).join(" | ")
    user.allPhones  = rawUser.phones.map(item => formatPhone(item.number)).join(" | ")
    user.allAddresses  = rawUser.addresses.map(item => item.addressBlock.replace(/(\r\n|\n|\r)/gm," | ") + " | " + item.city + " " + item.state + " " + item.postCode + " " + item.country).join("\n")
    user.allEducation  = rawUser.education.map(item => item.classOf + " " + item.degree + " | " + item.major + " | " + item.school).join("\n")

    return user;
  }

  const doCrmInputChange = async (e) => {
    await setLinkedCrmId(e.target.value)
  }

  const formatDate = rawDate => {
    return rawDate.substring(4,6) + '/' + rawDate.substring(6,8) + '/' + rawDate.substring(0,4)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
  }

  function doDislaimer(email) {
    if (email)
      return (<font color="red"> - This email will be sent to graduway. To use a different email, the user must first delete their account in graduway, then you must remove their UCLAOne User ID in CRM (Personal Info/Personal/Alternate lookup IDs).</font>)
    else
      return ""
  }

  // function doEmail() {
  //   const emailBody = `Name: ${formData.firstName} ${formData.middleName ? formData.middleName + ' ' : ''}${formData.lastName}\n`
  //                   + `Current email: ${formData.email}\n`
  //                   +  (formData.phoneNumber ? `Phone Number: ${formData.phoneNumber}\n` : '')
  //                   +  (formData.birthDate ? `Birth Date: ${formData.birthDate}\n` : '')
  //                   +  (formData.studentUid ? `Student UID: ${formData.studentUid}\n` : '')

  //   window.location.href = 'mailto:biodata@suppoprt.ucla.edu?subject=New CRM User Request&body=' + encodeURI(emailBody)
  //  }

//  https://docs.google.com/forms/d/e/1FAIpQLSdrqwlWmjjxrMRDAAlipqZUQs112azX5mm0brehbeeP_-2IpQ/viewform?usp=pp_url
// &entry.942956366=Vivian+San+Gabriel
// &entry.1410338357=requestor@x.com
// &entry.1125799740=First
// &entry.5624150=Last
// &entry.822915942=MIddle
// &entry.63414935=Alumnus
// &entry.63414935=Faculty
// &entry.63414935=Staff
// &entry.706219409=Yes (have email)
// &entry.1252092367=Home (email tyoe)
// &entry.1210992523=useremail@x.xom
// &entry.1669198601=Yes (have phone)
// &entry.242764336=Home+Phone (phone type)
// &entry.1484584347=3103440889
// &entry.297209684=United+States (phone country)

  function doBioDataLink(e) {
    e.preventDefault();

    // const nonPrefilledGoogleFormsLink = "https://docs.google.com/forms/d/e/1FAIpQLSdrqwlWmjjxrMRDAAlipqZUQs112azX5mm0brehbeeP_-2IpQ/viewform"

    let prefilledFormLink = "https://docs.google.com/forms/d/e/1FAIpQLSdrqwlWmjjxrMRDAAlipqZUQs112azX5mm0brehbeeP_-2IpQ/viewform?usp=pp_url"
                          + "&entry.942956366=" + loggedInUser.crmUser.profile.primaryName.firstName + " " + loggedInUser.crmUser.profile.primaryName.keyName
                          + "&entry.1410338357=" + loggedInUser.cognitoUser.username

    if (formData.firstName) prefilledFormLink += "&entry.1125799740=" + formData.firstName
    if (formData.middleName) prefilledFormLink += "&entry.822915942=" + formData.middleName
    if (formData.lastName) prefilledFormLink += "&entry.5624150=" + formData.lastName
    if (formData.affiliation) prefilledFormLink += "&entry.63414935=" + formData.affiliation

    if (row.original.CognitoLogin.indexOf('@') > -1) {
      prefilledFormLink += "&entry.706219409=Yes"
      prefilledFormLink += "&entry.1252092367=Home"
      prefilledFormLink += "&entry.1210992523=" + row.original.CognitoLogin

      if (formData.phoneNumber) {
        prefilledFormLink += "&entry.1669198601=Yes"
        prefilledFormLink += "&entry.242764336=Home+Phone"
        prefilledFormLink += "&entry.1484584347=" + formData.phoneNumber.replace(/[(|)| |-]/g, '')
        prefilledFormLink += "&entry.297209684=United+States"
      }
    }
    else {
      prefilledFormLink += "&entry.1669198601=Yes"
      prefilledFormLink += "&entry.242764336=Home+Phone"
      prefilledFormLink += "&entry.1484584347=" + row.original.CognitoLogin.replace(/[(|)| |-]/g, '')
      prefilledFormLink += "&entry.297209684=United+States"

      if (formData.email) {
        prefilledFormLink += "&entry.706219409=Yes"
        prefilledFormLink += "&entry.1252092367=Home"
        prefilledFormLink += "&entry.1210992523=" + formData.email
      }
    }

    const winRef = window.open(); // open window first to prevent popup blockers from activating
    winRef.location = prefilledFormLink
   }

  function doError(response, setError) {
    if (response.error || response.errorMessage) {

      if (response.errorMessage && response.errorMessage === "[object Object]")
        setError("Error: Something went wrong. Please try again.")
      else
        setError("Error: " + (response.error ? response.error.msg || response.error.key : response.errorMessage))

      showLoading(false)

      return true
    }
    else {
      return false
    }
  }

  const saveForm = async () => {
    showLoading(true)

    const updatedUser = {
      pendingItem: {
        cognitoLogin: row.original.CognitoLogin,
        workingCrmId: linkedCrmId,
        pendingStatus: 'In Progress',
        notes: notesValue,
      }
    }

    const response = await updatePendingUser(updatedUser)

    if (!doError(response, setCrmLookupErrorMsg)) {
      setIsDirty(false)
      props.handleFilter('All Open')
    }
  }

  const approveUser = async () => {
    showLoading(true)
    const linkResponse = await linkCrmUser("LINK_USER", linkedCrmId, row.original.CognitoLogin)

    // if the user has already been linked (possibly by a different group), make sure the CRM IDs match
    if (linkResponse && linkResponse.errorMessage && linkResponse.errorMessage === "[object Object]") {
      const checkLinkedUserResponse = await getCognitoUsers(row.original.CognitoLogin)
      if (checkLinkedUserResponse && checkLinkedUserResponse.length > 0 && checkLinkedUserResponse[0].Attributes) {
        const crmId = checkLinkedUserResponse[0].Attributes.primaryCrmId
        if (crmId !== linkedCrmId) {
          doError({error: {msg: row.original.CognitoLogin + " has already been linked to CRM ID: " + crmId}}, setCrmLookupErrorMsg)
          return
        }
      }
    }
    else if (doError(linkResponse, setCrmLookupErrorMsg)) {
      return;
    }

    const updatedUser = {
      email: formData.email,
      firstName: formData.firstName,
      sendUcla1Email: true,
      pendingItem: {
        cognitoLogin: row.original.CognitoLogin,
        workingCrmId: linkedCrmId,
        pendingStatus: 'Approved',
        notes: notesValue,
      }
    }

    const response = await updatePendingUser(updatedUser)

    if (!doError(response, setUserActionErrorMsg)) {
      setIsDirty(false)
      props.handleFilter('All Open')
    }
  }

  const denyUser = async (sendUcla1Email) => {
    showLoading(true)
    const updatedUser = {
      email: formData.email,
      firstName: formData.firstName,
      sendUcla1Email,
      pendingItem: {
        cognitoLogin: row.original.CognitoLogin,
        workingCrmId: '',
        pendingStatus: 'Denied',
        notes: notesValue,
      }
    }

    const response = await updatePendingUser(updatedUser)

    if (!doError(response, setUserActionErrorMsg)) {
      setIsDirty(false)
      props.handleFilter('All Open')
    }
  }

  const deleteUser = async () => {
    showLoading(true)
    const deletedUser = {
      pendingItem: {
        cognitoLogin: row.original.CognitoLogin,
        workingCrmId: linkedCrmId,
        pendingStatus: 'Deleted',
        notes: notesValue,
        formData: formData,
      }
    }

    const response = await updatePendingUser(deletedUser)

    if (!doError(response, setUserActionErrorMsg)) {
      setIsDirty(false)
      props.handleFilter('All Open')
    }
  }

  const status = row.original.PendingStatus
  return (
    <PendingListItemTable >
      <thead>
        <tr>
          {status !== "Deleted" && status !== "Approved" &&
            <th colSpan="2">
              <PendingActionButton btnName="approve" disabled={!showCrmData || crmLookupErrorMsg || status === "Approved"} onClick={approveUser}/>
              <PendingActionButton btnName="deny"  disabled={status === "Denied"} onClick={() => denyUser(true)}/>
              <PendingActionButton btnName="deny w/o email"  disabled={status === "Denied"} onClick={() => denyUser(false)}/>
              <PendingActionButton btnName="delete"  onClick={deleteUser}/>
              {userActionErrorMsg &&
                <Alert icon={iconInfo} >
                  <div>{userActionErrorMsg}</div>
                </Alert>}
            </th>}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='crm-label'><b>Notes:</b></td>
          <td rowSpan="2">
            <form onSubmit={onSubmit}>
              <table>
                <tbody>
                    <tr>
                      <td><input size="80" name="notes" onChange={doNotesInputChange} value={notesValue}></input></td>
                      <td rowSpan="2"><PendingActionButton disabled={!isDirty} onClick={saveForm} btnName="save" /></td>
                    </tr>
                    <tr>
                      <td><input size="10" name="linkedCrmId" onChange={doCrmInputChange} disabled={!!showCrmData || ['New', 'In Progress', 'Denied'].indexOf(status) === -1} value={linkedCrmId}></input>
                        {['New', 'In Progress', 'Denied'].indexOf(status) > -1 &&
                          <button onClick={linkCrmId}>{lookupButtonText}</button>}
                        {['New', 'In Progress', 'Denied'].indexOf(status) > -1 &&
                          <button onClick={doBioDataLink}>EMAIL BIODATA</button>}
                        {crmLookupErrorMsg &&
                          <Alert icon={iconInfo} >
                            <div>{crmLookupErrorMsg}</div>
                          </Alert>}
                      </td>
                    </tr>
               </tbody>
            </table>
          </form>
          </td>
        </tr>
        <tr>
          <td className='crm-label'><b>CRM ID:</b></td>
        </tr>
        {showCrmData && <tr><td className='crm-label'>UCLA1 User ID:</td><td><b>{crmUser.uclaOneUserId}</b>{doDislaimer(crmUser.uclaOneUserId)}</td></tr>}
        {showCrmData && <tr><td className='crm-label'>CRM Names:</td><td>{crmUser.allNames}</td></tr>}
        {showCrmData && <tr><td className='crm-label'>CRM UID:</td><td>{crmUser.uid}</td></tr>}
        {showCrmData && <tr><td className='crm-label'>CRM Emails:</td><td>{crmUser.allEmails}</td></tr>}
        {showCrmData && <tr><td className='crm-label'>CRM Phones:</td><td>{crmUser.allPhones}</td></tr>}
        {showCrmData && <tr><td className='crm-label'>CRM Addresses:</td><td className='wrap-row'>{crmUser.allAddresses}</td></tr>}
        {showCrmData && <tr><td className='crm-label'>UCLA Education:</td><td className='wrap-row'>{crmUser.allEducation}</td></tr>}
        {showCrmData && <tr><td className='crm-label'>Birth Date:</td><td>{formatDate(crmUser.birthDate)}</td></tr>}
        {showCrmData && <tr><td className='crm-label'>Spouse:</td><td>{crmUser.spouseName}</td></tr>}
        <tr>
          <td colSpan="2" className='user-header'><b>USER SUPPLIED INFORMATION</b></td>
        </tr>
        <tr><td className='user-label'>Name:</td><td><b>{formData.firstName} {formData.lastName}</b></td></tr>
        <tr><td className='user-label'>First Name:</td><td><b>{formData.firstName}</b></td></tr>
        <tr><td className='user-label'>Middle Name:</td><td><b>{formData.middleName}</b></td></tr>
        <tr><td className='user-label'>Last Name:</td><td><b>{formData.lastName}</b></td></tr>
        <tr><td className='user-label'>Login username:</td><td><b>{row.original.CognitoLogin}</b></td></tr>
        <tr><td className='user-label'>Maiden name:</td><td><b>{formData.maidenName}</b></td></tr>
        <tr><td className='user-label'>Birth date:</td><td><b>{formData.birthDate}</b></td></tr>
        <tr><td className='user-label'>Grad year and degree:</td><td><b>{(formData.gradYear || '') + " " + (formData.degree || '')}</b></td></tr>
        <tr><td className='user-label'>Major:</td><td><b>{formData.major}</b></td></tr>
        <tr><td className='user-label'>Current email:</td><td><b>{formData.email}</b></td></tr>
        <tr><td className='user-label'>Past UCLA or student email:</td><td><b>{formData.pastUclaEmail}</b></td></tr>
        <tr><td className='user-label'>Phone number:</td><td><b>{formData.phoneNumber}</b></td></tr>
        <tr><td className='user-label'>Student UID:</td><td><b>{formData.studentUid}</b></td></tr>
        <tr><td className='user-label'>Affiliation:</td><td><b>{formData.affiliation}</b></td></tr>
        <tr><td className='user-label'>User submitted note:</td><td className='wrap-row'><b>{formData.comments}</b></td></tr>
      </tbody>
    </PendingListItemTable>
  );

}

export default PendingListUserForm
