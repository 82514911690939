import { Auth } from 'aws-amplify'

import { updateAuctionUser, updateUserRegistration } from '../../api'

export const startVerify = async (contact) => {

  const user = await Auth.currentAuthenticatedUser()

  let verifyCurrentUserAttributeResponse, updateUserAttributesResponse

  // prevent multiple texts
  if (user.attributes.phone_number === contact) {
    verifyCurrentUserAttributeResponse = await Auth.verifyCurrentUserAttribute("phone_number")
    console.log({verifyCurrentUserAttributeResponse})
  }
  else {
    // verification is sent automatically when updating
    updateUserAttributesResponse = await Auth.updateUserAttributes(user, { phone_number: contact })
    console.log({updateUserAttributesResponse})
  }

  return {success: true, verifyCurrentUserAttributeResponse, updateUserAttributesResponse}
}

export const submitCode = async (contact, verificationCode) => {
  const submitCodeResponse = await Auth.verifyCurrentUserAttributeSubmit((contact.indexOf('@') > 0) ? "email" : "phone_number", verificationCode)

  return submitCodeResponse
}

export const signUpUserForTextAlerts = async (auction, auctionUser, textAlertNumber) => {
  try {
    const updateTextAlertNumberResponse = await updateUserRegistration(auction, auctionUser, {textAlertNumber})
    console.log({updateTextAlertNumberResponse})

    // Brian, this is an example of how to update the check in status, put it whereever  you need it
    const updateCheckedInStatusResponse = await updateUserRegistration(auction, auctionUser, {isCheckedIn: true})
    console.log({updateCheckedInStatusResponse})

    const updateUserResponse = await updateAuctionUser({phoneNumber: textAlertNumber})

    console.log({updateUserResponse})
    return {success:true, updateTextAlertNumberResponse, updateUserResponse}
  }
  catch (e) {
    console.log(e)
    return e
  }
}

export const userDeclinesTextAlerts = async (auction, auctionUser) => {
  const textAlertNumber = 'declined'
  try {
    const updateTextAlertNumberResponse = await updateUserRegistration(auction, auctionUser, {textAlertNumber})
    console.log({updateTextAlertNumberResponse})

    return {success:true, updateTextAlertNumberResponse}
  }
  catch (e) {
    console.log(e)
    return e
  }
}

export const cardValidCheck = (dateString) => {
  //per Donor relations, warning should show up at the beginning of the month before the expiration date
  // ex: card expires 07/23, warning should show up starting 06/01/2023

  const today = new Date();
  
  const numExpMonth = parseInt(dateString.slice(0, 2), 10);
  const numExpYear = parseInt(dateString.slice(5, 7), 10);
  const currentYear = today.getFullYear() - 2000;
  const currentMonth = today.getMonth() + 1;
  
  //if the expiring year is less than current year, card is invalid
  if (numExpYear < currentYear) {
      return false;
  }
  
  //if expiration year is equal to current year, need to check current month
  if (numExpYear === currentYear) {   
      // if the exp month is one less than the current month, warning should show up
      if ((numExpMonth - 1) <= currentMonth) {
          return false;
      }
  }
  //special case for january for different year values
  //ex: current month 12/2022, exp date 01/23 - need to show as expiring soon
  if (numExpMonth === 1 &&
      currentMonth === 12 &&
      ((numExpYear - 1) === currentYear)
      ) {
      return false;
  }
  return true;
}