import React from 'react'

import Zindex from 'css/z-indexLayers.js';
import { device } from 'common/Device'

import styled from 'styled-components'

import BacktoTopImg from 'images/BacktoTop.svg'

const defaultFontSize = 16

const BacktoTopContainer = styled.div`
  position: absolute;
  right:${30/defaultFontSize}rem;
  bottom:${31/defaultFontSize}rem;
  z-index:${Zindex.zindexPopUpStackOrder1};
  cursor:pointer;
  @media ${ device.laptop } {
    bottom:${76/defaultFontSize}rem;
  }
  @media ${ device.mobile } {
    bottom:${232/defaultFontSize}rem;
  }
`

class BacktoTop extends React.PureComponent {
  backToTop() {
  	window.scrollTo({
  		top: 0,
  		behavior: 'smooth'
  	})
  }

  render() {
    return (
      <BacktoTopContainer className="hide-on-print">
        <img src={BacktoTopImg} alt="Back To Top" onClick={ e => this.backToTop() } />
      </BacktoTopContainer>
    )
  }
}

export default BacktoTop;
