import React from 'react'
import styled from 'styled-components';

import { history } from 'services/history.js'
import { getCookies } from '../mygivingutil.js'

import { StrokeBlue } from 'css/Buttons';
import arrowUpBlue from 'images/arrow-up-primary-blue.svg';
import arrowDownBlue from 'images/arrow-down-primary-blue.svg';
import arrowUpWhite from 'images/arrow-up-white.svg';
import arrowDownWhite from 'images/arrow-down-white.svg';

import { analyticsGiveAgain } from '../analytics';

import { PrintGiftButton, ArrowRightLink } from './GiftInterface';
import MoreInfo from './MoreInfo';
import { ReceiptActionStyles } from './HistoryStyles';
import { NON_RECEIPTABLE_MESSAGES } from './InfopopContent'

const defaultFontSize = 16;

const getNonReceiptableText = code => {
  // use most generic-sounding message if code not found (also applies to code=NORECEIPTFLAG)
  return NON_RECEIPTABLE_MESSAGES[code] || NON_RECEIPTABLE_MESSAGES["STOCK"];
}

const MoreGiftButton = styled( StrokeBlue )`
  display:inline-block;
  &:after {
    content:'';
    display:${ props => props.loading? 'none' : 'inline-block' };
    vertical-align:middle;
    height:${ 5/defaultFontSize }rem;
    width:${ 8/defaultFontSize }rem;

    margin:0 0.15rem;

    background-size:100% auto;
    background-repeat:no-repeat;
    background-position:50% 50%;
    background-image:url('${ props => props.expanded ? arrowUpBlue : arrowDownBlue }');
  }

  &:hover:after {
    background-image:url('${ props => props.expanded ? arrowUpWhite : arrowDownWhite }');
  }
`

// possibly use shouldComponentUpdate to control number of rerenders
class GiftActions extends React.Component  {
   _isMounted = false; // fix memory leak issue
  constructor(props) {
   super(props);
    this.state = {
      showInfoPop: false
    }
  }

  componentDidMount() {
    this._isMounted = true;

    // toggle info popup when disabled print button is clicked
    this.showPopup = () => {
      setTimeout(() => {
        if (this._isMounted && !this.state.showInfoPop) {
          this.setState({ showInfoPop: true });
        }
      }, 0 );
    }

    this.hidePopup = () => {
      setTimeout(() => {
        if (this._isMounted && this.state.showInfoPop) {
          this.setState({ showInfoPop: false });
        }
      }, 0 );
    }
  }

  togglePopup = () => {
    if (this.state.showInfoPop) {
      this.hidePopup();
    }
    else {
      this.showPopup();
    }
  }

  // print if receiptable else show info popup
  handlePrintClick = (e) => {
    if (this.props.isReceiptable) {
      this.props.printReceipt( this.props.original );
    }
    else {
      e.preventDefault();
      this.togglePopup();
    }
  }

  handleDetailClick = (e) => {
    e.preventDefault();

    if (this.props.original.loading) {
      // still loading, ignore
      return
    }

    // send the event up
    this.props.doExpand();

    // blur the button (improvising here)
    e.currentTarget.blur();
  }

  // cancel subscriptions and async tasks
  componentWillUnmount() {
     this._isMounted = false;
     clearTimeout(this.showPopup);
     clearTimeout(this.hidePopup);
   }

  doGiveAgain = async (e, linkUrl) => {
    e.preventDefault();
    analyticsGiveAgain( this.props.original.revenueLookupId )
    console.log({givingSiteLink:linkUrl});
    const winRef = window.open(); // open window first to prevent popup blockers from activating
    await getCookies('giveagain');
    winRef.location = linkUrl;
  }

  goToPledgesPage = async (e, linkUrl) => {
    e.preventDefault()
    history.push(this.props.linkUrl)
  }

  render() {
    let buttonText = "More";
    let loading = false;
    let expanded = false;
    const gift = this.props.original

    if (this.props.isExpanded) {
      buttonText = "Less";
      expanded = true;
    }
    else if (gift.loading) {
      buttonText = "...";
      loading = true;
    }

    // Parent Pledge rows are not included in giving history from the server, but we still filter it out just to be safe
    if (gift.revenueType === "ST") {
      return (<></>) // should never happen but just in case things ever change on the server
    }
    // Planned Gift with no installments made yet
    else if (gift.revenueType === "PG" && gift.pledgeBalance === gift.pledgeAmount) {
      return (<></>)
    }
    // Recurring Gift, Planned Gift with installments
    else if (gift.revenueTypeHasInstallments || gift.revenueTypeHasInstallmentSchedule) {
      return (
        <ReceiptActionStyles dataRev={ gift.revenueLookupId } className="expandable-row">
          <MoreGiftButton
            data-testid="more-button"
            isSmall={ true }
            onClick={ this.handleDetailClick }
            loading={ loading ? 1 : undefined }
            expanded={ expanded }
            linkUrl={ this.props.linkUrl }
          >
            <span>{ buttonText }</span>
          </MoreGiftButton>
        </ReceiptActionStyles>
      )
    }
    else {
      let reason = "";
      switch ( gift.paymentMethod ) {
        case 'Gift-in-kind':
          reason = 'GIFTINKIND';
        break
        default:
          reason = gift.notReceiptableReasonCode;
        break
      }

      return (
        // straight gift
        <ReceiptActionStyles data-testid="print-button-group">
          <span className="gift-actions-header">Receipt</span>
          <div className="print-button-container">
            { !this.props.isReceiptable &&
              <MoreInfo
                htmlName={`more_info_${ gift.revenueLookupId }`}
                htmlId={`more_info_${ gift.revenueLookupId }_${ this.props.index }`}
                srText={"Why can't I print this?"}
                isOpen={ this.state.showInfoPop }
              >
                {getNonReceiptableText( reason )}
              </MoreInfo>
            }
            <PrintGiftButton
              aria-label={ this.props.isReceiptable ? "Print button" : "Disabled print button"}
              disablePrint={ !this.props.isReceiptable }
              handlePrintClick={ this.handlePrintClick }
              onBlur={ this.hidePopup }
              onFocus={ this.showPopup }
              onMouseEnter={ this.showPopup }
              onMouseLeave={ this.hidePopup }
            />
          </div>

          {/* pledge payments */}
          {this.props.linkUrl && gift.revenueType === "PP" &&
            <ArrowRightLink onClick={(e) => { this.goToPledgesPage(e, this.props.linkUrl) }} target="_blank" href="#">{ this.props.linkText }</ArrowRightLink>}

          {/* everything but pledge payments */}
          {this.props.linkUrl && gift.revenueType !== "PP" &&
            <ArrowRightLink onClick={(e) => { this.doGiveAgain(e, this.props.linkUrl) }} target="_blank" href="#">{ this.props.linkText }</ArrowRightLink>}

        </ReceiptActionStyles>
      )
    }
  }
}

export default GiftActions;
