import { useState, useEffect } from 'react'

import { getDocumentList, getFundReports } from '../reports/documentsapi'

import ReportsAlertIcon from './ReportsAlertIcon'
import ReportsNotification from './ReportsNotification'

import css from './sass/alert.module.scss'

function ReportsAlert(props) {
  const [reports, setReports] = useState({ documentList: null, fundReports: null, });

  useEffect(() => {
    const documentList = getDocumentList();
    const fundReports = getFundReports();
    setReports({ documentList, fundReports });
  }, []);

  return (
    <>
      {(reports.fundReports || reports.documentList) && (
        <ReportsNotification>
          <div className={css.alertBannerIconContentContainer}>
            <ReportsAlertIcon />
            <div className={css.alertBannerMessage}>
              <p><b>You have new reports to review!</b></p>
              <p>The reports are now available on the <a href="/reports/">REPORTS</a> page.</p>
            </div>
          </div>
        </ReportsNotification>
      )}
    </>
  )
}

export default ReportsAlert
