import { Component } from 'react'

import RadioButton from './CustomizedRadio'
import DropDownMenu from 'common/forms/DropDown'
import DeleteNonPrimary from './DeleteNonPrimary'
import MoreInfo from 'common/MoreInfo'

import * as cs from 'css/CommonStyle'
import * as forms from 'css/forms'
import { ProfileDataContent } from '../personal/MyNames'

import { CONTACT_COUNTRIES } from 'common/forms/data/Countries'
import { CONTACT_STATES } from 'common/forms/data/States'

import styled from 'styled-components'
import { device } from 'common/Device'

const defaultFontSize = 16
const phoneRegex = /^\d{5}(?:[-\s]\d{4})?$/;

const AddressContainer = styled.div`
margin-bottom:30px;
& > div:nth-child(1),& > fieldset{
  padding:20px;
  background:#F5F5F5;
  border-radius:5px;
  border: 1px solid #DDDDDD;
}
h5{
  margin:5px;
  display:inline-block;
}

.address-flex-1 {
  display:flex;
  flex-wrap:wrap;
  flex: 0 0 75%;
  justify-content: space-between;
  margin:0;

    .sub-address-flex-1,.sub-address-flex-2,.sub-address-flex-4  {
      flex:0 47%;
    }
    .sub-address-flex-3 {
      flex:1 0 100%;
    }
    .sub-address-flex-6{
      flex:0 31%;
    }
}
.postal-address-divide-style{
  display:flex;
  flex:0 100%;
  justify-content:space-between;
}

@media ${device.tablet}{
  .address-flex-1{
    flex:0 0 95%;
    .sub-address-flex-5{
      flex:0 0 15%;
    }
  }

}

@media ${device.mobile}{
  .address-flex-1 {
    flex-wrap:nowrap;
    flex-direction:column;
    flex:0 0 90%;

    ${ [1, 2,3,4,5,6].map(item => (
      `.sub-address-flex-${item} {
        flex:none !important;
        };
     `))}

  }
  .postal-address-divide-style{
    flex-direction:column;
    flex:none;
    & >div{
      flex:none !important ;
    }
  }

}
`

const PrimaryContact=styled.div`


`
export const MoreInfoPositionStyles = styled.div`
  flex:none !important;
  position:relative;
  left:${ -16/defaultFontSize }rem;
  top:${ 46/defaultFontSize }rem;
  height:1rem;
  width:1rem;
  margin:0;

@media ${device.tablet}{
  margin:0 ${10/16}rem !important;
  top:${ 3/defaultFontSize }rem;
  left:0;
}
@media ${device.mobile}{
  &&&{
  margin:0 ${15/16}rem !important;
  }
}
`
export const PrimaryLabel = styled.label`
line-height:100px;`

class Address extends Component {
  constructor(props){
    super(props);
    this.state={
      zipcodeValid:false,
      isEmptyZipCode:false,
      isEmptyStreetAddress:false,
      isEmptyCity:false,
      defaultCountry:"United States"
    }
  }

  async componentDidMount() {
    if (this.props.data.postCode) {
      if (phoneRegex.test(this.props.data.postCode)) this.setState({ zipcodeValid: true, isEmptyZipCode: false });
    }
    else{
    this.setState({ zipcodeValid: true})
    }
  }

  handleValidation=(e)=> {
    switch (e.target.name) {
      case this.props.propName + '.postCode':
        this.setState({
          zipcodeValid: phoneRegex.test(e.target.value) ? true : false,
          isEmptyZipCode: e.target.value ? false : true
        }, this.updateValidation); break;
      case this.props.propName + '.addressBlock':
        this.setState({ isEmptyStreetAddress: e.target.value ? false : true }, this.updateValidation); break;
      case this.props.propName + '.city':
      this.setState({ isEmptyCity: e.target.value ? false : true }, this.updateValidation); break;
      default:
        break;
    }
  }
  updateValidation=(e)=>{
    this.props.updateValidation((this.state.isEmptyZipCode ||!this.state.zipcodeValid)||this.state.isEmptyStreetAddress|| this.state.isEmptyCity)
   }

  render() {
    return (
      <AddressContainer>
        <fieldset>
          <ProfileDataContent>

            <div className="address-flex-1">
              <div className="sub-address-flex-1">
                <label> Type</label>
                <cs.TextInputdiv>
                  <DropDownMenu
                    readOnly={this.props.readOnly}
                    name={this.props.propName + '.addressType'}
                    title={this.props.data.addressType}
                    list={this.props.availableDisplayTypes}
                    changeHandler={this.props.handleTypeChange}
                  />
                </cs.TextInputdiv>
              </div>

              <div className="sub-address-flex-2">
                <label> Country</label>
                <cs.TextInputdiv>
                  <DropDownMenu
                    name={this.props.propName + '.country'}
                    title={this.props.data.country || this.state.defaultCountry}
                    list={CONTACT_COUNTRIES}
                    changeHandler={this.props.changeHandler}
                  />
                </cs.TextInputdiv>
              </div>

              <div className="postal-address-divide-style">
                <div className="sub-address-flex-4">
                  <label>Care of</label>
                  <cs.TextInputdiv>
                    <forms.Input
                      name={this.props.propName + '.careOf'}
                      value={this.props.data.careOf}
                      onChange={this.props.changeHandler}
                      onFocus={this.handleValidation}
                      onBlur={this.handleValidation}
                    />
                     {this.state.isEmptyCity&&<cs.ErrorSpan textAlign="right">{"City cannot be empty"}</cs.ErrorSpan>}
                  </cs.TextInputdiv>
                </div>
              </div>

              <div className="sub-address-flex-3">
                <label> Street Address <span className="required">*</span></label>
                <cs.TextInputdiv>

                  <forms.TextArea  className={this.state.isEmptyStreetAddress?"error":""}
                  rows={(this.props.data.addressBlock.split("\n").length) > "3" ? "5" : "3"} borderColor={"#C2D1D9"}
                    name={this.props.propName + '.addressBlock'}
                    value={this.props.data.addressBlock}
                    onChange={this.props.changeHandler}
                    onFocus={this.handleValidation}
                    onBlur={this.handleValidation}
                  />
                  {this.state.isEmptyStreetAddress&&
                    <cs.ErrorSpan textAlign="right">{"Street Address cannot be empty"}</cs.ErrorSpan>}
                </cs.TextInputdiv>

              </div>

              {(this.props.data.country === 'United States' || !this.props.data.country) ?
                <div className="postal-address-divide-style">

                  <div className="sub-address-flex-4">
                    <label>City</label>
                    <cs.TextInputdiv>
                      <forms.Input
                       className={this.state.isEmptyCity?"error":""}
                        name={this.props.propName + '.city'}
                        value={this.props.data.city}
                        onChange={this.props.changeHandler}
                        onFocus={this.handleValidation}
                        onBlur={this.handleValidation}
                      />
                       {this.state.isEmptyCity&&<cs.ErrorSpan textAlign="right">{"City cannot be empty"}</cs.ErrorSpan>}
                    </cs.TextInputdiv>

                  </div>

                  <div className="sub-address-flex-5">
                    <label>State</label>
                    <cs.TextInputdiv>
                      <DropDownMenu
                        name={this.props.propName + '.state'}
                        title={this.props.data.state || "CA"}
                        list={CONTACT_STATES}
                        changeHandler={this.props.changeHandler}
                      />
                    </cs.TextInputdiv>
                  </div>

                  <div className="sub-address-flex-6">
                    <label>Zip Code <span className="required">*</span></label>
                    <cs.TextInputdiv>
                      <forms.Input
                      className={(!this.state.zipcodeValid || this.state.isEmptyZipCode)?"error":""}
                        name={this.props.propName + '.postCode'}
                        value={this.props.data.postCode}
                        onChange={(e)=>{this.props.changeHandler(e);this.handleValidation(e)}}
                        onFocus={this.handleValidation}
                        onBlur={this.handleValidation}
                      />
                      {this.state.isEmptyZipCode&&
                    <cs.ErrorSpan textAlign="right">{"Zip Code contains only numbers and cannot be empty"}</cs.ErrorSpan>}
                    {(!this.state.zipcodeValid&&!this.state.isEmptyZipCode) &&
                       <cs.ErrorSpan textAlign="right">{"Invalid Zip Code"}</cs.ErrorSpan>}
                    </cs.TextInputdiv>
                  </div>
                </div>
                : <div className="sub-address-flex-3">
                  <label>City, Province, Postal Code <span className="required">*</span></label>
                  <cs.TextInputdiv>
                    <forms.Input
                      placeholder="e.g. Seoul, Jongno-gu,Seoul 30174"
                      name={this.props.propName + '.city'}
                      value={this.props.data.city}
                      onChange={this.props.changeHandler}
                    />
                  </cs.TextInputdiv>

                </div>}

            </div>

            <PrimaryContact className="primary-contact">
              <RadioButton htmlFor={this.props.propName}>
                <input type="radio" id={this.props.propName}
                  className="form-check-input"
                  name="AddressPrimary"
                  value={this.props.propName}
                  checked={this.props.data.isPrimary}
                  onChange={(e) => this.props.setPrimary('address', this.props.propName)}
                /> Primary
                <span className="checked-circle"></span>
              </RadioButton>
            </PrimaryContact>

            {this.props.remove ?
              <DeleteNonPrimary deletepropName={this.props.propName} remove={this.props.remove} /> :
              <MoreInfoPositionStyles>
                <MoreInfo
                  htmlName={"more_info_primary_address_details"}
                  htmlId={"more_info_primary_address_id_details"}
                  srText={"Identifying the primary address?"}>
                  This is the method we will use to contact you via mail.
                </MoreInfo>
              </MoreInfoPositionStyles>
            }


          </ProfileDataContent>

        </fieldset>
      </AddressContainer>
    )
  }
}


export default Address
