import { useState, useEffect, useMemo } from 'react'

import IdmReactTable from "common/IdmReactTable"
import { showLoading } from 'services/util'
import Alert from 'common/notifications/Alert'
import iconInfo from 'images/iconColorinfo.svg'
import deleteIcon from 'images/trash-can-icon.png';

import { formatDate, formatPhone } from 'admin/adminutil'
import LinkUserWrapper from 'admin/linkuser/LinkUserWrapper'
import { QueryResultsStyles } from 'admin/QueryStyles'
import css from '../sass/confirm.modal.module.scss'
import AdminDeleteIcon from 'images/x-red.svg'
import DeleteConfirmModal from 'common/ConfirmModal'
import { deleteUnconfirmedUser, getUnconfirmedUserList } from './adminapi'

function UnconfirmedUsers(props) {
  const [tableData, setTableData] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [userToDelete, setUserToDelete] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)

  useEffect(() => {
    setErrorMsg()
    getData()
  }, [])

  const tableProps = {
    addExpanderColumn: true,
    initialState: {
      sortBy: [
       {
        id: 'username',
        desc: false
       }
      ]
    },
  }

  const mainTableColumns = [
    { Header: 'Username', id: 'username', accessor: 'Username', Cell: cell => cell.value},
    { Header: 'Date Created', id: 'date', accessor: 'UserCreateDate', Cell: cell => formatDate(cell.value)},
    { Header: '  ', desc: true, Cell: displayDeleteIcon },
  ]

  const getData = async () => {
    showLoading(true)
    const response = await getUnconfirmedUserList();

    if (!response || response.error) {
      await setErrorMsg(response && response.error ? response.error.msg || 'An Error Occurred' : 'An Error Occurred')
      await setTableData([])
      showLoading(false)
      return;
    }

    // setTableData(dummyResponse.success)
    setTableData(response.success)

    console.log(response)

    showLoading(false)
  }

  function displayDeleteIcon({ row }) {
    return (
      <center className={css.rowIcon}><img width="25" height="25" title='DELETE' alt='' src={deleteIcon}
              onClick={e => confirmDeleteUser(row.original, row.index)} /></center>
    )
  }

  const confirmDeleteUser = (user, index) => {
    setUserToDelete(user)
    setShowDeleteModal(true)
  }

  const finishDeleteUser = async (e) => {
    setErrorMsg()
    showLoading(true)
    const deleteReponse = await deleteUnconfirmedUser(userToDelete.Username)

    if (!deleteReponse || deleteReponse.error || deleteReponse.errorMessage) {
      let errorMessage = "There was an error deleting the user."
      if (deleteReponse)
        errorMessage = deleteReponse.errorMessage || deleteReponse.error || errorMessage
      setErrorMsg(errorMessage.msg || errorMessage)
      showLoading(false)
    }
    else {
      await getData();
    }
    setShowDeleteModal(false)
  }

  return (
    <LinkUserWrapper>
      <span style={{"whiteSpace": 'nowrap'}}>
        <h2>CIDM Unconfirmed Users</h2>

        {errorMsg &&
          <Alert icon={iconInfo} >
            <div>{errorMsg}</div>
          </Alert>
        }

      </span>

      <QueryResultsStyles searchType={'unconfirmedusers'}>
        {tableData.length > 0 &&
          <IdmReactTable
            { ...tableProps }
            data={ tableData }
            columns={ mainTableColumns }
          />}

         {showDeleteModal &&
          <DeleteConfirmModal
            onConfirm={finishDeleteUser}
            onCancel={() => setShowDeleteModal(false)}
            cancelText={"CANCEL"}
            confirmText={"OK"}
          >
            <div className={css.modalInner}>
              <div><img src={AdminDeleteIcon} alt='Delete Document icon'/></div>
              <div className={css.modalText}>
                Are you sure you want to delete the User:
                <br/><strong>{userToDelete.Username}</strong>?
              </div>
            </div>
          </DeleteConfirmModal>
        }

      {tableData.length === 0 &&
        <section>
        <div>
          <span>No Unconfirmed Users Found</span>
        </div>
      </section>}

      </QueryResultsStyles>
    </LinkUserWrapper>
  )
}

export default UnconfirmedUsers

const dummyResponse = {
  "success": [
    {
      "Username": "x@y.com",
      "UserCreateDate": "2022-05-12T18:51:39.394Z"
    },
    {
      "Username": "+13105550000",
      "UserCreateDate": "2022-05-12T18:51:16.059Z"
    }
  ]
}

