
//used constant values
export const MIN_YEAR_VALUE = 1900;
export const MIN_DATE_VALUE = 1;
export const MAX_DATE_VALUE = 31;

// formattting birthdate plain string
export const formatBirthDate = unfilteredDate => {
  if (!unfilteredDate) return {};

  let birthDate = null;

  birthDate = {
    year:unfilteredDate.substring(0, 4).replace(/^0000+/, ''),
    month:unfilteredDate.substring(4, 6).replace(/^0+/, ''),
    day:unfilteredDate.substring(6, 8).replace(/^0+/, '')
  }
  return (birthDate);
}

// takes a new value for month, day, year and return a new string of the format CRM likes
// newValue = numeric value of day, month or year
// unit = day, month or year
export const adjustBirthDate = (original, newValue, newIndex, unit) => {
  const old = {
    year:original.substring(0, 4),
    month:original.substring(4, 6),
    day:original.substring(6, 8),
  }
  if (unit === 'year') {
    return "" + newValue + old.month + old.day;
  }
  else {
    newValue = "" + (1*newIndex+1);
    if (newValue.length === 1) newValue = "0" + newValue;
    
    if (unit === 'month') return "" + old.year + newValue + old.day;
    else                  return "" + old.year + old.month + newValue;
  }
}