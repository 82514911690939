// loading interstitial
import styled from 'styled-components'
import graphic from 'images/loading.gif'
import Zindex from 'css/z-indexLayers.js';

const LoadingScreen = styled.div`
  position:fixed;
  top:0;
  left:0;
  z-index:${Zindex.zindexAllTheTop};
  height:100vh;
  width:100vw;
  display:${ props => props.isLoading ? "flex" : "none" };
  align-items:center;
  justify-content:center;
  background:rgba(255,255,255,0.8);
  font-weight:bold;
  font-size:16px;
  text-transform:uppercase;
`

const Loading = props => {
  return (
  <LoadingScreen {...props}>
    <img src={ graphic } alt="Loading..." />
  </LoadingScreen>
)}

export default Loading
