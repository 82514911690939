import styled from 'styled-components'

import Zindex from 'css/z-indexLayers.js';
import { primaryBlue, primaryYellow } from 'css/Colors'
import { device } from 'common/Device'

// preparing for eventual swap with a global (imported) constant
export const defaultFontSize = 16

const Profile = styled.div`
  display:flex;
  height:100%;
  box-sizing:border-box;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  position:relative;
  overflow:hidden;
  z-index:${Zindex.zindexOverlaystackOrder1};
  background:${ primaryBlue };
  color:#FFFFFF;
  padding:${ 80/defaultFontSize }rem ${ 40/defaultFontSize }rem;

  h2 {
    color:${ primaryYellow };

  }

  @media ${ device.tablet } {
   //padding:${ 15/defaultFontSize }rem ${ 40/defaultFontSize }rem;
   padding:0;
    h2 {
      margin:0;
    }
  }

  @media ${ device.mobile } {
    padding:${ 15/defaultFontSize }rem ${ 40/defaultFontSize }rem;

    h2 {
      margin:0;
    }
  }
`

export default Profile
