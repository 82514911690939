import styled from 'styled-components';

import * as colors from 'css/Colors';
import { device } from 'common/Device'
import { InnerContent } from 'css/CommonStyle';

const defaultFontSize = 16;

export const Accounts = styled(InnerContent)`
  border-radius: 2px;
  padding: 10px 0px;

  .paragraph-message {
    padding: 0 4%;
    font-size: calc(16rem/16);
    font-weight: bold;
  }

  .multiple-accounts {
    margin: 30px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  /* ie workaround for justify-content: space-evenly */
  .space-evenly-fix {
    display: flex;
    justify-content: space-between;

    &:before,
    &:after {
      content: '';
      display: block;
    }
  }

  .user-multiple-accounts {
    width: calc(275rem/${defaultFontSize});
    cursor: pointer;

    & p:hover {
      color: ${colors.primaryBlue};
    }
  }

  @media ${ device.mobile } {
    .multiple-accounts {
      flex-direction: column;
    }

    .user-multiple-accounts {
      width: 100%;

      &:first-child {
        margin-bottom: 20px;
      }
    }
  }
`;

export const AccountDetails = styled(InnerContent)`
  text-align: center;
  border: 2px solid ${colors.primaryYellow};
  border-radius: 7px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 15px 20px;
  box-sizing: border-box;

  p {
    margin: 0;
    padding: 0;
  }
`;
