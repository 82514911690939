export const urlQueryParser = (urlQueryString) => {

    //remove the ?
    const paramStr = urlQueryString.slice(1)
    // split out each parameters key value pair
    const paramArr = paramStr.split('&')
    //create object to return
    const returnObj = {}
    //break each key value pair up and assign in return object
    paramArr.forEach((param) => {
        const paramPair = param.split('=')
        returnObj[paramPair[0]] = paramPair[1]
    })

    return returnObj
}