import { Auth } from 'aws-amplify'
import { theme } from 'ThemeContext';

const redirectUrl = window.location.protocol + '//'
                  + window.location.hostname
                  + (window.location.port ? ':' + window.location.port : '');

const userPool = {
  poolId: process.env.REACT_APP_USER_POOL_ID,
  webClientId: theme?.cognitoClientId, // theme is undefined in jest tests for some reason
  domain: process.env.REACT_APP_USER_POOL_DOMAIN,
  region: process.env.REACT_APP_USER_POOL_REGION,
}

export const awsconfig = {
  Auth: {
    region: userPool.region,
    userPoolId: userPool.poolId,
    userPoolWebClientId: userPool.webClientId,
    mandatorySignIn: false,

    // seems to be needed for Auth.signIn with email as username (but not Auth.signUp) default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    oauth: {
      domain: userPool.domain,
      scope: ['phone', 'email', 'profile', 'openid'],
      redirectSignIn: redirectUrl + '/postlogin',
      redirectSignOut: redirectUrl,
      responseType: 'token',
    },
  },

  // hack because amplify sends the access token instead of the id token (but the hack doesn't work for subscriptions)
  // https://github.com/aws-amplify/amplify-js/issues/3326
  // https://github.com/aws-amplify/amplify-swift/issues/780
  // https://github.com/aws-amplify/amplify-js/issues/4751 (subscriptions)
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
};

export const socialParams = {
  ClientId: userPool.webClientId,
  UserPoolId: userPool.poolId,
  AppWebDomain: userPool.domain,
  TokenScopesArray: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  RedirectUriSignIn: redirectUrl + '/signin',
  RedirectUriSignOut: redirectUrl,
  ResponseType: 'token',
};

export const signOutUrl = 'https://' + socialParams.AppWebDomain + '/logout'
                     + '?logout_uri=' + socialParams.RedirectUriSignOut
                     + '&client_id=' + socialParams.ClientId;

const socialOauthUrl = 'https://' + socialParams.AppWebDomain + '/oauth2/authorize'
                     + '?redirect_uri=' + socialParams.RedirectUriSignIn
                     + '&response_type=token'
                     + '&client_id=' + socialParams.ClientId;

export const fbOauthUrl = socialOauthUrl + '&identity_provider=Facebook';
export const googleOauthUrl = socialOauthUrl + '&identity_provider=Google';

// used by apps like UCLA1 to manually force signin (will auto login the user if possible)
const manualOauthUrl =
  "https://" + userPool.domain + "/oauth2/authorize" +
  "?redirect_uri=" + redirectUrl + "/postlogin" +
  "&response_type=TOKEN" +
  "&client_id=" + userPool.webClientId +
  "&scope=phone email profile openid"

export const emailPasswordManualOauthUrl = manualOauthUrl + "&identity_provider=COGNITO"
export const facebookManualOauthUrl = manualOauthUrl + "&identity_provider=Facebook"
export const googleManualOauthUrl = manualOauthUrl + "&identity_provider=Google"
export const linkedInManualOauthUrl = manualOauthUrl + '&identity_provider=Continue-with-LinkedIn';
