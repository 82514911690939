import React from 'react'

import { HistoryDropdownStyles } from './HistoryStyles'

class SimpleDropdown extends React.Component {
  constructor( props ) {
    super( props )
    this.state = {
      isOpen: false
    }
    this.timeOutId = null
    this.listRef = React.createRef()
  }

  getSelectedLabel( columns, id ) {
    return columns.find( item => item.id === id ) || "None"
  }

  handleClick = (e) => {
    this.setState( cs => ({ isOpen: !cs.isOpen }) )
  }

  handleSelect = (e) => {
    this.props.onUpdate( e.target.getAttribute('value') )

    this.setState({ isOpen: false })
  }


  // TODO: Implement keyboard interactions
  blurHandler = (e) => {
    this.timeOutId = setTimeout(() => {
      this.setState({
        isOpen: false
      })
    })
  }

  focusHandler = (e) => {
    clearTimeout(this.timeOutId)
  }

  render() {
    return (
      <HistoryDropdownStyles
        onBlur={ this.blurHandler }
        onFocus={ this.focusHandler }
      >
        <label htmlFor={ "sort_results" }>{ this.props.label }</label>
        <button
          aria-haspopup="true"
          aria-expanded={ this.state.isOpen }
          onClick={ this.handleClick  }
          name={ "sort_results" }
        > { this.getSelectedLabel(this.props.columns, this.props.sorted.id).header } </button>

        { this.state.isOpen && (
          <ul onClick={ this.handleSelect }>
            { this.props.columns.map( (item, index) => (
              <li key={ index } className={ this.props.sorted.id === item.id? "selected" : ""}>
                <button ref={ index === 0? this.listRef : null } value={ item.id.toString() } >
                { item.header }
                </button>
              </li>
            ))}
          </ul>
        )}
      </HistoryDropdownStyles>
    )
  }

}

export default SimpleDropdown
