import { useEffect, useState } from 'react'

import infoIcon from '../components/images/info-icon-orange.svg'

import adminCss from './sass/adminpage.module.scss'

export default function AuctionDetailsAlert(props) {

  const [alertHeader, setAlertHeader] = useState('')
  const [alertMessage, setAlertMessage] = useState('')

  useEffect(() => {

    switch(props.liveStatus) {
      case "ended":
        setAlertHeader('Auction details and items cannot be edited when the auction has ended!')
        setAlertMessage('')
        break;
      case "started":
        setAlertHeader('Auction details and items cannot be edited while the bidding is live!')
        // setAlertMessage('Please unpublish this auction to make any auction detail changes.')
        break;
      case "before":
        setAlertHeader('')
        setAlertMessage('')
        break;
      default:
        setAlertHeader('')
        setAlertMessage('')
    }

  }, [props.liveStatus])

  if (props.liveStatus === 'before') return <></>

  return (
    <div className={adminCss['details-alert-container']}>

      <img src={infoIcon} alt={'Alert icon'} />
      <div>
        <p className={adminCss['details-alert-header']}>
          {alertHeader}
        </p>

        <p className={adminCss['details-alert-message']}>
          {alertMessage}
        </p>
      </div>
      
    </div>
  )
}