import { useState, useEffect, useContext, useLayoutEffect } from 'react';
import { withRouter } from 'react-router-dom'

import { history } from 'services/history.js'
import { ThemeContext } from 'ThemeContext';
import { showLoading, initializeDate } from 'services/util'
import { getCookies, formatAmount } from '../mygivingutil'

import { getPledges } from './pledgesapi'
import PayPledgeDetail from './PayPledgeDetail'
import { StrokeBlue, FilledBlue } from 'css/Buttons'
import css from './sass/paypledges.module.scss'

const labels = ["PLEDGE DATE", "FUND", "PLEDGE TOTAL", "PLEDGE BALANCE", "PAYMENT"];

function PayPledges(props) {
  const theme = useContext(ThemeContext)
  const [pledges, setPledges] = useState()
  const [errorMsg, setErrorMsg] = useState(null)
  const [paymentTotal, setPaymentTotal] = useState(0)
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setErrorMsg()
    loadPledges()
  }, [])

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const loadPledges = async () => {
    showLoading(true)
    const revIds = window.location.href.split('/').pop().split(',')
    const allPledges = await getPledges()

    // treat /paypledge the same as /paypledge/all - request from DR
    if (revIds[0] === "all" || revIds[0] === "paypledge") {
      setPledges(allPledges.filter(pledge => pledge.agency !== 'Regents')
                           .filter(pledge => pledge.pledgeBalance))
    }
    else {
      setPledges(allPledges.filter(pledge => revIds.indexOf(pledge.revenueLookupId) > -1)
                           .filter(pledge => pledge.pledgeBalance))
    }

    showLoading(false)
  }

  const goToGivingSite = async (e) => {
    e.preventDefault();
    const winRef = window.open(); // open window first to prevent popup blockers from activating
    await getCookies('paynow');

    const params = pledges
      .reduce((acc, curr) => {   // combine totals for any pledges going to the same fund
        const index = acc.findIndex(p => p.fundNumber === curr.fundNumber);
        if (index !== -1) {
          acc[index].amountToPay += curr.amountToPay;
        } else {
          acc.push(curr);
        }
        return acc;
      }, [])
      .reduce((prev, pledge, index) => {
        const count = 1*index + 1;
        if (index === 0)
          return prev + "amount=" + pledge.amountToPay + "&fund=" + pledge.fundNumber
        else
          return prev += ("&A" + count) + "=" + pledge.amountToPay + "&F" + count + "=" + pledge.fundNumber   // count starts at 2
      }, "")

    const pledgeSiteLink = "https://giving.ucla.edu/campaign" + (!theme.isProdEnv ? "Test/Donate.aspx?Test=Y&" : "/Donate.aspx?") + params + "&ref=DP&ProdSite=Y&Lock_Amount=Y&SiteNum=100"
    console.log({pledgeSiteLink})
    winRef.location = pledgeSiteLink
  }

  const amountToPayHandler = ((pledge, amountToPay) => {
    pledge.amountToPay = amountToPay
    setPaymentTotal(getPaymentTotal())
  })

  const getPaymentTotal = (() => {
    return formatAmount(pledges.reduce((acc, curr) => {
      return 1*acc + 1*curr.amountToPay
    }, 0))
  })

  const isMultiple = pledges && pledges.length > 1;

  return (
    <div className={css["pay-pledges-page"]}>
      <h1 id="landingPageTitle">Make Pledge Payment{isMultiple && <>s</>}</h1>

      {pledges && pledges.length > 0 &&
        <p>To make a payment{isMultiple && <> on one or more pledge</>}, choose from the drop-down menu {isMultiple && <>for each pledge </>}whether you’d like to make a single payment,
          pay the total balance, pay a custom amount, or make no payment at this time.
          Please review {isMultiple && <>all of </>}your choice{isMultiple && <>s</>} before clicking “Pay Now” to proceed to the payment page.</p>}

      {pledges && pledges.length === 0 &&
        <p>You do not have any open pledges to UCLA at this time. If you would like to make a new pledge, please
           visit <a alt="UCLA Giving Site" rel="noreferrer" target="_blank" href="https://giveto.ucla.edu/">giveto.ucla.edu</a>.</p>}

      <StrokeBlue
        className={css["view-open-pledges-button"]}
        onClick={() => history.push("/pledges")}
        height={"2.6"}
        borderWidth={2}
       >VIEW ALL PLEDGES</StrokeBlue>

      {pledges && pledges.length > 0 &&
        <div className={css["pay-pledges-outer-container"]}>
          <div className={css["pay-pledges-inner-container"]}>

           {width > 1024 &&
              <table className={css["pay-pledges-desktop-table"]}>
                <thead>
                  <tr>
                    <th></th>
                    <th>{labels[0]}</th>
                    <th>{labels[1]}</th>
                    <th>{labels[2]}</th>
                    <th>{labels[3]}</th>
                    <th>{labels[4]}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>

                  {pledges.map((pledge, index) => (
                    <PayPledgeDetail key={index}
                      isSingle={pledges.length === 1}
                      pledge={pledge}
                      labels={labels}
                      amountToPayHandler={amountToPayHandler}
                      isDesktop={true}
                    />
                  ))}

                </tbody>
              </table>}

            {width <= 1024 &&
              <div className={css["pay-pledges-mobile-and-tablet-container"]}>
                {pledges.map((pledge, index) => (
                  <PayPledgeDetail key={index}
                    pledge={pledge}
                    labels={labels}
                    amountToPayHandler={amountToPayHandler}
                    isDesktop={false}
                  />
                ))}
              </div>}

            <div className={css["pay-pledges-payment-total"]}>
              <h3>PAYMENT TOTAL</h3>
              <h2>{paymentTotal}</h2>
              <FilledBlue
                className={css["pay-now-button"]}
                onClick={goToGivingSite}
                >PAY NOW</FilledBlue>
            </div>

          </div>
        </div>}

    </div>
  )
}

export default withRouter(PayPledges);
