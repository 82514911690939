import { useState } from 'react'

import css from './sass/liveauction.module.scss'

export default function LiveUserBids( {auctionDetail, user} ) {
  const [showInfo, setShowInfo] = useState(false)

  const bids = auctionDetail.Bids
  //individual item bids sorted with most recent on top
  const userBids = bids?.filter(bid=> bid.UserId === user)
  .sort((a,b) => a.Timestamp < b.Timestamp ? 1 : -1)

  const itemMap = {}
  auctionDetail.Items.forEach((item) => {
    itemMap[item.ItemId] = item.Title
  })

  const click = () => {
    let current = showInfo
    setShowInfo(!current)
  }

  return (
    <div>
      <button onClick={()=>click()} >{showInfo ? 'Hide User Bids' : 'Show User Bids'}</button>
      
      {showInfo && (
          <table className={css['admin-infobids-table']}>
            <thead>
              <tr>
                <th>Item Title</th>
                <th>Bid Amount</th>
                <th>Bid Time</th>
              </tr>
            </thead>
            <tbody>
            {userBids && userBids.map((bid) => {
                return (
                  <tr key={bid.Timestamp}>
                    <td>{itemMap[bid.ItemId]}</td>
                    <td>{bid.BidAmount}</td>
                    <td>{new Date(bid.Timestamp).toLocaleString()}</td>
                    
                  </tr>)
              })}
            </tbody>
          </table>
        )
      }
    </div>    
  )
}