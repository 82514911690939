
const SetUpApp = () => (
  <span>
	  You can update your existing credit card information by clicking Payment Methods under your initials in the upper right.
    To set up new automatic pledge payments, please call (310) 794-2447.
  </span>
)

export default SetUpApp

