import { showLoading } from 'services/util'

import css from './sass/confirmmodal.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

import NumberRow from './NumberRow'

export default function ConfirmMarkAsPaid({setShowConfirmModal, updateItem, data}) {

  const toggleModal = () => {
    setShowConfirmModal(false)
  }

  const performConfirm = (test) => {

    const input = {
      ItemId: data.itemsMarkedPaid[0],
    }

    if (test) {
      input['Paid'] = null
      input['PaymentDetails'] = null
    } else {
      input['Paid'] = true
    }

    showLoading(true)
    updateItem(input)
  }
  

  return (
    <div className={css['modal-container']}>
      <div className={themeCss['modal-background']} onClick={()=>toggleModal()}> </div>
      <div className={`${css['modal-content']} }`}>

        <div className={css['withdata-body']}>
          <div className={css['withdata-text']}>
            <h3>CONFIRM MARKING ITEMS AS PAID</h3>

            {data.itemsMarkedPaid && <>
              <NumberRow number={data.itemsMarkedPaid.length} dollar={false} text={'ITEM(S) BEING MARKED AS PAID'} />
              <NumberRow number={data.totalMarkedPaid} dollar={true} text={'TOTAL BEING MARKED AS PAID'} />
            </>}
            
          </div> 
        </div>

        {/* {data.errorMessage && <p>Error</p>} */}

        <div className={css['button-options']}>
          <button className={`${themeCss['button']} ${themeCss['FilledWhite']} ${themeCss['centered-button']}`}
            onClick={()=>toggleModal()}>Cancel</button>          
          <button className={`${themeCss['FilledBlue']} ${themeCss['button']} ${themeCss['centered-button']} ${css['modal-buttontext-fixing']}`} 
            onClick={()=> performConfirm()}>Confirm Mark as Paid</button>
          
          <button 
            className={`${themeCss['FilledYellow']} ${themeCss['button']} ${themeCss['centered-button']} ${css['modal-buttontext-fixing']}`} 
            onClick={()=> performConfirm(true)}>Test-Unpaid</button>

        </div>
      </div>    
    </div>
  )
}