import { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import store from 'redux/store';

import { showLoading } from 'services/util'
import { device } from 'common/Device'
import Alert from 'common/notifications/Alert'
import { phoneMedia} from 'common/DeviceDetect'
import { FilledYellow, /* StrokeBlue */ } from 'css/Buttons'
import iconInfo from 'images/icon-info-red.svg'

import PromoBanner from '../components/PromoBanner'

import PromoNavigation from './PromoNavigation'
import promoBannerImg from '../images/royce-wide.jpeg'

const PromoWrapper = styled.div`
  background: #FFFFFF;
  .alert-wrapper{
    max-width:100%;
  }
  .has-dots:after{
    margin:calc(12rem/16) 0;
  }
  @media ${ device.tablet } {
    .alert-wrapper {
      padding:0;
    }
  }
  @media ${device.mobile}{
    .alert-wrapper{
      padding:0;
    }
    .has-dots{
      text-align:left;
    }
  }
`

class PromoPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failMsg: props.location.state ? props.location.state.failMsg : null,
    };
    this.promoProjects = createRef();
  }

  closeBtn = (e) => {
    this.setState({ failMsg: null })
  }

  componentDidMount = async () => {
    showLoading(true)

    this.scrollToElement = this.scrollToElement.bind(this);
    this.setState({ isAuthenticated: true });
    showLoading(false)
  }

  async componentDidMount() {
    this.handleResize();
    phoneMedia.addListener(this.handleResize)
  }

  componentWillUnmount() {
    phoneMedia.removeListener(this.handleResize)
  }

  handleResize = () => {
    const isPhone = phoneMedia.matches

    this.setState({ showSocialButtons: isPhone ? false : true })

    if (!isPhone) {
      this.setState({ showForm: true, showSocialButtons: true });
    }
  }

  scrollToElement = (content) => {
    if (this.promoProjects.current) {
      this.promoProjects.current.scrollIntoView({ behavior: "smooth" });
    }

  }

  render() {
    const theme = store.getState().theme

    return (
      <PromoWrapper>
        <div>
          <h1 id="landingPageTitle" className="span-12 center">Welcome to My Giving Gateway</h1>
        </div>

        {this.state.failMsg &&
        <div className="wrapper alert-wrapper">
          <Alert
            onClick={this.closeBtn}
            icon={iconInfo}
          >
            {this.state.failMsg === 'MULTIPLE_CRM_USERS_FOUND' &&
            <p ><b>Please call Donor Relations at (310) 794-2447 to verify your email or phone number, and we will activate your My Giving Gateway account.</b></p>}
            {this.state.failMsg && this.state.failMsg !== 'MULTIPLE_CRM_USERS_FOUND' &&
            <div><p ><b>We were unable to locate your information in our system.</b></p>
              <p>Please call (310) 794-2447 for assistance.</p>
              <p>The UCLA Office of Donor Relations is open Monday through Friday from 10:00 a.m. to 3:00 p.m. PT.</p>
            </div>}
          </Alert>
          {theme.nonCrmUserExtra}
        </div>}


        <PromoBanner referringPage="Promo" icon={promoBannerImg}>
          <h3 className="banner-header has-dots has-no-margins">Bruin Strong. Now and Always.</h3>
          <p className="para-1"><b>Join a community of optimists</b></p>
          <FilledYellow
            width="10.0625rem"
            bgColor={"#FFD100"}
            fontColor={"#005587"}
            borderColor={"#FFD100"}
            order={"after"}
            as="a"
            href="https://giveto.ucla.edu/"
            target="_blank"
            rel="noreferrer noopener"
            >
            <span >Start Giving</span>
          </FilledYellow>
        </PromoBanner>

        <PromoNavigation />

      </PromoWrapper>
    )
  }
}

export default withRouter(PromoPageComponent);
