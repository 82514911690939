import React from 'react'
import styled from 'styled-components'

import Zindex from 'css/z-indexLayers.js';
import { primaryBlue } from 'css/Colors'
import { device } from 'common/Device'
import WhiteHexagon from 'images/hexagon-white.svg'

import DonationTotals from '../history/DonationTotals'
import { formatAmount, formatDate, formatFund } from '../mygivingutil'

import Profile from './Profile'
import ProfileDetails from './ProfileDetails'
import RecentGifts from './RecentGifts'

// preparing for eventual swap with a global (imported) constant
const defaultFontSize = 16

const DashboardSummary = styled.div`
  padding:${ 27/defaultFontSize }rem 0 ${ 30/defaultFontSize }rem;

  @media ${ device.tablet } {
    padding:${ 18/defaultFontSize }rem ${ 18/defaultFontSize }rem ${ 26/defaultFontSize }rem 0;
  }

  @media ${device.mobile}{
    padding-left:${ 15 / 320 * 100 }%;
    padding-right:${ 15 / 320 * 100 }%;

    .contribution-totals{
      flex-direction:column;
      align-items:flex-start !important;
    }
  }
`

export const UserInitials = styled.div`
  background:url('${ WhiteHexagon}') no-repeat;
  background-size:cover;
  height:${ 163 / defaultFontSize}rem;
  width:${ 159 / defaultFontSize}rem;

  color:${ primaryBlue};
  font-size:${ 60 / defaultFontSize}rem;
  line-height:${ 72 / 60};
  font-weight:400;

  display:flex;
  justify-content:center;
  align-items:center;

  @media ${ device.tablet} {
    height:${ 50 / defaultFontSize}rem;
    width:${ 50 / defaultFontSize}rem;
    font-weight:600;
    font-size:${ 18 / defaultFontSize}rem;
    line-height:${ 18 / 22};
  }

  @media ${ device.mobile} {
    height:${ 50 / defaultFontSize}rem;
    width:${ 50 / defaultFontSize}rem;
    font-weight:600;
    font-size:${ 18 / defaultFontSize}rem;
    line-height:${ 18 / 22};
  }
`

const BoxShadow = styled.div`
  position:relative;
  z-index:${Zindex.zindexOverlaystackOrder1};
  overflow:hidden;
  border-radius:${ 5 / defaultFontSize}rem;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.12);
  margin-bottom:${ 30 / defaultFontSize}rem;

  /* white background */
  &:before {
    content:'';
    display:block;
    position:absolute;
    z-index:${Zindex.zindexInlineLayer};
    height:100%;
    width:90%;
    top:0;
    right:0;
    background:#FFFFFF;
  }

  @media ${ device.tablet} {
    &:before {
      width:100%;
    }
  }

  @media ${ device.mobile} {
    border-radius:0;
    /* nullify the parent padding */
    /* margin-left:-${16/320 * 100 }% ;
    margin-right:-${16/320 * 100 }% ; */
    .grid .span-4:nth-child(1) {
      padding:0;
    }
  }
`

const Summary = props => {
  let profile = null

  if ( props.profile && props.profile.primaryName ) {

    profile = {
      firstName: props.profile.primaryName.firstName,
      lastName: props.profile.primaryName.keyName,
      Initials: props.profile.primaryName.firstName.charAt(0) + props.profile.primaryName.keyName.charAt(0)
    }
  }

  const gutterRef = React.createRef()

  return (
    <BoxShadow>
      <div className="grid">
        { profile &&
          <div className="span-4 rowspan-2 t-rowspan-1 m-span-2">
            <Profile>
              <UserInitials>{ profile.Initials }</UserInitials>
              <h2 className="h4 bold">Welcome, { profile.firstName }!</h2>
              <ProfileDetails
                donorsince={ props.summary.donorSinceYear }
              />
            </Profile>
          </div>
        }

        <div className="span-4 t-span-8 m-span-2">
          <DashboardSummary ref={ gutterRef }>
            <DonationTotals
              header={ <h3>Summary</h3> }
              lifetimeRecognition={ formatAmount(props.summary.lifetimeRecognition) }
              currentYearTaxDeductible={ formatAmount(props.summary.currentYearTaxDeductible) }
              previousYearTaxDeductible={ formatAmount(props.summary.previousYearTaxDeductible) }
              gutterRef={ gutterRef }
            />
          </DashboardSummary>
        </div>

        <div className="span-8 t-span-12 m-span-2 -ms-force-row-2 -ms-force-column-5">
          <RecentGifts>
            <h3 className="h6">Recent Activity</h3>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Unit</th>
                  <th>Fund Name</th>
                  <th>Gift Type</th>
                  <th>Gift Amount</th>
                </tr>
              </thead>
              <tbody>
                { props.history.map( (item,idx) => (
                  <tr key={ `row-${idx}` }>
                    <td className="nowrap">{ formatDate(item.date) }</td>
                    <td>{ item.department }</td>
                    <td>{ formatFund(item.designation, item.designationLookupId.split('-')[0]) }</td>
                    <td className="nowrap">{ item.revenueTypeFull }</td>
                    <td className="nowrap">{ formatAmount(item.displayAmount) }</td>
                  </tr>
                )) }
              </tbody>
            </table>
          </RecentGifts>
        </div>
      </div>
    </BoxShadow>
  )
}

export default Summary
