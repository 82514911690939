const initialState = JSON.parse(localStorage.getItem('auctionUser'))

const auctionUser = (state = initialState, action) => {
  // console.log({action, state})
  if (action.type === "SET_AUCTION_USER") {
    const { auctionUser } = action.payload

    localStorage.setItem('auctionUser', JSON.stringify(auctionUser))
    return auctionUser
  }
  else {
    return state
  }
}

export default auctionUser;
