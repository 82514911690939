import styled from 'styled-components'

import { device } from 'common/Device'
import Zindex from 'css/z-indexLayers.js'
import * as colors from 'css/Colors'
import { FilledBlue, StrokeBlue } from 'css/Buttons'
import cancelIcon from 'images/x.svg'

const defaultFontSize=16

const ModalNavigation = styled.div`
  display:flex;
  justify-content:flex-end;
  background-color: ${colors.lightGray};
  padding:${ 15/defaultFontSize }rem ${ 20/defaultFontSize }rem;

  button {
    margin:0;
    &:last-child {
      margin-left:${ 15/defaultFontSize }rem;
    }
  }

  @media screen and ${ device.mobile } {
    flex-direction:column;
    justify-content:center;
    padding:${ 15/defaultFontSize }rem;

    button {
      margin:0;
      order:2;

      &:last-child {
        margin:0 0 ${ 10/defaultFontSize }rem;
        order:1;
      }
    }
  }
`

const LoadingScreen = styled.section`
  position:fixed;
  top:0;
  left:0;
  z-index:${Zindex.zindexAllTheTop};
  height:100vh;
  width:100vw;
  background-color: rgba(0,0,0,0.4);

  display:flex;
  align-items:center;
  justify-content:center;

  .modal {
    position:relative;
    overflow:hidden;
    background-color: #fff;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.3);
    border-radius: 3px;
    min-width:${ 500/defaultFontSize }rem;

   &:before{
    content:'';
    display:block;
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:calc(10rem/${defaultFontSize});
    background-color: ${colors.primaryYellow};
   }

   .cancel-icon {
     display:block;
     position:absolute;
     top:${ 21/defaultFontSize }rem;
     right:${ 21/defaultFontSize }rem;
     height:${ 14/defaultFontSize }rem;
     width:${ 14/defaultFontSize }rem;
     cursor:pointer;
   }
  }

.alert-icon {
  margin-right:${24/defaultFontSize}rem;
}

.actual-content{
 display:flex;
 justify-content: flex-start;
 padding: ${ 50/defaultFontSize }rem ${ 40/defaultFontSize }rem ${ 40/defaultFontSize }rem;

 p {
   margin:0;
 }

}

.active .modal {
    opacity: 0.5;
  }

@media screen and ${ device.tablet } {
  .modal {
    min-width:${ 475/defaultFontSize }rem;
  }
  .actual-content {
    padding: ${ 48/defaultFontSize }rem ${ 28/defaultFontSize }rem ${ 40/defaultFontSize }rem;
  }
}

@media screen and ${ device.mobile } {
  .modal {
    width:100%;
    min-width:auto;
    margin:0 ${ 15/defaultFontSize }rem;
    padding:0;
  }

  .alert-icon {
    margin:0 0 ${ 14/defaultFontSize }rem;
  }

  .actual-content {
    flex-direction:column;
    align-items:center;
    padding: ${ 51/defaultFontSize }rem ${ 15/defaultFontSize }rem ${31/defaultFontSize}rem;

    p {
      text-align:center;
    }
  }
}

`
const ConfirmModal = (props) => (
  <LoadingScreen id="overlay" className="active">
    <div className="modal">
      <div className="actual-content">
        {props.children}
      </div>

      <ModalNavigation>
        <StrokeBlue className="cancel" onClick={props.onCancel}>
            {props.cancelText}
        </StrokeBlue>

        <FilledBlue className="confirm" onClick={props.onConfirm}>
          {props.confirmText}
        </FilledBlue>
      </ModalNavigation>

      <img className="cancel-icon" src={ cancelIcon } alt="Close" onClick={ props.onCancel }/>
    </div>
  </LoadingScreen>
)

export default ConfirmModal;
