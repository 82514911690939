import { Component } from 'react';

import * as logon from 'authentication/css/AuthenticateStyles'
import DropDownMenu from 'common/forms/DropDown'
import { editIcon } from './MyInformationPersonal'
import {USER_TITLE,USER_SUFFIX} from 'common/forms/data/BioData'

import * as cs from 'css/CommonStyle'
import * as forms from 'css/forms'
import * as colors from 'css/Colors'

import styled from 'styled-components'
import { device } from 'common/Device'

export const ProfileDataContainer = styled.div`
background:#F5F5F5;
border-radius:5px;
border: 1px solid #DDDDDD;
margin-bottom:30px;

& > div:nth-child(1),& >fieldset{
  padding:20px;
  border:none;
  & >h5{
    margin:5px;
  }
}
.error{
  border: 1px solid #D0021B;
}
`

export const ProfileDataContentCopy = styled.div`
width:${props => props.width || "31%"};

& >span{
  color:${colors.messagingRed};
}
@media ${device.mobile}{
  width:100%;
}

`

export const ProfileDataContent = styled.div`
position:relative;
display:flex;
flex-wrap:wrap;


 & > div{
  flex:0 31%;
}
& >div:nth-child(3n+2){
  margin:0 30px;
}
.primary-contact{
  flex:none;
  padding-top:40px;
  cursor: pointer;
}

${cs.TextInputdiv}{
  ${logon.ImgLink}
  {
    display:none;
  }
  &:hover{
    ${logon.ImgLink}{
     display:block;
     right: 0;
     top: 0;
     width:30px;
     height:100%;
   & >img{
        padding:5px;
        margin: 10px auto;
       }
    }
  }
  & >input:focus{
    border: 1px solid ${colors.primaryBlue};
  }
}

.required{
  color:${colors.messagingRed};
}


@media ${device.laptop}{
  & >div:nth-child(3n+2){
    margin:0 20px;
  }

}

@media ${device.tablet}{
  .primary-contact{
    padding:0;
    margin:0 !important
   }
}

@media ${device.mobile}{
  & > div{
    flex:0 100%;
    margin:0 !important;
  }
}
`

class MyNames extends Component {
 constructor(props) {
  super(props);
  this.state = {
   selectedTitle: (this.props.nameData) && this.props.nameData.UserTitle,
   isEmptyFName: false,
   isEmptyLName: false,
   isValidate:false,
   titleListTypes:USER_TITLE,
   suffixListTypes:USER_SUFFIX
  }

 }

 componentDidMount(){
   if(this.props.titleSuffixTypes){
     this.setState({
     titleListTypes:[...this.state.titleListTypes,...this.props.titleSuffixTypes.types.title],
     suffixListTypes:[...this.state.suffixListTypes,...this.props.titleSuffixTypes.types.suffix]
     })
   }

 }

 handleValidation = (e) => {
 switch (e.target.name) {
   case "primaryName.firstName":
    this.setState({ isEmptyFName: e.target.value ? false : true }, this.updateValidation); break;
   case "primaryName.keyName":
    this.setState({ isEmptyLName: e.target.value ? false : true }, this.updateValidation); break;
   default: break;
  }

 }

 updateValidation=(e)=>{
  this.props.updateValidation(this.state.isEmptyFName||this.state.isEmptyLName)
 }

 render() {
  return (
   <ProfileDataContainer>
    <div>
     <ProfileDataContentCopy>
      <label> Title</label>
      <cs.TextInputdiv>
       <DropDownMenu name="primaryName.title"
        title={(this.props.nameData && this.props.nameData.title) ? this.props.nameData.title : "Select a Title"}
        //list={this.props.titleSuffixTypes && this.props.titleSuffixTypes.types.title}
        list={this.state.titleListTypes}
        changeHandler={this.props.changeHandler}
        specialtype
       />

      </cs.TextInputdiv>
     </ProfileDataContentCopy>

     <ProfileDataContent>
      <div>
       <label> First Name <span className="required">*</span></label>
       <cs.TextInputdiv>
        <forms.Input  name="primaryName.firstName"
         className={this.state.isEmptyFName ? "error" : ""}
         ref={(inputFocus) => this.editFirstName = inputFocus}
         value={this.props.nameData && this.props.nameData.firstName}
         onChange={(e)=>{this.props.changeHandler(e);this.handleValidation(e);}}
        onFocus={this.handleValidation}
         onBlur={this.handleValidation}
        />
        <logon.ImgLink onClick={() => this.editFirstName.focus()} >{editIcon}</logon.ImgLink>
        {(this.state.isEmptyFName) &&
        <cs.ErrorSpan textAlign="right">First Name cannot be left empty</cs.ErrorSpan>
       }
       </cs.TextInputdiv>

      </div>


      <div>
       <label> Middle Name </label>
       <cs.TextInputdiv>
        <forms.Input  name="primaryName.middleName"
         ref={(inputFocus) => this.editMiddleName = inputFocus}
         value={this.props.nameData && this.props.nameData.middleName}
         onChange={this.props.changeHandler}
        />
        <logon.ImgLink onClick={() => this.editMiddleName.focus()} >{editIcon}</logon.ImgLink>
       </cs.TextInputdiv>
      </div>

      <div>
       <label> Last Name <span className="required">*</span></label>
       <cs.TextInputdiv>
        <forms.Input  name="primaryName.keyName"
         className={this.state.isEmptyLName && "error"}
         ref={(inputFocus) => this.editLastName = inputFocus}
         value={this.props.nameData && this.props.nameData.keyName}
         onChange={this.props.changeHandler}
         onFocus={this.handleValidation}
         onBlur={this.handleValidation}
        />
        <logon.ImgLink onClick={() => this.editLastName.focus()} >{editIcon}</logon.ImgLink>
        {this.state.isEmptyLName &&
        <cs.ErrorSpan textAlign="right">{"Last Name  cannot be left empty"}</cs.ErrorSpan>
       }
       </cs.TextInputdiv>

      </div>

      <div>
       <label> Suffix 1</label>
       <DropDownMenu name="primaryName.suffix1"
        title={(this.props.nameData && this.props.nameData.suffix1) ? this.props.nameData.suffix1 : 'None'}
        list={this.state.suffixListTypes}
        //list={this.props.titleSuffixTypes && this.props.titleSuffixTypes.types.suffix}
        changeHandler={this.props.changeHandler}
        specialtype
       />
      </div>

      <div>
       <label> Suffix 2</label>
       <DropDownMenu name="primaryName.suffix2"
        title={(this.props.nameData && this.props.nameData.suffix2) ? this.props.nameData.suffix2 : "None"}
        list={this.state.suffixListTypes}
        //list={this.props.titleSuffixTypes && this.props.titleSuffixTypes.types.suffix}
        changeHandler={this.props.changeHandler}
        specialtype
       />
      </div>

     </ProfileDataContent>

    </div>
   </ProfileDataContainer>
  )
 }
}

export default MyNames;
