// react-table 7 implementation that matches react-table 6 structure
import { Fragment, useEffect } from 'react';
import { useTable, usePagination, useExpanded, useSortBy } from 'react-table'
import classnames from 'classnames'
import HistoryPagination from 'sites/mygiving/history/HistoryPagination'

let currentSortId = null

function IdmReactTable (props) {
  const {
    className = "",
    data,
    columns,
    initialState,
    sorted,
    loading = false,
    getTdProps = () => {},
    trClassName = "",
    SubComponent,
    addExpanderColumn,
  } = props;

  // hack to get row to open initially because row.getToggleRowExpandedProps().onClick() stopped working with some react upgrade
  useEffect(() => {
    if (props.expandRowAfterDataLoad) {
      doExpansion(props.expandRowAfterDataLoad)
    }
  }, [props.expandRowAfterDataLoad])

  /* Defining default column width for every column */
  const defaultColumn = {
    minWidth: 20,
    width: 20,
    maxWidth: 400
  };

 /* If the table is expandable then add the expander column */
  if (SubComponent && addExpanderColumn && columns[0].id !== 'expander') {
    columns.unshift({
      Header: "",
      id: "expander",
      className: "text-center",
      Cell: ({ row }) => {
        return (
          //Applying the toggle expander props i.e onClick, style and title
          <div
            {...row.getToggleRowExpandedProps()}
            title="Click here to see more information"
            className="rt-td rt-expandable"
          >
            <div
              className={classnames(
                "rt-expander",
                row.isExpanded ? "-open" : ""
              )}
            >
              {row.isExpanded  ? "▼" : "▶"}
            </div>
          </div>
        );
      }
    });
  }

  const doExpansion = row => {
    row.toggleRowExpanded(!row.isExpanded)
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,

    // pagination
    state: { pageIndex, pageSize },
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    // pageOptions,
    // nextPage,
    // previousPage,

    // new react table doesn't update stuff like canNextPage when you dynamically set the page size
    // so just removing all the handleResize stuff for changing page size
    // setPageSize,
  } = useTable (
    { columns, data, defaultColumn, SubComponent, initialState, disableSortRemove:true, autoResetPage:false },
    useSortBy,
    useExpanded,
    usePagination,
  );

  if (props.sorted && currentSortId !== props.sorted[0].id) {
    setSortBy(props.sorted)
    currentSortId = props.sorted[0].id
  }

  // use pagination only if pageSize is supplied
  const itemToMap = initialState.pageSize ? page : rows

  return (
    //Applying the table props
    <div className={classnames(className, "ReactTable")}>
      <div {...getTableProps} className="rt-table" role="table">
        <div className="rt-thead -header">
          { // Looping over the header rows
            headerGroups.map((headerGroup) => {
            const {key:headerGroupKey, ...headerGroupRest} = headerGroup.getHeaderGroupProps()
            return (
              //Applying the header row props
            <div key={headerGroupKey} {...headerGroupRest} className="rt-tr" role="headerrow">
              {//Looping over the headers in each row
                headerGroup.headers.map((column) => {
                  const sortedClass = column.isSorted ?
                        column.isSortedDesc
                          ? '-sort-desc'
                          : '-sort-asc'
                        : ''

                  const {key:headerKey, ...headerRest} = column.getHeaderProps()
                  return (
                    <div
                      key={headerKey}
                      {...headerRest}
                      className={"rt-th " + sortedClass}
                    >
                      <div {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                      </div>
                    </div>
                  )
                })}
            </div>
          )})}
        </div>
        <div className="rt-tbody">
         {/* Applying the table body props*/}
            { //Loopting over the table rows
              itemToMap.map((row, ind) => {
                //Preparing row for rendering
              prepareRow(row);
              const {key:rowKey, ...rowRest} = row.getRowProps()

              return (
                <Fragment key={row.id}>
                  <div {...getTableBodyProps()} className="rt-tr-group">

                  {/*Applying the row props and adding classname for alternate rows*/}
                  <div
                    key={rowKey}
                    {...rowRest}
                    className={classnames(
                      "rt-tr",
                      row.isExpanded ? "-expanded" : "",
                      ind % 2 === 0 ? "-odd" : "-even",
                      trClassName
                    )}
                  >
                    { //Looping over the row cells
                      row.cells.map((cell) => {
                      const {key:cellKey, ...cellRest} = cell.getCellProps()
                      return (
                        //Applying the cell props and rendering the cell content
                        <div
                          {...getTdProps(cell)}
                          key={cellKey}
                          {...cellRest}
                          className={classnames("rt-td", cell.column.className)}
                          onClick={() => { console.log(row); doExpansion(row); } }
                        >
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                  {/* Checking row expansion */}
                  {row.isExpanded && SubComponent ? SubComponent({ row }) : null}
          </div>
                </Fragment>
              );
            })}
        </div>
      </div>

      {initialState.pageSize && <HistoryPagination
        totalRows={data.length}
        pageSize={initialState.pageSize}
        pageCount={pageCount}
        canPrevious={canPreviousPage}
        canNext={canNextPage}
        gotoPage={gotoPage}
        setHistoryNestedGotoPage={props.setHistoryNestedGotoPage}
      />}

      {/*Loading overlay*/}
      <div className={classnames("-loading", loading ? "-active" : "")}>
        <div className="-loading-inner">Loading...</div>
      </div>
    </div>
  );
};

export default IdmReactTable;