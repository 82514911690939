import styled from 'styled-components'
import { device } from 'common/Device'

const StyledBanner = styled.div`
  padding:1rem;
  font-size:${ 14/16 }rem;
  font-weight: bold;
  background-color: #FFD103;
  color: #000;
  text-align:center;

  a {
    display:inline-block;
    margin:0 0 0 0.2rem;
  }

  @media ${device.mobile} {
    font-size:0.8rem;
  }
`

const BannerWarning = props => (
  <StyledBanner>
    For important updates about COVID-19 and its impact at UCLA, please visit
    <a href="https://newsroom.ucla.edu/stories/coronavirus-information-for-the-ucla-campus-community" target="_blank" rel="noopener noreferrer">ucla.in/coronavirus</a>.
    To support UCLA COVID-19-related efforts, visit
    <a href="https://giveto.ucla.edu" target="_blank" rel="noopener noreferrer">giveto.ucla.edu</a>.
  </StyledBanner>
)

export default BannerWarning
