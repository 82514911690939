import Zindex from 'css/z-indexLayers.js';
import * as colors from 'css/Colors'
import styled from 'styled-components'
import AlertIcon from 'images/Alerticonmodal.svg'

const defaultFontSize = 16

const LoadingScreen = styled.section`
  position:fixed;
  top:0;
  left:0;
  z-index:${Zindex.zindexAllTheTop};
  height:100vh;
  width:100vw;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color: rgba(0,0,0,0.4);
  .modal {
    position:relative;
    overflow:hidden;
    background-color: #fff;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.3);
    border-radius: 3px;
    &:before {
      content:'';
      display:block;
      position:absolute;
      left:0;
      top:0;
      width:100%;
      height:${ 10/defaultFontSize}rem;
      background-color: ${colors.primaryYellow};
    }
  }
  .actual-content{
    display:flex;
    flex-direction:row;
    justify-content: flex-start;
    align-items:flex-start;
    padding: ${ 48/defaultFontSize }rem ${ 40/defaultFontSize }rem;
    img {
      margin-right:${ 23/defaultFontSize }rem;
    }
    p {
      margin:0;
    }
  }
  .navigate-options{
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    background-color: ${colors.lightGray};
    padding:${ 15/defaultFontSize }rem ${ 5/defaultFontSize }rem;

    button {
      margin:0 ${ 15/defaultFontSize }rem;
    }
  }
  .active .modal {
    opacity: 0.5;
  }
`

// Alert takes an array of Component options (usually)
// this really needs to be merged with `common/notifications/Alert`
const Alert = (props) => {
  return (
    <LoadingScreen id="overlay" className="active">
      <div className="modal">
        <div className="actual-content">
          <img src={AlertIcon} alt='' />
          <div>
            { props.children }
          </div>
        </div>

        <div className="navigate-options">
          { props.options }
        </div>
      </div>
    </LoadingScreen>
  )
}

export default Alert
