import { forwardRef } from 'react'
import styled from 'styled-components'
import Zindex from 'css/z-indexLayers.js';
import { device } from 'common/Device'

import promoContent1 from '../images/promoContent1.png'
import promoContent2 from '../images/promoContent2.png'
import promoContent3 from '../images/promoContent3.png'

export const PromoProjectsContainer = styled.div`
  a {
    text-decoration:none;
    color:#000000;
  }

  @media ${ device.mobile } {
    padding-top:2.5rem;
  }
`

export const SparkHeader = styled.h2`
  &&&{
    color:#000 ;
    margin-bottom:10px;
  }

  @media ${ device.mobile } {
    margin-top:0;
  }
`

export const SparkSubheader = styled.h3`
  &&&{
    color:#000;
  }
  -ms-grid-row:2
`

export const PromoProjectDetails = styled.div`
  position:relative;
  margin-top:calc(40rem/16);
  border-radius: 5px;
  max-width: 500px;
  -ms-grid-row:3;

  &:nth-child(3) {
    -ms-grid-column:1 !important;
  }
  &:nth-child(4) {
    -ms-grid-column:5 !important;
  }
  &:nth-child(5) {
    -ms-grid-column:9 !important;
  }
  .project-details{
    display: flex;
    flex-direction: column;
    background-color: #F5F5F5;
    height:100%;
    border-radius: 5px;
    overflow: hidden;

    @media ${device.tablet}{
      flex-direction:row;
      align-items:stretch;
       & >div,a{
        flex:1 0 50%;
      }
    }

    @media ${device.mobile}{
      display:block;
    }
  }

  @media ${device.tablet}{
    max-width: 100%;
  }
`

export const PromoImage = styled.div`
  position:relative;
  display:flex;
  flex-direction:column;
  flex-wrap:wrap;

  &:before{
    content: '';
    display:block;
    background:${ props => props.icon ? `url("${props.icon}")` : ""};
    background-size:cover;
    background-position:center;
    background-repeat: no-repeat;
    height:calc(300rem/16);
    min-height: 100%;

    z-index:${Zindex.zindexOverlaystackOrder1};
   }
`

export const Promocontent = styled.div`
  position:relative;
  z-index:${Zindex.zindexOverlaystackOrder1};
  height:100%;
  h4{
    margin-bottom:0
  }
  @media ${device.tablet}{
      background-color: #F5F5F5;
  }
`

const PromoProjects = forwardRef((props, ref) => (

  <div className="wrapper">
    <PromoProjectsContainer className="grid" ref={ref}>
      <SparkHeader className="span-12 t-span-12 h3 center">Personal Projects. Immediate Impact.</SparkHeader>
      <SparkSubheader className="span-12 t-span-12 h6 normal has-no-margins center">Directly support UCLA students, faculty and staff with the resources they need to bring their passion projects to life.</SparkSubheader>
      <PromoProjectDetails className="span-4 t-span-12">
        <div className="project-details">
          <PromoImage icon={promoContent1} />
          <Promocontent
          as={"a"}
          href="https://campkesem.org/ucla/"
          target="_blank">
            <div>
              <h4 className="bold">Camp Kesam At UCLA</h4>
              <p>Kesem is a community, driven by passionate college student leaders, that supports children through and beyond their parent’s cancer.</p>
            </div>
          </Promocontent>
        </div>
      </PromoProjectDetails>
      <PromoProjectDetails className="span-4 t-span-12" >
        <div className="project-details">
          <PromoImage icon={promoContent2} />
          <Promocontent
           as={"a"}
           href="https://www.uclamensrowing.com/"
           target="_blank">
            <div>
              <h4 className="bold">UCLA Men’s Rowing</h4>
              <p>The UCLA Men’s Rowing team is a club varsity program fully recognized by both the Pac-12 Conference and ACRA conference.
                The club is a part of the Competitive Sports Program within the UCLA Recreation Department.</p>

            </div>
          </Promocontent>
        </div>
      </PromoProjectDetails>
      <PromoProjectDetails className="span-4 t-span-12">
        <div className="project-details">
          <PromoImage icon={promoContent3} />
          <Promocontent
           as={"a"}
           href="https://grandchallenges.ucla.edu/sustainable-la/"
           target="_blank">
            <div>
              <h4 className="bold">UCLA Sustainable L.A. Grand Challenge</h4>
              <p>From understanding future climate patterns and maximizing the region’s solar potential,
                to understanding how gender plays a role in reducing our daily water use and revolutionizing plant and animal conservation management,
                we are spearheading the research necessary to define the region’s pathway to sustainability.</p>
            </div>
          </Promocontent>
        </div>
      </PromoProjectDetails>
    </PromoProjectsContainer>
  </div>
));

export default PromoProjects;
