import React, { Component } from 'react';
import DropDownMenu from 'common/forms/DropDown'
import * as cs from 'css/CommonStyle'
import * as forms from 'css/forms'
import { DATE_MONTH } from 'common/forms/data/BioData'
import { MIN_YEAR_VALUE } from 'profile/constants'
import { ProfileDataContent, ProfileDataContainer, ProfileDataContentCopy } from './MyNames'

class BirthDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearList: [],
      isEmptyMonth:false,
    }
    this.monthRef = React.createRef();
    this.dayRef = React.createRef();
  }


  generateDateYears(minimum_value, maximum_value) {
    var list = []
    for (var i = minimum_value; i <= maximum_value; ++i) {
      list = [...list, i]
    }
    return list
  }

  generateDaysInMonth = (numericMonth, year) => {
    if (!numericMonth || !year) return this.generateDateYears(1, 31);

    const month = DATE_MONTH.month[numericMonth - 1];

    if (month === "February" && year % 4 === 0) return this.generateDateYears(1, 29);
    else if (month === "February") return this.generateDateYears(1, 28);
    else if (["April", "June", "September", "November"].indexOf(month) > -1) return this.generateDateYears(1, 30);
    else return this.generateDateYears(1, 31);
  }

  async componentDidMount() {
    let cur = new Date()
    this.setState({
      yearList: this.generateDateYears(MIN_YEAR_VALUE, cur.getFullYear()),
    })
  }

  handleChange = (value, index,name) => {
    switch(name) {
      case "month":
        this.monthRef.current.value = value;
        break;
      case "day":
        this.dayRef.current.value = value;
        break;
      default:
        break;
    }

    if (this.dayRef.current.value !== 'Select A Day' && this.monthRef.current.value === 'Select A Month') {
      this.setState({ isEmptyMonth: true }, this.updateValidation)
    }
    else {
      this.setState({ isEmptyMonth: false }, this.updateValidation)
    }
  }
  updateValidation = (e) => {
    this.props.updateValidation(this.state.isEmptyMonth)
  }

  render() {
    return (
      <div>
        <h4 className="bold"> Birth Date</h4>
        <ProfileDataContainer>
          <ProfileDataContent>
            <ProfileDataContentCopy>
              <label> Month</label>
              <cs.TextInputdiv >
                <DropDownMenu name="month"
                 title={this.props.userBirthdateInfo.month ? DATE_MONTH.month[this.props.userBirthdateInfo.month - 1] : "Select a Month"}
                  list={DATE_MONTH.month}
                  changeHandler={this.props.changeHandler}
                  onClick={this.handleChange}
                  resetThenSet={this.handleChange}
                  className={this.state.isEmptyMonth}
                />
              </cs.TextInputdiv>
              {this.state.isEmptyMonth && <cs.ErrorSpan textAlign="right"> {"Month cannot be empty"}</cs.ErrorSpan>}
              <forms.Input name="isMonth" type="hidden" ref={this.monthRef} value={this.props.userBirthdateInfo.month ? DATE_MONTH.month[this.props.userBirthdateInfo.month - 1] : "Select a Month"} />
            </ProfileDataContentCopy>

            <ProfileDataContentCopy>
              <label> Day</label>
              <cs.TextInputdiv>
                {JSON.stringify(this.state.monthDefaultTitle)}
                <DropDownMenu
                  name="day"
                  title={this.props.userBirthdateInfo.day || "Select a Day"}
                  list={this.generateDaysInMonth(this.props.userBirthdateInfo.month, this.props.userBirthdateInfo.year)}
                  changeHandler={this.props.changeHandler}
                  resetThenSet={this.handleChange}
                />
              </cs.TextInputdiv>
              <forms.Input name="isDay" type="hidden" ref={this.dayRef} value={this.props.userBirthdateInfo.day || "Select a Day"} />
            </ProfileDataContentCopy>

            <ProfileDataContentCopy>
              <label> Year</label>

              <cs.TextInputdiv>
                <DropDownMenu name="year"
                  title={this.props.userBirthdateInfo.year || "Select A Year"}
                  list={this.state.yearList}
                  changeHandler={this.props.changeHandler}
                />
              </cs.TextInputdiv>
            </ProfileDataContentCopy>
          </ProfileDataContent>

        </ProfileDataContainer>
      </div>
    )
  }
}

export default BirthDate;
