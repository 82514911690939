import { Component } from 'react';

import * as cs from 'css/CommonStyle';
import * as forms from 'css/forms';
import { FilledYellow } from 'css/Buttons'
import DropDownMenu from 'common/forms/DropDown'

import { InputWrap, ButtonWrap, OperatorWrapper } from 'admin/QueryStyles'

const metaSearchTypes = [
  {displayValue: "All Documents", key: "AllDocuments"},
  {displayValue: "Endowment Reports", key: "EIAR"},
  {displayValue: "Fund Narratives", key: "CEIR"},
  {displayValue: "Additional Campus Reports", key: "ACR"},
]

const searchTypes = {
  AllDocuments: [
    {displayValue: "Report Year", key: "ReportYear", isDropdown: true},
    {displayValue: "Show All", key: "ShowAll"},
    {displayValue: "File Name", key: "FileName"},
  ],
  EIAR: [
    {displayValue: "Report Year", key: "ReportYear", isDropdown: true},
    {displayValue: "Show All", key: "ShowAll"},
    {displayValue: "File Name", key: "FileName"},
    {displayValue: "CRM ID", key: "CrmId"},
    {displayValue: "First Name", key: "FirstName"},
    {displayValue: "Last Name", key: "LastName"},
  ],
  CEIR: [
    {displayValue: "Report Year", key: "ReportYear", isDropdown: true},
    {displayValue: "Show All", key: "ShowAll"},
    {displayValue: "File Name", key: "FileName"},
    {displayValue: "Fund Number", key: "ReportNumber"},
  ],
  ACR: [
    {displayValue: "Report Year", key: "ReportYear", isDropdown: true},
    {displayValue: "Show All", key: "ShowAll"},
    {displayValue: "File Name", key: "FileName"},
    {displayValue: "Report ID", key: "ReportId"},
    {displayValue: "Report Type", key: "ReportSubType"},
  ],
}

const reportTypeValues = [
  {displayValue: "User", key: "User"},
  {displayValue: "Fund", key: "Fund"},
  {displayValue: "Department", key: "Dept"},
]


let startYear = (new Date()).getFullYear()
const reportYearValues = [];
while (startYear >= 2020) {
  reportYearValues.push(startYear);
  startYear--;
}

class SharedQueryForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      metaSearchDisplay: 'All Documents',
      metaSearchType: 'AllDocuments',
      searchType: "ReportYear",
      searchDisplay: "Report Year",
      reportYearValue: reportYearValues[1], // start with previous year
      searchValue: reportYearValues[1],
      searchTypeList: searchTypes['AllDocuments'],
      reportTypeValue: reportTypeValues[0].displayValue,
    }
  }

  componentDidMount() {
  }

  handleMetaDropdownChange = async e => {
    await this.setState({
      metaSearchType: e.target.value.key,
      metaSearchDisplay: e.target.value.displayValue,
      searchTypeList: searchTypes[e.target.value.key],
    });
  }

  handleSearchTypeChange = e => {
    const searchType = e.target.value.key;

    this.setState({
      searchType,
      searchDisplay: e.target.value.displayValue,
      searchFieldName: searchType + 'InputField', // give fields different names so browsers will remember the right things
      searchValue: searchType === 'ReportYear' ? reportYearValues[1] : (searchType === 'ReportSubType' ? reportTypeValues[0].key : ''),
      searchDateTo: '',
      searchDateFrom: '',
    });
  }

  handleReportYearChange = e => {
    this.setState({
      reportYearValue: e.target.value,
      searchValue: e.target.value,
    });
  }

  handleReportTypeChange = e => {
    this.setState({
      reportTypeValue: e.target.value.displayValue,
      searchValue: e.target.value.key,
    });
  }

  handleSearchFieldChange = e => {
    this.setState({
      searchValue: '' + e.target.value,
    });
  }

  handleActionTypeChange = e => {
    this.setState({
      actionType: e.target.value,
      searchValue: e.target.value,
    });
  }

  handleToDateFieldChange = e => {
    this.setState({
      searchDateTo: '' + e.target.value,
    });
  }

  handleFromDateFieldChange = e => {
    this.setState({
      searchDateFrom: '' + e.target.value,
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const scrubbedValue = this.state.searchValue && this.state.searchType === 'CognitoUsername' && this.state.searchValue.charAt(0) === '+'
        ? '+' + this.state.searchValue.replace(/\D/g,'')
        : this.state.searchValue;

    await this.setState({searchValue:scrubbedValue})

    this.props.onSubmit(this.state);
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <cs.TextInputdiv className="user-search-form" >
            <InputWrap>
              <label className="link-donor-info-block-title">{"Search..."}</label>
              <DropDownMenu
                className="input-wrap-dropdown"
                name="metaSelect"
                title={this.state.metaSearchDisplay}
                list={metaSearchTypes}
                changeHandler={this.handleMetaDropdownChange}
              />
            </InputWrap>
            <InputWrap>
              <label className="link-donor-info-block-title">{"By..."}</label>
              <DropDownMenu
                className="input-wrap-dropdown"
                name="auditTrailSearch"
                title={this.state.searchDisplay}
                list={this.state.searchTypeList}
                changeHandler={this.handleSearchTypeChange}
              />
            </InputWrap>

            {['ShowAll'].indexOf(this.state.searchType) === -1 &&
              <OperatorWrapper>=</OperatorWrapper>}

            {['ShowAll', 'ReportYear', 'ReportSubType'].indexOf(this.state.searchType) === -1 &&
              <InputWrap key={this.state.searchType}>
                <label className="link-donor-info-block-title">&nbsp;</label>
                  <forms.ClearableInput
                  width="100%"
                  type="text"
                  tryOutTwoWaySetValue={this.state.searchValue}
                  name={this.state.searchFieldName}
                  onChange={this.handleSearchFieldChange} />
              </InputWrap>}

            {this.state.searchType === 'ReportYear' &&
              <InputWrap key={this.state.searchType}>
                <label className="link-donor-info-block-title">&nbsp;</label>
                <DropDownMenu
                  className="input-wrap-dropdown"
                  name="ReportYearList"
                  title={this.state.reportYearValue}
                  list={reportYearValues}
                  changeHandler={this.handleReportYearChange}
                />
              </InputWrap>}

            {this.state.searchType === 'ReportSubType' &&
              <InputWrap key={this.state.searchType}>
                <label className="link-donor-info-block-title">&nbsp;</label>
                <DropDownMenu
                  className="input-wrap-dropdown"
                  name="ReportTypeList"
                  title={this.state.reportTypeValue}
                  list={reportTypeValues}
                  changeHandler={this.handleReportTypeChange}
                />
              </InputWrap>}

            <ButtonWrap>
              <br/>
              <FilledYellow
                width="7.0625rem"
                bgColor={"#FFD100"}
                fontColor={"#005587"}
                borderColor={"#FFD100"}
                name={"filterDocumentSearchResults"}
              >
                <span> Search</span>
              </FilledYellow>
            </ButtonWrap>
          </cs.TextInputdiv>
        </form>
      </div>
    )
  }
}

export default SharedQueryForm;