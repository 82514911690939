import { Component } from 'react'

import { StyledMenu, StyledNavLink } from 'common/header/MenuStyles'

class Menu extends Component {

  render() {
    return (
      <StyledMenu id="headerLinks">
      </StyledMenu>
    )
  }
}

export default Menu;

