// this is the 'promo' that goes under the summary on the dashboard
// didn't want to call it 'promo' because of potential confusion with the promo page
import { withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'

import Zindex from 'css/z-indexLayers.js';
import { device } from 'common/Device'
import { FilledYellow as SeeMyGiftsButton } from 'css/Buttons'
import { borderGray } from 'css/Colors'

import BannerBackground from '../images/royce-wide.jpeg'

const defaultFontSize = 16

/* adjust this height to match background image */
const BannerImage = styled.div`
  position:relative;
  height:${ 433/defaultFontSize }rem;
  background:url('${BannerBackground}') no-repeat;
  background-size:cover;

  @media ${ device.tablet } {
    height:${ 300/defaultFontSize }rem;
  }

  @media ${ device.mobile } {
    height:${ 145/defaultFontSize }rem;
  }
`

const Content = styled.div`
  /* grid don't work too well on IE */
  -ms-grid-column:9 !important;
  background:#FFFFFF;
  h3 {
    font-size: calc(24rem/16);
    line-height: 1.375;
    font-weight: 600;

    .nomargin: {
      margin-bottom: 0;
    }
  }
`

const ContentWrapper = styled.div`
  display:flex;
  text-align:center;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  position:relative;
  z-index:${Zindex.zindexOverlaystackOrder1};
  padding:${ 30/defaultFontSize }rem ${ 30/defaultFontSize }rem ${ 30/defaultFontSize }rem 0;
  height:100%;
  box-sizing:border-box;

  .h4 {
    margin:0;
  }

  p, .h4 {
    width:100%;
  }

  @media ${ device.tablet } {
    padding-left:${ 15/defaultFontSize }rem;
    padding-right:${ 15/defaultFontSize }rem;
  }
`

const Grid = styled.div`
  position:relative;
  overflow:hidden;
  z-index:${Zindex.zindexOverlaystackOrder1};
  border-radius:${ 5/defaultFontSize }rem;
  border:1px solid ${ borderGray};
  background:#FFFFFF;
  margin-bottom:${ 30/defaultFontSize }rem;
`

const Highlight = props => {

  const goToGiveTo = (e) => {
    console.log(e)
    e.preventDefault();
    window.location.href='https://giveto.ucla.edu/';
  }

  return (
    <Grid>
      <div className="grid">
        <div className="span-8 m-span-2">
          <BannerImage  />
        </div>
        <Content className="span-4 m-span-2">
          {props.isDonor && <ContentWrapper>
            <p>View your lifetime giving history, print receipts or make payments.</p>
            <SeeMyGiftsButton as={ Link } to='/history' id="dashboardGivingHistoryLink">
              Giving History
            </SeeMyGiftsButton>
          </ContentWrapper>}
          {!props.isDonor && <ContentWrapper>
            <h3>Join a community of optimists</h3>
            <SeeMyGiftsButton onClick={goToGiveTo} id="dashboardGiveNowLink">
              Give Now
            </SeeMyGiftsButton>
          </ContentWrapper>}
        </Content>
      </div>
    </Grid>
  )
}

export default withRouter(Highlight)
