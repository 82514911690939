import Zindex from 'css/z-indexLayers.js';
import styled from 'styled-components'

const defaultFontSize = 16

const WithBackground = styled.div`
  position:relative;
  z-index:${Zindex.zindexInlineLayer};
  padding:${ 40/defaultFontSize }rem 0;

  /* placeholder for image */
  &:after {
    content:'';
    position:absolute;
    z-index:${Zindex.zindexHeader};
    width:100%;
    height:${ 484/defaultFontSize }rem;
    background:rgba(0,59,92,0.8);
    left:0;
    top:0;
    @media print {
      display:none;
    }
  }
`

export default WithBackground
