import * as colors from 'css/Colors'

import styled from 'styled-components'
import { device } from 'common/Device'

const defaultFontSize = 16

export const UserName = styled.li`
`

export const MenuPopUp = styled.ul`
  position:relative;
  overflow:visible;
  cursor:pointer;
  &:hover {
    .popup {
      display:block;
    }
  }
  .initials:hover {
    background:${colors.secondaryMediumBlue };
  }
  /* this the buffer to keep mouse hover working */
  &:after {
    display:block;
    position:absolute;
    top:100%;
    right:0;
    content:'';
    width:${ 45/defaultFontSize }rem;
    height:${ 5/defaultFontSize }rem;
  }
  &, ul, li {
    display:block;
    list-style-type:none;
    margin:0;
    padding:0;
  }
  .initials {
   display:block;
   color: #fff;
   background:${ colors.primaryBlue };
   border-radius: 50%;
   width: ${ 45/defaultFontSize }rem;
   height: ${ 45/defaultFontSize }rem;
   line-height: ${ 45/defaultFontSize }rem;
  }
  .popup {
    display: none;
    position:absolute;
    right:0;
    top:${ 48/defaultFontSize }rem;
    z-index:1000;
    background:#fff;
    border:1px solid #ddd;
    box-shadow:0 0 20px 0 rgba(0,0,0,0.1);
    .fullname {
      padding-bottom:2px;
      color: #fff;
      background:${ colors.secondaryMediumBlue};
      height: 15px;
      font-size: 15px;
      line-height: 15px;
      text-align: left;
      font-weight: bold;
    }
    .username {
      padding-top:2px;
      padding-bottom:15px;
      color: #fff;
      background:${ colors.secondaryMediumBlue};
      height: 12px;
      line-height: 12px;
      font-size: 12px;
      text-align: left;
    }
    .username.alone {
      padding-top:15px;
      font-size: 13px;
    }
    li {
      white-space:nowrap;
      margin:0;
      padding:0.6rem 1.5rem;
      &.popup-link {
        border-top:1px solid #ccc;
      }
      &.signout {
        border-top:1px solid #ccc;
        padding:0;
        button {
          font-weight:bold;
          border:none;
          background:transparent;
          cursor:pointer;
          color:${colors.secondaryDarkBlue};
          display:block;
          width:100%;
          box-sizing:border-box;
          text-align:left;
          padding:0.6rem 1.5rem;
        }
      }
    }
    a {
      color: ${colors.darkGray};
      display:block;
      text-decoration:none;
    }
  }
  @media ${device.tablet}{
    flex-direction: column;
    width: 100%;
    & ${ UserName } {
      padding:${8/defaultFontSize}rem ${ 15/320 * 100}%;
    }
    .initials{
      border: 1px solid #FFFFFF;
      display:inline-block;
      vertical-align:middle;
      &:hover {
        background:${colors.secondaryMediumBlue };
       }
    }
    .user-info-mobile{
      display:inline-block;
      vertical-align:middle;
      color:#fff;
      margin:0 0 0 ${20/320 * 100}%;
    }
    .fullname, .username {
      display:block;
    }
    .popup
    {
      display:block;
      position:static;
      box-shadow:none;
      border:none;
      background:${colors.lightGray};
      /* .fullname,.username{
        position: absolute;
        top: 0;
        left: 10%;
        background:none;
        margin:${10/defaultFontSize}rem 0;
      } */
      li{
        padding:0;
      }
      & li.signout{
        padding:0;
        button{
          color:${colors.primaryBlue};
          padding:${15/defaultFontSize}rem ${ 15/320 * 100}%;
        }
      }
    }
  }
`

export const MenuPopUpOverlay=styled.div`
  @media ${device.tablet}{
    background:${colors.secondaryMediumBlue};
  }
`
