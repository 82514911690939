import React from 'react'
import { LegalMainHeader, LegalSectionHeader, LegalCopy } from './PrintStyles'

const PrintLegalCopy = props => (
  <React.Fragment>
    <LegalMainHeader>
      Important Tax and Other Information
    </LegalMainHeader>

    <LegalSectionHeader>
      Tax-deductibility
    </LegalSectionHeader>
    <LegalCopy>
      Your gift is tax-deductible to the extent permitted by law, which excludes the fair market value of any benefits received. If no benefits were provided in connection with this gift the full value of the gift is tax-deductible.
    </LegalCopy>

    <LegalSectionHeader>
      Recovery of Operating Costs from Private Gifts
    </LegalSectionHeader>
    <LegalCopy>
      The UCLA Foundation and the University of California, Los Angeles apply a one-time administrative fee to all gifts and pledge payments to provide essential support necessary to UCLA's overall operation. The fee is currently 6.5%.
    </LegalCopy>

    <LegalSectionHeader>
      Valuation of Gifts of Stock
    </LegalSectionHeader>
    <LegalCopy>
      Your gift value is based on the mean selling price (average of the daily high and the daily low sales price per share) on the date your stock is received in the account of The UCLA Foundation and/or Regents. Please consult your financial advisor/broker if you require additional information or documentation regarding the valuation of your gift for tax purposes.
    </LegalCopy>

    <LegalSectionHeader>
      Valuation of Gifts in Kind
    </LegalSectionHeader>
    <LegalCopy>
      This document serves to acknowledge the receipt of your in-kind donation and does not include a gift value. Please consult your financial advisor for additional information or documentation regarding the valuation of your gift. It is the responsibility of the donor to provide the gift value when reporting such gifts for tax purposes.
    </LegalCopy>

    <LegalSectionHeader>
      Estate Planning
    </LegalSectionHeader>
    <LegalCopy>
      The Office of Gift Planning can provide information about and assist with arrangements for bequests, charitable remainder trusts, gift annuities, and a variety of assets, such as real estate and closely held stock. These types of gift plans often allow donors to optimize their philanthropy while meeting their financial, tax and estate planning goals. For information on how to include UCLA in your estate plans, visit www.legacy.ucla.edu or contact the Office of Gift Planning at (800) 737-8252.
    </LegalCopy>

    <LegalSectionHeader>
      Donor's Consent for Public Acknowledgment
    </LegalSectionHeader>
    <LegalCopy>
      The university is grateful for your support. One of the ways we express our thanks is by listing the names of donors in webbased and/or print honor rolls. Should you wish that your name not appear as a donor, please notify Donor Relations at (310) 794-2447 if you have not already done so.
    </LegalCopy>

    <LegalSectionHeader>
      Update Your Information and See Your Giving History Online
    </LegalSectionHeader>
    <LegalCopy>
      Visit the My Giving Gateway website: <a href="https://mygiving.ucla.edu">https://mygiving.ucla.edu</a>
    </LegalCopy>

  </React.Fragment>
)

export default PrintLegalCopy
