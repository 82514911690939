import { useState } from 'react';

import store from 'redux/store';
import { getCrmUser } from 'redux/selectors';

import DropDownMenu from 'common/forms/DropDown'
import { FilledYellow, StrokeBlue } from 'css/Buttons'

import { ReportsContainer, ReportsBlock, ReportsListing, RowWrapper, YearDropdown, ReportLink, EndowmentSurvey, YearHeader} from '../ReportStyles'

import { getStaticDocument, getDocumentList, downloadDocument } from '../documentsapi'

const prettyReportNames = {
  "EIAR" : "Endowment Fund Reports",
  "CEIR" : "Giving in Action Reports"
}

function EiarReports(props) {
  const [eiarYear, setEiarYear] = useState(null)

  const handleEndowmentReportYearChange = e => {
    setEiarYear(e.target.value)
  }

  const crmUser = getCrmUser(store.getState())
  const crmId = crmUser.profile.constituentLookupId
  const documentList = getDocumentList()

  const eiarYears = [];
  if (documentList) {
    documentList.forEach(doc => {
    if (eiarYears.indexOf(doc.ReportYear) === -1)
      eiarYears.push(doc.ReportYear)
    })
    eiarYears.sort().reverse()
  }

  return (
    <>
      {documentList &&
        <ReportsContainer>
          <ReportsBlock>
            <h3 className="h3">Endowment in Action Reports</h3>
            <p>You can view your Endowment in Action reports for each year, as well as our brochure,
               by clicking on the "Download" buttons in the section below.</p>
            <p>In addition to information about the performance of the university’s endowment, these materials include details about what you have made possible through your giving to individual endowed funds.</p>
            <p>Thank you for your support of UCLA!</p>
            <ReportsListing>

              {eiarYears.length === 1 && <YearHeader>Fiscal Year {eiarYears[0]}</YearHeader>}

              {eiarYears.length > 1 && <>
                <YearHeader>View endowment reports by year:</YearHeader>

                <YearDropdown>
                  <DropDownMenu
                    className="report-year-dropdown"
                    name="EndowmentReportYearList"
                    title={eiarYear || eiarYears[0]}
                    list={eiarYears}
                    changeHandler={handleEndowmentReportYearChange}
                  />
                </YearDropdown>
              </>}

              <RowWrapper className="reports-row-wrapper">
                <ReportLink key="-1">
                  Endowment In Action Booklet - {eiarYear || eiarYears[0]}
                  <FilledYellow onClick={e => getStaticDocument( {reportType: "EIAR", reportYear: eiarYear || eiarYears[0],
                                                                  url:`/mygiving/documents/${eiarYear || eiarYears[0]}/${eiarYear || eiarYears[0]} UCLA Endowment in Action Brochure.pdf`} )}>Download</FilledYellow>
                </ReportLink>
              </RowWrapper>

                {documentList && documentList.filter(doc => 1*doc.ReportYear === 1*(eiarYear  || eiarYears[0])).map((doc,idx) => (
                  <RowWrapper className="reports-row-wrapper"  key={idx}>
                    <ReportLink>
                      {prettyReportNames[doc.ReportType] || doc.ReportType} - {doc.ReportYear}
                      <FilledYellow id={"downloadEndowmentLink"+(1*idx+1)} onClick={e => downloadDocument(doc)}>Download</FilledYellow>
                    </ReportLink>
                  </RowWrapper>
                ))}
            </ReportsListing>

            <EndowmentSurvey>
              <p>How are we doing? Please take our survey and let us know what you think!</p>
              <StrokeBlue onClick={e => window.open('https://survey.sjc1.qualtrics.com/jfe/form/SV_cZ3tgdJNtnji99z?id_number=[[' + crmId + ']]')}>ENDOWMENT IN ACTION FEEDBACK SURVEY</StrokeBlue>
            </EndowmentSurvey>
          </ReportsBlock>
        </ReportsContainer>}
    </>
  )
}

export default EiarReports