import adminCss from '../sass/adminpage.module.scss'

export default function PreAttendees(props) {
  
  return (
    <div>
      <h1 className={adminCss['admin-auction-page-tab-header']}>
        Attendee View
      </h1>
      <p className={adminCss['admin-auction-page-tab-bolded']}>
        Once the auction is public, a list of attendees who have registered to participate in the auction will appear here.
      </p>
    </div>    
  )
}