import { useState, useEffect } from 'react'

import { anyPaymentMethodsExpiring } from '../payment/paymentUtilities'
import { getLoggedInUser } from 'redux/selectors'
import store from 'redux/store'

import PaymentAlertIcon from './PaymentAlertIcon'
import PaymentMethodsNotification from './PaymentMethodsNotification'

import css from './sass/alert.module.scss'

function PaymentMethodsAlert(props) {
  const [showWarning, setShowWarning] = useState(false)
  const loggedInUser = getLoggedInUser(store.getState())
  const cardsOnFile = loggedInUser.crmUser.creditCards;
  
  useEffect(() => {
    setShowWarning(anyPaymentMethodsExpiring(cardsOnFile));
  }, [cardsOnFile])
  
  return (
    <>
    {showWarning && 
      <PaymentMethodsNotification>
        <div className={css.alertBannerIconContentContainer}>
          <PaymentAlertIcon />
          <div className={css.alertBannerMessage}>
            <p><b>One or more credit cards are expired or are expiring soon.</b></p>
            <p>Please visit the <a href="/paymentmethods/">Payment Methods</a> page to update your credit card information.</p>
          </div>
        </div>
      </PaymentMethodsNotification>
    }
    </>
  )
}

export default PaymentMethodsAlert