import { useState, useEffect } from 'react';

import store from 'redux/store';
import { getProfile } from 'redux/selectors'

import { showLoading } from 'services/util'
import { history } from 'services/history.js'
import { analyticsYearReceipt } from '../analytics'
import Alert from 'common/Alert'
import Overlay from '../components/Overlay'

import { getRecognitionSummary, getReceipts, getReceiptBio, getSummary  } from '../history/historyapi'
import { getLimitsForYear } from '../mygivingutil'
import PrintReceipts from './PrintReceipts'
import PrintRecognitionSummary from './PrintRecognitionSummary'
import PrintReceiptPreview from '../history/PrintReceiptPreview'

import { StrokeBlue, FilledBlue } from 'css/Buttons'
import css from './sass/printsummaries.module.scss'

function PrintSummaries(props) {
  const [gifts, setGifts] = useState([])
  const [displayReceipts, setDisplayReceipts] = useState(false)
  const [receiptYear, setReceiptYear] = useState()
  const [displayRecognitionSummary, setDisplayRecognitionSummary] = useState(false)
  const [recognitionYear, setRecognitionYear] = useState()
  const [errorMsg, setErrorMsg] = useState(null)

  const profile = getProfile(store.getState())
  const receiptBio = getReceiptBio()
  const summary = getSummary()

  useEffect(() => {
    setErrorMsg()
  }, [])

  const printRecognitionSummary = async (year) => {
    showLoading(true)

    const gifts = await getRecognitionSummary(year)

    showLoading(false)

    await setRecognitionYear(year)
    await setGifts(gifts)
    await setDisplayRecognitionSummary(true)
  }

  const printReceiptsForYear = async (year) => {
    const date = getLimitsForYear(year)
    showLoading(true)

    analyticsYearReceipt(year)

    const gifts = await getReceipts(year)

    showLoading(false)

    await setReceiptYear(date.from.getFullYear())
    await setGifts(gifts)
    await setDisplayReceipts(true)
  }

  return (
    <>
      <div className={`${css["print-summaries-page"]} hide-on-print`}>

        <h1 id="landingPageTitle">Print Summaries and Receipts</h1>
        <p>The ability to view, download, or print receipts is limited to the last two calendar years for gifts personally made by you.
        If you do not see a receipt for a gift, please contact Donor Relations at 310-794-2447.</p>

        <StrokeBlue
          className={css["header-button"]}
          height={"2.6"}
          borderWidth={2}
          onClick={() => history.push('/history')}
          >BACK TO ALL GIVING HISTORY</StrokeBlue>

        <PrintReceipts
          printReceiptsForYear={ printReceiptsForYear }
          printRecognitionSummary={ printRecognitionSummary }
          donorSinceYear={summary && summary.donorSinceYear}
        />
      </div>

      {displayRecognitionSummary && gifts.length > 0 &&
        <Overlay useFullModal={true} >
          <PrintRecognitionSummary
            year={recognitionYear}
            gifts={gifts}
            onHideOverlay={ e => setDisplayRecognitionSummary(false) }
          />
        </Overlay>
      }
      {displayRecognitionSummary && gifts.length === 0 &&
        <Alert options={<FilledBlue isSmall={ true } onClick={ e => setDisplayRecognitionSummary(false) }>
            Return to History
          </FilledBlue>}
        >
          There are no gifts for {recognitionYear}.
        </Alert>
      }

      {displayReceipts && gifts.length > 0 &&
        <Overlay onHideOverlay={ e => setDisplayReceipts(false) }>
          <PrintReceiptPreview
            gifts={gifts}
            bio={receiptBio}
            crmId={profile.constituentLookupId}
          />
        </Overlay>
      }
      {displayReceipts && gifts.length === 0 &&
        <Alert options={<FilledBlue isSmall={ true } onClick={ e => setDisplayReceipts(false) }>Return to History</FilledBlue>}>
          There are no printable receipts for {receiptYear}.
        </Alert>}
    </>
  )
}

export default PrintSummaries
