// Calendar months names and shortnames

export const DATE_MONTH={
  month:[ "January","February","March","April", "May","June","July","August","September","October","November","December"]
}

export const MARITAL_STATUS = [ "Select A Status", "Single","Married","Divorced","Separated", "Domestic Partner","Widowed"];

export const USER_TITLE=["Mr.","Ms.","Mrs.","Dr.","Miss","Professor"]


export const USER_SUFFIX=["Ph.D.","M.D.","D.D.S.","Esq.","Jr.","Sr."]
