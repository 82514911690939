import { history } from 'services/history.js'
import { formatMoneyCents } from 'services/util'

import greenCheck from '../components/images/check-circle.svg'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/auctions.module.scss'
import auctionCardCss from './sass/auctionscards.module.scss'

export default function AuctionEndedCardPaid({ auction, currentUserId }) {

  let amountDue = 0;

  
  
  return(
    
    <div className={themeCss['box-top-green-accent-wide']} >
            <p className={auctionCardCss['auction-box-info-heading']}>Thank you for participating!</p>
            <p className={auctionCardCss['auction-box-info-text']}>Below are the items you have won.</p>
            <div className={css['auction-ended-paiditems-container']}>
              <p className={css['auction-ended-paiditems-message']}>Payment received. <br /> Thank you!</p>
              <img className={css['auction-ended-paiditems-img']} src={greenCheck} />

            </div>
            
          </div>
  )
}