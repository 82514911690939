import { createStore, combineReducers } from "redux"
import { rootReducers } from "./reducers"

const store = createStore(combineReducers(rootReducers))

function registerReducers(newReducers) {
  store.replaceReducer(
    combineReducers({ ...rootReducers, ...newReducers })
  );
}

export default store
export { registerReducers as injectReducers };
