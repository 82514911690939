import { useState, useEffect } from 'react'

import * as cs from 'css/CommonStyle';
import DropDownMenu from 'common/forms/DropDown'

import { InputWrap } from 'admin/QueryStyles'
import css from '../sass/upload.documents.module.scss'

const docTypes = [
  {displayValue: "EIAR Donor Reports", key: "EIAR"},
  {displayValue: "CEIR Fund Reports", key: "CEIR"},
  {displayValue: "Additonal Campus Reports", key: "ACR"},
]

function SelectDocTypeForm(props) {
  const [selectedType, setSelectedType] = useState('EIAR Donor Reports')

  const handleDropdownChange = async e => {
    setSelectedType(e.target.value.displayValue)
    console.log(e.target.value)
    props.onChange(e.target.value.key);
  }

  return (
    <div>
      <form>
        <cs.TextInputdiv>
          <InputWrap>
            <label>{"Select report type..."}</label>
            <DropDownMenu
              className="input-wrap-dropdown"
              name="selectDocType"
              title={selectedType}
              list={docTypes}
              changeHandler={handleDropdownChange}
              maxHeight="300"
            />
          </InputWrap>
       </cs.TextInputdiv>
      </form>
    </div>
  )
}

export default SelectDocTypeForm;