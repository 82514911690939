export const SCREENS = {
  phone: 767,
  tablet: 1024
}

export const phoneMedia = window.matchMedia(
  `(max-width: ${SCREENS.phone}px)`
)
export const tabletDownMedia = window.matchMedia(
  `(max-width: ${SCREENS.tablet}px)`
)
