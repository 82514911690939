import styled from 'styled-components'

import iconNextMonth from 'images/arrow-right.svg'
import iconNextYear from 'images/arrow-right-double.svg'
import iconPrevMonth from 'images/arrow-left.svg'
import iconPrevYear from 'images/arrow-left-double.svg'
import { primaryBlue } from 'css/Colors'

const defaultFontSize = 16

const PickerNavStyles = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;

  img {
    display:block;
    margin:0 ${ 2/defaultFontSize }rem;
    padding:${ 3/defaultFontSize }rem;
    width:${ 1.69612 / 2.165 }rem;
    height:1rem;
    cursor:pointer;
    border:1px solid #FFFFFF;
    border-radius:3px;

    &.is-focused {
      border-color:${ primaryBlue };
    }

    &.is-double {
      width:${ 2361 / 2165 }rem;
    }
  }
`

const MonthTitle = styled.span`
  display:block;
  font-weight:600;
  margin:0 ${ 6/defaultFontSize }rem;
  width:${ 135/defaultFontSize }rem;
  text-align:center;
`

const DatePickerNav = props => {
  const options = { year: 'numeric', month: 'long' }
  return (
    <PickerNavStyles>
      <img src={ iconPrevYear } className={ props.tabIndex === 0? 'is-focused is-double' : 'is-double' } alt="Previous Year" onMouseDown={ e => { e.preventDefault(); props.changeYear( -1 ) }} />
      <img src={ iconPrevMonth } className={ props.tabIndex === 1? 'is-focused' : '' } alt="Previous Month" onMouseDown={ e=> { e.preventDefault(); props.changeMonth( -1 ) }} />
      <MonthTitle>{ new Intl.DateTimeFormat('en-US', options).format( props.date )  }</MonthTitle>
      <img src={ iconNextMonth } className={ props.tabIndex === 2? 'is-focused' : '' } alt="Next Month" onMouseDown={ e => { e.preventDefault(); props.changeMonth( 1 ) }} />
      <img src={ iconNextYear } className={ props.tabIndex === 3? 'is-focused is-double' : 'is-double' } alt="Next Year" onMouseDown={ e => { e.preventDefault(); props.changeYear( 1 ) }} />
    </PickerNavStyles>
  )
}

export default DatePickerNav
