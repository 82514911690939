import { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'

import IdmReactTable from "common/IdmReactTable"

import PendingListUserForm from './PendingListUserForm'
import { formatDate, formatPhone } from 'admin/adminutil'
import { QueryResultsStyles } from 'admin/QueryStyles'
import { FilledYellow } from 'css/Buttons'

const ButtonWrap = styled.div`
  display: inline-block;
  white-space: nowrap;
  float: right;
  padding: .2rem 1.625rem 1.2rem 0;
`;

function PendingListResults(props) {
  const tableData = props.data

  const tableProps = {
    addExpanderColumn: true,
    initialState: {
      sortBy: [
       {
        id: 'date',
        desc: true
       }
      ]
    },
  }

  const mainTableColumns = useMemo(() => [
      { Header: 'Name', accessor: 'FormData.firstName', Cell: cell => cell.value + " " + ( cell.row.original.FormData.middleName || "") + " " + cell.row.original.FormData.lastName},
      { Header: 'Date', id: 'date', accessor: 'DateCreated', Cell: cell => formatDate(cell.value)},
      { Header: 'Affiliation', accessor: 'FormData.affiliation' },
      { Header: 'Status', accessor: 'PendingStatus'},
      { Header: 'Username', accessor: 'CognitoLogin', Cell: cell => {
        if (cell.value.indexOf('@') > -1)
          return <a href={"mailto:" + cell.value + "?subject=Your UCLA One New User Request"}>{cell.value}</a>
        else
          return formatPhone(cell.value)
      }},
      { Header: 'Notes', accessor: 'Notes'},
  ], [])

  function copyTableToClipboard() {
    const columns = mainTableColumns.slice(1); // remove expander
    const textToCopy = [columns.map(c => c.Header).join('\t')];
    tableData.forEach(row => {
      textToCopy.push(columns.map(c => {
        if (c.accessor === "DateCreated")
          return formatDate(row[c.accessor]);
        else if (c.accessor === "FormData.affiliation")
          return row.FormData.affiliation;
        else if (c.accessor === "FormData.firstName")
          return (row.FormData.firstName) + " " + (row.FormData.lastName);
        else if (c.key === "username")
          return row.CognitoLogin;
        else
          return (row[c.accessor]);
      })
      .join('\t'))
    })
    navigator.clipboard.writeText(textToCopy.join('\n'))
  }

  return (
    <QueryResultsStyles searchType={'pendinglist'}>
      {tableData.length > 0 &&
        <IdmReactTable
          { ...tableProps }
          data={ tableData }
          columns={ mainTableColumns }
          SubComponent={({ row, index }) => {
             return (
              <PendingListUserForm
                row={row}
                handleFilter={props.handleFilter}
              />
            );
          }}
        />}

      <section>
        <div>
          <span>Total Results: </span>{tableData.length}
          {tableData.length > 0 &&
            <ButtonWrap>
              <FilledYellow
                width="7.0625rem"
                bgColor={"#FFD100"}
                fontColor={"#005587"}
                borderColor={"#FFD100"}
                name={props.btnName}
                onClick={copyTableToClipboard}
              >
                <span>Copy to Clipboard</span>
              </FilledYellow>
            </ButtonWrap>}

        </div>
      </section>
    </QueryResultsStyles>
  )
}



export default PendingListResults
