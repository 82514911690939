import { useState, useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { formatDate, formatAmount } from '../mygivingutil'

import DropDownMenu from 'common/forms/DropDown'
import css from './sass/paypledges.module.scss'

function PaymentInput(props) {
  const [inputDisabled, setInputDisabled] = useState(true)
  const [amountToPay, setAmountToPay] = useState(props.pastDueAmount || props.pastPlusUpcomingAmountDue)
  const [selectedPaymentType, setSelectedPaymentType] = useState(null)

  const pastDueLabel = `Due ${formatDate(props.pastDueDate)}: ${formatAmount(props.pastDueAmount)}`
  const upcomingDueLabel = `Due ${formatDate(props.upcomingDueDate)}: ${formatAmount(props.pastPlusUpcomingAmountDue)}` // uses today's date if none is supplied
  const totalBalanceLabel = `Total Balance: ${formatAmount(props.pledge.pledgeBalance)}`
  const customAmountLabel =' Custom Amount'
  const noPaymentLabel = 'No Payment'

  const dropDownItems = [
    upcomingDueLabel,
    totalBalanceLabel,
    customAmountLabel,
  ]

  // don't show past due date if all payments are past due (no upcoming payments due)
  if (props.pastDueAmount && props.upcomingDueDate) dropDownItems.unshift(pastDueLabel)

  if (!props.isSingle) dropDownItems.push(noPaymentLabel)

  const {amountToPayHandler, pledge} = props

  useEffect(() => {
    amountToPayHandler(pledge, amountToPay || 0)
  }, [amountToPay, amountToPayHandler, pledge])

  const handlePaymentTypeChange = (e => {
    if (e.target.value === pastDueLabel) {
      setAmountToPay(props.pastDueAmount)
      setInputDisabled(true)
    }
    else if (e.target.value === upcomingDueLabel) {
      setAmountToPay(props.pastPlusUpcomingAmountDue)
      setInputDisabled(true)
    }
    else if (e.target.value === totalBalanceLabel) {
      setAmountToPay(props.pledge.pledgeBalance)
      setInputDisabled(true)
    }
    else if (e.target.value === customAmountLabel) {
      setAmountToPay('')
      setInputDisabled(false)
    }
    else if (e.target.value === noPaymentLabel) {
      setAmountToPay(0)
      setInputDisabled(true)
    }
    setSelectedPaymentType(e.target.value)
  })

  const handleAmountChange = (amount => {
    setAmountToPay(amount)
  })

  return (
    <div className={css["payment-input"]}>
     <DropDownMenu
        name="paymentType"
        title={selectedPaymentType || dropDownItems[0]}
        list={dropDownItems}
        changeHandler={handlePaymentTypeChange}
        specialtype
       />
      <div className={css["pledge-pay-currency-input"]}>
        <CurrencyInput
          name="paymentAmount"
          disabled={inputDisabled}
          placeholder="Please enter an amount"
          value={amountToPay}
          decimalScale={2}
          decimalsLimit={2}
          prefix={'$'}
          onValueChange={handleAmountChange}
        />
      </div>
    </div>
  )
}

export default PaymentInput
