import { Component } from 'react';
import styled from 'styled-components'

import { showLoading } from 'services/util'
import LinkUserWrapper from 'admin/linkuser/LinkUserWrapper'
import Alert from 'common/notifications/Alert'
import iconInfo from 'images/iconColorinfo.svg'
import * as colors from 'css/Colors';

import DocumentSearchForm from './DocumentSearchForm'
import DocumentSearchResults from './DocumentSearchResults'

import { getDocuments } from "../mggadminutil"

const AuditWrapper = styled.div`
  color:#fff;
  padding:20px;
  border-radius:5px;
  background-color:${ props => props.crm ? `${colors.secondaryMediumBlue}` : `${colors.primaryBlue}`};

  .user-search-form {
    margin: 0;
  }

`
class DocumentSearch extends Component {

  state = {
    documentType: 'AllDocuments'
  };

  async componentDidMount() {
    showLoading(false)
  }

  handleSearch = async data => {
    if (["FileName","CrmId"].indexOf(data.searchType) > -1 && !data.searchValue){
      this.setState({errorMsg: 'Search field cannot be blank'})
      return;
    }

    await this.setState({documentType: data.metaSearchType});
    this.setState({errorMsg: ''})
    showLoading(true)

    const response = await getDocuments(data.metaSearchType, data.searchType, data);

    if (!response || !response.entries) {
      await this.setState({errorMsg: response && response.error ? response.error.msg || 'An Error Occurred' : 'An Error Occurred'})
      await this.setState({tableData: null});
      showLoading(false)
      return "error" // todo - handle error
    }

    const tableData = response.entries.map(entry => {
      const newEntry = {...entry};
      for (const prop in newEntry) {
        if (prop.indexOf("LastLogin") > 0) {
          if (!newEntry.AllApps) newEntry.AllApps = prop.replace('LastLogin', '')
          else newEntry.AllApps += ', ' + prop.replace('LastLogin', '')
        }
      }
      return newEntry;
    })

    await this.setState({tableData});

    showLoading(false)
  }

  render() {
    return (
      <>
        <LinkUserWrapper>
          <h2>Search MGG Documents</h2>

          {this.state.errorMsg &&
            <Alert icon={iconInfo} >
              <div>{this.state.errorMsg}</div>
            </Alert>
          }

          <div className=" grid">
            <div className="span-12">
              <AuditWrapper>
                <DocumentSearchForm
                  onSubmit={this.handleSearch}
                >
                </DocumentSearchForm>

              </AuditWrapper>
            </div>
          </div>

          <br/>

          {this.state.tableData &&
            <DocumentSearchResults
              data={this.state.tableData}
              documentType={this.state.documentType}
            />}

        </LinkUserWrapper>
      </>
    )
  }
}

export default DocumentSearch;
