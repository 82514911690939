import { useContext } from 'react'

import { ThemeContext } from 'ThemeContext'

function PaymentMethodsCopy(props) {

  const theme = useContext(ThemeContext)

  return (
    <>
      { (props.hasRecurringGift || props.hasPledge) && 
        <>
        <strong>PLEASE NOTE:</strong>
        <br />
        </>}
      { (props.hasPledge) && 
        <>
          If the card you update is linked to a pledge with payments that have been missed,
          the <b>total amount of all missed payments</b> will be charged on the nearest 15<sup>th</sup> of the month after the credit card information is updated.
          If you would like to set up an adjusted payment schedule, please call Donor Relations at {theme.supportPhone} to update your credit card information.
          Do not use this site if you want to create a new pledge payment schedule.
        </>}
      { (props.hasPledge && props.hasRecurringGift) && <> <br/>&nbsp;<br/></> }
      { (props.hasRecurringGift) && 
      <>
        If the card you update is linked to recurring gift(s) with payments that have been missed,
        the missed payments will not be charged to your card after the credit card information is updated.
        The recurring charges will restart on the nearest 15<sup>th</sup> of the month after the credit card information is updated.
      </>}
    </>
  )
}

export default PaymentMethodsCopy;