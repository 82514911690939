import Zindex from 'css/z-indexLayers.js';
import { NavLink } from 'react-router-dom'

import * as colors from 'css/Colors';
import styled from 'styled-components'
import { device } from 'common/Device'

import uclalogo from 'images/UCLA-logo.svg'

const defaultFontSize = 16

export const HeaderOverlay = styled.header`
  font-size: 1rem;
  color: #003b5c;
  background-color: #FFFFFF;
  position:relative;
  z-index:${Zindex.zindexPopUpStack};
  padding-top: ${props => props.proxyBarVisible ? '40px' : '0'};
  .logo,.logo a{
    display: flex;
    justify-content:center;
    align-items:center;
  }
  .logo img{
    width: ${174 / defaultFontSize}rem;
    height: 100%;
  }
  .shadow
  {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    position:relative;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    padding: 0 ${36 / defaultFontSize}rem;
    pointer-events: none;
  }
  @media ${device.tablet}{
    .shadow{
      flex-direction:column;
      align-items: normal;
      box-shadow:none;
      padding:0;
      // padding: 0 ${24 / defaultFontSize}rem;
    }
    padding-top: ${props => props.proxyBarVisible ? '60px' : '0'};
    .header-links {
     display:flex;
     justify-content:flex-start;
     box-sizing:border-box;
     width:100%;
     padding:${15/defaultFontSize}rem ${15/320 * 100}%;
    }
  }
`

export const NavigationLinkHeader = styled(NavLink)`
  color: ${colors.darkGray};
  text-decoration:none;
  font-size:${14/defaultFontSize}rem;

  &.active:after {
    display:block;
    position:absolute;
    top: ${ 38 / defaultFontSize}rem;
    content:'';
    width:100%;
    height:5px;
    background:${ colors.primaryYellow};
  }

  @media ${device.tablet}{
    margin:0;
    &.active:after {
     display:none;
    }
    &.active:before {
      content:'';
      display:block;
      position:absolute;
      top:0;
      left:0;
      height:100%;
      width:calc(5rem/16);
      background:${ colors.primaryYellow};
     }
  }
`;

export const HeaderGradient = styled.div`
  position:relative;
  background: linear-gradient(225.44deg, ${colors.primaryBlue} 0%, ${colors.secondaryDarkBlue} 100%);
  width:100%;
  height:${46 / defaultFontSize}rem;
  &:after{
    content:'';
    display:block;
    position:absolute;
    top:${10 / defaultFontSize}rem;
    left:50%;
    transform:translateX(-50%);
    background:url(${uclalogo}) no-repeat;
    width: ${79 / defaultFontSize}rem;
    height: ${26 / defaultFontSize}rem;
  }
`

export const ProfileContainer = styled.div`
  position:relative;
  margin-left:${ 20 / defaultFontSize}rem;
  @media ${device.tablet}{
    margin:0;
    width:100%;
    &:after {
      content:'';
      display:block;
      height:1px;
      background: #BFBFBF;
    }
  }
`
export const NavBar = styled.nav`
  display:flex;
  flex-direction:row;
  align-items:center;
  pointer-events: auto;
  @media${device.tablet}{
    flex-direction:column-reverse;
    align-items:flex-start;
  }
`

export const HeaderDiv = styled.div`
  pointer-events: auto;
  @media ${device.tablet} {
    display:flex;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    justify-content:space-between;
    padding: ${7 / defaultFontSize}rem ${15/320 * 100}%;
    i {
      color: #666666;
    }
    .menu-icon {
      margin: auto 0;
    }
  }
`

export const DashboardImage = styled.img`
  cursor:pointer;
`

export const ProxyWarning = styled.nav`
  display:flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 36px;
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  font-size:15px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1);
  z-index: ${Zindex.zindexAllTheTop};
  span {
    color:#ca001a;
    font-weight:bold;
    display:inline-block;
  }
  @media ${device.tablet} {
    padding: 0 4.6875%;
  }
  @media ${device.mobile}{
    height: 60px;
  }
`

export const ErrorIcon = styled.img`
  height:1.44rem;
  width:1.46rem;
  margin: 0 10px 0 0;
`
;
