import styled,{css} from 'styled-components'
import { device } from 'common/Device'
import Zindex from 'css/z-indexLayers.js';

const defaultFontSize = 16;

// for math
const desktopDividerWidth = 204
const desktopColumnWidth = 319
const desktopTotalWidth = 900

export const PasswordHintSpan = styled.div``;

export const StyledSignInRegisterInnerDiv = css`
  position:relative;
  margin-top:15px;
  font-size: 16px;
  & > p{
    font-family: Arial;
    line-height: 14px;
    text-align: center;
  }
  .error{
    border: 1px solid #D0021B;
  }
  ${PasswordHintSpan}{
    margin:10px 0;
    width: 100%;
    color: #D0021B;
    font-family: Arial;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
  ${PasswordHintSpan} label.green{
    color: #1b9c68;
  }
`

export const SignInRegisterImgDiv = css`
  position:absolute;
  background:${props => props.bgColor || "none"};
  & img{
    padding:20px 15px;
  }
`
export const ImgLink = styled.div`${SignInRegisterImgDiv}`

export const SignInRegisterInnerDiv=styled.div`${StyledSignInRegisterInnerDiv}`

export const Content = styled.div`
  & > input[type="radio"], label[id^="mobileTabs"]{
     display:none;
  }
  @media ${device.tablet}{
    padding:${20/defaultFontSize}rem ${24/defaultFontSize}rem;
  }
  @media ${device.mobile}{
    padding:0;
  }
`

export const HeaderForSignInRegisterForm = styled.div`
  display:none;

  @media ${device.mobile} {
    display: flex;
    justify-content: center;
    background-color:#FAFAFA;
    border-radius:3px;
    border: 1px solid #DDDDDD;
    width:100%;
    position:relative;
    box-sizing:border-box;

    & > p {
      width:50%;
      text-align:center;
      font-size:calc(16rem/16);
      color:#666666;
      text-decoration:none;
    }

    .show {
      color:#2774AE;
      font-weight:bold;
      &:after {
        content:"";
        display:block;
        position:absolute;
        background-color: #FFD100;
        width:50%;
        height:5px;
        bottom:0;
      }
    }
  }`

export const ContentInner = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom:${ 100/defaultFontSize }rem;
  width:${ desktopTotalWidth / 1440 * 100 }%;
  min-width:750px;
  margin:0 auto;
  box-sizing:border-box;
  .external-accounts {
    margin:auto 0;
  }
  @media ${device.tablet} {
    padding-bottom:${ 60/defaultFontSize }rem;
    width:100%;
    min-width:auto;
  }
  @media ${device.mobile}{
    display:block;
    margin:${30/defaultFontSize}rem auto;
    padding-bottom:0;
    padding-left:${ 15 / 320 * 100 }%;
    padding-right:${ 15 / 320 * 100 }%;
  }`

export const SignInForm = styled.form`flex:1 1 ${ desktopColumnWidth / desktopTotalWidth * 100 }%;`

export const SignInDivider = styled.div`
  flex:1 1 ${ desktopDividerWidth / desktopTotalWidth * 100 }%;
  display:flex;
  justify-content: center;
  align-items: center;
  position:relative;

  ::after{
   content: "";
   position: absolute;
   top:0;
   left:50%;
   transform:translateX(-50%);
   background:#ccc;
   width:1px;
   height: 100%;
  }
  img {
    position: absolute;
    background-color: #fff;
    z-index:${Zindex.zindexOverlaystackOrder1};
    transform:translateX(-50%);
    left:50%;
    top:40%;
  }`

export const SigInInnerDiv = styled.div`
  line-height:50px;
  & input[type=checkbox] {

  opacity:0;
    :checked + label::after {
      content: "";
    }
    :focus + label::before {
      outline: rgb(59, 153, 252) auto 5px;
    }
  }

  label {
    position: relative;
    padding-left: 10px;
    &:before,&:after{
      position: absolute;
      content: "";
      display: inline-block;
    }
    &:before{
      height: 25px;
      width: 25px;
      border: 1px solid #2774AE;
      left: -${20 / defaultFontSize}rem;
      top: -${3 / defaultFontSize}rem;
      cursor:pointer;
    }
    &:after {
      height: 6px;
      width: 12px;
      color:#2774AE;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      left: -${14 / defaultFontSize}rem;
      top: ${4 / defaultFontSize}rem;
    }
  }
  input[type="checkbox"] + label::after {
    content: none;
  }
  & > a {
    text-decoration:none;
    float:right;
  }`

export const PasswordCheck = styled.div`
  position: absolute;
  display: inline-block;
  right: 20px;
  top: 40px;
`

export const MobileGutter = styled.div`
  @media ${device.mobile} {
    margin: 0 4px;
    text-align: left;
  }`
