import { Component } from 'react'

import { ThemeContext } from 'ThemeContext'
import { StyledMenu, StyledNavLink } from 'common/header/MenuStyles'

class Menu extends Component {

  render() {
    // for some reason react on node > 14 complains about the ThemeContext not being initiated for this file and LinkCrm.js
    Menu.contextType = ThemeContext
    if (!this.context.name) return

    const crmUser = this.props.authenticatedUser ? this.props.authenticatedUser.crmUser : null

    const isApAdmin = this.props.authenticatedUser && localStorage.isApAdmin && !sessionStorage.altCrmId // don't show admin link when browsing as another user

    const adminLink = isApAdmin && <li><StyledNavLink onClick={this.props.hideHeaderMenu} className="bold header-links" id="headerAdminLink" to={this.context.defaultAdminLink || "/admin/linkusertocrm"}>ADMIN</StyledNavLink></li>

    const overviewLink = crmUser && !this.props.hasMultipleAccounts
                            && <li><StyledNavLink  onClick={this.props.hideHeaderMenu} className="bold header-links" id="headerOverviewLink" to="/overview">OVERVIEW</StyledNavLink></li>
    const myInformationLink = crmUser && !this.props.hasMultipleAccounts
                            && <li><StyledNavLink  onClick={this.props.hideHeaderMenu} className="bold header-links" id="headerMyInformationLink" to="/profile">UPDATE PROFILE</StyledNavLink></li>

    const alumniEmailLink = crmUser?.alumniProfile && !this.props.hasMultipleAccounts
                            && <li><StyledNavLink  onClick={this.props.hideHeaderMenu} className="bold header-links" id="headerAlumniEmailLink" to="/alumniemail">ALUMNI EMAIL</StyledNavLink></li>

    return (
      <StyledMenu id="headerLinks">
        {adminLink}
        {overviewLink}
        {myInformationLink}
        {alumniEmailLink}
      </StyledMenu>
    )
  }
}

export default Menu
