import styled from 'styled-components'

import { lightGray } from 'css/Colors'

import { ReceiptSummaryHeader, GiftLineItem, defaultPrintFontSize } from './PrintStyles'
import { formatAmount } from '../mygivingutil'

const SummaryStyles = styled.div`
  position:relative;
  padding:${ 10/defaultPrintFontSize }em ${ 14/defaultPrintFontSize }em ${ 14/defaultPrintFontSize }em;
  border-top:1px solid #000000;

  /* the joys of print css */
  background:${ lightGray };
  -webkit-print-color-adjust: exact;

  h2 {
    text-align:right;
  }

  .summary-recognition {
    div {
      display:flex;
      justify-content:flex-end;
      margin:${ 5/defaultPrintFontSize }em 0;
    }
  }
`

const PledgeSummary = props => {
  const { amount, balance } = props
  return (
    <SummaryStyles>
      <ReceiptSummaryHeader>Pledge Summary</ReceiptSummaryHeader>

      <div className="summary-totals">
        <div className="summary-recognition">
          <div>Total Amount: <GiftLineItem className="has-underline">{ formatAmount(amount) }</GiftLineItem></div>
          <div>Remaining Balance: <GiftLineItem className="has-underline">{ formatAmount(balance) }</GiftLineItem></div>
        </div>
      </div>
    </SummaryStyles>
  )
}

export default PledgeSummary
