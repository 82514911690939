import { Route, Switch } from 'react-router-dom';
import PrivateRoute from 'common/PrivateRoute';

import MoreInfo from 'common/MoreInfo';
import authCss from 'authentication/sass/authenticate.module.scss'

import Home from './home/Home';
import FAQ from './faq/FAQ'

import History from './history/GivingHistory'
import PrintSummaries from './history/PrintSummaries'

import Dashboard from './dashboard/Dashboard';
import Reports from './reports/Reports'
import UpdatePayment from './payment/UpdatePayment'
import PaymentMethods from './payment/PaymentMethods'
import SuccessfulUpdatePayment from './payment/SuccessfulUpdatePayment';
import PromoPage from './promopage/PromoPage';
import Menu from './components/Menu'
import logoImage from './images/mygivinggatewaylogo.svg';
import { HeaderGradient } from 'common/header/HeaderStyles';
import * as analytics from './analytics';

import LinkUserSuccess from 'admin/linkuser/LinkUserSuccess'
import LinkCrm from 'admin/linkuser/LinkCrm'
import BrowseAsUser from 'admin/BrowseAsUser'
import UserSearch from 'admin/sharedqueries/UserSearch'
import AuditTrail from 'admin/sharedqueries/AuditTrail'
import UnconfirmedUsers from 'admin/UnconfirmedUsers'

import AdminViewList from './admin/AdminViewList'
import SearchReceipt from './admin/SearchReceipt'
import DownloadSearch from './admin/dpqueries/DownloadSearch'
import DocumentSearch from './admin/dpqueries/DocumentSearch'
import UploadDocuments from './admin/upload/UploadDocuments'
import DeleteUser from './admin/DeleteUser'

import PledgesPage from './pledges/PledgesPage'
import PayPledges from './pledges/PayPledges'

const routes = [
  <Route exact={true} path='/' component={Home} key='/home' />,

  <PrivateRoute exact path='/history' component={History} key='/history' />,
  <PrivateRoute path='/pledges' component={PledgesPage} key='/pledges' />,
  <PrivateRoute path='/paypledge' component={PayPledges} key='/paypledge' />,
  <PrivateRoute exact path='/printsummaries' component={PrintSummaries} key='/printsummaries' />,

  <PrivateRoute exact path='/dashboard' component={Dashboard} key='/dashboard' />,
  <PrivateRoute exact path='/reports' component={Reports} key='/reports' />,
  <PrivateRoute exact path='/payment' component={UpdatePayment} key='/payment' />,
  <PrivateRoute exact path='/paymentmethods' component={PaymentMethods} key='/paymentmethods' />,
  <PrivateRoute exact path='/paymentupdate' component={SuccessfulUpdatePayment} key='/paymentupdate' />,
  <Route exact path='/faq' component={FAQ} key='/faq' />,
  <Route exact path='/promopage' component={PromoPage} key='/promopage' />,
]

const themeHeader = <HeaderGradient />

const REGISTRATION_AFFILIATION = (
  <div>You may be affiliated with UCLA in one or more ways. Please use the email you associate with UCLA.
    <ul>
      <li>Alumni: If you have not changed your contact information with UCLA, please use the email or cell phone on your student record.</li>
      <li>Students: Please use the email or cell phone on your student record.</li>
      <li>Faculty / Staff: Please use the email or cell phone on your payroll account.</li>
    </ul>
  </div>
);

const REGISTRATION_TEXT = "To register, please enter the email address or mobile number associated with your giving history"

const REGISTRATION_HELPER_TEXT = `Please use the email address or cell phone number you used when making a gift to UCLA or attending a university event. This is also the email or phone number we would have used to send you a gift receipt or registration confirmation.`

const cognitoClientIds = {
  "dev": "4oio5n4d45sjadlhdh2oafnrm5",
  "stage": "698b5st9k34edak8o95ua7m8ka",
  "pilot": "4rhht8im47q2rabcgni8q3sh5v",
  "preprod": "1qd5vk6of3vlii3d91l8vao00v",
  "prod": "48ggb35486ovudrs8eukllmikj",
}

const registrationText = (
  <>
    <span className="block-title bold">
      {REGISTRATION_TEXT}
    </span>
    <div className={authCss.moreInfoPosition}>
      <MoreInfo
        htmlName=""
        srText={"What is my giving history account?"}
      >
      {REGISTRATION_HELPER_TEXT}
      </MoreInfo>
    </div>
    <span className="block-title bold">
      or UCLA affiliation
    </span>
    <div className={authCss.moreInfoPosition}>
      <MoreInfo
        htmlName=""
        srText={"What is my UCLA affiliation?"}
      >
      {REGISTRATION_AFFILIATION}
      </MoreInfo>
    </div>
    .
  </>)

const themeAdminLinks = (parentHistory) => (
  <div className="admin-content-wrapper">
    <Switch>
      <PrivateRoute exact={true} path='/admin/linkusertocrm' key='/admin/linkusertocrm' component={LinkCrm} />
      <PrivateRoute exact={true} path='/admin/linkusertocrm/success' key='/admin/linkusertocrm/success' component={LinkUserSuccess} />
      <PrivateRoute exact={true} path='/admin/linkusertocrm' key='/admin/linkusertocrm' component={LinkCrm} />
      <PrivateRoute exact={true} path='/admin/browseasuser' key='/admin/browseasuser' component={BrowseAsUser} data={{parentHistory}} />
      <PrivateRoute exact={true} path="/admin/history" key="/admin/history" component={AuditTrail} />
      <PrivateRoute exact={true} path="/admin/users" key="/admin/users" component={UserSearch} />
      <PrivateRoute exact={true} path="/admin/unconfirmedusers" key="/admin/unconfirmedusers" component={UnconfirmedUsers} />

      <PrivateRoute exact={true} path='/admin/printreceipt' key='/admin/printreceipt' component={SearchReceipt} />
      <PrivateRoute exact={true} path="/admin/downloads" key="/admin/downloads" component={DownloadSearch} />
      <PrivateRoute exact={true} path="/admin/documents" key="/admin/documents" component={DocumentSearch} />
      <PrivateRoute exact={true} path="/admin/upload" key="/admin/upload" component={UploadDocuments} />
      <PrivateRoute exact={true} path='/admin/deleteuser' key='/admin/deleteuser' component={DeleteUser} />
    </Switch>
  </div>
)

// For when the user clicks Give Again or Pay Pledge, goes to the OLG site, then clicks Edit Profile
const isFromOlg = (window.location.search?.indexOf('ref=olg') > -1)

// IMPORTANT: some of these are inherited or imported directly by other themes
export const mygiving = {
  name: 'mygiving',
  apiGatewayHost: process.env.REACT_APP_MGG_API_GATEWAY_HOST,
  routes,
  cognitoClientId: cognitoClientIds[process.env.REACT_APP_AWS_ENVIRONMENT || "dev"],

  isProdEnv: (process.env.REACT_APP_AWS_ENVIRONMENT === "prod"),

  logoImage,
  themeHeader,
  themeMenu: Menu,
  windowTitle: 'My UCLA Giving Gateway',
  signinText: "Please enter the email or cell phone number associated with your My Giving Gateway account.",
  registrationTitle: "My Giving Gateway Registration",
  registrationText,
  crmUserLandingPage: '/dashboard',
  nonCrmUserLandingPage: '/promopage',
  supportTeamName: "Donor Relations",
  appName: "My Giving Gateway",
  supportEmail: "MyGivingGateway@support.ucla.edu",
  supportPhone: "(310) 794-2447",
  themeAdminViewList: AdminViewList,
  themeAdminLinks,
  adminUserTest: "isDpAdmin",
  themeAnalytics: analytics,
  extraGetUserParam: '?app=mgg',

  // For when the user clicks Give Again or Pay Pledge, goes to the OLG site, then clicks Edit Profile
  closeWindowOnProfileSave: isFromOlg,
  hideHeader: () => isFromOlg,
  hideFooter: () => isFromOlg,
};

