import { useEffect, useState } from 'react'
import { useSelector } from "react-redux"

import { getLoggedInUser } from "redux/selectors"
import { history } from 'services/history.js'
import { showLoading } from 'services/util'

import { makePayment } from '../payment/paymentapi'

import themeCss from '../components/sass/theme.module.scss'

export default function AuctionsUserPage() {
  const [dateAdded, setDateAdded] = useState('')
  const [itemId, setItemId] = useState('')
  const [submitResults, setSubmitResults] = useState({})

  const loggedInUser = useSelector(getLoggedInUser)

  useEffect(() => {
    if (!loggedInUser?.cognitoUser) // login process hasn't completed yet
      return
 }, [loggedInUser])

  const submitDemoPayment = async (e) => {
    e.preventDefault()
    showLoading(true)
    const response = await makePayment(itemId, dateAdded)
    const parsedResponse = response

    // just parsing ResponseData for demo purposes, probably not something you'll need to do in code unless debugging
    /// ResponseData does not return in prod
    parsedResponse.ResponseData = JSON.parse(parsedResponse.ResponseData)
    console.log({parsedResponse})
    setSubmitResults(parsedResponse)
    showLoading(false)
  }

  return (
    <>
      <h2>Auction User Page</h2>
      <button 
        onClick ={(e) => {history.push({pathname: '/user/profileupdate'})}}
        className={`${themeCss['FilledBlue']} ${themeCss['button']}`}>
          Update My Information</button>
      <h3></h3>

      <h1>Make Payment Example</h1>
      <form>
        ItemId (pick an item you are the high bidder on): <input type="text" value={itemId} onChange={(e) => setItemId(e.target.value)}/>
        <br/>
        Payment Method DateAdded: <input type="text" value={dateAdded} onChange={(e) => setDateAdded(e.target.value)}/>
        <br/>
        <pre>submitResults: {JSON.stringify(submitResults,0,2)}</pre>
        <br/>
        <button onClick={submitDemoPayment} >Submit Demo Payment</button>
      </form>
    </>
  )
}