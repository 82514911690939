
import { UserInfoDisplay, EachUserLayout } from './UserProfile'
import * as cs from 'css/CommonStyle';
import * as colors from 'css/Colors';

import styled from 'styled-components'

const defaultFontSize = 16;

const CRMProfile = (props) => {
  const specialHandling = props.specialHandling || {};

  let CRMEachUserLayout;
  if (props.isBrowseAsUser) {
    CRMEachUserLayout=styled(EachUserLayout)`
      width:98.6%;
      margin: -10px 0 0 -16px; // sorry about this I couldn't figure out how to align it any other way
      padding: 20px 20px 0 20px;
      border: 1px solid lightgrey;
      background-color:#FFFFC3;
      & >div{
        z-index:10;
        flex:1 1 10%;
        white-space: nowrap;
        padding: 5px 5px 0 5px;
        label{
          font-size:${12 / defaultFontSize}rem;
          color:${colors.darkGray};
        }
        & label:nth-child(2){
          font-size:${14 / defaultFontSize}rem;
          color:#000000;
        }
        .address-ellipse {
          width:200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    `
  }
  else {
    CRMEachUserLayout=styled(EachUserLayout)`
      background-color:#FFFFC3;
      & >div{
        z-index:10;
        flex:0 33%;
        label{
          padding-right: .5rem;
          font-size:${12 / defaultFontSize}rem;
          color:${colors.darkGray};
        }
        & label:nth-child(2){
          font-size:${14 / defaultFontSize}rem;
          color:#000000;
        }
      }
    `
  }

  let fullname=props.foundCRMUser.names.map((item,index) => {
    if (item.nameType === "Primary") {
      return item.keyName+", "+item.firstName+(item.middleName ? " " + item.middleName[0] : '')
    }
    else {
      return null
    }
  })
  let constituent=props.foundCRMUser.isOrganization?'Organization' :'Individual';

  let address=props.foundCRMUser.addresses.map((item,index) => {
    if(item.isPrimary) {
      return item.addressBlock+`, `+item.city+", "+item.state
    }
    else {
      return null
    }
  })
  let phonenumber=props.foundCRMUser.phones.map((item,index) => {
    if(item.isPrimary) {
      return item.number
    }
    else {
      return null
    }
  })
  let email=props.foundCRMUser.emails.map((item,index) => {
    if(item.isPrimary) {
      return item.emailAddress
    }
    else {
      return null
    }
  })

  let uclaOneUserId = props.foundCRMUser.uclaOneUserId;

  return (
      <UserInfoDisplay className="crm-user-information" >
        <CRMEachUserLayout className="each-user-layout">
          <cs.TextInputdiv>
            <label> Lookup ID </label>
            <label className="bold">{props.foundCRMUser.constituentLookupId}</label>
          </cs.TextInputdiv>

          {specialHandling.showUcla1Id && <cs.TextInputdiv>
            <label> UCLA One User ID </label>
            <label >{uclaOneUserId || "[NONE]"}</label>
          </cs.TextInputdiv>}

          <cs.TextInputdiv>
            <label> Full Name </label>
            <label >{fullname}</label>
          </cs.TextInputdiv>

          <cs.TextInputdiv>
            <label> Primary Email Address </label>
            <label>{email||"None"}</label>
          </cs.TextInputdiv>

          <cs.TextInputdiv>
            <label> Phone Number </label>
            <label>{phonenumber}</label>
          </cs.TextInputdiv>

          <cs.TextInputdiv>
            <label> Constituent </label>
            <label>{constituent}</label>
          </cs.TextInputdiv>

          <cs.TextInputdiv>
            <label> Address </label>
            <label className={props.isBrowseAsUser ? "address-ellipse" : ""}>
              {address||"None"}
            </label>
          </cs.TextInputdiv>

        </CRMEachUserLayout>
      </UserInfoDisplay>

  )
}

export default CRMProfile;