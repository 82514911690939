import React from 'react';

import store from 'redux/store';
import { setTheme } from "redux/actions";

import { mygiving } from "sites/mygiving/theme"
import { alumni } from "sites/alumni/theme"
// import { classy } from "sites/classy/theme"
import { cidm } from "sites/cidm/theme"
import { olg } from "sites/olg/theme"
import { olgalumni } from "sites/olgalumni/theme"
import { ucla1 } from "sites/ucla1/theme"
import { asc } from "sites/asc/theme"
import { auctions } from "sites/auctions/theme"
import { events } from "sites/events/theme"

export const themes = {
  mygiving,
  auctions,
  alumni,
  asc: {...alumni, ...asc},
  // classy: {...mygiving, ...classy},
  cidm: {...mygiving, ...cidm},
  olg: {...mygiving, ...olg},
  olgalumni: {...mygiving, ...olg, ...olgalumni},
  ucla1: {...alumni, ...ucla1},
  events,
}

function getCobranding() {
  let themeName = process.env.REACT_APP_COBRANDING || 'cidm'

  console.log('themeName', themeName);

  if (/account.alumnischolarsclub.alumni.ucla/.test(window.location.hostname))
    themeName = 'asc'
  else if (/account.alumni.ucla/.test(window.location.hostname))
    themeName = 'alumni'
  else if (/mygiving/.test(window.location.hostname))
    themeName = 'mygiving'
  else if (/alumniaccount.giving/.test(window.location.hostname))
    themeName = 'olgalumni'
  else if (/account.giving/.test(window.location.hostname))
    themeName = 'olg'
  else if (/uclaone/.test(window.location.hostname))
    themeName = 'ucla1'
  else if (/account.cidm.support2/.test(window.location.hostname))
    themeName = 'cidm'
  else if (/auctions.support2/.test(window.location.hostname))
    themeName = 'auctions'
  else if (/account.events/.test(window.location.hostname))
    themeName = 'events'
  // else if (/cidm/.test(window.location.hostname))
  //   themeName = 'classy'

  return themeName
}

export const theme = themes[getCobranding()]

console.log("theme: ", theme)

store.dispatch(setTheme(theme))

export const ThemeContext = React.createContext(theme)

