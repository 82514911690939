import { Component } from 'react';
import { withRouter,BrowserRouter, Switch } from "react-router-dom"

import { ThemeContext } from 'ThemeContext';
import store from 'redux/store';
import { getLoggedInUser } from 'redux/selectors'

import AdminSideBar from 'admin/AdminSideBar'

import * as colors from 'css/Colors';
import styled from 'styled-components'

const defaultFontSize = 16

// grid specific to the admin section
const sideBarWidth = 355
const mainSectionWidth = 1085

// AdminGrid height = full viewport - header height - footer height
const AdminGrid = styled.div`
  display: flex;
  min-height: calc(100vh - ${89/defaultFontSize}rem - 75px);
  position: relative;
  .admin-sidebar-wrapper {
    box-sizing: border-box;
    flex: 1 0 ${ sideBarWidth / (sideBarWidth + mainSectionWidth) * 100 }%;
    background-color: ${colors.secondaryDarkBlue};
    max-width: 400px;
  }
  .admin-content-wrapper {
    box-sizing: border-box;
    flex: 1 0 ${ mainSectionWidth / (sideBarWidth + mainSectionWidth) * 100 }%;
    padding: 0 ${ 30/defaultFontSize }rem;
  }
`

class AdminComponent extends Component {
  state = {
    loggedInUser: null,
    userinfo:''
  }

  async componentDidMount() {
    const loggedInUser = getLoggedInUser(store.getState());

    if (!loggedInUser || !localStorage[this.context.adminUserTest]) {
      this.props.history.push('/');
      return;
    }

    this.setState({ userinfo: this.userInitials(loggedInUser) })
  }

  userInitials=(loggedInUser)=>{
    let userObject={initials:"ME",fullname:''};

    if (loggedInUser.crmUser) {
      const profile = loggedInUser.crmUser.profile
      userObject.initials = profile.primaryName.firstName.charAt(0) + profile.primaryName.keyName.charAt(0);
      userObject.fullname=profile.primaryName.firstName+" "+profile.primaryName.keyName
    }
    return userObject;
  }

  render() {
    return (
      <>
        <BrowserRouter>
          <div>
            <AdminGrid>
              <div className="admin-sidebar-wrapper hide-on-print">
                <AdminSideBar userinfo={this.state.userinfo} />
              </div>

              {this.context.themeAdminLinks(this.props.parentHistory)}

            </AdminGrid>
          </div>
        </BrowserRouter>
      </>
    )
  }
}

export default withRouter(AdminComponent);
AdminComponent.contextType = ThemeContext;