import { useState } from 'react';

import { showLoading } from 'services/util'
import DropDownMenu from 'common/forms/DropDown'
import { FilledYellow } from 'css/Buttons'

import { ReportsContainer, ReportsBlock, ReportsListing, RowWrapper, YearDropdown, ReportLink, YearHeader, Divider, PastYearsContainer, PastYearsLink} from '../ReportStyles'

import { downloadDocument, getGrabBagReports, getPastGrabBagReports } from '../documentsapi'

function AcrReports(props) {
  const [showAcrPastYears, setShowAcrPastYears] = useState(false)
  const [viewAcrPastYearsText, setViewAcrPastYearsText] = useState("View Past Years +")
  const [pastAcrReports, setPastAcrReports] = useState(null)
  const [allAcrPastYears, setAllAcrPastYears] = useState([])
  const [acrPastYear, setAcrPastYear] = useState(null)

  const handleAcrReportYearChange = e => {
    setAcrPastYear(e.target.value)
  }

  const toggleAcrLastYears = async e => {
    if (showAcrPastYears) {
      setShowAcrPastYears(false)
      setViewAcrPastYearsText("View Past Years +")
    }
    else {
      showLoading(true)

      const pastReports = await getPastGrabBagReports()
      setPastAcrReports(pastReports)

      const uniqueYears = new Set()
      pastReports.forEach(report => uniqueYears.add(report.reportYear))
      const pastYears = Array.from(uniqueYears).sort().reverse()
      setAllAcrPastYears(pastYears)
      setAcrPastYear(pastYears[0])

      showLoading(false)
      setShowAcrPastYears(true)
      setViewAcrPastYearsText("Hide Past Years -")
    }
  }

  const allGrabBagReports = getGrabBagReports()

  let filteredGrabBagReports
  if (allGrabBagReports && allGrabBagReports.length) {
    // don't show reports with duplicate names within year for subtype school and subtype department
    const grabBagReportsDupeCheck = {}
    filteredGrabBagReports = allGrabBagReports.filter(report => {
      if (["School", "Dept"].indexOf(report.reportSubType) === -1) {
        return true
      }
      else if (!grabBagReportsDupeCheck[report.reportYear+report.reportSubType]) {
        grabBagReportsDupeCheck[report.reportYear+report.reportSubType] = [report.reportName]
        return true
      }
      else if (grabBagReportsDupeCheck[report.reportYear+report.reportSubType].indexOf(report.reportName) > -1) {
        return false
      }
      else {
        grabBagReportsDupeCheck[report.reportYear+report.reportSubType].push(report.reportName)
        return true
      }
    })
  }

  return (
    <ReportsContainer>
      <ReportsBlock>
        <h3 className="h3">Additional Campus Reports</h3>
        <p>Please click on the "Download" button in the section below to view additional reports.</p>

      <ReportsListing>
        {!filteredGrabBagReports &&
          <YearHeader>No Current Reports</YearHeader>}

        {filteredGrabBagReports && filteredGrabBagReports.map((report,idx) => (
          <>
            <YearHeader>View additional campus reports:</YearHeader>
              <RowWrapper className="reports-row-wrapper" key={idx}>
                <ReportLink>
                  {report.reportYear} {report.reportName}
                  <FilledYellow id={"grabBagLink"+(1*idx+1)} onClick={e => downloadDocument(report)}>Download</FilledYellow>
                </ReportLink>
              </RowWrapper>
            </>
        ))}

        <PastYearsContainer>
          <Divider/>
          <span><PastYearsLink onClick={toggleAcrLastYears}>{viewAcrPastYearsText}</PastYearsLink></span>
        </PastYearsContainer>

        {showAcrPastYears &&
          <>
            <YearHeader>Past Years:</YearHeader>

            <YearDropdown>
              <DropDownMenu
                className="report-year-dropdown"
                name="AdditionalCampusReportYearList"
                title={acrPastYear}
                list={allAcrPastYears}
                changeHandler={handleAcrReportYearChange}
              />
            </YearDropdown>

            {pastAcrReports && pastAcrReports.filter(report => 1*report.reportYear === 1*(acrPastYear)).map((report,idx) => (
              <RowWrapper className="reports-row-wrapper" key={idx}>
                <ReportLink>
                  {report.reportYear} {report.reportName}
                  <FilledYellow id={"grabBagLink"+report.reportYear+"-"+(1*idx+1)} onClick={e => downloadDocument(report)}>Download</FilledYellow>
                </ReportLink>
              </RowWrapper>
            ))}
          </>}

      </ReportsListing>

      </ReportsBlock>
    </ReportsContainer>
  )
}

export default AcrReports