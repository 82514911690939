export const formatDate = (dateFromAuction) => {
  const date = new Date(dateFromAuction)
  const time = date.toLocaleTimeString('en-US', {hour12: true, hour: '2-digit', minute: '2-digit'})
  let tZA = Intl.DateTimeFormat('en-US', {
    timeZone: 'America/Los_Angeles',
    timeZoneName: 'short'
  }).formatToParts(date).find(part => part.type === 'timeZoneName').value;

  return date.toDateString() + ' - ' + time + ' ' + tZA
}
