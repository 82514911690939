import { doPostLogin } from "authentication/postlogin"
import { useEffectOnce } from "../helper"

import { showLoading } from "services/util"

// browser is redirected back to this page after Oauth call
// needs to call postLogin() due to auto-login in ForceSignin
function NewAmplifyPostLogin(props) {

  // in strict mode/development mode react renders every component twice
  useEffectOnce(() => {
    async function doInit() {
      showLoading(true, "PostLogin")
      await doPostLogin()
      showLoading(false, 'PostLogin')
		}
	  doInit()
  })

	return (
		<div>
		</div>
	)
}

export default NewAmplifyPostLogin;
