import { Link } from "react-router-dom"

import ThickCheck from 'images/Icon _ thick-check.svg'
import css from '../sass/ucla1id.module.scss'

const LinkUcla1IdSuccess = (props) => (
  <div>
    <h3>Set UCLA One User ID in CRM</h3>
    <p className="bold">UCLA One ID has been updated. <img src={ThickCheck} alt='success icon'/> </p>
    <p>The new UCLA One ID will be sent to Graduway (uclaone.com) the next time the user logs on.</p>
      <div className={css["backwardLinks"]}>
        <Link to="/admin/ucla1id" > <em className="bold">Go back to Set UCLA1 User ID ></em></Link>
      </div>
  </div>
)

export default LinkUcla1IdSuccess;