import { useEffect, useState } from 'react'
import { useQuery } from "@tanstack/react-query"

import { Link } from 'react-router-dom'
import { history } from 'services/history.js'
import { showLoading } from 'services/util'

import adminCss from './sass/adminpage.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import PreviewPublishChecklist from './PreviewPublishChecklist'
import { updateAuctionStatus } from './auctionsadminutil'
import UpdateAuctionStatusButton from './UpdateAuctionStatusButton'
import AuctionStatusAlert from './AuctionStatusAlert'

export default function PreviewPublish(props) {


  const auctionStatus = props.auctionDetail?.Status

  return (
    <div className={adminCss['widget-container']}>
      <div className={adminCss['widget-status-container']}>
        <AuctionStatusAlert liveStatus={props.liveStatus} auctionStatus={auctionStatus} />
        <PreviewPublishChecklist liveStatus={props.liveStatus} auctionDetail={props.auctionDetail} location={'widget'} />
      </div>

      {auctionStatus === 'PREVIEW' && <div className={adminCss['widget-button-container']}>
          
          <Link className={`${themeCss['button']} ${themeCss['FilledYellow']}`}
            to={`/admin/testauctionhome?auctionId=${props.auctionDetail.AuctionCode}`}>
            Test Site
          </Link>
        
        <UpdateAuctionStatusButton auction={props.auctionDetail} fromStatus={'PREVIEW'} toStatus={'PUBLIC'} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} />
      </div>}
      
      {auctionStatus === 'PUBLIC' && <div className={adminCss['widget-button-container']}>
        <UpdateAuctionStatusButton auction={props.auctionDetail} fromStatus={'PUBLIC'} toStatus={'PREVIEW'} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} />
        {props.liveStatus === 'started' &&
          <UpdateAuctionStatusButton auction={props.auctionDetail} fromStatus={'PUBLIC'} toStatus={'ENDED'} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} />}
        {props.liveStatus === 'ended' &&
          <UpdateAuctionStatusButton auction={props.auctionDetail} fromStatus={'PUBLIC'} toStatus={'CLOSED'} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} />}
      </div>}
      {auctionStatus === 'CLOSED' && <div>
        <UpdateAuctionStatusButton auction={props.auctionDetail} fromStatus={'CLOSED'} toStatus={'PUBLIC'} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} />
      </div>}
      
      
    </div>
  )
}