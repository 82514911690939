import { useEffect } from 'react'

import { formatMoney } from 'services/util'
import css from './sass/itembid.module.scss'
import themeCss from '../components/sass/theme.module.scss'

export default function BidSelector({ item, itemBidAmount, setItemBidAmount}) {

  //sets initial bid amount based off item status (no bids or with bids)
  let minBid = item.StartingBid
  if (item.BidCount) minBid = parseInt(item.CurrentBid) + parseInt(item.MinRaise)
  if (itemBidAmount < minBid) setItemBidAmount(minBid)

  useEffect(() => {
   setItemBidAmount(minBid)
  }, [minBid, setItemBidAmount])


  const bids = [minBid]
  for (let i=0;i<2;i++) {
    bids.push(bids[i] + parseInt(item.MinRaise))
  }

  const isBidActive = (value) => {
    if (value === itemBidAmount) return 'bid-value-active'
    else return 'bid-value-inactive'
  }

  if (!itemBidAmount) return <></>

  return (
    <>
      <p className={css['bid-text-input']}>Your bid</p>
      <input className={css['bid-input']} disabled={true} type='text' value={`$${formatMoney(itemBidAmount)}`}></input>
      
      <div className={css['bid-selectors']}>
        {bids && bids.map((bid) => {
          return <div key={bid} className={`${css[isBidActive(bid)]} ${themeCss['shadowed-light']}`} onClick={() => setItemBidAmount(bid)}>{`$${formatMoney(bid)}`}</div>
        })}
      </div>
    </>
  )
}