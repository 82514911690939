import store from 'redux/store';
import { doApi } from 'services/api';

export const linkSpouseUser = async (crmId) => {
  return await doApi({route:'linkuser', method:'POST', data:{crmId}});
};

// sets cookie to be reflected back from 3rd party site after login - to get more user info. like CRM ID
export const setSsoCookie = async (firstName, lastName) => {
  console.log('setSsoCookie', {firstName, lastName})
  const theme = store.getState().theme

  const setSsoCookiePath = store.getState().theme.setSsoCookiePath || 'setssocookie'
  let  params = (firstName && lastName) ? '?firstname=' + firstName + '&lastname=' + lastName : ''

  return await doApi({route:setSsoCookiePath + params, withCredentials: true})
};

// called when a sign up fails due to user existing - if user is UNCOFIRMED status, deletes user
export const doUnconfirmed = async (username) => {
  if (username.indexOf('+') === 0) username = username.substr(1); // + sign doesn't travel well in URL
  return await doApi({route:'unconfirmed?username='+username});
};