import QrTicket from '../components/QrTicket'

import css from './sass/auctionscards.module.scss'

export default function AuctionQrCard( {auction, isCheckedIn} ) {
  
  if (isCheckedIn) return <></>

  return (
    <div className={css['qr-container']}>
      <p className={css['auction-box-info-heading']}>
        Please use this QR code to check in when you arrive at the event.
      </p>
      <div className={css['qr-image-container']}>
        <QrTicket auctionId={auction.AuctionId} />
      </div>
    </div>
  )
}




