import { Component } from 'react'
import { withRouter } from "react-router-dom";


const initialState = {
  isActive: false,
  nextLocation: null
};

class AlterChangesPrompt extends Component {

  constructor(props) {
    super(props);
    this.block = this.block.bind(this);
    this.when = this.when.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onConfirm = this.onConfirm.bind(this);

    this.state = {
      ...initialState,
      unblock: this.props.history.block(this.block),
      previousUserAction: "",
      open: false,
    }

  }

  block(nextLocation, action) {
    const result = this.when(nextLocation);
    if(this.props.history.location.pathname!== nextLocation.pathname){
    if (result) {
      this.setState({
        action,
        nextLocation,
        isActive: !this.state.isActive,
      });

    }
  }
    return !result;
  }

  when(nextLocation) {
    if (typeof this.props.when === "function") {
      return this.props.when(this.props.location, nextLocation);
    } else {
      return this.props.when;
    }
  }

  componentDidMount() {
    console.log(this.props.history.location.pathname)
  }

  onCancel() {
    this.setState({ ...initialState, previousUserAction: "CANCEL"  })
  }

  onConfirm() {
    this.navigateToNextLocation();
  }

  navigateToNextLocation(forwardLocation){
    let { nextLocation }=this.state
    const { history }=this.props

    if(!nextLocation) nextLocation={pathname:"/"}
    this.state.unblock()
    this.setState({ previousUserAction: "CONFIRM" }, () => {
      history.push(nextLocation)
    })
  }

  componentWillUnmount(){
    this.state.unblock();
  }

  render() {
   if (!this.state.isActive) return null;
    return (
      <div >
      {this.props.children({
          isActive: this.state.isActive,
          onConfirm: this.onConfirm,
          onCancel: this.onCancel
        })}

      </div>
    )
  }
}

export default withRouter(AlterChangesPrompt)
