import styled from 'styled-components';
import {device} from 'common/Device';
import * as colors from 'css/Colors';

import Zindex from 'css/z-indexLayers.js';

const defaultFontSize=16;

export const HeaderTitle=styled.h1`
position:relative;
z-index:${Zindex.zindexOverlaystackOrder1};
width:100%;

& h1, &.h1 {
  color:#FFFFFF;
}
`

export const TextSpan=styled.span`
  color:${colors.secondaryMediumBlue};
  font-style:${props=> props.fontStyle ||"normal"};
  cursor:pointer;
`;

export const CommonOuterDiv=styled.div`
text-align:center;
padding:60px;

@media ${device.mobile}{
  /* padding:20% 0; */
  padding:0;
  width:100%;
}`

export const CommonHeader=styled.div`

color: ${colors.primaryBlue};
font-family: Helvetica;
font-size: ${ 24/defaultFontSize }rem;
font-weight: bold;
letter-spacing: 0.1px;
margin-bottom:20px;
`;

export const CommonInnerDiv=styled.div`

    display: inline-block;
    background-color: #f6f6f6;
    width:${ 669/defaultFontSize }rem;

    @media ${device.mobile}{
      width:100%;
      padding:10px 0;
    }
`;

export const InnerContent=styled.div`
padding:20px 40px;
text-align:center;

&  p{
  color: #000000;
  font-family: Arial;
  font-size: 16px;
  line-height: 28px;
}`;

export const ErrorSpan = styled.div`
height: 1rem;
width: 100%;
color: ${colors.messagingRed};
font-size: ${ 12/defaultFontSize }rem;
line-height: 1;
text-align: ${props => props.textAlign || "center"};
padding-top: ${ 10/defaultFontSize }rem;
`
export const PasswordHintSpan = styled.div``;

export const TextInputdiv=styled.div`
  position: relative;
  display:block;

  margin:0 auto;
  margin-bottom:${ 18/defaultFontSize }rem;
.error{
    border: 1px solid ${colors.messagingRed};
  }

  .alignLeft {
    text-align: left !important;
  }


  ${PasswordHintSpan}{
    /* height: ${ 56/defaultFontSize }rem; */
    width: 100%;
    color: #D0021B;
    font-family: Arial;
    font-size: 14px;
    line-height: 1;
    text-align: left;
  }
  ${PasswordHintSpan} label.green{
    color: #1b9c68;
  }
`;

export const CommonFooter=styled.div`
position: relative;
display:block;
margin:0 auto;
margin-bottom:${ 18/defaultFontSize }rem;
text-align: center;

.error{
  border: 1px solid ${colors.messagingRed};
}
.resendmsg{
  color: ${colors.messagingRed};
}
`;

 export const FooterBtnDiv=styled.div`
margin:20px auto;
color: #828282;
width:${props=>props.width||"263px"};
text-align:center;

@media ${device.mobile}{
  width:100%;
}
`;

export const SubmitBtn=styled.button`
    width: 100%;
    height: 50px;
    background-color: #005587;
    color: #FFFFFF;
    font-family: Helvetica;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.36px;
    line-height: 19px;
  `;
