import { useEffect, useState } from 'react'
import {useDropzone} from 'react-dropzone';
import css from './sass/file.dropzone.module.scss'

function FileDropzone(props) {
  const [allFiles, setAllFiles] = useState([])

  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true
  });

  const { setSelectedFiles } = props

  const addNonDupeFiles = newAcceptedFiles => existingAllFiles => {
    const existingFilenames = [...existingAllFiles].map(file => file.name)
    return existingAllFiles.concat(newAcceptedFiles.filter(file => existingFilenames.indexOf(file.name) === -1))
  }

  useEffect(() => {
    setSelectedFiles(allFiles)
  }, [allFiles, setSelectedFiles])

  useEffect(() => {
    setAllFiles(addNonDupeFiles(acceptedFiles))
  }, [acceptedFiles])

  const files = allFiles.map(file => (
    <li key={file.path}>
      <b>{file.path}</b> &nbsp;&nbsp;(<i>size:</i> {(Math.round(file.size/100000))/10} MB, <i>type:</i> {file.type})
    </li>
  ));

  return (
    <div className={css.outer}>
      <div {...getRootProps({className: css.dropzone}) }>
        <input {...getInputProps()} />
        <p>Drag and drop files here</p>
        <button type="button" onClick={open}>
          Or click here to open File Dialog <br/><span>(hold down CTRL or CMD to select multiple)</span>
        </button>
      </div>
      {files.length > 0 &&
          <aside className={css.selectedFiles}>
          <h4>Selected Files:</h4>
          <ul>{files}</ul>
        </aside>}
    </div>
  );
}

export default FileDropzone