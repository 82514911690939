import { history } from 'services/history'

import { showLoading } from 'services/util'

import { userDeclinesTextAlerts } from '../register/flow/verifyUtils'

import CloseIcon from './images/x-primary-blue.svg'
import MessageIcon from './images/message-icon.svg'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/auctionscards.module.scss'

export default function TextMessageOff( { auction, auctionUser, textAlertNumber} ) {

  const declineRequest = async () => {

    showLoading(true)

    try {
        const signUpResponse = await userDeclinesTextAlerts(auction, auctionUser)
        console.log({signUpResponse})
        if (signUpResponse.success) {
          console.log('user declined text messages')
        }
        else {
          // do error behavior
          console.log({signUpResponse})
        }
    

      showLoading(false)
    }
    catch (e) {
      showLoading(false)
      console.log(e)
    }
  }

  return (
    <div className={themeCss['box-top-yellow-accent-wide']}>
      {/* {showModal && <PhoneAssign updateTextStatus={updateTextStatus} userPhone={userPhone} setShowModal={setShowModal} auction={auction}/>} */}
      <p className={css['auction-box-info-heading']}>Get notified by text-message when you get outbid or win an item!</p>
      <div className={css['auction-box-info-row']}
        //  onClick={() => {history.push({ pathname: '/flow/phoneverification', auction }) }}
         >
          <img className ={css['auction-box-info-icons']}
            onClick={() => {history.push({ pathname: '/flow/phoneverification', auction }) }}
            src={MessageIcon} alt='Text Message Management Icon' />
          <p className={css['auction-box-info-link']}
            onClick={() => {history.push({ pathname: '/flow/phoneverification', auction }) }}
            >Set up text-message alerts</p>
        
      </div>
      {!textAlertNumber && <div className={css['auction-box-info-row']}
        // onClick={()=>declineRequest()}
        >
        <img className ={css['auction-box-info-icons']} onClick={()=>declineRequest()} src={CloseIcon} alt='Text Message Management Icon' />
        <p className={css['auction-box-info-link']} onClick={()=>declineRequest()} >No thanks, or set up later</p>
      </div>}
    </div>
  )
}