import React from 'react'
import styled from 'styled-components'

import { doApi } from 'services/api'
import { initializeDate } from 'services/util'
import { device } from 'common/Device'
import * as colors from 'css/Colors'
import Zindex from 'css/z-indexLayers.js';

import iconClock from '../images/icon-clock-blue.svg'
import iconFire from '../images/icon-fire.svg'
import iconGoal from '../images/icon-project-goal.svg'
import { PromoProjectsContainer, SparkHeader, SparkSubheader, PromoProjectDetails } from '../promopage/PromoProjects'

const SparkFeedDetails = styled( PromoProjectDetails )`
`

const SparkImage = styled.div`
  position:relative;
  display:block;
  width:100%;
  padding-bottom:${ 300/1368*100*3 }%;

  &:before{
    position:absolute;
    width:100%;
    height:100%;
    content: '';
    display:block;
    background:${ props => props.icon ? `url("${props.icon}")` : ""};
    background-size:cover;
    background-position:center;
    background-repeat: no-repeat;
    z-index:${Zindex.zindexOverlaystackOrder1};
  }

  @media ${ device.tablet } {
    padding:0;
    min-height:${ 300/16 }rem;
  }

  @media ${ device.mobile } {
    min-height:${ 80/16 }rem;
    padding-bottom:${ 400/767*100 }%
  }
`

const SparkContent = styled.div`
  box-sizing:border-box;
  height:100%;
  padding:0 ${30/16}rem;

  @media ${device.tablet} {
    height:auto;
  }

  @media ${ device.mobile } {
    padding-bottom:0;
    margin:0 ${ 15 / 320 * 100 }%;
    padding:0;
  }
`

const ContentPadding = styled.div`
  position:relative;
  box-sizing:border-box;
  height:100%;
`

const SparkTile = styled.div`
  display:flex;
  flex-direction:column;
  align-items:stretch;
  position:relative;
  overflow:hidden;
  border:1px solid #cccccc;
  border-radius: 5px;
  height:100%;

  h4 {
    margin:0;
  }

  @media ${device.tablet} {
    flex-direction:row;
    height:auto;

    > div {
      flex:1 0 50%;
    }
  }

  @media ${device.mobile} {
    flex-direction:column;
  }
`

const SparkCopy = styled.div`
  padding:${25/16}rem 0 ${115/16}rem;

  @media ${device.mobile} {
    padding:${25/16}rem 0 0;
  }
`

const ProgressContainer = styled.div`
  position:absolute;
  width:100%;
  bottom:${30/16}rem;

  .fund-goal {
    font-weight:500;
  }

  .progress-section {
    margin:${10/16}rem 0 0;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
  }

  @media ${ device.mobile } {
    position:static;
    margin:${ 45/16 }rem 0 ${ 30/16 }rem;
  }

`

const ProgressBar = styled.span`
  position:relative;
  display:block;
  height:${14/16}rem;

  &:after {
    content:'';
    display:block;
    width:100%;
    height:100%;
    box-sizing:border-box;
    border-radius:0.25rem;
    background:#dddddd;
    z-index:1;
  }

  i {
    box-sizing:border-box;
    display:block;
    width:${ props => props.width }%;
    position:absolute;
    z-index:10;
    height:100%;
    background:#00FF87;
    border-radius:0.25rem;
  }
`

const ProjectGoal = styled.div`
  position:absolute;
  bottom:0;
  width:100%;
  z-index:11;
  color:${ colors.secondaryDarkBlue }

  .time-remaining, .near-completion, .funding-complete {
    display:inline-block;
    padding:0.6rem ${ 30/16 }rem;
    line-height:${24/16}rem;
    font-size:${11/16}rem;
    font-weight:600;
    text-transform:uppercase;
    letter-spacing:1px;
    white-space:nowrap;
    background:${ colors.primaryYellow }

    @media ${ device.mobile } {
      padding:0.6rem ${ 15 / 320 * 100 }%;
    }

    span, i {
      display:inline-block;
      vertical-align:middle;
    }

    i {
      position:relative;
      width:1rem;
      margin:0 ${6/16}rem 0 0;
      background-repeat:no-repeat;
      background-size:contain;

      &.icon-fire {
        height:1.2rem;
        top:${-2/16}rem;
        background-image:url(${ iconFire });
      }
      &.icon-clock {
        height:1rem;
        background-image:url(${ iconClock });
      }
      &.icon-goal {
        height:${24/16}rem;
        width:1rem;
        background-image:url(${ iconGoal });
      }
    }
  }
`

const FundingComplete = () => (
  <span className="funding-complete"><i className="icon-goal"></i><span>Project Funded!</span></span>
)

const NearGoal = () => (
  <span className="near-completion"><i className="icon-fire"></i><span>Almost at Goal!</span></span>
)

const DaysRemaining = props => (
  <span className="time-remaining"><i className="icon-clock"></i><span>{ props.days} days left</span></span>
)

const ParticipationGoal = props => (
  <span className="progress-section">
    <span className="percent-raised">{ props.totalDonors } donors</span>
    <span className="fund-goal">{ props.goalAmount } donor goal</span>
  </span>
)

const MonitaryGoal = props => (
  <span className="progress-section">
    <span className="percent-raised">{ Math.round(100 * props.percentRaised) }% raised</span>
    <span className="fund-goal">${ Math.round(100 * props.goalAmount)/100 } goal</span>
  </span>
)

// tries twice then returns undefined if both calls fail
const getSparkFeed = async (isRetry) => {
  let feed = sessionStorage.getItem('sparkFeed');
  if (feed && feed.success) {
    return JSON.parse(feed);
  }
  else {
    feed = await doApi({route:'sparkfeed', noAuth:true});
    if (feed && feed.success) {
      sessionStorage.setItem('sparkFeed', JSON.stringify(feed.success));
      return feed.success;
    }
    else {
      if (!isRetry) {
        console.error('Spark Feed failed, retrying...');
        return await getSparkFeed(true);
      }
      else {
        console.error('Spark Feed failed second try');
        return undefined;
      }
    }
  }
};


class SparkFeed extends React.Component {
  state = {
    feed: [],
  }

  async componentDidMount() {
    this._isMounted = true // TODO - apparently this is an anti-pattern, will revisit when we convert to hooks

    const feed = await getSparkFeed()

    if (this._isMounted && feed && feed.length) {
      this.setState({ feed: feed.slice(0,3) })
    }
  }

  async componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { feed } = this.state
    return feed && feed.length? (
      <div className="wrapper">
        <PromoProjectsContainer className="grid">
          <SparkHeader className="span-12 t-span-12 h3 center">Personal Projects. Immediate Impact.</SparkHeader>
          <SparkSubheader className="span-12 t-span-12 h6 normal has-no-margins center">Directly support UCLA students, faculty and staff with the resources they need to bring their passion projects to life.</SparkSubheader>

          { feed.map( (item, index) => {
            const goal = (item.percentRaised >= 1)? <FundingComplete />
              : (item.percentRaised >= 0.85)? <NearGoal />
              : <DaysRemaining days={ Math.floor((initializeDate(item.endDate) - initializeDate()) / 1000 / 60 / 60 / 24) } />

            return (
              <SparkFeedDetails className="span-4 t-span-12" key={ index } >
                <SparkTile
                 as={"a"}
                 href={ item.link }
                 target="_blank"
                 rel={ "noreferrer nofollow noopener" }>
                  <SparkImage icon={item.tileImage}>
                    <ProjectGoal { ...item }>
                    { goal }
                    </ProjectGoal>
                  </SparkImage>
                  <SparkContent>
                    <ContentPadding>
                      <SparkCopy>
                        <h4 className="bold">{ item.title }</h4>
                        <p>{ item.summary }</p>
                      </SparkCopy>
                      <ProgressContainer>
                        <ProgressBar width={ Math.round(1000 * item.percentRaised) / 10 }><i /></ProgressBar>
                        { item.goalType === 'participation'? <ParticipationGoal {...item} /> : <MonitaryGoal {...item} /> }
                      </ProgressContainer>
                    </ContentPadding>
                  </SparkContent>
                </SparkTile>
              </SparkFeedDetails>
            )
          })}
        </PromoProjectsContainer>
      </div>
    ) : ( <div /> )
  }
}

export default SparkFeed
