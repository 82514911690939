import styled from 'styled-components'

import * as colors from 'css/Colors'

const defaultFontSize = 16

const UserIcon=styled.div`

background: ${colors.primaryBlue};
border-radius: 50%;
width: ${ 115/defaultFontSize }rem;
height: ${ 115/defaultFontSize }rem;

text-align: center;

h1{
  color: #fff;
  line-height: ${ 115/defaultFontSize }rem;
  margin: 0;
}
`

export default UserIcon;