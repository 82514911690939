export const setAuctionUser = (auctionUser) => ({
  type: "SET_AUCTION_USER",
  payload: auctionUser
})

export const setBidSubscriptionResponse = (bidSubscriptionResponse) => ({
  type: "SET_BID_SUBSCRIPTION_RESPONSE",
  payload: bidSubscriptionResponse
})

export const setLiveAuctionCode = (auctionCode) => ({
  type: "SET_LIVE_AUCTION",
  payload: auctionCode
})


