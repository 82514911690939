import { SET_LOGGED_IN_USER, SET_CRM_USER, REDIRECT_AFTER_LOGIN, SET_THEME, SET_IS_LOADING } from "./actionTypes";

export const setLoggedInUser = (user) => ({
  type: SET_LOGGED_IN_USER,
  payload: {user}
});

export const setCrmUser = (crmUser) => ({
  type: SET_CRM_USER,
  payload: {crmUser}
});

export const redirectAfterLogin = (redirectTo) => ({
  type: REDIRECT_AFTER_LOGIN,
  payload: {redirectTo}
});

export const setTheme = (theme) => ({
  type: SET_THEME,
  payload: {theme}
});

export const setIsLoading = (isLoading, srcTracker = "N/A") => ({
  type: SET_IS_LOADING,
  payload: {isLoading, srcTracker}
});

