import { Component } from 'react';
import { withRouter } from "react-router-dom";

import { getCognitoUsers, getCrmUserAdmin, linkCrmUser } from "admin/adminapi";
import { adminErrorHandler } from 'admin/adminutil'
import { showLoading } from 'services/util'

import styled from 'styled-components'
import LinkUserWrapper from 'admin/linkuser/LinkUserWrapper'
import UserProfile from 'admin/linkuser/UserProfile'
import CRMProfile from 'admin/linkuser/CRMProfile'
import LinkUserForm from 'admin/linkuser/LinkUserForm'
import ConfirmModal from 'common/ConfirmModal'
import Alert from 'common/notifications/Alert'
import BlackbaudLink from 'admin/BlackbaudLink'
import UserProfileInfopopup from 'admin/linkuser/UserProfileInfopopup'
import * as colors from 'css/Colors';
import { FilledBlue } from 'css/Buttons';
import LinkImage from 'images/link-image.svg'
import iconInfo from 'images/iconColorinfo.svg'

const defaultFontSize = 16

const UserCRMInformation = styled.div`
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  background-color: ${ props => props.crm ? `${colors.secondaryMediumBlue}` : `${colors.primaryBlue}`};
  min-height: ${315/defaultFontSize}rem;
  h6 {
    color :#fff;
    margin: 0 0 ${18/defaultFontSize}rem;
  }
  .crm-user-information {
    background-color: #FFFFC3;
  }
  .smaller-alert > div > div {
    padding: 0 calc(10rem/16);
  }
  .user-search-form {
    margin: 0;
  }
  .mismatch-error {
    color:#000000;

  }
  .link-user-info-block-title {
    font-size: ${16/defaultFontSize}rem;
  }
  .account-display {
    font-size: ${14/defaultFontSize}rem;
  }
`

class LookupCrmPanel extends Component {
  state = {
    crmLookup: '',
    crmLookupResponse: '',
    crmFoundConstituent: false,
  };

  specialHandling = this.props.specialHandling || {};

  handleCrmSubmit = async e => {
    e.preventDefault()

    showLoading(true)

    const response = await getCrmUserAdmin(this.state.crmLookup);

    const crmState = {
      crmFoundConstituent: !!("crmUser" in response),
      crmLookupResponse: response
    }

    this.setState(crmState);

    this.props.handleCrmChange(crmState)

    showLoading(false)
  }

  handleCrmChange = e => {

    const crmState = {
      crmLookup: e.target.value.trim(),
      crmLookupResponse:'',
      crmFoundConstituent: false
    }

    this.setState(crmState);

    this.props.handleCrmChange(crmState)
  }

  render() {
    return (
      <>
        {/* CRM Information display */}
        <div className="span-5">
          <UserCRMInformation crm>
            <LinkUserForm
              title={"CRM Information"}
              inputName={"revId"}
              btnName={"crmSearch"}
              inputTitle={"Enter CRM ID"}
              formType="crm"
              onSubmit={this.handleCrmSubmit}
              onChange={this.handleCrmChange}
            >
            <BlackbaudLink />
            </LinkUserForm>
          {/* <pre>{this.state.crmLookupResponse}</pre> */}

          {this.state.crmLookupResponse ?(!this.state.crmLookupResponse.error ?
          <CRMProfile
            foundCRMUser={this.state.crmLookupResponse.crmUser} specialHandling={this.specialHandling} /> :
              <Alert
              className="smaller-alert"
              padded
              nonPromoPage
              icon={iconInfo}
              color={colors.messagingRed}
              width="25" height="25" top="35%" >
              <div className="mismatch-error">{adminErrorHandler(this.state.crmLookupResponse.error, `No matching user with CRM ID "${this.state.crmLookup}". Please check the number and try again.`)}</div>
            </Alert>):""}
          </UserCRMInformation>
        </div>
      </>
    )
  }
}

export default withRouter(LookupCrmPanel);
