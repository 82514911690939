import { Component } from 'react';
import styled from 'styled-components';

import * as colors from 'css/Colors'
import { device } from 'common/Device'
import PlainArrowDown from 'images/ArrowDownPlain.svg'
import PlainArrowUp from 'images/PlainArrowUp.svg'

import { faqs } from './FAQData'

const defaultFontSize=16;
const FAQContent = styled.div`
padding:${(30/defaultFontSize)}rem 0 ${ 45/defaultFontSize }rem;

@media ${ device.tablet } {
  padding-top:${ 20/defaultFontSize }rem;
}
`

const ListHeader = styled.ul`
list-style-type: none;
padding:0;
margin:0;
.radient-list-title{
  color:${colors.primaryBlue};
   margin:${ 10/defaultFontSize }rem ${ 30/defaultFontSize }rem;
  }
.clickedTrue{
  background:linear-gradient(225.44deg, ${colors.primaryBlue} 0%, ${colors.secondaryDarkBlue} 100%);
  h6,img{
    color:#FFFFFF;
  }
}
.clickedFalse{
  background-color: #F5F5F5;
}
.faq-description{
  padding:${ 20/defaultFontSize }rem;
 color:#000000;
 }

 @media ${ device.tablet } {
   .radient-list-title {
     margin:${ 10/defaultFontSize }rem 0;
   }
 }

 @media ${ device.mobile } {
   .faq-description{
     padding-left:${ 15/320 * 100}%;
     padding-right:${ 15/320 * 100}%;
   }
 }
`
const OrderItems = styled.div`
  height:${ 50/defaultFontSize }rem;

  cursor:pointer;

  & > div{
    display:flex;
    padding: 0 ${20/defaultFontSize}rem;
    justify-content: space-between;
    align-items:center;
    height:100%;
  }
  img{
    /* especially for IE11*/
    width: ${19/defaultFontSize}rem;
    height: ${25/defaultFontSize}rem;;
  }

  @media ${ device.tablet } {
    height:${ 40/defaultFontSize }rem;
  }

  @media ${ device.mobile } {
    & > div {
      padding-left:${ 15/320 * 100}%;
      padding-right:${ 15/320 * 100}%;
    }

  }
`

class FAQList extends Component {

  doHashChange = () => {
    if (window.location.hash)
      this.setState({ fragment: window.location.hash.substring(1) })
  }

  componentDidMount() {
    window.addEventListener("hashchange", this.doHashChange, false);
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.doHashChange, false);
  }

  state = {
    fragment: window.location.hash.substring(1)
  }

  removeHash = () => {
    window.history.pushState("", document.title, window.location.pathname
                                                       + window.location.search);
  }

  showfaqInfo = (fragment) => {
    this.setState({ fragment })
    this.removeHash()
  }

  render() {
    return (
      <FAQContent >

        <ListHeader id="faqlist" >

          {faqs.map((faq, index) => (
            <li key={index} className="radient-list-title">
              <OrderItems className={(this.state.fragment === faq.f) ? "clickedTrue" : "clickedFalse"}
                onClick={() => this.showfaqInfo(faq.f)}  >
                <div>
                  <h6 className="bold has-no-margins">{faq.q} {this.state.fragment === faq.f ? <a href={"https://mygiving.ucla.edu/faq#"+faq.f}>(link)</a> : ''}</h6>
                  {(this.state.fragment === faq.f) ? <img src={PlainArrowUp} alt='ArrowUp' /> :
                    <img src={PlainArrowDown} alt='ArrrowDown' />}
                </div>
              </OrderItems>
              {(this.state.fragment === faq.f) &&
                <div className="faq-description">{faq.a}</div>}

            </li>
          ))}
        </ListHeader>
      </FAQContent>
    )
  }
}
export default FAQList;
