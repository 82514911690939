import { Component } from 'react';

import * as cs from 'css/CommonStyle';
import * as forms from 'css/forms';
import { FilledYellow } from 'css/Buttons'
import DropDownMenu from 'common/forms/DropDown'
import EmailPhoneToggle from 'authentication/components/EmailPhoneToggle'

import { InputWrap, ButtonWrap, OperatorWrapper } from 'admin/QueryStyles'

const searchTypes = [
  {displayValue: "Last 60 Days", key: "Last60Days"},
  {displayValue: "CRM ID", key: "CrmId"},
  {displayValue: "Username", key: "CognitoUsername"},
  {displayValue: "Action", key: "Action", isDropdown: true},
  {displayValue: "Admin User Email", key: "AdminUsername"},
  {displayValue: "Show All", key: "ShowAll"},
]

const ActionValues = [
  "LINK_USER",
  "RELINK_USER",
  "UNLINK_USER",
  "DELETE_USER",
  "SET_UCLA1ID",
  "CHANGE_UCLA1ID",
  "REMOVE_UCLA1ID",
]

class SharedQueryForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchType: "Last60Days",
      searchDisplay: "Last 60 Days",
      actionType: "LINK_USER",
      ActionValues,
    }
  }

  componentDidMount() {
  }

  handleSearchTypeChange = e => {
    const searchType = e.target.value.key;

    this.setState({
      searchType,
      searchDisplay: e.target.value.displayValue,
      searchFieldName: searchType + 'InputField', // give fields different names so browsers will remember the right things
      searchValue: (searchTypes.filter(t => t.key === searchType)[0].isDropdown) ? this.state[searchType+'Values'][0] : '',
    });
  }

  handleActionTypeChange = e => {
    this.setState({
      actionType: e.target.value,
      searchValue: e.target.value,
    });
  }

  handleSearchFieldChange = e => {
    this.setState({
      searchValue: '' + e.target.value,
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const scrubbedValue = this.state.searchValue && this.state.searchType === 'CognitoUsername' && this.state.searchValue.charAt(0) === '+'
        ? '+' + this.state.searchValue.replace(/\D/g,'')
        : this.state.searchValue;

    await this.setState({searchValue:scrubbedValue})

    this.props.onSubmit(this.state);
  }

  render() {
    return (
      <div>
        {/*<h6 className="bold">User Management Audit</h6>*/}
        <form onSubmit={this.handleSubmit}>
          <cs.TextInputdiv className="user-search-form" >
            <InputWrap>
              <label className="link-donor-info-block-title">{"Search By..."}</label>
              <DropDownMenu
                className="input-wrap-dropdown"
                name="auditTrailSearch"
                title={this.state.searchDisplay}
                list={searchTypes}
                changeHandler={this.handleSearchTypeChange}
              />
            </InputWrap>

           {['ShowAll', 'Last60Days'].indexOf(this.state.searchType) === -1 &&
              <OperatorWrapper>=</OperatorWrapper>}

            {['Action','CognitoUsername', 'ShowAll', 'Last60Days'].indexOf(this.state.searchType) === -1 &&
              <InputWrap key={this.state.searchType}>
                <label className="link-donor-info-block-title">&nbsp;</label>
                  <forms.ClearableInput
                  width="100%"
                  type="text"
                  name={this.state.searchFieldName}
                  onChange={this.handleSearchFieldChange} />
              </InputWrap>}

            {this.state.searchType === 'CognitoUsername' &&
              <InputWrap key={this.state.searchType}>
                <label className="link-donor-info-block-title">&nbsp;</label>
                  <EmailPhoneToggle onChange={this.handleSearchFieldChange}/>
              </InputWrap>}

            {this.state.searchType === 'Action' &&
              <InputWrap key={this.state.searchType}>
                <label className="link-donor-info-block-title">&nbsp;</label>
                <DropDownMenu
                  className="input-wrap-dropdown"
                  name="ActionList"
                  title={this.state.actionType}
                  list={ActionValues}
                  changeHandler={this.handleActionTypeChange}
                />
              </InputWrap>}

            <ButtonWrap>
              <br/>
              <FilledYellow
                width="7.0625rem"
                bgColor={"#FFD100"}
                fontColor={"#005587"}
                borderColor={"#FFD100"}
                name={"filterAuditResults"}
              >
                <span> Search</span>
              </FilledYellow>
            </ButtonWrap>
          </cs.TextInputdiv>
        </form>
      </div>
    )
  }
}

export default SharedQueryForm;