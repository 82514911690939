import { Route } from 'react-router-dom';

import Menu from './components/Menu'
import Login from 'authentication/routes/Login';
import cidmHeader from './sass/navigation.module.scss'

// IMPORTANT: right now this is dedicated to uclafoundation.
// If we want to reuse it for something else, some changes will need to be made
// we can use ssoRedirectUrl to determine which app

const redirectTest = (ssoRedirectUrl) => {
  return ( ssoRedirectUrl.indexOf('https://uclafoundation.org/') === 0
        || ssoRedirectUrl.indexOf('https://dev.uclafoundation.org/') === 0
        || ssoRedirectUrl.indexOf('https://stage.uclafoundation.org/') === 0
        || ssoRedirectUrl.indexOf('https://pilot.uclafoundation.org/') === 0
        || ssoRedirectUrl.indexOf('https://preprod.uclafoundation.org/') === 0
        || ssoRedirectUrl.indexOf('http://local.uclafoundation.org') === 0
        || (ssoRedirectUrl.indexOf('https://localhost:7039') === 0 && process.env.REACT_APP_AWS_ENVIRONMENT.indexOf('prod') === -1)
  )
}

const cognitoClientIds = {
  "dev": "384d9lths2onj01k9l51b0rs8g",
  "stage": "3u2eganevicr5924qv28ssuhjv",
  "pilot": "7gk9ta8do4fg8arno0ph9h8esj",
  "preprod": "64u83u3mmh0h70e215v2r4lmd0",
  "prod": "pnhjffcdntu0rug6i7a0k4ppl",
}

const hideFooter = () => {
  return true
}

const hideHeader = () => {
  return true
}

const returnToUclaFoundationLink = process.env.REACT_APP_UCLA_FOUNDATION_SITE_LINK || "https://uclafoundation.org/"
const themeHeader = (<div className={cidmHeader.container}>
                      <a className={cidmHeader['return-link']} href={returnToUclaFoundationLink}>Return to UCLA Foundation</a>
                    </div>)

const REGISTRATION_TEXT = "To register, use an email or cell phone number associated with your UCLA account."
const registrationText = (
  <>
    <span className="block-title bold">
      {REGISTRATION_TEXT}
    </span>
  </>)

const routes = [
  <Route exact={true} path='/' component={Login} key="/cidmhome" />,
]

// cidm inherits from mygiving
export const cidm = {
  name: 'cidm',
  appName: "UCLA Sign On Portal",
  apiGatewayHost: process.env.REACT_APP_IDM_API_GATEWAY_HOST,
  routes,
  themeMenu: Menu,
  themeHeader,
  windowTitle: 'UCLA Sign On Portal',
  signinText: "Please enter the email or cell phone number associated with your UCLA account.",
  registrationTitle: "UCLA Sign On Portal Registration",
  registrationText,
  hideHeader: false,
  hideFooter,
  redirectTest,
  extraGetUserParam: '?app=uf', // TODO - get this from ssoRedirectUrl when we have multiple apps
  supportTeamName: "UCLA Foundation",
  supportEmail: "foundation@support.ucla.edu",
  supportPhone: "(310) 794-3193",
  cognitoClientId: cognitoClientIds[process.env.REACT_APP_AWS_ENVIRONMENT || "dev"],
  setSsoCookieForCrmUser: true,
  setSsoCookieForNonCrmUser: false,
  forceLogoutOnRedirect: true,
  addTokenToUrlForLocalhost: true,
  addTokenToUrlInDev: true,
  addTokenToUrlInProd: true, // cookie doesn't work for uclafoundation.org
}

