import { SET_THEME } from "../actionTypes";

const initialState = {
  theme: {},
};

const theme = (state = initialState, action) => {
  if(action.type === SET_THEME) {
    const { theme } = action.payload;

    document.title = theme.windowTitle

    return theme;
  }
  return state;
}


export default theme;
