import React from 'react'
import styled from 'styled-components'

import { initializeDate } from 'services/util'

import { primaryBlue, messagingRed } from 'css/Colors'
import { formatDate } from '../mygivingutil'

const defaultFontSize = 16

const StyledInput = styled.input`
  &[type="text"] {
    appearance: none;
  }

  width:${ 102/232 * 100 }%;
  min-width:${ 100/defaultFontSize }rem;
  box-sizing:border-box;
  border:1px solid ${ props => props.isValid? "#FFFFFF" : messagingRed };
  padding:${4/defaultFontSize}rem ${6/defaultFontSize}rem;
  color:${ props => props.isValid? primaryBlue : messagingRed };
  font-size:1rem;
  font-weight:600;
  line-height:1;

  &:focus {
    outline:none;
  }

  @media screen and (max-width:1180px) {
    width:${ 240/(1180/3) * 100 }%;
  }
`

class DateTextEntry extends React.Component {
  // local state tracks user entry
  state = {
    textValue: "",
    changing: false,
  }

  regexp = /^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{4})$/

  validateDate( entry ) {
    if (entry && (entry.length <= 10 && entry.length >= 8)) {
      const matches = entry.match( this.regexp )
      if (matches) {
        const isValidated = initializeDate( `${matches[3]}-${matches[1]}-${matches[2]}` )

        return !isNaN(isValidated.valueOf())
      }
    }

    return false
  }

  normalizeDate( entry ) {
    const matches = entry.match( this.regexp )
    return `${ matches[3] }-${ matches[1] }-${ matches[2] }`
  }

  onFocus = (e) => {
    this.setState({ textValue: "" , changing: true })
  }

  onBlur = (e) => {
    const { textValue } = this.state
    // validate user entry
    if ( this.validateDate( textValue ) ) {
      // valid date
      this.props.onChange( this.normalizeDate(textValue) )

    } else if (textValue === "" ) {
      // just tabbing through, reset value to what's coming from props
      this.setState({ textValue: this.props.value })
    }

    this.setState({ changing: false })
  }

  onTextEntry = (e) => {
    this.setState({
      textValue: e.currentTarget.value,
      changing: true
    })
  }

  render() {
    const renderedValue = this.state.changing? this.state.textValue : formatDate(this.props.value)
    return (
      <StyledInput
        type="text"
        name={ this.props.htmlName }
        value={ renderedValue }
        placeholder={ this.props.defaultFromDate }
        onChange={ this.onTextEntry }
        onFocus={ this.onFocus }
        onBlur={ this.onBlur }
        isValid={ this.validateDate( renderedValue ) }
      />
    )
  }
}

export default DateTextEntry
