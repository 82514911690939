import { useState, useEffect } from 'react'
import { getAuctionUser } from '../redux/selectors'
import { useSelector } from 'react-redux'

// import { history } from 'services/history.js'

import css from './sass/mypages.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import AuctionCardUpcoming from './AuctionCardUpcoming'
import AuctionRowPast from './AuctionRowPast'

export default function MyAuctions() {

  const [myAuctions, setMyAuctions] = useState([])
  const [pastAuctions, setPastAuctions] = useState([])
  const [upcomingAuctions, setUpcomingAuctions] = useState([])
  const [liveAuctions, setLiveAuctions] = useState([])
  const [noAuctions, setNoAuctions] = useState(false)


  const auctionUser = useSelector(getAuctionUser)

  useEffect(() => {
    if (auctionUser) {
      setMyAuctions(auctionUser.MyAuctions.map((auction) => auction.Auction))
    }
  }, [auctionUser])

  useEffect(() => {
    const today = new Date()

    const past = []
    const upcoming = []
    const live = []

    myAuctions.forEach((auction) => {
      const isLive = auction.StartTime < today.toISOString()

      //if auction is public
      if (auction.Status === 'PUBLIC') {
        if (today.toISOString() > auction.EndTime) {
          past.push(auction)
        } else {
          //if auction is not ended
          if (isLive) live.push(auction)
          else upcoming.push(auction)
        }
      }
    })

    setPastAuctions(past)
    setUpcomingAuctions(upcoming)
    setLiveAuctions(live)

    if (past.length === 0 && upcoming.length === 0 && live.length === 0) setNoAuctions(true)
    else setNoAuctions(false)

  }, [myAuctions])

  if (!auctionUser) return <></>
  
  return (
    <>
{/*      <div className={css['mypages-header']}>
        <h2 className={css['mypages-headings']}>My Auctions</h2>
      </div>
*/}      <div className={themeCss['blue-background']}>

        {liveAuctions.length !== 0 && liveAuctions.map((auction) => {
          return (<AuctionCardUpcoming key={auction.AuctionId} auction={auction} isLive={true}/>)
        })}

        {upcomingAuctions.length !== 0 && <>
            <h3 className={css['mypages-headings-white']}>Upcoming Auctions</h3>
            {upcomingAuctions.map((auction) => {
              return (<AuctionCardUpcoming key={auction.AuctionId} auction={auction} />)
            })}
          </>
        }

        {pastAuctions.length !== 0 && <>
            <h3 className={css['mypages-headings-white']}>My Past Auctions</h3>
            <div className={css['mypages-card']}>
              {pastAuctions.map((auction) => {
                return (<AuctionRowPast key={auction.AuctionId} auction={auction} />)
              })}
            </div>
          </>
        }

        {noAuctions && <div className={css['mypages-card']}>
            <p>
              You are not currently signed up for any auctions. If you believe this in incorrect, please contact the auction administrator.
            </p>
          </div>
        }
      </div>
    
    </>
  )
}