import EnterPayment from './EnterPayment'

export default function PaymentUpdate() {

  const header = 'Update Payment Method'
  const message = 'Please add a payment method to participate in this auction. By placing a bid, you agree to pay the full amount if you win that item.'
  const buttonText = 'Update Payment Card'

  return (
    <>
      <EnterPayment header={header} message={message} buttonText={buttonText} />
    </>
  )
}