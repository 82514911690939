import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'

import { getUserFromDb } from 'services/api';
import { showLoading } from 'services/util';
import { getTypes, saveProfile } from './profileapi';
import store from 'redux/store';
import { setLoggedInUser } from 'redux/actions';
import { getCognitoUser } from 'redux/selectors';

import MyInformationPersonal from 'profile/personal/MyInformationPersonal';
import MyInformationProfessional from 'profile/professional/MyInformationProfessional';
import MyInformationContact from 'profile/contact/MyInformationContact';
import ConfirmNavigationPrompt from 'profile/AlterChangesPrompt'
import LeaveConfirmation from 'common/ConfirmModal'
import Alert from 'common/notifications/Alert'

import * as mi from './MyInformationStyles';
import * as colors from 'css/Colors'
import * as cs from 'css/CommonStyle';
import { FilledBlue, StrokeBlue } from 'css/Buttons';

import MyInformationHeader from 'images/MyInformationHeader.png';
import AlertIcon from 'images/Alerticonmodal.svg'
import success from 'images/TickMark.svg'
import iconInfo from 'images/icon-info-red.svg'

class MyInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClean: true,
      isValidate: false,
      userData: null,
      bioTypes: null,
      sampleData: null,
      isSubmitSuccessful: false,
      submitError: false,
      showPersonal: true,
      showProfessional: false,
      showContact: false,
      isCrmUser: true,
    }
    this.updateValidation = this.updateValidation.bind(this);
    this.paddingRef = React.createRef()
  }

  async componentDidMount() {
    if (this.props.authenticatedUser && this.props.authenticatedUser.crmUser) {

      showLoading(true)
      const bioTypes = await getTypes();
      showLoading(false)

      const userData = this.props.authenticatedUser.crmUser.profile;

      // spouse name can still be populated if user isn't married, but we don't want to show it
      if (userData.maritalStatus === "Married" && userData.spouseName)
        userData.spouseReadOnly = true;
      else
        userData.spouseName = "";

      // make copy of original data for use when resetting form
      const originalUserData = JSON.parse(JSON.stringify(userData));
      this.setState({ userData, originalUserData, bioTypes, isCrmUser: true });
    }
    else {
      this.setState({ isCrmUser: false })
    }
  }

  showPersonalProfile = () => {
    this.setState({ showPersonal: true, showProfessional: false, showContact: false, })
  }
  showProfesssionalProfile = () => {
    this.setState({ showPersonal: false, showProfessional: true, showContact: false })
  }
  showContactProfile = () => {
    this.setState({ showPersonal: false, showProfessional: false, showContact: true })
  }

  handleSubmit = async (event) => {
    if (!this.state.isClean) {

      showLoading(true)

      const theme = store.getState().theme

      theme.themeAnalytics.analyticsUpdateMyInfo()

      const submitResponse = await saveProfile(this.state.userData);

      // get profile because the batch does some magic things like capitalizing
      const crmUser = (await getUserFromDb()).success;

      // TODO - might be a better way - but for now we need to grab the cognitoUser first to save back to loggedInUser redux state
      // we don't want to split cognitoUser and crmUser because it causes problems with state updates when cognitoUser is set but crmUser hasn't been determined yet
      const cognitoUser = getCognitoUser(store.getState())
      store.dispatch(setLoggedInUser({cognitoUser, crmUser}))
      const newProfile = crmUser.profile

      // spouse name can still be populated if user isn't married, but we don't want to show it
      if (newProfile.maritalStatus === "Married" && !!newProfile.spouseName)
        newProfile.spouseReadOnly = true;
      else
        newProfile.spouseName = "";

      if (submitResponse.error || submitResponse.errorMessage) {
        const errorMsg = submitResponse.errorMessage || submitResponse.error.msg;
        await this.setState({ userData: newProfile, originalUserData: JSON.parse(JSON.stringify(newProfile)), isSubmitSuccessful: false, submitError: errorMsg, isClean: true });
      }
      else {
        // reset should restore the saved data, not the original date loaded
        await this.setState({ userData: newProfile, originalUserData: JSON.parse(JSON.stringify(newProfile)), isSubmitSuccessful: true, submitError: null, isClean: true });

        if (theme.closeWindowOnProfileSave) window.close()
      }

      showLoading(false)
    }
  }

  handleReset = async (event) => {
    await this.setState({
      userData: JSON.parse(JSON.stringify(this.state.originalUserData)),
      isClean: true
    });
  }

  closeWindow = async (event) => {
    window.close()
  }

  changeHandler = event => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    const userData = this.state.userData;

    if (fieldName.indexOf('.') > -1) {
      const fieldNames = fieldName.split('.');
      if (userData[fieldNames[0]][fieldNames[1]] !== fieldValue) {
        userData[fieldNames[0]][fieldNames[1]] = fieldValue;
        this.setState({ isClean: false });
      }
    }
    else if (userData[fieldName] !== fieldValue) {
      userData[fieldName] = fieldValue;
      this.setState({ isClean: false });
    }
    this.setState({ userData });
  }

  updateValidation(errorValidity) {
    if (errorValidity) {
      this.setState({ isValidate: true });
    }
    else {
      this.setState({ isValidate: false });
    }
  }

  render() {
    const initials = (this.state.userData && this.state.userData.primaryName) ? this.state.userData.primaryName.firstName.substr(0, 1) + this.state.userData.primaryName.keyName.substr(0, 1) : "ME";
    return (
      <React.Fragment>
        <ConfirmNavigationPrompt when={!this.state.isClean}>
         {({ isActive, onConfirm, onCancel }) => (
          <LeaveConfirmation
           isActive={isActive}
           onConfirm={onConfirm}
           onCancel={onCancel}
           cancelText={"Stay on this page"}
           confirmText={"Leave This Page"}
          >
            <div>
              <img src={AlertIcon} alt='Alert icon' className="alert-icon"/>
            </div>
            <div>
              <p className="bold" >You haven’t saved your changes</p>
              <p> Are you sure you want to leave this page?</p>
            </div>
          </LeaveConfirmation>
         )}
        </ConfirmNavigationPrompt>
        <mi.Background className={!this.state.isCrmUser?"is-non-crm-user-placeholder" :"background-grey"}>
          <mi.HeaderBackground headerImage={MyInformationHeader}>
           <cs.HeaderTitle className="h1 center has-no-margins" id="landingPageTitle">My Information</cs.HeaderTitle>

            <div >
             <mi.UserImage>
              <mi.UserInitialsImg>{initials}</mi.UserInitialsImg>
             </mi.UserImage>

             {this.state.isCrmUser ?
              <mi.OuterContainer>
              <mi.MyInformationContent ref={ this.paddingRef }>
               {(this.state.isClean && this.state.isSubmitSuccessful) &&
                <div className="save-changes-alert">
                 <Alert
                  nonPromoPage
                  icon={success}
                  color={colors.guideGreen}
                  width="20" height="15" top="40%" >
                  <p>Thank you for updating your information. In order to ensure that our data is as accurate as possible,
                    we will review these changes before committing them to our system.
                    If you do not see your changes reflected within the next few days,
                    please contact {this.context.supportTeamName} at {this.context.supportPhone || this.context.supportEmail} and we will be happy to assist you. </p>
                 </Alert>
                </div>}

               {(this.state.isClean && this.state.submitError) &&
                <div className="save-changes-alert">
                 <Alert
                  nonPromoPage
                  icon={iconInfo}
                  color={colors.messagingRed}
                  width="25" height="25" top="35%" >
                  <p>{this.state.submitError}</p>
                 </Alert>
                </div>}

               <mi.InformationListsHeader>
                <h6 className={`bold has-no-margins ${(this.state.showPersonal ? 'show' : '')}`} onClick={this.showPersonalProfile}> PERSONAL</h6>
                <h6 className={`bold has-no-margins ${(this.state.showProfessional ? 'show' : '')}`} onClick={this.showProfesssionalProfile}> PROFESSIONAL </h6>
                <h6 className={`bold has-no-margins ${(this.state.showContact ? 'show' : '')}`} onClick={this.showContactProfile}> CONTACT</h6>
               </mi.InformationListsHeader>
               <div>
                <span className="required-note">*Required Fields</span>
               </div>

               <mi.MyProfileData >
                {this.state.userData && this.state.showPersonal &&
                 <MyInformationPersonal
                  isClean={this.state.isClean}
                  changeHandler={this.changeHandler}
                  updateValidation={this.updateValidation}
                  data={this.state.userData}
                  bioType={this.state.bioTypes} />
                }
                {this.state.userData && this.state.showProfessional &&
                 <MyInformationProfessional
                  isClean={this.state.isClean}
                  changeHandler={this.changeHandler}
                  updateValidation={this.updateValidation}
                  data={this.state.userData}
                  bioType={this.state.bioTypes} />
                }
                {this.state.userData && this.state.showContact &&
                 <MyInformationContact
                  isClean={this.state.isClean}
                  changeHandler={this.changeHandler}
                  updateValidation={this.updateValidation}
                  data={this.state.userData}
                  bioType={this.state.bioTypes}
                  gutterRef={ this.paddingRef }
                />
                }
               </mi.MyProfileData>

              </mi.MyInformationContent>

              <mi.SubmitButtons>

               <FilledBlue disabled={this.state.isClean || this.state.isValidate} onClick={this.handleSubmit} ><span id="myInformationSaveButton"> SAVE</span></FilledBlue>
               {!this.state.isClean && <StrokeBlue onClick={this.handleReset}><span> RESET</span></StrokeBlue>}
               {store.getState().theme.closeWindowOnProfileSave && <StrokeBlue onClick={this.closeWindow}><span> CANCEL</span></StrokeBlue>}
              </mi.SubmitButtons>

             </mi.OuterContainer>: <div className="placeholder-text">
             <h2 className="has-no-margins">Profile is not Available</h2>
             <p>We do not have any profile information for you in our system at this time. </p> </div> }
            </div>

          </mi.HeaderBackground>
        </mi.Background>
      </React.Fragment>
    )
  }
}

export default withRouter(MyInformation);
