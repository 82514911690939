import { useState } from 'react'
import { saveAlumniEmail } from '../alumniapi'
import { useDispatch } from "react-redux"
import { setIsLoading } from "redux/actions"
import { FilledBlue } from 'css/Buttons'
import css from '../sass/email.module.scss'

function Update({
  constituentLookupId,
  ...props
}) {
  const dispatch = useDispatch()
  const [ email, setEmail ] = useState(props.email)
  const [ error, setError ] = useState(null)

  function submitForm(e) {
    e.preventDefault()

    // validate email quick like
    if (/^.+@alumni\.ucla\.edu$/.test(email)) {
      dispatch(setIsLoading(true, "AlumniEmail"))

      // use the API
      saveAlumniEmail({
        email,
      }).then( response => {
        dispatch(setIsLoading(false, "AlumniEmail"))

        if (response.Success === email) {
          setError('Your email address has been updated.')

        } else {
          switch (response.Success) {
            case 'EMAIL_ALREADY_EXISTS':
              setError('That email already exists.')
            break;

            case 'FAILED_ALIAS_DELETE':
              setError('Could not delete your old email address.  New one was created.')
            break;

            case 'FAILED_UPDATE':
              setError('Could not update your email address.')
            break;

            default:
              setError('We were not able to update your email address.')
              console.log('email update error:', response.Success)
            break;
          }
        }

      }).catch( err => {
        dispatch(setIsLoading(false, "AlumniEmail"))
        setError('Something unexpected has happened.')
        console.log('email update broke:', err)
      })

    } else {
      setError('Must end with @alumni.ucla.edu.')
    }

  }

  return (
    <form
      className={css['alumni-email']}
      onSubmit={submitForm}
    >
      <label htmlFor='alumniemail'>Alumni Email</label>
      <input
        type='email'
        name='alumniemail'
        autoComplete='off'
        defaultValue={email}
        className={css.input}
        onChange={e => setEmail(e.currentTarget.value)}
      />
      { error !== null && <span className='error'>{ error }</span> }
      <p>
        <FilledBlue isSmall={true}> Update </FilledBlue>
      </p>
      <p>If you have any questions, please call <a href="tel:3108252586">310-825-2586</a> Monday through Friday between 8 a.m. and 5 p.m. PT or email <a href="mailto:alumni@alumni.ucla.edu">alumni@alumni.ucla.edu</a>.</p>
    </form>
  )

}

export default Update
