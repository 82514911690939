import { Component } from 'react';
import { withRouter } from 'react-router';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';

import store from 'redux/store'
import { getCognitoUser } from 'redux/selectors'

import { fbOauthUrl, googleOauthUrl } from 'config'
import { linkSpouseUser } from '../authapi';
import { doPostLogin } from '../postlogin';
import { signOut } from "../signout";
import { showLoading } from 'services/util'
import { ThemeContext } from 'ThemeContext';

import Alert from 'common/notifications/Alert';
import * as colors from 'css/Colors';
import * as cs from 'css/CommonStyle';
import * as ms from '../css/MultipleAccountsStyle.js';
import { device } from 'common/Device';
import iconInfo from 'images/icon-info-red.svg';

// TODO - use this for testing
// const dummyResponse = {
//   "users": [
//     {
//       "providerName": "Facebook",
//       "name": "Joseph Robert Bruin",
//       "email": "joe.c.bruin@gmail.com"
//     },
//     {
//       "providerName": "Google",
//       "name": "Mary Anne Bruin",
//       "email": "joe.c.bruin@gmail.com",
//       "phone_number": "310-555-1212"
//     },
//   ]
// };

const MultipleStyles = styled.div`
  ${ ms.Accounts}, ${ cs.CommonHeader }, .confirm-info {
    @media ${ device.mobile } {
      padding:0 ${ 15 / 320 * 100 }%;
    }
  }

  .error-alert {
    margin: 0 auto;
    width: 669px;

    @media ${ device.mobile } {
      width: 100%;
    }
  }

  .error-alert-text {
    padding: 0;
    text-align: left;

    @media ${ device.mobile } {
      padding: 0 3%;
    }
  }
`

class MultipleAccounts extends Component {
  state = {
    dupeUsers: this.props.location.state ? this.props.location.state.users : [],
    loggedInUser: this.props.location.state ? this.props.location.state.loggedInUser : {},
    users: [],
    submitError: false
  };

  async componentDidMount() {
    const currentUser = getCognitoUser(store.getState())

    if (currentUser.attributes && currentUser.attributes.identities) {
      currentUser.attributes.provider = JSON.parse(currentUser.attributes.identities.replace(/'/g, '"'))[0].providerName;
    }
    //original data
    this.setState({ "users": [currentUser.attributes].concat(this.state.dupeUsers), currentUser });

    // uncomment this to test with dummy data
    // this.setState({ "users": dummyResponse.users });

    this.props.hasMultipleAccounts(true);
  }

  componentWillUnmount() {
    this.props.hasMultipleAccounts(false);
  }

  handleAccountClick = async crmId => {
    const linkUserResponse = await linkSpouseUser(crmId);
    if (linkUserResponse.error) {
      const errorMsg = linkUserResponse.error.msg;
      await this.setState({ userData: linkUserResponse, originalUserData: JSON.parse(JSON.stringify(linkUserResponse)), isSubmitSuccessful: false, submitError: errorMsg, isClean: true });
    }
    else if (this.context.openIdRedirect) { // open id users get redirected back to their source
      document.location.href = this.context.openIdRedirect;
    }
    else if (!this.state.loggedInUser.password) { // social user has to log in again
      if (!localStorage.socialLoginSource) {
        signOut(); // do full signout if something goes wrong, they can log in again
      }
      else {
        await signOut(localStorage.socialLoginSource === 'google' ? googleOauthUrl : fbOauthUrl); // force resign in again through social account
      }
      return;
    }
    else {
      showLoading(true);
      await Auth.signIn(this.state.loggedInUser.username, this.state.loggedInUser.password);
      await doPostLogin({username:this.state.loggedInUser.username, password:this.state.loggedInUser.password});
    }
  }

  render() {
    const multipleUserInfo = this.state.dupeUsers.map((user, index) => {
      const name = user[0];
      const crmId = user[1];
      const id = "existing-account-" + index;
      return (
        <div key={index} className="user-multiple-accounts" onClick={() => this.handleAccountClick(crmId)}>
          <ms.AccountDetails>
            <div id={id} className="existing-accounts-details">
              {name && <p className="user-name bold">{name}</p>}
            </div>
          </ms.AccountDetails>
        </div>
      );
    });

    let logInMethod;
    let logInCredential;
    const email = this.state.loggedInUser.email;
    const mobilePhone = this.state.loggedInUser.phone_number;

    // display log in method
    if (email) logInMethod = 'email address';
    if (mobilePhone) logInMethod = 'mobile phone number';

    // show email address or phone number used to log in
    if (email) logInCredential = email;
    if (mobilePhone) logInCredential = mobilePhone;

    return (
      <MultipleStyles>
        <cs.CommonOuterDiv>
          {(this.state.isClean && this.state.submitError) &&
            <div className="error-alert">
              <Alert
                nonPromoPage
                icon={iconInfo}
                color={colors.messagingRed}
                className="error-alert-text"
                width="25" height="25" top="35%" >
                <p>{this.state.submitError}</p>
             </Alert>
            </div>
          }
          <cs.CommonHeader>
            <h4 className="bold" id="multiplePageTitle">We have found two existing accounts.</h4>
          </cs.CommonHeader>
          <cs.CommonInnerDiv>
            <ms.Accounts>
              <div>
                <p className="paragraph-message">We have found two accounts using the {logInMethod} you have provided: {logInCredential}. Please choose your name to log into your account.</p>
              </div>
              <div className="multiple-accounts space-evenly-fix">{multipleUserInfo /* original data */}{/* dummyUsers *//* test data */ }</div>
            </ms.Accounts>
          </cs.CommonInnerDiv>
          <p className="confirm-info">If any of your information is incorrect, please contact {this.context.supportTeamName} at&nbsp;
            {this.context.supportPhone && <>{this.context.supportPhone}</>}
            {!this.context.supportPhone && this.context.supportEmail && <a href={"mailto:" + this.context.supportEmail + "?subject=" + this.context.appName + " request not approved inquiry"}>{this.context.supportEmail}</a>}
          </p>
        </cs.CommonOuterDiv>
      </MultipleStyles>
    )
  }
}

export default withRouter(MultipleAccounts);
MultipleAccounts.contextType = ThemeContext;