import IdmReactTable from "common/IdmReactTable"
import MoreInfo from 'common/MoreInfo'
import { initializeDate } from 'services/util'

import { formatAmount, formatDate, formatActivity } from '../mygivingutil'
import GiftActions from './GiftActions'
import { PaymentDue, PaymentReceived, ReceiptActionStyles, NoPledgeInstallmentsMessage } from './HistoryStyles'

// this displays the gift history table / listing
function HistorySubtable (props) {
  const subsectionTableProps = {
    initialState: {
      minRows: 0,
      sortBy: [
       {
        id: 'date',
        desc: true
       }
      ]
    },
  }

  const recurringColumns = [
    { Header: 'Activity', id: 'activity', Cell: cell => formatActivity({ index: cell.row.index, balance: cell.row.original.balance })},
    { Header: 'Status', id: 'paid', Cell: cell => pledgeStatus({ balance: cell.row.original.balance, date: initializeDate(cell.row.original.date), cell }) },
    { Header: 'Date', accessor: 'date', Cell: cell => formatDate( cell.value )},
    { Header: 'Legal Donor Name', accessor: 'primaryDonorName' },
    { Header: 'Amount', accessor: 'displayAmount', Cell: cell => formatAmount( cell.value ) },
    { Header: 'Payment Method', Cell: cell => pledgePaymentMethod( cell ) },
    { Header: 'Receipt', id: 'receipt', Cell: cell => pledgeActions( cell ) },
  ];

  // I don't think pledge balance makes sense anymore
  // const pledgeOrPlannedColumns = recurringColumns.slice(0,5).concat(
  //   { Header: 'Payment Balance', id: 'balance', Cell: cell => formatAmount( cell.row.original.balance || 0 )},
  // ).concat(recurringColumns.slice(5,7))

  const pledgeStatus = ({ balance, date }) => {
    if (balance) {
      return <PaymentDue>{ initializeDate() > date ? "Past Due" : "Due" }</PaymentDue>
    }
    else {
      return <PaymentReceived>Paid</PaymentReceived>
    }
  }

  const pledgePaymentMethod = cell => {
    const revenueLookupId = cell.row.original.revenueLookupId || cell.row.index
    return cell.row.original.pledge.hasAutomaticPayments && ( initializeDate(cell.row.date) >= initializeDate() ) ?
    (
      <ReceiptActionStyles>
        <div className="installment-container">
          <span>Autopay</span>
          <MoreInfo
            htmlName={`more_info_${ revenueLookupId }`}
            htmlId={`more_info_${ revenueLookupId }_${ cell.row.index }`}
            srText={"About Automatic Payments"}
          >
            You have elected to make automatic payments on this pledge. We will charge the appropriate amount on the date the payment is due.
          </MoreInfo>
        </div>
      </ReceiptActionStyles>
    ) : cell.row.original.paymentMethod || null
  }

  // shuold only be recurring gifts and planned gifts, pledges have moved to their own section
  const pledgeActions = cell => {
    const row = cell.row.original

    // only schedules have a balance, should only be recurring gifts now
    if (row.balance) {

      // next upcoming installment - recurring gift
      if (row.pledge.hasAutomaticPayments && ( initializeDate(cell.row.date) >= initializeDate() )) {
        const revenueLookupId = row.revenueLookupId || cell.row.index
        return (
          <ReceiptActionStyles>
            <div className="installment-container">
              <span>Pending Payment</span>
              <MoreInfo
                htmlName={`more_info_${ revenueLookupId }`}
                htmlId={`more_info_${ revenueLookupId }_${ cell.row.index }`}
                srText={"About Pending Payments"}
              >
                A receipt will be available after this payment has been made.
              </MoreInfo>
            </div>
          </ReceiptActionStyles>
        )
      }
      // if there is a balance, but the date is in the past show nothing
      else {
        return (<> </>)
      }
    }
    // paid recurring gift installments or paid planned gift installments
    else {

      return (
        <div className="pledge-print-button">
          <GiftActions
            isReceiptable={ row.isReceiptable }
            printReceipt={ props.printReceipt }
            original={ row }
            index={ cell.row.index }
          />
        </div>
      )
    }
  }

  return (
    <>
      {props.data && props.data.length > 0 &&
        <IdmReactTable
          {...subsectionTableProps}
          data={props.data}
          columns={recurringColumns}
          className={"subtable"}
      />}

      {props.data && props.data.length === 0 &&
        <NoPledgeInstallmentsMessage>No installments available</NoPledgeInstallmentsMessage>}
    </>
  )
}

export default HistorySubtable;
