import { useContext } from 'react'

import { ThemeContext } from 'ThemeContext';
import DropDownMenu from 'common/forms/DropDown'
import * as cs from 'css/CommonStyle'
import * as forms from 'css/forms'
import { MARITAL_STATUS } from 'common/forms/data/BioData'
import { ProfileDataContainer } from './MyNames'

import styled from 'styled-components'
import { device } from 'common/Device'
import MoreInfo from 'common/MoreInfo'

const RowData=styled(ProfileDataContainer)`
  display:flex;
  margin-bottom:0;
  justify-content:space-between;
  padding: ${20/16}rem;
  .spouse-info {
    padding:0 !important;
    flex:0 25%;
  }
  .wide-text-input {
    flex: 0 70%;
  }
  @media ${device.mobile} {
    flex-direction:column;
    & >div{
      flex:0 100%;
    }
  }
`
const defaultFontSize = 16

const SpouseMoreInfoPositionStyles = styled.div`
  margin:0 0 -15px 0 !important;
  flex:none !important;
  position:relative;
  left:${ 80/defaultFontSize }rem;
  top:${ -20/defaultFontSize }rem;
  height:1rem;
  width:1rem;
}`

const Spouse = (props) => {
  const theme = useContext(ThemeContext)

  return (
    <div>
      <h4 className="bold">Spouse or Partner</h4>
      <RowData>

        <div className="spouse-info">
          <label> Status</label>
          <cs.TextInputdiv>
            <DropDownMenu
              name="maritalStatus"
              // treat other marital statuses (such as "Unknown") the same as missing
              title={(props.userMaritalStatus.maritalstatus && MARITAL_STATUS.indexOf(props.userMaritalStatus.maritalstatus) > -1) ? props.userMaritalStatus.maritalstatus : "Select A Status"}
              list={MARITAL_STATUS}
              changeHandler={props.changeHandler}
            />
          </cs.TextInputdiv>
        </div>

        {(props.userMaritalStatus.maritalstatus === 'Married'|| props.userMaritalStatus.maritalstatus === 'Domestic Partner') &&
         <div className="wide-text-input">

            <label> Full  Name</label>
            {props.readOnly &&
            <SpouseMoreInfoPositionStyles>
              <MoreInfo
                  htmlName={`more_info_spouse`}
                  htmlId={`more_info_spouse1`}
                  srText={"About Spouse"}
                >
                  Please contact {theme.supportTeamName} at {theme.supportPhone || theme.supportEmail} to request a change to your spouse’s name.
                </MoreInfo>
            </SpouseMoreInfoPositionStyles>}

            <cs.TextInputdiv>
              <forms.Input
                name="spouseName"
                readOnly={props.readOnly}
                value={props.userMaritalStatus.spouseName}
                onChange={props.changeHandler}
              />
            </cs.TextInputdiv>
          </div>
        }

      </RowData>
    </div>
    )
}

export default Spouse
