import { doApi } from 'services/api';
import store from 'redux/store';

// NOTE: to see alumni emails - see authenticatedUser.crmUser.alumniEmails - there can be 0, 1 or multiple

// test users
// 0 emails - any crm user
// 1 email - test@test.com (JB)
// multiple emails - test0091569989@test.com (Silas)

export const saveAlumniEmail = async (formBody) => {
  const loggedInUser = store.getState().loggedInUser
  if (!loggedInUser || !loggedInUser.cognitoUser || !loggedInUser.cognitoUser.username)
    console.error('User not logged in')
  else if (loggedInUser.cognitoUser.username.indexOf('@test.com') > 0)
    console.error('Cant save test user')
  else
     return await doApi({route:'alumni/saveemail', method:'POST', data:formBody});
};

// formBody - input params
//
// Update existing account
// {
//   "email": "test@alumni.ucla.edu",
// }
// Create new account (all fields are required)
// {
//   "email": "test@alumni.ucla.edu",
//   "firstName": "Jane",
//   "lastName": "Bruin",
//   "password": "thisisapassword" //minimum 8 characters
// }


// ### Response examples:
// {
//   "Success": "test@alumni.ucla.edu"
// }
// {
//   "Success": "BAD_PARAMETERS"
// }
// {
//   "Success": "FAILED_UPDATE"
// }
// {
//   "Success": "FAILED_ALIAS_DELETE"
// }
// {
//   "Success": "FAILED_CREATE"
// }
// {
//   "Success": "NO_GOOGLE_ACCOUNT"
// }
// {
//   "Success": "EMAIL_ALREADY_EXISTS"
// }
