import styled from 'styled-components';

const StyledHeading = styled.div`
text-align: center;
padding: 0px 15px;
font-size: 16px;
letter-spacing: 0.1px;
line-height: 26px;

& > p {
  color: #000000;
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.1px;
  line-height: 26px;
  text-align: center;
}
`;

export const Heading = ({ children, className }) => (
  <StyledHeading className={className}>
    {children}
  </StyledHeading>
);
