import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import adminCss from './sass/adminpage.module.scss'

export default function AdminBreadcrumbs(props) {

  const [mainTitle, setMainTitle] = useState('')
  const [mainLink, setMainLink] = useState('')

  useEffect(() => {

    if (!props.auctionDetail) return

    switch(props.auctionDetail.Status) {
      case "CLOSED":
        setMainTitle('Closed Auctions')
        setMainLink('/admin/listclosedauctions')
        break;
      case "PUBLIC":
        setMainTitle('Public Auctions')
        setMainLink('/admin/listpublicauctions')
        break;
      case "PREVIEW":
        setMainTitle('Preview Auctions')
        setMainLink('/admin/listpreviewauctions')
      break;
      default:
        setMainTitle()
        setMainLink()
    }
  }, [props.auctionDetail])

  if (!props.auctionDetail) return <></>
 
  return (
    <div className={adminCss['admin-auction-page-breadcrumbs']}>
      <Link to={mainLink}> {mainTitle}</Link>
      <span> 
        / {props.auctionDetail.Title}
      </span>
    </div>
  )
}