import { Link,withRouter } from "react-router-dom"

import iconRightArrow from 'images/arrow-right.svg'
import styled from 'styled-components'
import ThickCheck from 'images/Icon _ thick-check.svg'

const defaultFontSize = 16

const BackwardLinks=styled.div`
a{
  text-decoration:none;
}
a:nth-child(2){
  align-items: flex-end;
}
`
const ArrowLink = styled.a`
  display:inline-flex;
  flex-direction:row;
  align-items:center;
  text-decoration:none;
  font-style:italic;
  font-weight:600;
  font-size:${ 14/defaultFontSize }rem;
  text-transform:uppercase;
  white-space:nowrap;

  &:after {
    content:'';
    display:block;
    vertical-align:top;
    margin-left:${ 4/defaultFontSize }rem;
    height:0.9em;
    width:0.9em;
    background:url(${iconRightArrow}) no-repeat;
    background-size:contain;
  }
`

const ArrowRightLink = props => (
  <ArrowLink className="arrow-right-link" onClick={ props.onClick } href={ props.href } target={props.target} rel="nofollow noopener" data-testid="give-again-link">
    <span>{ props.children }</span>
  </ArrowLink>
)

const LinkUserSuccess=(props)=>(
  <div>
    <h3> Link User to CRM</h3>
    <p className="bold"> Account link has been updated. <img src={ThickCheck} alt='success icon'/> </p>
    <p> User will be linked to their CRM account the next time they logon. </p>
      <BackwardLinks>
      <Link to="/admin/linkusertocrm" > <em className="bold">Go back to Link User to CRM </em></Link><ArrowRightLink/>
      </BackwardLinks>
  </div>
)
export default withRouter(LinkUserSuccess);