import styled from 'styled-components'
import { primaryBlue, lightGray, borderGray } from 'css/Colors'

// math things
const defaultFontSize = 16

// doing the math for the columns here
// starting with main table widths
const auditTableColumnWidths = [
  134,
  140,
  120,
  190,
  190,
  190,
]
const usersTableColumnWidths = [
  134,
  350,
  160,
  160,
  300,
]
const downloadsTableColumnWidths = [
  110,
  75,
  75,
  134,
  320,
  350,
  150,
]
const documentsTableColumnWidths = [
  40,
  65,
  75,
  250,
  100,
  20,
  20,
]
const pendingListColumnWidths = [
  20,
  150,
  75,
  75,
  75,
  175,
  175,
]
const unconfirmedUsersColumnWidths = [
  350,
  350,
  150,
]

const auditTableCompWidth = auditTableColumnWidths.reduce( (acc, width) => acc + width )
const usersTableCompWidth = usersTableColumnWidths.reduce( (acc, width) => acc + width )
const downloadsTableCompWidth = downloadsTableColumnWidths.reduce( (acc, width) => acc + width )
const documentsTableCompWidth = documentsTableColumnWidths.reduce( (acc, width) => acc + width )
const pendingListTableCompWidth = pendingListColumnWidths.reduce( (acc, width) => acc + width )
const unconfirmedUsersTableCompWidth = unconfirmedUsersColumnWidths.reduce( (acc, width) => acc + width )

export const AdminButtonWrap = styled.div`
  display: inline-block;
  white-space: nowrap;
  float: right;
  padding: .2rem 1.625rem 1.2rem 0;
`;

export const InputWrap = styled.div`
  flex: 1 0 100px;
  margin-right: 0.825rem;
  display: inline-block;

  .input-wrap-dropdown {
    height:3.045rem;
  }
  .toggle-container {
    margin:0;
  }
`;

export const ButtonWrap = styled.div`
  display: inline-block;
  white-space: wrap;
`;

export const OperatorWrapper = styled.div`
  font-size: 36px;
  line-height: 3.045rem;
  margin-right: 0.7rem;
  margin-top: 1.4rem;
`

export const QueryResultsStyles = styled.div`
  font-size:${ 14/defaultFontSize }rem;
  line-height:${ 20/14 };
  margin-top:${ 10/defaultFontSize }rem;
  padding: 0;
  width: 100%;

  /* the note */
  aside {
    margin:${ 30/defaultFontSize }rem 0;
  }

  section {
    font-size: 1.5rem;
    line-height: 1.4285714285714286;
    margin-top: .1rem;
    padding: 1rem 0 .5em 0.625rem ;
    width: 100%;
    font-weight: 600;

    span {
      color: #2774AE;
    }

    span {
      color: #2774AE;
    }
  }

  .row-button {
    height: 100%;
  }
  /* sort carat */
  .-sort-desc {
    box-shadow: none;
    &:before {
      content: "▼";
      float: right;
    }
  }

  .-sort-asc {
    box-shadow: none;
    &:before {
      content: "▲";
      float: right;
    }
  }

  /* hide the loading message */
  .-loading {
    display:none;
  }

  .rt-table {
    border:1px solid ${ borderGray };
    border-radius:${ 5/defaultFontSize }rem;
  }

  .rt-tr {
    display:grid;
    width:100%;
    box-sizing:border-box;
    /* build the grid dimensions */
    grid-template-columns:${props => {
      switch(props.searchType) {
        case 'users':
          return usersTableColumnWidths.map( (width,idx) => {
            return `${width / usersTableCompWidth * 100}% `
          })
        case 'audit':
          return auditTableColumnWidths.map( (width,idx) => {
            return `${width / auditTableCompWidth * 100}% `
          })
        case 'documents':
          return documentsTableColumnWidths.map( (width,idx) => {
            return `${width / documentsTableCompWidth * 100}% `
          })
       case 'pendinglist':
          return pendingListColumnWidths.map( (width,idx) => {
            return `${width / pendingListTableCompWidth * 100}% `
          })
       case 'unconfirmedusers':
          return unconfirmedUsersColumnWidths.map( (width,idx) => {
            return `${width / unconfirmedUsersTableCompWidth * 100}% `
          })
        default:
          return downloadsTableColumnWidths.map( (width,idx) => {
            return `${width / downloadsTableCompWidth * 100}% `
          })
      }}
    };
    padding:${ 12/defaultFontSize }rem;

    grid-template-rows:auto;

    &.-odd {
      background:${ lightGray };
    }
  }

  .rt-th {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    text-align:left;
  }

  .rt-td {
    position:relative;
    box-sizing:content-box;
  }

  .rt-tbody {
    overflow-y:auto;
    max-height: ${props => props.searchType === 'pendinglist' ? "75vh" : "51vh" };
  }

  /* header */
  .rt-thead {
    font-weight:600;
    color:${ primaryBlue };
    text-transform:uppercase;
    border-bottom:1px solid ${ borderGray };

    .rt-tr {
      padding-top:${ 26/defaultFontSize }rem;
      padding-bottom:${ 21/defaultFontSize }rem;
    }
  }

  /* individual grid cell styles */
  .rt-td {
    &:nth-child(2) {
      font-weight:600;
    }
  }
`
