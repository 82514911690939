import { getHistoryList } from '../history/historyapi';

export const getPledges = async () => {
  console.log('getPledges!');

  if (localStorage.crmUser === "{}") {
    return [];
  }
  else {
    return (await getHistoryList()).filter(gift => gift.isPledgeSummary);
  }
}


//   const dummyPledges = {
//    "success":{
//       "pledges":[
//          {
//             "revenueLookupId":"rev-14727835",
//             "date":"2019-12-24T00:00:00",
//             "legalAmount":91604,
//             "recognitionAmount":229010,
//             "totalPaidAmount":91604,
//             "school":"College of Letters and Science",
//             "division":"Division of Social Sciences",
//             "department":"Anthropology",
//             "campaign":"Centennial Campaign",
//             "designation":"Robert B. Edgerton Endowed Graduate Fellowship",
//             "designationLookupId":"82315E - BB",
//             "agency":"Foundation",
//             "purpose":"Student Aid - Fellowships",
//             "pledgeAmount":229010,
//             "pledgeBalance":137406,
//             "nextInstallmentDate":"2022-02-28T00:00:00",
//             "nextInstallmentAmount":45802,
//             "frequency":"Irregular",
//             "taxDeductibleAmount":91604,
//             "benefitAmount":0,
//             "revenueType":"ST",
//             "revenueTypeFull":"Pledge",
//             "isReceiptable":false,
//             "notReceiptableReasonCode":"PLEDGE",
//             "paymentMethod":"None",
//             "hasAutomaticPayments":false,
//             "isAnonymous":false,
//             "giftDetails":"",
//             "tributeText1":"",
//             "tributeText2":"",
//             "primaryDonorName":"Dr. Karen L. Ito",
//             "spouseName":"",
//             "fundNumber":"82315E",
//             "revenueTypeHasInstallments":true,
//             "revenueTypeHasInstallmentSchedule":true,
//             "displayAmount":229010,
//             "schedules":[
//                {
//                   "date":"2019-12-24T00:00:00",
//                   "amount":45802,
//                   "balance":0
//                },
//                {
//                   "date":"2021-02-28T00:00:00",
//                   "amount":45802,
//                   "balance":0
//                },
//                {
//                   "date":"2022-02-28T00:00:00",
//                   "amount":45802,
//                   "balance":45802
//                },
//                {
//                   "date":"2023-02-28T00:00:00",
//                   "amount":45802,
//                   "balance":45802
//                },
//                {
//                   "date":"2024-02-29T00:00:00",
//                   "amount":45802,
//                   "balance":45802
//                }
//             ],
//             "installments":[
//                {
//                   "revenueLookupId":"rev-14726813",
//                   "date":"2019-12-27T00:00:00",
//                   "legalAmount":5000,
//                   "recognitionAmount":0,
//                   "school":"College of Letters and Science",
//                   "division":"Division of Social Sciences",
//                   "department":"Anthropology",
//                   "campaign":"Centennial Campaign",
//                   "designation":"Robert B. Edgerton Endowed Graduate Fellowship",
//                   "designationLookupId":"82315E - BB",
//                   "agency":"Foundation",
//                   "purpose":"Student Aid - Fellowships",
//                   "taxDeductibleAmount":5000,
//                   "benefitAmount":0,
//                   "revenueType":"PP",
//                   "revenueTypeFull":"Pledge Payment",
//                   "isReceiptable":false,
//                   "notReceiptableReasonCode":"OUTSIDEDATERANGE",
//                   "paymentMethod":"Check",
//                   "isAnonymous":false,
//                   "giftDetails":"",
//                   "tributeText1":"",
//                   "tributeText2":"",
//                   "primaryDonorName":"Dr. Karen L. Ito",
//                   "spouseName":"",
//                   "fundNumber":"82315E",
//                   "displayAmount":5000
//                },
//                {
//                   "revenueLookupId":"rev-14708645",
//                   "date":"2019-12-31T00:00:00",
//                   "legalAmount":22880.13,
//                   "recognitionAmount":0,
//                   "school":"College of Letters and Science",
//                   "division":"Division of Social Sciences",
//                   "department":"Anthropology",
//                   "campaign":"Centennial Campaign",
//                   "designation":"Robert B. Edgerton Endowed Graduate Fellowship",
//                   "designationLookupId":"82315E - BB",
//                   "agency":"Foundation",
//                   "purpose":"Student Aid - Fellowships",
//                   "taxDeductibleAmount":22880.13,
//                   "benefitAmount":0,
//                   "revenueType":"PP",
//                   "revenueTypeFull":"Pledge Payment",
//                   "isReceiptable":false,
//                   "notReceiptableReasonCode":"OUTSIDEDATERANGE",
//                   "paymentMethod":"Check",
//                   "isAnonymous":false,
//                   "giftDetails":"",
//                   "tributeText1":"",
//                   "tributeText2":"",
//                   "primaryDonorName":"Dr. Karen L. Ito",
//                   "spouseName":"",
//                   "fundNumber":"82315E",
//                   "displayAmount":22880.13
//                },
//                {
//                   "revenueLookupId":"rev-14727836",
//                   "date":"2020-02-25T00:00:00",
//                   "legalAmount":17921.87,
//                   "recognitionAmount":0,
//                   "school":"College of Letters and Science",
//                   "division":"Division of Social Sciences",
//                   "department":"Anthropology",
//                   "campaign":"Post Centennial Campaign",
//                   "designation":"Robert B. Edgerton Endowed Graduate Fellowship",
//                   "designationLookupId":"82315E - BB",
//                   "agency":"Foundation",
//                   "purpose":"Student Aid - Fellowships",
//                   "taxDeductibleAmount":17921.87,
//                   "benefitAmount":0,
//                   "revenueType":"PP",
//                   "revenueTypeFull":"Pledge Payment",
//                   "isReceiptable":true,
//                   "notReceiptableReasonCode":"",
//                   "paymentMethod":"Check",
//                   "isAnonymous":false,
//                   "giftDetails":"",
//                   "tributeText1":"",
//                   "tributeText2":"",
//                   "primaryDonorName":"Dr. Karen L. Ito",
//                   "spouseName":"",
//                   "fundNumber":"82315E",
//                   "displayAmount":17921.87
//                },
//                {
//                   "revenueLookupId":"rev-14836295",
//                   "date":"2021-01-21T00:00:00",
//                   "legalAmount":10345.55,
//                   "recognitionAmount":0,
//                   "school":"College of Letters and Science",
//                   "division":"Division of Social Sciences",
//                   "department":"Anthropology",
//                   "campaign":"Centennial Campaign",
//                   "designation":"Robert B. Edgerton Endowed Graduate Fellowship",
//                   "designationLookupId":"82315E - BB",
//                   "agency":"Foundation",
//                   "purpose":"Student Aid - Fellowships",
//                   "taxDeductibleAmount":10345.55,
//                   "benefitAmount":0,
//                   "revenueType":"PP",
//                   "revenueTypeFull":"Pledge Payment",
//                   "isReceiptable":true,
//                   "notReceiptableReasonCode":"",
//                   "paymentMethod":"Check",
//                   "isAnonymous":false,
//                   "giftDetails":"",
//                   "tributeText1":"",
//                   "tributeText2":"",
//                   "primaryDonorName":"Dr. Karen L. Ito",
//                   "spouseName":"",
//                   "fundNumber":"82315E",
//                   "displayAmount":10345.55
//                },
//                {
//                   "revenueLookupId":"rev-14841219",
//                   "date":"2021-02-17T00:00:00",
//                   "legalAmount":5402,
//                   "recognitionAmount":0,
//                   "school":"College of Letters and Science",
//                   "division":"Division of Social Sciences",
//                   "department":"Anthropology",
//                   "campaign":"Centennial Campaign",
//                   "designation":"Robert B. Edgerton Endowed Graduate Fellowship",
//                   "designationLookupId":"82315E - BB",
//                   "agency":"Foundation",
//                   "purpose":"Student Aid - Fellowships",
//                   "taxDeductibleAmount":5402,
//                   "benefitAmount":0,
//                   "revenueType":"PP",
//                   "revenueTypeFull":"Pledge Payment",
//                   "isReceiptable":true,
//                   "notReceiptableReasonCode":"",
//                   "paymentMethod":"Check",
//                   "isAnonymous":false,
//                   "giftDetails":"",
//                   "tributeText1":"",
//                   "tributeText2":"",
//                   "primaryDonorName":"Dr. Karen L. Ito",
//                   "spouseName":"",
//                   "fundNumber":"82315E",
//                   "displayAmount":5402
//                },
//                {
//                   "revenueLookupId":"rev-14859729",
//                   "date":"2021-05-05T00:00:00",
//                   "legalAmount":30054.45,
//                   "recognitionAmount":0,
//                   "school":"College of Letters and Science",
//                   "division":"Division of Social Sciences",
//                   "department":"Anthropology",
//                   "campaign":"Centennial Campaign",
//                   "designation":"Robert B. Edgerton Endowed Graduate Fellowship",
//                   "designationLookupId":"82315E - BB",
//                   "agency":"Foundation",
//                   "purpose":"Student Aid - Fellowships",
//                   "taxDeductibleAmount":30054.45,
//                   "benefitAmount":0,
//                   "revenueType":"PP",
//                   "revenueTypeFull":"Pledge Payment",
//                   "isReceiptable":true,
//                   "notReceiptableReasonCode":"",
//                   "paymentMethod":"Credit card",
//                   "isAnonymous":false,
//                   "giftDetails":"",
//                   "tributeText1":"",
//                   "tributeText2":"",
//                   "primaryDonorName":"Dr. Karen L. Ito",
//                   "spouseName":"",
//                   "fundNumber":"82315E",
//                   "displayAmount":30054.45
//                }
//             ]
//          },
//          {
//             "revenueLookupId":"rev-14312892",
//             "date":"2016-08-10T00:00:00",
//             "legalAmount":100000,
//             "recognitionAmount":100000,
//             "totalPaidAmount":100000,
//             "school":"College of Letters and Science",
//             "division":"Division of Social Sciences",
//             "department":"Anthropology",
//             "campaign":"Centennial Campaign",
//             "designation":"Robert B. Edgerton Endowed Graduate Fellowship",
//             "designationLookupId":"82315E - BB",
//             "agency":"Foundation",
//             "purpose":"Student Aid - Fellowships",
//             "pledgeAmount":100000,
//             "pledgeBalance":0,
//             "nextInstallmentAmount":0,
//             "frequency":"Irregular",
//             "taxDeductibleAmount":100000,
//             "benefitAmount":0,
//             "revenueType":"ST",
//             "revenueTypeFull":"Pledge",
//             "isReceiptable":false,
//             "notReceiptableReasonCode":"PLEDGE",
//             "paymentMethod":"None",
//             "hasAutomaticPayments":false,
//             "isAnonymous":false,
//             "giftDetails":"",
//             "tributeText1":"",
//             "tributeText2":"",
//             "primaryDonorName":"Dr. Karen L. Ito",
//             "spouseName":"",
//             "fundNumber":"82315E",
//             "revenueTypeHasInstallments":true,
//             "revenueTypeHasInstallmentSchedule":true,
//             "displayAmount":100000,
//             "schedules":[
//                {
//                   "date":"2016-08-10T00:00:00",
//                   "amount":20000,
//                   "balance":0
//                },
//                {
//                   "date":"2016-12-31T00:00:00",
//                   "amount":30000,
//                   "balance":0
//                },
//                {
//                   "date":"2017-12-31T00:00:00",
//                   "amount":50000,
//                   "balance":0
//                }
//             ],
//             "installments":[
//                {
//                   "revenueLookupId":"rev-14312893",
//                   "date":"2016-08-19T00:00:00",
//                   "legalAmount":20000,
//                   "recognitionAmount":0,
//                   "school":"College of Letters and Science",
//                   "division":"Division of Social Sciences",
//                   "department":"Anthropology",
//                   "campaign":"Centennial Campaign",
//                   "designation":"Robert B. Edgerton Endowed Graduate Fellowship",
//                   "designationLookupId":"82315E - BB",
//                   "agency":"Foundation",
//                   "purpose":"Student Aid - Fellowships",
//                   "taxDeductibleAmount":20000,
//                   "benefitAmount":0,
//                   "revenueType":"PP",
//                   "revenueTypeFull":"Pledge Payment",
//                   "isReceiptable":false,
//                   "notReceiptableReasonCode":"OUTSIDEDATERANGE",
//                   "paymentMethod":"Check",
//                   "isAnonymous":false,
//                   "giftDetails":"",
//                   "tributeText1":"",
//                   "tributeText2":"",
//                   "primaryDonorName":"Dr. Karen L. Ito",
//                   "spouseName":"",
//                   "fundNumber":"82315E",
//                   "displayAmount":20000
//                },
//                {
//                   "revenueLookupId":"rev-14346764",
//                   "date":"2016-12-09T13:26:00",
//                   "legalAmount":30000,
//                   "recognitionAmount":0,
//                   "school":"College of Letters and Science",
//                   "division":"Division of Social Sciences",
//                   "department":"Anthropology",
//                   "campaign":"Centennial Campaign",
//                   "designation":"Robert B. Edgerton Endowed Graduate Fellowship",
//                   "designationLookupId":"82315E - BB",
//                   "agency":"Foundation",
//                   "purpose":"Student Aid - Fellowships",
//                   "taxDeductibleAmount":30000,
//                   "benefitAmount":0,
//                   "revenueType":"PP",
//                   "revenueTypeFull":"Pledge Payment",
//                   "isReceiptable":false,
//                   "notReceiptableReasonCode":"OUTSIDEDATERANGE",
//                   "paymentMethod":"Credit card",
//                   "isAnonymous":false,
//                   "giftDetails":"",
//                   "tributeText1":"",
//                   "tributeText2":"",
//                   "primaryDonorName":"Dr. Karen L. Ito",
//                   "spouseName":"",
//                   "fundNumber":"82315E",
//                   "displayAmount":30000
//                },
//                {
//                   "revenueLookupId":"rev-14462412",
//                   "date":"2017-11-30T16:07:00",
//                   "legalAmount":50000,
//                   "recognitionAmount":0,
//                   "school":"College of Letters and Science",
//                   "division":"Division of Social Sciences",
//                   "department":"Anthropology",
//                   "campaign":"Centennial Campaign",
//                   "designation":"Robert B. Edgerton Endowed Graduate Fellowship",
//                   "designationLookupId":"82315E - BB",
//                   "agency":"Foundation",
//                   "purpose":"Student Aid - Fellowships",
//                   "taxDeductibleAmount":50000,
//                   "benefitAmount":0,
//                   "revenueType":"PP",
//                   "revenueTypeFull":"Pledge Payment",
//                   "isReceiptable":false,
//                   "notReceiptableReasonCode":"OUTSIDEDATERANGE",
//                   "paymentMethod":"Credit card",
//                   "isAnonymous":false,
//                   "giftDetails":"",
//                   "tributeText1":"",
//                   "tributeText2":"",
//                   "primaryDonorName":"Dr. Karen L. Ito",
//                   "spouseName":"",
//                   "fundNumber":"82315E",
//                   "displayAmount":50000
//                }
//             ]
//          },
//          {
//             "revenueLookupId":"0001946521",
//             "date":"1999-12-10T00:00:00",
//             "legalAmount":0,
//             "recognitionAmount":300,
//             "totalPaidAmount":300,
//             "school":"Chancellor's Greatest Needs",
//             "division":"Chancellor's Greatest Needs",
//             "department":"UCLA Fund",
//             "campaign":"Faculty Campaign",
//             "designation":"Chancellor's Circle - Blue",
//             "designationLookupId":"00407G - FZ",
//             "agency":"Foundation",
//             "purpose":"Unrestricted",
//             "pledgeAmount":300,
//             "pledgeBalance":0,
//             "nextInstallmentAmount":0,
//             "frequency":"Single Installment",
//             "taxDeductibleAmount":0,
//             "benefitAmount":0,
//             "revenueType":"ST",
//             "revenueTypeFull":"Pledge",
//             "isReceiptable":false,
//             "notReceiptableReasonCode":"NOTLEGALDONOR",
//             "paymentMethod":"None",
//             "hasAutomaticPayments":false,
//             "isAnonymous":false,
//             "giftDetails":"",
//             "tributeText1":"",
//             "tributeText2":"",
//             "primaryDonorName":"Professor Robert B. Edgerton",
//             "spouseName":"Dr. Karen L. Ito",
//             "fundNumber":"00407G",
//             "revenueTypeHasInstallments":true,
//             "revenueTypeHasInstallmentSchedule":true,
//             "displayAmount":300,
//             "schedules":[
//                {
//                   "date":"1999-12-10T00:00:00",
//                   "amount":300,
//                   "balance":0
//                }
//             ],
//             "installments":[
//                {
//                   "revenueLookupId":"0001948968",
//                   "date":"1999-12-27T00:00:00",
//                   "legalAmount":300,
//                   "recognitionAmount":0,
//                   "school":"Chancellor\u0027s Greatest Needs",
//                   "division":"Chancellor\u0027s Greatest Needs",
//                   "department":"UCLA Fund",
//                   "campaign":"Faculty Campaign",
//                   "designation":"Chancellor\u0027s Circle - Blue",
//                   "designationLookupId":"00407G - FZ",
//                   "agency":"Foundation",
//                   "purpose":"Unrestricted",
//                   "taxDeductibleAmount":0,
//                   "benefitAmount":0,
//                   "revenueType":"PP",
//                   "revenueTypeFull":"Pledge Payment",
//                   "isReceiptable":false,
//                   "notReceiptableReasonCode":"NOTLEGALDONOR",
//                   "paymentMethod":"Check",
//                   "isAnonymous":false,
//                   "giftDetails":"",
//                   "tributeText1":"",
//                   "tributeText2":"",
//                   "primaryDonorName":"Professor Robert B. Edgerton",
//                   "spouseName":"Dr. Karen L. Ito",
//                   "fundNumber":"00407G",
//                   "displayAmount":300
//                }
//             ]
//          }
//       ]
//    }
// }