import styled from 'styled-components'

const defaultFontSize = 16
const BlackbaudLinkContainer = styled.a`
  display: inline-block;
  color: #FFFFFF;
  font-size: ${ 14/defaultFontSize }rem;
  min-width: 160px;
  height: 1rem;
  &:hover {
    color:#FFFFFF;
  }
`

const BlackbaudLink =(props)=>(

   <BlackbaudLinkContainer className="blackbaud-link" href="https://crm.support2.ucla.edu/crm/webui/webshellpage.aspx?databasename=BBInfinity"
   target="_blank" rel="noopener noreferrer">Lookup on Blackbaud</BlackbaudLinkContainer>

)

export default BlackbaudLink