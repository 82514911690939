import { doApi } from 'services/api';

// deletes current logged in User from Cognito (used for testing)
// export const deleteUser = async (emailOrPhone) => {
//   return await doApi({route:'admin/deleteuser?username=' + emailOrPhone});
// };

// cognitoUsername is the name they used to sign up
export const getCognitoUsers = async (cognitoUsername) => {
  return await doApi({route:'admin/getcognitousers?username=' + cognitoUsername}); // TODO - do we need to escape this?
};

export const getCrmUserAdmin = async (crmId) => {
  return await doApi({route:'admin/getcrmusers?crmid=' + crmId});
};

export const linkCrmUser = async (action, crmId, emailOrPhone, previousCrmId) => {
  return await doApi({route:'admin/linkcrmuser', method:'POST', data:{action, crmId, emailOrPhone, previousCrmId}});
};

export const getAuditTrail = async (queryType, queryValue) => {
  return await doApi({route:'admin/audittrail?querytype=' + queryType + '&queryvalue=' + encodeURIComponent(queryValue)});
};

export const getUsers = async (queryApp, queryType, data) => {
  if (data.searchDateFrom || data.searchDateTo) {
    return await doApi({route:'admin/queryusers?queryapp=' + queryApp + '&querytype=' + queryType
                         + '&queryvalue1=' + encodeURIComponent(data.searchDateFrom || '')
                         + '&queryvalue2=' + encodeURIComponent(data.searchDateTo || '') });
  }
  else {
    return await doApi({route:'admin/queryusers?queryapp=' + queryApp + '&querytype=' + queryType + '&queryvalue1=' + encodeURIComponent(data.searchValue || '')});
  }
};

export const getUnconfirmedUserList = async () => {
  return await doApi({route:'admin/getunconfirmedusers'});
};

export const deleteUnconfirmedUser = async (username) => {
  return await doApi({route:'admin/deleteunconfirmeduser?username=' + encodeURIComponent(username)});
};
