import { Component } from 'react'

import Social from './Social'
import { ProfileDataContainer, ProfileDataContent } from 'profile/personal/MyNames'

import fBookIcon from 'images/facebook-icon.svg'
import instaIcon from 'images/Instagram.svg'
import linkedInIcon from 'images/LinkedIn.svg'
import twitterIcon from 'images/Twitter.svg'
import instagramBackground from 'images/instagram-background.jpg'
import MoreInfo_Twitter from 'images/hint-box-twitter.png'
import MoreInfo_Facebook from 'images/hint-box-facebook.png'
import MoreInfo_Instagram from 'images/hint-box-instagram.png'
import MoreInfo_Linkedin from 'images/hint-box-linkedin.png'


import styled from 'styled-components'
import { device } from 'common/Device'

const SortSocialMedia = styled(ProfileDataContent)`
justify-content:space-between

@media ${device.mobile}{
  flex-direction:column;
}`

class SocialMedia extends Component {

  state = {
    infoImage: false,
  }
  componentDidMount(){

  }

  socialChange = event => {
    const socialName = event.target.name;
    let url = event.target.value;

    const newItem = {
      "socialMediaService": socialName,
      "socialMediaAccountType": "",
      "userID": "", // let the back end figure out URL from userID or vice/versa
      "url": url
    };

    this.props.changeHandler({target:{value:newItem, name:socialName}});
  }

  render() {

    return (

      <ProfileDataContainer>
        <div>
          <SortSocialMedia>
            <Social
              title="Facebook URL"
              keyName="Facebook"
              bgColor="#3B5998"
              socialMediaIcon={fBookIcon}
              placeholder="e.g. https://www.facebook.com/joebruin/"
              url={this.props.data.Facebook && this.props.data.Facebook.url}
              changeHandler={this.socialChange}
              htmlName={"more_info_facebook_url_find_details"}
              htmlId={"more_info_facebook_url_find_details"}
              srText={"How to find the facebook URL?"}
              icon={MoreInfo_Facebook}
              gutterRef={ this.props.gutterRef }
            />

            <Social
              title="Twitter URL or Handle"
              keyName="Twitter"
              bgColor="#38A1F3"
              socialMediaIcon={twitterIcon}
              placeholder="e.g. https://www.twitter.com/joebruin/"
              url={this.props.data.Twitter &&this.props.data.Twitter.url}
              changeHandler={this.socialChange}
              htmlName={"more_info_twitter_url_find_details"}
              htmlId={"more_info_twitter_url_find_details"}
              srText={"How to find the Twitter handle?"}
              icon={MoreInfo_Twitter}
              gutterRef={ this.props.gutterRef }
            />

            <Social
              title="LinkedIn URL"
              keyName="LinkedIn"
              bgColor="#0077B5"
              socialMediaIcon={linkedInIcon}
              placeholder="e.g. https://www.linkedin.com/in/joebruin/"
              url={this.props.data.LinkedIn&&this.props.data.LinkedIn.url}
              changeHandler={this.socialChange}
              htmlName={"more_info_linkedin_url_find_details"}
              htmlId={"more_info_linkedin_url_find_details"}
              srText={"How to find the LinkedIn URL?"}
              icon={MoreInfo_Linkedin}
              gutterRef={ this.props.gutterRef }
            />

            <Social
              title="Instagram URL or Handle"
              keyName="Instagram"
              background={instagramBackground}
              socialMediaIcon={instaIcon}
              placeholder="e.g. https://www.instagram.com/joebruin/"
              url={this.props.data.Instagram&&this.props.data.Instagram.url}
              changeHandler={this.socialChange}
              htmlName={"more_info_instagram_url_find_details"}
              htmlId={"more_info_instagram_url_find_details"}
              srText={"How to find the Instagram handle?"}
              icon={MoreInfo_Instagram}
              gutterRef={ this.props.gutterRef }
            />

          </SortSocialMedia>
        </div>

      </ProfileDataContainer>
    )
  }
}

export default SocialMedia;
