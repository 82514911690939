import styled from 'styled-components'

import { showLoading } from "services/util"

import { device } from 'common/Device'
import warningIcon from 'images/icon-warning.svg'

const ErrorContainer = styled.div`
  margin:10% auto;
  max-width:${720/16}rem;
  box-sizing:border-box;

  h4{
    margin-bottom:0;
  }
  @media ${ device.tablet } {
    width:75%;
  }
`;

const ErrorWrapper = styled.div`
  display:flex;
  flex-direction:column;
  flex:1 1 100%;
  justify-content:center;
  align-items:center;
`;

const ErrorHeading = styled.h1`
  color:#2774AE;
  font-size:24px;
  font-weight:bold;
  margin-bottom:0.5rem;
  text-align:center;
`;

const ErrorMessage = styled.p`
  text-align:center;
  font-size:1rem;
  line-height:1.625;
`;

const ErrorIcon = styled.i`
  display:block;
  height:3rem;
  width:calc(53rem/16);
  background-image:url(${ warningIcon});
  background-size:contain;
  background-repeat:no-repeat;
`

function SystemErrorComponent(props) {

  showLoading(false, 'systemerror page')

	return (
		<ErrorContainer>
			<ErrorWrapper>
				<ErrorIcon />
				<ErrorHeading id="pageTitle">Oops, something went wrong</ErrorHeading>
        <ErrorMessage>
          {props.children}
        </ErrorMessage>
			</ErrorWrapper>
		</ErrorContainer>
	)
}

export default SystemErrorComponent;
