import { useSelector } from "react-redux"
import { getAuctionUser } from '../redux/selectors'

import css from './sass/qrticket.module.scss'

export default function QrTicket( {auctionId} ) {
  
  const auctionUser = useSelector(getAuctionUser);
  const currentAuction = auctionUser.MyAuctions.find((auction) => auction.Auction.AuctionId === auctionId)

  // simple error display if any error in auction user / auction id
  if (!auctionUser || !currentAuction || !auctionId) return <p className={css['qr-error-text']}>Error loading QR Code</p>
  
  return (
      <img className={css['qr-image']} src={currentAuction?.QrBase64} />
  )
}