import { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'

import { ThemeContext } from 'ThemeContext';
import { handleSignup } from "../signup"
import { redirectSignedInUser } from "../helper"
import { validateCreatePassword } from "../validators"
import { signOut } from "../signout";

import EmailPhoneToggle from '../components/EmailPhoneToggle'
import { Heading } from '../components/Heading'
import { Label } from '../components/Label'
import { Button } from '../components/Buttons'
import { SignInRegisterDiv } from '../components/SignInRegisterDiv'
import ExternalAccounts from '../components/ExternalAccounts'
import { phoneMedia } from 'common/DeviceDetect'

import { Input } from 'css/forms'
import { ErrorSpan } from 'css/CommonStyle'
import orDividerImg from 'images/or.png';
import success from 'images/TickMark.svg';
import fail from 'images/WrongMark.svg';

import * as logon from '../css/AuthenticateStyles'

function RegisterForm(props) {
  const theme = useContext(ThemeContext)
  const [savedUsername, setSavedUsername] = useState(localStorage.getItem('savedUsername'))
  const [title] = useState(theme.registrationTitle)
  const [undertitle, setUndertitle] = useState(theme.registrationText)
  const [showForm, setShowForm] = useState(true)
  const [showSocialButtons, setShowSocialButtons] = useState(true)
  const [passwordProgress, setPasswordProgress] = useState()
  const [passwordFailing, setPasswordFailing] = useState(false)
  const [genericError, setGenericError] = useState('')
  const [usernameError, setUsernameError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')

  const isPhone = phoneMedia.matches;
  const checkmark = (<img src={success} alt='' />);
  const xmark = (<img src={fail} alt='' />);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const ssoRedirectUrl = urlParams.get('redirect_url')

    if (localStorage.loggedInUser
        && (theme.forceLogoutOnSignOrRegister
            || (ssoRedirectUrl && theme.forceLogoutOnRedirect && theme.redirectTest(ssoRedirectUrl)) ) ) {
      signOut(window.location.href, ssoRedirectUrl)
      return;
    }
    else {
      // directs user to landing page if they're signed in
      if (redirectSignedInUser()) {
        return;
      }
    }

    theme.themeAnalytics.analyticsRegistrationStep('entry')

    if (ssoRedirectUrl && theme.redirectTest && theme.redirectTest(ssoRedirectUrl))
      sessionStorage.setItem('ssoRedirectUrl', ssoRedirectUrl)

    if (props.location.state && props.location.state.isBadPasswordOnReverify) {
      let username = props.location.state.username;
      let authSource = "email address";
      if (username && username.substr(0,1) === "+") {
        authSource = "mobile number";
        username = username.substr(1);
      }
      setUndertitle("Your " + authSource + " has been confirmed. But the password you entered is incorrect. Please try a different password or click the 'Forgot Password?' link below.")
      setSavedUsername(username)
    }

    handleResize()
    phoneMedia.addListener(handleResize)
  }, [props.location.state, theme])

  useEffect(() => {
    return () => {
      phoneMedia.removeListener(handleResize)
    }
  }, [])

  const setMessages = {
    setGenericError,
    setUsernameError,
    setPasswordError,
    setConfirmPasswordError,
    setPasswordProgress,
    setPasswordFailing
  }

  const handleResize = () => {
    const isPhone = phoneMedia.matches
    setShowSocialButtons(isPhone ? false : true)
    if (!isPhone) {
      setShowForm(true)
      setShowSocialButtons(true)
    }
  }

  const showSocialButtonsClick = () => {
    setShowForm(false)
    setShowSocialButtons(true)
  };

  const showFormClick = () => {
    setShowForm(true)
    setShowSocialButtons(false)
  };

  const handleSubmit = async (event) => {
    await handleSignup(event, setMessages);
  }

  const showPasswordRules = (e) => {
    const errorObj = validateCreatePassword(e.target.value)
    setPasswordProgress(errorObj.passwordProgress)
    setPasswordFailing(errorObj.passwordFailin)
  };

  const hidePasswordRules = (e) => {
    setPasswordProgress(null);
  };

  return (
    <div>
      <logon.Content>
        <h3 className="bold center h4-mobile">{title}</h3>

        {isPhone && <Heading>
          {showForm
            ? <p>
                <logon.MobileGutter>
                  {undertitle}
                </logon.MobileGutter>
              </p>
            : <p>You may also register through an external account.</p>}
        </Heading>}

        <logon.HeaderForSignInRegisterForm>
          <p className={(showForm ? 'show' : '')} onClick={showFormClick} >Email/Phone</p>
          <p className={(showSocialButtons ? 'show' : '')} onClick={showSocialButtonsClick}>External Account</p>
        </logon.HeaderForSignInRegisterForm>

        <logon.ContentInner>
          {showForm &&
            <logon.SignInForm onSubmit={handleSubmit}>

              <SignInRegisterDiv>
                {!isPhone && <div>
                  <logon.MobileGutter>
                    {undertitle}
                  </logon.MobileGutter>
                </div>}

                <EmailPhoneToggle width="319px"
                  bgColor={"#f6f6f6"}
                  savedUsername={savedUsername}
                  usernameError={usernameError} signIn />

                <logon.SignInRegisterInnerDiv>
                  <Label>Create Password</Label>

                  <Input bgColor="#f6f6f6" type="password" name="password"
                    className={(passwordFailing ? "error" : "")}
                    onChange={showPasswordRules}
                    onFocus={showPasswordRules}
                    onBlur={hidePasswordRules} />

                  {passwordError &&
                    <ErrorSpan textAlign="right">
                      <label>{passwordError}</label>
                    </ErrorSpan>}

                  {passwordProgress &&
                    <logon.PasswordHintSpan>
                      <label className={passwordProgress.lowercase ? "green" : ""}>
                        {passwordProgress.lowercase ? checkmark : xmark} Password must contain a lower case letter<br />
                      </label>
                      <label className={passwordProgress.uppercase ? "green" : ""}>
                        {passwordProgress.uppercase ? checkmark : xmark} Password must contain an upper case letter<br />
                      </label>
                      <label className={passwordProgress.hasnumber ? "green" : ""}>
                        {passwordProgress.hasnumber ? checkmark : xmark} Password must contain a number<br />
                      </label>
                      <label className={passwordProgress.minlength ? "green" : ""}>
                        {passwordProgress.minlength ? checkmark : xmark} Password must be at least 8 characters<br />
                      </label>
                    </logon.PasswordHintSpan>}

                  { !passwordProgress ? '' :
                            ( passwordProgress.lowercase &&
                              passwordProgress.uppercase &&
                              passwordProgress.hasnumber &&
                              passwordProgress.minlength)
                            ? <logon.PasswordCheck>{checkmark}</logon.PasswordCheck>
                            : <logon.PasswordCheck> {xmark}</logon.PasswordCheck>}

                </logon.SignInRegisterInnerDiv>
                  <logon.SignInRegisterInnerDiv >
                    <Label>Confirm Password</Label>
                    <Input bgColor="#f6f6f6" type="password" name="confirmPassword" className={confirmPasswordError ? "error" : ""} />

                    {confirmPasswordError &&
                      <ErrorSpan textAlign="right">
                        <label >{confirmPasswordError}</label>
                      </ErrorSpan>}
                  </logon.SignInRegisterInnerDiv>

                {genericError &&
                  <logon.SignInRegisterInnerDiv>
                    <ErrorSpan textAlign="right">
                      <label>{genericError}</label>
                    </ErrorSpan>
                  </logon.SignInRegisterInnerDiv>}

                <logon.SignInRegisterInnerDiv>
                  <Button type="submit">CONTINUE</Button>
                  <p>Already have an account? <Link to='/signin'>Sign in</Link> now</p>
                </logon.SignInRegisterInnerDiv>

              </SignInRegisterDiv>
            </logon.SignInForm>}

          {!isPhone &&
            <logon.SignInDivider >
              <img alt='' src={orDividerImg} />
            </logon.SignInDivider>}

          {showSocialButtons &&
            <ExternalAccounts
              isPhone={isPhone}
              socialtitle="You may also register through an external account."
              isRegistration={true}
            />}
        </logon.ContentInner>
      </logon.Content>
    </div>
  )
}

export default RegisterForm
