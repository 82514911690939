import { useState } from 'react'

import css from './sass/alert.module.scss'

function PaymentMethodsNotification(props) {
  const [hidden, setHidden] = useState(localStorage.getItem('hidePaymentMethodsNotification') === 'true' ? true : false);

  const hide = (e) =>{
    setHidden(true)
    localStorage.setItem('hidePaymentMethodsNotification', true);
  }

    return (
      <>
      {!hidden && (
        <div className={`wrapper ${css.alertBannerGrid}`}>
          <div className={css.alertBannerContainer}>
            { props.children }
            <div className={css.alertBannerOuterDiv}>
              <button className={css.CloseNotificationButton} onClick={e => hide(e)}></button>
            </div>
          </div>
        </div>
      )}
      </>
    );
}

export default PaymentMethodsNotification;