import { useState } from 'react';

import store from 'redux/store';
import { getCrmUser } from 'redux/selectors';

import { showLoading } from 'services/util'
import DropDownMenu from 'common/forms/DropDown'
import { FilledYellow, StrokeBlue } from 'css/Buttons'

import { ReportsContainer, ReportsBlock, ReportsListing, RowWrapper, YearDropdown, ReportLink, YearHeader, Divider, PastYearsContainer, PastYearsLink} from '../ReportStyles'

import { getStaticDocument, downloadDocument, getFundReports, getPastCeirReports, ceirCurrentYear } from '../documentsapi'

const allCeirPastYears = []
for (let i=ceirCurrentYear-1; i >= 2020; i-- ) {
  allCeirPastYears.push(i);
}

function CeirReports(props) {
  const [showCeirPastYears, setShowCeirPastYears] = useState(false)
  const [viewCeirPastYearsText, setViewCeirPastYearsText] = useState("View Past Years +")
  const [pastCeirReports, setPastCeirReports] = useState(null)
  const [ceirPastYear, setCeirPastYear] = useState(allCeirPastYears[0]) // TODO - set dynamcially

  const handleCeirReportYearChange = e => {
    setCeirPastYear(e.target.value)
  }

  const toggleCeirLastYears = async e => {
    if (showCeirPastYears) {
      setShowCeirPastYears(false)
      setViewCeirPastYearsText("View Past Years +")
    }
    else {
      showLoading(true)

      const pastReports = await getPastCeirReports()
      setPastCeirReports(pastReports)

      showLoading(false)

      setShowCeirPastYears(true)
      setViewCeirPastYearsText("Hide Past Years -")
    }
  }

  const crmUser = getCrmUser(store.getState())
  const fundReports = getFundReports()

  return (
    <ReportsContainer>
      <ReportsBlock>
        <h3 className="h3">Giving in Action Reports</h3>
        <p>Giving in Action reports include information about how select current-use funds were spent during each calendar year. To view your reports, please click on the
           "Download" button next to the year for which you would like to access this information in the section below.</p>
        <p>Thank you for your support of UCLA!</p>
        <ReportsListing>
          <YearHeader>Calendar Year {ceirCurrentYear}</YearHeader>
            <RowWrapper className="reports-row-wrapper">
              <ReportLink key="-1">
                Giving In Action Booklet
                <FilledYellow onClick={e => getStaticDocument( {reportType: "CEIR", reportYear: ceirCurrentYear,
                                                                url:`/mygiving/documents/${ceirCurrentYear}/${ceirCurrentYear} UCLA Giving in Action Brochure.pdf`} )}>Download</FilledYellow>
              </ReportLink>
            </RowWrapper>
            {fundReports && fundReports.map((fundReport,idx) => (
              <RowWrapper className="reports-row-wrapper"  key={idx}>
                <ReportLink>
                  {fundReport.fundDesignation}
                  <FilledYellow id={"fundNarrativeLink"+(1*idx+1)} onClick={e => downloadDocument(fundReport)}>Download</FilledYellow>
                </ReportLink>
              </RowWrapper>
            ))}

            <>
              {<PastYearsContainer>
                <Divider/>
                <span><PastYearsLink onClick={toggleCeirLastYears}>{viewCeirPastYearsText}</PastYearsLink></span>
              </PastYearsContainer>}
              {showCeirPastYears &&
                <>
                  <YearHeader>Past Years:</YearHeader>

                  <YearDropdown>
                    <DropDownMenu
                      className="report-year-dropdown"
                      name="GivingInActionReportYearList"
                      title={ceirPastYear}
                      list={allCeirPastYears}
                      changeHandler={handleCeirReportYearChange}
                    />
                  </YearDropdown>
                  <RowWrapper className="reports-row-wrapper">
                    <ReportLink key="-1">
                      Giving In Action Booklet - {ceirPastYear}
                     <FilledYellow onClick={e => getStaticDocument( {reportType: "CEIR", reportYear: ceirPastYear,
                                                                      url:`/mygiving/documents/${ceirPastYear}/${ceirPastYear} UCLA Giving in Action Brochure.pdf`} )}>Download</FilledYellow>
                    </ReportLink>
                  </RowWrapper>

                  {pastCeirReports && pastCeirReports.filter(report => 1*report.reportYear === 1*(ceirPastYear)).map((report,idx) => (
                    <RowWrapper className="reports-row-wrapper" key={idx}>
                      <ReportLink>
                        {report.fundDesignation} - {report.reportYear}
                        <FilledYellow id={"fundNarrativeLink"+report.reportYear+"-"+(1*idx+1)} onClick={e => downloadDocument(report)}>Download</FilledYellow>
                      </ReportLink>
                    </RowWrapper>
                  ))}
                </>}
            </>

        </ReportsListing>
        {fundReports &&
          <div>
            <p>Please take this survey about your Giving in Action Report and let us know what you think!</p>
            <StrokeBlue onClick={e => window.open('https://survey.sjc1.qualtrics.com/jfe/form/SV_6Ff3vtwMKBymUBw?id_number=[[' + crmUser.profile.constituentLookupId + ']]')}>GIVING IN ACTION FEEDBACK SURVEY</StrokeBlue>
          </div>
        }
      </ReportsBlock>
    </ReportsContainer>
  )
}

export default CeirReports