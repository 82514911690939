import { Component, createRef } from 'react'

import { StyledMenu, StyledNavLink } from 'common/header/MenuStyles'

import ContactUs from '../contactus/ContactUs'
import { ContactUsHeader } from '../contactus/ContactUsStyles'

import css from '../components/sass/new.module.scss'

class Menu extends Component {
  constructor(props) {
    console.log(props)
    super(props);
    this.state = {
      showContactForm: false,
      timeOut: null
    }
    this.contactUsForm = createRef();
  }

  componentDidUpdate() {
    const { showContactForm } = this.state
    setTimeout(() => {
      if (showContactForm) {
        window.addEventListener('click', this.close)
      }
      else {
        window.removeEventListener('click', this.close)
      }
    }, 0)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.close)
  }

  displayContactForm = () => {
    this.setState({ showContactForm: !this.state.showContactForm })
  }

  close = (e) => {
    if (this.contactUsForm.current) {
      if (this.contactUsForm.current.contains(e.target)) {
        return;
      }
      this.setState({
        showContactForm: false
      })
    }
  }

  render() {
    const crmUser = this.props.authenticatedUser ? this.props.authenticatedUser.crmUser : null

    const isDpAdmin = localStorage.isDpAdmin && !sessionStorage.altCrmId; // don't show admin link when browsing as another user
    const adminLink = isDpAdmin && <li><StyledNavLink onClick={this.props.hideHeaderMenu} className="bold header-links" id="headerAdminLink" to="/admin/linkusertocrm">ADMIN</StyledNavLink></li>;

    const overviewLink = crmUser && !this.props.hasMultipleAccounts
                            && <li><StyledNavLink  onClick={this.props.hideHeaderMenu} className="bold header-links" id="headerOverviewLink" to="/dashboard">OVERVIEW</StyledNavLink></li>;

    const givingHistoryLink = crmUser && !this.props.hasMultipleAccounts &&
                           <li><StyledNavLink onClick={this.props.hideHeaderMenu} className="bold header-links" id="headerGivingHistoryLink" to="/history">GIVING HISTORY</StyledNavLink></li> ;

    const pledgesLink = crmUser && !this.props.hasMultipleAccounts &&
                         <li>
                            <StyledNavLink  onClick={this.props.hideHeaderMenu} className="bold header-links" id="headerPledgesLink" to="/pledges">PLEDGES</StyledNavLink>
                            <p className={`${css.callout} ${css.whitetext}`}>NEW</p>
                         </li>;

    const reportsLink = crmUser && <li><StyledNavLink  onClick={this.props.hideHeaderMenu} className="bold header-links" id="headerReportsLink" to="/reports">REPORTS</StyledNavLink></li>

    return (
      <StyledMenu id="headerLinks">
        {adminLink}
        {overviewLink}
        {givingHistoryLink}
        {pledgesLink}
        {reportsLink}
        <li>
          <StyledNavLink
            onClick={this.props.hideHeaderMenu}
            to="/faq"
            className=" bold header-links"
            id="headerFAQsLink">
            FAQS
          </StyledNavLink>
        </li>
          <li className="m-border">
            <ContactUsHeader
              onClick={this.displayContactForm}
              className="bold header-links"
              showContactForm={this.state.showContactForm}
             >
            <span id="contactUsLink">CONTACT US</span>
            </ContactUsHeader>
          </li>

        {this.state.showContactForm && <ContactUs ref={this.contactUsForm} />}
      </StyledMenu>
    )
  }
}

export default Menu;
