import Education from './Education'
import Employment from './Employment'

const MyInformationProfessional = (props)=> {

	let education = props.data.education.map((item, index) => {
	  // not using title for now since primary education should be shown first and isn't always most recent
	  return (<Education key={index} data={item}/>);
	})

	return (
		<div>
			{education.length > 0 && <h4 className="bold">UCLA  Education</h4>}

			{education}

			<h4 className="bold"> Primary Employment/Business</h4>

			<Employment
				updateValidation={props.updateValidation}
			changeHandler={props.changeHandler}
			employmentData={props.data}
			industryTypesList={props.bioType} />

		</div>
	)
}

export default MyInformationProfessional;