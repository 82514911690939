import { history } from 'services/history.js'
import { cardValidCheck } from "./paymentUtilities"

import CardWarning from './CardWarning'
import GiftsAssociatedToggle from './GiftsAssociatedToggle'
import PaymentMethodsSingleCard from './PaymentMethodsSingleCard'

import buttonStyle from '../sass/buttons.module.scss'
import css from '../payment/sass/payments.module.scss'

function PaymentMethodsSingle(props) {

  const associatedGifts = props.fullHistory.filter(gift => props.card.revenueLookupIdArray.includes((gift.revenueLookupId)))

  const cardValid = cardValidCheck(props.card.ccExpiresOn)

  return (
    <div className={css['payment-method']}>
      <div className={css['payment-method-body-top']}>
        <PaymentMethodsSingleCard card={props.card} cardValid={cardValid} paymentUpdateForm={false}/>
        <button
          onClick ={(e) => {history.push({ pathname: '/payment', state: { associatedGifts, cardId: props.card.uniqueIdentifier} })}}
          className={`${buttonStyle.button} ${buttonStyle.FilledBlue}`}>
            Update This Card
        </button>
      </div>
      <div className={`${cardValid ? css.hidden : css['card-invalid-warning'] }`}>
        <CardWarning />
      </div>
      {associatedGifts.length > 0 && <GiftsAssociatedToggle gifts={associatedGifts} />}
    </div>
  )
}

export default PaymentMethodsSingle