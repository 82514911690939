import { Auth } from 'aws-amplify'
import buildMetadata from 'build-metadata.json';
import { resetLoginTimeout } from './util'
import store from 'redux/store';

const clientContext = {
  client: { AppTitle: 'UCLA Support Portal', InstallationId: buildMetadata.build_id},
  custom: {crmId:'N/A', csub:'N/A'},
  env: process.env,
};

export const doApi = async (options) => {
  console.log('doApi!!!', options);

  try {
    if (!options.noAuth) {
      const session = await Auth.currentSession(); // throws error if user isn't logged in, refreshes ID and Access Tokens if expired
      if (!options.noAuth) options.idToken = session.idToken.jwtToken;
      clientContext.custom.csub = (session.idToken.payload) ? session.idToken.payload.sub : 'N/A';
      clientContext.custom.crmId = (session.idToken.payload) ? session.idToken.payload['custom:BlackBaudId'] : 'N/A';
      // update session, since Amplify will get a refresh token if needed
      resetLoginTimeout();
    }
  }
  catch (e) {
    console.log(e, 'user not authenticated');
  }

  let fetchUrl = store.getState().theme.apiGatewayHost + options.route;

  const fetchData = {
    method: options.method || "GET",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Amz-Client-Context': btoa(JSON.stringify(clientContext))
    },
    body: options.data ? JSON.stringify(options.data) : null
  }

  if (!options.noAuth) {
    fetchData.headers.Authorization = options.idToken;

    if (sessionStorage.altCrmId && options.noBrowseAsUser)
      throw new Error({key: 'BROWSE_AS_USER_NOT_ALLOWED'});
    else if (sessionStorage.altCrmId)
      fetchData.headers['dp-alt-bbid'] = sessionStorage.altCrmId;
  }

  // needed to Set-Cookie response header to work
  if (options.withCredentials) fetchData.credentials = 'include';

  try {
    const response = await fetch(fetchUrl, fetchData);
    const data = await response.json();
    return data;
  }
  catch (e) {
    if (options.noError) return;

    console.log("ERROR - Failed to ' + fetchData.method + ' ' + route");
    console.log(e);
    return {
      error: {
        key:e.key || 'CALL_FAILED',
        msg:e.message || e,
      }
    };
  }
};

// calls /user from back end for logged in Cognito user
// (email and cognito ID are automatically added by API Gateway)
export const getUserFromDb = async () => {
  const user = await doApi({route:'user' + (store.getState().theme.extraGetUserParam || '')});

  return user;
};

export const doPing = async () => {
  const response = await doApi({route:'dppinger', noAuth:true, /* noError:true */});
  console.log('ping response: ' + response);
};
