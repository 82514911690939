import styled from 'styled-components'

import { device } from 'common/Device'
import * as colors from 'css/Colors'

import PromoPersonalProjects from './PromoPersonalProjects'
import Health from '../images/Health.svg'
import Community from '../images/Community.svg'
import StudentSupports from '../images/StudentSupports.svg'
import Sciences from '../images/Sciences.svg'
import Arts from '../images/Arts.svg'
import SeeAllFunds from '../images/SeeAllFunds.svg'

const defaultFontSize = 16

const PromoNav = styled.nav`
background-color:#F5F5F5;

 .giving-to-header{
   color:#000000;
   &:after
   {
     content:'';
     display:block;
     background-color:${colors.primaryBlue};
     height:5px;
     width:5%;
     margin:20px auto;
   }
 }

@media${device.laptop}{
  .giving-to-header:after{
  width:10%;
  }
}

@media${device.mobile}{
  .giving-to-header:after{
  width:20%;
  }
}`

const PromoContent = styled.div`
display:flex;
flex-wrap: wrap;
margin-bottom:${58 / defaultFontSize}rem;
padding:0;
-ms-grid-row:2;

& >a[class*='span-']{
  padding:0;
}

@media ${device.tablet}{
  margin:0 ${30 / defaultFontSize}rem ${35 / defaultFontSize}rem;
}
`

const PromoNavigation = props => (
  <PromoNav className="fund-nav">
    <div className="wrapper">
      <div className="grid t-grid">
        <h3 className="span-12 t-span-12 giving-to-header center">Giving to What Matters</h3>
        <PromoContent className="span-12 t-span-12">
          <PromoPersonalProjects personalprojecticon={Health} personalprojectforward={"https://giveto.ucla.edu/cause/health/"} personalprojecttitle="HEALTH" />
          <PromoPersonalProjects personalprojecticon={Community} personalprojectforward={"https://giveto.ucla.edu/cause/community/"} personalprojecttitle="Community" />
          <PromoPersonalProjects personalprojecticon={StudentSupports} personalprojectforward={"https://giveto.ucla.edu/cause/student-support/"} personalprojecttitle="Student Support" />
          <PromoPersonalProjects personalprojecticon={Sciences} personalprojectforward={"https://giveto.ucla.edu/cause/sciences/"} personalprojecttitle="Sciences" />
          <PromoPersonalProjects personalprojecticon={Arts} personalprojectforward={" https://giveto.ucla.edu/cause/arts/"} personalprojecttitle="Arts" />
          <PromoPersonalProjects personalprojecticon={SeeAllFunds} personalprojectforward={"https://giveto.ucla.edu/find-funds/"} personalprojecttitle="See All Funds" />
        </PromoContent>
      </div>
    </div>
  </PromoNav>
)


export default PromoNavigation
