import { initializeDate } from 'services/util'

import { formatAmount, formatDate, formatFund } from '../mygivingutil'
import PaymentInput from './PaymentInput'

import css from './sass/paypledges.module.scss'

function PayPledgeDetail(props) {
  const {pledge, labels} = props

  // get totals of current payment and any past due payments
  const getAmountAndDateDue = (pledge => {
    const toPay = {pastDueAmount: 0, pastDueDate: '', upcomingAmountDue: 0, upcomingDueDate: ''}

    let currentPaymentAdded = false
    pledge.schedules.forEach(scheduledPayment => {
      const scheduleDate = initializeDate(scheduledPayment.date)

      if (scheduledPayment.balance && scheduleDate < initializeDate()) { // payment is late, total all and make due date most recent date
        toPay.pastDueAmount += scheduledPayment.balance
        if (!toPay.pastDueDate || scheduleDate > toPay.pastDueDate ) {
          toPay.pastDueDate = scheduleDate
        }
      }
      else if (scheduledPayment.balance && !currentPaymentAdded) { // payment is current, only save next payment
        toPay.upcomingAmountDue = scheduledPayment.balance
        toPay.upcomingDueDate = scheduleDate
        currentPaymentAdded = true;
      }
    })

    toPay.pastPlusUpcomingAmountDue = toPay.pastDueAmount + toPay.upcomingAmountDue

    return toPay;
  })

  const pledgeDueValues = getAmountAndDateDue(pledge)

  return (
    <>
      {props.isDesktop &&
        <tr>
          <td></td>
          <td>{formatDate(initializeDate(pledge.date))}</td>
          <td>{formatFund(pledge.designation, pledge.designationLookupId.split('-')[0])}</td>
          <td>{formatAmount(pledge.displayAmount)}</td>
          <td>{formatAmount(pledge.pledgeBalance)}</td>
          <td>
            <PaymentInput {...props} {...pledgeDueValues}/>
          </td>
          <td></td>
        </tr>}

      {!props.isDesktop &&
        <div className={css["pay-pledge-mobile-and-tablet-grid"]}>
          <div>
            <h3 className={css["pay-pledge-mobile-and-tablet-label"]}>{labels[0]}</h3>
            <span>{formatDate(initializeDate(pledge.date))}</span>
          </div>
          <div>
            <h3 className={css["pay-pledge-mobile-and-tablet-label"]}>{labels[1]}</h3>
            <span>{formatFund(pledge.designation, pledge.designationLookupId.split('-')[0])}</span>
          </div>
          <div>
            <h3 className={css["pay-pledge-mobile-and-tablet-label"]}>{labels[2]}</h3>
            <span>{formatAmount(pledge.displayAmount)}</span>
          </div>
          <div>
            <h3 className={css["pay-pledge-mobile-and-tablet-label"]}>{labels[3]}</h3>
            <span>{formatAmount(pledge.pledgeBalance)}</span>
          </div>

          <PaymentInput {...props} {...pledgeDueValues}/>

        </div>}
    </>
  )
}

export default PayPledgeDetail
