import css from '../sass/contact.module.scss'

function Contact() {
  return (
    <div>
      <h2 className="black bold with-line line-yellow">Contact Us</h2>
      <ul className={css.links}>
        <li>
          <address>
            <em className="label">
              <span className={css.break}>James West Alumni Center</span>
              <span className={css.break}>or Account Questions</span>
            </em>
            <span className={css.info}>310-825-2586</span>
            <span className={css.info}>alumni@alumni.ucla.edu</span>
          </address>
        </li>
        <li>
          <address>
            <em className="label">Donation Questions?</em>
            <span className={css.info}>310-794-2447</span>
            <span className={css.info}>gifts@support.ucla.edu</span>
          </address>
        </li>
      </ul>
    </div>
  )
}

export default Contact
