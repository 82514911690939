import { Route } from 'react-router-dom';

import Login from 'authentication/routes/Login';
import logoImage from '../alumni/images/Boxed-Alumni-Portal-Logo-v3.svg';

// olg-alumni inherits from olg, and borrowss some propertes direclty from alumni
import {alumni} from '../alumni/theme';
import Help from '../alumni/Help'

const routes = [
  <Route exact={true} path='/' component={Login} key="/alumnihome" />,
  <Route exact={true} path='/help' component={Help} key="/help" />,
]

// olgalumni inherits from olg and imports some things directly from alumni theme
export const olgalumni = {
  name: 'olgalumni',
  routes,
  logoImage,
  windowTitle: alumni.windowTitle,
  signinText: alumni.signinText,
  registrationTitle: alumni.registrationTitle,
  registrationText: alumni.registrationText,
  supportTeamName: alumni.supportTeamName,
  supportEmail: alumni.supportEmail,
  supportPhone: alumni.supportPhone,
  appName: alumni.appName,
  nonCrmUserLandingPage: '/help',
  captureNonCrmUser: true,
}

