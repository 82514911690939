import adminCss from './sass/adminpage.module.scss'

export default function NoAuctionsFound(props) {

  const options = ['closed', 'public', 'preview']

  const stateIndex = options.indexOf(props.status.toLowerCase())

  const current = options.splice(stateIndex, 1)

  return (
    <div className={adminCss['admin-auction-page-tab-container']}>
      <h3 className={adminCss['admin-auction-page-tab-header']}>
        {`No auctions are currently in the ${current} state, check the ${options[0]} or ${options[1]} states for auctions.`}
      </h3>
    </div>
  )
}