import React from 'react'
import styled from 'styled-components'

const defaultFontSize = 16

const DetailParagraph = styled.p`
  margin:0;

  span {
    font-size:${ 16/defaultFontSize }rem;
    font-weight:400;
  }
`

const ProfileName = styled.h2`
  color:#000000;
  font-size:${ 20/defaultFontSize }rem;
  font-weight:600;
`

const ProfileDetails = props => (
  <React.Fragment>
    { props.name && (
      <ProfileName>{ props.name }</ProfileName>
    )}
    { props.otherAccounts && (
      '-other accounts-'
    )}
    <DetailParagraph> <span>Donor Since { props.donorsince }</span></DetailParagraph>
    { props.uclaid && <DetailParagraph> <span>{ props.uclaid }</span></DetailParagraph> }
  </React.Fragment>
)

export default ProfileDetails
